// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-search.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-search-icon-top=0.5rem] - top margin of search icon
   */
  --sh-search-icon-top: 0.5rem;
  /* 
   * @cssprop [--sh-search-condensed-icon-top=0.25rem] - top margin of condensed search icon
   */
  --sh-search-condensed-icon-top: 0.25rem;
  /* 
   * @cssprop [--sh-search-icon-left=0.5rem] - left margin of search icon
   */
  --sh-search-icon-left: 0.5rem;
  /* 
   * @cssprop [--sh-search-condensed-icon-left=0.5rem] - left margin of condensed search icon
   */
  --sh-search-condensed-icon-left: 0.5rem;
  /* 
   * @cssprop [--sh-search-input-height=2.5rem] - height of search input
   */
  --sh-search-input-height: 2.5rem;
  /* 
   * @cssprop [--sh-search-condensed-input-height=2rem] - height of condensed search input
   */
  --sh-search-condensed-input-height: 2rem;
  /* 
   * @cssprop [--sh-search-value-line-height=0.5rem] - line-height of value
   */
  --sh-search-value-line-height: 0.5rem;
  /* 
   * @cssprop [--sh-search-input-padding=0.5rem 0.5rem 0.5rem 2.5rem] - padding of input
   */
  --sh-search-input-padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  /* 
   * @cssprop [--sh-search-condensed-input-padding=0.25rem 0.5rem 0.25rem 2.5rem] - padding of condensed input
   */
  --sh-search-condensed-input-padding: 0.25rem 0.5rem 0.25rem 2.5rem;
  /* 
   * @cssprop [--sh-search-input-padding-right=2.5rem] - right padding of input
   */
  --sh-search-input-padding-right: 2.5rem;
  /* 
   * @cssprop [--sh-search-input-condensed-padding-right=2.5rem] - right padding of condensed input
   */
  --sh-search-input-condensed-padding-right: 2.5rem;
  /* 
   * @cssprop [--sh-search-border-radius=var(--sh-radius-control) var(--sh-radius-control) var(--sh-radius-none) var(--sh-radius-none)] - border-radius of input
   */
  --sh-search-border-radius: var(--sh-radius-control) var(--sh-radius-control) var(--sh-radius-none) var(--sh-radius-none);
  /* 
   * @cssprop [--sh-search-border-bottom-thickness=0.0625rem] - border-bottom of input
   */
  --sh-search-border-bottom-thickness: 0.0625rem;
  /* 
   * @cssprop [--sh-search-list-item-height=2.5rem] - list-item-height
   */
  --sh-search-list-item-height: 2.5rem;
  /* 
   * @cssprop [--sh-search-single-line-list-item-padding=0.5rem] - list-item-padding
   */
  --sh-search-single-line-list-item-padding: 0.5rem;
  /* 
   * @cssprop [--sh-search-list-item-line-height=1.5rem] - line-height of list item
   */
  --sh-search-list-item-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-search-close-icon-right=0.5rem] - distance between right of close icon and right of search input
   */
  --sh-search-close-icon-right: 0.5rem;
  /* 
   * @cssprop [--sh-search-condensed-close-icon-right=0.25rem] - distance between right of close icon and right of condensed search input
   */
  --sh-search-condensed-close-icon-right: 0.25rem;
  /* 
   * @cssprop [--sh-search-multi-line-list-item-padding=0.25rem 0.5rem] - multi-line list-item padding
   */
  --sh-search-multi-line-list-item-padding: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-search-multi-line-list-item-height=2.75rem] - multi-line list-item height
   */
  --sh-search-multi-line-list-item-height: 2.75rem;
  /* 
   * @cssprop [--sh-search-multi-line-single-item-height=1.125rem] - multi-line single-item height
   */
  --sh-search-multi-line-single-item-height: 1.125rem;
  /* 
   * @cssprop [--sh-search-empty-wrapper-padding=1rem 0.5rem] - padding of empty wrapper
   */
  --sh-search-empty-wrapper-padding: 1rem 0.5rem;
  /* 
   * @cssprop [--sh-search-empty-slot-item-gap=1rem] - the gap between the items in empty slot
   */
  --sh-search-empty-slot-item-gap: 1rem;
  /* 
   * @cssprop [--sh-search-rounded-border-radius=var(--sh-radius-pill)] - border-radius of rounded border
   */
  --sh-search-rounded-border-radius: var(--sh-radius-pill);
  /* 
   * @cssprop [--sh-search-rounded-icon-left=0.75rem] - left margin of rounded search icon
   */
  --sh-search-rounded-icon-left: 0.75rem;
  /* 
   * @cssprop [--sh-search-rounded-input-padding=0.5rem 0.75rem 0.5rem 2.75rem] - padding of rounded search input
   */
  --sh-search-rounded-input-padding: 0.5rem 0.75rem 0.5rem 2.75rem;
  /* 
   * @cssprop [--sh-search-rounded-input-padding-right=2.75rem] - right padding of rounded search input
   */
  --sh-search-rounded-input-padding-right: 2.75rem;
  /* 
   * @cssprop [--sh-search-rounded-close-icon-right=0.75rem] - distance between right of close icon and right of rounded search input
   */
  --sh-search-rounded-close-icon-right: 0.75rem;
  /* 
   * @cssprop [--sh-search-rounded-value-line-height=1.5rem] - line-height of rounded search value
   */
  --sh-search-rounded-value-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-search-rounded-list-item-line-height=1.5rem] - line-height of rounded search list item
   */
  --sh-search-rounded-list-item-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-search-rounded-single-line-list-item-padding=0.5rem 0.75rem] - padding of rounded search list item
   */
  --sh-search-rounded-single-line-list-item-padding: 0.5rem 0.75rem;
  /* 
   * @cssprop [--sh-search-rounded-multi-line-list-item-padding=0.25rem 0.75rem] - padding of rounded search multi-line list item
   */
  --sh-search-rounded-multi-line-list-item-padding: 0.25rem 0.75rem;
  /* 
   * @cssprop [--sh-search-rounded-empty-wrapper-padding=1rem 0.75rem] - padding of rounded search empty wrapper
   */
  --sh-search-rounded-empty-wrapper-padding: 1rem 0.75rem;
}

:host {
  width: 100%;
  display: block;
  height: var(--sh-search-input-height);
  position: relative;
}

:host([no-border]) input {
  border: none;
  outline: none;
  border-bottom: var(--sh-search-border-bottom-thickness) solid transparent;
  border-radius: var(--sh-radius-control);
}

:host([no-border]:not([rounded])) input:focus {
  border-color: rgba(var(--ui-1), var(--opacity-4));
  border-radius: unset;
}

:host([condensed]:not([rounded])) {
  height: var(--sh-search-condensed-input-height);
}

input,
:host([readonly]) input:focus {
  padding: var(--sh-search-input-padding);
  border-radius: var(--sh-search-border-radius);
  background: rgba(var(--ui-1), var(--opacity-7));
  height: var(--sh-search-input-height);
  color: var(--color-text-strong);
  font: var(--body-1);
  line-height: var(--sh-search-value-line-height) !important;
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  text-overflow: ellipsis;
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) background;
  border-bottom: var(--sh-search-border-bottom-thickness) solid transparent;
  border-color: rgba(var(--ui-1), var(--opacity-4));
}

:host([condensed]:not([rounded])) input,
:host([condensed][readonly]:not([rounded])) input:focus {
  padding: var(--sh-search-condensed-input-padding);
  height: var(--sh-search-condensed-input-height);
}

:host(.no-hovermq) input {
  transition: none;
}

input::-ms-clear {
  display: none;
}

:host([disabled]) input {
  color: var(--text-disabled);
  background: rgba(var(--ui-1), var(--opacity-7));
}

sh-icon {
  position: absolute;
  top: var(--sh-search-icon-top);
  left: var(--sh-search-icon-left);
  cursor: pointer;
}

:host([condensed]:not([rounded])) sh-icon {
  top: var(--sh-search-condensed-icon-top);
  left: var(--sh-search-condensed-icon-left);
}

#showSearch {
  border-radius: 0.125rem;
  max-height: calc(5.5 * var(--sh-search-list-item-height)) !important;
}

.empty-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--sh-search-empty-slot-item-gap);
}

#showSearch,
.empty-wrapper {
  overflow: auto;
  background: var(--base-4);
  z-index: 1;
  box-shadow: var(--shadow-overlay);
  box-sizing: border-box;
  border-radius: var(--sh-radius-small-container);
  margin: 4px 0;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#showSearch .itemList {
  height: var(--sh-search-list-item-height);
  padding: var(--sh-search-single-line-list-item-padding);
  font: var(--body-1);
  color: var(--color-text-strong);
  line-height: var(--sh-search-list-item-line-height);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  transition: var(--hover-transition-time) background;
}

.closeIcon {
  position: absolute;
  left: auto;
  right: var(--sh-search-close-icon-right);
  cursor: pointer;
  background: transparent !important;
}

:host([condensed]:not([rounded])) .closeIcon {
  position: absolute;
  left: auto;
  right: var(--sh-search-condensed-close-icon-right);
}

:host([disabled]) input::-moz-placeholder {
  color: var(--text-disabled);
  opacity: unset;
}

:host([disabled]) input::-webkit-input-placeholder,
:host([disabled]) input::placeholder {
  color: var(--text-disabled);
}

input::-webkit-input-placeholder,
input::placeholder,
input::-moz-placeholder {
  font: var(--body-1);
  color: var(--color-text-subtle);
}

.searchText {
  white-space: pre;
  color: var(--color-text-strong);
  font: var(--title-1);
}

/* hover */
input:hover {
  background: rgba(var(--ui-1), var(--opacity-6));
}

input:focus {
  border-color: rgba(var(--ui-1), var(--opacity-2));
}

:host(:not(.no-hovermq)) #showSearch .itemList:hover,
#showSearch .itemList.hover {
  cursor: pointer;
  background: rgba(var(--ui-1), var(--opacity-7));
}

:host(:not(.no-hovermq)) .closeIcon:hover,
.closeIcon.hover {
  color: rgba(var(--ui-1), var(--opacity-2));
}

.itemList.active {
  background: rgba(var(--ui-1), var(--opacity-7));
}

/*multiline suggestions */
:host([multiline]) #showSearch {
  max-height: calc(5.5 * var(--sh-search-multi-line-list-item-height)) !important;
}

:host([multiline]) #showSearch .itemList {
  padding: var(--sh-search-multi-line-list-item-padding);
  height: var(--sh-search-multi-line-list-item-height);
}

:host([multiline]) #showSearch .itemList div {
  height: var(--sh-search-multi-line-single-item-height);
  line-height: var(--sh-search-multi-line-single-item-height);
}

:host([multiline]) .highlight .itemList {
  background: rgba(var(--ui-1), var(--opacity-7));
}

:host([multiline]) #showSearch .firstItem {
  font: var(--body-1);
  color: var(--color-text-strong);
}

:host([multiline]) #showSearch .secondItem {
  font: var(--body-2);
  color: var(--color-text-subtle);
}

.secondItem .searchText {
  font: var(--title-2);
}

.firstItem,
.secondItem {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.empty-wrapper {
  padding: var(--sh-search-empty-wrapper-padding);
}

input::-moz-placeholder {
  font: var(--body-1);
  color: var(--color-text-subtle);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
}

input::placeholder {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  font: var(--body-1);
  color: var(--color-text-subtle);
}

:host([value=""]) #showSearch,
:host([value=""]) .empty-wrapper {
  display: none;
}

:host(:not([value=""])) input {
  padding-right: var(--sh-search-input-padding-right);
}

:host([condensed]:not([value=""]):not([rounded])) input {
  padding-right: var(--sh-search-input-condensed-padding-right);
}

:host([rounded]) input {
  border-radius: var(--sh-search-rounded-border-radius);
  border: 1px solid rgba(var(--ui-1), var(--opacity-50));
  background: rgba(var(--ui-1), var(--opacity-50));
  padding: var(--sh-search-rounded-input-padding);
  line-height: var(--sh-search-rounded-value-line-height) !important;
}

:host([rounded]:not([disabled])) input:hover {
  background: rgba(var(--ui-1), var(--opacity-100));
}

:host([rounded]) input:focus {
  border: 1px solid rgba(var(--ui-1), var(--opacity-300));
}

:host([rounded][no-border]) input {
  border-color: transparent;
}

:host([rounded][no-border]) input:focus {
  border: 1px solid rgba(var(--ui-1), var(--opacity-300));
}

:host([rounded]) sh-icon {
  left: var(--sh-search-rounded-icon-left);
}

:host([rounded]) .closeIcon {
  left: auto;
  right: var(--sh-search-rounded-close-icon-right);
}

:host([rounded]:not([value=""])) input {
  padding-right: var(--sh-search-rounded-input-padding-right);
}

:host([rounded]) #showSearch .itemList {
  line-height: var(--sh-search-rounded-list-item-line-height);
  padding: var(--sh-search-rounded-single-line-list-item-padding);
}

:host([rounded][multiline]) #showSearch .itemList {
  padding: var(--sh-search-rounded-multi-line-list-item-padding);
}

:host([rounded]) .empty-wrapper {
  padding: var(--sh-search-rounded-empty-wrapper-padding);
}`;