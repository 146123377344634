// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-timepicker.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-timepicker-height=2.5rem] - timepicker height
   */
  --sh-timepicker-height: 2.5rem;
  /* 
   * @cssprop [--sh-timepicker-panel-height=8.5rem] - height of the timepicker panel
   */
  --sh-timepicker-panel-height: 8.5rem;
  /* 
   * @cssprop [--sh-timepicker-control-value-margin=0.5rem 0] - margin of the control-value
   */
  --sh-timepicker-control-value-margin: 0.5rem 0;
  /* 
   * @cssprop [--sh-timepicker-control-value-height=1.5rem] - height of the control value
   */
  --sh-timepicker-control-value-height: 1.5rem;
  /* 
   * @cssprop [--sh-timepicker-icon-wrapper-padding-right=0.5rem] - padding-right of the icon-wrapper
   */
  --sh-timepicker-icon-wrapper-padding-right: 0.5rem;
  /* 
   * @cssprop [--sh-timepicker-icon-wrapper-gap=0.5rem] - gap between icon in icon-wrapper
   */
  --sh-timepicker-icon-wrapper-gap: 0.5rem;
  /* 
   * @cssprop [--sh-timepicker-label-without-value-line-height=1.5rem] - line-height of the label without value
   */
  --sh-timepicker-label-without-value-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-timepicker-label-left=0.5rem] - left of the label
   */
  --sh-timepicker-label-left: 0.5rem;
  /* 
   * @cssprop [--sh-timepicker-label-top=0.5rem] - top pf the label
   */
  --sh-timepicker-label-top: 0.5rem;
  /* 
   * @cssprop [--sh-timepicker-label-with-value-top=0.25rem] - top of the label when value is present
   */
  --sh-timepicker-label-with-value-top: 0.25rem;
  /* 
   * @cssprop [--sh-timepicker-label-with-value-line-height=1rem] - line-height of the label with value
   */
  --sh-timepicker-label-with-value-line-height: 1rem;
  /* 
   * @cssprop [--sh-timepicker-input-field-height=1rem] - height of the input-field
   */
  --sh-timepicker-input-field-height: 1rem;
  /* 
   * @cssprop [--sh-timepicker-input-field-padding=1.25rem 0 0.25rem 0.5rem] - padding of the input-field
   */
  --sh-timepicker-input-field-padding: 1.25rem 0 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-timepicker-placeholder-height=var(--sh-timepicker-input-field-height)] - height of the placeholder
   */
  --sh-timepicker-placeholder-height: var(--sh-timepicker-input-field-height);
  /* 
   * @cssprop [--sh-timepicker-amPm-overlay-width=1.5rem] - width of the am and pm present in the overlay
   */
  --sh-timepicker-amPm-overlay-width: 1.5rem;
  /* 
   * @cssprop [--sh-timepicker-amPm-height=var(--sh-timepicker-input-field-height)] - height of the am and pm in the placeholder
   */
  --sh-timepicker-amPm-height: var(--sh-timepicker-input-field-height);
  /* 
   * @cssprop [--sh-timepicker-amPm-label-width=1.25rem] - width of the am and pm in the label
   */
  --sh-timepicker-amPm-label-width: 1.25rem;
  /* 
   * @cssprop [--sh-timepicker-seperator-width=0.25rem] - width of the seperator
   */
  --sh-timepicker-seperator-width: 0.25rem;
  /* 
   * @cssprop [--sh-timepicker-seperator-height=var(--sh-timepicker-input-field-height)] - height of the seperator
   */
  --sh-timepicker-seperator-height: var(--sh-timepicker-input-field-height);
  /* 
   * @cssprop [--sh-timepicker-condensed-height=2rem] - height of the condensed timepicker
   */
  --sh-timepicker-condensed-height: 2rem;
  /* 
   * @cssprop [--sh-timepicker-condensed-label-top=0.25rem] - top of the condensed label
   */
  --sh-timepicker-condensed-label-top: 0.25rem;
  /* 
   * @cssprop [--sh-timepicker-condensed-input-field-height=1.5rem] - height of the condensed input-field
   */
  --sh-timepicker-condensed-input-field-height: 1.5rem;
  /* 
   * @cssprop [--sh-timepicker-condensed-input-field-padding=0.25rem 0 0.25rem 0.5rem] - padding of the condensed input-field
   */
  --sh-timepicker-condensed-input-field-padding: 0.25rem 0 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-timepicker-helper-text-padding=0.25rem 0.5rem 0rem] - padding of the helper-text
   */
  --sh-timepicker-helper-text-padding: 0.25rem 0.5rem 0rem;
  /* 
   * @cssprop [--sh-timepicker-seperator-line-height=1] - line-height of the seperator
   */
  --sh-timepicker-seperator-line-height: 1;
  /* 
   * @cssprop [--sh-timepicker-border-radius=var(--sh-radius-control) var(--sh-radius-control) var(--sh-radius-none) var(--sh-radius-none)] - border-radius of the timepicker
   */
  --sh-timepicker-border-radius: var(--sh-radius-control) var(--sh-radius-control) var(--sh-radius-none) var(--sh-radius-none);
}

:host {
  outline: 0;
  width: 100%;
  height: var(--sh-timepicker-height);
  position: relative;
  display: flex;
  flex-direction: column;
}

/*--Idle state styles--*/
.timepicker-wrapper {
  width: 100%;
  height: var(--sh-timepicker-height);
  box-sizing: border-box;
  background-color: rgba(var(--ui-1), var(--opacity-7));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  border-radius: var(--sh-timepicker-border-radius);
  position: relative;
  transition: var(--transition-time) all ease-in-out;
  border-bottom: 0.0625rem solid transparent;
  border-color: rgba(var(--ui-1), var(--opacity-4));
}

.timepicker-input {
  display: flex;
  flex-direction: row;
}

.timepicker-panel {
  background: var(--base-4);
  height: var(--sh-timepicker-panel-height);
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0.0625rem 0.125rem 0.0625rem var(--shadow-strong), -0.0625rem -0.0625rem 0.0625rem var(--shadow-light);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: var(--sh-radius-small-container);
}

.timepicker-controls-wrapper {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timepicker-controls-wrapper .seperator {
  width: 1.5rem;
}

.timepicker-controls {
  text-align: center;
}

.control-value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--sh-timepicker-control-value-margin);
  height: var(--sh-timepicker-control-value-height);
}

:host([no-border]) .timepicker-wrapper {
  border-radius: var(--sh-radius-control);
}

:host([error]) .timepicker-wrapper {
  border-color: rgb(var(--functional-red));
}

:host([safety]) .timepicker-wrapper {
  border-color: rgb(var(--functional-yellow));
}

:host([success]) .timepicker-wrapper {
  border-color: rgb(var(--functional-green));
}

.icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  padding-right: var(--sh-timepicker-icon-wrapper-padding-right);
  gap: var(--sh-timepicker-icon-wrapper-gap);
}

.label-area {
  width: 100%;
  transition: var(--transition-time) all ease-in-out;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.label-slot {
  color: var(--text-secondary);
  font: var(--body-1);
  line-height: var(--sh-timepicker-label-without-value-line-height);
  left: var(--sh-timepicker-label-left);
  width: calc(100% - var(--sh-timepicker-label-left));
  -webkit-transition: var(--transition-time) all ease-in-out, 0s line-height ease-in-out, 0s height ease-in-out;
  -o-transition: var(--transition-time) all ease-in-out, 0s line-height ease-in-out, 0s height ease-in-out;
  transition: var(--transition-time) all ease-in-out, 0s line-height ease-in-out, 0s height ease-in-out, 0s width ease-in-out;
  transform-origin: left;
  top: var(--sh-timepicker-label-top);
  transform: scale(1);
  position: absolute;
  display: flex;
}

.close-button {
  opacity: 0;
  position: relative;
  transition: var(--transition-time) opacity ease-in-out;
  pointer-events: none;
  --sh-icon-size-s: 0;
  --sh-icon-font-size-s: 0;
}

.close-button.item-focused,
:host([error]:not([h=""])) .close-button,
:host([error]:not([m=""])) .close-button,
:host([error]:not([s=""])) .close-button,
:host(:hover:not([h=""])) .close-button,
:host(:hover:not([m=""])) .close-button,
:host(:hover:not([s=""])) .close-button,
:host(:not([h=""])) .close-button.touch-device {
  opacity: 1;
  pointer-events: all;
  --sh-icon-size-s: unset;
  --sh-icon-font-size-s: unset;
}

/*--Hover styles--*/
:host([active][responsive]:not([error]):not([safety]):not([success])) .timepicker-wrapper,
:host(:not([responsive]).hasCursor:not([error]):not([safety]):not([success])) .timepicker-wrapper {
  border-color: rgba(var(--ui-1), var(--opacity-2));
}

.timepicker-wrapper:hover {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host(.hasCursor) .timepicker-wrapper {
  background-color: rgba(var(--ui-1), var(--opacity-7));
}

/*--Focus Styles--*/
.timepicker-panel input::placeholder,
.time-input-wrapper input::placeholder {
  color: var(--text-disabled);
}

:host(.hasCursor) .label-slot,
:host(.hasValue) .label-slot {
  top: var(--sh-timepicker-label-with-value-top);
  font: var(--body-2);
  line-height: var(--sh-timepicker-label-with-value-line-height);
}

#time-input-field {
  opacity: 0;
  height: var(--sh-timepicker-input-field-height);
  padding: var(--sh-timepicker-input-field-padding);
  width: calc(100% - var(--sh-timepicker-label-left));
}

:host(.hasCursor) #time-input-field,
:host(.hasValue) #time-input-field {
  opacity: 1;
}

#h,
#m,
#s,
#h-label,
#m-label,
#s-label {
  width: 2ch;
  height: var(--sh-timepicker-placeholder-height);
  background: transparent;
  outline: none;
  border: none;
  padding: 0rem;
  font: var(--body-1);
  color: var(--text-primary);
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-transition: all 0.01s ease-in-out;
  -o-transition: all 0.01s ease-in-out;
  transition: all 0.01s ease-in-out;
  overscroll-behavior: contain;
}

#m:placeholder-shown {
  width: 3ch;
}

#h-label {
  padding-right: 0.125rem;
}

#s-label {
  padding-left: 0.125rem;
}

:host([responsive]) .input-slot input {
  pointer-events: none;
}

#amOrPm,
#ap-label {
  width: var(--sh-timepicker-amPm-overlay-width);
  height: var(--sh-timepicker-amPm-height);
  background: transparent;
  outline: none;
  padding: 0rem;
  border: none;
  font: var(--body-1);
  color: var(--text-primary);
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overscroll-behavior: contain;
}

#amOrPm {
  text-align: center;
}

#ap-label {
  width: var(--sh-timepicker-amPm-label-width);
  padding-left: 0.125rem;
}

#m-label:not(:placeholder-shown) {
  padding: 0 0.125rem;
  text-align: center;
}

#s-label:not(:placeholder-shown) {
  text-align: center;
}

#m-label:placeholder-shown {
  width: 3ch;
  padding: 0 0.125rem;
}

.seperator {
  width: var(--sh-timepicker-seperator-width);
  height: var(--sh-timepicker-seperator-height);
  text-align: center;
  color: var(--text-disabled);
  font: var(--body-1);
  line-height: var(--sh-timepicker-seperator-line-height);
}

:host([error][error-message]),
:host([hint]) {
  height: auto;
}

#mandatoryStarSymbol {
  color: rgba(var(--ui-2), var(--opacity-1));
}

.seperator.colon-active {
  color: var(--text-primary);
}

.time-input-wrapper {
  display: flex;
}

#time-panel {
  height: 0rem;
}

/*--disabled--*/
:host([disabled]) .timepicker-wrapper {
  background-color: rgba(var(--ui-1), var(--opacity-7));
  pointer-events: none;
}

:host([disabled]) *,
:host([disabled]) #h-label,
:host([disabled]) #m-label,
:host([disabled]) #s-label,
:host([disabled]) #ap-label,
:host([disabled]) input {
  pointer-events: none;
  color: var(--text-disabled);
}

:host([disabled]),
:host([disabled]) .label-slot,
:host([disabled]) .close-button {
  pointer-events: none;
}

:host([disabled]) #mandatoryStarSymbol {
  color: rgba(var(--ui-2), var(--opacity-5)) !important;
}

:host([disabled]) .helper-text {
  color: var(--text-disabled);
}

/*--Condensed Styles--*/
:host(:not([hint]):not([error])[condensed]) {
  height: var(--sh-timepicker-condensed-height);
}

:host([condensed]) .timepicker-wrapper {
  height: var(--sh-timepicker-condensed-height);
}

:host([condensed]) .label-slot {
  display: flex;
  top: var(--sh-timepicker-condensed-label-top);
}

:host([condensed]) #time-input-field {
  height: var(--sh-timepicker-condensed-input-field-height);
  padding: var(--sh-timepicker-condensed-input-field-padding);
  justify-content: center;
}

:host([condensed].hasValue) .label-slot,
:host([condensed].hasCursor) .label-slot,
:host([disabled]) .close-button,
:host([active=false]) .timepicker-panel,
:host([error][error-message]) .hint {
  display: none;
}

/*--helper text--*/
.helper-text {
  padding: var(--sh-timepicker-helper-text-padding);
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  visibility: visible;
}

:host([no-border]) .timepicker-wrapper {
  border-bottom: none;
}

.overlay {
  box-shadow: var(--shadow-overlay);
  height: var(--sh-timepicker-panel-height);
  margin: 4px 0;
  border-radius: var(--sh-radius-small-container);
}`;