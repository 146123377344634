// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-user-identifier.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-user-identifier-margin-left=0.5rem] - info margin left
   */
  --sh-user-identifier-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-user-identifier-max-width=10rem] - max width of name and info
   */
  --sh-user-identifier-max-width: 10rem;
  /* 
   * @cssprop [--sh-user-identifier-height=1rem] - height of name and info
   */
  --sh-user-identifier-height: 1rem;
  /* 
   * @cssprop [--sh-user-identifier-circle-dimension=2rem] - height of name and info
   */
  --sh-user-identifier-circle-dimension: 2rem;
  /* 
   * @cssprop [--sh-user-identifier-image-circle=2rem] - width and height of image circle
   */
  --sh-user-identifier-image-circle: 2rem;
  /* 
   * @cssprop [--sh-user-identifier-margin=0rem] - width and height of image circle
   */
  --sh-user-identifier-margin: 0rem;
}

:host {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  outline: 0;
}

:host([access-context]) {
  width: fit-content;
  width: -ms-fit-content;
  width: -moz-fit-content;
}

.circle {
  width: var(--sh-user-identifier-circle-dimension);
  height: var(--sh-user-identifier-circle-dimension);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--ui-1), var(--opacity-6));
  border-radius: 50%;
  overflow: hidden;
  font: var(--title-1);
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
}

:host([image]) .circle {
  width: var(--sh-user-identifier-image-circle);
  height: var(--sh-user-identifier-image-circle);
}

:host([image]) sh-icon {
  --sh-icon-font-size-m: var(--sh-user-identifier-image-circle);
}

:host([unauthenticated]) .circle,
:host([initials]) .circle,
:host(:not([initials]):not([image]):not([unauthenticated])) .circle {
  width: var(--sh-user-identifier-circle-dimension);
  height: var(--sh-user-identifier-circle-dimension);
  margin: var(--sh-user-identifier-margin);
}

.info-wrapper {
  max-width: var(--sh-user-identifier-max-width);
  flex-direction: column;
  display: flex;
  margin-left: var(--sh-user-identifier-margin-left);
  justify-content: center;
  flex: 1;
  overflow: hidden;
}

:host([access-context]) .info-wrapper {
  max-width: var(--sh-user-identifier-max-width);
}

.name,
.info {
  max-width: var(--sh-user-identifier-max-width);
  line-height: var(--sh-user-identifier-height);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: siemens sans, sans-serif;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  height: var(--sh-user-identifier-height);
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
}

:host .name,
:host .info,
:host .circle,
:host sh-icon[icon=user] {
  color: var(--text-secondary);
}

@media (hover: hover) {
  :host(:hover) .name,
  :host(:hover) .info,
  :host(:hover) .circle,
  :host(:hover) sh-icon[icon=user],
  :host(:hover) sh-icon[icon=user-avatar] {
    color: var(--text-primary);
  }
}
.name {
  font: var(--title-1);
  line-height: var(--sh-user-identifier-height);
}

.info {
  font: var(--body-2);
}

/* unauthenticated */
:host([unauthenticated]:not([name])) .circle {
  border-radius: initial;
  background-color: unset;
}

/* condensed access-bar */
:host([access-context][condensed]) {
  margin: -0.25rem 0;
}

/* medium screens */
@media only screen and (max-width: 64.0625rem) {
  :host([access-context]) .info-wrapper {
    display: none;
  }
}
/* focus */
:host(.focus) {
  outline: var(--focus-outline);
}

:host sh-icon[icon=user-avatar] {
  color: var(--text-secondary);
}`;