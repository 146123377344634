// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-table.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:host {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  --scrollbar-width: 0rem;
}

:host([multiselect][selection-mode=metakey]) {
  user-select: none;
}

:host([fit-content]) {
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  max-height: fit-content;
  max-height: -ms-fit-content;
  max-height: -moz-fit-content;
}

.table-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

:host([freeze]) .floating-scrollbar {
  position: relative;
  margin-top: 0.5rem;
  overflow-x: auto;
}

:host([freeze]) .scrollThumb {
  height: 0.5rem;
}

.header-wrapper {
  overflow-x: hidden;
  width: 100%;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  overflow-y: hidden;
  padding-right: var(--scrollbar-width);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.content {
  height: 100%;
}`;