// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-icon.font.lit.scss INSTEAD!
import { css } from 'lit';
export default css`/* native icons */
:host([icon=calculate]) .first:before {
  content: "\\E000";
}

:host([icon=calendar]) .first:before {
  content: "\\E001";
}

:host([icon=edit]) .first:before {
  content: "\\E003";
}

:host([size=s][icon=edit]) .first:before {
  content: "\\EF2B";
}

:host([icon=export]) .first:before {
  content: "\\E004";
}

:host([size=s][icon=export]) .first:before {
  content: "\\EF16";
}

:host([icon=favorite]) .first:before {
  content: "\\E005";
}

:host([icon=filter]) .first:before {
  content: "\\E006";
}

:host([icon=home]) .first:before {
  content: "\\E008";
}

:host([icon=import]) .first:before {
  content: "\\E009";
}

:host([icon=layout-1x1]) .first:before {
  content: "\\E00A";
}

:host([icon=layout-2x1]) .first:before {
  content: "\\E00B";
}

:host([icon=layout-2x2]) .first:before {
  content: "\\E00C";
}

:host([icon=layout-3x2]) .first:before {
  content: "\\E00D";
}

:host([icon=layout-4x4]) .first:before {
  content: "\\E00E";
}

:host([icon=lock]) .first:before {
  content: "\\E00F";
}

:host([icon=marker]) .first:before {
  content: "\\E010";
}

:host([icon=minus]) .first:before {
  content: "\\E011";
}

:host([icon=open]) .first:before {
  content: "\\E012";
}

:host([icon=pan]) .first:before {
  content: "\\E013";
}

:host([icon=patient]) .first:before {
  content: "\\E014";
}

:host([icon=patient-browser]) .first:before {
  content: "\\E015";
}

:host([icon=patient-scheduler]) .first:before {
  content: "\\E016";
}

:host([icon=pause]) .first:before {
  content: "\\E017";
}

:host([size=s][icon=pause]) .first:before {
  content: "\\EF32";
}

:host([icon=play]) .first:before {
  content: "\\E018";
}

:host([size=s][icon=play]) .first:before {
  content: "\\EF34";
}

:host([icon=plus]) .first:before {
  content: "\\E019";
}

:host([icon=print]) .first:before {
  content: "\\E01A";
}

:host([size=s][icon=print]) .first:before {
  content: "\\EF00";
}

:host([icon=radiation]) .first:before {
  content: "\\E01B";
}

:host([icon=redo]) .first:before {
  content: "\\E01C";
}

:host([icon=rotate-2d]) .first:before {
  content: "\\E01D";
}

:host([icon=rotate-3d]) .first:before {
  content: "\\E01E";
}

:host([icon=save]) .first:before {
  content: "\\E01F";
}

:host([icon=save-as]) .first:before {
  content: "\\E020";
}

:host([icon=scanner]) .first:before {
  content: "\\E021";
}

:host([icon=search]) .first:before {
  content: "\\E022";
}

:host([icon=skip-backward]) .first:before {
  content: "\\E024";
}

:host([icon=skip-forward]) .first:before {
  content: "\\E025";
}

:host([icon=snapshot]) .first:before {
  content: "\\E026";
}

:host([size=xs][icon=snapshot]) .first:before {
  content: "\\EEDC";
}

:host([icon=stop]) .first:before {
  content: "\\E027";
}

:host([icon=studies]) .first:before {
  content: "\\E028";
}

:host([icon=text]) .first:before {
  content: "\\E029";
}

:host([icon=undo]) .first:before {
  content: "\\E02A";
}

:host([icon=unlock]) .first:before {
  content: "\\E02B";
}

:host([icon=up]) .first:before,
:host([icon=arrow-face-up-l]) .first:before {
  content: "\\E02C";
}

:host([icon=windowing]) .first:before {
  content: "\\E02D";
}

:host([icon=zoom]) .first:before {
  content: "\\E02E";
}

:host([icon=zoom-minus]) .first:before {
  content: "\\E02F";
}

:host([icon=zoom-plus]) .first:before {
  content: "\\E030";
}

:host([icon=menu]) .first:before {
  content: "\\E035";
}

:host([icon=left-s]) .first:before,
:host([icon=arrow-face-left-s]) .first:before {
  content: "\\E036";
}

:host([icon=right-s]) .first:before,
:host([icon=arrow-face-right-s]) .first:before {
  content: "\\E037";
}

:host([icon=up-s]) .first:before,
:host([icon=arrow-face-up-s]) .first:before {
  content: "\\E038";
}

:host([icon=arrow-down-s]) .first:before,
:host([icon=arrow-line-down-s]) .first:before {
  content: "\\E039";
}

:host([icon=arrow-left-s]) .first:before,
:host([icon=arrow-line-left-s]) .first:before {
  content: "\\E03A";
}

:host([icon=arrow-right-s]) .first:before,
:host([icon=arrow-line-right-s]) .first:before {
  content: "\\E03B";
}

:host([icon=arrow-up-s]) .first:before,
:host([icon=arrow-line-up-s]) .first:before {
  content: "\\E03C";
}

:host([icon=down-s]) .first:before,
:host([icon=arrow-face-down-s]) .first:before {
  content: "\\E03D";
}

:host([icon=chapter]) .first:before {
  content: "\\E03E";
}

:host([icon=document]) .first:before {
  content: "\\E03F";
}

:host([icon=page]) .first:before {
  content: "\\E040";
}

:host([icon=more]) .first:before {
  content: "\\E042";
}

:host([icon=grid-3d]) .first:before {
  content: "\\E043";
}

:host([icon=backward-4d]) .first:before {
  content: "\\E044";
}

:host([icon=data-correct-4d]) .first:before {
  content: "\\E045";
}

:host([icon=data-correct-4d]) .first:after {
  content: "\\EDDE";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=forward-4d]) .first:before {
  content: "\\E046";
}

:host([icon=series-create-4d]) .first:before {
  content: "\\E047";
}

:host([icon=series-create-4d]) .first:after {
  content: "\\EDDD";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=series-split-4d]) .first:before {
  content: "\\E048";
}

:host([icon=series-split-4d]) .first:after {
  content: "\\EDD8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=ablation-catheter-settings]) .first:before {
  content: "\\E049";
}

:host([icon=ablation-catheter-connection-settings]) .first:before {
  content: "\\E04A";
}

:host([icon=acquisition-note]) .first:before {
  content: "\\E04B";
}

:host([icon=acquisition-time]) .first:before {
  content: "\\E04C";
}

:host([icon=acquisition-wizard]) .first:before {
  content: "\\E04D";
}

:host([icon=action-failed]) .first:before {
  content: "\\E04E";
}

:host([icon=add-circle]) .first:before,
:host([icon=circle-add]) .first:before {
  content: "\\E04F";
}

:host([icon=add-line]) .first:before,
:host([icon=line-add]) .first:before {
  content: "\\E050";
}

:host([icon=add-needle]) .first:before,
:host([icon=needle-add]) .first:before {
  content: "\\E051";
}

:host([icon=add-polygon]) .first:before,
:host([icon=polygon-add]) .first:before {
  content: "\\E052";
}

:host([icon=add-rectangle]) .first:before {
  content: "\\E053";
}

:host([icon=add-snapshot]) .first:before,
:host([icon=add-snapshot-to-finding]) .first:before {
  content: "\\E054";
}

:host([icon=adjust-c-arm]) .first:before,
:host([icon=adjust-c-arm-3d]) .first:before {
  content: "\\E057";
}

:host([icon=adjust-gamma-level]) .first:before {
  content: "\\E058";
}

:host([icon=adjust-needle]) .first:before {
  content: "\\E059";
}

:host([icon=adjust-objects]) .first:before {
  content: "\\E05A";
}

:host([icon=adjust-virtual-stent]) .first:before {
  content: "\\E05B";
}

:host([icon=adjustment-volume-hide]) .first:before {
  content: "\\E05C";
}

:host([icon=adjustment-volume-hide]) .first:after {
  content: "\\EDE0";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=airways-measurement]) .first:before {
  content: "\\E05D";
}

:host([icon=airways-measurement]) .first:after {
  content: "\\EDDF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=algorithm-parameters-default]) .first:before {
  content: "\\E05E";
}

:host([icon=anatomical-background]) .first:before {
  content: "\\E05F";
}

:host([icon=angio-view]) .first:before {
  content: "\\E060";
}

:host([icon=annulus-plane]) .first:before {
  content: "\\E061";
}

:host([icon=annulus-plane]) .first:after {
  content: "\\EDEE";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=aorta-reference-region]) .first:before {
  content: "\\E062";
}

:host([icon=aorta-reference-region]) .first:after {
  content: "\\EDED";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=aortic-valve-plane]) .first:before {
  content: "\\E063";
}

:host([icon=aortic-valve-plane]) .first:after {
  content: "\\EDEC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=apply-segment-orientation]) .first:before {
  content: "\\E064";
}

:host([icon=apply-segment-orientation]) .first:after {
  content: "\\EDEB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=arrow-down-2]) .first:before {
  content: "\\E065";
}

:host([icon=arrow-left-2]) .first:before {
  content: "\\E066";
}

:host([icon=arrow-right-2]) .first:before {
  content: "\\E067";
}

:host([icon=arrow-up-2]) .first:before {
  content: "\\E068";
}

:host([icon=auto-iso]) .first:before {
  content: "\\E06A";
}

:host([icon=auto-iso]) .first:after {
  content: "\\EE42";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=auto-map-image]) .first:before {
  content: "\\E06B";
}

:host([icon=auto-map-image]) .first:after {
  content: "\\EE41";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=auto-pixelshift]) .first:before {
  content: "\\E06C";
}

:host([icon=auto-segmentation]) .first:before {
  content: "\\E06D";
}

:host([icon=c-arm]) .first:before {
  content: "\\E06E";
}

:host([icon=auto-tod]) .first:before {
  content: "\\E06F";
}

:host([icon=arrow-down]) .first:before,
:host([icon=arrow-line-down-l]) .first:before {
  content: "\\E070";
}

:host([icon=arrow]) .first:before {
  content: "\\E071";
}

:host([icon=angle]) .first:before {
  content: "\\E072";
}

:host([icon=arrow-up]) .first:before,
:host([icon=arrow-line-up-l]) .first:before {
  content: "\\E073";
}

:host([icon=arrow-right]) .first:before,
:host([icon=arrow-line-right-l]) .first:before {
  content: "\\E074";
}

:host([icon=arrow-left]) .first:before,
:host([icon=arrow-line-left-l]) .first:before {
  content: "\\E075";
}

:host([icon=three-point-mode]) .first:before {
  content: "\\E076";
}

:host([icon=artery-define]) .first:before {
  content: "\\E077";
}

:host([icon=artery-define]) .first:after {
  content: "\\EDEA";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=balance-out-pressure]) .first:before {
  content: "\\E078";
}

:host([icon=battery-1-vertical]) .first:before {
  content: "\\E079";
}

:host([icon=battery-2-vertical]) .first:before {
  content: "\\E07A";
}

:host([icon=battery-3-horizontal]) .first:before {
  content: "\\E07B";
}

:host([icon=battery-3-vertical]) .first:before {
  content: "\\E07C";
}

:host([icon=battery-4-vertical]) .first:before {
  content: "\\E07D";
}

:host([icon=battery-charging]) .first:before,
:host([icon=battery-charging-horizontal]) .first:before {
  content: "\\E07E";
}

:host([icon=battery-empty-horizontal]) .first:before {
  content: "\\E07F";
}

:host([icon=battery-full-horizontal]) .first:before {
  content: "\\E080";
}

:host([icon=battery-full-vertical]) .first:before {
  content: "\\E081";
}

:host([icon=battery-low-horizontal]) .first:before,
:host([icon=battery-1-horizontal]) .first:before {
  content: "\\E082";
}

:host([size=xs][icon=battery-2-horizontal]) .first:before {
  content: "\\ED39";
}

:host([size=xs][icon=battery-3-horizontal]) .first:before {
  content: "\\ED3A";
}

:host([size=xs][icon=battery-4-horizontal]) .first:before {
  content: "\\ED3B";
}

:host([icon=bd-vessel-assign]) .first:before,
:host([icon=bd-vessel-align]) .first:before {
  content: "\\E083";
}

:host([icon=black-box]) .first:before {
  content: "\\E084";
}

:host([icon=blood-lv-edit]) .first:before {
  content: "\\E085";
}

:host([icon=blood-lv-edit]) .first:after {
  content: "\\EE40";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=blood-removal]) .first:before {
  content: "\\E086";
}

:host([icon=blood-removal]) .first:after {
  content: "\\EDE9";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=blood-volume]) .first:before {
  content: "\\E087";
}

:host([icon=blood-volume]) .first:after {
  content: "\\EDE8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bold]) .first:before {
  content: "\\E088";
}

:host([icon=bone-cad-active]) .first:before {
  content: "\\E089";
}

:host([icon=bone-cad-display]) .first:before {
  content: "\\E08A";
}

:host([icon=bone-removal]) .first:before {
  content: "\\E08B";
}

:host([icon=bone-removal]) .first:after {
  content: "\\EDE7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bone-removal-auto]) .first:before {
  content: "\\E08C";
}

:host([icon=bone-removal-auto]) .first:after {
  content: "\\EDE6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bone-removal-body]) .first:before {
  content: "\\E08D";
}

:host([icon=bone-removal-body-de]) .first:before {
  content: "\\E08E";
}

:host([icon=bone-removal-edit]) .first:before {
  content: "\\E08F";
}

:host([icon=bone-removal-edit]) .first:after {
  content: "\\EDE5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bone-removal-head]) .first:before {
  content: "\\E090";
}

:host([icon=bone-removal-head-de]) .first:before {
  content: "\\E091";
}

:host([icon=bone-removal-leg]) .first:before {
  content: "\\E092";
}

:host([icon=bone-removal-structure-isolation]) .first:before {
  content: "\\E093";
}

:host([icon=bone-removal-structure-isolation]) .first:after {
  content: "\\EDE4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bone-removal-tools]) .first:before {
  content: "\\E094";
}

:host([icon=bone-removal-tools]) .first:after {
  content: "\\EDE3";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bones-hide]) .first:before {
  content: "\\E095";
}

:host([icon=bones-hide]) .first:after {
  content: "\\EDE1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bones-show]) .first:before {
  content: "\\E096";
}

:host([icon=bones-show]) .first:after {
  content: "\\EDE2";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bookmark-add]) .first:before {
  content: "\\E097";
}

:host([icon=bookmark-segment]) .first:before {
  content: "\\E098";
}

:host([icon=brain]) .first:before {
  content: "\\E099";
}

:host([icon=breast-biopsy]) .first:before {
  content: "\\E09A";
}

:host([icon=breast-lesion-marker]) .first:before {
  content: "\\E09B";
}

:host([icon=brightness-decrease]) .first:before {
  content: "\\E09C";
}

:host([icon=brightness-increase]) .first:before {
  content: "\\E09D";
}

:host([icon=broad-worklist-query]) .first:before {
  content: "\\E09E";
}

:host([icon=bullets]) .first:before {
  content: "\\E09F";
}

:host([icon=cable-winder]) .first:before {
  content: "\\E0A0";
}

:host([icon=calcium-remove]) .first:before {
  content: "\\E0A1";
}

:host([icon=calcium-remove]) .first:after {
  content: "\\EE33";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=calculation-cancel]) .first:before {
  content: "\\E0A2";
}

:host([icon=caliper-measurement]) .first:before {
  content: "\\E0A3";
}

:host([icon=cardiac-layout-edit]) .first:before {
  content: "\\E0A4";
}

:host([icon=cardiac-layout-edit]) .first:after {
  content: "\\EE3F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cardiac-mask-edit]) .first:before {
  content: "\\E0A5";
}

:host([icon=cardiac-mask-edit]) .first:after {
  content: "\\EE36";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cardiac-output]) .first:before {
  content: "\\E0A6";
}

:host([icon=care-position]) .first:before {
  content: "\\E0A7";
}

:host([icon=care-position]) .first:after {
  content: "\\EE35";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cascoring-show-original]) .first:before {
  content: "\\E0A8";
}

:host([icon=catheter-pullback]) .first:before {
  content: "\\E0A9";
}

:host([icon=cava-calculate]) .first:before {
  content: "\\E0AA";
}

:host([icon=cava-calculate]) .first:after {
  content: "\\EE20";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cava-prepare]) .first:before {
  content: "\\E0AB";
}

:host([icon=cava-prepare]) .first:after {
  content: "\\EE1F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chamber-left]) .first:before {
  content: "\\E0AC";
}

:host([icon=chamber-left]) .first:after {
  content: "\\ECBB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chamber-middle]) .first:before {
  content: "\\E0AD";
}

:host([icon=chamber-middle]) .first:after {
  content: "\\ECBC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chamber-right]) .first:before {
  content: "\\E0AE";
}

:host([icon=chamber-right]) .first:after {
  content: "\\ECBD";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=change-view]) .first:before {
  content: "\\E0AF";
}

:host([icon=change-view]) .first:after {
  content: "\\EE1E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cinematic-vrt]) .first:before {
  content: "\\E0B0";
}

:host([icon=cinematic-vrt]) .first:after {
  content: "\\E9F6";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=cinematic-vrt]) .second:before {
  content: "\\EE31";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=cinematic-vrt]) .second:after {
  content: "\\EE32";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=coil-information]) .first:before {
  content: "\\E0B2";
}

:host([icon=coils-hide]) .first:before {
  content: "\\E0B3";
}

:host([icon=coils-hide]) .first:after {
  content: "\\EE1D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=colon-show-marked]) .first:before {
  content: "\\E0B4";
}

:host([icon=colon-show-marked]) .first:after {
  content: "\\EE1C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=combine]) .first:before,
:host([icon=structure-combine]) .first:before {
  content: "\\E0B6";
}

:host([icon=compare-protocols]) .first:before {
  content: "\\E0B7";
}

:host([icon=compute-maps]) .first:before {
  content: "\\E0B8";
}

:host([icon=configurations-sync]) .first:before,
:host([icon=sync-configurations]) .first:before {
  content: "\\E0B9";
}

:host([icon=contour-copy]) .first:before {
  content: "\\E0BB";
}

:host([icon=contour-delete-all]) .first:before {
  content: "\\E0BC";
}

:host([icon=contour-delete-single]) .first:before {
  content: "\\E0BD";
}

:host([icon=contour-draw-new]) .first:before {
  content: "\\E0BE";
}

:host([icon=contour-edit]) .first:before {
  content: "\\E0BF";
}

:host([icon=contour-edit-slices]) .first:before {
  content: "\\E0C0";
}

:host([icon=contour-preview]) .first:before {
  content: "\\E0C1";
}

:host([icon=contour-preview]) .first:after {
  content: "\\EE15";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=contour-redraw]) .first:before {
  content: "\\E0C2";
}

:host([icon=contour-reflect]) .first:before {
  content: "\\E0C3";
}

:host([icon=contour-threshold]) .first:before {
  content: "\\E0C5";
}

:host([icon=contour-undo]) .first:before {
  content: "\\E0C6";
}

:host([icon=contours-compute]) .first:before {
  content: "\\E0C7";
}

:host([icon=contours-compute]) .first:after {
  content: "\\EE3D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=contour-rv-edit]) .first:before,
:host([icon=contours-edit]) .first:before {
  content: "\\E0C8";
}

:host([icon=contour-rv-edit]) .first:after,
:host([icon=contours-edit]) .first:after {
  content: "\\EE3E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=contours-reset]) .first:before {
  content: "\\E0C9";
}

:host([icon=contours-reset]) .first:after {
  content: "\\EE14";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=contours-show]) .first:before {
  content: "\\E0CA";
}

:host([icon=contours-show]) .first:after {
  content: "\\EE13";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=contrast-agent-administration-applied]) .first:before,
:host([icon=contrast-agent-completed]) .first:before {
  content: "\\E0CB";
}

:host([icon=contrast-decrease]) .first:before {
  content: "\\E0CC";
}

:host([icon=contrast-increase]) .first:before {
  content: "\\E0CD";
}

:host([icon=contrast-optimum]) .first:before {
  content: "\\E0CE";
}

:host([icon=control-panel]) .first:before {
  content: "\\E0CF";
}

:host([icon=convert-to]) .first:before {
  content: "\\E0D0";
}

:host([icon=copy-and-go]) .first:before {
  content: "\\E0D1";
}

:host([icon=copy-image-to-clipboard]) .first:before {
  content: "\\E0D2";
}

:host([icon=copy-paste]) .first:before {
  content: "\\E0D3";
}

:host([icon=copy-reference-new]) .first:before {
  content: "\\E0D4";
}

:host([icon=copy-reference-source]) .first:before {
  content: "\\E0D5";
}

:host([icon=copy-reference-target]) .first:before {
  content: "\\E0D6";
}

:host([icon=coronary-tree]) .first:before {
  content: "\\E0DA";
}

:host([icon=correlated-cursors]) .first:before {
  content: "\\E0DB";
}

:host([icon=create-view-catheter-lab]) .first:before {
  content: "\\E0DC";
}

:host([icon=csi-matrix-interpolated]) .first:before {
  content: "\\E0DD";
}

:host([icon=csi-matrix-scan]) .first:before {
  content: "\\E0DE";
}

:host([icon=ct-laser-zero]) .first:before {
  content: "\\E0DF";
}

:host([icon=ct-laser-zero]) .first:after {
  content: "\\EE12 ";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cut]) .first:before {
  content: "\\E0E0";
}

:host([icon=cutline-3d-draw]) .first:before {
  content: "\\E0E1";
}

:host([icon=down]) .first:before,
:host([icon=arrow-face-down-l]) .first:before {
  content: "\\E0E3";
}

:host([icon=distance-line]) .first:before {
  content: "\\E0E4";
}

:host([icon=delete]) .first:before {
  content: "\\E0E5";
}

:host([icon=checkmark]) .first:before {
  content: "\\E0E6";
}

:host([icon=contrast-administration]) .first:before,
:host([icon=injection]) .first:before {
  content: "\\E0E7";
}

:host([icon=scheduled]) .first:before,
:host([icon=time]) .first:before {
  content: "\\E0E8";
}

:host([icon=patient-scan]) .first:before {
  content: "\\E0E9";
}

:host([icon=reconstruction-running]) .first:before {
  content: "\\E0EA";
}

:host([icon=circle-filled]) .first:before {
  content: "\\E0EB";
}

:host([icon=circle-half]) .first:before {
  content: "\\E0EC";
}

:host([icon=circle-outline]) .first:before {
  content: "\\E0ED";
}

:host([icon=apps]) .first:before {
  content: "\\E0EE";
}

:host([icon=camera]) .first:before,
:host([icon=movie]) .first:before {
  content: "\\E0EF";
}

:host([icon=library]) .first:before {
  content: "\\E0F0";
}

:host([icon=partner-upload]) .first:before,
:host([icon=upload-partner]) .first:before {
  content: "\\E0F1";
}

:host([icon=upload-user]) .first:before {
  content: "\\E0F9";
}

:host([icon=reject]) .first:before {
  content: "\\E0F3";
}

:host([icon=sort-custom]) .first:before {
  content: "\\E0F4";
}

:host([icon=standby]) .first:before {
  content: "\\E0F5";
}

:host([icon=user-avatar]) .first:before {
  content: "\\E0F8";
}

:host([icon=show]) .first:before {
  content: "\\E101";
}

:host([icon=pause-circle]) .first:before {
  content: "\\E121";
}

:host([icon=gantry-tilt-zero]) .first:before {
  content: "\\E127";
}

:host([icon=go-to-console]) .first:before {
  content: "\\E128";
}

:host([icon=table-height]) .first:before {
  content: "\\E12B";
}

:host([icon=table-in]) .first:before {
  content: "\\E12C";
}

:host([icon=table-longitudinal]) .first:before {
  content: "\\E12D";
}

:host([icon=table-out]) .first:before {
  content: "\\E12E";
}

:host([icon=voice-command-record]) .first:before {
  content: "\\E12F";
}

:host([icon=heart-bodypart]) .first:before {
  content: "\\E131";
}

:host([icon=image]) .first:before {
  content: "\\E132";
}

:host([icon=edit-partner]) .first:before,
:host([icon=partner-edit]) .first:before {
  content: "\\E136";
}

:host([icon=edit-user]) .first:before,
:host([icon=user-edit]) .first:before {
  content: "\\E137";
}

:host([size=s][icon=edit-user]) .first:before,
:host([size=s][icon=user-edit]) .first:before {
  content: "\\EF39";
}

:host([icon=logoff]) .first:before {
  content: "\\E138";
}

:host([icon=invert]) .first:before {
  content: "\\E145";
}

:host([icon=image-text-custom]) .first:before {
  content: "\\E141";
}

:host([icon=image-text-configuration]) .first:before {
  content: "\\E140";
}

:host([icon=image-text-full]) .first:before {
  content: "\\E142";
}

:host([icon=rotate-left]) .first:before {
  content: "\\E14B";
}

:host([icon=rotate-right]) .first:before {
  content: "\\E14E";
}

:host([icon=finding-add]) .first:before {
  content: "\\E139";
}

:host([icon=mip]) .first:before {
  content: "\\E152";
}

:host([icon=roi-circle]) .first:before {
  content: "\\E153";
}

:host([icon=roi-ellipsis]) .first:before {
  content: "\\E154";
}

:host([icon=roi-freehand]) .first:before {
  content: "\\E155";
}

:host([icon=roi-polygonal]) .first:before {
  content: "\\E156";
}

:host([icon=roi-rectangle]) .first:before {
  content: "\\E157";
}

:host([icon=scroll]) .first:before {
  content: "\\E15A";
}

:host([icon=vrt]) .first:before {
  content: "\\E158";
}

:host([icon=vrt]) .first:after {
  content: "\\EEDF";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=vrt]) .second:before {
  content: "\\EEE0";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=vrt]) .second:after {
  content: "\\EEE1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=delete-number]) .first:before {
  content: "\\E901";
}

:host([icon=compass-direction]) .first:before {
  content: "\\E900";
}

:host([icon=speakers-off]) .first:before {
  content: "\\E904";
}

:host([icon=speakers-on]) .first:before {
  content: "\\E905";
}

:host([icon=workstation-free-fd]) .first:before {
  content: "\\E933";
}

:host([icon=timer]) .first:before {
  content: "\\E92f";
}

:host([icon=switch-monitor]) .first:before {
  content: "\\E92e";
}

:host([icon=signal-strength-5]) .first:before {
  content: "\\E92C";
}

:host([icon=signal-strength-0]) .first:before {
  content: "\\E92C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=show-all-monitors]) .first:before {
  content: "\\E923";
}

:host([icon=reset]) .first:before {
  content: "\\E91E";
}

:host([icon=patient-size-small]) .first:before {
  content: "\\E91B";
}

:host([icon=patient-size-large]) .first:before {
  content: "\\E91A";
}

:host([icon=open-chat]) .first:before {
  content: "\\E919";
}

:host([icon=focus-small]) .first:before {
  content: "\\E914";
}

:host([icon=focus-big]) .first:before {
  content: "\\E913";
}

:host([icon=fluoroscopy-control]) .first:before {
  content: "\\E912";
}

:host([icon=exposure-time-increase]) .first:before {
  content: "\\E911";
}

:host([icon=exposure-time-decrease]) .first:before {
  content: "\\E910";
}

:host([icon=expert-i]) .first:before {
  content: "\\E90F";
}

:host([icon=dual-monitor-mode]) .first:before {
  content: "\\E90E";
}

:host([icon=channel-3]) .first:before {
  content: "\\E909";
}

:host([icon=channel-2]) .first:before {
  content: "\\E908";
}

:host([icon=channel-1]) .first:before {
  content: "\\E907";
}

:host([icon=send-message]) .first:before {
  content: "\\E906";
}

:host([icon=like]) .first:before {
  content: "\\E93D";
}

:host([icon=dislike]) .first:before {
  content: "\\E939";
}

:host([icon=double-arrow-face-up-s]) .first:before {
  content: "\\E941";
}

:host([icon=double-arrow-face-down-s]) .first:before {
  content: "\\E93E";
}

:host([icon=double-arrow-face-left-s]) .first:before {
  content: "\\E93F";
}

:host([icon=double-arrow-face-right-s]) .first:before {
  content: "\\E940";
}

:host([icon=sync-on]) .first:before {
  content: "\\E94B";
}

:host([icon=share]) .first:before {
  content: "\\E948";
}

:host([icon=laser]) .first:before {
  content: "\\E943";
}

:host([icon=monitors]) .first:before,
:host([icon=monitor]) .first:before {
  content: "\\E942";
}

:host([icon=positive-negative]) .first:before {
  content: "\\E94E";
}

:host([icon=connected-tablet-0]) .first:before {
  content: "\\E95C";
}

:host([icon=connected-tablet-1]) .first:before {
  content: "\\E95D";
}

:host([icon=connected-tablet-2]) .first:before {
  content: "\\E95E";
}

:host([icon=connected-tablet-3]) .first:before {
  content: "\\E95F";
}

:host([icon=connected-tablet-4]) .first:before {
  content: "\\E960";
}

:host([icon=connected-tablet-5]) .first:before {
  content: "\\E961";
}

:host([icon=rating]) .first:before {
  content: "\\E922";
}

:host([icon=delivery]) .first:before {
  content: "\\E962";
}

:host([icon=envelope]) .first:before {
  content: "\\E963";
}

:host([size=s][icon=envelope]) .first:before {
  content: "\\EF2C";
}

:host([icon=eraser]) .first:before {
  content: "\\E964";
}

:host([icon=financial-value]) .first:before {
  content: "\\E965";
}

:host([icon=image-quality-check]) .first:before {
  content: "\\E966";
}

:host([icon=refresh]) .first:before {
  content: "\\E96F";
}

:host([size=s][icon=refresh]) .first:before {
  content: "\\EF35";
}

:host([icon=scanner-settings]) .first:before {
  content: "\\E970";
}

:host([icon=series]) .first:before {
  content: "\\E971";
}

:host([icon=upload]) .first:before {
  content: "\\E974";
}

:host([icon=colon-solid-view]) .first:before {
  content: "\\E975";
}

:host([icon=drug-pharmaceutical]) .first:before,
:host([icon=medications]) .first:before {
  content: "\\E976";
}

:host([icon=liver]) .first:before {
  content: "\\E979";
}

:host([icon=mark-left]) .first:before {
  content: "\\E97C";
}

:host([icon=mark-right]) .first:before {
  content: "\\E97D";
}

:host([icon=surgery]) .first:before {
  content: "\\E97E";
}

:host([icon=blood-pressure]) .first:before {
  content: "\\E98E";
}

:host([icon=dashboard]) .first:before {
  content: "\\E9A2";
}

:host([icon=doctor-visit]) .first:before {
  content: "\\E9A6";
}

:host([icon=feedback-add]) .first:before {
  content: "\\E9A5";
}

:host([icon=group-series-configuration]) .first:before {
  content: "\\E9A4";
}

:host([icon=heart-chart]) .first:before {
  content: "\\E9A3";
}

:host([icon=kitmed-report]) .first:before {
  content: "\\E9AB";
}

:host([icon=locum-circles]) .first:before {
  content: "\\E9AC";
}

:host([icon=note-add]) .first:before {
  content: "\\E9B9";
}

:host([icon=observations]) .first:before {
  content: "\\E9B8";
}

:host([icon=open-tasks-add]) .first:before {
  content: "\\E9B7";
}

:host([icon=patient-insurance]) .first:before {
  content: "\\E9B6";
}

:host([icon=patient-measurement]) .first:before {
  content: "\\E9B5";
}

:host([icon=result-group-configuration]) .first:before {
  content: "\\E9B4";
}

:host([icon=studies-medical]) .first:before {
  content: "\\E9B3";
}

:host([icon=web-data-entry]) .first:before {
  content: "\\E9B2";
}

:host([icon=weight-measurement]) .first:before {
  content: "\\E9B1";
}

:host([icon=change-password]) .first:before {
  content: "\\E9BC";
}

:host([icon=comment]) .first:before {
  content: "\\E9CA";
}

:host([icon=kitpro]) .first:before {
  content: "\\E9C9";
}

:host([icon=histogram]) .first:before {
  content: "\\E9C8";
}

:host([icon=fine-tuning]) .first:before {
  content: "\\E9C7";
}

:host([icon=download]) .first:before {
  content: "\\E9C6";
}

:host([icon=wellbeing-good]) .first:before,
:host([icon=good]) .first:before {
  content: "\\E9C3";
}

:host([icon=trending-graph]) .first:before {
  content: "\\E9C2";
}

:host([icon=report-new]) .first:before {
  content: "\\E9C1";
}

:host([icon=report]) .first:before {
  content: "\\E9C0";
}

:host([icon=patient-add]) .first:before {
  content: "\\E9BF";
}

:host([icon=open-tasks]) .first:before {
  content: "\\E9BE";
}

:host([icon=message-history]) .first:before {
  content: "\\E9BD";
}

:host([icon=fullscreen]) .first:before {
  content: "\\E9CE";
}

:host([icon=exit-fullscreen]) .first:before {
  content: "\\E9CD";
}

:host([icon=alpha-blending]) .first:before {
  content: "\\E9F2";
}

:host([icon=central-library]) .first:before {
  content: "\\E9F1";
}

:host([icon=cloud-download]) .first:before {
  content: "\\E9F0";
}

:host([icon=cloud-upload]) .first:before {
  content: "\\E9EE";
}

:host([icon=factory-default]) .first:before {
  content: "\\E9EA";
}

:host([icon=group-chat]) .first:before {
  content: "\\E9E7";
}

:host([icon=manual-phasing]) .first:before {
  content: "\\E9DF";
}

:host([icon=music-via-headphones]) .first:before {
  content: "\\E9DE";
}

:host([icon=music-via-speakers]) .first:before {
  content: "\\E9DD";
}

:host([icon=nerve-stimulation]) .first:before {
  content: "\\E9DC";
}

:host([icon=not-tablet-approved]) .first:before {
  content: "\\E9DB";
}

:host([icon=qr-code]) .first:before {
  content: "\\E9D6";
}

:host([icon=report-template-edit]) .first:before {
  content: "\\E9CF";
}

:host([icon=report-template-assignment]) .first:before {
  content: "\\E9D0";
}

:host([icon=rt-laser-switch]) .first:before {
  content: "\\E98B";
}

:host([icon=rt-laser-zero]) .first:before {
  content: "\\E991";
}

:host([icon=sar-simulation-limit-exceeded]) .first:before,
:host([icon=sar-stimulation-limit-exceeded]) .first:before {
  content: "\\E994";
}

:host([icon=spine]) .first:before {
  content: "\\E9FA";
}

:host([icon=user]) .first:before {
  content: "\\E9F9";
}

:host([size=s][icon=user]) .first:before {
  content: "\\EF36";
}

:host([icon=ventilation]) .first:before {
  content: "\\EF65";
}

:host([icon=workflow-settings-active-mode]) .first:before {
  content: "\\E9F5";
}

:host([icon=workflow-settings-inactive-mode]) .first:before {
  content: "\\E9F4";
}

:host([icon=workflow-switch]) .first:before {
  content: "\\E9F3";
}

:host([icon=desktop-sharing]) .first:before {
  content: "\\EA08";
}

:host([icon=maximize]) .first:before {
  content: "\\EA04";
}

:host([icon=minimize]) .first:before {
  content: "\\EA03";
}

:host([icon=restore]) .first:before {
  content: "\\EA01";
}

:host([icon=quick-guidance]) .first:before {
  content: "\\EA02";
}

:host([icon=workstation]) .first:before {
  content: "\\E9FF";
}

:host([icon=zoom-pan]) .first:before {
  content: "\\EA0F";
}

:host([icon=dicom-data-not-included]) .first:before {
  content: "\\EA22";
}

:host([size=s][icon=dicom-data-not-included]) .first:before {
  content: "\\EF15";
}

:host([icon=image-export]) .first:before {
  content: "\\EA3C";
}

:host([icon=job-view]) .first:before {
  content: "\\EA3E";
}

:host([icon=patient-reject]) .first:before {
  content: "\\EA3F";
}

:host([icon=store-snapshot-segment]) .first:before {
  content: "\\EA40";
}

:host([icon=distance-ratio]) .first:before {
  content: "\\EA46";
}

:host([icon=injection-ready]) .first:before {
  content: "\\EA49";
}

:host([icon=pause-add]) .first:before {
  content: "\\EA53";
}

:host([icon=pause-remove]) .first:before {
  content: "\\EA54";
}

:host([icon=location-pin]) .first:before {
  content: "\\EA5B";
}

:host([icon=procedure-add]) .first:before {
  content: "\\EA5C";
}

:host([icon=structured-report]) .first:before {
  content: "\\EA5D";
}

:host([icon=study-pass]) .first:before {
  content: "\\EA5E";
}

:host([icon=blood-glucose]) .first:before {
  content: "\\EA61";
}

:host([icon=camera-enable]) .first:before {
  content: "\\EA62";
}

:host([icon=camera-swap]) .first:before {
  content: "\\EA63";
}

:host([icon=connect-to-hospital]) .first:before {
  content: "\\EA64";
}

:host([icon=hang-up-call]) .first:before {
  content: "\\EA65";
}

:host([icon=hospital-add]) .first:before {
  content: "\\EA66";
}

:host([icon=hospital]) .first:before {
  content: "\\EA67";
}

:host([icon=manage-message-templates]) .first:before {
  content: "\\EA68";
}

:host([icon=microphone-enable]) .first:before {
  content: "\\EA69";
}

:host([icon=phone]) .first:before {
  content: "\\EA6A";
}

:host([icon=questionnaire-symptom]) .first:before {
  content: "\\EA6B";
}

:host([icon=start-video-call]) .first:before {
  content: "\\EA6C";
}

:host([icon=step-count]) .first:before {
  content: "\\EA6D";
}

:host([icon=thermometer]) .first:before {
  content: "\\EA6E";
}

:host([icon=video-call-accept]) .first:before {
  content: "\\EA6F";
}

:host([icon=active-directory]) .first:before {
  content: "\\EA70";
}

:host([icon=brightness]) .first:before {
  content: "\\EA71";
}

:host([icon=delete-all]) .first:before {
  content: "\\EA78";
}

:host([icon=favorite-off]) .first:before {
  content: "\\EA7d";
}

:host([icon=image-forward]) .first:before {
  content: "\\EA7e";
}

:host([icon=virtual-collimation]) .first:before {
  content: "\\EA8b";
}

:host([icon=windowing-presets]) .first:before {
  content: "\\EA8c";
}

:host([icon=software-update]) .first:before {
  content: "\\EA7A";
}

:host([icon=cloud]) .first:before {
  content: "\\EA9B";
}

:host([icon=region-language]) .first:before {
  content: "\\EA9C";
}

:host([icon=image-metadata-edit]) .first:before {
  content: "\\EA9E";
}

:host([icon=attention-chat]) .first:before {
  content: "\\EAA3";
}

:host([icon=document-raw]) .first:before {
  content: "\\EAA2";
}

:host([icon=image-quality-assesment]) .first:before {
  content: "\\EAA1";
}

:host([icon=parked-view]) .first:before {
  content: "\\EAA8";
}

:host([icon=patient-history-studies]) .first:before {
  content: "\\EAA7";
}

:host([size=s][icon=patient-history-studies]) .first:before {
  content: "\\EED0";
}

:host([icon=dicom]) .first:before {
  content: "\\EAA9";
}

:host([icon=next-scene]) .first:before {
  content: "\\EAAA";
}

:host([icon=previous-scene]) .first:before {
  content: "\\EAAC";
}

:host([icon=parked-view-gallery]) .first:before {
  content: "\\EAAB";
}

:host([icon=demonstrate-visuals]) .first:before {
  content: "\\EAAD";
}

:host([icon=recording]) .first:before {
  content: "\\EAB2";
}

:host([icon=table-target-home]) .first:before {
  content: "\\EABA";
}

:host([icon=table-target-iso-center]) .first:before {
  content: "\\EABB";
}

:host([icon=export-to-xls]) .first:before {
  content: "\\EABD";
}

:host([icon=subitem-add]) .first:before {
  content: "\\EAC1";
}

:host([icon=user-group-edit]) .first:before {
  content: "\\EAC2";
}

:host([icon=image-flavor]) .first:before {
  content: "\\EAC0";
}

:host([icon=waterproof]) .first:before {
  content: "\\EAC3";
}

:host([icon=attachment]) .first:before {
  content: "\\EAC8";
}

:host([icon=command-prompt]) .first:before {
  content: "\\EAC9";
}

:host([icon=comment-add]) .first:before {
  content: "\\EACC";
}

:host([icon=dicom-configuration]) .first:before {
  content: "\\EACD";
}

:host([icon=dicom-search]) .first:before {
  content: "\\EACE";
}

:host([icon=filter-cancel]) .first:before {
  content: "\\EACF";
}

:host([icon=laboratory-results-edit]) .first:before {
  content: "\\EAD0";
}

:host([icon=laboratory-results-search]) .first:before {
  content: "\\EAD1";
}

:host([icon=laboratory-results]) .first:before {
  content: "\\EAD2";
}

:host([icon=laboratory-system]) .first:before {
  content: "\\EAD3";
}

:host([icon=log]) .first:before {
  content: "\\EAD4";
}

:host([icon=order-add]) .first:before {
  content: "\\EAD5";
}

:host([icon=order]) .first:before {
  content: "\\EAD8";
}

:host([icon=resize-tile-to-large]) .first:before {
  content: "\\EAD9";
}

:host([icon=resize-tile-to-small]) .first:before {
  content: "\\EADA";
}

:host([icon=translators-connection]) .first:before {
  content: "\\EADB";
}

:host([icon=wizard]) .first:before {
  content: "\\EADC";
}

:host([icon=layout-1x2]) .first:before {
  content: "\\EAE9";
}

:host([icon=private]) .first:before {
  content: "\\EAE6";
}

:host([icon=battery-4-horizontal]) .first:before {
  content: "\\EAEE";
}

:host([icon=battery-2-horizontal]) .first:before {
  content: "\\EAED";
}

:host([icon=bar-code]) .first:before {
  content: "\\EAEC";
}

:host([icon=apple]) .first:before {
  content: "\\EAEB";
}

:host([icon=apple-eaten]) .first:before {
  content: "\\EAEA";
}

:host([icon=remote-service-connected]) .first:before {
  content: "\\EAF3";
}

:host([icon=patient-open-tasks]) .first:before {
  content: "\\EAF2";
}

:host([icon=monitor-settings]) .first:before {
  content: "\\EAF1";
}

:host([icon=keyboard]) .first:before {
  content: "\\EAF0";
}

:host([icon=duration]) .first:before {
  content: "\\EAEF";
}

:host([icon=move-mask]) .first:before {
  content: "\\EAFC";
}

:host([icon=cr-cassette]) .first:before {
  content: "\\EAFB";
}

:host([icon=annotations]) .first:before {
  content: "\\EAFA";
}

:host([icon=annotations-edit]) .first:before {
  content: "\\EAF9";
}

:host([icon=annotations-delete]) .first:before {
  content: "\\EAF8";
}

:host([icon=calendar-monthly]) .first:before {
  content: "\\EB2C";
}

:host([icon=calendar-weekly]) .first:before {
  content: "\\EB2D";
}

:host([icon=toolbar-accept-reject]) .first:before {
  content: "\\EB34";
}

:host([icon=step-variants]) .first:before {
  content: "\\EB33";
}

:host([icon=program-template]) .first:before {
  content: "\\EB32";
}

:host([icon=program]) .first:before {
  content: "\\EB31";
}

:host([icon=create-new-step-variants]) .first:before {
  content: "\\EB30";
}

:host([icon=create-new-program-templates]) .first:before {
  content: "\\EB2F";
}

:host([icon=create-new-program]) .first:before {
  content: "\\EB2E";
}

:host([icon=patient-list]) .first:before {
  content: "\\EB3E";
}

:host([icon=layout-split-view]) .first:before {
  content: "\\EB3D";
}

:host([icon=layout-edit]) .first:before {
  content: "\\EB3C";
}

:host([icon=layout-5x4]) .first:before {
  content: "\\EB3B";
}

:host([icon=layout-4x3]) .first:before {
  content: "\\EB3A";
}

:host([icon=layout-3x3]) .first:before {
  content: "\\EB39";
}

:host([icon=layout-3x1]) .first:before {
  content: "\\EB38";
}

:host([icon=image-enlarge]) .first:before {
  content: "\\EB37";
}

:host([icon=export-to-csv]) .first:before {
  content: "\\EB36";
}

:host([icon=dicom-reset]) .first:before {
  content: "\\EB35";
}

:host([icon=shopping-cart-add]) .first:before {
  content: "\\EB41";
}

:host([icon=sync-off]) .first:before {
  content: "\\EB40";
}

:host([icon=dice]) .first:before {
  content: "\\EB06";
}

:host([icon=edge-enhancement-dicom]) .first:before {
  content: "\\EB05";
}

:host([icon=edge-enhancement-high]) .first:before {
  content: "\\EB04";
}

:host([icon=edge-enhancement-off]) .first:before {
  content: "\\EAFF";
}

:host([icon=expand-collapse]) .first:before {
  content: "\\EAFE";
}

:host([icon=handcontrol]) .first:before {
  content: "\\EB0B";
}

:host([icon=image-enhancement]) .first:before {
  content: "\\EB0A";
}

:host([icon=image-enhancement-advanced]) .first:before {
  content: "\\EB09";
}

:host([icon=match-settings]) .first:before {
  content: "\\EB1A";
}

:host([icon=patient-orientation-switch]) .first:before {
  content: "\\EB29";
}

:host([icon=radiation-high]) .first:before {
  content: "\\EB28";
}

:host([icon=repeat]) .first:before {
  content: "\\EB22";
}

:host([icon=report-template-search]) .first:before {
  content: "\\EB21";
}

:host([icon=technician-access]) .first:before {
  content: "\\EB20";
}

:host([icon=pain-scale]) .first:before {
  content: "\\EB2B";
}

:host([icon=patient-management]) .first:before {
  content: "\\EB48";
}

:host([icon=collision]) .first:before {
  content: "\\EB47";
}

:host([icon=browser]) .first:before {
  content: "\\EB46";
}

:host([icon=speaker-enable]) .first:before {
  content: "\\EB50";
}

:host([icon=user-group]) .first:before {
  content: "\\EB51";
}

:host([icon=drive]) .first:before {
  content: "\\EB65";
}

:host([icon=editing-rights]) .first:before {
  content: "\\EB62";
}

:host([icon=mammography-system]) .first:before {
  content: "\\EB61";
}

:host([icon=merge]) .first:before {
  content: "\\EB60";
}

:host([icon=metal-artifact]) .first:before {
  content: "\\EB5F";
}

:host([icon=pat-factor-low]) .first:before {
  content: "\\EB5E";
}

:host([icon=pat-factor-medium]) .first:before {
  content: "\\EB5D";
}

:host([icon=patient-copy-to]) .first:before {
  content: "\\EB5C";
}

:host([icon=patient-move-to]) .first:before {
  content: "\\EB5B";
}

:host([icon=rescue-brake-release]) .first:before {
  content: "\\EB59";
}

:host([icon=sending-job]) .first:before {
  content: "\\EB56";
}

:host([icon=split-horizontally]) .first:before {
  content: "\\EB68";
}

:host([icon=split-vertically]) .first:before {
  content: "\\EB67";
}

:host([icon=stress-layout-edit]) .first:before {
  content: "\\EB66";
}

:host([icon=stress-layout-reset]) .first:before {
  content: "\\EB6F";
}

:host([icon=stress-layout-show]) .first:before {
  content: "\\EB6E";
}

:host([icon=study-merge]) .first:before {
  content: "\\EB6D";
}

:host([icon=study-split]) .first:before {
  content: "\\EB6C";
}

:host([icon=unread]) .first:before {
  content: "\\EB6B";
}

:host([icon=viewing-rights]) .first:before {
  content: "\\EB6A";
}

:host([icon=advanced]) .first:before {
  content: "\\EB72";
}

:host([icon=battery-charging-vertical]) .first:before {
  content: "\\EB73";
}

:host([icon=battery-empty-vertical]) .first:before {
  content: "\\EB74";
}

:host([icon=c-arm-park-position]) .first:before {
  content: "\\EB75";
}

:host([icon=c-arm-save-position]) .first:before {
  content: "\\EB76";
}

:host([icon=c-arm-table]) .first:before {
  content: "\\EB77";
}

:host([icon=distance-calibration]) .first:before {
  content: "\\EB7C";
}

:host([icon=radiation-filter]) .first:before {
  content: "\\EB89";
}

:host([icon=sync-zoom-pan]) .first:before {
  content: "\\EB87";
}

:host([icon=sync-across-timepoints]) .first:before {
  content: "\\EB85";
}

:host([icon=sync-scroll]) .first:before {
  content: "\\EB86";
}

:host([icon=wifi]) .first:before {
  content: "\\EB84";
}

:host([icon=sign-in]) .first:before {
  content: "\\EB8B";
}

:host([icon=sign-out]) .first:before {
  content: "\\EB8C";
}

:host([icon=multiple-patients]) .first:before {
  content: "\\EB7F";
}

:host([icon=inr-medication-quarter-dose]) .first:before,
:host([icon=medication-quarter-dose]) .first:before {
  content: "\\EB8D";
}

:host([icon=rotate-to-0]) .first:before {
  content: "\\EBA5";
}

:host([icon=note-share]) .first:before {
  content: "\\EBA4";
}

:host([icon=metal-artifact-labeling]) .first:before {
  content: "\\EBA3";
}

:host([icon=import-from-usb-stick]) .first:before {
  content: "\\EBA2";
}

:host([icon=import-from-network]) .first:before {
  content: "\\EBA1";
}

:host([icon=export-to-usb-stick]) .first:before {
  content: "\\EBA0";
}

:host([icon=export-to-network]) .first:before {
  content: "\\EB9F";
}

:host([icon=export-to-dvd]) .first:before {
  content: "\\EB9E";
}

:host([icon=contrast]) .first:before {
  content: "\\EB9D";
}

:host([icon=utilities]) .first:before {
  content: "\\EBB2";
}

:host([icon=studies-medical-edit]) .first:before {
  content: "\\EBB1";
}

:host([icon=studies-medical-duplicate]) .first:before {
  content: "\\EBB0";
}

:host([icon=open-tasks-duplicate]) .first:before {
  content: "\\EBAF";
}

:host([icon=ethernet]) .first:before {
  content: "\\EBAE";
}

:host([icon=add-to-list]) .first:before {
  content: "\\EBD8";
}

:host([icon=bar-code-scanner]) .first:before {
  content: "\\EBD7";
}

:host([icon=footswitch-battery-2]) .first:before {
  content: "\\EBD2";
}

:host([icon=footswitch-battery-3]) .first:before {
  content: "\\EBD1";
}

:host([icon=footswitch-battery-4]) .first:before {
  content: "\\EBD0";
}

:host([icon=footswitch-battery-full]) .first:before {
  content: "\\EBCE";
}

:host([icon=handcontrol-battery-2]) .first:before {
  content: "\\EBCC";
}

:host([icon=handcontrol-battery-3]) .first:before {
  content: "\\EBCB";
}

:host([icon=handcontrol-battery-4]) .first:before {
  content: "\\EBCA";
}

:host([icon=handcontrol-battery-full]) .first:before {
  content: "\\EBC9";
}

:host([icon=injection-pump]) .first:before {
  content: "\\EBC8";
}

:host([icon=laboratory-system-scheduler]) .first:before {
  content: "\\EBC7";
}

:host([icon=open-tasks-quality]) .first:before {
  content: "\\EBC6";
}

:host([icon=remote-desktop]) .first:before {
  content: "\\EBC5";
}

:host([icon=remote-desktop-cancel]) .first:before {
  content: "\\EBC3";
}

:host([icon=utility-chart]) .first:before,
:host([icon=utility-cart]) .first:before {
  content: "\\EBBB";
}

:host([icon=window]) .first:before {
  content: "\\EBBA";
}

:host([icon=hematology]) .first:before {
  content: "\\EBDC";
}

:host([icon=copy-reference-target-table]) .first:before {
  content: "\\EBDB";
}

:host([icon=copy-reference-source-table]) .first:before {
  content: "\\EBDA";
}

:host([icon=touch-ui-home]) .first:before {
  content: "\\EBDE";
}

:host([icon=batch-orders]) .first:before {
  content: "\\EBED";
}

:host([icon=body-registration-anterior-posterior]) .first:before {
  content: "\\EBEC";
}

:host([icon=body-registration-lateral]) .first:before {
  content: "\\EBEB";
}

:host([icon=body-registration-superior-inferior]) .first:before {
  content: "\\EBEA";
}

:host([icon=cold-storage]) .first:before {
  content: "\\EBE9";
}

:host([icon=document-format]) .first:before {
  content: "\\EBE8";
}

:host([icon=document-scanner]) .first:before {
  content: "\\EBE7";
}

:host([icon=notification-off]) .first:before {
  content: "\\EBE3";
}

:host([icon=workflow-guided]) .first:before {
  content: "\\EBE2";
}

:host([icon=door-closed]) .first:before {
  content: "\\EBF2";
}

:host([icon=door-open]) .first:before {
  content: "\\EBF3";
}

:host([icon=reference-image-lock]) .first:before {
  content: "\\EBF8";
}

:host([icon=transfer-to-reference]) .first:before {
  content: "\\EBFB";
}

:host([icon=multiselect]) .first:before {
  content: "\\EBF6";
}

:host([icon=media-library]) .first:before {
  content: "\\EBF5";
}

:host([icon=rotate-text]) .first:before {
  content: "\\EBF9";
}

:host([icon=community]) .first:before {
  content: "\\EBFC";
}

:host([icon=study-forward]) .first:before {
  content: "\\EBFD";
}

:host([icon=detector-big]) .first:before {
  content: "\\EBFF";
}

:host([icon=detector-big-handle]) .first:before {
  content: "\\EBFE";
}

:host([icon=detector-small]) .first:before {
  content: "\\EC00";
}

:host([icon=detector-standard]) .first:before {
  content: "\\EC02";
}

:host([icon=detector-standard-handle]) .first:before {
  content: "\\EC01";
}

:host([icon=document-margins]) .first:before {
  content: "\\EC03";
}

:host([icon=highlighter]) .first:before {
  content: "\\EC0D";
}

:host([icon=image-save]) .first:before {
  content: "\\EC0E";
}

:host([icon=series-save]) .first:before {
  content: "\\EC12";
}

:host([icon=myocardial-map-t1]) .first:before {
  content: "\\EC0F";
}

:host([icon=myocardial-map-t2]) .first:before {
  content: "\\EC11";
}

:host([icon=myocardial-map-t2-star]) .first:before {
  content: "\\EC10";
}

:host([icon=speaker-bluetooth]) .first:before {
  content: "\\EC13";
}

:host([icon=study-received]) .first:before {
  content: "\\EC15";
}

:host([icon=table-view-switch]) .first:before {
  content: "\\EC16";
}

:host([icon=sort-ascending]) .first:before {
  content: "\\EC1E";
}

:host([icon=sort-descending]) .first:before {
  content: "\\EC1F";
}

:host([icon=resize-segments]) .first:before {
  content: "\\EC20";
}

:host([icon=pin]) .first:before {
  content: "\\EC24";
}

:host([icon=unpin]) .first:before {
  content: "\\EC25";
}

:host([icon=patient-orientation-define]) .first:before {
  content: "\\EC23";
}

:host([icon=monitoring-viewer]) .first:before {
  content: "\\EC22";
}

:host([icon=layout-grid]) .first:before {
  content: "\\EC21";
}

:host([icon=tree-map]) .first:before {
  content: "\\EC35";
}

:host([icon=scatter-plot]) .first:before {
  content: "\\EC34";
}

:host([icon=pivot-table]) .first:before {
  content: "\\EC33";
}

:host([icon=pie-chart]) .first:before {
  content: "\\EC32";
}

:host([icon=kpi]) .first:before {
  content: "\\EC30";
}

:host([icon=thumbnail-viewmode]) .first:before {
  content: "\\EC4B";
}

:host([icon=preview-mode]) .first:before {
  content: "\\EC38";
}

:host([icon=cp-mode]) .first:before {
  content: "\\EC31";
}

:host([icon=range-tools]) .first:before {
  content: "\\EC48";
}

:host([icon=mr-conditional]) .first:before {
  content: "\\EC37";
}

:host([icon=pacs]) .first:before {
  content: "\\EC4E";
}

:host([icon=kvm-switch]) .first:before {
  content: "\\EC4D";
}

:host([icon=weight]) .first:before {
  content: "\\EC4F";
}

:host([icon=scheduled-autofill]) .first:before {
  content: "\\EC52";
}

:host([icon=other-devices]) .first:before {
  content: "\\EC51";
}

:host([icon=image-not-available]) .first:before {
  content: "\\EA7f";
}

:host(:not([color])[icon=image-not-available]) .first:after {
  content: "\\E996";
  color: rgba(var(--functional-red));
}

:host(:not([color])[icon=image-not-available]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([icon=dicom-archivable]) .first:before {
  content: "\\EA1A";
}

:host([size=s][icon=dicom-archivable]) .first:before {
  content: "\\EF14";
}

:host([size=xs][icon=dicom-archivable]) .first:before {
  content: "\\EA19";
}

:host([icon=organizer-tree]) .first:before {
  content: "\\EB81";
}

:host([size=xs][icon=organizer-tree]) .first:before {
  content: "\\EB80";
}

:host([icon=marked]) .first:before {
  content: "\\EAE8";
}

:host([size=xs][icon=marked]) .first:before {
  content: "\\EAE7";
}

:host([icon=analytics]) .first:before {
  content: "\\EA48";
}

:host([size=xs][icon=analytics]) .first:before {
  content: "\\EA47";
}

:host([icon=validate-all]) .first:before {
  content: "\\EAF5";
}

:host([size=s][icon=validate-all]) .first:before {
  content: "\\EAF4";
}

:host([icon=shopping-cart]) .first:before {
  content: "\\EAF7";
}

:host([size=xs][icon=shopping-cart]) .first:before {
  content: "\\EAF6";
}

:host([icon=open-link]) .first:before {
  content: "\\EAB7";
}

:host([size=xs][icon=open-link]) .first:before {
  content: "\\EAB5";
}

:host([size=s][icon=open-link]) .first:before {
  content: "\\EAB6";
}

:host([icon=settings]) .first:before {
  content: "\\E023";
}

:host([size=s][icon=settings]) .first:before {
  content: "\\EBFA";
}

:host([icon=notification-center]) .first:before {
  content: "\\E135";
}

:host([size=s][icon=notification-center]) .first:before {
  content: "\\EBF7";
}

:host([icon=dragable]) .first:before,
:host([icon=draggable]) .first:before,
:host([icon=draggable-vertical-s]) .first:before {
  content: "\\EA7C";
}

:host([icon=draggable-horizontal-s]) .first:before {
  content: "\\EC1B";
}

:host([size=s][icon=draggable-vertical-s]) .first:before {
  content: "\\EC06";
}

:host([size=xs][icon=draggable-vertical-s]) .first:before {
  content: "\\EC19";
}

:host([size=xs][icon=draggable-horizontal-s]) .first:before {
  content: "\\EC18";
}

:host([size=s][icon=draggable-horizontal-s]) .first:before {
  content: "\\EC1A";
}

:host([size=l][icon=draggable-horizontal-s]) .first:before {
  content: "\\EC1B";
}

:host([icon=draggable-horizontal-l]) .first:before {
  content: "\\EC05";
}

:host([size=xs][icon=draggable-horizontal-l]) .first:before {
  content: "\\EC05";
}

:host([size=s][icon=draggable-horizontal-l]) .first:before {
  content: "\\EC04";
}

:host([size=l][icon=draggable-horizontal-l]) .first:before {
  content: "\\EC04";
}

:host([icon=bookmark]) .first:before {
  content: "\\EBF1";
}

:host([size=xs][icon=bookmark]) .first:before {
  content: "\\EBEF";
}

:host([size=s][icon=bookmark]) .first:before {
  content: "\\EBF0";
}

:host([size=l][icon=bookmark]) .first:before {
  content: "\\EBF0";
}

:host([icon=help]) .first:before {
  content: "\\E007";
}

:host([size=xs][icon=help]) .first:before {
  content: "\\EA9D";
}

:host([size=s][icon=help]) .first:before {
  content: "\\EBF4";
}

:host([icon=standard-privacy]) .first:before {
  content: "\\EA3B";
}

:host([size=xs][icon=standard-privacy]) .first:before {
  content: "\\EA39";
}

:host([size=s][icon=standard-privacy]) .first:before {
  content: "\\EA3A";
}

:host([size=l][icon=standard-privacy]) .first:before {
  content: "\\EA3A";
}

:host([icon=restrictive-privacy]) .first:before {
  content: "\\EA38";
}

:host([size=xs][icon=restrictive-privacy]) .first:before {
  content: "\\EA36";
}

:host([size=s][icon=restrictive-privacy]) .first:before {
  content: "\\EA37";
}

:host([size=l][icon=restrictive-privacy]) .first:before {
  content: "\\EA37";
}

:host([icon=no-data-minimization]) .first:before {
  content: "\\EA35";
}

:host([size=xs][icon=no-data-minimization]) .first:before {
  content: "\\EA33";
}

:host([size=s][icon=no-data-minimization]) .first:before {
  content: "\\EA34";
}

:host([size=l][icon=no-data-minimization]) .first:before {
  content: "\\EA34";
}

:host([icon=high-privacy]) .first:before {
  content: "\\EA32";
}

:host([size=xs][icon=high-privacy]) .first:before {
  content: "\\EA30";
}

:host([size=s][icon=high-privacy]) .first:before {
  content: "\\EA31";
}

:host([size=l][icon=high-privacy]) .first:before {
  content: "\\EA31";
}

:host([icon=customized-privacy]) .first:before {
  content: "\\EA2F";
}

:host([size=xs][icon=customized-privacy]) .first:before {
  content: "\\EA2D";
}

:host([size=s][icon=customized-privacy]) .first:before {
  content: "\\EA2E";
}

:host([size=l][icon=customized-privacy]) .first:before {
  content: "\\EA2E";
}

:host([icon=result-table]) .first:before {
  content: "\\EB58";
}

:host([size=xs][icon=result-table]) .first:before {
  content: "\\EB57";
}

:host([size=s][icon=result-table]) .first:before {
  content: "\\EB57";
}

:host([size=l][icon=result-table]) .first:before {
  content: "\\EB58";
}

:host([icon=information]) .first:before {
  content: "\\E94F";
}

:host([icon=information]) .first:after {
  content: "\\E952";
  color: rgb(var(--ui-7));
}

:host(:not([color])[icon=information]) .first:before {
  content: "\\E94F";
  color: #6e7b9f;
}

:host(:not([color])[icon=information]) .first:after {
  content: "\\E952";
  color: #ffffff;
}

:host([size=xs][icon=information]) .first:before {
  content: "\\E950";
}

:host([size=xs][icon=information]) .first:after {
  content: "\\E032";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=information]) .first:before {
  content: "\\E957";
}

:host([size=s][icon=information]) .first:after {
  content: "\\E959";
  color: rgb(var(--ui-7));
}

:host([size=l][icon=information]) .first:before {
  content: "\\E957";
}

:host([size=l][icon=information]) .first:after {
  content: "\\E959";
  color: rgb(var(--ui-7));
}

:host(:not([color])[size=xs][icon=information]) .first:before {
  content: "\\E950";
  color: #6e7b9f;
}

:host(:not([color])[size=xs][icon=information]) .first:after {
  content: "\\E032";
  color: #ffffff;
}

:host(:not([color])[size=s][icon=information]) .first:before {
  content: "\\E957";
  color: #6e7b9f;
}

:host(:not([color])[size=s][icon=information]) .first:after {
  content: "\\E959";
  color: #ffffff;
}

:host(:not([color])[size=l][icon=information]) .first:before {
  content: "\\E957";
  color: #6e7b9f;
}

:host(:not([color])[size=l][icon=information]) .first:after {
  content: "\\E959";
  color: #ffffff;
}

:host([icon=confirmation]) .first:before {
  content: "\\E0BA";
}

:host([icon=confirmation]) .first:after {
  content: "\\EA5A";
  color: #ffffff;
}

:host(:not([color])[icon=confirmation]) .first:before {
  content: "\\E0BA";
  color: #6e7b9f;
}

:host(:not([color])[icon=confirmation]) .first:after {
  content: "\\EA5A";
  color: #ffffff;
}

:host([size=s][icon=confirmation]) .first:before {
  content: "\\EEC9";
}

:host([size=s][icon=confirmation]) .first:after {
  content: "\\EECA";
  color: #ffffff;
}

:host(:not([color])[size=s][icon=confirmation]) .first:before {
  content: "\\EEC9";
  color: #6e7b9f;
}

:host(:not([color])[size=s][icon=confirmation]) .first:after {
  content: "\\EECA";
  color: #ffffff;
}

:host([size=xs][icon=confirmation]) .first:before {
  content: "\\EA58";
}

:host([size=xs][icon=confirmation]) .first:after {
  content: "\\EA59";
  color: #ffffff;
}

:host(:not([color])[size=xs][icon=confirmation]) .first:before {
  content: "\\EA58";
  color: #6e7b9f;
}

:host(:not([color])[size=xs][icon=confirmation]) .first:after {
  content: "\\EA59";
  color: #ffffff;
}

:host([color][icon=error]) .first:before {
  content: "\\E94F";
}

:host([color][icon=error]) .first:after {
  content: "\\E951";
  color: rgb(var(--ui-7));
}

:host(:not([color])[icon=error]) .first:before {
  content: "\\E94F";
  color: rgba(var(--functional-red), var(--opacity-1));
}

:host(:not([color])[icon=error]) .first:after {
  content: "\\E951";
  color: white;
}

:host([size=xs][color][icon=error]) .first:before {
  content: "\\E950";
}

:host([size=xs][color][icon=error]) .first:after {
  content: "\\E031";
  color: rgb(var(--ui-7));
}

:host([size=xs]:not([color])[icon=error]) .first:before {
  content: "\\E950";
  color: rgba(var(--functional-red), var(--opacity-1));
}

:host([size=xs]:not([color])[icon=error]) .first:after {
  content: "\\E031";
  color: white;
}

:host([size=s][color][icon=error]) .first:before {
  content: "\\E957";
}

:host([size=s][color][icon=error]) .first:after {
  content: "\\E958";
  color: rgb(var(--ui-7));
}

:host([size=s]:not([color])[icon=error]) .first:before {
  content: "\\E957";
  color: rgba(var(--functional-red), var(--opacity-1));
}

:host([size=s]:not([color])[icon=error]) .first:after {
  content: "\\E958";
  color: white;
}

:host([size=l][color][icon=error]) .first:before {
  content: "\\E957";
}

:host([size=l][color][icon=error]) .first:after {
  content: "\\E958";
  color: rgb(var(--ui-7));
}

:host([size=l]:not([color])[icon=error]) .first:before {
  content: "\\E957";
  color: rgba(var(--functional-red), var(--opacity-1));
}

:host([size=l]:not([color])[icon=error]) .first:after {
  content: "\\E958";
  color: white;
}

:host([icon=cancel]) .first:before {
  content: "\\E98C";
}

:host([size=xs][icon=cancel]) .first:before {
  content: "\\E002";
}

:host([size=s][icon=cancel]) .first:before {
  content: "\\E98D";
}

:host([size=l][icon=cancel]) .first:before {
  content: "\\E98D";
}

:host([color][icon=success]) .first:before {
  content: "\\E94F";
}

:host([color][icon=success]) .first:after {
  content: "\\E953";
  color: rgb(var(--ui-7));
}

:host(:not([color])[icon=success]) .first:before {
  content: "\\E94F";
  color: rgba(var(--functional-green), var(--opacity-1));
}

:host(:not([color])[icon=success]) .first:after {
  content: "\\E953";
  color: white;
}

:host([size=xs][color][icon=success]) .first:before {
  content: "\\E950";
}

:host([size=xs][color][icon=success]) .first:after {
  content: "\\E033";
  color: rgb(var(--ui-7));
}

:host([size=xs]:not([color])[icon=success]) .first:before {
  content: "\\E950";
  color: rgba(var(--functional-green), var(--opacity-1));
}

:host([size=xs]:not([color])[icon=success]) .first:after {
  content: "\\E033";
  color: white;
}

:host([size=s][color][icon=success]) .first:before {
  content: "\\E957";
}

:host([size=s][color][icon=success]) .first:after {
  content: "\\E95A";
  color: rgb(var(--ui-7));
}

:host([size=s]:not([color])[icon=success]) .first:before {
  content: "\\E957";
  color: rgba(var(--functional-green), var(--opacity-1));
}

:host([size=s]:not([color])[icon=success]) .first:after {
  content: "\\E95A";
  color: white;
}

:host([size=l][color][icon=success]) .first:before {
  content: "\\E957";
}

:host([size=l][color][icon=success]) .first:after {
  content: "\\E95A";
  color: rgb(var(--ui-7));
}

:host([size=l]:not([color])[icon=success]) .first:before {
  content: "\\E957";
  color: rgba(var(--functional-green), var(--opacity-1));
}

:host([size=l]:not([color])[icon=success]) .first:after {
  content: "\\E95A";
  color: white;
}

:host([color][icon=warning]) .first:before {
  content: "\\E94F";
}

:host([color][icon=warning]) .first:after {
  content: "\\E954";
  color: rgb(var(--ui-7));
}

:host(:not([color])[icon=warning]) .first:before {
  content: "\\E94F";
  color: rgba(var(--functional-yellow), var(--opacity-1));
}

:host(:not([color])[icon=warning]) .first:after {
  content: "\\E954";
  color: black;
}

:host([size=xs][color][icon=warning]) .first:before {
  content: "\\E950";
}

:host([size=xs][color][icon=warning]) .first:after {
  content: "\\E034";
  color: rgb(var(--ui-7));
}

:host([size=xs]:not([color])[icon=warning]) .first:before {
  content: "\\E950";
  color: rgba(var(--functional-yellow), var(--opacity-1));
}

:host([size=xs]:not([color])[icon=warning]) .first:after {
  content: "\\E034";
  color: black;
}

:host([size=s][color][icon=warning]) .first:before {
  content: "\\E957";
}

:host([size=s][color][icon=warning]) .first:after {
  content: "\\E95B";
  color: rgb(var(--ui-7));
}

:host([size=s]:not([color])[icon=warning]) .first:before {
  content: "\\E957";
  color: rgba(var(--functional-yellow), var(--opacity-1));
}

:host([size=s]:not([color])[icon=warning]) .first:after {
  content: "\\E95B";
  color: black;
}

:host([size=l][color][icon=warning]) .first:before {
  content: "\\E957";
}

:host([size=l][color][icon=warning]) .first:after {
  content: "\\E95B";
  color: rgb(var(--ui-7));
}

:host([size=l]:not([color])[icon=warning]) .first:before {
  content: "\\E957";
  color: rgba(var(--functional-yellow), var(--opacity-1));
}

:host([size=l]:not([color])[icon=warning]) .first:after {
  content: "\\E95B";
  color: black;
}

:host([color][icon=in-progress]) .first:before {
  content: "\\EB07";
}

:host(:not([color])[icon=in-progress]) .first:before {
  content: "\\EB1C";
  color: #66bcea;
}

:host(:not([color])[icon=in-progress]) .first:after {
  content: "\\EB1B";
  color: black;
}

:host([icon=pat-factor]) .first:before {
  content: "\\EA84";
}

:host([size=xs][icon=pat-factor]) .first:before {
  content: "\\EA83";
}

:host([icon=generic-lesion-marker]) .first:before {
  content: "\\EBB3";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host(:not([color])[icon=generic-lesion-marker]) .first:after {
  content: "\\EBB4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=generic-lesion-marker]) .first:after {
  content: "\\EBB4";
}

:host([icon=liver-lesion-marker]) .first:before {
  content: "\\EBB8";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host(:not([color])[icon=liver-lesion-marker]) .first:after {
  content: "\\EBB9";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=liver-lesion-marker]) .first:after {
  content: "\\EBB9";
}

:host([icon=lung-lesion-marker]) .first:before {
  content: "\\EBB5";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=lung-lesion-marker]) .first:after {
  content: "\\EBB6 ";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host(:not([color])[icon=lung-lesion-marker]) .second:before {
  content: "\\EBB7 ";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=lung-lesion-marker]) .second:before {
  content: "\\EBB7 ";
}

:host([icon=two-d-view]) .first:before,
:host([icon=view-2d]) .first:before {
  content: "\\E14F";
}

:host([icon=two-d-view]) .first:after,
:host([icon=view-2d]) .first:after {
  content: "\\E151";
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host([icon=airways-analyse]) .first:before {
  content: "\\E0FE";
}

:host([icon=airways-analyse]) .first:after {
  content: "\\E0FD";
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host([icon=hide]) .first:before {
  content: "\\E100";
}

:host([icon=hide]) .first:after {
  content: "\\E0FF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-first-supine]) .first:before {
  content: "\\E102";
}

:host([icon=head-first-supine]) .first:after {
  content: "\\E103";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-first-left-lateral]) .first:before {
  content: "\\E104";
}

:host([icon=head-first-left-lateral]) .first:after {
  content: "\\E105";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-first-right-lateral]) .first:before {
  content: "\\E106";
}

:host([icon=head-first-right-lateral]) .first:after {
  content: "\\E107";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-first-prone]) .first:before {
  content: "\\E108";
}

:host([icon=head-first-prone]) .first:after {
  content: "\\E109";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=feet-first-supine]) .first:before {
  content: "\\E10A";
}

:host([icon=feet-first-supine]) .first:after {
  content: "\\E10B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=feet-first-left-lateral]) .first:before {
  content: "\\E10A";
}

:host([icon=feet-first-left-lateral]) .first:after {
  content: "\\E10C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=feet-first-right-lateral]) .first:before {
  content: "\\E10A";
}

:host([icon=feet-first-right-lateral]) .first:after {
  content: "\\E10D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-left-supine]) .first:before {
  content: "\\E10F";
}

:host([icon=head-left-supine]) .first:after {
  content: "\\E110";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-right-supine]) .first:before {
  content: "\\E111";
}

:host([icon=head-right-supine]) .first:after {
  content: "\\E112";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-left-prone]) .first:before {
  content: "\\E113";
}

:host([icon=head-left-prone]) .first:after {
  content: "\\E114";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=head-right-prone]) .first:before {
  content: "\\E115";
}

:host([icon=head-right-prone]) .first:after {
  content: "\\E116";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=upright]) .first:before {
  content: "\\E117";
}

:host([icon=upright]) .first:after {
  content: "\\E118";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=position-undefined]) .first:before {
  content: "\\E10A";
}

:host([icon=position-undefined]) .first:after {
  content: "\\E119";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=plantar]) .first:before {
  content: "\\E10A";
}

:host([icon=plantar]) .first:after {
  content: "\\E11A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=superman-supine-wrist]) .first:before {
  content: "\\E11F";
}

:host([icon=superman-supine-wrist]) .first:after {
  content: "\\E11C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=superman-prone-wrist]) .first:before {
  content: "\\E11D";
}

:host([icon=superman-prone-wrist]) .first:after {
  content: "\\E11E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=superman-supine-elbow]) .first:before {
  content: "\\E11B";
}

:host([icon=superman-supine-elbow]) .first:after {
  content: "\\E11C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=superman-prone-elbow]) .first:before {
  content: "\\E120";
}

:host([icon=superman-prone-elbow]) .first:after {
  content: "\\E11E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=gantry-tilt]) .first:before {
  content: "\\E130";
}

:host([icon=gantry-tilt]) .first:after {
  content: "\\E122";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=gantry-tilt-left]) .first:before {
  content: "\\E123";
}

:host([icon=gantry-tilt-left]) .first:after {
  content: "\\E124";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=gantry-tilt-right]) .first:before {
  content: "\\E126";
}

:host([icon=gantry-tilt-right]) .first:after {
  content: "\\E125";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=image-hide]) .first:before {
  content: "\\E12A";
}

:host([icon=image-hide]) .first:after {
  content: "\\E129";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=image-text-hide]) .first:before {
  content: "\\E144";
}

:host([icon=image-text-hide]) .first:after {
  content: "\\E143";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=rotate-cw-90]) .first:before {
  content: "\\E149";
}

:host([icon=rotate-cw-90]) .first:after {
  content: "\\E14A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=rotate-ccw-90]) .first:before {
  content: "\\E147";
}

:host([icon=rotate-ccw-90]) .first:after {
  content: "\\E148";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pixel-lens]) .first:before {
  content: "\\E146";
}

:host([icon=pixel-lens]) .first:after {
  content: "\\E150";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=rotate-perpendicular]) .first:before {
  content: "\\E14C";
}

:host([icon=rotate-perpendicular]) .first:after {
  content: "\\E14D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=flip-horizontal]) .first:before {
  content: "\\E13B";
}

:host([icon=flip-horizontal]) .first:after {
  content: "\\E13A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=flip-vertical]) .first:before {
  content: "\\E13F";
}

:host([icon=flip-vertical]) .first:after {
  content: "\\E13E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=flip-popup]) .first:before {
  content: "\\E13D";
}

:host([icon=flip-popup]) .first:after {
  content: "\\E13C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=ecg-connection-state]) .first:before {
  content: "\\E902";
}

:host([icon=ecg-connection-state]) .first:after {
  content: "\\E903";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=signal-strength-4]) .first:before {
  content: "\\E92A";
}

:host([icon=signal-strength-4]) .first:after {
  content: "\\E92B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=signal-strength-3]) .first:before {
  content: "\\E928";
}

:host([icon=signal-strength-3]) .first:after {
  content: "\\E929";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=signal-strength-2]) .first:before {
  content: "\\E926";
}

:host([icon=signal-strength-2]) .first:after {
  content: "\\E927";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=signal-strength-1]) .first:before {
  content: "\\E924";
}

:host([icon=signal-strength-1]) .first:after {
  content: "\\E925";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=select-monitor]) .first:before {
  content: "\\E920";
}

:host([icon=select-monitor]) .first:after {
  content: "\\E91F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=patient-table]) .first:before {
  content: "\\E91D";
}

:host([icon=patient-table]) .first:after {
  content: "\\E91C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=intervention-layout-switch]) .first:before {
  content: "\\E916";
}

:host([icon=intervention-layout-switch]) .first:after {
  content: "\\E915";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=connection-quality-low]) .first:before {
  content: "\\E90C";
}

:host([icon=connection-quality-low]) .first:after {
  content: "\\E90D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=compass-direction-w]) .first:before {
  content: "\\E90B";
  color: #000000;
}

:host([icon=compass-direction-w]) .first:after {
  content: "\\E90A";
  color: rgba(var(--functional-yellow), var(--opacity-1));
}

:host([icon=show-breathing-curve]) .first:before {
  content: "\\E949";
}

:host([icon=show-breathing-curve]) .first:after {
  content: "\\E94A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=relative-zero-position]) .first:before {
  content: "\\E946";
}

:host([icon=relative-zero-position]) .first:after {
  content: "\\E947";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=patient-mark]) .first:before {
  content: "\\E944";
}

:host([icon=patient-mark]) .first:after {
  content: "\\E945";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=physician]) .first:before {
  content: "\\E94C";
}

:host([icon=physician]) .first:after {
  content: "\\E94D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=table-removal]) .first:before {
  content: "\\E972";
}

:host([icon=table-removal]) .first:after {
  content: "\\E973";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=mdt-ready]) .first:before {
  content: "\\E96D";
}

:host([icon=mdt-ready]) .first:after {
  content: "\\E96E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=kidneys]) .first:before {
  content: "\\E977";
}

:host([icon=kidneys]) .first:after {
  content: "\\E978";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lung]) .first:before {
  content: "\\E97A";
}

:host([icon=lung]) .first:after {
  content: "\\E97B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=light-funnel]) .first:before {
  content: "\\E986";
}

:host([icon=light-funnel]) .first:after {
  content: "\\E987";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=light-ring]) .first:before {
  content: "\\E988";
}

:host([icon=light-ring]) .first:after {
  content: "\\E989";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=show-laser-crosshair]) .first:before,
:host([icon=laser-crosshair-show]) .first:before {
  content: "\\E99A";
}

:host([icon=show-laser-crosshair]) .first:after,
:host([icon=laser-crosshair-show]) .first:after {
  content: "\\E99B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=user-position-left]) .first:before {
  content: "\\E99C";
}

:host([icon=user-position-left]) .first:after {
  content: "\\E99D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=user-position-right]) .first:before {
  content: "\\E99E";
}

:host([icon=user-position-right]) .first:after {
  content: "\\E99F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=inr-medication]) .first:before,
:host([icon=inr-measurement]) .first:before {
  content: "\\E9A9";
}

:host([icon=inr-medication]) .first:after,
:host([icon=inr-measurement]) .first:after {
  content: "\\E9A8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=laboratory]) .first:before {
  content: "\\E9C4";
}

:host([icon=laboratory]) .first:after {
  content: "\\E9C5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=fov-segment]) .first:before {
  content: "\\E9E9";
}

:host([icon=fov-segment]) .first:after {
  content: "\\E9E8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=light-bore]) .first:before {
  content: "\\E9E5";
}

:host([icon=light-bore]) .first:after {
  content: "\\E9E6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=light-outer-ring]) .first:before {
  content: "\\E9E3";
}

:host([icon=light-outer-ring]) .first:after {
  content: "\\E9E4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lobes-edit-sync]) .first:before {
  content: "\\E9E2";
}

:host([icon=lobes-edit-sync]) .first:after {
  content: "\\E9E1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=open-reconstruction-tab]) .first:before {
  content: "\\E9DA";
}

:host([icon=open-reconstruction-tab]) .first:after {
  content: "\\E9D9";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=patient-position-change]) .first:before {
  content: "\\E9D8";
}

:host([icon=patient-position-change]) .first:after {
  content: "\\E9D7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=qr-code-scan]) .first:before {
  content: "\\E9D4";
}

:host([icon=qr-code-scan]) .first:after {
  content: "\\E9D5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=reference-segment]) .first:before {
  content: "\\E9D2";
}

:host([icon=reference-segment]) .first:after {
  content: "\\E9D1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=show-hide-peak-information-map]) .first:before {
  content: "\\E992";
}

:host([icon=show-hide-peak-information-map]) .first:after {
  content: "\\E98A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=show-hide-saturation-region]) .first:before {
  content: "\\E9FE";
}

:host([icon=show-hide-saturation-region]) .first:after {
  content: "\\E9FD";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=skull-unfolding]) .first:before {
  content: "\\E9FC";
}

:host([icon=skull-unfolding]) .first:after {
  content: "\\E9FB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=user-planes]) .first:before {
  content: "\\E9F7";
}

:host([icon=user-planes]) .first:after {
  content: "\\E9F8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=move-to-rt-laser]) .first:before {
  content: "\\EA0E";
}

:host([icon=move-to-rt-laser]) .first:after {
  content: "\\EA0D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=move-to-ct-laser]) .first:before {
  content: "\\EA0B";
}

:host([icon=move-to-ct-laser]) .first:after {
  content: "\\EA0C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=disc-space-1]) .first:before {
  content: "\\EA11";
}

:host([icon=disc-space-1]) .first:after {
  content: "\\EA15";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=disc-space-2]) .first:before {
  content: "\\EA16";
}

:host([icon=disc-space-2]) .first:after {
  content: "\\EA15";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=disc-space-3]) .first:before {
  content: "\\EA12";
}

:host([icon=disc-space-3]) .first:after {
  content: "\\EA15";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=disc-space-4]) .first:before {
  content: "\\EA13";
}

:host([icon=disc-space-4]) .first:after {
  content: "\\EA15";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=disc-space-5]) .first:before {
  content: "\\EA14";
}

:host([icon=disc-space-5]) .first:after {
  content: "\\EA15";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=annotations-hide]) .first:before {
  content: "\\EA10";
}

:host([icon=annotations-hide]) .first:after {
  content: "\\EA17";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=table-longitudinal-closeup]) .first:before {
  content: "\\EA41";
}

:host([icon=table-longitudinal-closeup]) .first:after {
  content: "\\EA42";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=move-table-top-only]) .first:before {
  content: "\\EA43";
}

:host([icon=move-table-top-only]) .first:after {
  content: "\\EA44";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=light-all]) .first:before {
  content: "\\E985";
}

:host([icon=light-all]) .first:after {
  content: "\\EA45";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=vessel-lumen-show]) .first:before {
  content: "\\EA4A";
}

:host([icon=vessel-lumen-show]) .first:after {
  content: "\\EA4C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pulmonary-density]) .first:before {
  content: "\\EA50";
}

:host([icon=pulmonary-density]) .first:after {
  content: "\\EA51";
  color: #be1818;
}

:host([icon=graphics-hide]) .first:before {
  content: "\\EA55";
}

:host([icon=graphics-hide]) .first:after {
  content: "\\EA56";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pulse-oximetry]) .first:before {
  content: "\\EA5F";
}

:host([icon=pulse-oximetry]) .first:after {
  content: "\\EA60";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chessboard-view]) .first:before {
  content: "\\EA72";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chessboard-view]) .first:after {
  content: "\\EA73";
}

:host([icon=contour-cut-axial-caudal]) .first:before {
  content: "\\EA74";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=contour-cut-axial-caudal]) .first:after {
  content: "\\EA75";
}

:host([icon=contour-cut-axial-cranial]) .first:before {
  content: "\\EA76";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=contour-cut-axial-cranial]) .first:after {
  content: "\\EA77";
}

:host([icon=poi-crosshair-move]) .first:before {
  content: "\\EA85";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=poi-crosshair-move]) .first:after {
  content: "\\EA86";
}

:host([icon=poi-new]) .first:before {
  content: "\\EA87";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=poi-new]) .first:after {
  content: "\\EA88";
}

:host([icon=poi-to-crosshair]) .first:before {
  content: "\\EA89";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=poi-to-crosshair]) .first:after {
  content: "\\EA8a";
}

:host([icon=breast]) .first:before {
  content: "\\E056";
}

:host([icon=breast]) .first:after {
  content: "\\E0D8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=breast-analytics]) .first:before {
  content: "\\E0D9";
}

:host([icon=breast-analytics]) .first:after {
  content: "\\EA8D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=clinical-trial-matching]) .first:before {
  content: "\\EA8E";
}

:host([icon=clinical-trial-matching]) .first:after {
  content: "\\EA8F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=coronary-artery-disease]) .first:before {
  content: "\\EA90";
}

:host([icon=coronary-artery-disease]) .first:after {
  content: "\\E0DA";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=infectious-disease]) .first:before {
  content: "\\EA91";
}

:host([icon=infectious-disease]) .first:after {
  content: "\\EA92";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=infectious-disease-analytics]) .first:before {
  content: "\\EA93";
}

:host([icon=infectious-disease-analytics]) .first:after {
  content: "\\EA92";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lung-analytics]) .first:before {
  content: "\\EA94";
}

:host([icon=lung-analytics]) .first:after {
  content: "\\EA95";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=prostate]) .first:before {
  content: "\\EA96";
}

:host([icon=prostate]) .first:after {
  content: "\\EA97";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=prostate-analytics]) .first:before {
  content: "\\EA98";
}

:host([icon=prostate-analytics]) .first:after {
  content: "\\EA97";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=valvular-heart-disease]) .first:before {
  content: "\\EA99";
}

:host([icon=valvular-heart-disease]) .first:after {
  content: "\\EA9A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=three-d-camera]) .first:before {
  content: "\\EAA0";
}

:host([icon=three-d-camera]) .first:after {
  content: "\\EA9F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=radiopaedia]) .first:before {
  content: "\\EAA6";
}

:host([icon=radiopaedia]) .first:after {
  content: "\\EAA5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=auto-organ-segmentation]) .first:before {
  content: "\\EAAE";
}

:host([icon=auto-organ-segmentation]) .first:after {
  content: "\\EAAF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=isocontour]) .first:before {
  content: "\\EAB0";
}

:host([icon=isocontour]) .first:after {
  content: "\\EAB1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=series-angle]) .first:before {
  content: "\\EAB8";
}

:host([icon=series-angle]) .first:after {
  content: "\\EAB9";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=table-move-to-laser-guidance-position]) .first:before {
  content: "\\EAC5";
}

:host([icon=table-move-to-laser-guidance-position]) .first:after {
  content: "\\EAC4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cutline-smooth]) .first:before {
  content: "\\EAC6";
}

:host([icon=cutline-smooth]) .first:after {
  content: "\\EAC7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=combined-trending-graph]) .first:before {
  content: "\\EACA";
}

:host([icon=combined-trending-graph]) .first:after {
  content: "\\EACB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=sample-delete]) .first:before {
  content: "\\EAD6";
}

:host([icon=sample-delete]) .first:after {
  content: "\\EAD7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=sample-search]) .first:before {
  content: "\\EADE";
}

:host([icon=sample-search]) .first:after {
  content: "\\EADF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=samples]) .first:before {
  content: "\\EAE0";
}

:host([icon=samples]) .first:after {
  content: "\\EAE1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=trending-graph-pronounced]) .first:before {
  content: "\\EAE4";
}

:host([icon=trending-graph-pronounced]) .first:after {
  content: "\\EAE5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=sample]) .first:before {
  content: "\\EB44";
}

:host([icon=sample]) .first:after {
  content: "\\EB45";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=send-rating-feedback]) .first:before {
  content: "\\EB42";
}

:host([icon=send-rating-feedback]) .first:after {
  content: "\\EB43";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=edge-enhancement-low]) .first:before {
  content: "\\EB03";
}

:host([icon=edge-enhancement-low]) .first:after {
  content: "\\EB02";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=edge-enhancement-medium]) .first:before {
  content: "\\EB00";
}

:host([icon=edge-enhancement-medium]) .first:after {
  content: "\\EB01";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-filter-high]) .first:before {
  content: "\\EB19";
}

:host([icon=noise-reduce-filter-high]) .first:after {
  content: "\\EB18";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-filter-low]) .first:before {
  content: "\\EB19";
}

:host([icon=noise-reduce-filter-low]) .first:after {
  content: "\\EB17";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-filter-medium]) .first:before {
  content: "\\EB19";
}

:host([icon=noise-reduce-filter-medium]) .first:after {
  content: "\\EB16";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-filter-off]) .first:before {
  content: "\\EB15";
}

:host([icon=noise-reduce-filter-off]) .first:after {
  content: "\\EB14";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-lih-high]) .first:before {
  content: "\\EB13";
}

:host([icon=noise-reduce-lih-high]) .first:after {
  content: "\\EB12";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-lih-low]) .first:before {
  content: "\\EB13";
}

:host([icon=noise-reduce-lih-low]) .first:after {
  content: "\\EB11";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-lih-medium]) .first:before {
  content: "\\EB13";
}

:host([icon=noise-reduce-lih-medium]) .first:after {
  content: "\\EB10";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-live-image-high]) .first:before {
  content: "\\EB0F";
}

:host([icon=noise-reduce-live-image-high]) .first:after {
  content: "\\EB0E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-live-image-low]) .first:before {
  content: "\\EB0F";
}

:host([icon=noise-reduce-live-image-low]) .first:after {
  content: "\\EB0D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=noise-reduce-live-image-medium]) .first:before {
  content: "\\EB0F";
}

:host([icon=noise-reduce-live-image-medium]) .first:after {
  content: "\\EB0C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=radiation-low]) .first:before {
  content: "\\EB26";
}

:host([icon=radiation-low]) .first:after {
  content: "\\EB25";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=radiation-medium]) .first:before {
  content: "\\EB23";
}

:host([icon=radiation-medium]) .first:after {
  content: "\\EB24";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=ed-percent]) .first:before {
  content: "\\EB64";
}

:host([icon=ed-percent]) .first:after {
  content: "\\EB63";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=stress-layout-hide]) .first:before {
  content: "\\EB71";
}

:host([icon=stress-layout-hide]) .first:after {
  content: "\\EB70";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=wifi-1]) .first:before {
  content: "\\EB82";
}

:host([icon=wifi-1]) .first:after {
  content: "\\EB83";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=wifi-2]) .first:before {
  content: "\\EB7A";
}

:host([icon=wifi-2]) .first:after {
  content: "\\EB7B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=wifi-3]) .first:before {
  content: "\\EB78";
}

:host([icon=wifi-3]) .first:after {
  content: "\\EB79";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=fuse-images]) .first:before {
  content: "\\EB7D";
}

:host([icon=fuse-images]) .first:after {
  content: "\\EB7E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=inr-medication-full-dose]) .first:before,
:host([icon=medication-full-dose]) .first:before {
  content: "\\EB90";
}

:host([icon=inr-medication-full-dose]) .first:after,
:host([icon=medication-full-dose]) .first:after {
  content: "\\EB91";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=inr-medication-half-dose]) .first:before,
:host([icon=medication-half-dose]) .first:before {
  content: "\\EB8E";
}

:host([icon=inr-medication-half-dose]) .first:after,
:host([icon=medication-half-dose]) .first:after {
  content: "\\EB8F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=gantry-expand]) .first:before {
  content: "\\EBAD";
}

:host([icon=gantry-expand]) .first:after {
  content: "\\EBAC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=delete-point]) .first:before {
  content: "\\EBD5";
}

:host([icon=delete-point]) .first:after {
  content: "\\EBD6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=usb-stick-space-1]) .first:before {
  content: "\\EBC1";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=usb-stick-space-1]) .first:after {
  content: "\\EBC0";
}

:host([icon=usb-stick-space]) .first:before,
:host([icon=usb-stick-space-2]) .first:before {
  content: "\\EBC1";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=usb-stick-space]) .first:after,
:host([icon=usb-stick-space-2]) .first:after {
  content: "\\EBBF";
}

:host([icon=usb-stick-space-3]) .first:before {
  content: "\\EBC1";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=usb-stick-space-3]) .first:after {
  content: "\\EBBE";
}

:host([icon=usb-stick-space-4]) .first:before {
  content: "\\EBC1";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=usb-stick-space-4]) .first:after {
  content: "\\EBBD";
}

:host([icon=usb-stick-space-5]) .first:before {
  content: "\\EBC1";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=usb-stick-space-5]) .first:after {
  content: "\\EBBC";
}

:host([icon=mute-alarm]) .first:before {
  content: "\\EBE0";
}

:host([icon=mute-alarm]) .first:after {
  content: "\\EBE1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lung-breathing-percentile]) .first:before {
  content: "\\EBE5";
}

:host([icon=lung-breathing-percentile]) .first:after {
  content: "\\EBE4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=heart-left-ventricle]) .first:before {
  content: "\\EC07";
}

:host([icon=heart-left-ventricle]) .first:after {
  content: "\\EC08";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=heart-ventricles]) .first:before {
  content: "\\EC09";
}

:host([icon=heart-ventricles]) .first:after {
  content: "\\EC0A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=heart-ventricles-manual]) .first:before {
  content: "\\EC0B";
}

:host([icon=heart-ventricles-manual]) .first:after {
  content: "\\EC0C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=vertebrae-centering]) .first:before {
  content: "\\EC17";
}

:host([icon=vertebrae-centering]) .first:after {
  content: "\\EC14";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lung-breathing-percentile-restart]) .first:before {
  content: "\\EC1D";
}

:host([icon=lung-breathing-percentile-restart]) .first:after {
  content: "\\EC1C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=position-lateral-right]) .first:before {
  content: "\\EC2E";
}

:host([icon=position-lateral-right]) .first:after {
  content: "\\EC2F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=position-lateral-left]) .first:before {
  content: "\\EC2D";
}

:host([icon=position-lateral-left]) .first:after {
  content: "\\EC2C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=position-prone]) .first:before {
  content: "\\EC29";
}

:host([icon=position-prone]) .first:after {
  content: "\\EC28";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=position-supine]) .first:before {
  content: "\\EC2B";
}

:host([icon=position-supine]) .first:after {
  content: "\\EC2A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=virtual-reality]) .first:before {
  content: "\\EC27";
}

:host([icon=virtual-reality]) .first:after {
  content: "\\EC26";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=spine-ranges]) .first:before {
  content: "\\EC49";
}

:host([icon=spine-ranges]) .first:after {
  content: "\\EC4A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=position-display-medium]) .first:before {
  content: "\\EC4C";
}

:host([icon=position-display-medium]) .first:after {
  content: "\\EC36";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=footswitch-battery-1]) .first:before {
  content: "\\EBD3";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=footswitch-battery-1]) .first:after {
  content: "\\EBD4";
}

:host([icon=footswitch-battery-empty]) .first:before {
  content: "\\EBD3";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=footswitch-battery-empty]) .first:after {
  content: "\\EBCF";
}

:host([icon=handcontrol-battery-1]) .first:before {
  content: "\\EBCD";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=handcontrol-battery-1]) .first:after {
  content: "\\EBD4";
}

:host([icon=handcontrol-battery-empty]) .first:before {
  content: "\\EBCD";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=handcontrol-battery-empty]) .first:after {
  content: "\\EBCF";
}

:host([icon=test-image]) .first:before {
  content: "\\EB1F";
}

:host([icon=test-image]) .first:after {
  content: "\\EB1E";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=test-image]) .second:before {
  content: "\\EB1D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=isolate-volume]) .first:before {
  content: "\\EBAB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=isolate-volume]) .first:after {
  content: "\\EBAA";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=isolate-volume]) .second:before {
  content: "\\EBA9";
}

:host([icon=download-completed]) .first:before {
  content: "\\EA79";
}

:host([icon=download-completed]) .first:after {
  content: "\\E990";
}

:host([icon=download-completed]) .second:before {
  content: "\\E995";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host(:not([color])[icon=download-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=download-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([icon=import-completed]) .first:before {
  content: "\\EA80";
}

:host([icon=import-completed]) .first:after {
  content: "\\EA81";
}

:host([icon=import-completed]) .second:before {
  content: "\\EA82";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host(:not([color])[icon=import-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=import-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([icon=rt-laser-not-zero]) .first:before {
  content: "\\E993";
}

:host([icon=rt-laser-not-zero]) .first:after {
  content: "\\EA4B";
  color: rgb(var(--functional-yellow));
}

:host([icon=rt-laser-not-zero]) .second:before {
  content: "\\EAB3";
  color: #000000;
}

:host([icon=connection-quality-medium]) .first:before {
  content: "\\E93C";
}

:host([icon=connection-quality-medium]) .first:after {
  content: "\\E93B";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=connection-quality-medium]) .second:before {
  content: "\\E93A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=feet-first-prone]) .first:before {
  content: "\\E10A";
}

:host([icon=feet-first-prone]) .first:after {
  content: "\\E10E";
}

:host([icon=feet-first-prone]) .second:before {
  content: "\\E921";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=corner-menu-br-bounding-box]) .first:before {
  content: "\\E9ED";
}

:host([icon=corner-menu-br-bounding-box]) .first:after {
  content: "\\E9EC";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=corner-menu-br-bounding-box]) .second:before {
  content: "\\E9EB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=vessel-coronary-ffr]) .first:before {
  content: "\\EA4D";
}

:host([icon=vessel-coronary-ffr]) .first:after {
  content: "\\EA4E";
}

:host([icon=vessel-coronary-ffr]) .second:before {
  content: "\\EA4F";
}

:host(:not([color])[icon=vessel-coronary-ffr]) .first:before {
  content: "\\EA4D";
  color: #ffff00;
}

:host(:not([color])[icon=vessel-coronary-ffr]) .first:after {
  content: "\\EA4E";
  color: #52c1ff;
}

:host(:not([color])[icon=vessel-coronary-ffr]) .second:before {
  content: "\\EA4F";
  color: #be1818;
}

:host([icon=aspect-overlay-hide]) .first:before {
  content: "\\EB93";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host(:not([color])[icon=aspect-overlay-hide]) .first:after {
  content: "\\EB95";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=aspect-overlay-hide]) .first:after {
  content: "\\EB94";
}

:host([icon=aspect-score]) .first:before {
  content: "\\EB96";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host(:not([color])[icon=aspect-score]) .first:after {
  content: "\\EB98";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=aspect-score]) .first:after {
  content: "\\EB97";
}

:host([icon=aspect-score-cancel]) .first:before {
  content: "\\EB99";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host(:not([color])[icon=aspect-score-cancel]) .first:after {
  content: "\\EB9B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=aspect-score-cancel]) .first:after {
  content: "\\EB9A";
}

:host([icon=aspect-score-confirm]) .first:before {
  content: "\\EB9C";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host(:not([color])[icon=aspect-score-confirm]) .first:after {
  content: "\\EB9B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=aspect-score-confirm]) .first:after {
  content: "\\EB9A";
}

:host([icon=connection-quality-high]) .first:before {
  content: "\\E937";
}

:host([icon=connection-quality-high]) .first:after {
  content: "\\E938";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=connection-quality-high]) .second:before {
  content: "\\E935";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=connection-quality-high]) .second:after {
  content: "\\E936";
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host(:not([color])[icon=lines-show]) .first:before {
  content: "\\E917";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([color][icon=lines-show]) .first:before {
  content: "\\E917";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=lines-show]) .first:after {
  content: "\\E918";
}

:host([icon=show-hide-metal]) .first:before {
  content: "\\E956";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=show-hide-metal]) .first:after {
  content: "\\E955";
}

:host([icon=topogram]) .first:before {
  content: "\\E931";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=topogram]) .first:after {
  content: "\\E932";
}

:host([icon=mdt-postponed]) .first:before {
  content: "\\E96A";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=mdt-postponed]) .first:after {
  content: "\\E96B";
}

:host(:not([color])[icon=mdt-postponed]) .second:before {
  content: "\\E96C";
  color: rgba(var(--ui-1), 0);
}

:host([color][icon=mdt-postponed]) .second:before {
  content: "\\E96C";
  color: #000000;
}

:host([icon=trend-up]) .first:before {
  content: "\\E982";
}

:host(:not([color])[icon=trend-up]) .first:after {
  content: "\\E983";
  color: rgba(var(--ui-1), 0);
}

:host([color][icon=trend-up]) .first:after {
  content: "\\E983";
  color: #ffffff;
}

:host([icon=trend-stable]) .first:before {
  content: "\\E981";
}

:host(:not([color])[icon=trend-stable]) .first:after {
  content: "\\E984";
  color: rgba(var(--ui-1), 0);
}

:host([color][icon=trend-stable]) .first:after {
  content: "\\E984";
  color: #000000;
}

:host([icon=trend-down]) .first:before {
  content: "\\E97F";
}

:host(:not([color])[icon=trend-down]) .first:after {
  content: "\\E980";
  color: rgba(var(--ui-1), 0);
}

:host([color][icon=trend-down]) .first:after {
  content: "\\E980";
  color: #ffffff;
}

:host([icon=blood-pressure-completed]) .first:before {
  content: "\\E98F";
}

:host([icon=blood-pressure-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=blood-pressure-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=blood-pressure-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=blood-pressure-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=pain-scale-completed]) .first:before {
  content: "\\EB2A";
}

:host([icon=pain-scale-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=pain-scale-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=pain-scale-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=pain-scale-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=kvm-switch-completed]) .first:before {
  content: "\\EC50";
}

:host([icon=kvm-switch-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=kvm-switch-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=kvm-switch-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=kvm-switch-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=handcontrol-wifi-error]) .first:before {
  content: "\\EBE6";
}

:host([icon=handcontrol-wifi-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=handcontrol-wifi-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=handcontrol-wifi-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=handcontrol-wifi-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=blood-pressure-error]) .first:before {
  content: "\\E98F";
}

:host([icon=blood-pressure-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=blood-pressure-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=blood-pressure-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=blood-pressure-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=blood-pressure-in-progress]) .first:before {
  content: "\\E98F";
}

:host([icon=blood-pressure-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=blood-pressure-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=blood-pressure-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=blood-pressure-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=blood-pressure-warning]) .first:before {
  content: "\\E98F";
}

:host([icon=blood-pressure-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=blood-pressure-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=blood-pressure-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=blood-pressure-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=inr-completed]) .first:before,
:host([icon=inr-measurement-completed]) .first:before {
  content: "\\E9A7";
}

:host([icon=inr-completed]) .first:after,
:host([icon=inr-measurement-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=inr-completed]) .first:after,
:host(:not([color])[icon=inr-measurement-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=inr-completed]) .second:before,
:host(:not([color])[icon=inr-measurement-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=inr-completed]) .second:before,
:host([color][icon=inr-measurement-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=inr-error]) .first:before,
:host([icon=inr-measurement-error]) .first:before {
  content: "\\E9A7";
}

:host([icon=inr-error]) .first:after,
:host([icon=inr-measurement-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=inr-error]) .first:after,
:host(:not([color])[icon=inr-measurement-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=inr-error]) .second:before,
:host(:not([color])[icon=inr-measurement-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=inr-error]) .second:before,
:host([color][icon=inr-measurement-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=inr-in-progress]) .first:before,
:host([icon=inr-measurement-in-progress]) .first:before {
  content: "\\E9A7";
}

:host([icon=inr-in-progress]) .first:after,
:host([icon=inr-measurement-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=inr-in-progress]) .first:after,
:host(:not([color])[icon=inr-measurement-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=inr-in-progress]) .second:before,
:host(:not([color])[icon=inr-measurement-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=inr-in-progress]) .second:before,
:host([color][icon=inr-measurement-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=inr-warning]) .first:before,
:host([icon=inr-measurement-warning]) .first:before {
  content: "\\E9A7";
}

:host([icon=inr-warning]) .first:after,
:host([icon=inr-measurement-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=inr-warning]) .first:after,
:host(:not([color])[icon=inr-measurement-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=inr-warning]) .second:before,
:host(:not([color])[icon=inr-measurement-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=inr-warning]) .second:before,
:host([color][icon=inr-measurement-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=kitmed-report-warning]) .first:before {
  content: "\\E9AA";
}

:host([icon=kitmed-report-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=kitmed-report-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=kitmed-report-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=kitmed-report-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=kitpro-warning]) .first:before {
  content: "\\E9AD";
}

:host([icon=kitpro-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=kitpro-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=kitpro-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=kitpro-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=active-implant-warning]) .first:before {
  content: "\\EAFD";
}

:host([icon=active-implant-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=active-implant-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=active-implant-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=active-implant-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=radiation-high-warning]) .first:before {
  content: "\\EB27";
}

:host([icon=radiation-high-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=radiation-high-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=radiation-high-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=radiation-high-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=medication-completed]) .first:before,
:host([icon=inr-medication-completed]) .first:before {
  content: "\\E9AF";
}

:host([icon=medication-completed]) .first:after,
:host([icon=inr-medication-completed]) .first:after {
  content: "\\E9AE";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=medication-completed]) .second:before,
:host([icon=inr-medication-completed]) .second:before {
  content: "\\E990";
}

:host(:not([color])[icon=medication-completed]) .second:before,
:host(:not([color])[icon=inr-medication-completed]) .second:before {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=medication-completed]) .second:after,
:host(:not([color])[icon=inr-medication-completed]) .second:after {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=medication-completed]) .second:after,
:host([color][icon=inr-medication-completed]) .second:after {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=medication-error]) .first:before,
:host([icon=inr-medication-error]) .first:before {
  content: "\\E9AF";
}

:host([icon=medication-error]) .first:after,
:host([icon=inr-medication-error]) .first:after {
  content: "\\E9AE";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=medication-error]) .second:before,
:host([icon=inr-medication-error]) .second:before {
  content: "\\E996";
}

:host(:not([color])[icon=medication-error]) .second:before,
:host(:not([color])[icon=inr-medication-error]) .second:before {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=medication-error]) .second:after,
:host(:not([color])[icon=inr-medication-error]) .second:after {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=medication-error]) .second:after,
:host([color][icon=inr-medication-error]) .second:after {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=medication-in-progress]) .first:before,
:host([icon=inr-medication-in-progress]) .first:before {
  content: "\\E9AF";
}

:host([icon=medication-in-progress]) .first:after,
:host([icon=inr-medication-in-progress]) .first:after {
  content: "\\E9AE";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=medication-in-progress]) .second:before,
:host([icon=inr-medication-in-progress]) .second:before {
  content: "\\E998";
}

:host(:not([color])[icon=medication-in-progress]) .second:before,
:host(:not([color])[icon=inr-medication-in-progress]) .second:before {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=medication-in-progress]) .second:after,
:host(:not([color])[icon=inr-medication-in-progress]) .second:after {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=medication-in-progress]) .second:after,
:host([color][icon=inr-medication-in-progress]) .second:after {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=medication-warning]) .first:before,
:host([icon=inr-medication-warning]) .first:before {
  content: "\\E9AF";
}

:host([icon=medication-warning]) .first:after,
:host([icon=inr-medication-warning]) .first:after {
  content: "\\E9AE";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=medication-warning]) .second:before,
:host([icon=inr-medication-warning]) .second:before {
  content: "\\E9A0";
}

:host(:not([color])[icon=medication-warning]) .second:before,
:host(:not([color])[icon=inr-medication-warning]) .second:before {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=medication-warning]) .second:after,
:host(:not([color])[icon=inr-medication-warning]) .second:after {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=medication-warning]) .second:after,
:host([color][icon=inr-medication-warning]) .second:after {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=message-read-notification]) .first:before {
  content: "\\E9B0";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=message-read-notification]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=message-read-notification]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=message-read-notification]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=message-read-notification]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=remote-desktop-active]) .first:before {
  content: "\\EBC4";
}

:host([icon=remote-desktop-active]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=remote-desktop-active]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=remote-desktop-active]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=remote-desktop-active]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=weight-measurement-completed]) .first:before {
  content: "\\E9BB";
}

:host([icon=weight-measurement-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=weight-measurement-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=weight-measurement-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=weight-measurement-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=remote-service-error]) .first:before {
  content: "\\EBD9";
}

:host([icon=remote-service-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=remote-service-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=remote-service-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=remote-service-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=remote-service-warning]) .first:before {
  content: "\\EBD9";
}

:host([icon=remote-service-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=remote-service-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=remote-service-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=remote-service-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=weight-measurement-error]) .first:before {
  content: "\\E9BB";
}

:host([icon=weight-measurement-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=weight-measurement-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=weight-measurement-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=weight-measurement-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=weight-measurement-in-progress]) .first:before {
  content: "\\E9BB";
}

:host([icon=weight-measurement-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=weight-measurement-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=weight-measurement-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=weight-measurement-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=weight-measurement-warning]) .first:before {
  content: "\\E9BB";
}

:host([icon=weight-measurement-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=weight-measurement-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=weight-measurement-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=weight-measurement-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=wellbeing-completed]) .first:before {
  content: "\\E9BA";
}

:host([icon=wellbeing-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=wellbeing-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=wellbeing-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=wellbeing-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=wellbeing-error]) .first:before {
  content: "\\E9BA";
}

:host([icon=wellbeing-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=wellbeing-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=wellbeing-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=wellbeing-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=wellbeing-in-progress]) .first:before {
  content: "\\E9BA";
}

:host([icon=wellbeing-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=wellbeing-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=wellbeing-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=wellbeing-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=wellbeing-warning]) .first:before {
  content: "\\E9BA";
}

:host([icon=wellbeing-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=wellbeing-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=wellbeing-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=wellbeing-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=sending-completed]) .first:before {
  content: "\\E9CC";
}

:host([icon=sending-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=sending-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=sending-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=sending-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=sending-failed]) .first:before {
  content: "\\E9CC";
}

:host([icon=sending-failed]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=sending-failed]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=sending-failed]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=sending-failed]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=sending-in-progress]) .first:before {
  content: "\\E9CC";
}

:host([icon=sending-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=sending-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=sending-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=sending-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=radiation-dose-warning]) .first:before {
  content: "\\E9D3";
}

:host([icon=radiation-dose-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=radiation-dose-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=radiation-dose-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=radiation-dose-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=radiation-error]) .first:before {
  content: "\\E9D3";
}

:host([icon=radiation-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=radiation-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=radiation-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=radiation-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=radiation-ready]) .first:before {
  content: "\\E9D3";
}

:host([icon=radiation-ready]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=radiation-ready]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=radiation-ready]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=radiation-ready]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=dicom-confirmation]) .first:before {
  content: "\\EA07";
}

:host([icon=dicom-confirmation]) .first:after {
  content: "\\EA06";
}

:host(:not([color])[icon=dicom-confirmation]) .first:after {
  content: "\\EA06";
  color: #6e7b9f;
}

:host(:not([color])[icon=dicom-confirmation]) .second:before {
  content: "\\EA05";
  color: #ffffff;
}

:host([color][icon=dicom-confirmation]) .second:before {
  content: "\\EA05";
  color: #ffffff;
}

:host([icon=system-information]) .first:before {
  content: "\\EA00";
}

:host([icon=system-information]) .first:after {
  content: "\\EA0A";
}

:host(:not([color])[icon=system-information]) .first:after {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=system-information]) .second:before {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=system-information]) .second:before {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=dicom-archived]) .first:before {
  content: "\\EA07";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=dicom-archived]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=dicom-archived]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=dicom-archived]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=dicom-archived]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=dicom-archived]) .first:before {
  content: "\\EA1B";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([size=xs][icon=dicom-archived]) .first:after {
  content: "\\EA1C";
}

:host(:not([color])[size=xs][icon=dicom-archived]) .first:after {
  content: "\\EA1C";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=xs][icon=dicom-archived]) .second:before {
  content: "\\EA1D";
  color: #ffffff;
}

:host([color][size=xs][icon=dicom-archived]) .second:before {
  content: "\\EA1D";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=dicom-archived]) .first:before {
  content: "\\EA1E";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([size=s][icon=dicom-archived]) .first:after {
  content: "\\EA1F";
}

:host(:not([color])[size=s][icon=dicom-archived]) .first:after {
  content: "\\EA1F";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=s][icon=dicom-archived]) .second:before {
  content: "\\EA20";
  color: #ffffff;
}

:host([color][size=s][icon=dicom-archived]) .second:before {
  content: "\\EA20";
  color: rgb(var(--ui-7));
}

:host([size=l][icon=dicom-archived]) .first:before {
  content: "\\EA1E";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([size=l][icon=dicom-archived]) .first:after {
  content: "\\EA1F";
}

:host(:not([color])[size=l][icon=dicom-archived]) .first:after {
  content: "\\EA1F";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=l][icon=dicom-archived]) .second:before {
  content: "\\EA20";
  color: #ffffff;
}

:host([color][size=l][icon=dicom-archived]) .second:before {
  content: "\\EA20";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=dicom-failed]) .first:before {
  content: "\\EA1B";
}

:host([size=xs][icon=dicom-failed]) .first:after {
  content: "\\EA21";
}

:host([icon=dicom-failed]) .first:before {
  content: "\\EA07";
}

:host([icon=dicom-failed]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=dicom-failed]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=dicom-failed]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=dicom-failed]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host(:not([color])[size=xs][icon=dicom-failed]) .first:after {
  content: "\\EA21";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=xs][icon=dicom-failed]) .second:before {
  content: "\\EA23";
  color: #ffffff;
}

:host([color][size=xs][icon=dicom-failed]) .second:before {
  content: "\\EA23";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=dicom-failed]) .first:before {
  content: "\\EA1E";
}

:host([size=s][icon=dicom-failed]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[size=s][icon=dicom-failed]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=s][icon=dicom-failed]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([color][size=s][icon=dicom-failed]) .second:before {
  content: "\\EA25";
  color: rgb(var(--ui-7));
}

:host([size=l][icon=dicom-failed]) .first:before {
  content: "\\EA1E";
}

:host([size=l][icon=dicom-failed]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[size=l][icon=dicom-failed]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=l][icon=dicom-failed]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([color][size=l][icon=dicom-failed]) .second:before {
  content: "\\EA25";
  color: rgb(var(--ui-7));
}

:host([icon=dicom-sending-in-progress]) .first:before {
  content: "\\EA07";
}

:host([icon=dicom-sending-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=dicom-sending-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=dicom-sending-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=dicom-sending-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=dicom-sending-in-progress]) .first:before {
  content: "\\EA1B";
}

:host([size=xs][icon=dicom-sending-in-progress]) .first:after {
  content: "\\EA26";
}

:host(:not([color])[size=xs][icon=dicom-sending-in-progress]) .first:after {
  content: "\\EA26";
  color: #52c1ff;
}

:host(:not([color])[size=xs][icon=dicom-sending-in-progress]) .second:before {
  content: "\\EA27";
  color: #000000;
}

:host([color][size=xs][icon=dicom-sending-in-progress]) .second:before {
  content: "\\EA27";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=dicom-sending-in-progress]) .first:before {
  content: "\\EA1E";
}

:host([size=s][icon=dicom-sending-in-progress]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=s][icon=dicom-sending-in-progress]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=s][icon=dicom-sending-in-progress]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=s][icon=dicom-sending-in-progress]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([size=l][icon=dicom-sending-in-progress]) .first:before {
  content: "\\EA1E";
}

:host([size=l][icon=dicom-sending-in-progress]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=l][icon=dicom-sending-in-progress]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=l][icon=dicom-sending-in-progress]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=l][icon=dicom-sending-in-progress]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([icon=dicom-information]) .first:before {
  content: "\\EA07";
}

:host([icon=dicom-information]) .first:after {
  content: "\\EA0A";
}

:host(:not([color])[icon=dicom-information]) .first:after {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=dicom-information]) .second:before {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=dicom-information]) .second:before {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=order-information]) .first:before {
  content: "\\EADD";
}

:host([icon=order-information]) .first:after {
  content: "\\EA0A";
}

:host(:not([color])[icon=order-information]) .first:after {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=order-information]) .second:before {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=order-information]) .second:before {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=samples-information]) .first:before {
  content: "\\EAE2";
}

:host([icon=samples-information]) .first:after {
  content: "\\EAE3";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=samples-information]) .second:before {
  content: "\\EA0A";
}

:host(:not([color])[icon=samples-information]) .second:before {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=samples-information]) .second:after {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=samples-information]) .second:after {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=export-failed]) .first:before {
  content: "\\EA2C";
}

:host([icon=export-failed]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=export-failed]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=export-failed]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=export-failed]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=export-failed]) .first:before {
  content: "\\EA2A";
}

:host([size=xs][icon=export-failed]) .first:after {
  content: "\\EA21";
}

:host(:not([color])[size=xs][icon=export-failed]) .first:after {
  content: "\\EA21";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=xs][icon=export-failed]) .second:before {
  content: "\\EA23";
  color: #ffffff;
}

:host([color][size=xs][icon=export-failed]) .second:before {
  content: "\\EA23";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=export-failed]) .first:before {
  content: "\\EA2B";
}

:host([size=s][icon=export-failed]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[size=s][icon=export-failed]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=s][icon=export-failed]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([color][size=s][icon=export-failed]) .second:before {
  content: "\\EA25";
  color: rgb(var(--ui-7));
}

:host([size=l][icon=export-failed]) .first:before {
  content: "\\EA2B";
}

:host([size=l][icon=export-failed]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[size=l][icon=export-failed]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=l][icon=export-failed]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([color][size=l][icon=export-failed]) .second:before {
  content: "\\EA25";
  color: rgb(var(--ui-7));
}

:host([icon=export-finished]) .first:before {
  content: "\\EA2C";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([icon=export-finished]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=export-finished]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=export-finished]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=export-finished]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=export-finished]) .first:before {
  content: "\\EA2A";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([size=xs][icon=export-finished]) .first:after {
  content: "\\EA1C";
}

:host(:not([color])[size=xs][icon=export-finished]) .first:after {
  content: "\\EA1C";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=xs][icon=export-finished]) .second:before {
  content: "\\EA1D";
  color: #ffffff;
}

:host([color][size=xs][icon=export-finished]) .second:before {
  content: "\\EA1D";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=export-finished]) .first:before {
  content: "\\EA2B";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([size=s][icon=export-finished]) .first:after {
  content: "\\EA1F";
}

:host(:not([color])[size=s][icon=export-finished]) .first:after {
  content: "\\EA1F";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=s][icon=export-finished]) .second:before {
  content: "\\EA20";
  color: #ffffff;
}

:host([color][size=s][icon=export-finished]) .second:before {
  content: "\\EA20";
  color: rgb(var(--ui-7));
}

:host([size=l][icon=export-finished]) .first:before {
  content: "\\EA2B";
  color: rgba(var(--ui-1), var(--opacity-1));
}

:host([size=l][icon=export-finished]) .first:after {
  content: "\\EA1F";
}

:host(:not([color])[size=l][icon=export-finished]) .first:after {
  content: "\\EA1F";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=l][icon=export-finished]) .second:before {
  content: "\\EA20";
  color: #ffffff;
}

:host([color][size=l][icon=export-finished]) .second:before {
  content: "\\EA20";
  color: rgb(var(--ui-7));
}

:host([icon=export-in-progress]) .first:before {
  content: "\\EA2C";
}

:host([icon=export-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=export-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=export-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=export-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=export-in-progress]) .first:before {
  content: "\\EA2A";
}

:host([size=xs][icon=export-in-progress]) .first:after {
  content: "\\EA26";
}

:host(:not([color])[size=xs][icon=export-in-progress]) .first:after {
  content: "\\EA26";
  color: #52c1ff;
}

:host(:not([color])[size=xs][icon=export-in-progress]) .second:before {
  content: "\\EA27";
  color: #000000;
}

:host([color][size=xs][icon=export-in-progress]) .second:before {
  content: "\\EA27";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=export-in-progress]) .first:before {
  content: "\\EA2B";
}

:host([size=s][icon=export-in-progress]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=s][icon=export-in-progress]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=s][icon=export-in-progress]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=s][icon=export-in-progress]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([size=l][icon=export-in-progress]) .first:before {
  content: "\\EA2B";
}

:host([size=l][icon=export-in-progress]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=l][icon=export-in-progress]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=l][icon=export-in-progress]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=l][icon=export-in-progress]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([icon=print-completed]) .first:before {
  content: "\\E9CB";
}

:host([icon=print-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=print-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=print-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=print-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=print-completed]) .first:before {
  content: "\\EA57";
}

:host([size=s][icon=print-completed]) .first:after {
  content: "\\EA1F";
}

:host(:not([color])[size=s][icon=print-completed]) .first:after {
  content: "\\EA1F";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=s][icon=print-completed]) .second:before {
  content: "\\EA20";
  color: #ffffff;
}

:host([color][size=s][icon=print-completed]) .second:before {
  content: "\\EA20";
  color: rgb(var(--ui-7));
}

:host([icon=print-error]) .first:before {
  content: "\\E9CB";
}

:host([icon=print-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=print-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=print-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=print-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=print-error]) .first:before {
  content: "\\EA57";
}

:host([size=s][icon=print-error]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[size=s][icon=print-error]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=s][icon=print-error]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([size=s][icon=print-error]) .second:before {
  content: "\\EA25";
  color: rgb(var(--ui-7));
}

:host([icon=patient-error]) .first:before {
  content: "\\EABE";
}

:host([icon=patient-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=patient-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=patient-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=patient-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=print-in-progress]) .first:before {
  content: "\\E9CB";
}

:host([icon=print-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=print-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=print-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=print-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=patient-in-progress]) .first:before {
  content: "\\EABE";
}

:host([icon=patient-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=patient-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=patient-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=patient-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=print-in-progress]) .first:before {
  content: "\\EA57";
}

:host([size=s][icon=print-in-progress]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=s][icon=print-in-progress]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=s][icon=print-in-progress]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=s][icon=print-in-progress]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([icon=image-processing-error]) .first:before {
  content: "\\EA3D";
}

:host([icon=image-processing-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=image-processing-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=image-processing-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=image-processing-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=open-tasks-error]) .first:before {
  content: "\\EA52";
}

:host([icon=open-tasks-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=open-tasks-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=open-tasks-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=open-tasks-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=addin-info]) .first:before {
  content: "\\E055";
}

:host([icon=addin-info]) .first:after {
  content: "\\EA0A";
}

:host(:not([color])[icon=addin-info]) .first:after {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=addin-info]) .second:before {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=addin-info]) .second:before {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=addin-warning]) .first:before {
  content: "\\E055";
}

:host([icon=addin-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=addin-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=addin-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=addin-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=auto-align-scout-failed]) .first:before {
  content: "\\E069";
}

:host([icon=auto-align-scout-failed]) .first:after {
  content: "\\EA0A";
}

:host([icon=close-patient]) .first:before,
:host([icon=patient-success]) .first:before,
:host([icon=patient-completed]) .first:before {
  content: "\\E0B1";
}

:host([icon=close-patient]) .first:after,
:host([icon=patient-success]) .first:after,
:host([icon=patient-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=close-patient]) .first:after,
:host(:not([color])[icon=patient-success]) .first:after,
:host(:not([color])[icon=patient-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=close-patient]) .second:before,
:host(:not([color])[icon=patient-success]) .second:before,
:host(:not([color])[icon=patient-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=close-patient]) .second:before,
:host([color][icon=patient-success]) .second:before,
:host([color][icon=patient-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=patient-warning]) .first:before {
  content: "\\E0B1";
}

:host([icon=patient-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=patient-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=patient-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=patient-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=patient-status-unknown]) .first:before {
  content: "\\E0B1";
}

:host([icon=patient-status-unknown]) .first:after {
  content: "\\EA06";
}

:host(:not([color])[icon=patient-status-unknown]) .first:after {
  content: "\\EA06";
  color: #6e7b9f;
}

:host(:not([color])[icon=patient-status-unknown]) .second:before {
  content: "\\EA05";
  color: #ffffff;
}

:host([color][icon=patient-status-unknown]) .second:before {
  content: "\\EA05";
  color: rgb(var(--ui-7));
}

:host([icon=copy-reference-target-busy]) .first:before {
  content: "\\E0D7";
}

:host([icon=copy-reference-target-busy]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=copy-reference-target-busy]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=copy-reference-target-busy]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([icon=copy-reference-target-error]) .first:before {
  content: "\\E0D7";
}

:host([icon=copy-reference-target-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=copy-reference-target-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=copy-reference-target-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([icon=copy-reference-target-warning]) .first:before {
  content: "\\E0D7";
}

:host([icon=copy-reference-target-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=copy-reference-target-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=copy-reference-target-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([icon=mdt-done]) .first:before {
  content: "\\E967";
}

:host([icon=mdt-done]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=mdt-done]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=mdt-done]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([icon=low-frame-rate]) .first:before {
  content: "\\E9E0";
}

:host([icon=low-frame-rate]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=low-frame-rate]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=low-frame-rate]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([icon=cloud-sync]) .first:before {
  content: "\\E9EF";
}

:host([icon=cloud-sync]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=cloud-sync]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=cloud-sync]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=cloud-sync]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=cloud-completed]) .first:before {
  content: "\\E9EF";
}

:host([icon=cloud-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=cloud-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=cloud-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=cloud-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=cloud-warning]) .first:before {
  content: "\\E9EF";
}

:host([icon=cloud-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=cloud-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=cloud-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([icon=cloud-error]) .first:before {
  content: "\\E9EF";
}

:host([icon=cloud-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=cloud-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=cloud-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=cloud-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=show-all-monitors-error]) .first:before {
  content: "\\EAA4";
}

:host([color][icon=show-all-monitors-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=show-all-monitors-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=show-all-monitors-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=show-all-monitors-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=show-all-monitors-warning]) .first:before {
  content: "\\EAA4";
}

:host([icon=show-all-monitors-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=show-all-monitors-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=show-all-monitors-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=show-all-monitors-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=match]) .first:before {
  content: "\\EAB4";
}

:host([color][icon=match]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=match]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=match]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=match]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=shopping-cart-success]) .first:before {
  content: "\\EB3F";
}

:host([icon=shopping-cart-success]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=shopping-cart-success]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=shopping-cart-success]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=shopping-cart-success]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=scanner-error]) .first:before {
  content: "\\EB4D";
}

:host([icon=scanner-error]) .first:after {
  content: "\\EB4E";
}

:host(:not([color])[icon=scanner-error]) .first:after {
  content: "\\EB4E";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=scanner-error]) .second:before {
  content: "\\EB4F";
  color: #ffffff;
}

:host([color][icon=scanner-error]) .second:before {
  content: "\\EB4F";
  color: rgb(var(--ui-7));
}

:host([icon=scanner-success]) .first:before {
  content: "\\EB4D";
}

:host([icon=scanner-success]) .first:after {
  content: "\\EB49";
}

:host(:not([color])[icon=scanner-success]) .first:after {
  content: "\\EB49";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=scanner-success]) .second:before {
  content: "\\EB4A";
  color: #ffffff;
}

:host([color][icon=scanner-success]) .second:before {
  content: "\\EB4A";
  color: rgb(var(--ui-7));
}

:host([icon=scanner-in-progress]) .first:before {
  content: "\\EB4D";
}

:host([icon=scanner-in-progress]) .first:after {
  content: "\\EB4C";
}

:host(:not([color])[icon=scanner-in-progress]) .first:after {
  content: "\\EB4B";
  color: #000000;
}

:host(:not([color])[icon=scanner-in-progress]) .second:before {
  content: "\\EB4C";
  color: #52c1ff;
}

:host([color][icon=scanner-in-progress]) .second:before {
  content: "\\EB4B";
  color: rgb(var(--ui-7));
}

:host([icon=temperature-error]) .first:before {
  content: "\\EB55";
}

:host([icon=temperature-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=temperature-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=temperature-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=temperature-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=temperature-warning]) .first:before {
  content: "\\EB53";
}

:host([icon=temperature-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=temperature-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=temperature-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=temperature-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=temperature-success]) .first:before {
  content: "\\EB54";
}

:host([icon=temperature-success]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=temperature-success]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=temperature-success]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=temperature-success]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=acquisition-error]) .first:before,
:host([icon=tube-error]) .first:before {
  content: "\\EB52";
}

:host([icon=acquisition-error]) .first:after,
:host([icon=tube-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=acquisition-error]) .first:after,
:host(:not([color])[icon=tube-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=acquisition-error]) .second:before,
:host(:not([color])[icon=tube-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=acquisition-error]) .second:before,
:host([color][icon=tube-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=wifi-error]) .first:before {
  content: "\\EB69";
}

:host([icon=wifi-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=wifi-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=wifi-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=wifi-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=ethernet-error]) .first:before {
  content: "\\EB8A";
}

:host([icon=ethernet-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=ethernet-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=ethernet-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=ethernet-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=signal-strength-error]) .first:before {
  content: "\\EB88";
}

:host([icon=signal-strength-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=signal-strength-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=signal-strength-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=signal-strength-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=network-export-error]) .first:before {
  content: "\\EBA8";
}

:host([icon=network-export-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=network-export-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=network-export-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=network-export-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=network-import-error]) .first:before {
  content: "\\EBDF";
}

:host([icon=network-import-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=network-import-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=network-import-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=network-import-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=usb-export-error]) .first:before {
  content: "\\EBA7";
}

:host([icon=usb-export-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=usb-export-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=usb-export-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=usb-export-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=usb-import-error]) .first:before {
  content: "\\EBDD";
}

:host([icon=usb-import-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=usb-import-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=usb-import-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=usb-import-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=acquisition-warning]) .first:before,
:host([icon=tube-warning]) .first:before {
  content: "\\EB52";
}

:host([icon=acquisition-warning]) .first:after,
:host([icon=tube-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=acquisition-warning]) .first:after,
:host(:not([color])[icon=tube-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=acquisition-warning]) .second:before,
:host(:not([color])[icon=tube-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=acquisition-warning]) .second:before,
:host([color][icon=tube-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=acquisition-ready]) .first:before,
:host([icon=tube-ready]) .first:before {
  content: "\\EB52";
}

:host([icon=acquisition-ready]) .first:after,
:host([icon=tube-ready]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=acquisition-ready]) .first:after,
:host(:not([color])[icon=tube-ready]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=acquisitione-ready]) .second:before,
:host(:not([color])[icon=tube-ready]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=acquisition-ready]) .second:before,
:host([color][icon=tube-ready]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=read]) .first:before {
  content: "\\EB5A";
}

:host([icon=read]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=read]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=read]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=read]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=medications-completed]) .first:before {
  content: "\\EB92";
}

:host([icon=medications-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=medications-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=medications-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=medications-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=medications-warning]) .first:before {
  content: "\\EB92";
}

:host([icon=medications-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=medications-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=medications-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=medications-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=medications-error]) .first:before {
  content: "\\EB92";
}

:host([icon=medications-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=medications-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=medications-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=medications-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=medications-in-progress]) .first:before {
  content: "\\EB92";
}

:host([icon=medications-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=medications-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=medications-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=medications-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=tablet-horizontal-error]) .first:before {
  content: "\\EBC2";
}

:host([icon=tablet-horizontal-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=tablet-horizontal-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=tablet-horizontal-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=tablet-horizontal-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=door-open-warning]) .first:before {
  content: "\\EBEE";
}

:host([icon=door-open-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=door-open-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=door-open-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=door-open-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=range-radial-volume]) .first:before {
  content: "\\EC42";
}

:host([icon=range-radial-volume]) .first:after {
  content: "\\EC43";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=range-radial-volume]) .second:before {
  content: "\\EC44";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=range-radial-slice]) .first:before {
  content: "\\EC3F";
}

:host([icon=range-radial-slice]) .first:after {
  content: "\\EC40";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=range-radial-slice]) .second:before {
  content: "\\EC41";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=range-parallel]) .first:before {
  content: "\\EC3C";
}

:host([icon=range-parallel]) .first:after {
  content: "\\EC3D";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=range-parallel]) .second:before {
  content: "\\EC3E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=range-curved]) .first:before {
  content: "\\EC39";
}

:host([icon=range-curved]) .first:after {
  content: "\\EC3A";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=range-curved]) .second:before {
  content: "\\EC3B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=range-shifted-curve]) .first:before {
  content: "\\EC45";
}

:host([icon=range-shifted-curve]) .first:after {
  content: "\\EC46";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=range-shifted-curve]) .second:before {
  content: "\\EC47";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=radiation-lock]) .first:before {
  content: "\\EC5B";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=radiation-lock]) .first:after {
  content: "\\EC5C";
}

:host([size=xs][icon=analyzer]) .first:before {
  content: "\\EC58";
}

:host([size=s][icon=analyzer]) .first:before {
  content: "\\EC59";
}

:host([icon=analyzer]) .first:before {
  content: "\\EC5A";
}

:host([size=xs][icon=recommendation]) .first:before {
  content: "\\EC55";
}

:host([size=s][icon=recommendation]) .first:before {
  content: "\\EC56";
}

:host([icon=recommendation]) .first:before {
  content: "\\EC57";
}

:host([icon=no-findings]) .first:before {
  content: "\\EC62";
}

:host([icon=analyzer-error][size=s]) .first:before {
  content: "\\EC5F";
}

:host([icon=analyzer-error][size=s]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[icon=analyzer-error][size=s]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=analyzer-error][size=s]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([icon=analyzer-error]) .first:before {
  content: "\\EC60";
}

:host([icon=analyzer-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=analyzer-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=analyzer-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([icon=group]) .first:before {
  content: "\\EC61";
}

:host([icon=ungroup]) .first:before {
  content: "\\EC64";
}

:host([icon=projector]) .first:before {
  content: "\\EC63";
}

:host([icon=dual-energy-information]) .first:before {
  content: "\\EC5D";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=dual-energy-information]) .first:after {
  content: "\\EC5E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host(:not([color])[icon=dual-energy-information]) .second:before {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=dual-energy-information]) .second:after {
  content: "\\EA09";
  color: #ffffff;
}

:host([icon=dual-energy-information]) .second:before {
  content: "\\EA0A";
}

:host([icon=dual-energy-information]) .second:after {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=duplicate-step]) .first:before {
  content: "\\EC53";
}

:host([icon=detector-series]) .first:before {
  content: "\\EC67";
}

:host([icon=brush]) .first:before {
  content: "\\EC66";
}

:host([icon=body-registration-anterior-posterior-left]) .first:before {
  content: "\\EC65";
}

:host([icon=injection-information]) .first:before {
  content: "\\EC69";
}

:host([icon=injection-information]) .first:after {
  content: "\\EA0A";
}

:host(:not([color])[icon=injection-information]) .first:after {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=injection-information]) .second:before {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=injection-information]) .second:before {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=injection-information]) .first:before {
  content: "\\EC68";
}

:host([size=s][icon=injection-information]) .first:after {
  content: "\\EC6A";
}

:host(:not([color])[size=s][icon=injection-information]) .first:after {
  content: "\\EC6A";
  color: #6e7b9f;
}

:host(:not([color])[size=s][icon=injection-information]) .second:before {
  content: "\\EC6B";
  color: #ffffff;
}

:host([color][size=s][icon=injection-information]) .second:before {
  content: "\\EC6B";
  color: rgb(var(--ui-7));
}

:host([icon=network-status-error]) .first:before {
  content: "\\EC6C";
}

:host([icon=network-status-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=network-status-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=network-status-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=network-status-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=network-status-level-1]) .first:before {
  content: "\\EC71";
}

:host([icon=network-status-level-1]) .first:after {
  content: "\\EC72";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=network-status-level-2]) .first:before {
  content: "\\EC6F";
}

:host([icon=network-status-level-2]) .first:after {
  content: "\\EC70";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=network-status-level-3]) .first:before {
  content: "\\EC6D";
}

:host([icon=network-status-level-3]) .first:after {
  content: "\\EC6E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=video-analog]) .first:before {
  content: "\\EC7B";
}

:host([size=s][icon=video-analog]) .first:before {
  content: "\\EC7A";
}

:host([size=xs][icon=video-analog]) .first:before {
  content: "\\EC79";
}

:host([icon=thumbnail-status-4d]) .first:before {
  content: "\\EC78";
}

:host([size=s][icon=thumbnail-status-4d]) .first:before {
  content: "\\EC77";
}

:host([size=xs][icon=thumbnail-status-4d]) .first:before {
  content: "\\EC76";
}

:host([icon=thumbnail-status-3d]) .first:before {
  content: "\\EC75";
}

:host([size=s][icon=thumbnail-status-3d]) .first:before {
  content: "\\EC74";
}

:host([size=xs][icon=thumbnail-status-3d]) .first:before {
  content: "\\EC73";
}

:host([icon=patient-emergency-add]) .first:before {
  content: "\\EC54";
}

:host([icon=exposure-correction]) .first:before {
  content: "\\EC95";
}

:host([icon=block-movement]) .first:before {
  content: "\\EC94";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=block-movement]) .first:after {
  content: "\\ECCA";
}

:host([icon=copy-results-to-clipboard]) .first:before {
  content: "\\EC93";
}

:host([icon=timer-cancel]) .first:before {
  content: "\\EC92";
}

:host([icon=grid]) .first:before {
  content: "\\EC91";
}

:host([icon=block-radiation]) .first:before {
  content: "\\ECAF";
}

:host([icon=block-radiation]) .first:after {
  content: "\\EC90";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chamber-all]) .first:before {
  content: "\\EC8F";
}

:host([icon=chamber-left-middle]) .first:before {
  content: "\\EC8D";
}

:host([icon=chamber-left-middle]) .first:after {
  content: "\\EC8E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chamber-left-right]) .first:before {
  content: "\\EC8B";
}

:host([icon=chamber-left-right]) .first:after {
  content: "\\EC8C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chamber-middle-right]) .first:before {
  content: "\\EC89";
}

:host([icon=chamber-middle-right]) .first:after {
  content: "\\EC8A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=collimation-rotated]) .first:before {
  content: "\\EC88";
}

:host([icon=detector-tray-empty]) .first:before {
  content: "\\EC87";
}

:host([icon=grid-off]) .first:before {
  content: "\\EC86";
}

:host([icon=patient-size-x-large]) .first:before {
  content: "\\EC85";
}

:host([icon=remote-pager]) .first:before {
  content: "\\EC84";
}

:host([icon=sid]) .first:before {
  content: "\\EC83";
}

:host([icon=size-collimation]) .first:before {
  content: "\\EC82";
}

:host([icon=sod-measure]) .first:before {
  content: "\\EC81";
}

:host([icon=table-ysio]) .first:before {
  content: "\\EC80";
}

:host([icon=top-alignment]) .first:before {
  content: "\\EC7F";
}

:host([icon=wallstand-angle]) .first:before {
  content: "\\EC97";
}

:host([icon=wallstand-angle]) .first:after {
  content: "\\EC7E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=magnification-factor]) .first:before {
  content: "\\EC99";
}

:host([icon=magnification-factor]) .first:after {
  content: "\\EC98";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=workstation-wall]) .first:before {
  content: "\\EC7C";
}

:host([icon=workstation-wall]) .first:after {
  content: "\\EC7D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=data-edit]) .first:before {
  content: "\\EC96";
}

:host([size=s][icon=data-edit]) .first:before {
  content: "\\EF12";
}

:host([icon=tube-detector-angle-vertical]) .first:before {
  content: "\\ECAE";
}

:host([icon=tube-detector-angle-horizontal]) .first:before {
  content: "\\ECAD";
}

:host([icon=tube-angle-vertical]) .first:before {
  content: "\\ECAC";
}

:host([icon=tube-angle-horizontal]) .first:before {
  content: "\\ECAB";
}

:host([icon=table-height-measure]) .first:before {
  content: "\\ECAA";
}

:host([icon=radiographic-aid]) .first:before {
  content: "\\ECA9";
}

:host([icon=ortho-tube-rot-start]) .first:before {
  content: "\\ECA8";
}

:host([icon=ortho-tube-rot-start]) .first:after {
  content: "\\ECA7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=ortho-tube-rot-end]) .first:before {
  content: "\\ECA6";
}

:host([icon=ortho-tube-rot-end]) .first:after {
  content: "\\ECA5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=legal-information]) .first:before {
  content: "\\ECA4";
}

:host([icon=detector-tray-portrait]) .first:before {
  content: "\\ECA3";
}

:host([icon=detector-tray-landscape]) .first:before {
  content: "\\ECA2";
}

:host([icon=detector-square-angle-longitudinal]) .first:before {
  content: "\\ECA1";
}

:host([icon=detector-square-angle-lateral]) .first:before {
  content: "\\ECA0";
}

:host([icon=detector-small-angle-longitudinal]) .first:before {
  content: "\\EC9F";
}

:host([icon=detector-small-angle-lateral]) .first:before {
  content: "\\EC9E";
}

:host([icon=detector-handle-angle-longitudinal]) .first:before {
  content: "\\EC9D";
}

:host([icon=detector-handle-angle-lateral]) .first:before {
  content: "\\EC9C";
}

:host([icon=detector-angle-longitudinal]) .first:before {
  content: "\\EC9B";
}

:host([icon=detector-angle-lateral]) .first:before {
  content: "\\EC9A";
}

:host([icon=studies-reject]) .first:before {
  content: "\\ECBA";
}

:host([icon=photo-documentation]) .first:before {
  content: "\\ECB9";
}

:host([icon=lung-lobe]) .first:before {
  content: "\\ECB8";
}

:host([icon=lung-lobe]) .first:after {
  content: "\\ECB7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=document-xls]) .first:before {
  content: "\\ECB6";
}

:host([icon=document-txt]) .first:before {
  content: "\\ECB5";
}

:host([icon=document-reject]) .first:before {
  content: "\\ECB4";
}

:host([icon=document-ppt]) .first:before {
  content: "\\ECB3";
}

:host([icon=document-pdf]) .first:before,
:host([icon=pdf]) .first:before {
  content: "\\ECB2";
}

:host([icon=document-docx]) .first:before {
  content: "\\ECB1";
}

:host([icon=contouring-series]) .first:before {
  content: "\\ECB0";
}

:host([icon=log-phase-forward]) .first:before {
  content: "\\ECBF";
}

:host([size=s][icon=log-phase-forward]) .first:before {
  content: "\\ECBE";
}

:host([icon=vessel-coronary-ffr-marker]) .first:before {
  content: "\\ECC0";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=vessel-coronary-ffr-marker]) .first:after {
  content: "\\ECC1";
}

:host([color][icon=warning-triangle]) .first:before {
  content: "\\ECC6";
}

:host([color][icon=warning-triangle]) .first:after {
  content: "\\ECC5";
  color: rgb(var(--ui-7));
}

:host([color="#E7001D"][icon=warning-triangle]) .first:after {
  content: "\\ECC5";
  color: #eaeaea;
}

:host([color="#FFD200"][icon=warning-triangle]) .first:after {
  content: "\\ECC5";
  color: #000000;
}

:host(:not([color])[icon=warning-triangle]) .first:before {
  content: "\\ECC6";
  color: rgba(var(--functional-yellow), var(--opacity-1));
}

:host(:not([color])[icon=warning-triangle]) .first:after {
  content: "\\ECC5";
  color: rgb(0, 0, 0);
}

:host([size=xs][color][icon=warning-triangle]) .first:before {
  content: "\\ECF6";
}

:host([size=xs][color][icon=warning-triangle]) .first:after {
  content: "\\ECF7";
  color: rgb(var(--ui-7));
}

:host([size=xs][color="#E7001D"][icon=warning-triangle]) .first:after {
  content: "\\ECF7";
  color: #eaeaea;
}

:host([size=xs][color="#FFD200"][icon=warning-triangle]) .first:after {
  content: "\\ECF7";
  color: rgb(0, 0, 0);
}

:host([size=xs]:not([color])[icon=warning-triangle]) .first:before {
  content: "\\ECF6";
  color: rgba(var(--functional-yellow), var(--opacity-1));
}

:host([size=xs]:not([color])[icon=warning-triangle]) .first:after {
  content: "\\ECF7";
  color: black;
}

:host([icon=patient-observation]) .first:before {
  content: "\\ECC4";
}

:host([icon=patient-observation]) .first:after {
  content: "\\ECC3";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=coil-warning]) .first:before {
  content: "\\ECC2";
}

:host([icon=coil-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=coil-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=coil-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=coil-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=rotation-stepwise-r-l-axis]) .first:before {
  content: "\\ECF0";
}

:host([icon=rotation-stepwise-r-l-axis]) .first:after {
  content: "\\ECF1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=rotation-stepwise-cran-caud-axis]) .first:before {
  content: "\\ECEE";
}

:host([icon=rotation-stepwise-cran-caud-axis]) .first:after {
  content: "\\ECEF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=rotation-stepwise-a-p-axis]) .first:before {
  content: "\\ECDF";
}

:host([icon=rotation-stepwise-a-p-axis]) .first:after {
  content: "\\ECE0";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=timelens]) .first:before {
  content: "\\ECDD";
}

:host([icon=target-pointer]) .first:before {
  content: "\\ECDB";
}

:host([icon=target-pointer]) .first:after {
  content: "\\ECDC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=stroke-cockpit]) .first:before {
  content: "\\ECD9";
}

:host([icon=stroke-cockpit]) .first:after {
  content: "\\ECDA";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=translation-r-l-axis]) .first:before {
  content: "\\ECD7";
}

:host([icon=translation-r-l-axis]) .first:after {
  content: "\\ECD8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=translation-cran-caud-axis]) .first:before {
  content: "\\ECD5";
}

:host([icon=translation-cran-caud-axis]) .first:after {
  content: "\\ECD6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=translation-a-p-axis]) .first:before {
  content: "\\ECD3";
}

:host([icon=translation-a-p-axis]) .first:after {
  content: "\\ECD4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=registration-auto]) .first:before {
  content: "\\ECD1";
}

:host([icon=registration-auto]) .first:after {
  content: "\\ECD2";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=headscanner-drive-mode-icu]) .first:before {
  content: "\\ECD0";
}

:host([icon=ambulance]) .first:before {
  content: "\\ECC9";
}

:host([icon=align-patient-manual]) .first:before {
  content: "\\ECC7";
}

:host([icon=align-patient-manual]) .first:after {
  content: "\\ECC8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cursor]) .first:before {
  content: "\\ECCB";
}

:host([icon=vertical-long-axis]) .first:before {
  content: "\\ECE3";
}

:host([icon=vertical-long-axis]) .second:before {
  content: "\\ECE4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=short-axis]) .first:before {
  content: "\\ECE1";
}

:host([icon=short-axis]) .second:before {
  content: "\\ECE2";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=job-view-error]) .first:before {
  content: "\\ECE5";
}

:host([icon=job-view-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=job-view-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=job-view-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=job-view-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=job-view-error]) .first:before {
  content: "\\ECDE";
}

:host([size=xs][icon=job-view-error]) .first:after {
  content: "\\EA21";
}

:host([size=xs]:not([color])[icon=job-view-error]) .first:after {
  content: "\\EA21";
  color: rgb(var(--functional-red));
}

:host([size=xs]:not([color])[icon=job-view-error]) .second:before {
  content: "\\EA23";
  color: #ffffff;
}

:host([size=xs][color][icon=job-view-error]) .second:before {
  content: "\\EA23";
  color: rgb(var(--ui-7));
}

:host([icon=horizontal-long-axis]) .first:before {
  content: "\\ECCE";
}

:host([icon=horizontal-long-axis]) .first:after {
  content: "\\ECCF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=arrow-face-left-l]) .first:before {
  content: "\\ECCC";
}

:host([icon=arrow-face-right-l]) .first:before {
  content: "\\ECCD";
}

:host([icon=scanner-search]) .first:before {
  content: "\\ECE9";
}

:host([icon=scanner-history]) .first:before {
  content: "\\ECE8";
}

:host([size=s] [icon=high-priority]) .first:before,
:host([size=s] [icon=high-prority]) .first:before {
  content: "\\ECE6";
}

:host([icon=high-priority]) .first:before,
:host([icon=high-prority]) .first:before {
  content: "\\ECE7";
}

:host([icon=patient-information]) .first:before {
  content: "\\E0B1";
}

:host([icon=patient-information]) .first:after {
  content: "\\EA0A";
}

:host(:not([color])[icon=patient-information]) .first:after {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=patient-information]) .second:before {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=patient-information]) .second:before {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=privacy]) .first:before {
  content: "\\ECF4";
}

:host([size=xs][icon=privacy]) .first:before {
  content: "\\ECF2";
}

:host([size=s][icon=privacy]) .first:before {
  content: "\\ECF3";
}

:host([size=l][icon=privacy]) .first:before {
  content: "\\ECF5";
}

:host([icon=in-plane-view]) .first:before {
  content: "\\ECED";
}

:host([icon=compare-image]) .first:before {
  content: "\\ECEB";
}

:host([icon=attribute-group-add]) .first:before {
  content: "\\ECEA";
}

:host([icon=leg-measure]) .first:before {
  content: "\\ECFE";
}

:host([icon=leg-measure]) .first:after {
  content: "\\ED00";
}

:host([icon=leg-measure]) .second:before {
  content: "\\ECFF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=needle-detection-view]) .first:before {
  content: "\\ECFC";
}

:host([icon=needle-detection-view]) .first:after {
  content: "\\ECFD";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=orientation-view]) .first:before {
  content: "\\ECFA";
}

:host([icon=orientation-view]) .first:after {
  content: "\\ECFB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=ortho-view]) .first:before {
  content: "\\ECF8";
}

:host([icon=ortho-view]) .first:after {
  content: "\\ECF9";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=plaque-options]) .first:before {
  content: "\\ED02";
}

:host([icon=plaque-options]) .first:after {
  content: "\\ED01";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=total-plaque-burden]) .first:before {
  content: "\\ED14";
}

:host([icon=total-plaque-burden]) .first:after {
  content: "\\ED15";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=structure-margin]) .first:before {
  content: "\\ED13";
}

:host([icon=structure-lesion-add]) .first:before {
  content: "\\ED11";
}

:host([icon=structure-lesion-add]) .first:after {
  content: "\\ED12";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=save-to-radiation-field-center]) .first:before {
  content: "\\ED10";
}

:host([icon=panel]) .first:before {
  content: "\\ED0F";
}

:host([icon=lesion-finding]) .first:before {
  content: "\\ED0D";
}

:host([icon=lesion-finding]) .first:after {
  content: "\\ED0E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=export-plaque-results]) .first:before {
  content: "\\ED0B";
}

:host([icon=export-plaque-results]) .first:after {
  content: "\\ED0C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=event-study-available]) .first:before {
  content: "\\ED09";
}

:host([icon=event-study-available]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=event-study-available]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=event-study-available]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=event-study-available]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=evaluation-results]) .first:before {
  content: "\\ED07";
}

:host([icon=evaluation-results]) .first:after {
  content: "\\ED08";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=beam]) .first:before {
  content: "\\ED05";
}

:host([icon=beam]) .first:after {
  content: "\\ED06";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=three-d-mesh-view]) .first:before {
  content: "\\ED03";
}

:host([icon=three-d-mesh-view]) .first:after {
  content: "\\ED04";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lesions-show]) .first:before,
:host([icon=show-lesions]) .first:before {
  content: "\\ED36";
}

:host([icon=lesions-show]) .first:after,
:host([icon=show-lesions]) .first:after {
  content: "\\ED37";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lumen-show]) .first:before,
:host([icon=show-lumen]) .first:before {
  content: "\\ED34";
}

:host([icon=lumen-show]) .first:after,
:host([icon=show-lumen]) .first:after {
  content: "\\ED35";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=plaque-show]) .first:before,
:host([icon=show-plaque]) .first:before {
  content: "\\ED32";
}

:host([icon=plaque-show]) .first:after,
:host([icon=show-plaque]) .first:after {
  content: "\\ED33";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=stent-show]) .first:before,
:host([icon=show-stent]) .first:before {
  content: "\\ED30";
}

:host([icon=stent-show]) .first:after,
:host([icon=show-stent]) .first:after {
  content: "\\ED31";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=default-login]) .first:before {
  content: "\\ED2F";
}

:host([icon=lesion-define]) .first:before,
:host([icon=define-lesion]) .first:before {
  content: "\\ED2D";
}

:host([icon=lesion-define]) .first:after,
:host([icon=define-lesion]) .first:after {
  content: "\\ED2E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=stent-finding]) .first:before {
  content: "\\ED2B";
}

:host([icon=stent-finding]) .first:after {
  content: "\\ED2C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=wall-show]) .first:before,
:host([icon=show-wall]) .first:before {
  content: "\\ED29";
}

:host([icon=wall-show]) .first:after,
:host([icon=show-wall]) .first:after {
  content: "\\ED2A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lumen-edit]) .first:before,
:host([icon=edit-lumen]) .first:before {
  content: "\\ED27";
}

:host([icon=lumen-edit]) .first:after,
:host([icon=edit-lumen]) .first:after {
  content: "\\ED28";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=dicom-tag-supported]) .first:before {
  content: "\\ED26";
}

:host([size=xs][icon=dicom-tag-supported]) .first:before {
  content: "\\ED25";
}

:host([icon=dicom-tag-optional]) .first:before {
  content: "\\ED24";
}

:host([size=xs][icon=dicom-tag-optional]) .first:before {
  content: "\\ED23";
}

:host([icon=stent-define]) .first:before,
:host([icon=define-stent]) .first:before {
  content: "\\ED21";
}

:host([icon=stent-define]) .first:after,
:host([icon=define-stent]) .first:after {
  content: "\\ED22";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=emergency-login]) .first:before {
  content: "\\ED20";
}

:host([icon=action-notification-app]) .first:before {
  content: "\\ED1F";
}

:host([size=xs][icon=action-notification-app]) .first:before {
  content: "\\ED1E";
}

:host([icon=wall-edit]) .first:before,
:host([icon=edit-wall]) .first:before {
  content: "\\ED1C";
}

:host([icon=wall-edit]) .first:after,
:host([icon=edit-wall]) .first:after {
  content: "\\ED1D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=vessel-edit]) .first:before,
:host([icon=edit-vessel]) .first:before {
  content: "\\ED1A";
}

:host([icon=vessel-edit]) .first:after,
:host([icon=edit-vessel]) .first:after {
  content: "\\ED1B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=plaque-analysis]) .first:before {
  content: "\\ED16";
}

:host([icon=plaque-analysis]) .first:after {
  content: "\\ED17";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=multi-selection]) .first:before {
  content: "\\ED41";
}

:host([icon=multi-selection]) .first:after {
  content: "\\ED42";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=picture]) .first:before {
  content: "\\ED18";
}

:host([icon=wellbeing-average]) .first:before,
:host([icon=average]) .first:before {
  content: "\\ED19";
}

:host([icon=document-zip]) .first:before {
  content: "\\ED38";
}

:host([icon=blocked-automatic-movements]) .first:before {
  content: "\\ED3C";
}

:host([icon=label]) .first:before {
  content: "\\ED3D";
}

:host([icon=select-all-images]) .first:before {
  content: "\\ED53";
}

:host([icon=liver-ablation-zone]) .first:before {
  content: "\\ED3E";
}

:host([icon=liver-ablation-zone]) .first:after {
  content: "\\ED3F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=liver-ablation-zone]) .second:before {
  content: "\\ED40";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=structure-ablation-zone]) .first:before {
  content: "\\ED54";
}

:host([icon=structure-ablation-zone]) .first:after {
  content: "\\ED55";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=structure-ablation-zone]) .second:before {
  content: "\\ED56";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=microphone]) .first:before {
  content: "\\ED43";
}

:host([icon=patient-export]) .first:before {
  content: "\\ED44";
}

:host([icon=fingerprint]) .first:before {
  content: "\\ED45";
}

:host([icon=send-to-report]) .first:before {
  content: "\\ED46";
}

:host([icon=send-to-report-completed]) .first:before {
  content: "\\ED47";
}

:host([icon=send-to-report-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=send-to-report-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=send-to-report-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=send-to-report-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=structure-lesion-contour-draw]) .first:before {
  content: "\\ED48";
}

:host([icon=structure-lesion-contour-draw]) .first:after {
  content: "\\ED49";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=face-id]) .first:before {
  content: "\\ED4B";
}

:host([icon=dicom-companion]) .first:before {
  content: "\\ED4C";
}

:host([icon=ablation-guide]) .first:before {
  content: "\\ED4E";
}

:host([icon=ablation-guide]) .first:after {
  content: "\\ED4D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=marked-region]) .first:before {
  content: "\\ED51";
  color: rgb(var(--functional-green));
}

:host([icon=marked-region]) .first:after {
  content: "\\ED4F";
  color: rgb(var(--functional-yellow));
}

:host([icon=marked-region]) .second:before {
  content: "\\ED50";
  color: rgb(var(--functional-red));
}

:host([icon=body-chessboard-view]) .first:before {
  content: "\\ED52";
}

:host([icon=body-chessboard-view]) .first:after {
  content: "\\ED57";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=body-registration-markers-set]) .first:before {
  content: "\\ED58";
}

:host([icon=body-registration-markers-set]) .first:after {
  content: "\\ED59";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=body-registration-markers-set]) .second:before {
  content: "\\ED5A";
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host([icon=body-spyglass-view]) .first:before {
  content: "\\ED5B";
}

:host([icon=body-spyglass-view]) .first:after {
  content: "\\ED5C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=body-spyglass-view]) .second:before {
  content: "\\ED5D";
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host([icon=calculate-registration-deformable]) .first:before {
  content: "\\ED5E";
}

:host([icon=calculate-registration-rigid]) .first:before {
  content: "\\ED5F";
}

:host([icon=centerline-hide]) .first:before {
  content: "\\ED60";
}

:host([icon=centerline-hide]) .first:after {
  content: "\\ED61";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=deselect-all-images]) .first:before {
  content: "\\ED62";
}

:host([icon=deselect-all-images]) .first:after {
  content: "\\ED63";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lesion-hide]) .first:before {
  content: "\\ED64";
}

:host([icon=lesion-hide]) .first:after {
  content: "\\ED65";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=log-save]) .first:before {
  content: "\\ED66";
}

:host([icon=lumen-hide]) .first:before {
  content: "\\ED67";
}

:host([icon=lumen-hide]) .first:after {
  content: "\\ED68";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=plaque-hide]) .first:before {
  content: "\\ED69";
}

:host([icon=plaque-hide]) .first:after {
  content: "\\ED6A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=profile-curve]) .first:before {
  content: "\\ED6B";
}

:host([icon=registration-deformable]) .first:before {
  content: "\\ED6C";
}

:host([icon=registration-rigid]) .first:before {
  content: "\\ED6D";
}

:host([icon=scan-protocol-edit]) .first:before {
  content: "\\ED6E";
}

:host([icon=service-login]) .first:before {
  content: "\\ED6F";
}

:host([icon=stent-hide]) .first:before {
  content: "\\ED70";
}

:host([icon=stent-hide]) .first:after {
  content: "\\ED71";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=virtual-visit]) .first:before {
  content: "\\ED72";
}

:host([icon=wall-hide]) .first:before {
  content: "\\ED74";
}

:host([icon=wall-hide]) .first:after {
  content: "\\ED73";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=table-mode-loc]) .first:before {
  content: "\\ED75";
}

:host([icon=table-mode-mlt]) .first:before {
  content: "\\ED76";
}

:host([icon=table-mode-fix]) .first:after {
  content: "\\ED75";
  color: rgba(var(--ui-2));
}

:host([icon=table-mode-iso]) .first:before {
  content: "\\ED78";
}

:host([icon=standard-orientation]) .first:before {
  content: "\\ED79";
}

:host([icon=series-open]) .first:before {
  content: "\\ED7A";
}

:host([icon=rotate-90-degrees]) .first:before {
  content: "\\ED7B";
}

:host([icon=range-curved-asymmetical-mode]) .first:before {
  content: "\\ED7E";
}

:host([icon=range-curved-asymmetical-mode]) .first:after {
  content: "\\ED7C";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=range-curved-asymmetical-mode]) .second:before {
  content: "\\ED7D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=radial-ranges-set-vertical]) .first:before {
  content: "\\ED7F";
}

:host([icon=radial-range-axial]) .first:before {
  content: "\\ED80";
}

:host([icon=radial-range-axial]) .first:after {
  content: "\\ED81";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=radial-range-coronal]) .first:before {
  content: "\\ED82";
}

:host([icon=radial-range-sagittal]) .first:before {
  content: "\\ED83";
}

:host([icon=radial-ranges-set-horizontal]) .first:before {
  content: "\\ED84";
}

:host([icon=parallel-ranges-set-vertical]) .first:before {
  content: "\\ED85";
}

:host([icon=parallel-ranges-set-vertical]) .first:after {
  content: "\\ED86";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=ortho-ranges]) .first:before {
  content: "\\ED87";
}

:host([icon=nudge-2d]) .first:before {
  content: "\\ED89";
}

:host([icon=nudge-2d]) .first:after {
  content: "\\ED88";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=more-error]) .first:before {
  content: "\\ED8A";
}

:host([icon=more-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=more-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=more-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=more-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=corner-menu-bl-fmpr-coronal]) .first:before {
  content: "\\ED8B";
}

:host([icon=corner-menu-bl-fmpr-coronal]) .first:after {
  content: "\\ED8C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=corner-menu-bl-fmpr-sagittal]) .first:before {
  content: "\\ED8D";
}

:host([icon=corner-menu-bl-fmpr-sagittal]) .first:after {
  content: "\\ED8E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=corner-menu-bl-fmpr-axial]) .first:before {
  content: "\\ED8F";
}

:host([icon=corner-menu-bl-fmpr-axial]) .first:after {
  content: "\\ED90";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=filtered-scroll]) .first:before {
  content: "\\ED77";
}

:host([icon=patient-table-down]) .first:before {
  content: "\\ED91";
}

:host([icon=patient-table-up]) .first:before {
  content: "\\ED92";
}

:host([icon=table-horizontal-position]) .first:before {
  content: "\\ED93";
}

:host([icon=table-tilting]) .first:before {
  content: "\\ED94";
}

:host([icon=table-top-view-mode]) .first:before {
  content: "\\ED95";
}

:host([icon=table-upright-position]) .first:before {
  content: "\\ED96";
}

:host([icon=timepoint-undo]) .first:before {
  content: "\\ED97";
}

:host([icon=timepoint-undo]) .first:after {
  content: "\\ED98";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=list-viewmode]) .first:before {
  content: "\\ED9D";
}

:host([icon=lesion-tool]) .first:before {
  content: "\\ED9B";
}

:host([icon=lesion-tool]) .first:after {
  content: "\\ED9C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=assisted-perpendicular]) .first:before {
  content: "\\ED9A";
}

:host([icon=swap-segment]) .first:before {
  content: "\\ED99";
}

:host([icon=vascular-aorta]) .first:before {
  content: "\\EDA6";
}

:host([icon=vascular-aorta]) .first:after {
  content: "\\EDA7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=swap-view]) .first:before {
  content: "\\EDA4";
}

:host([icon=swap-view]) .first:after {
  content: "\\EDA5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=swap-order]) .first:before {
  content: "\\EDA3";
}

:host([icon=filter-search]) .first:before {
  content: "\\EDA2";
}

:host([icon=download-in-progress]) .first:before {
  content: "\\EDA1";
}

:host([icon=download-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=download-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=download-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=download-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=download-in-progress]) .first:before {
  content: "\\EDA0";
}

:host([size=s][icon=download-in-progress]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=s][icon=download-in-progress]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=s][icon=download-in-progress]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=s][icon=download-in-progress]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([icon=body-registration-edit]) .first:before {
  content: "\\ED9E";
}

:host([icon=body-registration-edit]) .first:after {
  content: "\\ED9F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=chamber-no-selection]) .first:before {
  content: "\\EDA8";
}

:host([icon=five-field-chamber-bottom-left]) .first:before {
  content: "\\EDA9";
}

:host([icon=five-field-chamber-bottom-left]) .first:after {
  content: "\\EDAA";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-bottom-left-and-middle]) .first:before {
  content: "\\EDAB";
}

:host([icon=five-field-chamber-bottom-left-and-middle]) .first:after {
  content: "\\EDAC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-bottom-three-selected]) .first:before {
  content: "\\EDAF";
}

:host([icon=five-field-chamber-bottom-three-selected]) .first:after {
  content: "\\EDB0";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-bottom-right-and-middle]) .first:before {
  content: "\\EDAD";
}

:host([icon=five-field-chamber-bottom-right-and-middle]) .first:after {
  content: "\\EDAE";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-bottom-two-selected]) .first:before {
  content: "\\EDB1";
}

:host([icon=five-field-chamber-bottom-two-selected]) .first:after {
  content: "\\EDB2";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-left-three-selected]) .first:before {
  content: "\\EDB3";
}

:host([icon=five-field-chamber-left-three-selected]) .first:after {
  content: "\\EDB4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-left-two-selected]) .first:before {
  content: "\\EDB5";
}

:host([icon=five-field-chamber-left-two-selected]) .first:after {
  content: "\\EDB6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-middle]) .first:before {
  content: "\\EDB7";
}

:host([icon=five-field-chamber-middle]) .first:after {
  content: "\\EDB8";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-no-selection]) .first:before {
  content: "\\EDB9";
}

:host([icon=five-field-chamber-right-three-selected]) .first:before {
  content: "\\EDBA";
}

:host([icon=five-field-chamber-right-three-selected]) .first:after {
  content: "\\EDBB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-right-two-selected]) .first:before {
  content: "\\EDBC";
}

:host([icon=five-field-chamber-right-two-selected]) .first:after {
  content: "\\EDBD";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-top-left]) .first:before {
  content: "\\EDBE";
}

:host([icon=five-field-chamber-top-left]) .first:after {
  content: "\\EDBF";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-top-left-and-middle]) .first:before {
  content: "\\EDC0";
}

:host([icon=five-field-chamber-top-left-and-middle]) .first:after {
  content: "\\EDC1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-top-right]) .first:before {
  content: "\\EDC2";
}

:host([icon=five-field-chamber-top-right]) .first:after {
  content: "\\EDC3";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-top-right-and-middle]) .first:before {
  content: "\\EDC4";
}

:host([icon=five-field-chamber-top-right-and-middle]) .first:after {
  content: "\\EDC5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-top-three-selected]) .first:before {
  content: "\\EDC6";
}

:host([icon=five-field-chamber-top-three-selected]) .first:after {
  content: "\\EDC7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=five-field-chamber-top-two-selected]) .first:before {
  content: "\\EDC8";
}

:host([icon=five-field-chamber-top-two-selected]) .first:after {
  content: "\\EDC9";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pet-check]) .first:before {
  content: "\\EDCA";
}

:host([icon=pet-check]) .first:after {
  content: "\\EDCB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pet-check-error]) .first:before {
  content: "\\EDCE";
}

:host([icon=pet-check-error]) .first:after {
  content: "\\EDCC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pet-check-error]) .second:before {
  content: "\\EDCD";
}

:host([icon=pet-check-success]) .first:before {
  content: "\\EDCE";
}

:host([icon=pet-check-success]) .first:after {
  content: "\\EDCC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pet-check-success]) .second:before {
  content: "\\EDCF";
}

:host([icon=pet-check-warning]) .first:before {
  content: "\\EDCE";
}

:host([icon=pet-check-warning]) .first:after {
  content: "\\EDCC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=pet-check-warning]) .second:before {
  content: "\\EDD0";
}

:host([icon=five-field-chamber-bottom-right]) .first:before {
  content: "\\EDD1";
}

:host([icon=five-field-chamber-bottom-right]) .first:after {
  content: "\\EDD2";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=image-recovery]) .second:before {
  content: "\\EDD3";
}

:host([icon=pixelsize]) .second:before {
  content: "\\EDD4";
}

:host([icon=radiographic-aid]) .second:before {
  content: "\\EDD5";
}

:host([icon=ref]) .second:before {
  content: "\\EDD6";
}

:host([icon=stitched-image]) .second:before {
  content: "\\EDD7";
}

:host([icon=temperature-detector-error]) .first:before {
  content: "\\EDD9";
}

:host([icon=temperature-detector-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=temperature-detector-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=temperature-detector-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=temperature-detector-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=temperature-detector-warning]) .first:before {
  content: "\\EDD9";
}

:host([icon=temperature-detector-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=temperature-detector-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=temperature-detector-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=temperature-detector-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=tube]) .first:before {
  content: "\\EDDA";
}

:host([icon=usb-import-completed]) .first:before {
  content: "\\EDDC";
}

:host([icon=usb-import-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=usb-import-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=usb-import-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=usb-import-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=usb-export-completed]) .first:before {
  content: "\\EDDB";
}

:host([icon=usb-export-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=usb-export-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=usb-export-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=usb-export-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=usb-export-in-progress]) .first:before {
  content: "\\EDDB";
}

:host([icon=usb-export-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=usb-export-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=usb-export-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=usb-export-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=usb-import-in-progress]) .first:before {
  content: "\\EDDC";
}

:host([icon=usb-import-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=usb-import-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=usb-import-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=usb-import-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=import-error]) .first:before {
  content: "\\EA80";
}

:host([icon=import-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=import-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=import-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=import-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=ab-test]) .first:before {
  content: "\\EDEF";
}

:host([icon=automatic-mode]) .first:before {
  content: "\\EDF0";
}

:host([icon=calibrate]) .first:before {
  content: "\\EDF1";
}

:host([icon=collimator-rotate-move]) .first:before {
  content: "\\EDF2";
}

:host([icon=collimator-scale]) .first:before {
  content: "\\EDF3";
}

:host([icon=copper-filter]) .first:before {
  content: "\\EDF4";
}

:host([icon=dose-level-one]) .first:before {
  content: "\\EDF5";
}

:host([icon=dose-level-one]) .first:after {
  content: "\\EDF6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=dose-level-three-warning]) .first:before {
  content: "\\EDF7";
}

:host([icon=dose-level-three-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=dose-level-three-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=dose-level-three-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=dose-level-three-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=dose-level-three]) .first:after {
  content: "\\EDF8";
}

:host([icon=dose-level-two]) .first:before {
  content: "\\EDF9";
}

:host([icon=dose-level-two]) .first:after {
  content: "\\EDFA";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=dose-level-zero]) .second:before {
  content: "\\EDFB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=flip-indicator-horizontal]) .first:after {
  content: "\\EDFC";
}

:host([icon=flip-indicator-vertical]) .first:after {
  content: "\\EDFD";
}

:host([icon=main-power-connected]) .first:after {
  content: "\\EDFE";
}

:host([icon=panoramic-view]) .first:after {
  content: "\\EDFF";
}

:host([icon=pet-recon]) .first:after {
  content: "\\EE00";
}

:host([icon=pet-viewing]) .first:before {
  content: "\\EE01";
}

:host([icon=pet-viewing]) .first:after {
  content: "\\EE02";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=power-off]) .first:after {
  content: "\\EE03";
}

:host([icon=power-on-off]) .first:after {
  content: "\\EE04";
}

:host([icon=remote-control]) .first:after {
  content: "\\EE05";
}

:host([icon=rotate-counterclockwise]) .first:before {
  content: "\\EE06";
}

:host([icon=rotate-counterclockwise]) .first:after {
  content: "\\EE07";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=rotate-clockwise]) .first:before,
:host([icon=rotate-counterwise]) .first:before {
  content: "\\EE08";
}

:host([icon=rotate-clockwise]) .first:after,
:host([icon=rotate-counterwise]) .first:after {
  content: "\\EE09";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=sd-card-horizontal]) .first:after {
  content: "\\EE0A";
}

:host([icon=sd-card-vertical]) .first:after {
  content: "\\EE0B";
}

:host([icon=spot-adapt]) .first:after {
  content: "\\EE0C";
}

:host([icon=usb-c-horizontal]) .first:after {
  content: "\\EE0D";
}

:host([icon=usb-c-vertical]) .first:after {
  content: "\\EE0E";
}

:host([icon=zoom-one-x]) .first:after {
  content: "\\EE0F";
}

:host([icon=zoom-three-x]) .first:after {
  content: "\\EE10";
}

:host([icon=zoom-two-x]) .first:after {
  content: "\\EE11";
}

:host([icon=collapse]) .first:after {
  content: "\\EE16";
}

:host([icon=expand]) .first:after {
  content: "\\EE17";
}

:host([icon=expert-i-stopped]) .first:after {
  content: "\\EE18";
}

:host([icon=headscanner-parked-mode]) .first:after {
  content: "\\EE19";
}

:host([icon=privacy-not-configured]) .first:after {
  content: "\\EE1A";
}

:host([icon=trim]) .first:after {
  content: "\\EE1B";
}

:host([icon=listen-to]) .first:after {
  content: "\\EE21";
}

:host([icon=minip-thin]) .first:before {
  content: "\\EE22";
}

:host([icon=minip-thin]) .first:after {
  content: "\\EE23";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=mip-thin]) .first:before {
  content: "\\EE24";
}

:host([icon=mip-thin]) .first:after {
  content: "\\EE25";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=mpr]) .first:before {
  content: "\\EE26";
}

:host([icon=mpr]) .first:after {
  content: "\\EE27";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=mpr-mpr]) .first:before {
  content: "\\EE28";
}

:host([icon=mpr-mpr]) .first:after {
  content: "\\EE29";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=mpr-thick]) .first:before {
  content: "\\EE2A";
}

:host([icon=mpr-thick]) .first:after {
  content: "\\EE2B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=show-background-effect]) .first:before {
  content: "\\EE2C";
}

:host([icon=show-background-effect]) .first:after {
  content: "\\EE2D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=talk-to]) .first:after {
  content: "\\EE2E";
}

:host([icon=vrt-thin]) .first:before {
  content: "\\EE2F";
}

:host([icon=vrt-thin]) .first:after {
  content: "\\EE30";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=crop]) .first:before {
  content: "\\EE34";
}

:host([icon=windowing-thresholds]) .first:before {
  content: "\\EE3C";
}

:host([icon=start-point-trim]) .first:before {
  content: "\\EE3B";
}

:host([icon=end-point-trim]) .first:before {
  content: "\\EE39";
}

:host([icon=select-range]) .first:before {
  content: "\\EE3A";
}

:host([icon=ct-somatom-on]) .first:before {
  content: "\\EE38";
}

:host([icon=client-printer]) .first:before {
  content: "\\EE37";
}

:host([icon=export-to-pdf]) .first:before {
  content: "\\EE43";
}

:host([icon=patient-worklist-query]) .first:before {
  content: "\\EE45";
}

:host([icon=fit-to-window]) .first:before {
  content: "\\EE44";
}

:host([icon=exam-automapped]) .first:before {
  content: "\\EE46";
}

:host([icon=exam-confirmed]) .first:before {
  content: "\\EE47";
}

:host([icon=exam-error]) .first:before {
  content: "\\EE48";
}

:host([icon=exam-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=exam-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=exam-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=exam-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=exam-ignored]) .first:before {
  content: "\\EE49";
}

:host([icon=exam-manual-mapping]) .first:before {
  content: "\\EE4A";
}

:host([icon=exam-new]) .first:before {
  content: "\\EE4B";
}

:host([icon=exam]) .first:before {
  content: "\\EE4C";
}

:host([icon=fit-to-window-height]) .first:before {
  content: "\\EE4D";
}

:host([icon=fit-to-window-width]) .first:before {
  content: "\\EE4E";
}

:host([icon=general-chambers-all-active]) .first:before {
  content: "\\EE4F";
}

:host([icon=general-chambers-disabled]) .first:before {
  content: "\\EE51";
}

:host([icon=general-chambers-disabled]) .first:after {
  content: "\\EE50";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=general-chambers-left-active]) .first:before {
  content: "\\EE52";
}

:host([icon=general-chambers-left-active]) .first:after {
  content: "\\EE53";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=general-chambers-left-middle-active]) .first:before {
  content: "\\EE54";
}

:host([icon=general-chambers-left-middle-active]) .first:after {
  content: "\\EE55";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=general-chambers-left-right-active]) .first:before {
  content: "\\EE56";
}

:host([icon=general-chambers-left-right-active]) .first:after {
  content: "\\EE57";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=general-chambers-middle-active]) .first:before {
  content: "\\EE58";
}

:host([icon=general-chambers-middle-active]) .first:after {
  content: "\\EE59";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=general-chambers-middle-enhanced-active]) .first:before {
  content: "\\EE5A";
}

:host([icon=general-chambers-middle-enhanced-active]) .first:after {
  content: "\\EE5B";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=general-chambers-middle-right-active]) .first:before {
  content: "\\EE5C";
}

:host([icon=general-chambers-middle-right-active]) .first:after {
  content: "\\EE5D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=general-chambers-right-active]) .first:before {
  content: "\\EE5E";
}

:host([icon=general-chambers-right-active]) .first:after {
  content: "\\EE5F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=multi-region-automapped]) .first:before {
  content: "\\EE60";
}

:host([icon=multi-region]) .first:before {
  content: "\\EE61";
}

:host([icon=multi-sensor]) .first:before {
  content: "\\EE62";
}

:host([icon=receiver]) .first:before {
  content: "\\EE63";
}

:host([icon=single-region-automapped]) .first:before {
  content: "\\EE64";
}

:host([icon=single-region]) .first:before {
  content: "\\EE65";
}

:host([icon=wellbeing-bad]) .first:before,
:host([icon=bad]) .first:before {
  content: "\\EE66";
}

:host([icon=atrial-analysis]) .first:before {
  content: "\\EE67";
}

:host([icon=atrial-analysis]) .first:after {
  content: "\\EE68";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=auto-adjust-roi]) .first:before {
  content: "\\EE69";
}

:host([icon=auto-adjust-roi]) .first:after {
  content: "\\EE6A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cardiac-normal]) .first:before {
  content: "\\EE6C";
}

:host([icon=compression-cone]) .first:before {
  content: "\\EE6D";
}

:host([icon=flow-analysis]) .first:before {
  content: "\\EE6F";
}

:host([icon=flow-analysis]) .first:after {
  content: "\\EE70";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=left-atria-contours]) .first:before {
  content: "\\EE71";
}

:host([icon=left-atria-contours]) .first:after {
  content: "\\EE72";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=minus-light]) .first:before {
  content: "\\EE73";
}

:host([icon=plus-light]) .first:before {
  content: "\\EE76";
}

:host([icon=papillary]) .first:before {
  content: "\\EE74";
}

:host([icon=papillary]) .first:after {
  content: "\\EE75";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=right-atria-contours]) .first:before {
  content: "\\EE77";
}

:host([icon=right-atria-contours]) .first:after {
  content: "\\EE78";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=single-click-vessel-segmentation]) .first:before {
  content: "\\EE79";
}

:host([icon=single-click-vessel-segmentation]) .first:after {
  content: "\\EE7A";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=scanner-warning]) .first:before {
  content: "\\EE81";
}

:host([icon=scanner-warning]) .first:after {
  content: "\\EE82";
}

:host(:not([color])[icon=scanner-warning]) .first:after {
  content: "\\EE82";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=scanner-warning]) .second:before {
  content: "\\EE83";
  color: #000000;
}

:host([color][icon=scanner-warning]) .second:before {
  content: "\\EE83";
  color: rgb(var(--ui-7));
}

:host([icon=usb-recording]) .first:before {
  content: "\\EE84";
}

:host([icon=map-analysis]) .first:before {
  content: "\\EE7D";
}

:host([icon=map-analysis]) .first:after {
  content: "\\EE7E";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=map-analysis]) .second:before {
  content: "\\EE7F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=map-analysis]) .second:after {
  content: "\\EE80";
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host([icon=fit-to-acquisition-size]) .first:before {
  content: "\\EE7B";
}

:host([icon=fit-to-acquisition-size]) .first:after {
  content: "\\EE7C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=fit-to-acquisition-double-size]) .first:before {
  content: "\\EE6B";
}

:host([icon=fit-to-acquisition-double-size]) .first:after {
  content: "\\EE6E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=phase-backward]) .first:before {
  content: "\\EE89";
}

:host([icon=phase-forward]) .first:before {
  content: "\\EE8A";
}

:host([icon=player]) .first:before {
  content: "\\EE8B";
}

:host([icon=cinematic-insight]) .first:before {
  content: "\\EE85";
}

:host([icon=cinematic-insight]) .first:after {
  content: "\\EE86";
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host([icon=cinematic-insight]) .second:before {
  content: "\\EE87";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=cinematic-insight]) .second:after {
  content: "\\EE88";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=centerline-show]) .first:before {
  content: "\\EE8C";
}

:host([icon=centerline-show]) .first:after {
  content: "\\EE8D";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=group-completed]) .first:before {
  content: "\\EE8E";
}

:host([icon=group-completed]) .first:after {
  content: "\\E990";
}

:host([icon=group-completed]) .second:before {
  content: "\\E995";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host(:not([color])[icon=group-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=group-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([icon=group-status-unknown]) .first:before {
  content: "\\EE8E";
}

:host([icon=group-status-unknown]) .first:after {
  content: "\\EA06";
}

:host(:not([color])[icon=group-status-unknown]) .first:after {
  content: "\\EA06";
  color: #6e7b9f;
}

:host(:not([color])[icon=group-status-unknown]) .second:before {
  content: "\\EA05";
  color: #ffffff;
}

:host([color][icon=group-status-unknown]) .second:before {
  content: "\\EA05";
  color: rgb(var(--ui-7));
}

:host([icon=image-text-center-left]) .first:before {
  content: "\\EE8F";
}

:host([icon=image-text-center-right]) .first:before {
  content: "\\EE90";
}

:host([icon=image-text-lower-center]) .first:before {
  content: "\\EE91";
}

:host([icon=image-text-lower-left]) .first:before {
  content: "\\EE92";
}

:host([icon=image-text-lower-right]) .first:before {
  content: "\\EE93";
}

:host([icon=image-text-upper-left]) .first:before {
  content: "\\EE94";
}

:host([icon=image-text-upper-right]) .first:before {
  content: "\\EE95";
}

:host([icon=midline-shift]) .first:before {
  content: "\\EE96";
}

:host([icon=workflow-open]) .first:before {
  content: "\\EEA8";
}

:host([icon=units]) .first:before {
  content: "\\EEA7";
}

:host([icon=tube-rotation]) .first:before {
  content: "\\EEA6";
}

:host([icon=touch-screen-unlock]) .first:before {
  content: "\\EEA5";
}

:host([icon=touch-screen-lock]) .first:before {
  content: "\\EEA4";
}

:host([icon=switch-user]) .first:before {
  content: "\\EEA3";
}

:host([icon=sort-by]) .first:before {
  content: "\\EEA2";
}

:host([icon=shutter]) .first:before {
  content: "\\EEA0";
}

:host([icon=shutter]) .first:after {
  content: "\\EEA1";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=remote-service-ongoing]) .first:before {
  content: "\\EE9E";
}

:host([icon=remote-service-ongoing]) .first:after {
  content: "\\E990";
}

:host([icon=remote-service-ongoing]) .second:before {
  content: "\\E995";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host(:not([color])[icon=remote-service-ongoing]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=remote-service-ongoing]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([icon=recall-collimation]) .first:before {
  content: "\\EE9D";
}

:host([icon=panel-temperature-4-1]) .first:before {
  content: "\\EE9C";
}

:host([icon=manual]) .first:before {
  content: "\\EE9B";
}

:host([icon=fit-to-segment]) .first:before {
  content: "\\EE9A";
}

:host([icon=fit-to-acquisition]) .first:before {
  content: "\\EE98";
}

:host([icon=fit-to-acquisition]) .first:after {
  content: "\\EE99";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=delete-protect]) .first:before {
  content: "\\EE97";
}

:host([size=s][icon=delete-protect]) .first:before {
  content: "\\EF13";
}

:host([icon=question]) .first:before {
  content: "\\EEAA";
}

:host([icon=fit-to-defined-size]) .first:before {
  content: "\\EE9F";
}

:host([icon=fit-to-defined-size]) .first:after {
  content: "\\EEA9";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=centerline]) .first:before {
  content: "\\EEAB";
}

:host([icon=centerline]) .first:after {
  content: "\\EEAC";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=coverage-meter]) .first:before {
  content: "\\EEAD";
}

:host([icon=layout-switch-dual-image]) .first:before {
  content: "\\EEAE";
}

:host([icon=layout-switch-single-image]) .first:before {
  content: "\\EEAF";
}

:host([icon=mark-left-right]) .first:before {
  content: "\\EEB0";
}

:host([icon=override-softstop]) .first:before {
  content: "\\EEB1";
}

:host([icon=re-ablate]) .first:before {
  content: "\\EEB2";
}

:host([icon=re-ablate]) .first:after {
  content: "\\EEB3";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=segment-ablation]) .first:before {
  content: "\\EEB4";
}

:host([icon=segment-ablation]) .first:after {
  content: "\\EEB5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=segment-target]) .first:before {
  content: "\\EEB6";
}

:host([icon=segment-target]) .first:after {
  content: "\\EEB7";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=timer-end]) .first:before {
  content: "\\EEB8";
}

:host([icon=timer-start]) .first:before {
  content: "\\EEB9";
}

:host([icon=vessel-lumen]) .first:before {
  content: "\\EEBA";
}

:host([icon=vessel-lumen]) .first:after {
  content: "\\EEBB";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=bone-marrow-segmentation]) .first:before {
  content: "\\EEBC";
}

:host([icon=bone-marrow-segmentation]) .first:after {
  content: "\\EEBD";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=cleaning-screen]) .first:before {
  content: "\\EEBE";
}

:host([icon=dynamic-angio]) .first:before {
  content: "\\EEBF";
}

:host([icon=image-completed]) .first:before {
  content: "\\EEC0";
}

:host([icon=image-completed]) .first:after {
  content: "\\E990";
}

:host([icon=image-completed]) .second:before {
  content: "\\E995";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host(:not([color])[icon=image-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=image-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([icon=inverse-longitudinal]) .first:before {
  content: "\\EEC1";
}

:host([icon=inverse-longitudinal]) .first:after {
  content: "\\EEC2";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=inverse-transversal]) .first:before {
  content: "\\EEC3";
}

:host([icon=inverse-transversal]) .first:after {
  content: "\\EEC4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=perfusion-ventilation-results]) .first:before {
  content: "\\EEC5";
}

:host([icon=perfusion-ventilation-results]) .first:after {
  content: "\\EEC6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=wifi-error-0]) .first:before {
  content: "\\EEC7";
}

:host([icon=wifi-error-0]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=wifi-error-0]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=wifi-error-0]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=wifi-error-0]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=expert-i-not-available]) .first:before {
  content: "\\ED4A";
}

:host([icon=expert-i-not-available]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=expert-i-not-available]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=expert-i-not-available]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=expert-i-not-available]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=ai-finding-critical-warning]) .first:before {
  content: "\\EEC8";
}

:host([icon=ai-finding-critical-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=ai-finding-critical-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=ai-finding-critical-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=ai-finding-critical-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=table-position]) .first:before {
  content: "\\EEDE";
}

:host([icon=study-scheduled]) .first:before {
  content: "\\EEDD";
}

:host([icon=series-completed]) .first:before {
  content: "\\EEDB";
}

:host([icon=series-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=series-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=series-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=series-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([icon=remote-session-audio]) .first:before {
  content: "\\EEDA";
}

:host([icon=eco-power-mode]) .first:before {
  content: "\\EED9";
}

:host([icon=eco-power-mode-off]) .first:before {
  content: "\\EED8";
}

:host([icon=eco-power-mode-error]) .first:before {
  content: "\\EED7";
}

:host([icon=eco-power-mode-error]) .first:after {
  content: "\\EB4E";
}

:host(:not([color])[icon=eco-power-mode-error]) .first:after {
  content: "\\EB4E";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=eco-power-mode-error]) .second:before {
  content: "\\EB4F";
  color: #ffffff;
}

:host([color][icon=eco-power-mode-error]) .second:before {
  content: "\\EB4F";
  color: rgb(var(--ui-7));
}

:host([icon=poi]) .first:before {
  content: "\\EED5";
}

:host([icon=poi]) .first:after {
  content: "\\EED6";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([size=s][icon=poi]) .first:before {
  content: "\\EED3";
}

:host([size=s][icon=poi]) .first:after {
  content: "\\EED4";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([size=xs][icon=poi]) .first:before {
  content: "\\EED1";
}

:host([size=xs][icon=poi]) .first:after {
  content: "\\EED2";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lock-to-center]) .first:before {
  content: "\\EECF";
}

:host([icon=lock-dit-vertically]) .first:before {
  content: "\\EECE";
}

:host([icon=lock-dit-horizontally]) .first:before {
  content: "\\EECD";
}

:host([icon=fulcrum-height]) .first:before {
  content: "\\EECC";
}

:host([icon=footswitch-lateral]) .first:before {
  content: "\\EECB";
}

:host([icon=bird-eye-view]) .first:before {
  content: "\\EEE2";
}

:host([icon=compression-cone-parked]) .first:before {
  content: "\\EEE3";
}

:host([icon=expert-i-analysis]) .first:before {
  content: "\\EEE4";
}

:host([icon=manual-mode]) .first:before {
  content: "\\EEE5";
}

:host([icon=monitor-view]) .first:before {
  content: "\\EEE6";
}

:host([icon=rad-confirm]) .first:before {
  content: "\\EEE7";
}

:host([icon=reverse-table-movement]) .first:before {
  content: "\\EEE8";
}

:host([icon=reverse-table-movement]) .first:after {
  content: "\\EEE9";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=stored-local]) .first:before {
  content: "\\EEEA";
}

:host([icon=stored-server]) .first:before {
  content: "\\EEEB";
}

:host([icon=vessel-segment]) .first:before {
  content: "\\EEF4";
}

:host([icon=vessel-segment]) .first:after {
  content: "\\EEF5";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=stress-scan]) .first:before {
  content: "\\EEF3";
}

:host([icon=rest-scan]) .first:before {
  content: "\\EEF2";
}

:host([icon=multiple-patients-lock]) .first:before {
  content: "\\EEF0";
}

:host([icon=multiple-patients-warning]) .first:before {
  content: "\\EEF1";
}

:host([icon=multiple-patients-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=multiple-patients-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=multiple-patients-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=multiple-patients-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=multiple-patients-error]) .first:before {
  content: "\\EEEE";
}

:host([icon=multiple-patients-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=multiple-patients-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=multiple-patients-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=multiple-patients-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=multiple-patients-in-progress]) .first:before {
  content: "\\EEEF";
}

:host([icon=multiple-patients-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=multiple-patients-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=multiple-patients-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=multiple-patients-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([icon=analyzer-in-progress]) .first:before {
  content: "\\EEED";
}

:host([icon=analyzer-in-progress]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=analyzer-in-progress]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=analyzer-in-progress]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=analyzer-in-progress]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=analyzer-in-progress]) .first:before {
  content: "\\EEEC";
}

:host([size=s][icon=analyzer-in-progress]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=s][icon=analyzer-in-progress]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=s][icon=analyzer-in-progress]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=s][icon=analyzer-in-progress]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([icon=acquisition-protocol]) .first:before {
  content: "\\EEF6";
}

:host([icon=analyzer-not-for-clinical-use]) .first:before {
  content: "\\EEF8";
}

:host([size=s][icon=analyzer-not-for-clinical-use]) .first:before {
  content: "\\EEF7";
}

:host([icon=camera-error]) .first:before {
  content: "\\EEF9";
}

:host([icon=camera-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=camera-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=camera-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=camera-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=fluoroscopy-timer]) .first:before {
  content: "\\EEFA";
}

:host([icon=radiation-suspend]) .first:before {
  content: "\\EEFB";
}

:host([icon=tube-centered-no]) .first:before {
  content: "\\EEFC";
}

:host([icon=open-tasks-warning]) .first:before {
  content: "\\EA52";
}

:host([icon=open-tasks-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=open-tasks-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=open-tasks-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=open-tasks-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=arrow-face-down-l-cut-line]) .first:before {
  content: "\\EF01";
}

:host([icon=arrow-face-up-l-cut-line]) .first:before {
  content: "\\EF02";
}

:host([icon=disc-space-6-error]) .first:before {
  content: "\\EEFF";
}

:host([icon=disc-space-6-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=disc-space-6-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=disc-space-6-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=disc-space-6-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=disc-space-6-error-color]) .first:before {
  content: "\\EEFD";
}

:host([icon=disc-space-6-error-color]) .first:after {
  content: "\\EEFE";
  color: #be1818;
}

:host([icon=disc-space-6-error-color]) .second:before {
  content: "\\E996";
}

:host(:not([color])[icon=disc-space-6-error-color]) .second:before {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=disc-space-6-error-color]) .second:after {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=disc-space-6-error-color]) .second:after {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=off-center-support-cancel]) .first:before {
  content: "\\EF04";
}

:host([size=xs][icon=off-center-support-cancel]) .first:before {
  content: "\\EF03";
}

:host([icon=off-center-support-lock]) .first:before {
  content: "\\EF06";
}

:host([size=xs][icon=off-center-support-lock]) .first:before {
  content: "\\EF05";
}

:host([icon=server]) .first:before {
  content: "\\EF07";
}

:host([icon=off-center-support]) .first:before {
  content: "\\EF09";
}

:host([size=xs][icon=off-center-support]) .first:before {
  content: "\\EF08";
}

:host([icon=mouse-information]) .first:before {
  content: "\\EF0A";
}

:host([icon=mouse-information]) .first:after {
  content: "\\EA0A";
}

:host(:not([color])[icon=mouse-information]) .first:after {
  content: "\\EA0A";
  color: #6e7b9f;
}

:host(:not([color])[icon=mouse-information]) .second:before {
  content: "\\EA09";
  color: #ffffff;
}

:host([color][icon=mouse-information]) .second:before {
  content: "\\EA09";
  color: rgb(var(--ui-7));
}

:host([icon=intracranial-hemorrhage-auto-color]) .first:before {
  content: "\\EF0B";
}

:host([icon=intracranial-hemorrhage-auto-color]) .first:after {
  content: "\\EF0C";
  color: #be1818;
}

:host([icon=intracranial-hemorrhage-auto]) .first:before {
  content: "\\EF0D";
}

:host([icon=image-reject]) .first:before {
  content: "\\EF0E";
}

:host([icon=disc-space-6-color]) .first:before {
  content: "\\EF0F";
}

:host([icon=disc-space-6-color]) .first:after {
  content: "\\EF10";
  color: #be1818;
}

:host([icon=disc-space-6]) .first:before {
  content: "\\EF11";
}

:host([icon=accept-all]) .first:before {
  content: "\\EF17";
}

:host([icon=expert-id-fdg]) .first:before {
  content: "\\EF18";
}

:host([icon=expert-id-fdg]) .first:after {
  content: "\\EF19";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=expert-id-fdg-w]) .first:before {
  content: "\\EF18";
}

:host([icon=expert-id-fdg-w]) .first:after {
  content: "\\EF1A";
  color: #ffd200;
}

:host([icon=expert-id-psma-hepatic]) .first:before {
  content: "\\EF1B";
}

:host([icon=expert-id-psma-hepatic]) .first:after {
  content: "\\EF1C";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=expert-id-psma-hepatic-w]) .first:before {
  content: "\\EF1B";
}

:host([icon=expert-id-psma-hepatic-w]) .first:after {
  content: "\\EF1D";
  color: #ffd200;
}

:host([icon=expert-id-psma-renal]) .first:before {
  content: "\\EF1E";
}

:host([icon=expert-id-psma-renal]) .first:after {
  content: "\\EF1F";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=expert-id-psma-renal-w]) .first:before {
  content: "\\EF1E";
}

:host([icon=expert-id-psma-renal-w]) .first:after {
  content: "\\EF20";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=expert-id-psma-renal-w]) .second:before {
  content: "\\EF21";
  color: #ffd200;
}

:host([icon=layout-by-column]) .first:before {
  content: "\\EF23";
}

:host([size=s][icon=layout-by-column]) .first:before {
  content: "\\EF22";
}

:host([icon=layout-by-row]) .first:before {
  content: "\\EF25";
}

:host([size=s][icon=layout-by-row]) .first:before {
  content: "\\EF24";
}

:host([icon=lesion-scout]) .first:before {
  content: "\\EF26";
}

:host([icon=lesion-scout]) .first:after {
  content: "\\EF27";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=lesion-scout-w]) .first:before {
  content: "\\EF26";
}

:host([icon=lesion-scout-w]) .first:after {
  content: "\\EF28";
  color: #ffd200;
}

:host([icon=swap]) .first:before {
  content: "\\EF2F";
}

:host([icon=zoom-to-mouse]) .first:before {
  content: "\\EF29";
}

:host([icon=user-cancel]) .first:before {
  content: "\\EF38";
}

:host([size=s][icon=user-cancel]) .first:before {
  content: "\\EF37";
}

:host([icon=lesion-detection]) .first:before {
  content: "\\EF2D";
}

:host([icon=lesion-detection]) .first:after {
  content: "\\EF2E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=size-collimation-auto]) .first:before {
  content: "\\EF33";
}

:host([icon=display-user-layout]) .first:before {
  content: "\\EF3A";
}

:host([icon=layout-new]) .first:before {
  content: "\\EF3B";
}

:host([icon=filter-marked]) .first:before {
  content: "\\EF46";
}

:host([icon=adapt-contours]) .first:before {
  content: "\\EF3F";
}

:host([icon=tree-viewmode]) .first:before {
  content: "\\EF3E";
}

:host([icon=studies-in-time]) .first:before {
  content: "\\EF3D";
}

:host([icon=structure-template-create]) .first:before {
  content: "\\EF3C";
}

:host([icon=clip-box]) .first:before {
  content: "\\EF42";
}

:host([icon=clip-box]) .first:after {
  content: "\\EF41";
  color: rgba(var(--ui-1), var(--opacity-3));
}

:host([icon=clip-box]) .second:before {
  content: "\\EF40";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=detector-companion]) .first:before {
  content: "\\EF43";
}

:host([icon=log-companion]) .first:before {
  content: "\\EF44";
}

:host([icon=series-forward]) .first:before {
  content: "\\EF45";
}

:host([icon=tube-centered-yes]) .first:before {
  content: "\\EF47";
}

:host([icon=tube-centering]) .first:before {
  content: "\\EF48";
}

:host([icon=tube-centering]) .first:after {
  content: "\\EF49";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=black-box-error]) .first:before {
  content: "\\EF4F";
}

:host([icon=black-box-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=black-box-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=black-box-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=black-box-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=black-box-error]) .first:before {
  content: "\\EF4E";
}

:host([size=s][icon=black-box-error]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[size=s][icon=black-box-error]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=s][icon=black-box-error]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([size=s][icon=black-box-error]) .second:before {
  content: "\\EA25";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=black-box-error]) .first:before {
  content: "\\EF4D";
}

:host([size=xs][icon=black-box-error]) .first:after {
  content: "\\EA21";
}

:host([size=xs]:not([color])[icon=black-box-error]) .first:after {
  content: "\\EA21";
  color: rgb(var(--functional-red));
}

:host([size=xs]:not([color])[icon=black-box-error]) .second:before {
  content: "\\EA23";
  color: #ffffff;
}

:host([size=xs][color][icon=black-box-error]) .second:before {
  content: "\\EA23";
  color: rgb(var(--ui-7));
}

:host([icon=black-box-completed]) .first:before {
  content: "\\EF4F";
}

:host([icon=black-box-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=black-box-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=black-box-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=black-box-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=black-box-completed]) .first:before {
  content: "\\EF4E";
}

:host([size=s][icon=black-box-completed]) .first:after {
  content: "\\EA1F";
}

:host(:not([color])[size=s][icon=black-box-completed]) .first:after {
  content: "\\EA1F";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=s][icon=black-box-completed]) .second:before {
  content: "\\EA20";
  color: #ffffff;
}

:host([color][size=s][icon=black-box-completed]) .second:before {
  content: "\\EA20";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=black-box-completed]) .first:before {
  content: "\\EF4D";
}

:host([size=xs][icon=black-box-completed]) .first:after {
  content: "\\EF50";
}

:host([size=xs]:not([color])[icon=black-box-completed]) .first:after {
  content: "\\EF50";
  color: rgb(var(--functional-green));
}

:host([size=xs]:not([color])[icon=black-box-completed]) .second:before {
  content: "\\EF51";
  color: #ffffff;
}

:host([size=xs][color][icon=black-box-completed]) .second:before {
  content: "\\EF51";
  color: rgb(var(--ui-7));
}

:host([icon=black-box-warning]) .first:before {
  content: "\\EF4F";
}

:host([icon=black-box-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=black-box-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=black-box-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=black-box-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=black-box-warning]) .first:before {
  content: "\\EF4E";
}

:host([size=s][icon=black-box-warning]) .first:after {
  content: "\\EF54";
}

:host([size=s]:not([color])[icon=black-box-warning]) .first:after {
  content: "\\EF54";
  color: rgb(var(--functional-yellow));
}

:host([size=s]:not([color])[icon=black-box-warning]) .second:before {
  content: "\\EF55";
  color: #000000;
}

:host([size=s][color][icon=black-box-warning]) .second:before {
  content: "\\EF55";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=black-box-warning]) .first:before {
  content: "\\EF4D";
}

:host([size=xs][icon=black-box-warning]) .first:after {
  content: "\\EF52";
}

:host([size=xs]:not([color])[icon=black-box-warning]) .first:after {
  content: "\\EF52";
  color: rgb(var(--functional-yellow));
}

:host([size=xs]:not([color])[icon=black-box-warning]) .second:before {
  content: "\\EF53";
  color: #000000;
}

:host([size=xs][color][icon=black-box-warning]) .second:before {
  content: "\\EF53";
  color: rgb(var(--ui-7));
}

:host([icon=black-box-running]) .first:before {
  content: "\\EF4F";
}

:host([icon=black-box-running]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=black-box-running]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=black-box-running]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=black-box-running]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=black-box-running]) .first:before {
  content: "\\EF4E";
}

:host([size=s][icon=black-box-running]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=s][icon=black-box-running]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=s][icon=black-box-running]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=s][icon=black-box-running]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=black-box-running]) .first:before {
  content: "\\EF4D";
}

:host([size=xs][icon=black-box-running]) .first:after {
  content: "\\EA26";
}

:host(:not([color])[size=xs][icon=black-box-running]) .first:after {
  content: "\\EA26";
  color: #52c1ff;
}

:host(:not([color])[size=xs][icon=black-box-running]) .second:before {
  content: "\\EA27";
  color: #000000;
}

:host([color][size=xs][icon=black-box-running]) .second:before {
  content: "\\EA27";
  color: rgb(var(--ui-7));
}

:host([icon=upload-error]) .first:before {
  content: "\\EF4C";
}

:host([icon=upload-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=upload-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=upload-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=upload-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=upload-error]) .first:before {
  content: "\\EF4B";
}

:host([size=s][icon=upload-error]) .first:after {
  content: "\\EA24";
}

:host(:not([color])[size=s][icon=upload-error]) .first:after {
  content: "\\EA24";
  color: rgb(var(--functional-red));
}

:host(:not([color])[size=s][icon=upload-error]) .second:before {
  content: "\\EA25";
  color: #ffffff;
}

:host([size=s][icon=upload-error]) .second:before {
  content: "\\EA25";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=upload-error]) .first:before {
  content: "\\EF4A";
}

:host([size=xs][icon=upload-error]) .first:after {
  content: "\\EA21";
}

:host([size=xs]:not([color])[icon=upload-error]) .first:after {
  content: "\\EA21";
  color: rgb(var(--functional-red));
}

:host([size=xs]:not([color])[icon=upload-error]) .second:before {
  content: "\\EA23";
  color: #ffffff;
}

:host([size=xs][color][icon=upload-error]) .second:before {
  content: "\\EA23";
  color: rgb(var(--ui-7));
}

:host([icon=upload-completed]) .first:before {
  content: "\\EF4C";
}

:host([icon=upload-completed]) .first:after {
  content: "\\E990";
}

:host(:not([color])[icon=upload-completed]) .first:after {
  content: "\\E990";
  color: rgb(var(--functional-green));
}

:host(:not([color])[icon=upload-completed]) .second:before {
  content: "\\E995";
  color: #ffffff;
}

:host([color][icon=upload-completed]) .second:before {
  content: "\\E995";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=upload-completed]) .first:before {
  content: "\\EF4B";
}

:host([size=s][icon=upload-completed]) .first:after {
  content: "\\EA1F";
}

:host(:not([color])[size=s][icon=upload-completed]) .first:after {
  content: "\\EA1F";
  color: rgb(var(--functional-green));
}

:host(:not([color])[size=s][icon=upload-completed]) .second:before {
  content: "\\EA20";
  color: #ffffff;
}

:host([color][size=s][icon=upload-completed]) .second:before {
  content: "\\EA20";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=upload-completed]) .first:before {
  content: "\\EF4A";
}

:host([size=xs][icon=upload-completed]) .first:after {
  content: "\\EF50";
}

:host([size=xs]:not([color])[icon=upload-completed]) .first:after {
  content: "\\EF50";
  color: rgb(var(--functional-green));
}

:host([size=xs]:not([color])[icon=upload-completed]) .second:before {
  content: "\\EF51";
  color: #ffffff;
}

:host([size=xs][color][icon=upload-completed]) .second:before {
  content: "\\EF51";
  color: rgb(var(--ui-7));
}

:host([icon=upload-warning]) .first:before {
  content: "\\EF4C";
}

:host([icon=upload-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=upload-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=upload-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=upload-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=upload-warning]) .first:before {
  content: "\\EF4B";
}

:host([size=s][icon=upload-warning]) .first:after {
  content: "\\EF54";
}

:host([size=s]:not([color])[icon=upload-warning]) .first:after {
  content: "\\EF54";
  color: rgb(var(--functional-yellow));
}

:host([size=s]:not([color])[icon=upload-warning]) .second:before {
  content: "\\EF55";
  color: #000000;
}

:host([size=s][color][icon=upload-warning]) .second:before {
  content: "\\EF55";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=upload-warning]) .first:before {
  content: "\\EF4A";
}

:host([size=xs][icon=upload-warning]) .first:after {
  content: "\\EF52";
}

:host([size=xs]:not([color])[icon=upload-warning]) .first:after {
  content: "\\EF52";
  color: rgb(var(--functional-yellow));
}

:host([size=xs]:not([color])[icon=upload-warning]) .second:before {
  content: "\\EF53";
  color: #000000;
}

:host([size=xs][color][icon=upload-warning]) .second:before {
  content: "\\EF53";
  color: rgb(var(--ui-7));
}

:host([icon=upload-running]) .first:before {
  content: "\\EF4C";
}

:host([icon=upload-running]) .first:after {
  content: "\\E998";
}

:host(:not([color])[icon=upload-running]) .first:after {
  content: "\\E998";
  color: #52c1ff;
}

:host(:not([color])[icon=upload-running]) .second:before {
  content: "\\E999";
  color: #000000;
}

:host([color][icon=upload-running]) .second:before {
  content: "\\E999";
  color: rgb(var(--ui-7));
}

:host([size=s][icon=upload-running]) .first:before {
  content: "\\EF4B";
}

:host([size=s][icon=upload-running]) .first:after {
  content: "\\EA28";
}

:host(:not([color])[size=s][icon=upload-running]) .first:after {
  content: "\\EA28";
  color: #52c1ff;
}

:host(:not([color])[size=s][icon=upload-running]) .second:before {
  content: "\\EA29";
  color: #000000;
}

:host([color][size=s][icon=upload-running]) .second:before {
  content: "\\EA29";
  color: rgb(var(--ui-7));
}

:host([size=xs][icon=upload-running]) .first:before {
  content: "\\EF4A";
}

:host([size=xs][icon=upload-running]) .first:after {
  content: "\\EA26";
}

:host(:not([color])[size=xs][icon=upload-running]) .first:after {
  content: "\\EA26";
  color: #52c1ff;
}

:host(:not([color])[size=xs][icon=upload-running]) .second:before {
  content: "\\EA27";
  color: #000000;
}

:host([color][size=xs][icon=upload-running]) .second:before {
  content: "\\EA27";
  color: rgb(var(--ui-7));
}

:host([icon=nbp-measurement]) .first:before {
  content: "\\EF56";
}

:host([icon=call-ended]) .first:before {
  content: "\\EF58";
}

:host([icon=call-ended][size=xs]) .first:before {
  content: "\\EF57";
}

:host([icon=phone-flip]) .first:before {
  content: "\\EF59";
}

:host([icon=unbalance-out-pressure]) .first:before {
  content: "\\EF61";
}

:host([icon=t2s-tube-startposition]) .first:before {
  content: "\\EF5F";
}

:host([icon=t2s-tube-startposition]) .first:after {
  content: "\\EF60";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=t2s-tube-endposition]) .first:before {
  content: "\\EF5D";
}

:host([icon=t2s-tube-endposition]) .first:after {
  content: "\\EF5E";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=notification-center-warning]) .first:before {
  content: "\\EF5C";
}

:host([icon=notification-center-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=notification-center-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=notification-center-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=notification-center-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=notification-center-error]) .first:before {
  content: "\\EF5C";
}

:host([icon=notification-center-error]) .first:after {
  content: "\\E996";
}

:host(:not([color])[icon=notification-center-error]) .first:after {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=notification-center-error]) .second:before {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=notification-center-error]) .second:before {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=caudo-cranial-scan-direction]) .first:before {
  content: "\\EF5A";
}

:host([icon=cranio-caudal-scan-direction]) .first:before {
  content: "\\EF5B";
}

:host([icon=detector-uncalibrated-warning]) .first:before {
  content: "\\EF62";
}

:host([icon=detector-uncalibrated-warning]) .first:after {
  content: "\\E9A0";
}

:host(:not([color])[icon=detector-uncalibrated-warning]) .first:after {
  content: "\\E9A0";
  color: rgb(var(--functional-yellow));
}

:host(:not([color])[icon=detector-uncalibrated-warning]) .second:before {
  content: "\\E9A1";
  color: #000000;
}

:host([color][icon=detector-uncalibrated-warning]) .second:before {
  content: "\\E9A1";
  color: rgb(var(--ui-7));
}

:host([icon=network-error]) .first:before {
  content: "\\EF63";
}

:host([icon=network-error]) .first:after {
  content: "\\EF64";
  color: rgba(var(--ui-1), var(--opacity-4));
}

:host([icon=network-error]) .second:before {
  content: "\\E996";
}

:host(:not([color])[icon=network-error]) .second:before {
  content: "\\E996";
  color: rgb(var(--functional-red));
}

:host(:not([color])[icon=network-error]) .second:after {
  content: "\\E997";
  color: #ffffff;
}

:host([color][icon=network-error]) .second:after {
  content: "\\E997";
  color: rgb(var(--ui-7));
}

:host([icon=scanner-off]) .first:before {
  content: "\\EF66";
}

:host([icon=thumbnail-status-3d-off]) .first:before {
  content: "\\EF67";
}`;