// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { calculatedBaseFontSize } from './base-font-size.js';
import { convertToPixel } from './rem-to-pixel-convertor.js';
export const SliderStepMarkerMixin = (superClass, { stepMarker = 'stepMarkers' }) => {
  class StepMarkerMixin extends superClass {
    constructor() {
      super(...arguments);
      this.min = 0;
      this.max = 100;
      this.step = 1;
    }
    static get properties() {
      return {
        [stepMarker]: {
          type: [],
        },
      };
    }
    getCalculatedStepDistance(containerLength, min, max, step, stepTickHalfWidthRem) {
      this._STEP_TICK_HALF_WIDTH_REM = stepTickHalfWidthRem;
      let distance;
      this.min = min;
      this.max = max;
      this.step = step;
      distance = this.step / (this.max - this.min);
      distance = distance * containerLength - convertToPixel(this._STEP_TICK_HALF_WIDTH_REM);
      return distance;
    }
    calculatePoints(containerLength, stepTickHalfWidthRem) {
      this._STEP_TICK_HALF_WIDTH_REM = stepTickHalfWidthRem;
      let value;
      let points = [];
      const marker_pos = Math.floor((this.max - this.min) / this.step);
      if (this.min < 0 && this.min / this.step != 0) {
        points = [0];
        value = 0;
        const marker_pos_minus = Math.floor((0 - this.min) / this.step);
        const marker_pos_plus = Math.floor((this.max - 0) / this.step);
        for (let i = 0; i < marker_pos_minus; ++i) {
          value = value - this.step;
          points.push(value);
        }
        value = 0;
        for (let i = 0; i < marker_pos_plus; ++i) {
          value = value + this.step;
          points.push(value);
        }
      } else {
        // when min is positive and divisible by step value
        points = [this.min];
        value = this.min;
        for (let i = 0; i < marker_pos; ++i) {
          value = value + this.step;
          points.push(value);
        }
      }
      // map the values in points array
      this.calculateStepTicks(points, containerLength);
    }
    calculateStepTicks(points, containerLength) {
      let current_position, style;
      const markersArray = points.map((value) => {
        current_position = (value - this.min) / (this.max - this.min);
        current_position =
          (2 * Math.round((current_position * containerLength) / 2)) / calculatedBaseFontSize;
        if (this._STEP_TICK_HALF_WIDTH_REM) {
          current_position -= this._STEP_TICK_HALF_WIDTH_REM;
        }
        style = `left: ${current_position}rem`;
        return { style, position: value };
      });
      this[stepMarker] = markersArray;
    }
  }
  return StepMarkerMixin;
};
