// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2024. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Chart, ChartAnnotation, ChartDataLabels } from '../../chartjs-es/module.js';
import '../../styles';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { resizeMixin } from '../../utils/resize-mixin.js';
/**
 * @property {Array} labels - Defines the label of each data point (e.g. columns on a Bar chart).
 * @property {Object} datasets - Defines the datasets represented in the chart.
 * @property {String} label - 	Defines the label of a specific data set. This value is also rendered in the legend. (defined inside the datasets object)
 * @property {Array} data - Defines each individual data point. Bar, Pie, Doughnut, Line and Radar charts take individual numbers for each data point. Scatter and Bubble charts take an X and Y value, while Bubble also requires a R value to define the radius of each point. (defined inside the datasets object).
 * @property {String/Arrray} color - Defines the color that represents the data set. A single color can be given, but for charts such as Pie and Doughnut, a string of colors could be given instead, which will be applied in sequence to the respective data points.  (defined inside the datasets object)
 * @property {Boolean} fill - For Line and Radar charts, defines if the outlines are filled or not.  (defined inside the datasets object)
 */
let SHChart = class SHChart extends resizeMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**Defines the type of chart displayed. Possible values are `bar`, `horizontalBar`, `pie`, `doughnut`, `line`, `radar`, `scatter`, `bubble`. */
    this.type = 'line';
    /**If set to `true`, the dataset legends will be shown above the chart. */
    this.legend = false;
  }
  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          height: 100%;
          width: 100%;
          position: relative;
          display: flex;
        }
        .chart-parent {
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          display: flex;
          flex: 1;
          position: relative;
        }
      `,
    ];
  }
  render() {
    return html` <div class="chart-parent">
      <canvas id="chart-wrapper" width="0" height="0"></canvas>
    </div>`;
  }
  firstUpdated() {
    Chart.plugins.register([ChartDataLabels, ChartAnnotation]);
    /** checking for updateComplete here to avoid lit warning */
    this.updateComplete.then(() => {
      this.createChart();
    });
  }
  update(changedProperties) {
    if (
      changedProperties.has('type') ||
      changedProperties.has('legend') ||
      changedProperties.has('data') ||
      changedProperties.has('options')
    ) {
      this.updateComplete.then(() => {
        this.createChart();
      });
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    const listOfProperties = ['options', 'type', 'legend', 'data'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  handleResize() {
    let id;
    for (id in Chart.instances) {
      if (Chart.instances.hasOwnProperty(id)) {
        Chart.instances[id].resize();
      }
    }
  }
  disconnectedCallback() {
    var _a;
    super.disconnectedCallback();
    (_a = this.chartData) === null || _a === void 0 ? void 0 : _a.destroy();
  }
  setChartData() {
    // set default attributes on load
    var _a;
    if (this.data === null || this.data === undefined) {
      return;
    }
    const sets = (_a = this.data) === null || _a === void 0 ? void 0 : _a.datasets.length;
    for (let i = 0; i < sets; i++) {
      // default values for customizable dataset attributes
      this.data.datasets[i].label = !this.data.datasets[i].label
        ? 'Dataset Label'
        : this.data.datasets[i].label;
      if (this.data.datasets[i].color === undefined) {
        if (this.type !== 'pie' && this.type !== 'doughnut' && sets > 1) {
          this.setChartDataColor(i);
        } else {
          this.data.datasets[i].color = [
            'rgba(50,158,188,.8)',
            'rgba(139,50,188,.8)',
            'rgba(50,188,77,.8)',
            'rgba(50,188,167,.8)',
            'rgba(50,144,188,.8)',
            'rgba(188,50,115,.8)',
          ];
        }
      }
      this.data.datasets[i].fill =
        this.data.datasets[i].fill === undefined ? false : this.data.datasets[i].fill;
      // default values for fixed dataset attributes
      this.data.datasets[i].pointBorderColor = 'transparent';
      this.data.datasets[i].pointRadius = 4;
      this.data.datasets[i].pointHoverRadius = 8;
      this.data.datasets[i].pointHitRadius = 4;
      this.data.datasets[i].backgroundColor = this.data.datasets[i].color;
      this.data.datasets[i].hoverBackgroundColor = this.data.datasets[i].color;
      this.data.datasets[i].pointBackgroundColor = this.data.datasets[i].color;
      this.data.datasets[i].pointHoverBackgroundColor = this.data.datasets[i].color;
      this.data.datasets[i].borderColor = this.data.datasets[i].color;
      if (
        this.type === 'line' ||
        this.type === 'radar' ||
        this.type === 'pie' ||
        this.type === 'doughnut'
      ) {
        this.data.datasets[i].borderWidth = 2;
        if (this.type === 'pie' || this.type === 'doughnut') {
          this.data.datasets[i].borderColor = 'white';
        }
      } else {
        this.data.datasets[i].borderWidth = 0;
      }
      if (this.type === 'radar') {
        this.data.datasets[i].lineTension = 0;
      }
    }
  }
  setChartDataColor(i) {
    switch (i) {
      case 0:
        this.data.datasets[i].color = 'rgba(50,158,188,1)';
        break;
      case 1:
        this.data.datasets[i].color = 'rgba(139,50,188,.8)';
        break;
      case 2:
        this.data.datasets[i].color = 'rgba(50,188,77,.8)';
        break;
      case 3:
        this.data.datasets[i].color = 'rgba(50,188,167,.8)';
        break;
      case 4:
        this.data.datasets[i].color = 'rgba(50,144,188,.8)';
        break;
      case 5:
        this.data.datasets[i].color = 'rgba(188,50,115,.8)';
        break;
    }
  }
  createChart() {
    var _a, _b;
    if (this.chartData !== undefined && this.chartData !== null) {
      this.chartData.destroy();
    }
    this.setChartData();
    Chart.defaults.global.defaultFontColor = 'rgba(0,0,0,.6)';
    Chart.defaults.global.defaultFontFamily = 'siemens sans';
    Chart.defaults.global.defaultFontSize = 14;
    Chart.defaults.global.legend.labels.fontSize = 12;
    Chart.defaults.global.legend.labels.fontColor = 'rgba(0,0,0,.9)';
    Chart.defaults.global.legend.labels.padding = 16;
    Chart.defaults.global.legend.labels.usePointStyle = true;
    Chart.defaults.global.tooltips.xPadding = 8;
    this.ctx =
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#chart-wrapper')) === null || _b === void 0
        ? void 0
        : _b.getContext('2d');
    this.chartData = new Chart(this.ctx, {
      type: this.type,
      data: this.data,
      options: this.options,
    });
    this.chartData.options.tooltips.backgroundColor = 'rgb(255, 255, 255)';
    this.chartData.options.tooltips.titleFontColor = 'rgba(0,0,0,.9)';
    this.chartData.options.tooltips.bodyFontColor = 'rgba(0,0,0,.9)';
    this.chartData.options.tooltips.titleSpacing = 8;
    this.chartData.options.tooltips.bodySpacing = 4;
    this.chartData.options.tooltips.footerSpacing = 4;
    this.chartData.options.tooltips.yPadding = 8;
    this.chartData.options.tooltips.borderColor = 'rgba(0,0,0,.2)';
    this.chartData.options.tooltips.multiKeyBackground = 'transparent';
    this.chartData.options.tooltips.cornerRadius = 2;
    this.chartData.options.tooltips.borderWidth = 1;
    this.chartData.options.legend.display = this.legend;
    if (this.type === 'line') {
      // this.borderWidth = 2; Does not do anything! What is the requirement?
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHChart.prototype, 'type', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHChart.prototype, 'legend', void 0);
__decorate(
  [
    property({ type: Object }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ],
  SHChart.prototype,
  'data',
  void 0
);
__decorate([property({ type: Object })], SHChart.prototype, 'options', void 0);
__decorate(
  [
    property({ type: Object }),
    /** @ignore */
  ],
  SHChart.prototype,
  'chartData',
  void 0
);
SHChart = __decorate([customElement('sh-chart')], SHChart);
export { SHChart };
