// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/**
 * Base event class for our legacy propXYZ-changed events
 */
export class LegacyPropertyChangedEvent extends CustomEvent {
  constructor(prop, init) {
    super(`${prop}-changed`, init);
  }
}
export class DisabledPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor(init) {
    super('disabled', init);
  }
}
export class IconPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('icon');
  }
}
export class LabelPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('label');
  }
}
export class SizePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('size');
  }
}
export class ColorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('color');
  }
}
export class TooltipPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('tooltip');
  }
}
export class HrefPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('href');
  }
}
export class DragAndDropPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('dragAndDrop');
  }
}
export class IconColorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('iconColor');
  }
}
export class HeaderPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('header');
  }
}
export class ButtonPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('button');
  }
}
export class BadgePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('badge');
  }
}
export class TogglePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('toggle');
  }
}
export class StickyPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('sticky');
  }
}
export class TitlePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('title');
  }
}
export class VariationPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('variation');
  }
}
export class TargetPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('target');
  }
}
export class NumberPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('number');
  }
}
export class VisiblePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('visible');
  }
}
export class TypePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('type');
  }
}
export class ModalHeightPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('modalHeight');
  }
}
export class ModalWidthPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('modalWidth');
  }
}
export class PagesPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('pages');
  }
}
export class CurrentPagePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('current-page');
  }
}
export class SpacingPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('spacing');
  }
}
export class VariantPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('variant');
  }
}
export class VerticalPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('vertical');
  }
}
export class IndeterminatePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('indeterminate');
  }
}
export class NeutralPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('neutral');
  }
}
export class HintPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('hint');
  }
}
export class ErrorMessagePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('errorMessage');
  }
}
export class MultiSelectPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('multiSelect');
  }
}
export class NoBorderPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('noBorder');
  }
}
export class MandatoryPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('mandatory');
  }
}
export class SuccessPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('success');
  }
}
export class SafetyPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('safety');
  }
}
export class EditablePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('editable');
  }
}
export class ValuePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor(init) {
    super('value', init);
  }
}
export class MultilinePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('multiline');
  }
}
export class FieldsPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('fields');
  }
}
export class SrcPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('src');
  }
}
export class PlacementPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('placement');
  }
}
export class ErrorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('error');
  }
}
export class ImagePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('image');
  }
}
export class LabelAlignmentPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('labelAlignment');
  }
}
export class OverlayPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('overlay');
  }
}
export class PausedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('paused');
  }
}
export class RadialPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('radial');
  }
}
export class StatusPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('status');
  }
}
export class InfoPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('info');
  }
}
export class ModalPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('modal');
  }
}
export class ProgressPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('progress');
  }
}
export class SubHeadlinePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('subHeadline');
  }
}
export class VersionPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('version');
  }
}
export class NoClearPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('noClear');
  }
}
export class ActivePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('active');
  }
}
export class CurrentPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('current');
  }
}
export class DecimalsPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('decimals');
  }
}
export class EuropeanNumberConventionPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('europeanNumberConvention');
  }
}
export class ReadonlyPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('readonly');
  }
}
export class MessageVisiblePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('messageVisible');
  }
}
export class ThumbnailPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('thumbnail');
  }
}
export class FlatPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('flat');
  }
}
export class ExpandedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('expanded');
  }
}
export class MessagePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('message');
  }
}
export class VisualFocusPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('visualFocus');
  }
}
export class SelectablePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('selectable');
  }
}
export class NewPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('new');
  }
}
export class SubMenuPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('subMenu');
  }
}
export class PositionPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('position');
  }
}
export class PassivePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('passive');
  }
}
export class MobilePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('mobile');
  }
}
export class MiniPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('mini');
  }
}
export class CheckedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('checked');
  }
}
export class OptionsPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('options');
  }
}
export class CollapsedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('collapsed');
  }
}
export class CondensedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('condensed');
  }
}
export class NativePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('native');
  }
}
export class EmbeddedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('embedded');
  }
}
export class TextareaPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('textarea');
  }
}
export class ResizablePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('resizable');
  }
}
export class CardHeightPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('cardHeight');
  }
}
export class ArrowPositionPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('arrowPosition');
  }
}
export class BehaviorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('behavior');
  }
}
export class StretchPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('stretch');
  }
}
export class HPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('h');
  }
}
export class MPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('m');
  }
}
export class SPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('s');
  }
}
export class AmPmPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('amPm');
  }
}
export class StepPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('step');
  }
}
export class ClockFormatPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('clockFormat');
  }
}
export class ValPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('val');
  }
}
export class TimeValueChangedEvent extends CustomEvent {
  constructor(name, init) {
    super(name, init);
  }
}
export class currentActivePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('currentActive');
  }
}
export class currentSelectedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('currentSelected');
  }
}
export class DrawerWidthPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('drawerWidth');
  }
}
export class AspectRatioPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('aspectRatio');
  }
}
export class MinPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('min');
  }
}
export class MaxPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('max');
  }
}
export class KeypadPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('keypad');
  }
}
export class NoArrowsPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('noArrows');
  }
}
export class InputDisabledPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('inputDisabled');
  }
}
export class KeypadActivePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('keypadActive');
  }
}
export class KeypadDisabledPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('keypadDisabled');
  }
}
export class NoValidatePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('noValidate');
  }
}
export class FixedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('fixed');
  }
}
export class InitialsPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('initials');
  }
}
export class NamePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('name');
  }
}
export class PatientPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('patient');
  }
}
export class ShowControlsPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('showControls');
  }
}
export class ShowRangePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('showRange');
  }
}
export class LowerValuePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor(init) {
    super('lower-value', init);
  }
}
export class UpperValuePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor(init) {
    super('upper-value', init);
  }
}
export class ChangeEndEvent extends CustomEvent {
  constructor(type, init) {
    super(type, init); //emit different strings based on differnt event types (value-change-end, range-value-change-end)
  }
}
export class UnauthenticatedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('unauthenticated');
  }
}
export class BackgroundPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('background');
  }
}
export class PaddingPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('padding');
  }
}
export class ElevationPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('elevation');
  }
}
export class ContentGapPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('contentGap');
  }
}
export class LayoutPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('layout');
  }
}
