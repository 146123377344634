// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/**
 * Year object used to store information about a year like:
 * the date corresponding to the year, whether the year is selected
 * or not, whether the year is today's year or not and whether the year
 * is disabled or not.
 *
 * @property {Date} date - the date corresponding to Jan 1 of the year
 * @property {Boolean} isSelected - whether the date is selected or not.
 * @property {Boolean} isThisYear - whether the date is today or not.
 * @property {Boolean} isDisabled - whether the date is disabled or not.
 */
export class YearData {
  constructor() {
    this.date = null;
    this.isSelected = false;
    this.isThisYear = false;
    this.isDisabled = false;
  }
}
/**
 * Function that generates static decade data.
 *
 * @param {Number} decade - the decade to which the years belong
 * @param {Date} todaysDate - this year's January 1st date with hours, minutes, seconds and milliseconds set to zero
 * @param {Date} selectedDate - the selected year's January 1st date with hours, minutes, seconds and milliseconds set to zero
 * @param {Boolean} futureDateDisable - whether the future years (years coming after todaysDate) are disabled or not.
 * @param {Date} minDate - the minimum year's January 1st date with hours, minutes, seconds and milliseconds set to zero
 * @param {Date} maxDate - the maximum year's January 1st date with hours, minutes, seconds and milliseconds set to zero
 * @returns An array of `YearData` corresponding to the given parameters.
 */
export function generateDecadeData(
  decade,
  todaysDate,
  selectedDate = null,
  futureDateDisable = false,
  pastDateDisable = false,
  minDate = null,
  maxDate = null
) {
  const decadeData = [];
  const startingYear = decade - 1;
  for (let i = startingYear; i <= startingYear + 11; i++) {
    const yearData = new YearData();
    yearData.date = new Date(i, 0, 1, 0, 0, 0, 0);
    yearData.isDisabled = isDisabledYear(
      yearData.date,
      todaysDate,
      futureDateDisable,
      pastDateDisable,
      minDate,
      maxDate
    );
    if (!yearData.isDisabled) {
      yearData.isSelected =
        Boolean(selectedDate) && yearData.date.getTime() === selectedDate.getTime();
      yearData.isThisYear = yearData.date.getTime() === todaysDate.getTime();
    }
    decadeData.push(yearData);
  }
  return decadeData;
}
function isDisabledYear(
  currentDate,
  todaysDate,
  futureDateDisable,
  pastDateDisable,
  minDate,
  maxDate
) {
  const currentDateTime = currentDate.getTime();
  const todaysDateTime = todaysDate.getTime();
  let isDisabled =
    (Boolean(futureDateDisable) && currentDateTime > todaysDateTime) ||
    (Boolean(pastDateDisable) && currentDateTime < todaysDateTime);
  if (!isDisabled && minDate) {
    if (currentDate.getTime() < minDate.getTime()) {
      isDisabled = true;
    }
  }
  if (!isDisabled && maxDate) {
    if (currentDate.getTime() > maxDate.getTime()) {
      isDisabled = true;
    }
  }
  return isDisabled;
}
