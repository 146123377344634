// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import radioGroupStyles from './sh-radio-group.lit.scss.js';
let SHRadioGroup = class SHRadioGroup extends LitElement {
  constructor() {
    super(...arguments);
    /** Default false. Defines if a radio button direction by defauly direction is vertical. If true, direction is set to horizontal. */
    this.horizontal = false;
    /** @ignore */
    this.role = 'radiogroup';
  }
  static get styles() {
    return [radioGroupStyles];
  }
  render() {
    return html`<slot id="grouped" @slotchange="${this.handleSlotChange}"></slot>`;
  }
  updated() {
    const radios = this.querySelectorAll('sh-radio-button');
    const totalRadios = radios.length;
    radios.forEach((radio, index) => {
      radio.setAttribute('data-posinset', (index + 1).toString());
      radio.setAttribute('data-setsize', totalRadios.toString());
    });
  }
  firstUpdated() {
    this.addEventListener('click', (e) => {
      this.handleClick(e);
    });
  }
  handleSlotChange() {
    const radios = this.querySelectorAll('sh-radio-button');
    const activeRadio = this.querySelector('sh-radio-button[active]');
    radios.forEach((radio) => {
      if (activeRadio === radio) {
        radio.setAttribute('tabindex', '0'); // Only active one should be focusable
      } else {
        radio.setAttribute('tabindex', '-1');
      }
    });
    // If no active radio, set tabindex="0" on the first one
    if (!activeRadio && radios.length > 0) {
      radios[0].setAttribute('tabindex', '0');
    }
  }
  handleClick(e) {
    const target = e.target;
    // If the clicked target is not disabled and not radio-group, but is a radio-button, then only toggle
    // active for child radio-buttons.
    if (
      !target.disabled &&
      !target.readonly &&
      target.tagName !== 'SH-RADIO-GROUP' &&
      target.tagName === 'SH-RADIO-BUTTON'
    ) {
      const activeEls = this.querySelectorAll('[active]:not([readonly])');
      this.handleSlotChange();
      for (const activeEl of activeEls) {
        if (activeEl !== target) {
          activeEl.active = false;
        }
      }
    }
  }
};
__decorate(
  [queryAssignedElements({ slot: 'default' })],
  SHRadioGroup.prototype,
  'radioButtons',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioGroup.prototype,
  'horizontal',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHRadioGroup.prototype, 'role', void 0);
SHRadioGroup = __decorate([customElement('sh-radio-group')], SHRadioGroup);
export { SHRadioGroup };
