// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

export const baseFontSize = 16;

export const calculatedBaseFontSize = getCalculatedBaseFontSize();
function getCalculatedBaseFontSize(): number {
  const calculatedBaseFontSize = parseFloat(
    window
      .getComputedStyle(document.querySelector('html') as HTMLElement, null)
      .getPropertyValue('font-size')
  );
  return calculatedBaseFontSize;
}
