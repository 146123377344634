// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/**
 * ### convertToPixel()
 * ___
 * Function that converts rem to pixels.
 * ___
 * #### How to use this function ?
 * Import like this in the component:
 *
 * ```js
 * import { convertToPixel } from '@shui-web-components/utils/rem-to-pixel-convertor.js'
 * ```
 * Then use the function like the following:
 *
 * Suppose you want to convert 0.5rem to px.
 * For that, you can do as following:
 *
 * ```js
 * const pxValue = convertToPixel(0.5);
 * ```
 *
 * @param {Number} remValue The rem value in number
 * @returns Px value in number
 */
export const convertToPixel = (remValue) => {
  const htmlFontSize = Number(
    getComputedStyle(document.documentElement).getPropertyValue('font-size').split('px')[0]
  );
  return remValue * htmlFontSize;
};
