import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import boxStyles from './sh-box.lit.scss.js';
let SHBoxHeader = class SHBoxHeader extends LitElement {
  static get styles() {
    return [sharedStyles, boxStyles];
  }
  render() {
    return html`<slot></slot> ${this.divider &&
      (this.divider === 'inset' || this.divider === 'full-width')
        ? html`<div class="header-divider-wrapper"></div>`
        : html``}`;
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    if (!['wide', 'compact', 'supercompact', 'none'].includes(newval)) {
      const paddingAttributes = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'];
      if (name === 'padding') {
        this.style.setProperty(`--container-padding`, newval || `var(--container-padding)`);
        for (const eachItem of paddingAttributes) {
          this.style.setProperty(`--${eachItem}`, newval || `var(--${eachItem})`);
        }
      } else if (paddingAttributes.includes(name)) {
        this.style.setProperty(`--${name}`, newval || `var(--${name})`);
      }
    }
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHBoxHeader.prototype,
  'background',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHBoxHeader.prototype, 'padding', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-top' })],
  SHBoxHeader.prototype,
  'paddingTop',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-right' })],
  SHBoxHeader.prototype,
  'paddingRight',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-bottom' })],
  SHBoxHeader.prototype,
  'paddingBottom',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-left' })],
  SHBoxHeader.prototype,
  'paddingLeft',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'divider' })],
  SHBoxHeader.prototype,
  'divider',
  void 0
);
SHBoxHeader = __decorate([customElement('sh-box-header')], SHBoxHeader);
export { SHBoxHeader };
