// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from "tslib";
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import arrowControlStyles from './arrow-controls.lit.scss.js';
/**
 * @fires value-change-end - Marks the end of a long press on the arrow keys as well as drag end.
 * @fires range-value-change-end - Marks the end of the whole range slider drag.*/
let SHArrowControls = class SHArrowControls extends LitElement {
    constructor() {
        super(...arguments);
        /** Default 0. Defines the minimum value allowed. */
        this.min = 0;
        /** Default 100. Defines the maximum value allowed. */
        this.max = 100;
        /** Default 1. Defines the intervals of the parent component. If set to 0.1 for example, decimals will be allowed. */
        this.step = 1;
    }
    static get styles() {
        return [arrowControlStyles];
    }
    render() {
        return html `
      <sh-icon
        id="minus"
        button
        icon="${this.horizontal ? 'left-s' : 'up-s'}"
        ?disabled="${this.disabled}"
        @mousedown="${this.startCounter}"
        @mousemove="${this.releaseCounter}"
        @mouseup="${this.releaseCounter}"
        @touchstart="${this.startCounter}"
        @touchend="${this.releaseCounter}"
        @keyup="${(e) => {
            if (e.code === 'Enter' || e.code === 'Space') {
                this.releaseCounter(e);
            }
        }}"
      ></sh-icon>
      <sh-icon
        id="plus"
        button
        icon="${this.horizontal ? 'right-s' : 'down-s'}"
        ?disabled="${this.disabled}"
        @mousedown="${this.startCounter}"
        @mousemove="${this.releaseCounter}"
        @mouseup="${this.releaseCounter}"
        @touchstart="${this.startCounter}"
        @touchend="${this.releaseCounter}"
        @keyup="${(e) => {
            if (e.code === 'Enter' || e.code === 'Space') {
                this.releaseCounter(e);
            }
        }}"
      ></sh-icon>
    `;
    }
    startCounter(e) {
        if (e.touches) {
            this.skipMouseMove = true;
        }
        const target = e.target;
        target.style.transition = '0s all linear';
        this.releaseCounter();
        this.setFlags(e);
        this.clock = window.requestAnimationFrame(this.clockControl.bind(this));
    }
    handleOnClick() {
        if (this.mouseDownAddition) {
            this.mouseDownAddition = false;
            return;
        }
        if (this.clickedOnPlusIcon) {
            this.handlePlusCalc();
        }
        else if (this.clickedOnMinusIcon) {
            this.handleMinusCalc();
        }
    }
    clockControl() {
        this.timer = setTimeout(this.handleOnMouseDown.bind(this), 100);
    }
    handleOnMouseDown() {
        if (this.clickedOnPlusIcon) {
            this.handlePlusCalc();
        }
        else if (this.clickedOnMinusIcon) {
            this.handleMinusCalc();
        }
        this.mouseDownAddition = true;
        this.clock = window.requestAnimationFrame(this.clockControl.bind(this));
    }
    handleMinusCalc() {
        const decimalPoints = this.decimalNumberPrecision();
        let tempval = 0;
        if (this.value !== this.min) {
            tempval =
                parseFloat(this.value) - parseFloat(this.step);
            tempval = Number(tempval).toFixed(decimalPoints);
            if (tempval >= this.min) {
                this.value = Number(tempval);
                dispatchCustomEvent(this, 'control-value-change');
            }
        }
    }
    handlePlusCalc() {
        const decimalPoints = this.decimalNumberPrecision();
        let tempval = 0;
        if (this.value !== this.max) {
            tempval =
                parseFloat(this.value) + parseFloat(this.step);
            tempval = Number(tempval).toFixed(decimalPoints);
            if (tempval <= this.max) {
                this.value = Number(tempval);
                dispatchCustomEvent(this, 'control-value-change');
            }
        }
    }
    decimalNumberPrecision() {
        const stepInString = this.step.toString();
        const minInString = this.min.toString();
        let numberOfDecimalPoints;
        numberOfDecimalPoints = 0;
        if (stepInString.includes('.')) {
            numberOfDecimalPoints = stepInString.split('.')[1].length;
        }
        else if (minInString.includes('.')) {
            numberOfDecimalPoints = minInString.split('.')[1].length;
        }
        return numberOfDecimalPoints;
    }
    releaseCounter(e) {
        /**
         * The below check for mousemove was to prevent mousemove from clearing the timer
         * for repeated addition/subtraction in touch scenarios.
         * In some touch scenarios, the mousemove was coming after touchstart and before touchend.
         * So to prevent this scenario we set a flag when we detect mousemove.
         * Also event.preventDefault() has to be called after touchend actions are done,
         * to prevent the subsequent mouse event handlers from executing.
         */
        if (e && e.type === 'mousemove' && this.skipMouseMove) {
            this.skipMouseMove = false;
            return;
        }
        window.cancelAnimationFrame(this.clock);
        clearTimeout(this.timer);
        if (e && (e.type === 'mouseup' || e.type === 'touchend')) {
            dispatchCustomEvent(this, 'control-value-change-end');
        }
        if (e && e.type !== 'mousemove') {
            this.setFlags(e);
            this.handleOnClick();
        }
        if (e && e.touches) {
            e.preventDefault();
        }
        this.clickedOnPlusIcon = false;
        this.clickedOnMinusIcon = false;
    }
    setFlags(e) {
        /**
         * sets the flag for the respective operation.
         */
        if (e.target.id === 'plus') {
            this.clickedOnPlusIcon = true;
        }
        else if (e.target.id === 'minus') {
            this.clickedOnMinusIcon = true;
        }
    }
    disconnectedCallback() {
        window.cancelAnimationFrame(this.clock);
        clearTimeout(this.timer);
        super.disconnectedCallback();
    }
};
__decorate([
    property({ type: Number, reflect: true })
], SHArrowControls.prototype, "value", void 0);
__decorate([
    property({ type: Number, reflect: true })
], SHArrowControls.prototype, "min", void 0);
__decorate([
    property({ type: Number, reflect: true })
], SHArrowControls.prototype, "max", void 0);
__decorate([
    property({ type: Number, reflect: true })
], SHArrowControls.prototype, "step", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SHArrowControls.prototype, "horizontal", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SHArrowControls.prototype, "disabled", void 0);
SHArrowControls = __decorate([
    customElement('arrow-controls')
], SHArrowControls);
export { SHArrowControls };
