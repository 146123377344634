// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __awaiter, __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { decimalSeparator } from '../../utils/decimal-separator.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import { KEYCODE } from '../../utils/keycode.js';
import inputNumberStyles from './sh-input-number.lit.scss.js';
/**
 * @fires keypad-opened - Fired when the keypad is opened.
 * @fires keypad-closed - Fired when the keypad is closed.
 * @fires minusPressed - Fired when left facing icon is pressed.
 * @fires plusPressed - Fired when right facing icon is pressed.
 * @fires clear-clicked - Fired when the clear icon is clicked.
 */
let SHInputNumber = class SHInputNumber extends FocusBlurMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**Defines the label of the input. If the value is empty, it will be shown as a placeholder. */
    this.label = 'label';
    /**Defines the size of the increment/decrement when the user presses the minus or plus step icons. */
    this.step = 1;
    /**Defines a limit of decimal numbers allowed (e.g. if set to 2, 1.000 value will be rounded to 1.00).*/
    this.decimals = '';
    /** @ignore */
    this.displayValue = '';
    /** @ignore */
    this.decimalSeparator = '.';
    /** @ignore */
    this.inputMode = 'numeric';
    this.boundMove = this.releasePlusCounter.bind(this);
    this.closeListener = this.closeInputKeyPad.bind(this);
    this.rAFCallBack = this.showKeypad.bind(this);
  }
  static get styles() {
    return [inputNumberStyles];
  }
  render() {
    return html`
      <div style="display: flex; width: 100%">
        <sh-input-text
          .label=${this.label}
          id="input"
          .value=${this.value}
          ?error=${this.error}
          ?success=${this.success}
          ?safety=${this.safety}
          type="text"
          ?condensed="${this.condensed}"
          ?readonly="${this.readonly}"
          ?disabled="${this.disabled}"
          ?no-border="${this.noBorder}"
          ?no-clear="${this.noClear}"
          ?mandatory="${this.mandatory}"
          min="${this.min}"
          max="${this.max}"
          step="${this.step}"
          @click="${(e) => {
            this.storeCursorPosition(e.target);
          }}"
          @input-clicked="${(e) => {
            e.stopPropagation();
          }}"
          @input-focused="${(e) => {
            e.stopPropagation();
            this.inputFocus(e);
          }}"
          @input="${(e) => {
            var _a, _b;
            this.value = this.europeanNumberConvention
              ? (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.value) === null ||
                _b === void 0
                ? void 0
                : _b.toString().split(',').join('.')
              : e.target.value;
          }}"
          @value-changed="${(e) => {
            var _a, _b, _c, _d, _e;
            if (this.value !== '') {
              const inputText =
                (_a = this.shadowRoot) === null || _a === void 0
                  ? void 0
                  : _a.querySelector('#input');
              (_c =
                (_b =
                  inputText === null || inputText === void 0 ? void 0 : inputText.shadowRoot) ===
                  null || _b === void 0
                  ? void 0
                  : _b.querySelector('#clear')) === null || _c === void 0
                ? void 0
                : _c.setAttribute('tabindex', '0');
            }
            if (!this.value && this.keypadActive) {
              const inputText =
                (_d = this.shadowRoot) === null || _d === void 0
                  ? void 0
                  : _d.querySelector('#input');
              inputText.classList.remove('empty');
            }
            if ((this.value == '' || this.value === undefined) && this.readonly) {
              const inputText =
                (_e = this.shadowRoot) === null || _e === void 0
                  ? void 0
                  : _e.querySelector('#input');
              inputText.setAttribute('tabindex', '-1');
            }
            e.stopPropagation();
          }}"
          .notifyInputClick="${true}"
          .notifyInputFocus="${true}"
          @clear-clicked="${() => this.clearField()}"
          @keydown="${(e) => {
            this.restrictOnlyNumberKeys(e);
            if (!this.inputDisabled) {
              this.closeKeypadIfBackTabbed(e);
            } else {
              e.preventDefault();
            }
          }}"
          @keyup="${() => this.updateDisplayValue.bind(this)}"
          @focus="${() => {
            var _a, _b;
            const inputText =
              (_a = this.shadowRoot) === null || _a === void 0
                ? void 0
                : _a.querySelector('#input');
            if (
              (inputText === null || inputText === void 0 ? void 0 : inputText.shadowRoot) &&
              ((_b = inputText === null || inputText === void 0 ? void 0 : inputText.shadowRoot) ===
                null || _b === void 0
                ? void 0
                : _b.querySelector('input'))
            ) {
              inputText.shadowRoot.querySelector('input').inputMode = this.inputMode;
            }
          }}"
          @blur="${(e) => this.handleInputBlur(e)}"
          ><div class="icon-wrapper" style="display: flex;" slot="icon">
            ${!this.noArrows && !this.readonly ? this.renderIcons() : ''}
          </div></sh-input-text
        >
        ${this.keypad
          ? html`
              <sh-numeric-keypad
                @key-press="${(e) => {
                  var _a;
                  this.updateInputBox(
                    e,
                    (_a = this.shadowRoot) === null || _a === void 0
                      ? void 0
                      : _a.querySelector('#input')
                  );
                }}"
                @closing="${() => {
                  this.closeKeypadIfTabbed();
                }}"
                .target=${this}
                .disabled=${this.keypadDisabled}
                .visible=${this.keypadActive}
                .condensed=${this.condensed}
                .decimals=${this.decimals}
                .europeanNumberConvention=${this.europeanNumberConvention}
              >
                <slot name="functions" slot="functions"></slot>
                <slot name="footer" slot="footer"></slot>
              </sh-numeric-keypad>
            `
          : ''}
      </div>
      ${this.hint && !this.error
        ? html`
            <sh-text size="body-2" class="helper-text hint" color="secondary">${this.hint}</sh-text>
          `
        : ''}
      ${this.error && this.errorMessage
        ? html`
            <sh-text size="body-2" class="helper-text error-message" color="secondary"
              >${this.errorMessage}</sh-text
            >
          `
        : ''}
    `;
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
  }
  update(changedProperties) {
    if (changedProperties.has('value')) {
      this.updateComplete.then(() => {
        this.valueChanged();
      });
    }
    if (changedProperties.has('decimals')) {
      this.updateComplete.then(() => {
        this.decimalsObserver();
      });
    }
    if (changedProperties.has('inputDisabled')) {
      this.handleInputDisabledChange();
    }
    if (changedProperties.has('keypad')) {
      this.keypadObserver();
    }
    if (changedProperties.has('keypadActive')) {
      this.handleKeypadActive();
    }
    if (changedProperties.has('europeanNumberConvention')) {
      this.updateComplete.then(() => {
        this.europeanNumberConventionObserver();
      });
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    // We have added this separate dispatcher for value-changed event
    // so as to emit detail object also in the value-changed event.
    // This was done to support a BL from AIRC whose product was already
    // released. This feature of emitting detail object along-with
    // value-changed event, will be removed after one release.
    // TODO: Remove this after one release
    if (changedProperties.has('value')) {
      dispatchCustomEvent(this, 'value-changed', { value: this.value }, false);
    }
    const listOfProperties = [
      'label',
      'step',
      'readonly',
      'disabled',
      'min',
      'max',
      'mandatory',
      'error',
      'success',
      'safety',
      'hint',
      'condensed',
      'decimals',
      'keypad',
      'errorMessage',
      'noClear',
      'noArrows',
      'inputDisabled',
      'keypadActive',
      'keypadDisabled',
      'noBorder',
      'noValidate',
      'europeanNumberConvention',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    document.body.addEventListener('mousemove', this.boundMove);
    this.addEventListener('blur', () => {
      this.validateOnBlurOnNormalInput();
    });
    this.checkMaxMin();
    this.renewStableValue(this.value);
    /**
     * If europeanNumberConvention property is not boolean,
     * then that means europeanNumberConvention property was never
     * set by the user. So it can be set by checking locale string.
     *
     * Otherwise, do not set the europeanNumberConvention property.
     */
    if (this.europeanNumberConvention === undefined || this.europeanNumberConvention === null) {
      this.setBrowserLanguageBasedEuropeanNumberConventionProperty();
    }
    if (!this.value && this.value !== 0) {
      this.classList.add('empty');
    }
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'textbox');
    }
    this.addEventListener('keydown', (e) => {
      this.closeKeypadIfTabbedOnAppropriateElement(e);
    });
  }
  renderIcons() {
    return html` <sh-icon
        button
        icon="${this.mathOperators ? 'minus-light' : 'left-s'}"
        id="minus"
        ?disabled="${this.disabled}"
        @keypress="${(e) => this.releaseMinusCounter(e)}"
        @keyup="${(e) => {
          if (e.code === 'Space' || e.code === 'NumpadEnter') {
            this.releaseMinusCounter(e);
          }
        }}"
        @mousedown="${(e) => this.startMinusCounter(e)}"
        @mouseup="${(e) => this.releaseMinusCounter(e)}"
        @touchstart="${(e) => this.startMinusCounter(e)}"
        @touchend="${(e) => this.releaseMinusCounter(e)}"
      ></sh-icon>
      <sh-icon
        button
        icon="${this.mathOperators ? 'plus-light' : 'right-s'}"
        id="plus"
        ?disabled="${this.disabled}"
        @keypress="${(e) => this.releasePlusCounter(e)}"
        @keyup="${(e) => {
          if (e.code === 'Space' || e.code === 'NumpadEnter') {
            this.releasePlusCounter(e);
          }
        }}"
        @mousedown="${(e) => this.startPlusCounter(e)}"
        @mouseup="${(e) => this.releasePlusCounter(e)}"
        @touchstart="${(e) => this.startPlusCounter(e)}"
        @touchend="${(e) => this.releasePlusCounter(e)}"
      ></sh-icon>`;
  }
  valueChanged() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (!this.value && this.value !== 0) {
      this.classList.add('empty');
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input')) ===
        null || _b === void 0
        ? void 0
        : _b.classList.add('empty');
      this.displayValue = '';
    } else {
      this.classList.remove('empty');
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#input')) ===
        null || _d === void 0
        ? void 0
        : _d.classList.remove('empty');
      const inputText =
        (_e = this.shadowRoot) === null || _e === void 0 ? void 0 : _e.querySelector('#input');
      if (
        (_f = inputText === null || inputText === void 0 ? void 0 : inputText.shadowRoot) ===
          null || _f === void 0
          ? void 0
          : _f.querySelector('#clear')
      ) {
        (_h =
          (_g = inputText === null || inputText === void 0 ? void 0 : inputText.shadowRoot) ===
            null || _g === void 0
            ? void 0
            : _g.querySelector('#clear')) === null || _h === void 0
          ? void 0
          : _h.setAttribute('tabindex', '0');
      }
      if (this.value || this.value === 0) {
        const valueOfInput = this.value.toString();
        if (valueOfInput !== '' && valueOfInput.indexOf('.') > -1) {
          const decimalPlaces = valueOfInput.split('.')[1].length;
          const inputText =
            (_j = this.shadowRoot) === null || _j === void 0 ? void 0 : _j.querySelector('#input');
          if (inputText) {
            inputText.step = Math.pow(10, -decimalPlaces);
          }
        }
        let numberSystem;
        if (this.europeanNumberConvention === undefined || this.europeanNumberConvention === null) {
          numberSystem = decimalSeparator === ',' ? 'European' : 'English';
        } else {
          numberSystem = this.europeanNumberConvention ? 'European' : 'English';
        }
        if (numberSystem === 'English') {
          this.displayValue = this.value.toString().split(',').join('.');
        } else {
          this.displayValue = this.value.toString().split('.').join(',');
        }
      }
    }
    this.displayValueChange();
  }
  getValue() {
    if (this.value !== undefined && this.value !== null) {
      const valueInNumber = parseFloat(this.value.toString());
      if (!isNaN(valueInNumber)) {
        return valueInNumber;
      } else {
        return '';
      }
    }
    return;
  }
  updateInputBox(e, input) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      const inputBox =
        (_a = input === null || input === void 0 ? void 0 : input.shadowRoot) === null ||
        _a === void 0
          ? void 0
          : _a.querySelector('input');
      const isEraseButton = e.detail.isEraseButton;
      /* temporarily changing the type of input to text to allow the appearance of dot */
      let buttonValue;
      if (!isEraseButton) {
        buttonValue = Number(e.detail.keyValueString.toString());
      }
      const buttonString = e.detail.keyValueString.toString();
      const valueString = this.displayValue ? this.displayValue.toString() : '';
      const isEitherNumberOrDecimalSeparator =
        (buttonString !== '' && buttonValue >= 0 && buttonValue <= 9) ||
        buttonString === '.' ||
        buttonString === ',';
      this.cursorPosition = inputBox.selectionStart;
      let tempDisplayValue;
      /* if button's value is either dot, comma or number, then insert value at cursor position */
      if (isEitherNumberOrDecimalSeparator) {
        tempDisplayValue =
          valueString.slice(0, this.cursorPosition) +
          buttonString +
          valueString.slice(this.cursorPosition);
        this.value = tempDisplayValue.toString().split(',').join('.');
        inputBox.style.caretColor = 'transparent';
        setTimeout(() => {
          if (this.cursorPosition) {
            inputBox.setSelectionRange(this.cursorPosition + 1, this.cursorPosition + 1);
            inputBox.style.caretColor = 'auto';
          }
        }, 0);
      }
      /* if button is erase button and cursor position is not at the beginning of the input value,
               then remove the previous character at the cursor position */
      if (isEraseButton && this.cursorPosition && this.cursorPosition !== 0) {
        tempDisplayValue =
          valueString.slice(0, this.cursorPosition - 1) + valueString.slice(this.cursorPosition);
        this.value = tempDisplayValue.toString().split(',').join('.');
        inputBox.style.caretColor = 'transparent';
        setTimeout(() => {
          if (this.cursorPosition) {
            inputBox.setSelectionRange(this.cursorPosition - 1, this.cursorPosition - 1);
            inputBox.style.caretColor = 'auto';
          }
        }, 0);
      }
      this.cursorPosition = inputBox.selectionStart;
      if (e.detail.changeFocus) {
        inputBox.focus();
      }
    });
  }
  closeKeypadIfTabbedOnAppropriateElement(e) {
    var _a, _b;
    const forwardTabbed = e.code === 'Tab' && !e.shiftKey;
    const backwardTabbed = e.code === 'Tab' && e.shiftKey;
    if (forwardTabbed) {
      const footer =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('[name="footer"]');
      if (footer === null) return;
      const footerNodes =
        footer === null || footer === void 0
          ? void 0
          : footer.assignedNodes({
              flatten: true,
            });
      const hasFooterNode = footerNodes.length !== 0;
      const forwardTabCheckingElement = this.determineForwardTabCheckingElement(
        hasFooterNode,
        footerNodes
      );
      this.closeKeypadIfForwardTabbedOnForwardTabElement(e, forwardTabCheckingElement);
    } else if (backwardTabbed) {
      const functionslot =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('[name="functions"]');
      if (functionslot === null) return;
      const functionNodes =
        functionslot === null || functionslot === void 0
          ? void 0
          : functionslot.assignedNodes({
              flatten: true,
            });
      const hasFunctionNodes = functionNodes.length !== 0;
      const backwardTabCheckingElement = this.determineBackwardTabCheckingElement(
        hasFunctionNodes,
        functionNodes
      );
      this.closeKeypadIfBackwardTabbedOnBackwardTabElement(e, backwardTabCheckingElement);
    } else {
      // invalid condition
    }
  }
  closeKeypadIfTabbed() {
    this.closeKeypad();
    this.finalCheck();
    this.checkMaxMin();
  }
  determineForwardTabCheckingElement(hasFooterNode, footerNodes) {
    if (hasFooterNode) {
      return footerNodes[footerNodes.length - 1];
    }
    return;
  }
  determineBackwardTabCheckingElement(hasFunctionNodes, functionNodes) {
    if (hasFunctionNodes) {
      return functionNodes[0];
    }
    return;
  }
  closeKeypadIfForwardTabbedOnForwardTabElement(e, forwardTabCheckingElement) {
    if (e.target === forwardTabCheckingElement) {
      this.closeKeypad();
      this.finalCheck();
      this.checkMaxMin();
    }
  }
  closeKeypadIfBackwardTabbedOnBackwardTabElement(e, backwardTabCheckingElement) {
    if (e.target === backwardTabCheckingElement) {
      this.closeKeypad();
      this.finalCheck();
      this.checkMaxMin();
    }
  }
  storeCursorPosition(inputBox) {
    this.cursorPosition = inputBox.selectionStart;
  }
  inputFocus(e) {
    // Open the keypad only if it is closed.
    // Do not try to open keypad if it is already opened.
    // Having this check enables us to avoid unnecessary function calls.
    // Not adding a seperate function here. But calling same code inline here again...
    // so that function calls can be avoided.
    if (!this.keypadActive) {
      this.openKeypadIfAppropriate();
    }
    e.target.onkeydown = (e) => {
      if (!this.inputDisabled) {
        this.addOrSubtractBasedOnKey(e);
      } else {
        e.preventDefault();
      }
    };
  }
  handleInputBlur(e) {
    var _a, _b;
    if (
      (this.value !== '' || (e.currentTarget && e.currentTarget.tagName == 'SH-INPUT-TEXT')) &&
      this.keypadActive
    ) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input')) ===
        null || _b === void 0
        ? void 0
        : _b.classList.remove('empty');
    }
  }
  openKeypadIfAppropriate() {
    if (this.keypad && !this.readonly && !this.disabled) {
      this.openKeypad();
    }
  }
  openKeypad() {
    this.keypadActive = true;
  }
  addOrSubtractBasedOnKey(e) {
    switch (e.code) {
      case 'ArrowUp':
        {
          e.preventDefault();
          if (this.disabled || this.readonly) {
            return;
          }
          this.handlePlus();
        }
        break;
      case 'ArrowDown':
        {
          e.preventDefault();
          if (this.disabled || this.readonly) {
            return;
          }
          this.handleMinus();
        }
        break;
      default: {
        // nothing here. Just adding for Sonar.
      }
    }
  }
  closeKeypadIfBackTabbed(e) {
    if (e.code === 'Tab' && e.shiftKey && e.target.id !== 'plus' && e.target.id !== 'minus') {
      this.finalCheck();
      this.checkMaxMin();
      this.closeKeypad();
    }
  }
  finalCheck() {
    var _a;
    const isInvalidValue = this.value === '' || isNaN(this.value);
    if (isInvalidValue) {
      const inpuText =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input');
      inpuText.value = null;
      this.value = '';
      //  else if it is a valid value, then
    } else {
      this.value = Number(parseFloat(this.value)).toString();
      // if it is a decimal number, round it to the number of decimal places.
      if (this.decimals !== '' && parseFloat(this.decimals) >= 0 && !isNaN(this.decimals)) {
        this.value = Number(this.value).toFixed(Number(this.decimals)).toString();
      }
    }
  }
  validateOnBlurOnNormalInput() {
    var _a;
    /**
     * Do the validations by executing finalCheck()
     * only if noValidate is false. This is to provide
     * an option to stop validations if the user wants
     * to be so.
     * From work-item: https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/25569/
     */
    if (!this.noValidate) {
      this.finalCheck();
    }
    if (!this.inputDisabled) {
      this.checkMaxMin();
    }
    if (!this.readonly) {
      const inpuText =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input');
      inpuText.readonly = false;
    }
  }
  closeInputKeyPad(e) {
    var _a, _b;
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input');
    const inputBox =
      (_b = inputText === null || inputText === void 0 ? void 0 : inputText.shadowRoot) === null ||
      _b === void 0
        ? void 0
        : _b.querySelector('input');
    const hasClickedOutsideKeypad = this.checkIfClickedOutsideKeypad(e);
    if (hasClickedOutsideKeypad) {
      this.validateOnBlurOnKeypadInput(inputBox);
      this.blur();
    }
    // removing blur call from here to avoid issues like cursor disappearing, etc.
    // https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/34267
  }
  validateOnBlurOnKeypadInput(inputBox) {
    this.closeKeypad();
    /**
     * Do the validations by executing finalCheck()
     * only if noValidate is false. This is to provide
     * an option to stop validations if the user wants
     * to be so.
     * From work-item: https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/25569/
     */
    this.checkMaxMin();
    if (!this.readonly) {
      inputBox.readonly = false;
    }
  }
  closeKeypad() {
    var _a, _b;
    this.keypadActive = false;
    if (this.value == '') {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input')) ===
        null || _b === void 0
        ? void 0
        : _b.classList.add('empty');
    }
  }
  checkMaxMin() {
    if (this.max !== undefined || this.min !== undefined) {
      const finalNumberOfDecimalPoints = this.getNumberOfPrecision();
      if (this.value) {
        this.value = Number(this.value).toFixed(finalNumberOfDecimalPoints);
      }
      if (this.value !== '' && Number(this.value) > Number(this.max)) {
        this.value =
          this.decimals == '' ? this.max : Number(this.max).toFixed(finalNumberOfDecimalPoints);
      }
      if (this.value !== '' && Number(this.value) < Number(this.min)) {
        this.value =
          this.decimals == '' ? this.min : Number(this.min).toFixed(finalNumberOfDecimalPoints);
      }
    }
  }
  checkIfClickedOutsideKeypad(e) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    let clickedOutside;
    const composedPathLength = e.composedPath().length;
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input');
    for (let i = 0; i < composedPathLength; i++) {
      const clickedKeypadWrapperOrInputBox =
        e.composedPath()[i] ===
          ((_b = this.shadowRoot) === null || _b === void 0
            ? void 0
            : _b.querySelector('sh-numeric-keypad')) ||
        e.composedPath()[i] ===
          ((_c = inputText === null || inputText === void 0 ? void 0 : inputText.shadowRoot) ===
            null || _c === void 0
            ? void 0
            : _c.querySelector('input')) ||
        e.composedPath()[i] ===
          ((_d = this.shadowRoot) === null || _d === void 0
            ? void 0
            : _d.querySelector('#clear')) ||
        e.composedPath()[i] === this;
      const clickedFunctionWrapperOrFooterWrapper =
        e.composedPath()[i] ===
          ((_e = this.shadowRoot) === null || _e === void 0
            ? void 0
            : _e.querySelector('.functions-wrapper')) ||
        e.composedPath()[i] ===
          ((_f = this.shadowRoot) === null || _f === void 0
            ? void 0
            : _f.querySelector('.footer-wrapper'));
      const clickedDivider =
        e.composedPath()[i] ===
        ((_g = this.shadowRoot) === null || _g === void 0 ? void 0 : _g.querySelector('.divider'));
      const clickedHost =
        e.composedPath()[i] ===
        ((_h = this.shadowRoot) === null || _h === void 0
          ? void 0
          : _h.querySelector('.host-wrapper'));
      const clickOnControl =
        clickedKeypadWrapperOrInputBox ||
        clickedFunctionWrapperOrFooterWrapper ||
        clickedDivider ||
        clickedHost;
      if (clickOnControl) {
        clickedOutside = false;
        break;
      } else {
        clickedOutside = true;
      }
    }
    return clickedOutside;
  }
  setBrowserLanguageBasedEuropeanNumberConventionProperty() {
    if (Number('120.94').toLocaleString() === '120,94') {
      this.europeanNumberConvention = true;
    } else if (Number('120.94').toLocaleString() === '120.94') {
      this.europeanNumberConvention = false;
    }
  }
  handleKeypadActive() {
    if (this.keypadActive) {
      if (this.inputDisabled) {
        document.removeEventListener('keydown', this.keyDownListener);
        this.keyDownListener = this.closeOnInactive.bind(this);
        document.addEventListener('keydown', this.keyDownListener);
      }
      document.body.addEventListener('click', this.closeListener);
      document.body.addEventListener('touchstart', this.closeListener);
      dispatchCustomEvent(this, 'keypad-opened', this);
      clearTimeout(this.rAFFrameControlDelay);
      cancelAnimationFrame(this.positioningFrame);
      this.positioningFrame = window.requestAnimationFrame(this.rAFCallBack);
    } else {
      document.removeEventListener('keydown', this.keyDownListener);
      document.body.removeEventListener('click', this.closeListener);
      document.body.removeEventListener('touchstart', this.closeListener);
      dispatchCustomEvent(this, 'keypad-closed', this);
      clearTimeout(this.rAFFrameControlDelay);
      cancelAnimationFrame(this.positioningFrame);
    }
  }
  closeOnInactive() {
    if (document.activeElement !== this) {
      this.closeKeypad();
    }
  }
  showKeypad() {
    this.rAFFrameControlDelay = setTimeout(() => {
      clearTimeout(this.rAFFrameControlDelay);
      this.handleOffset();
      if (this.keypadActive) {
        this.positioningFrame = window.requestAnimationFrame(this.rAFCallBack);
      } else {
        cancelAnimationFrame(this.positioningFrame);
      }
    }, 10);
  }
  handleOffset() {
    var _a;
    const menu =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('sh-numeric-keypad');
    const baseFontSize = parseFloat(
      window.getComputedStyle(document.querySelector('html'), null).getPropertyValue('font-size')
    );
    // position menu in X axis
    menu.style.left = this.offsetLeft / 16 + 'rem';
    // extracting the parent element here
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let elem = this;
    let transformPresent;
    let transformedParent;
    /**
     * Check for parent elements up the DOM tree from this dropdown
     * which have css transform property set.
     * If the css transform property is set to something, (translation), then
     * we store that parent element and raise a flag
     *
     * Later we use this flag and this stored parent element to correctly
     * determine the css top value of the internal popover
     */
    while (
      (elem === null || elem === void 0 ? void 0 : elem.parentNode) &&
      elem.parentNode.nodeName.toLowerCase() !== 'body'
    ) {
      if (getComputedStyle(elem.parentNode).transform !== 'none') {
        transformPresent = true;
        transformedParent = elem.parentNode;
        break;
      } else {
        transformPresent = false;
      }
      elem = elem.parentNode;
    }
    this.offsetY = this.getBoundingClientRect().top;
    /**
     * Variables used in this function and their intended meanings:
     *
     * 1. menu              - the popover
     *
     * 2. parentEl          - the parent element as mentioned above
     *
     * 3. this.offsetY      - the dropdown's distance from top of the page
     *                        in pixels got by getBoundingClientRect()
     *
     * 4. menu.clientHeight - the popover's height
     *
     * 5. this.offsetHeight - the dropdown input's height
     *
     * 6. hintHeight        - the height of the hint text
     *
     * 7. transformPresent  - flag to indicate presence of parent with css transform
     *                        property set.
     *
     * 8. transformParent   - the parent element with the css transform property set.
     *
     * If the bottom of the dropdown container which is measured as
     * (popover height (this.offsetHeight) + top of dropdown (this.offsetY) +
     *  dropdown-input height(menu.clientHeight)),
     * is greater than window inner height, then that means the dropdown container
     * goes beyond the window.
     * This means that the dropdown must drop-up.
     *
     * If the transform property is set on the parent...
     * then..
     * popover top must be 0 - the transformed parents top + dropdown's top - popover's height
     * (because top 0rem on the dropdown stays to the top of the transformed parent
     * and not at the top of the page. This is why we have to first set the popover to
     * be at the top of the page by [ 0 - transformed parents top ] which would be like
     * a reference point and then take the dropdown's top, dropdown's input height,
     * popover-height, hint-height, etc to correctly set the top value of the popover according
     * to whether it is drop-up or not).
     *
     * Else, go by the normal calculations.
     */
    /* check if there is not enough space below && enough space ontop */
    const rect = this.getBoundingClientRect();
    if (
      (this.offsetY + menu.clientHeight + this.offsetHeight > window.innerHeight &&
        this.offsetY > menu.clientHeight) ||
      this.isDropUp
    ) {
      menu.style.left = rect.left / baseFontSize + 'rem';
      /* position the keypad ontop */
      if (transformPresent) {
        menu.style.top =
          (0 -
            (transformedParent === null || transformedParent === void 0
              ? void 0
              : transformedParent.getBoundingClientRect().top) +
            this.offsetY -
            menu.clientHeight) /
            baseFontSize +
          'rem';
      } else {
        menu.style.top = (this.offsetY - menu.clientHeight) / baseFontSize + 'rem';
      }
      /* Adjust keypad position horizontal if position is outside of the window */
      if (rect.left + menu.offsetWidth + 16 > window.innerWidth) {
        const leftOffset = (window.innerWidth - menu.offsetWidth - 16) / baseFontSize + 'rem';
        menu.style.left = leftOffset;
      } else {
        if (menu.style.left) {
          menu.style.left = rect.left / baseFontSize + 'rem';
        }
      }
      /* check if enough space below */
    } else if (this.offsetY + menu.clientHeight + this.offsetHeight < window.innerHeight) {
      menu.style.left = rect.left / baseFontSize + 'rem';
      /* position the keypad below */
      if (transformPresent) {
        menu.style.top =
          (0 - transformedParent.getBoundingClientRect().top + this.offsetY + this.offsetHeight) /
            baseFontSize +
          'rem';
      } else {
        menu.style.top = (this.offsetY + this.offsetHeight) / baseFontSize + 'rem';
      }
      /* Adjust keypad position horizontal if position is outside of the window */
      if (rect.left + menu.offsetWidth + 16 > window.innerWidth) {
        const leftOffset = (window.innerWidth - menu.offsetWidth - 16) / baseFontSize + 'rem';
        menu.style.left = leftOffset;
      } else {
        if (menu.style.left) {
          menu.style.left = rect.left / baseFontSize + 'rem';
        }
      }
    } else {
      const rect = this.getBoundingClientRect();
      /* check if left is enough space */
      if (rect.left > menu.clientWidth) {
        /* position the keypad left */
        menu.style.left = (rect.left - menu.clientWidth) / baseFontSize + 'rem';
        menu.style.top = (window.innerHeight - menu.clientHeight - 16) / baseFontSize + 'rem';
      } else {
        /* position keypad right */
        if (rect.left + this.offsetWidth + menu.offsetWidth + 16 < window.innerWidth) {
          const leftOffset = (rect.left + this.offsetWidth) / baseFontSize + 'rem';
          menu.style.left = leftOffset;
          menu.style.top = (window.innerHeight - menu.clientHeight - 16) / baseFontSize + 'rem';
        } else {
          /* if not enough space on the right keep keypad in the view */
          const leftOffset = (window.innerWidth - menu.offsetWidth - 16) / baseFontSize + 'rem';
          menu.style.left = leftOffset;
        }
      }
    }
  }
  keypadObserver() {
    if (this.keypad) {
      this.inputMode = 'none';
    } else {
      this.inputMode = 'numeric';
    }
  }
  handleInputDisabledChange() {
    document.removeEventListener('keydown', this.keyDownListener);
    if (this.inputDisabled && this.keypadActive) {
      this.keyDownListener = this.closeOnInactive.bind(this);
      document.addEventListener('keydown', this.keyDownListener);
    }
  }
  decimalsObserver() {
    var _a;
    const decimalsInNumber = Number(this.decimals);
    /* this below assignment to step of input is to prevent HTML from showing warning
           tooltip */
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input');
    inputText.step = Math.pow(10, -decimalsInNumber);
  }
  displayValueChange() {
    var _a;
    const newValueIsNotEmpty = this.displayValue ? true : false;
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input');
    if (this.displayValue === '') {
      inputText.value = this.displayValue;
    }
    if (newValueIsNotEmpty) {
      inputText.value = this.displayValue;
    }
  }
  restrictOnlyNumberKeys(e) {
    const isValidInput = this.checkIfValidInput(e);
    if (isValidInput && !this.inputDisabled) {
      let numberSystem;
      if (this.europeanNumberConvention === undefined || this.europeanNumberConvention === null) {
        numberSystem = decimalSeparator === ',' ? 'European' : 'English';
      } else {
        numberSystem = this.europeanNumberConvention ? 'European' : 'English';
      }
      // preventing commas and dots in respective number conventions inorder to force allow
      // only one separator to be present at a time.
      if (numberSystem === 'English' && e.key === ',') {
        e.preventDefault();
      }
      if (numberSystem === 'European' && e.key === '.') {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }
  checkIfValidInput(e) {
    let isValidInput = false;
    const eventKey = e.key;
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'e',
      'Shift',
      'Enter',
      'Tab',
      'Backspace',
      'Delete',
      'Home',
      'End',
      'ArrowUp',
      'ArrowDown',
      'ArrowRight',
      'ArrowLeft',
      '-',
      '+',
      ',',
      '.',
      'Space',
    ];
    const numberOfAllowedKeys = allowedKeys.length;
    for (let i = 0; i < numberOfAllowedKeys; i++) {
      if (eventKey === allowedKeys[i]) {
        isValidInput = true;
        break;
      } else if (e.ctrlKey && KEYCODE.A) {
        isValidInput = true;
        break;
      } else if (
        (e.metaKey || e.ctrlKey) &&
        [
          KEYCODE.V.code,
          KEYCODE.C.code,
          KEYCODE.A.code,
          KEYCODE.X.code,
          KEYCODE.Z.code,
          KEYCODE.Y.code,
        ].indexOf(e.code) !== -1
      ) {
        isValidInput = true;
        break;
      }
    }
    return isValidInput;
  }
  updateDisplayValue() {
    this.value = this.value ? this.value.toString().split(',').join('.') : '';
  }
  clearField() {
    this.value = '';
  }
  startMinusCounter(e) {
    var _a, _b;
    (_b =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input')) ===
      null || _b === void 0
      ? void 0
      : _b.classList.remove('empty');
    if (e.touches) {
      this.skipMouseMove = true;
    }
    e.target.style.transition = '0s all linear';
    this.releaseMinusCounter();
    this.releasePlusCounter();
    this.minusClock = setInterval(this.handleMinusOnMouseDown.bind(this), 120, this);
  }
  startPlusCounter(e) {
    var _a, _b;
    (_b =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input')) ===
      null || _b === void 0
      ? void 0
      : _b.classList.remove('empty');
    if (e.touches) {
      this.skipMouseMove = true;
    }
    e.target.style.transition = '0s all linear';
    this.releasePlusCounter();
    this.releaseMinusCounter();
    this.plusClock = setInterval(this.handlePlusOnMouseDown.bind(this), 120, this);
  }
  releasePlusCounter(e = null) {
    // the below check for mousemove was to prevent mousemove from clearing the timer for repeated addition/subtraction in touch scenarios
    // in some touch scenarios, the mousemove was coming after touchstart and before touchend. This led to clearing of timer
    // before release of touch thus leading to stopping of repeated addition after a few seconds, even if the element was still
    // under touch. (The releasePlusCounter/releaseMinusCounter was called by the mousemove event handler attached to the document body)
    // So to prevent this scenario we set a flag when we detect mousemove.
    // If touch is also detected and this flag is also set, then that means we got an erroneous condition as mentioned above.
    // In that case, we reset the flag and return to prevent further execution.
    // Else we proceed to do the addition/subtraction
    // To prevent additional complexities, it is better to handle click event actions in
    // mouseup/touchend itself instead of adding a seperate click handler (applicable for cases where an explicit mousup/ touchend event handler
    // is added)
    // Also event.preventDefault() has to be called after touchend actions are done, to prevent the subsequent mouse event handlers from executing.
    if (e && e.type === 'keypress' && e.code !== 'Enter' && e.code !== 'Space') {
      return;
    }
    if (e && e.type === 'mousemove' && this.skipMouseMove) {
      this.skipMouseMove = false;
      return;
    }
    if (this.plusIcon) {
      clearInterval(this.plusClock);
    }
    if (this.minusIcon) {
      clearInterval(this.minusClock);
    }
    clearInterval(this.plusClock);
    if (e && e.type !== 'mousemove') {
      this.handlePlus();
    }
    if (e && e.touches) {
      e.preventDefault();
    }
  }
  releaseMinusCounter(e = null) {
    // the below check for mousemove was to prevent mousemove from clearing the timer for repeated addition/subtraction in touch scenarios
    // in some touch scenarios, the mousemove was coming after touchstart and before touchend. This led to clearing of timer
    // before release of touch thus leading to stopping of repeated addition after a few seconds, even if the element was still
    // under touch. (The releasePlusCounter/releaseMinusCounter was called by the mousemove event handler attached to the document body)
    // So to prevent this scenario we set a flag when we detect mousemove.
    // If touch is also detected and this flag is also set, then that means we got an erroneous condition as mentioned above.
    // In that case, we reset the flag and return to prevent further execution.
    // Else we proceed to do the addition/subtraction
    // To prevent additional complexities, it is better to handle click event actions in
    // mouseup/touchend itself instead of adding a seperate click handler (applicable for cases where an explicit mousup/ touchend event handler
    // is added)
    // Also event.preventDefault() has to be called after touchend actions are done, to prevent the subsequent mouse event handlers from executing.
    if (e && e.type === 'keypress' && e.code !== 'Enter' && e.code !== 'Space') {
      return;
    }
    if (e && e.type === 'mousemove' && this.skipMouseMove) {
      this.skipMouseMove = false;
      return;
    }
    if (this.plusIcon) {
      clearInterval(this.plusClock);
    }
    if (this.minusIcon) {
      clearInterval(this.minusClock);
    }
    clearInterval(this.minusClock);
    if (e && e.type !== 'mousemove') {
      this.handleMinus();
    }
    if (e && e.touches) {
      e.preventDefault();
    }
  }
  handlePlusOnMouseDown() {
    /**
     * This function is triggered when the right facing icon
     * is pressed. This function adds the value to step after
     * necessary calculations and emits an event 'plus pressed'
     *
     * 1. If value is null or undefined, it is set to ''.
     * 2. If step is null or undefined, it is set to '1'.
     * 3. Get required number of decimal places in the value.
     * 4. Convert decimal separators in step and value to dots.
     * 5. Add the numerical values of step and value.
     * 6. Replace all dots in the value and step
     *    back to decimal separators.
     * 7. Check whether value is within max and min values.
     * 8. Either save this value as stable value (if this value is
     *    a valid value) or renew the stable value as the required
     *    value.
     */
    if (!this.disabled) {
      if (this.value === null || this.value === undefined) {
        this.value = '';
      }
      if (this.step === null || this.step === undefined) {
        this.step = 1;
      }
      const finalNumberOfDecimalPoints = this.getNumberOfPrecision();
      this.value = Number(this.step) + Number(this.value);
      this.value = Number(this.value).toFixed(finalNumberOfDecimalPoints);
      this.checkMaxMin();
      this.renewStableValue(this.value);
      this.minusIcon = false;
      this.plusIcon = true;
      // fire additional custom event
      dispatchCustomEvent(this, 'plusPressed', self);
      /* mouseDownAddition is a flag to indicate that mouse down addition has happened. This flag
               is checked inside the handlePlus block to prevent subsequent addition due to click  */
      this.mouseDownAddition = true;
    } else {
      this.releasePlusCounter();
    }
  }
  getNumberOfPrecision() {
    /**
     *  Function to get the required number of decimal
     *  places in the result, by either taking the maximum
     *  number of decimal places in the step and value, or
     *  by taking the value specified in the decimals property
     *  (if specified).
     *
     * 1. Replace all decimal separators with dots of both
     *    step and copy of value.
     * 2. Extracts the number of decimal places in step &
     *    value.
     * 3. Compares them and the maximum number is extracted
     *    and placed as the required number of decimal places
     *    in the result.
     * 4. If the component already has the property 'decimals',
     *    then, that property is set as the required number of
     *    the decimal places in the result regardless of the
     *    above calculation.
     * 5. If not, the number of decimal places calculated will
     *    be set as the required number of decimal places.
     */
    const valueInString = this.value ? this.value.toString() : '';
    let valueHasDecimalPoints = false;
    let stepHasDecimalPoints = false;
    let numberOfDecimalPointsOfValue = 0;
    let numberOfDecimalPointsOfStep = 0;
    let finalNumberOfDecimalPoints = 0;
    if (this.step !== Math.round(this.step)) {
      stepHasDecimalPoints = true;
      numberOfDecimalPointsOfStep = ('' + this.step).split('.')[1].length;
    }
    if (valueInString.includes('.')) {
      valueHasDecimalPoints = true;
      numberOfDecimalPointsOfValue = valueInString.split('.')[1].length;
    }
    if (valueHasDecimalPoints || stepHasDecimalPoints) {
      if (numberOfDecimalPointsOfStep > numberOfDecimalPointsOfValue) {
        finalNumberOfDecimalPoints = numberOfDecimalPointsOfStep;
      } else {
        finalNumberOfDecimalPoints = numberOfDecimalPointsOfValue;
      }
    }
    return this.getNumberOfDecimalPoints(finalNumberOfDecimalPoints);
  }
  getNumberOfDecimalPoints(finalNumberOfDecimalPoints) {
    var _a, _b, _c;
    const decimalPoints = this.decimals;
    const numOfDecimalPoints = Number(decimalPoints);
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#input');
    if (decimalPoints !== '' && parseFloat(decimalPoints) >= 0 && !isNaN(decimalPoints)) {
      if ((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#input')) {
        inputText.step = Math.pow(10, -numOfDecimalPoints);
      }
      return numOfDecimalPoints;
    } else {
      if ((_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#input')) {
        inputText.step = Math.pow(10, -finalNumberOfDecimalPoints);
      }
      return finalNumberOfDecimalPoints;
    }
  }
  handleMinusOnMouseDown() {
    /**
     * This function is triggered when the left facing icon
     * is pressed. This function subtracts the step from value
     * after necessary calculations and emits an event 'plus pressed'.
     *
     * 1. If value is null or undefined, it is set to '0'.
     * 2. If step is null or undefined, it is set to '1'.
     * 3. Get required number of decimal places in the value.
     * 4. Convert decimal separators in step and value to dots.
     * 5. Add the numerical values of step and value.
     * 6. Replace all dots in the value and step back to decimal
     *    separators.
     * 7. Check whether value is within max and min values.
     * 8. Either save this value as stable value (if this value is
     *    a valid value) or renew the stable value as the required
     *    value.
     */
    if (!this.disabled) {
      if (this.value === null || this.value === undefined) {
        this.value = '';
      }
      if (this.step === null || this.step === undefined) {
        this.step = 1;
      }
      const finalNumberOfDecimalPoints = this.getNumberOfPrecision();
      this.value = Number(this.value) - Number(this.step);
      this.value = Number(this.value).toFixed(finalNumberOfDecimalPoints);
      if (this.value === '-0') {
        this.value = 0;
      }
      this.checkMaxMin();
      this.renewStableValue(this.value);
      this.plusIcon = false;
      this.minusIcon = true;
      // fire additional custom event
      dispatchCustomEvent(this, 'minusPressed', self);
      /* mouseDownSubtraction is a flag to indicate that mouse down subtraction has happened. This flag
               is checked inside the handleMinus block to prevent subsequent subtraction due to click  */
      this.mouseDownSubtraction = true;
    } else {
      this.releaseMinusCounter();
    }
  }
  handlePlus() {
    /**
     * This function is triggered when the right facing icon
     * is pressed. This function adds the value to step after
     * necessary calculations and emits an event 'plus pressed'
     *
     * 1. If value is null or undefined, it is set to ''.
     * 2. If step is null or undefined, it is set to '1'.
     * 3. Get required number of decimal places in the value.
     * 4. Convert decimal separators in step and value to dots.
     * 5. Add the numerical values of step and value.
     * 6. Replace all dots in the value and step
     *    back to decimal separators.
     * 7. Check whether value is within max and min values.
     * 8. Either save this value as stable value (if this value is
     *    a valid value) or renew the stable value as the required
     *    value.
     */
    /* the below if-check is to prevent double addition due to both mousedown and click
           firing simultaneously.  */
    if (!this.disabled) {
      if (this.mouseDownAddition) {
        this.mouseDownAddition = false;
        return;
      }
      if (this.value === null || this.value === undefined) {
        this.value = '';
      }
      if (this.step === null || this.step === undefined) {
        this.step = 1;
      }
      const finalNumberOfDecimalPoints = this.getNumberOfPrecision();
      this.value = Number(this.step) + Number(this.value);
      this.value = Number(this.value).toFixed(finalNumberOfDecimalPoints);
      this.checkMaxMin();
      this.renewStableValue(this.value);
      // fire additional custom event
      /** @ignore */
      dispatchCustomEvent(this, 'plusPressed', this);
    }
    this.releaseMinusCounter();
    this.releasePlusCounter();
  }
  handleMinus() {
    /**
     * This function is triggered when the left facing icon
     * is pressed. This function subtracts the step from value
     * after necessary calculations and emits an event 'plus pressed'.
     *
     * 1. If value is null or undefined, it is set to '0'.
     * 2. If step is null or undefined, it is set to '1'.
     * 3. Get required number of decimal places in the value.
     * 4. Convert decimal separators in step and value to dots.
     * 5. Add the numerical values of step and value.
     * 6. Replace all dots in the value and step back to decimal
     *    separators.
     * 7. Check whether value is within max and min values.
     * 8. Either save this value as stable value (if this value is
     *    a valid value) or renew the stable value as the required
     *    value.
     */
    /* the below if-check is to prevent double subtraction due to both mousedown and click
           firing simultaneously.  */
    if (!this.disabled) {
      if (this.mouseDownSubtraction) {
        this.mouseDownSubtraction = false;
        return;
      }
      if (this.value === null || this.value === undefined) {
        this.value = '';
      }
      if (this.step === null || this.step === undefined) {
        this.step = 1;
      }
      const finalNumberOfDecimalPoints = this.getNumberOfPrecision();
      this.value = Number(this.value) - Number(this.step);
      this.value = Number(this.value).toFixed(finalNumberOfDecimalPoints);
      if (this.value === '-0') {
        this.value = 0;
      }
      this.checkMaxMin();
      this.renewStableValue(this.value);
      // fire additional custom event
      /** @ignore */
      dispatchCustomEvent(this, 'minusPressed', this);
    }
    this.releaseMinusCounter();
    this.releasePlusCounter();
  }
  renewStableValue(value) {
    const tempValue = Number(value);
    /**
     *  Below if-check is to save the value as a stable value.
     *  If the host contains min and max properties, then save the
     *  current value as stable value only if it satisfies :
     *  Number(value) >= Number(min) and Number(value) <= Number(max)
     *
     *  If the host does not contain min and max properties,
     *  then the current value is by default a stable value.
     **/
    if (this.min && this.max) {
      if (Number(this.value) >= Number(this.min) && Number(this.value) <= Number(this.max)) {
        this.stableValue = tempValue;
      }
    } else {
      this.stableValue = tempValue;
    }
  }
  europeanNumberConventionObserver() {
    const replaceSeparator = this.europeanNumberConvention ? '.' : ',';
    this.decimalSeparator = this.europeanNumberConvention ? ',' : '.';
    if (this.displayValue) {
      this.displayValue = this.displayValue
        .toString()
        .split(replaceSeparator)
        .join(this.decimalSeparator);
      this.displayValueChange();
    }
  }
  disconnectedCallback() {
    if (this.rAFFrameControlDelay) {
      clearTimeout(this.rAFFrameControlDelay);
    }
    if (this.positioningFrame) {
      cancelAnimationFrame(this.positioningFrame);
    }
    if (this.keyDownListener) {
      document.removeEventListener('keydown', this.keyDownListener);
    }
    document.body.removeEventListener('click', this.closeListener);
    document.body.removeEventListener('mousemove', this.boundMove);
    document.body.removeEventListener('click', this.closeListener);
    super.disconnectedCallback();
  }
  focus() {
    var _a;
    const input =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('sh-input-text');
    input === null || input === void 0 ? void 0 : input.focus();
  }
};
__decorate([property({ type: String, reflect: true })], SHInputNumber.prototype, 'label', void 0);
__decorate([property({ type: Number, reflect: true })], SHInputNumber.prototype, 'value', void 0);
__decorate([property({ type: Number, reflect: true })], SHInputNumber.prototype, 'step', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputNumber.prototype,
  'readonly',
  void 0
);
__decorate([property({ type: Number, reflect: true })], SHInputNumber.prototype, 'min', void 0);
__decorate([property({ type: Number, reflect: true })], SHInputNumber.prototype, 'max', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputNumber.prototype,
  'mandatory',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHInputNumber.prototype, 'error', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputNumber.prototype,
  'success',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHInputNumber.prototype, 'safety', void 0);
__decorate([property({ type: String, reflect: true })], SHInputNumber.prototype, 'hint', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputNumber.prototype,
  'condensed',
  void 0
);
__decorate(
  [property({ type: Number, reflect: true })],
  SHInputNumber.prototype,
  'decimals',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHInputNumber.prototype, 'keypad', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputNumber.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'error-message' })],
  SHInputNumber.prototype,
  'errorMessage',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-clear' })],
  SHInputNumber.prototype,
  'noClear',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-arrows' })],
  SHInputNumber.prototype,
  'noArrows',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'input-disabled' })],
  SHInputNumber.prototype,
  'inputDisabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'keypad-active' })],
  SHInputNumber.prototype,
  'keypadActive',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'keypad-disabled' })],
  SHInputNumber.prototype,
  'keypadDisabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-border' })],
  SHInputNumber.prototype,
  'noBorder',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-validate' })],
  SHInputNumber.prototype,
  'noValidate',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'european-number-convention' })],
  SHInputNumber.prototype,
  'europeanNumberConvention',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'math-operators' })],
  SHInputNumber.prototype,
  'mathOperators',
  void 0
);
__decorate([property({ type: String })], SHInputNumber.prototype, 'displayValue', void 0);
__decorate([property({ type: String })], SHInputNumber.prototype, 'decimalSeparator', void 0);
__decorate([property({ type: String })], SHInputNumber.prototype, 'inputMode', void 0);
__decorate([property({ type: Number })], SHInputNumber.prototype, 'cursorPosition', void 0);
SHInputNumber = __decorate([customElement('sh-input-number')], SHInputNumber);
export { SHInputNumber };
