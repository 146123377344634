// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-explicit-any */
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { sharedStyles } from '../../styles';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import searchStyles from './sh-search.lit.scss.js';
export const searchListOfProperties = ['label', 'disabled', 'value', 'multiline', 'fields'];
/**
 * @attr {Array} search-array - Defines the array of items that will appear to the user as suggestions as she/he starts typing. This array can be linked to a data set through data-binding. The way to do the data-binding will depend on your application's JS framework (e.g. Angular, React, Vue, ...). When multiline is enabled search-array to be given as array of objects (e.g.[{name:'jack',email:'jack@email.com'},{name:'john',email:'john@email.com'}]) and fields property can be set.
 * @attr {Boolean} whole-string - Default false. If set to true, search will be enabled for whole string instead of just first character in the search array.
 * @attr {Boolean} no-clear - If set, clear icon will not be displayed.
 * @attr {Boolean} icon-search-event - If set, `search` event will be dispatched on search icon click only.
 * @slot empty - Takes Empty State as child. Will be displayed if the search suggestions are empty (value doesn't match any item in the search-array).
 * @fires search - Dispatched when clicking on the search icon.
 * @fires noresult - Dispatched when no matching result found.
 * @fires clearsearch - Dispatched when clicking on clear icon.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires disabled-changed {disabledPropertyChangedEvent} - *hide emitted when the disabled property changes.
 * @fires value-changed {ValuePropertyChangedEvent} - *hide emitted when the value property changes.
 * @fires multiline-changed {MultilinePropertyChangedEvent} - *hide emitted when the multiline property changes.
 * @fires fields-changed {FieldsPropertyChangedEvent} - *hide emitted when the fields property changes.
 */
let SHSearch = class SHSearch extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** Defines the placeholder text for the search box. */
    this.label = 'Search';
    /** Defines the array of items that will appear to the user as suggestions as she/he starts typing. This array can be linked to a data set through data-binding. The way to do the data-binding will depend on your application's JS framework (e.g. Angular, React, Vue, ...). When multiline is enabled search-array to be given as array of objects (e.g.[{name:'jack',email:'jack@email.com'},{name:'john',email:'john@email.com'}]) and fields property can be set. */
    this.searchArray = [];
    /** Defines key names for which autocomplete will appear e.g. ['name','email']. If not specified first two keys will be taken. */
    this.fields = [];
    /** Default false. If set to `true`, search will be enabled for whole string instead of just first character in the search array. */
    this.wholeString = false;
    /** Initial value of search field. */
    this.value = '';
    /** If set, `search` event will be dispatched on search icon click only. */
    this.iconSearchEvent = false;
    /** If set to `true`, the bottom border will not be displayed. */
    this.noBorder = false;
    /**If set to true, displays the search field with fully rounded corners. Rounded style does not support condensed mode. */
    this.rounded = false;
    /** @ignore */
    this.currentFocus = -1;
  }
  static get styles() {
    return [sharedStyles, searchStyles];
  }
  render() {
    return html`
      <input
        type="text"
        .value="${this.value}"
        maxlength="${ifDefined(this.maxlength)}"
        @input="${(e) => this.onInput(e)}"
        @keyup="${(e) => this.changeInput(this.value, e)}"
        @keydown="${(e) => this.keyEvents(e)}"
        id="searchBox"
        ?disabled="${this.disabled}"
        placeholder="${this.label}"
      />
      <sh-icon
        size="s"
        icon="search"
        ?disabled="${this.disabled}"
        button
        @click="${() => this.search()}"
      ></sh-icon>
      ${this.value && !this.disabled && !this.noClear
        ? html`
            <sh-icon
              icon="cancel"
              size="s"
              button
              class="closeIcon"
              id="close"
              ?disabled="${this.disabled}"
              @click="${() => this.clearInput()}"
            ></sh-icon>
          `
        : ''}
      <sh-overlay
        id="showSearch"
        class="showSearch"
        style="width:${getComputedStyle(this).width};"
        scroll-action="cancel"
        @focus="${(e) => this.overlayFocusHandler(e)}"
        no-overlap
        horizontal-align="left"
        no-cancel-on-outside-click
      >
        ${!this.multiline
          ? html`
              ${this.searchArray.map(
                (item) => html`
                  ${this.computeSingleFilter(item)
                    ? html`
                        <div
                          @click="${(e) => this.selectItem(e)}"
                          class="itemList"
                          data-item="${item}"
                        >
                          <span class="name" data-item="${item}">${item}</span>
                        </div>
                      `
                    : ''}
                `
              )}
            `
          : ''}
        ${this.multiline
          ? html`
              ${this.searchArray.map(
                (item) => html`
                  ${this.computeFilter(item)
                    ? html`
                        <div
                          @click="${(e) => {
                            this.selectItem(e);
                          }}"
                          class="itemList"
                          data-item="${item[this.fields[0]]}"
                        >
                          <div class="firstItem" data-item="${item[this.fields[0]]}">
                            <span class="name" data-item="${item[this.fields[0]]}"
                              >${item[this.fields[0]]}</span
                            >
                          </div>
                          <div
                            class="secondItem"
                            data-item="${item[this.fields[0]]}"
                            data-second-item="${item[this.fields[1]]}"
                          >
                            <span class="name" data-item="${item[this.fields[0]]}"
                              >${item[this.fields[1]]}</span
                            >
                          </div>
                        </div>
                      `
                    : ''}
                `
              )}
            `
          : ''}
      </sh-overlay>
      ${this.emptyResult && this.children.length > 0
        ? html`
            <sh-overlay
              opened
              class="empty-wrapper"
              style="width:${getComputedStyle(this).width}"
              scroll-action="refit"
              no-overlap
              horizontal-align="left"
              no-cancel-on-outside-click
            >
              <slot name="empty"></slot>
            </sh-overlay>
          `
        : ''}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    /** @ignore */
    this.clickListener = this.closeSearch.bind(this);
    document.body.addEventListener('click', this.clickListener);
    if (this.multiline && this.fields.length === 0 && this.searchArray.length > 0) {
      this.fields[0] = Object.keys(this.searchArray[0])[0];
      this.fields[1] = Object.keys(this.searchArray[0])[1];
    }
  }
  firstUpdated() {
    var _a, _b, _c, _d, _e;
    this.overlay =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#showSearch');
    (_c = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('input')) ===
      null || _c === void 0
      ? void 0
      : _c.addEventListener('focus', () => {
          if (this.value) {
            this.overlay.open();
            if (this.overlay.querySelectorAll('.itemList').length === 0) {
              this.emptyResult = true;
            }
          } else {
            this.overlay.close();
            this.emptyResult = false;
          }
        });
    (_e = (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('input')) ===
      null || _e === void 0
      ? void 0
      : _e.addEventListener('keydown', (event) => {
          if (event.key === 'Enter' && !this.iconSearchEvent) {
            this.search();
          }
        });
  }
  update(changedProperties) {
    if (changedProperties.has('value')) {
      this.updateComplete.then(() => {
        this.overlay.refit();
      });
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    dispatchAttributeChangedEventTypesafe(this, changedProperties, searchListOfProperties);
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.clickListener);
    super.disconnectedCallback();
  }
  closeSearch(e) {
    if (e.target === this) {
      return;
    } else {
      this.overlay.close();
      this.emptyResult = false;
    }
  }
  overlayFocusHandler(e) {
    var _a, _b;
    e.preventDefault();
    (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('input')) ===
      null || _b === void 0
      ? void 0
      : _b.focus();
  }
  onInput(e) {
    this.value = e.target.value;
  }
  changeInput(value, event) {
    var _a, _b, _c, _d;
    if (!(event.type === 'keyup' && event.code === 'Enter')) {
      if (this.value) {
        this.overlay.open();
      } else {
        this.overlay.close();
      }
      if (!this.multiline && this.value) {
        if (
          this.shadowRoot &&
          ((_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelectorAll('#showSearch .itemList'))
        ) {
          const itemList =
            (_b = this.shadowRoot) === null || _b === void 0
              ? void 0
              : _b.querySelectorAll('#showSearch .itemList');
          for (let i = 0; i < itemList.length; i++) {
            const fullStr =
              (_d = (_c = itemList[i]) === null || _c === void 0 ? void 0 : _c.dataset) === null ||
              _d === void 0
                ? void 0
                : _d.item;
            itemList[i].innerHTML = this.searchHighlight(fullStr, value);
          }
        }
      } else {
        if (this.value) {
          this.multilineSearch();
        }
      }
      if (this.overlay.querySelectorAll('.itemList').length === 0) {
        if (this.value === '') {
          return;
        }
        this.noResultEvent.emit(new CustomEvent('noresult'));
        this.emptyResult = true;
      } else {
        this.emptyResult = false;
      }
    }
  }
  multilineSearch() {
    const firstItemList = this.overlay.querySelectorAll('.itemList .firstItem');
    const secondItemList = this.overlay.querySelectorAll('.itemList .secondItem');
    for (let i = 0; i < firstItemList.length; i++) {
      this.findSearchedStr(firstItemList[i], firstItemList[i].dataset.item);
    }
    for (let i = 0; i < secondItemList.length; i++) {
      this.findSearchedStr(secondItemList[i], secondItemList[i].dataset.secondItem);
    }
  }
  findSearchedStr(item, itemStr) {
    const fullStr = itemStr;
    if (!this.wholeString && fullStr.toLowerCase().indexOf(this.value.toLowerCase()) !== -1) {
      item.innerHTML = this.searchHighlight(fullStr, this.value);
    } else if (this.wholeString && fullStr.toLowerCase().indexOf(this.value.toLowerCase()) !== -1) {
      item.innerHTML = this.searchHighlight(fullStr, this.value);
    } else {
      item.innerHTML = `<span class="name">${fullStr}</span>`;
    }
  }
  searchHighlight(fullStr, value) {
    let replaceHtml;
    if (value) {
      const matchStart = fullStr.toLowerCase().indexOf(value.toLowerCase());
      const matchEnd = matchStart + value.length - 1;
      const beforeMatch = fullStr.slice(0, matchStart);
      const matchText = fullStr.slice(matchStart, matchEnd + 1);
      const afterMatch = fullStr.slice(matchEnd + 1);
      replaceHtml = `${beforeMatch}<span class="searchText">${matchText}</span>${afterMatch}`;
    }
    return replaceHtml;
  }
  computeSingleFilter(inputStr) {
    let searchedString;
    if (this.wholeString) {
      searchedString = inputStr.toLowerCase().indexOf(this.value.toLowerCase()) !== -1;
    } else {
      searchedString = inputStr.toLowerCase().indexOf(this.value.toLowerCase()) === 0;
    }
    return searchedString;
  }
  computeFilter(inputStr) {
    let searchedString;
    if (this.wholeString) {
      searchedString =
        inputStr[this.fields[0]].toLowerCase().indexOf(this.value.toLowerCase()) !== -1 ||
        inputStr[this.fields[1]].toLowerCase().indexOf(this.value.toLowerCase()) !== -1;
    } else {
      searchedString =
        inputStr[this.fields[0]].toLowerCase().indexOf(this.value.toLowerCase()) === 0 ||
        inputStr[this.fields[1]].toLowerCase().indexOf(this.value.toLowerCase()) === 0;
    }
    return searchedString;
  }
  clearInput() {
    this.value = '';
    this.emptyResult = false;
    this.clearSearchEvent.emit(new CustomEvent('clearsearch'));
    this.overlay.close();
  }
  selectItem(e) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    this.value = (_a = e.target.dataset) === null || _a === void 0 ? void 0 : _a.item;
    if (e.target.tagName === 'SPAN') {
      this.value =
        (_d =
          (_c = (_b = e.target) === null || _b === void 0 ? void 0 : _b.parentNode) === null ||
          _c === void 0
            ? void 0
            : _c.dataset) === null || _d === void 0
          ? void 0
          : _d.item;
    } else {
      this.value =
        (_f = (_e = e.target) === null || _e === void 0 ? void 0 : _e.dataset) === null ||
        _f === void 0
          ? void 0
          : _f.item;
    }
    if (this.shadowRoot && this.shadowRoot.querySelector('input')) {
      this.shadowRoot.querySelector('input').value = this.value;
    }
    this.changeInput(this.value, e);
    (_h = (_g = this.shadowRoot) === null || _g === void 0 ? void 0 : _g.querySelector('input')) ===
      null || _h === void 0
      ? void 0
      : _h.focus();
    this.overlay.close();
    this.clearActive();
    if (!this.iconSearchEvent) {
      this.search();
    }
  }
  clearActive() {
    const activeClass = this.overlay.getElementsByClassName('active');
    if (activeClass) {
      for (let i = 0; i < activeClass.length; i++) {
        activeClass[i].classList.remove('active');
      }
    }
    this.currentFocus = -1;
  }
  //keyboard events using up and down arrows to navigate between the search results
  keyEvents(e) {
    var _a;
    let itemlist;
    itemlist =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#showSearch');
    if (itemlist) {
      itemlist = itemlist.querySelectorAll('.itemList');
    }
    if (e.code === 'ArrowDown') {
      e.preventDefault();
      this.currentFocus++;
      this.addActive(itemlist);
    }
    if (e.code === 'ArrowUp') {
      e.preventDefault();
      this.currentFocus--;
      this.addActive(itemlist);
    }
    if (e.code === 'Enter') {
      e.preventDefault();
      if (itemlist) {
        if (this.currentFocus > -1 && itemlist.length > 0) {
          if (e.target.tagName === 'INPUT') {
            e.stopImmediatePropagation();
          }
          itemlist[this.currentFocus].click();
        }
      }
    }
  }
  addActive(itemlist) {
    if (!itemlist || this.emptyResult) {
      return;
    }
    this.removeActive(itemlist);
    if (this.currentFocus >= itemlist.length) {
      this.currentFocus = 0;
    }
    if (this.currentFocus < 0) {
      this.currentFocus = itemlist.length - 1;
    }
    itemlist[this.currentFocus].classList.add('active');
  }
  removeActive(itemlist) {
    for (let i = 0; i < itemlist.length; i++) {
      itemlist[i].classList.remove('active');
    }
  }
  search() {
    // fire event on searching
    this.searchEvent.emit(new CustomEvent('search'));
  }
  focus() {
    var _a;
    const input =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('input');
    input === null || input === void 0 ? void 0 : input.focus();
  }
};
__decorate([property({ type: String, reflect: true })], SHSearch.prototype, 'label', void 0);
__decorate(
  [property({ type: Array, reflect: true, attribute: 'search-array' })],
  SHSearch.prototype,
  'searchArray',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHSearch.prototype, 'multiline', void 0);
__decorate([property({ type: Array, reflect: true })], SHSearch.prototype, 'fields', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'whole-string' })],
  SHSearch.prototype,
  'wholeString',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHSearch.prototype, 'condensed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSearch.prototype, 'disabled', void 0);
__decorate([property({ type: String, reflect: true })], SHSearch.prototype, 'value', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-clear' })],
  SHSearch.prototype,
  'noClear',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'icon-search-event' })],
  SHSearch.prototype,
  'iconSearchEvent',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-border' })],
  SHSearch.prototype,
  'noBorder',
  void 0
);
__decorate([property({ type: Number, reflect: true })], SHSearch.prototype, 'maxlength', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSearch.prototype, 'rounded', void 0);
__decorate([property({ type: Boolean })], SHSearch.prototype, 'emptyResult', void 0);
__decorate([property({ type: Number })], SHSearch.prototype, 'currentFocus', void 0);
__decorate([event()], SHSearch.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHSearch.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHSearch.prototype, 'valueChangedEvent', void 0);
__decorate([event()], SHSearch.prototype, 'multilineChangedEvent', void 0);
__decorate([event()], SHSearch.prototype, 'fieldsChangedEvent', void 0);
__decorate([event()], SHSearch.prototype, 'noResultEvent', void 0);
__decorate([event()], SHSearch.prototype, 'clearSearchEvent', void 0);
__decorate([event()], SHSearch.prototype, 'searchEvent', void 0);
SHSearch = __decorate([customElement('sh-search')], SHSearch);
export { SHSearch };
