// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ShuiLitElement } from '../../utils/event-decorator.js';
import valueIndicatorStyles from './sh-value-indicator.lit.scss.js';
/**
 * @slot footer - Takes Icons and Text as children. (Optional)
 */
let SHValueIndicator = class SHValueIndicator extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**Default l. Defines the size of the value indicator. Available in two sizes - s and l. */
    this.size = 'l';
  }
  static get styles() {
    return [valueIndicatorStyles];
  }
  render() {
    return html`
      <div class="value-wrapper">
        ${this.icon
          ? html` <sh-icon icon="${this.icon}" class="icon" .color="${this.iconColor}"></sh-icon> `
          : ''}
        ${this.value ? html` <sh-text class="value">${this.value}</sh-text> ` : ''}
        ${this.unit ? html` <sh-text class="unit">${this.unit}</sh-text> ` : ''}
        ${this.status ? html` <sh-icon icon="${this.status}" class="status"></sh-icon> ` : ''}
      </div>
      ${this.label ? html` <sh-text class="label">${this.label}</sh-text> ` : ''}
      <slot name="footer"></slot>
    `;
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHValueIndicator.prototype,
  'label',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHValueIndicator.prototype,
  'value',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHValueIndicator.prototype, 'unit', void 0);
__decorate([property({ type: String, reflect: true })], SHValueIndicator.prototype, 'icon', void 0);
__decorate(
  [property({ type: String, reflect: true })],
  SHValueIndicator.prototype,
  'status',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHValueIndicator.prototype, 'size', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHValueIndicator.prototype,
  'iconColor',
  void 0
);
SHValueIndicator = __decorate([customElement('sh-value-indicator')], SHValueIndicator);
export { SHValueIndicator };
