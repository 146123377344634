// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { convertToRem } from '../../utils/pixel-to-rem-convertor';
import tableStyles from './sh-table.lit.scss.js';
/**
 * @slot Takes sh-table-row as children.
 * @slot header - Takes sh-table-row as children. Will be rendered in a fixed position at the top of the table (won't scroll with the content). Meant to be the table's header.
 * @fires scroll-end - Dispatched when the scroll bar hits the bottom of the table.
 * @fires middle-scrolled - Dispatched when either the horizontal scrollbar or any row in the scrollable block is scrolled, in a frozen table.
 * @fires slots-modified - Dispatched when any of the frozen table slots content is modified / added.
 */
let SHTable = class SHTable extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**Default 33.3%. Sets the max-width, width, min-width of the frozen-left block in a frozen table.*/
    this.lwidth = '33.3%';
    /**Default 33.3%. Sets the max-width, width, min-width of the scrollable block in a frozen table*/
    this.mwidth = '33.3%';
    /**Default 33.3%. Sets the max-width, width, min-width of the frozen-right block in a frozen table.*/
    this.rwidth = '33.3%';
    /**Returns an array of current selected rows. It is applicable only when `multiselect` is set.*/
    /** @ignore */
    this.selectedRows = [];
    this.issuedFrozenTableWarning = false;
    this.resizeListener = this.windowResizeListener.bind(this);
  }
  static get styles() {
    return [sharedStyles, tableStyles];
  }
  render() {
    return html`
      <div class="header-wrapper">
        <slot name="header" id="header-slot"></slot>
      </div>
      <div class="table-wrapper" @scroll="${() => this.contentScrolled()}" id="tableContent">
        <div class="content">
          <slot id="tableBodySlot"></slot>
        </div>
      </div>
      ${this.freeze
        ? html`
            <div class="floating-scrollbar" @scroll="${(e) => this.adjustScrollLeft(e)}">
              <div class="scrollThumb"></div>
            </div>
          `
        : ''}
    `;
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    if (name === 'lwidth' || name === 'rwidth' || name === 'mwidth') {
      this.widthObserver();
    } else if (name === 'readonly' || name === 'condensed') {
      this.slotObserver();
    }
  }
  update(changedProperties) {
    if (changedProperties.has('scrolledLeft')) {
      this.scrolledLeftChanged();
    }
    if (changedProperties.has('condensed')) {
      this.condensedObserver();
    }
    super.update(changedProperties);
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('clicked', () => {
      if (!this.multiselect) {
        const childElement = this.querySelectorAll('sh-table-row[active]');
        for (let i = 0; i < childElement.length; i++) {
          childElement[i].removeAttribute('active');
        }
      }
    });
    if (this.freeze) {
      window.addEventListener('resize', this.resizeListener);
    }
    this.resizeObserver = new ResizeObserver(() => {
      this.windowResizeListener();
    });
  }
  firstUpdated(changedProperties) {
    var _a, _b, _c, _d;
    super.firstUpdated(changedProperties);
    const bodySlot =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#tableBodySlot');
    bodySlot === null || bodySlot === void 0
      ? void 0
      : bodySlot.addEventListener('slotchange', () => this.slotObserver());
    const baseFontSize = parseFloat(
      window.getComputedStyle(document.querySelector('html'), null).getPropertyValue('font-size')
    );
    if (this.freeze) {
      const floatingScrollBar =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('.floating-scrollbar');
      const floatThumb =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('.scrollThumb');
      this.addEventListener('slots-modified', (e) => {
        var _a;
        floatThumb.style.width = `${e.detail.innerWidth / baseFontSize}rem`;
        floatingScrollBar.style.width = `${e.detail.width / baseFontSize}rem`;
        floatingScrollBar.style.left = `${e.detail.leftPosition / baseFontSize}rem`;
        if (this.scrolledLeft !== undefined) {
          floatingScrollBar.scrollLeft = this.scrolledLeft;
          const allChildTableRows = this.querySelectorAll('sh-table-row');
          for (let i = 0; i < allChildTableRows.length; i++) {
            if (allChildTableRows[i].freeze) {
              const element =
                (_a = allChildTableRows[i].shadowRoot) === null || _a === void 0
                  ? void 0
                  : _a.querySelector('.scrollable');
              if (element) {
                element.scrollLeft = this.scrollLeft;
              }
            }
          }
        }
        this.widthObserver();
      });
      this.addEventListener('touchstart', (e) => {
        if (!this.hasAllFrozenRows()) {
          return;
        }
        const touchMovePathElements = e.composedPath();
        // a flag to identify if touch actually swiped on the scrollable part
        let swipedOnScrollable = false;
        for (let i = 0; i < touchMovePathElements.length; i++) {
          // adding check for touchMovePathElements[i].tagName since sometimes it will throw error for document nodes that don't have tags
          if (
            touchMovePathElements[i].tagName &&
            touchMovePathElements[i].classList.contains('scrollable') &&
            touchMovePathElements[i].tagName === 'DIV'
          ) {
            swipedOnScrollable = true;
            break;
          }
        }
        if (swipedOnScrollable) {
          // updating touchStartPos at the beginning of every swipe event ...
          this.touchStartPos = e.changedTouches[0].pageX;
          this.touchstartedOnScrollable = true;
        } else {
          this.touchstartedOnScrollable = false;
        }
      });
      this.addEventListener('touchmove', (e) => {
        if (!this.hasAllFrozenRows()) {
          if (!this.issuedFrozenTableWarning) {
            console.warn(`All table rows in a frozen table must have freeze property.`);
            this.issuedFrozenTableWarning = true;
          }
          return;
        }
        const touchMovePathElements = e.composedPath();
        if (this.touchstartedOnScrollable) {
          // a flag to identify if touch actually swiped on the scrollable part
          let swipedOnScrollable = false;
          for (let i = 0; i < touchMovePathElements.length; i++) {
            // adding check for touchMovePathElements[i].tagName since sometimes it will throw error for document nodes that don't have tags
            if (
              touchMovePathElements[i].tagName &&
              touchMovePathElements[i].classList.contains('scrollable') &&
              touchMovePathElements[i].tagName === 'DIV'
            ) {
              swipedOnScrollable = true;
              break;
            }
          }
          // e.changedTouches[0].pageX - touchStartPos is meant to be the delta needed to either add or subtract from the scrollLeft.
          // simply doing += to the scrollLeft didn't work...
          // that's why using different checks which direction the pointer is moving...
          // and then either subtracting or adding the absolute value of the delta...
          // maybe additional improvements or altogether better idea could also be deviced in the future...
          if (swipedOnScrollable) {
            if (this.touchStartPos && e.changedTouches[0].pageX - this.touchStartPos < 0) {
              floatingScrollBar.scrollLeft += Math.abs(
                e.changedTouches[0].pageX - this.touchStartPos
              );
            } else {
              if (this.touchStartPos) {
                floatingScrollBar.scrollLeft -= Math.abs(
                  e.changedTouches[0].pageX - this.touchStartPos
                );
              }
            }
            // updating touchStartPos at the end of every touchmove ...
            this.touchStartPos = e.changedTouches[0].pageX;
          }
        }
      });
    }
    /**@ignore */
    this.issuedFrozenTableWarning = false;
    (_d = this === null || this === void 0 ? void 0 : this.resizeObserver) === null || _d === void 0
      ? void 0
      : _d.observe(this);
  }
  setHeaderPadding() {
    var _a, _b, _c;
    const scrollBarWidthInPx = this.getScrollBarWidth();
    const scrollBarWidthInRem =
      typeof scrollBarWidthInPx === 'number' ? convertToRem(scrollBarWidthInPx) : null;
    const headerWrapperIsPresent = Boolean(
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.header-wrapper')
    );
    if (headerWrapperIsPresent && typeof scrollBarWidthInRem === 'number') {
      (_c =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('.header-wrapper')) === null || _c === void 0
        ? void 0
        : _c.style.setProperty('--scrollbar-width', `${scrollBarWidthInRem}rem`);
    }
  }
  getScrollBarWidth() {
    var _a, _b, _c, _d, _e;
    const tableWrapperIsPresent = Boolean(
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.table-wrapper')
    );
    if (tableWrapperIsPresent) {
      const tableWrapperWidth =
        (_c =
          (_b = this.shadowRoot) === null || _b === void 0
            ? void 0
            : _b.querySelector('.table-wrapper')) === null || _c === void 0
          ? void 0
          : _c.getBoundingClientRect().width;
      const tableWrapperContentWidth =
        (_e =
          (_d = this.shadowRoot) === null || _d === void 0
            ? void 0
            : _d.querySelector('.table-wrapper .content')) === null || _e === void 0
          ? void 0
          : _e.getBoundingClientRect().width;
      if (tableWrapperWidth && tableWrapperContentWidth)
        return tableWrapperWidth - tableWrapperContentWidth;
    } else {
      return null;
    }
    return;
  }
  windowResizeListener() {
    clearTimeout(this.windowResizeTimer);
    this.windowResizeTimer = setTimeout(() => {
      var _a, _b, _c, _d;
      clearTimeout(this.windowResizeTimer);
      this.setHeaderPadding();
      this.forceStyleUpdate();
      if (this.freeze) {
        const floatingScrollBar =
          (_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('.floating-scrollbar');
        const floatThumb =
          (_b = this.shadowRoot) === null || _b === void 0
            ? void 0
            : _b.querySelector('.scrollThumb');
        // Selecting first row with freeze property as the sample
        // row to apply the scrollbar's width and internal thumb width
        const firstRowChild = this.querySelector('sh-table-row[freeze]');
        const baseFontSize = parseFloat(
          window
            .getComputedStyle(document.querySelector('html'), null)
            .getPropertyValue('font-size')
        );
        if (!firstRowChild) {
          return;
        }
        const frozenLeft =
          (_c = firstRowChild.shadowRoot) === null || _c === void 0
            ? void 0
            : _c.querySelector('.frozen-left');
        const scrollable =
          (_d = firstRowChild.shadowRoot) === null || _d === void 0
            ? void 0
            : _d.querySelector('.scrollable');
        if (floatingScrollBar) {
          floatingScrollBar.style.left = `${frozenLeft.getBoundingClientRect().width / baseFontSize}rem`;
          floatingScrollBar.style.width = `${scrollable.getBoundingClientRect().width / baseFontSize}rem`;
        }
        if (floatThumb) {
          floatThumb.style.width = `${scrollable.scrollWidth / baseFontSize}rem`;
        }
      }
    }, 200);
  }
  forceStyleUpdate() {
    getComputedStyle(document.documentElement);
  }
  widthObserver() {
    const allChildTableRows = this.querySelectorAll('sh-table-row');
    for (let i = 0; i < allChildTableRows.length; i++) {
      if (allChildTableRows[i].freeze) {
        allChildTableRows[i].lwidth = this.lwidth;
        allChildTableRows[i].mwidth = this.mwidth;
        allChildTableRows[i].rwidth = this.rwidth;
      }
    }
    this.windowResizeListener();
  }
  condensedObserver() {
    const allChildTableRows = this.querySelectorAll('sh-table-row');
    for (let i = 0; i < allChildTableRows.length; i++) {
      allChildTableRows[i].condensed = this.condensed;
    }
  }
  contentScrolled() {
    var _a;
    const content =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#tableContent');
    const contentScroll = content.scrollTop + content.clientHeight;
    const contentMaxScroll = content.scrollHeight;
    if (Math.ceil(contentScroll) >= contentMaxScroll) {
      this.scrollEndEvent.emit(
        new CustomEvent('scroll-end', {
          detail: { detail: this },
          composed: true,
          bubbles: true,
        })
      );
    }
  }
  slotObserver() {
    const allChildTableRows = this.querySelectorAll('sh-table-row');
    for (let i = 0; i < allChildTableRows.length; i++) {
      if (this.readonly) {
        allChildTableRows[i].readonly = this.readonly;
        allChildTableRows[i].setAttribute('tabindex', '-1');
        /* make all child elements not navigable*/
      } else {
        allChildTableRows[i].setAttribute('tabindex', '0');
        if (allChildTableRows[0].slot === 'header') {
          allChildTableRows[0].removeAttribute('tabindex');
          /*otherwise the the whole header row gets focus */
        }
      }
      /*Added to cover case in bundle consumption (property does not get set on child table rows / table cell elements)
            Hence below code is added to set the condensed property on all child table rows and table cells */
      if (this.condensed) {
        allChildTableRows[i].condensed = this.condensed;
        const allChildTableCells = allChildTableRows[i].children;
        for (let j = 0; j < allChildTableCells.length; j++) {
          allChildTableCells[j].condensed = this.condensed;
        }
      }
    }
    this.windowResizeListener();
  }
  adjustScrollLeft(e) {
    var _a;
    const allChildTableRows = this.querySelectorAll('sh-table-row');
    if (!this.hasAllFrozenRows()) {
      if (!this.issuedFrozenTableWarning) {
        console.warn(`All table rows in a frozen table must have freeze property.`);
        this.issuedFrozenTableWarning = true;
      }
      return;
    }
    const target = e.target;
    for (let i = 0; i < allChildTableRows.length; i++) {
      if (allChildTableRows[i].freeze) {
        const element =
          (_a = allChildTableRows[i].shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('.scrollable');
        if (element)
          element.scrollLeft = target === null || target === void 0 ? void 0 : target.scrollLeft;
      }
    }
    this.scrolledLeft = target === null || target === void 0 ? void 0 : target.scrollLeft;
  }
  scrolledLeftChanged() {
    var _a;
    const allChildTableRows = this.querySelectorAll('sh-table-row');
    if (!this.hasAllFrozenRows()) {
      return;
    }
    for (let i = 0; i < allChildTableRows.length; i++) {
      if (allChildTableRows[i].freeze) {
        allChildTableRows[i].scrollLeft = Number(this.scrolledLeft);
        const element =
          (_a = allChildTableRows[i].shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('.scrollable');
        if (element) {
          element.scrollLeft = Number(this.scrolledLeft);
        }
      }
    }
  }
  /**@ignore */
  hasAllFrozenRows() {
    /**
     * If any sh-table-row is present without freeze property, then all
     * rows are not frozen.
     * Else, all rows are frozen
     */
    const areAllRowsFrozen = this.querySelector('sh-table-row:not([freeze])') ? false : true;
    return areAllRowsFrozen;
  }
  disconnectedCallback() {
    var _a;
    if (this.freeze) {
      window.removeEventListener('resize', this.resizeListener);
    }
    (_a = this === null || this === void 0 ? void 0 : this.resizeObserver) === null || _a === void 0
      ? void 0
      : _a.disconnect();
    super.disconnectedCallback();
  }
};
__decorate([event()], SHTable.prototype, 'scrollEndEvent', void 0);
__decorate([event()], SHTable.prototype, 'middleScrolledEvent', void 0);
__decorate([event()], SHTable.prototype, 'slotsModifiedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHTable.prototype, 'lwidth', void 0);
__decorate([property({ type: String, reflect: true })], SHTable.prototype, 'mwidth', void 0);
__decorate([property({ type: String, reflect: true })], SHTable.prototype, 'rwidth', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTable.prototype, 'condensed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTable.prototype, 'freeze', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTable.prototype, 'readonly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTable.prototype, 'multiselect', void 0);
__decorate([property({ type: Array, reflect: false })], SHTable.prototype, 'selectedRows', void 0);
__decorate([property({ type: Number })], SHTable.prototype, 'scrolledLeft', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'selection-mode' })],
  SHTable.prototype,
  'selectionMode',
  void 0
);
SHTable = __decorate([customElement('sh-table')], SHTable);
export { SHTable };
