// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { numberkeyInteraction } from '../../utils/keyboardInteraction.js';
export const TimeInputValidatorMixin = (superClass) => {
    class TimeInputValidatorMixin extends superClass {
        onKeyupValidateTimeInput(event) {
            const target = event.target;
            if (numberkeyInteraction(event)) {
                //---- If the inputted value > maximum-value for that particular field, then make that field's value as 0 (Internal validation). ----
                this.validateInput(event);
            }
            else {
                target.value = this.isNonDigit(target.value) ? '00' : target.value;
            }
            setTimeout(() => {
                dispatchCustomEvent(this, 'time-value-changed');
            }, 120);
        }
        onKeyupValidateAmPm(e) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const timepickerInput = this;
            const target = e.target;
            let specialCharacterIsPressed = false;
            specialCharacterIsPressed =
                e.key === ' ' ||
                    e.key === 'Backspace' ||
                    e.key === 'Tab' ||
                    e.key === 'Up' ||
                    e.key === 'ArrowUp' ||
                    e.key === 'ArrowDown' ||
                    e.key === 'Down';
            if (!specialCharacterIsPressed) {
                timepickerInput.amPm = this.validateAmPmBasedOnInput(target.value);
                target.value = timepickerInput.amPm;
            }
            dispatchCustomEvent(this, 'time-value-changed');
        }
        validateTime() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const timepickerInput = this;
            this.validateTimeInputModified();
            timepickerInput.amPm = timepickerInput.amPm
                ? this.validateAmPmBasedOnInput(timepickerInput.amPm)
                : '';
        }
        getHourValueIfClockFormat24() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const timepickerInput = this;
            if (timepickerInput.clockFormat === '24-hour') {
                return '00';
            }
            else {
                return '01';
            }
        }
        validateInput(event) {
            var _a, _b, _c;
            const h = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#h-label');
            const target = event.target;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const timepickerInput = this;
            if (parseInt(target.value, 10) > Number(target.max)) {
                target.value = event.target == h ? this.getHourValueIfClockFormat24() : '00';
            }
            if (target.value == '00' && event.target == h && timepickerInput.clockFormat === '12-hour') {
                target.value = '01';
            }
            if (target.value.length === parseInt(target.maxLength.toString(), 10)) {
                if (timepickerInput.clockFormat === '24-hour' &&
                    ((_b = target.nextElementSibling) === null || _b === void 0 ? void 0 : _b.nextElementSibling) == null) {
                    if (timepickerInput.mini) {
                        timepickerInput.m = target.value;
                    }
                    else {
                        timepickerInput.s = target.value;
                    }
                }
                else {
                    const lastsibling = (_c = target.nextElementSibling) === null || _c === void 0 ? void 0 : _c.nextElementSibling;
                    setTimeout(() => {
                        lastsibling.focus();
                        lastsibling.setSelectionRange(0, 2);
                    }, 120);
                }
            }
        }
        validateTimeInputModified() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const timepickerInput = this;
            if (timepickerInput.h && this.isNonDigit(timepickerInput.h)) {
                timepickerInput.h = this.getHourValueIfClockFormat24();
            }
            timepickerInput.s = this.getMinOrSecValue(timepickerInput.s);
            timepickerInput.m = this.getMinOrSecValue(timepickerInput.m);
            timepickerInput.h =
                (Number(timepickerInput.h) > 12 || timepickerInput.h === '00') &&
                    timepickerInput.clockFormat === '12-hour'
                    ? '01'
                    : Number(timepickerInput.h) > 23 && timepickerInput.clockFormat === '24-hour'
                        ? '00'
                        : timepickerInput.h;
        }
        getMinOrSecValue(timepickerInputField) {
            if ((timepickerInputField && this.isNonDigit(timepickerInputField)) ||
                Number(timepickerInputField) > 59) {
                return '00';
            }
            return timepickerInputField;
        }
        isNonDigit(input) {
            return input.toString().match(/\D/i) !== null;
        }
        validateAmPmBasedOnInput(input) {
            const valueOfAmPmInput = input.toString().toLowerCase();
            if (valueOfAmPmInput.length === 2) {
                return valueOfAmPmInput != 'am' && valueOfAmPmInput != 'pm'
                    ? 'AM'
                    : valueOfAmPmInput === 'pm' || valueOfAmPmInput === 'am'
                        ? valueOfAmPmInput.toUpperCase()
                        : '';
            }
            else if (valueOfAmPmInput.length === 1) {
                return valueOfAmPmInput !== 'p' ? 'AM' : 'PM';
            }
            else if (valueOfAmPmInput.length > 2) {
                return 'AM';
            }
            else
                return;
        }
    }
    return TimeInputValidatorMixin;
};
