import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { sharedStyles } from '../../styles';
import { generateCalendarData } from '../../utils/calendar-data-generator.js';
import { DateFormatHelper } from '../../utils/date-format-helper.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { MultiItemFocusBlurMixin } from '../../utils/multi-item-focus-blur-mixin.js';
import privateDateSelectorStyles from './sh-private-date-selector.lit.scss.js';
/**
 * Component that allows to select dates from a set
 * of months. Either 1 date or 2 dates depending on the
 * range property.
 * @fires month-header-clicked - Dispatched when clicked on the month-heading button. The clicked month and year as passed as part of detail object of event.
 * @fires selected-dates-changed - Dispatched when the `selectedDates` array is changed and not empty.
 * @fires ready-to-update-value - Dispatched when clicked on 1 date if there is no range and dispatched when clicked on the 2nd date in the case of range ; both
 * @fires apply-first-selection - *hide Dispatched when clicked on the 1st date in the case of range. This is to notify the parent component to enable the 'Apply' button
 * after a small visual delay.
 **/
let SHPrivateDateSelector = class SHPrivateDateSelector extends MultiItemFocusBlurMixin(
  ShuiLitElement
) {
  constructor() {
    super(...arguments);
    /** The array of selected dates if any. Maximum it can have 2 selected dates for the case of range property
           and only one value if there is no range property. */
    this.selectedDates = [];
    /** The minimum date below which all dates will be disabled. `miiiiiinDate` value should be of type Date. */
    this.minDate = null;
    /** The maximum date above which all dates will be disabled. `maxDate` value should be of type Date. */
    this.maxDate = null;
    /** @ignore */
    this.calendarDatas = [];
    /** @ignore */
    this.monthYearDatas = [];
    /** @ignore */
    this.startMondayLocales = [];
  }
  static get styles() {
    return [super.styles, sharedStyles, privateDateSelectorStyles];
  }
  render() {
    return html`<div class="months">
      ${this.calendarDatas.map(
        (calendarData, monthIndex) =>
          html`<div class="date-panel">
            <div class="header-area">
              ${monthIndex === 0
                ? html`<sh-icon
                    icon="arrow-left-s"
                    size="m"
                    button
                    @click="${() => {
                      --this.month;
                      this.generateCalendarDatas();
                    }}"
                  ></sh-icon>`
                : html`<div class="icon-dummy"></div>`}
              <div
                class="month-and-year-display focus-item"
                @click="${() => {
                  this.monthHeaderClickedEvent.emit(
                    new CustomEvent('month-header-clicked', {
                      detail: {
                        clickedYear: this.monthYearDatas[monthIndex][1],
                      },
                    })
                  );
                }}"
                tabindex="0"
              >
                ${this.getMonthYearHeader(this.monthYearDatas[monthIndex])}
              </div>
              ${monthIndex === this.calendarDatas.length - 1
                ? html`<sh-icon
                    icon="arrow-right-s"
                    size="m"
                    button
                    @click="${() => {
                      ++this.month;
                      this.generateCalendarDatas();
                    }}"
                  ></sh-icon>`
                : html`<div class="icon-dummy"></div>`}
            </div>
            <div class="date-grid">
              ${new Array(7).fill('_').map((_, index) => {
                let startDay = this.startMondayLocales.includes(this.locale) ? 1 : 0;
                // first day of week has to be highest priority over locale
                if (this.firstDayOfWeek) {
                  startDay = this.firstDayOfWeek === 'monday' ? 1 : 0;
                }
                // to make it to zero if index=7 (this happens when we start counting from 1)
                const dayIndex = index + startDay < 7 ? index + startDay : 0;
                return html`<span class="week-day-name"
                  >${DateFormatHelper.getWeekDays(dayIndex, this.locale)}</span
                >`;
              })}
              ${calendarData.map((dateObject) => {
                var _a;
                return html`<span
                  class="${classMap(this.getComputedClasses(dateObject))}"
                  @click="${() => {
                    this.addToSelectedDates(dateObject.date, dateObject);
                  }}"
                  tabindex="${dateObject.isDisabled ? '-1' : '0'}"
                  @mouseover="${() => {
                    if (this.range && this.selectedDates.length === 1) {
                      this.hoverActions(dateObject.date);
                    }
                  }}"
                  >${(_a = dateObject.date) === null || _a === void 0 ? void 0 : _a.getDate()}</span
                >`;
              })}
            </div>
          </div>`
      )}
    </div>`;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    } else {
      this.setAttribute('tabindex', '0');
    }
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('selectedDates') && this.selectedDates.length !== 0) {
      this.selectedDatesChangedEvent.emit(new CustomEvent('selected-dates-changed'));
    }
  }
  generateCalendarDatas() {
    if (this.month > 11) {
      this.month = 0;
      ++this.year;
    }
    if (this.month < 0) {
      this.month = 11;
      --this.year;
    }
    let startMonth = this.month;
    // eslint-disable-next-line prefer-const
    let startYear = this.year;
    const calendarDatas = [];
    const monthYearDatas = [];
    for (let i = 0; i < this.months; i++) {
      monthYearDatas.push([startMonth, startYear]);
      calendarDatas.push(
        generateCalendarData(
          startMonth,
          startYear,
          this.todaysDate,
          this.selectedDates,
          this.futureDateDisable,
          this.pastDateDisable,
          this.minDate,
          this.maxDate,
          this.startMondayLocales.includes(this.locale) ? 1 : 0,
          this.firstDayOfWeek
        )
      );
      ++startMonth;
      if (startMonth === 12) {
        startMonth = 0;
        ++startYear;
      }
    }
    this.calendarDatas = calendarDatas;
    this.monthYearDatas = monthYearDatas;
  }
  getComputedClasses(dateObject) {
    return {
      date: true,
      'other-month': dateObject.isOtherMonthDay,
      disabled: dateObject.isDisabled,
      today: dateObject.isToday,
      selected: dateObject.isSelected,
      'within-range': dateObject.isWithinRange,
      'focus-item': true,
    };
  }
  addToSelectedDates(date, dateObject) {
    const selectedDates = this.selectedDates;
    if (this.range) {
      if (selectedDates.length < 2) {
        selectedDates.push(date);
        this.updateSelectedDate(date);
        if (selectedDates.length === 2) {
          // means both dates have been selected.
          // so sort the dates and dispatch
          // event to notify that the datepicker
          // is ready to update its value.
          // After dispatch, return from further
          // execution and further update of
          // selectedDates Array.
          selectedDates.sort((a, b) => a.getTime() - b.getTime());
          this.selectedDates = selectedDates;
          this.readyToUpdateValueEvent.emit(
            new CustomEvent('ready-to-update-value', {
              detail: { selectedDates: this.selectedDates },
              bubbles: true,
              composed: true,
            })
          );
          return;
        }
        // means a date has just been selected by clicking !
        else if (selectedDates.length === 1) {
          // this switching of view is only for
          // switching view for the first clicked
          // date in the range. For the second
          // date, the datepicker anyway closes.
          this.switchViewIfDateIsOutsideView(date);
          if (this.requireApply) {
            // dispatching this event so that
            // the 'Apply' button's disabled property
            // can be set to true for the click of
            // first date
            this.updateFirstValueRequireApplyEvent.emit(
              new CustomEvent('apply-first-selection', {
                detail: { selectedDates: this.selectedDates },
                bubbles: true,
                composed: true,
              })
            );
          }
        }
      }
      // if the date has to be selected after selecting two dates
      // after opening the overlay.
      else if (selectedDates.length === 2) {
        // empty the selectedDates array.
        // clear all the UI data
        // call this function again.
        // This time, it will go through the
        // other if condition pushing the
        // date to the array.
        this.selectedDates = [];
        this.clearAllData();
        this.addToSelectedDates(date, dateObject);
        return;
      }
    } else {
      this.clearAllData();
      this.selectedDates = [date];
      this.updateSelectedDate(date);
      this.readyToUpdateValueEvent.emit(
        new CustomEvent('ready-to-update-value', {
          detail: { selectedDates: this.selectedDates },
          bubbles: true,
          composed: true,
        })
      );
      return;
    }
    // means only one date is selected. Then update
    // selectedDates Array and requestUpdate
    // so as to dispatch 'selected-dates-changed'
    // event
    if (this.range && selectedDates.length !== 2) {
      this.selectedDates = selectedDates;
      this.requestUpdate();
    }
  }
  /**
   * Check if the clicked date
   * is outside the current view. If so,
   * then click on the next or previous
   * icon to switch view.
   * @param {Date} date
   */
  switchViewIfDateIsOutsideView(date) {
    var _a, _b;
    const firstMonthYearMonthData = JSON.parse(JSON.stringify(this.monthYearDatas[0])).reverse();
    const lastMonthYearMonthData = JSON.parse(
      JSON.stringify(this.monthYearDatas[this.monthYearDatas.length - 1])
    ).reverse();
    const lastMonthLastDayDate = this.getDayCountforMonthYear(
      lastMonthYearMonthData[1],
      lastMonthYearMonthData[0]
    );
    const firstMonthFirstDay = new Date(...firstMonthYearMonthData, 1, 0, 0, 0, 0);
    const lastMonthLastDay = new Date(...lastMonthYearMonthData, lastMonthLastDayDate, 0, 0, 0, 0);
    const selectedDateIsEarlierDate = date.getTime() < firstMonthFirstDay.getTime();
    const selectedDateIsLaterDate = date.getTime() > lastMonthLastDay.getTime();
    const selectedDateMonthIsNotInView = selectedDateIsEarlierDate || selectedDateIsLaterDate;
    if (selectedDateMonthIsNotInView) {
      if (selectedDateIsEarlierDate) {
        ((_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('sh-icon[icon="arrow-left-s"]')
        ).click();
      } else {
        ((_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('sh-icon[icon="arrow-right-s"]')
        ).click();
      }
    }
  }
  hoverActions(date) {
    const calendarDatas = this.updateWithinRangeCalendarData(
      this.calendarDatas,
      this.selectedDates[0],
      date
    );
    this.calendarDatas = calendarDatas;
  }
  /**
   *
   * @param {Array} allCalendarDatas - the single dimensional array of calendarDatas
   * @returns - an array of arrays of calendarDatas of 42 items each
   */
  extractNewCalendarDatas(allCalendarDatas) {
    const numOfData = allCalendarDatas.length / 42;
    let calendarDataIterationCount = 0;
    const requiredCalendarDatas = [];
    for (let i = 0; i < numOfData; i++) {
      const calendarData = [];
      const startIndex = calendarDataIterationCount;
      const endIndex = calendarDataIterationCount + 41;
      for (let j = startIndex; j <= endIndex; j++) {
        calendarData.push(allCalendarDatas[j]);
        ++calendarDataIterationCount;
      }
      requiredCalendarDatas.push(calendarData);
    }
    return requiredCalendarDatas;
  }
  clearAllData() {
    const allCalendarDatas = this.calendarDatas.flat();
    allCalendarDatas.forEach((dateObject) => (dateObject.isWithinRange = false));
    allCalendarDatas.forEach((dateObject) => (dateObject.isSelected = false));
    const requiredCalendarDatas = this.extractNewCalendarDatas(allCalendarDatas);
    this.calendarDatas = requiredCalendarDatas;
  }
  updateSelectedDate(date) {
    const allCalendarDatas = this.calendarDatas.flat();
    const selectedDateObjectIndex = allCalendarDatas.findIndex((dateObject) => {
      var _a;
      return (
        ((_a = dateObject.date) === null || _a === void 0 ? void 0 : _a.getTime()) ===
          date.getTime() && !dateObject.isOtherMonthDay
      );
    });
    if (selectedDateObjectIndex !== -1) {
      allCalendarDatas[selectedDateObjectIndex].isSelected = true;
    }
    const requiredCalendarDatas = this.extractNewCalendarDatas(allCalendarDatas);
    this.calendarDatas = requiredCalendarDatas;
    this.requestUpdate();
  }
  /**
   *
   * @param {Array<Array<DateData>>} calendarDatas
   * @param {Date} firstClickedDate
   * @param {Date} hoverDate
   * @returns
   */
  updateWithinRangeCalendarData(calendarDatas, firstClickedDate, hoverDate) {
    if (hoverDate.getTime() > firstClickedDate.getTime()) {
      this.fromDate = firstClickedDate;
      this.toDate = hoverDate;
    } else {
      this.fromDate = hoverDate;
      this.toDate = firstClickedDate;
    }
    const allCalendarDatas = calendarDatas.flat();
    allCalendarDatas.forEach((dateObject) => (dateObject.isWithinRange = false));
    let fromIndex = allCalendarDatas.findIndex((dateObject) => {
      var _a, _b;
      return (
        ((_a = dateObject.date) === null || _a === void 0 ? void 0 : _a.getTime()) ===
          ((_b = this.fromDate) === null || _b === void 0 ? void 0 : _b.getTime()) &&
        !dateObject.isOtherMonthDay
      );
    });
    fromIndex = fromIndex === -1 ? 0 : fromIndex;
    let toIndex = allCalendarDatas.findIndex((dateObject) => {
      var _a, _b;
      return (
        ((_a = dateObject.date) === null || _a === void 0 ? void 0 : _a.getTime()) ===
          ((_b = this.toDate) === null || _b === void 0 ? void 0 : _b.getTime()) &&
        !dateObject.isOtherMonthDay
      );
    });
    toIndex = toIndex === -1 ? allCalendarDatas.length - 1 : toIndex;
    for (let i = fromIndex; i <= toIndex; i++) {
      const dateObject = allCalendarDatas[i];
      if (!dateObject.isOtherMonthDay) {
        dateObject.isWithinRange = true;
      }
    }
    const requiredCalendarDatas = this.extractNewCalendarDatas(allCalendarDatas);
    return requiredCalendarDatas;
  }
  getDayCountforMonthYear(monthIndex, year) {
    // eslint-disable-next-line prefer-const
    let dayCount = 0;
    for (let i = 1; i <= 31; i++) {
      const testDate = new Date(year, monthIndex, i, 0, 0, 0, 0);
      if (testDate.getMonth() === monthIndex) {
        ++dayCount;
      } else {
        break;
      }
    }
    return dayCount;
  }
  _enterKeyAction(focusedElement) {
    if (focusedElement !== this) {
      focusedElement.click();
    }
  }
  getMonthYearHeader(monthYearData) {
    const month = monthYearData[0];
    const year = monthYearData[1];
    const date = new Date(year, month, 1, 0, 0, 0, 0);
    let monthYearHeader = date.toLocaleString(this.locale, { month: 'long', year: 'numeric' });
    monthYearHeader = monthYearHeader[0].toLocaleUpperCase(this.locale) + monthYearHeader.slice(1);
    return monthYearHeader;
  }
};
__decorate([property({ type: Number })], SHPrivateDateSelector.prototype, 'month', void 0);
__decorate([property({ type: Number })], SHPrivateDateSelector.prototype, 'year', void 0);
__decorate([property({ type: Object })], SHPrivateDateSelector.prototype, 'todaysDate', void 0);
__decorate([property({ type: Array })], SHPrivateDateSelector.prototype, 'selectedDates', void 0);
__decorate(
  [property({ type: Boolean })],
  SHPrivateDateSelector.prototype,
  'futureDateDisable',
  void 0
);
__decorate(
  [property({ type: Boolean })],
  SHPrivateDateSelector.prototype,
  'pastDateDisable',
  void 0
);
__decorate([property({ type: Object })], SHPrivateDateSelector.prototype, 'minDate', void 0);
__decorate([property({ type: Object })], SHPrivateDateSelector.prototype, 'maxDate', void 0);
__decorate([property({ type: Number })], SHPrivateDateSelector.prototype, 'months', void 0);
__decorate([property({ type: Boolean })], SHPrivateDateSelector.prototype, 'range', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHPrivateDateSelector.prototype,
  'responsive',
  void 0
);
__decorate([property({ type: String })], SHPrivateDateSelector.prototype, 'locale', void 0);
__decorate([property({ type: Array })], SHPrivateDateSelector.prototype, 'calendarDatas', void 0);
__decorate([property({ type: Array })], SHPrivateDateSelector.prototype, 'monthYearDatas', void 0);
__decorate(
  [property({ type: Array })],
  SHPrivateDateSelector.prototype,
  'startMondayLocales',
  void 0
);
__decorate([property({ type: Date })], SHPrivateDateSelector.prototype, 'fromDate', void 0);
__decorate([property({ type: Date })], SHPrivateDateSelector.prototype, 'toDate', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'require-apply' })],
  SHPrivateDateSelector.prototype,
  'requireApply',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHPrivateDateSelector.prototype,
  'firstDayOfWeek',
  void 0
);
__decorate([event()], SHPrivateDateSelector.prototype, 'monthHeaderClickedEvent', void 0);
__decorate([event()], SHPrivateDateSelector.prototype, 'selectedDatesChangedEvent', void 0);
__decorate([event()], SHPrivateDateSelector.prototype, 'readyToUpdateValueEvent', void 0);
__decorate([event()], SHPrivateDateSelector.prototype, 'updateFirstValueRequireApplyEvent', void 0);
SHPrivateDateSelector = __decorate(
  [customElement('sh-private-date-selector')],
  SHPrivateDateSelector
);
export { SHPrivateDateSelector };
