// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-popover.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-popover-min-width=12.5rem] - min-width of the popover
   */
  --sh-popover-min-width: 12.5rem;
  /* 
   * @cssprop [--sh-popover-width=15.5rem] - width of the popover
   */
  --sh-popover-width: 15.5rem;
  /* 
   * @cssprop [--sh-popover-current-scale=s] - the current scale of popover used to set the arrow-position initial values
   */
  --sh-popover-current-scale: s;
  /* 
   * @cssprop [--sh-popover-header-padding=1rem 1rem 0] - margin-bottom of the header-wrapper
   */
  --sh-popover-header-padding: 1rem 1rem 0;
  /* 
   * @cssprop [--sh-popover-body-padding=1rem] - padding of the popover
   */
  --sh-popover-body-padding: 1rem;
  /* 
   * @cssprop [--sh-popover-body-wrapper-margin-bottom=0.5rem] - margin-bottom for container components
   */
  --sh-popover-body-wrapper-margin-bottom: 0.5rem;
  /* 
   * @cssprop [--sh-popover-footer-padding=0 1rem 1rem] - margin-top of the footer-wrapper
   */
  --sh-popover-footer-padding: 0 1rem 1rem;
  /* 
   * @cssprop [--sh-popover-header-padding-with-empty-function-slot=1rem] - padding-right of header when function slot is empty
   */
  --sh-popover-header-padding-with-empty-function-slot: 1rem;
  /* 
   * @cssprop [--sh-popover-label-padding=0] - padding of the label
   */
  --sh-popover-label-padding: 0;
  /* 
   * @cssprop [--sh-popover-headerless-body-top-padding=1rem] - padding-top of body-wrapper when header is empty
   */
  --sh-popover-headerless-body-top-padding: 1rem;
  /* 
   * @cssprop [--sh-popover-gap-between-footer-slot-elements=0.5rem] - gap between the footer slot elements
   */
  --sh-popover-gap-between-footer-slot-elements: 0.5rem;
  /* 
   * @cssprop [--sh-popover-gap-between-function-slot-elements=var(--sh-popover-gap-between-footer-slot-elements)] - gap between the functions slot element
   */
  --sh-popover-gap-between-function-slot-elements: var(--sh-popover-gap-between-footer-slot-elements);
  /* 
   * @cssprop [--sh-popover-label-margin-right=0.5rem] - margin-right of the label when function slot is not empty
   */
  --sh-popover-label-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-popover-label-line-height=1.5rem] - line-height of the popover label
   */
  --sh-popover-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-popover-header-icon-margin-right=0.5rem] - margin-right of the icon in header
   */
  --sh-popover-header-icon-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-popover-content-line-height=1.25rem] - line-height of the content
   */
  --sh-popover-content-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-popover-arrow-border-width=0rem 0.375rem 0.5rem 0.375rem] - border-width of arrow
   */
  --sh-popover-arrow-border-width: 0rem 0.375rem 0.5rem 0.375rem;
  /* 
   * @cssprop [--sh-popover-arrow-transform-origin=0.375rem 0.5rem] - transform origin for the arrow in passive popover
   */
  --sh-popover-arrow-transform-origin: 0.375rem 0.5rem;
  /* 
   * @cssprop [--sh-popover-submenu-translateY=-0.5rem] - the top space fod the submenu from the target
   */
  --sh-popover-submenu-translateY: -0.5rem;
  /* 
   * @cssprop [--sh-popover-border-radius=var(--sh-radius-container)] - popover border radius
   */
  --sh-popover-border-radius: var(--sh-radius-container);
  /* 
   * @cssprop [--sh-popover-icon-margin-top=0px] - icon margin top
   */
  --sh-popover-icon-margin-top: 0px;
}

:host {
  font: var(--body-1);
  color: var(--text-primary);
  display: block;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  background-color: var(--base-4);
  min-width: var(--sh-popover-min-width);
  width: var(--sh-popover-width);
  min-height: auto;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  box-sizing: border-box;
  border-radius: var(--sh-popover-border-radius);
  margin-top: 0rem !important;
  box-shadow: var(--shadow-overlay);
  transition: var(--transition-time) all ease-in-out, 0s top, 0s left, 0s width, 0s min-width;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  --current-scale: var(--sh-popover-current-scale);
  z-index: 999;
  pointer-events: none;
  overflow-wrap: break-word;
}

:host(:not([mobile])[blur-background]) {
  background-color: rgba(var(--blur-background-1), var(--opacity-3));
  backdrop-filter: blur(var(--blur-1));
  -webkit-backdrop-filter: blur(var(--blur-1));
}

:host(:not([mobile])) .popover-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

:host(:not([label])) .body-wrapper {
  padding: unset;
}

:host(:not([label])) {
  padding: var(--sh-popover-headerless-body-top-padding);
}

:host(:not([label])) .footer-wrapper {
  padding-top: var(--sh-popover-headerless-body-top-padding);
}

:host([label]) .footer-wrapper {
  padding: var(--sh-popover-footer-padding);
}

:host([passive]) .arrow {
  position: absolute;
  border-width: var(--sh-popover-arrow-border-width);
  border-style: solid;
  border-color: transparent transparent var(--base-4) transparent;
  transform-origin: var(--sh-popover-arrow-transform-origin);
}

:host(:not([mobile])[passive][blur-background]) .arrow {
  border-color: transparent transparent rgba(var(--blur-background-1), var(--opacity-3)) transparent;
}

@-moz-document url-prefix() {
  :host(:not([mobile])[blur-background]) {
    background-color: rgba(var(--blur-background-1), var(--opacity-2));
  }
  :host(:not([mobile])[passive][blur-background]) .arrow {
    border-color: transparent transparent rgba(var(--blur-background-1), var(--opacity-2)) transparent;
  }
}
.header-wrapper {
  display: flex;
  padding: var(--sh-popover-header-padding);
  align-items: flex-start;
}

:host(:not([label])) .header-wrapper {
  display: none;
}

.popover-wrapper[empty-function] .header-wrapper {
  padding-right: var(--sh-popover-header-padding-with-empty-function-slot);
}

slot[name=functions] {
  display: flex;
  gap: var(--sh-popover-gap-between-function-slot-elements);
}

:host([label-rows=auto]) slot[name=functions] {
  align-self: flex-start;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: var(--sh-popover-content-line-height);
  padding: var(--sh-popover-body-padding);
}

:host([mobile]:not([label])) .body-wrapper {
  padding: var(--sh-popover-headerless-body-top-padding);
}

.body-wrapper > ::slotted(*:not(:last-child):not(sh-menu-item)) {
  margin-bottom: var(--sh-popover-body-wrapper-margin-bottom);
}

.footer-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--sh-popover-gap-between-footer-slot-elements);
}

.popover-wrapper:not([empty-function]) .popover-label {
  margin-right: var(--sh-popover-label-margin-right);
}

.popover-label {
  font: var(--header-2);
  color: var(--text-primary);
  display: block;
  line-height: var(--sh-popover-label-line-height);
  padding: var(--sh-popover-label-padding);
  align-self: center;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:host([label-rows=auto]) .popover-label {
  white-space: unset;
}

:host([visible]) {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

:host([sub-menu]) {
  transform: translateY(var(--sh-popover-submenu-translateY));
}

.header-wrapper sh-icon {
  margin-top: var(--sh-popover-icon-margin-top);
  margin-right: var(--sh-popover-header-icon-margin-right);
}

/* mobile */
:host([mobile]) {
  border-radius: 0px;
  position: fixed;
  top: 0rem !important;
  left: 0rem !important;
  height: 100%;
  background: rgba(0, 0, 0, var(--opacity-4));
  z-index: 999;
  width: 100vw;
  padding: 0;
  transition: var(--transition-time) all ease-in-out, 0s height ease-in-out var(--transition-time), 0s max-height ease-in-out var(--transition-time);
}

:host([mobile]) .popover-wrapper {
  position: absolute;
  width: 100vw;
  background: var(--base-4);
  bottom: 0rem;
  max-height: calc(100% - 6.5rem);
  overflow: auto;
}

/* slot styles */
:host(:not([label]):not([type])) .popover-wrapper[empty-function] .header-wrapper,
.popover-wrapper[empty-footer] .footer-wrapper {
  display: none;
}`;