// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-table-row.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-table-row-div-border-bottom=0.0625rem solid rgba(var(--ui-1), var(--opacity-6))] - border bottom of a div inside table row
   */
  --sh-table-row-div-border-bottom: 0.0625rem solid rgba(var(--ui-1), var(--opacity-6));
  /* 
   * @cssprop [--sh-table-row-header-slot-div-border-bottom=0.0625rem solid rgba(var(--ui-1), var(--opacity-4))] - border bottom of a header slot div inside table row
   */
  --sh-table-row-header-slot-div-border-bottom: 0.0625rem solid rgba(var(--ui-1), var(--opacity-4));
  /* 
   * @cssprop [--sh-table-row-multiselect-checkbox-left=2.5rem] - left value for checkbox with nested multiselect 
   */
  --sh-table-row-multiselect-checkbox-left: 2.5rem;
  /* 
   * @cssprop [--sh-table-row-initial-icon-left=3rem] - left value for checkbox with nested multiselect 
   */
  --sh-table-row-initial-icon-left: 3rem;
  /* 
   * @cssprop [--sh-table-row-nested-expand-collapse-left=0.75rem] - left value for nested expand collapse icon 
   */
  --sh-table-row-nested-expand-collapse-left: 0.75rem;
  /* 
   * @cssprop [--sh-table-row-arrow-inset=2.75rem] - expand clollapse arrow inset -nested  
   */
  --sh-table-row-arrow-inset: 2.75rem;
}

:host {
  outline: 0;
  display: block;
}

:host > div {
  border-bottom: var(--sh-table-row-div-border-bottom);
  display: flex;
  flex-direction: row;
  transition: var(--transition-time) all ease-in-out, calc(var(--transition-time) * 0.5) background ease;
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
}

:host([slot=header]) > div {
  border-bottom: var(--sh-table-row-header-slot-div-border-bottom);
}

/* column assignment */
#wrapper > ::slotted([columns="24"]),
#wrapper > div > ::slotted([columns="24"]) {
  width: 100%;
}

#wrapper > ::slotted([columns="23"]),
#wrapper > div > ::slotted([columns="23"]) {
  width: 95.8333333333%;
}

#wrapper > ::slotted([columns="22"]),
#wrapper > div > ::slotted([columns="22"]) {
  width: 91.6666666667%;
}

#wrapper > ::slotted([columns="21"]),
#wrapper > div > ::slotted([columns="21"]) {
  width: 87.5%;
}

#wrapper > ::slotted([columns="20"]),
#wrapper > div > ::slotted([columns="20"]) {
  width: 83.3333333333%;
}

#wrapper > ::slotted([columns="19"]),
#wrapper > div > ::slotted([columns="19"]) {
  width: 79.1666666667%;
}

#wrapper > ::slotted([columns="18"]),
#wrapper > div > ::slotted([columns="18"]) {
  width: 75%;
}

#wrapper > ::slotted([columns="17"]),
#wrapper > div > ::slotted([columns="17"]) {
  width: 70.8333333333%;
}

#wrapper > ::slotted([columns="16"]),
#wrapper > div > ::slotted([columns="16"]) {
  width: 66.6666666667%;
}

#wrapper > ::slotted([columns="15"]),
#wrapper > div > ::slotted([columns="15"]) {
  width: 62.5%;
}

#wrapper > ::slotted([columns="14"]),
#wrapper > div > ::slotted([columns="14"]) {
  width: 58.3333333333%;
}

#wrapper > ::slotted([columns="13"]),
#wrapper > div > ::slotted([columns="13"]) {
  width: 54.1666666667%;
}

#wrapper > ::slotted([columns="12"]),
#wrapper > div > ::slotted([columns="12"]) {
  width: 50%;
}

#wrapper > ::slotted([columns="11"]),
#wrapper > div > ::slotted([columns="11"]) {
  width: 45.8333333333%;
}

#wrapper > ::slotted([columns="10"]),
#wrapper > div > ::slotted([columns="10"]) {
  width: 41.6666666667%;
}

#wrapper > ::slotted([columns="9"]),
#wrapper > div > ::slotted([columns="9"]) {
  width: 37.5%;
}

#wrapper > ::slotted([columns="8"]),
#wrapper > div > ::slotted([columns="8"]) {
  width: 33.3333333333%;
}

#wrapper > ::slotted([columns="7"]),
#wrapper > div > ::slotted([columns="7"]) {
  width: 29.1666666667%;
}

#wrapper > ::slotted([columns="6"]),
#wrapper > div > ::slotted([columns="6"]) {
  width: 25%;
}

#wrapper > ::slotted([columns="5"]),
#wrapper > div > ::slotted([columns="5"]) {
  width: 20.8333333333%;
}

#wrapper > ::slotted([columns="4"]),
#wrapper > div > ::slotted([columns="4"]) {
  width: 16.6666666667%;
}

#wrapper > ::slotted([columns="3"]),
#wrapper > div > ::slotted([columns="3"]) {
  width: 12.5%;
}

#wrapper > ::slotted([columns="2"]),
#wrapper > div > ::slotted([columns="2"]) {
  width: 8.3333333333%;
}

#wrapper > ::slotted([columns="1"]),
#wrapper > div > ::slotted([columns="1"]) {
  width: 4.1666666667%;
}

#wrapper > ::slotted([columns="0"]),
#wrapper > div > ::slotted([columns="0"]) {
  display: none;
}

/* column assignment for medium breakpoint */
@media only screen and (max-width: 64.0625rem) {
  #wrapper > ::slotted([col-m="24"]) {
    width: 100%;
  }
  #wrapper > ::slotted([col-m="23"]) {
    width: 95.8333333333%;
  }
  #wrapper > ::slotted([col-m="22"]) {
    width: 91.6666666667%;
  }
  #wrapper > ::slotted([col-m="21"]) {
    width: 87.5%;
  }
  #wrapper > ::slotted([col-m="20"]) {
    width: 83.3333333333%;
  }
  #wrapper > ::slotted([col-m="19"]) {
    width: 79.1666666667%;
  }
  #wrapper > ::slotted([col-m="18"]) {
    width: 75%;
  }
  #wrapper > ::slotted([col-m="17"]) {
    width: 70.8333333333%;
  }
  #wrapper > ::slotted([col-m="16"]) {
    width: 66.6666666667%;
  }
  #wrapper > ::slotted([col-m="15"]) {
    width: 62.5%;
  }
  #wrapper > ::slotted([col-m="14"]) {
    width: 58.3333333333%;
  }
  #wrapper > ::slotted([col-m="13"]) {
    width: 54.1666666667%;
  }
  #wrapper > ::slotted([col-m="12"]) {
    width: 50%;
  }
  #wrapper > ::slotted([col-m="11"]) {
    width: 45.8333333333%;
  }
  #wrapper > ::slotted([col-m="10"]) {
    width: 41.6666666667%;
  }
  #wrapper > ::slotted([col-m="9"]) {
    width: 37.5%;
  }
  #wrapper > ::slotted([col-m="8"]) {
    width: 33.3333333333%;
  }
  #wrapper > ::slotted([col-m="7"]) {
    width: 29.1666666667%;
  }
  #wrapper > ::slotted([col-m="6"]) {
    width: 25%;
  }
  #wrapper > ::slotted([col-m="5"]) {
    width: 20.8333333333%;
  }
  #wrapper > ::slotted([col-m="4"]) {
    width: 16.6666666667%;
  }
  #wrapper > ::slotted([col-m="3"]) {
    width: 12.5%;
  }
  #wrapper > ::slotted([col-m="2"]) {
    width: 8.3333333333%;
  }
  #wrapper > ::slotted([col-m="1"]) {
    width: 4.1666666667%;
  }
  #wrapper > ::slotted([col-m="0"]) {
    display: none;
  }
}
/* column assignment for small breakpoint */
@media only screen and (max-width: 47.9375rem) {
  #wrapper > ::slotted([col-s="24"]) {
    width: 100%;
  }
  #wrapper > ::slotted([col-s="23"]) {
    width: 95.8333333333%;
  }
  #wrapper > ::slotted([col-s="22"]) {
    width: 91.6666666667%;
  }
  #wrapper > ::slotted([col-s="21"]) {
    width: 87.5%;
  }
  #wrapper > ::slotted([col-s="20"]) {
    width: 83.3333333333%;
  }
  #wrapper > ::slotted([col-s="19"]) {
    width: 79.1666666667%;
  }
  #wrapper > ::slotted([col-s="18"]) {
    width: 75%;
  }
  #wrapper > ::slotted([col-s="17"]) {
    width: 70.8333333333%;
  }
  #wrapper > ::slotted([col-s="16"]) {
    width: 66.6666666667%;
  }
  #wrapper > ::slotted([col-s="15"]) {
    width: 62.5%;
  }
  #wrapper > ::slotted([col-s="14"]) {
    width: 58.3333333333%;
  }
  #wrapper > ::slotted([col-s="13"]) {
    width: 54.1666666667%;
  }
  #wrapper > ::slotted([col-s="12"]) {
    width: 50%;
  }
  #wrapper > ::slotted([col-s="11"]) {
    width: 45.8333333333%;
  }
  #wrapper > ::slotted([col-s="10"]) {
    width: 41.6666666667%;
  }
  #wrapper > ::slotted([col-s="9"]) {
    width: 37.5%;
  }
  #wrapper > ::slotted([col-s="8"]) {
    width: 33.3333333333%;
  }
  #wrapper > ::slotted([col-s="7"]) {
    width: 29.1666666667%;
  }
  #wrapper > ::slotted([col-s="6"]) {
    width: 25%;
  }
  #wrapper > ::slotted([col-s="5"]) {
    width: 20.8333333333%;
  }
  #wrapper > ::slotted([col-s="4"]) {
    width: 16.6666666667%;
  }
  #wrapper > ::slotted([col-s="3"]) {
    width: 12.5%;
  }
  #wrapper > ::slotted([col-s="2"]) {
    width: 8.3333333333%;
  }
  #wrapper > ::slotted([col-s="1"]) {
    width: 4.1666666667%;
  }
  #wrapper > ::slotted([col-s="0"]) {
    display: none;
  }
}
/* chapter menu */
.chapter-wrapper {
  transition: calc(var(--transition-time) * 0.5) ease-in-out all;
}

:host(:not([expanded])) > .chapter-wrapper {
  max-height: 0rem;
  overflow: hidden;
  pointer-events: none;
  transform: translateY(-0.5rem);
  opacity: 0;
  display: none;
}

:host([chapter]) > .chapter-wrapper {
  flex-direction: column;
  border: none;
}

:host(:not([chapter])) > #wrapper > .arrow-icon {
  display: none;
}

:host([chapter]) > #wrapper > .arrow-icon {
  margin-top: 0.5rem;
}

:host([chapter][expanded]) > #wrapper > .arrow-icon {
  transform: rotate(90deg);
}

:host(:not([chapter]).chapter-sibling) > #wrapper > .menu-item-wrapper {
  margin-left: 2rem;
}

/* hover */
:host(:not(.touch-device):not([slot=header]):not([readonly])) > #wrapper:hover {
  background: rgba(var(--ui-1), var(--opacity-7));
}

:host([slot=header]:not(.touch-device)) > div:hover {
  background: transparent;
}

:host(:not([readonly])[active]) #wrapper {
  background: rgba(var(--ui-1), var(--opacity-6)) !important;
}

:host(:not([freeze])) .frozen-left,
:host(:not([freeze])) .scrollable,
:host(:not([freeze])) .frozen-right {
  display: none !important;
}

.frozen-left,
.frozen-right,
.scrollable {
  display: flex;
  align-items: center;
}

.frozen-left {
  box-shadow: inset -0.125rem 0rem 0rem 0rem rgba(var(--ui-1), var(--opacity-6));
}

.frozen-right {
  box-shadow: inset 0.125rem 0rem 0rem 0rem rgba(var(--ui-1), var(--opacity-6));
}

.scrollable {
  overflow-x: hidden;
  overflow-y: hidden;
}

/* menu Expand Icon */
:host #wrapper {
  position: relative;
}

:host #wrapper #tableExpandIcon {
  position: absolute;
}

:host([condensed]) #tableExpandIcon {
  margin-top: 0.25rem !important;
}

:host #wrapper .multiselect-checkbox {
  left: calc(var(--sh-table-row-initial-icon-left) + var(--nesting-level) * var(--sh-table-row-multiselect-checkbox-left));
}

:host #wrapper .default-nested {
  left: calc(var(--sh-table-row-nested-expand-collapse-left) + var(--nesting-level) * var(--sh-table-row-arrow-inset));
}

:host(:focus-visible) #wrapper {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
}`;