// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { css, unsafeCSS } from 'lit';
/**
 * Feature flag to disable interactive area enlargement
 */
const DISABLED = true;
const minTouchSize = `48px`;
/**
 * Some defaults for touch margins
 * Should typically be configurable via css custom properties
 * so they can be overriden as part of themeing!
 */
export const Presets = {
  /**
   * the defalt sizing preset ensures a minium touch size of 48x48px
   * it can be overriden by themes by targeting --sh-interactive-area-min-touch-size
   */
  default: fixedMinSize(),
  defaultLeftAligned: {
    right: 'initial',
    left: `0%`,
    top: `50%`,
    width: '100%',
    height: '100%',
    'min-width': minTouchSize,
    'min-height': minTouchSize,
    transform: 'translate(0%,-50%)',
  },
  defaultRightAligned: {
    left: 'initial',
    right: `0%`,
    top: `50%`,
    width: '100%',
    height: '100%',
    'min-width': minTouchSize,
    'min-height': minTouchSize,
    transform: 'translate(0%,-50%)',
  },
  /**
   * add more reusable presets here if wanted
   */
  // megaHugeTouchArea: fixedMinSize({ size: `88px` }),
};
/**
 * Gnerates a set of css rules that will
 * enforce minimal dimensions of a touchable item.
 * the defalt sizing preset ensures a minium touch size of 48x48px
 * it can be overriden by themes by targeting --sh-interactive-area-min-touch-size
 */
export function fixedMinSize({
  size = `var(--sh-interactive-area-min-touch-size, ${minTouchSize})`,
  width = size,
  height = size,
} = {}) {
  return {
    left: `50%`,
    top: `50%`,
    width: '100%',
    height: '100%',
    'outline-offset': '-1px',
    'min-width': width,
    'min-height': height,
    transform: 'translate(-50%,-50%)',
  };
}
/**
 * IMPORTANT: requires target to have relative positioning!
 * Helper that generates an extended interactice area DOM nodes
 * Helper transparently works for
 *   * Touch interactions
 *   * Hover interactions
 * Uses ':after' css pseudo selector with absolute positioning.
 */
export function extendInteractiveArea(selector = ':host', cssProps = Presets.default) {
  return DISABLED
    ? css``
    : css`
        ${unsafeCSS(`${selector}:after {
      content: '';
      position: absolute;
      outline: var(--sh-interactive-area-outline, none);
      display: var(--sh-interactive-area-display, block);
      ${Object.entries(cssProps)
        .map(([k, v]) => `${k}: ${v};`)
        .join('\n')}
    }`)}
      `;
}
