// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
let keyboardActive = false;

document.addEventListener(
  'keydown',
  () => {
    keyboardActive = true;
  },
  { capture: true }
);

document.addEventListener(
  'pointerdown',
  () => {
    keyboardActive = false;
  },
  { capture: true }
);

export function isKeyboardActive() {
  return keyboardActive;
}
