// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-switch-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`[sh-scale=s] {
  /* 
   * @cssprop [--sh-switch-item-width=5.5rem] - switch item width
   */
  --sh-switch-item-width: 5.5rem;
  /* 
   * @cssprop [--sh-switch-item-height=2.5rem] - switch height
   */
  --sh-switch-item-height: 2.5rem;
  /* 
   * @cssprop [--sh-switch-item-padding=0 0.75rem 0 0.75rem] - switch item padding
   */
  --sh-switch-item-padding: 0 0.75rem 0 0.75rem;
  /* 
   * @cssprop [--sh-switch-item-label-alignment=center] - label alignment
   */
  --sh-switch-item-label-alignment: center;
  /* 
   * @cssprop [--sh-switch-item-border-radius=var(--sh-radius-control)] - switch  border radius
   */
  --sh-switch-item-border-radius: var(--sh-radius-control);
  /* 
   * @cssprop [--sh-switch-item-condensed-height=2rem] - switch  item condensed height
   */
  --sh-switch-item-condensed-height: 2rem;
  /* 
   * @cssprop [--sh-switch-item-condensed-border-radius=1rem] - switch  item condensed height
   */
  --sh-switch-item-condensed-border-radius: 1rem;
  /* 
   * @cssprop [--sh-switch-item-condensed-padding=0.5rem 0.75rem] - switch  item condensed height
   */
  --sh-switch-item-condensed-padding: 0.5rem 0.75rem;
  /* 
   * @cssprop [--sh-switch-item-icon-height=2rem] - switch  item icon height
   */
  --sh-switch-item-icon-height: 2rem;
  /* 
   * @cssprop [--sh-switch-item-icon-width=2rem] - switch  item icon width
   */
  --sh-switch-item-icon-width: 2rem;
  /* 
   * @cssprop [--sh-switch-item-icon-margin=0.25rem 1.75rem] - switch  item icon margin
   */
  --sh-switch-item-icon-margin: 0.25rem 1.75rem;
  /* 
   * @cssprop [--sh-switch-item-label-height=1rem] - switch  item icon margin
   */
  --sh-switch-item-label-height: 1rem;
  /* 
   * @cssprop [--sh-switch-item-icon-condensed-min-width=2rem] - switch item icon condensed min-width
   */
  --sh-switch-item-icon-condensed-min-width: 2rem;
  /* 
   * @cssprop [--sh-switch-item-icon-label-padding=0rem 1.375rem 0rem 1.125rem] - switch item icon and label padding
   */
  --sh-switch-item-icon-label-padding: 0rem 1.375rem 0rem 1.125rem;
  /* 
   * @cssprop [--sh-switch-item-icon-label-min-width=6rem] - switch item icon and label min-width
   */
  --sh-switch-item-icon-label-min-width: 6rem;
  /* 
   * @cssprop [--sh-switch-item-icon-position-margin=0 0.5rem 0 0] - switch item icon margin when icon-position is left
   */
  --sh-switch-item-icon-position-margin: 0 0.5rem 0 0;
}

[theme=light],
[theme=dark] {
  --sh-switch-selected-background: var(--tint-150);
  --sh-switch-selected-border: var(--tint-150);
  --sh-switch-selected-disabled-background: var(--tint-50);
  --sh-switch-selected-disabled-border: var(--tint-50);
}

[theme=light-classic],
[theme=dark-classic] {
  --sh-switch-selected-background: rgba(var(--ui-1), var(--opacity-6));
  --sh-switch-selected-border: transparent;
  --sh-switch-selected-disabled-background: rgba(var(--ui-1), var(--opacity-7));
  --sh-switch-selected-disabled-border: transparent;
}

:host {
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
  border-radius: var(--sh-switch-item-border-radius);
  padding: var(--sh-switch-item-padding);
  font: var(--body-1);
  color: var(--color-text-inactive);
  cursor: pointer;
  min-width: var(--sh-switch-item-width);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  outline: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid transparent;
  height: var(--sh-switch-item-height);
  box-sizing: border-box;
}

:host([variant=squared]) {
  transition: none;
}

.label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  height: var(--sh-switch-item-label-height);
  line-height: var(--sh-switch-item-label-height);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:host([active]) {
  background-color: var(--sh-switch-selected-background);
  border: 1px solid var(--sh-switch-selected-border);
  line-height: var(--text-title-1-line-height);
  color: var(--color-text-active);
}

:host([active][variant=squared]) {
  border-radius: var(--sh-radius-control);
}

:host([icon]:not([label])) {
  padding: 0rem;
}

:host([icon][variant=squared]:not([label])) {
  min-width: var(--sh-switch-item-icon-min-width);
}

:host([icon][condensed][variant=squared]:not([label])) {
  min-width: var(--sh-switch-item-icon-condensed-min-width);
  --sh-icon-font-size-s: var(--sh-switch-item-condensed-icon-font-size);
}

:host([icon]) .switch-icon {
  display: flex;
  margin: var(--sh-switch-item-icon-margin);
  color: rgba(var(--ui-1), var(--opacity-3));
  height: var(--sh-switch-item-icon-height);
  width: var(--sh-switch-item-icon-width);
}

:host([icon][active]) .switch-icon {
  color: var(--color-text-active);
}

:host([icon][disabled]) .switch-icon,
:host([disabled]) {
  pointer-events: none;
  color: var(--color-text-disabled);
}

:host([icon][disabled][icon-color]) .switch-icon {
  pointer-events: none;
  opacity: var(--opacity-5);
}

:host([active][disabled]) {
  background-color: var(--sh-switch-selected-disabled-background);
  border: 1px solid var(--sh-switch-selected-disabled-border);
}

:host([condensed]) {
  height: var(--sh-switch-item-condensed-height);
  border-radius: var(--sh-switch-item-condensed-border-radius);
}

:host([blue-outline]) {
  box-shadow: inset 0rem 0rem 0rem 0.125rem var(--focus-color);
}

:host([icon][label][icon-position]) {
  display: flex;
  flex-direction: row;
  min-width: var(--sh-switch-item-icon-label-min-width);
  padding: var(--sh-switch-item-icon-label-padding);
}

:host([icon][label][icon-position]) .switch-icon {
  margin: var(--sh-switch-item-icon-position-margin);
}

:host(:hover),
:host(:hover) .switch-icon {
  color: var(--color-text-strong) !important;
}`;