// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-tooltip.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-tooltip-max-content-width=25rem] - maximum width of the tooltip
   */
  --sh-tooltip-max-content-width: 25rem;
  /* 
   * @cssprop [--sh-tooltip-content-padding=0.25rem 0.5rem] - padding for the tooltip
   */
  --sh-tooltip-content-padding: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tooltip-label-line-height=1rem] - line height of the tooltip label
   */
  --sh-tooltip-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-tooltip-icon-text-title-padding=0.25rem 0rem] - padding for the tooltip with title
   */
  --sh-tooltip-icon-text-title-padding: 0.25rem 0rem;
  /* 
   * @cssprop [--sh-tooltip-icon-text-label-padding=0.25rem 0rem] - padding for the tooltip with label
   */
  --sh-tooltip-icon-text-label-padding: 0.25rem 0rem;
  /* 
   * @cssprop [--sh-tooltip-padding-top-bottom-tooltip-title=0.25rem] - padding for the tooltop title with icon
   */
  --sh-tooltip-padding-top-bottom-tooltip-title: 0.25rem;
  /* 
   * @cssprop [--sh-tooltip-padding-top-bottom-long-tooltip=0.5rem] - padding for the long variation tooltip
   */
  --sh-tooltip-padding-top-bottom-long-tooltip: 0.5rem;
  /* 
   * @cssprop [--sh-tooltip-title-line-height=1rem] - line height of the tooltip title
   */
  --sh-tooltip-title-line-height: 1rem;
  /* 
   * @cssprop [--sh-tooltip-margin-right=0.5rem] - margin right value of the tooltip title and error icon
   */
  --sh-tooltip-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-tooltip-margin-top=0.5rem] - margin top value of the label in long variation
   */
  --sh-tooltip-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-tooltip-border-radius=var(--sh-radius-small-container)] - border-radius of the tooltip
   */
  --sh-tooltip-border-radius: var(--sh-radius-small-container);
  /* 
   * @cssprop [--sh-tooltip-error-icon-size=1rem] - error icon size in xs
   */
  --sh-tooltip-error-icon-size: 1rem;
}

:host {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  color: var(--text-primary);
  transition-property: opacity, visibility, z-index;
  transition-timing-function: ease-in-out;
  transition-duration: var(--transition-time);
  transition-delay: 0s;
  width: max-content;
  height: auto;
  margin: auto;
  margin-top: var(--sh-tooltip-margin-top-value) !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  display: block;
  background: var(--base-4);
  box-shadow: var(--shadow-tooltip);
  border-radius: var(--sh-tooltip-border-radius);
  padding: var(--sh-tooltip-content-padding);
  z-index: 999;
  max-width: var(--sh-tooltip-max-content-width);
  box-sizing: border-box;
  word-break: break-word;
}

:host([title]) {
  padding-top: var(--sh-tooltip-padding-top-bottom-tooltip-title);
  padding-bottom: var(--sh-tooltip-padding-top-bottom-tooltip-title);
}

:host([title][variation=long]) {
  padding-top: var(--sh-tooltip-padding-top-bottom-long-tooltip);
  padding-bottom: var(--sh-tooltip-padding-top-bottom-long-tooltip);
}

:host([variation=short]) .tooltip-label {
  font: var(--body-2);
  color: var(--text-primary);
  line-height: var(--sh-tooltip-label-line-height);
}

:host([variation=short][icon]) .tooltip-label {
  padding: var(--sh-tooltip-icon-text-label-padding);
}

:host([variation=short]:not([icon=error])) .tooltip-label {
  padding: var(--sh-tooltip-icon-text-label-padding);
}

:host([variation=short][icon=error]) .tooltip-label,
:host([variation=short]:not([icon])) .tooltip-label {
  padding: var(--sh-tooltip-top-bottom-label-padding);
}

:host([variation=long]:not([icon=error])) .tooltip-title {
  padding: var(--sh-tooltip-icon-text-title-padding, --sh-tooltip-icon-text-label-padding);
}

:host([variation=long][icon=error]) .tooltip-title,
:host([variation=long]:not([icon])) .tooltip-title {
  padding: 0rem;
}

:host([variation=long]) .tooltip-label {
  font: var(--body-2);
  color: var(--text-primary);
  line-height: var(--sh-tooltip-label-line-height);
}

:host .tooltip {
  font: var(--title-2);
  color: var(--text-primary);
  line-height: var(--sh-tooltip-title-line-height);
  word-break: break-all;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

:host([variation=short]) .tooltip-title {
  padding-top: var(--sh-tooltip-padding-top-bottom-tooltip-title);
  padding-bottom: var(--sh-tooltip-padding-top-bottom-tooltip-title);
}

:host([variation=long]:not([title]):not([icon=error])) .tooltip-label {
  padding: var(--sh-tooltip-icon-text-label-padding);
}

:host([variation=long]:not([title]):not([icon])) .tooltip-label {
  padding: 0px;
}

:host([variation=long][icon=error]:not([title])) .tooltip-label {
  padding: var(--sh-tooltip-top-bottom-label-padding);
}

:host([variation=long]:not([title])) .label-wrapper,
:host([variation=short]) .label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

:host([variation=long]) .label-wrapper {
  margin-top: var(--sh-tooltip-margin-top);
}

:host([variation=long]:not([label])) .label-wrapper {
  margin-top: 0px;
}

:host([variation=long]:not([title])) .label-wrapper {
  margin-top: 0px;
}

#tooltipIcon {
  margin-right: var(--sh-tooltip-margin-right);
}

:host([visible]) {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

:host([visible][delay]) {
  transition-delay: 0.4s;
}

:host([icon=error][variation=short]) sh-icon {
  --sh-icon-font-size-xs: var(--sh-tooltip-error-icon-size);
  padding: var(--sh-tooltip-icon-top-bottom-padding);
}

:host([icon=error][variation=long]) sh-icon {
  --sh-icon-font-size-xs: var(--sh-tooltip-error-icon-size);
  padding: var(--sh-tooltip-icon-error-padding);
}`;