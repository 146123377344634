// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-box-section.lit.scss INSTEAD!
import { css } from 'lit';
export default css`[sh-scale=s] {
  /* 
   * @cssprop [--sh-box-color-surface=var(--base-3)] - Background color of the box
   */
  --sh-box-color-surface: var(--base-3);
  /* 
   * @cssprop [--sh-box-color-surface-secondary=var(--base-2)] - Secondary background color of the box
   */
  --sh-box-color-surface-secondary: var(--base-2);
  /* 
   * @cssprop [--sh-box-color-surface-tertiary=rgba(var(--ui-1), var(--opacity-50))] - Tertiary background color of the box
   */
  --sh-box-color-surface-tertiary: rgba(var(--ui-1), var(--opacity-50));
  /* 
   * @cssprop [--sh-box-color-transparent=transparent] - Transparent color
   */
  --sh-box-color-transparent: transparent;
  /* 
   * @cssprop [--sh-box-padding-wide=1.5rem] - Wide padding for the box
   */
  --sh-box-padding-wide: 1.5rem;
  /* 
   * @cssprop [--sh-box-padding-compact=1rem] - Compact padding for the box
   */
  --sh-box-padding-compact: 1rem;
  /* 
   * @cssprop [--sh-box-padding-supercompact=0.5rem] - Supercompact padding for the box
   */
  --sh-box-padding-supercompact: 0.5rem;
  /* 
   * @cssprop [--sh-box-padding-none=0] - No padding for the box
   */
  --sh-box-padding-none: 0;
  /* 
   * @cssprop [--sh-box-content-gap-0=0] - No gap between content
   */
  --sh-box-content-gap-0: 0;
  /* 
   * @cssprop [--sh-box-content-gap-1=1px] - 1px gap between content
   */
  --sh-box-content-gap-1: 1px;
  /* 
   * @cssprop [--sh-box-content-gap-2=2px] - 2px gap between content
   */
  --sh-box-content-gap-2: 2px;
  /* 
   * @cssprop [--sh-box-content-gap-4=4px] - 4px gap between content
   */
  --sh-box-content-gap-4: 4px;
  /* 
   * @cssprop [--sh-box-content-gap-8=8px] - 8px gap between content
   */
  --sh-box-content-gap-8: 8px;
  /* 
   * @cssprop [--sh-box-elevation-none=none] - No elevation for the box
   */
  --sh-box-elevation-none: none;
  /* 
   * @cssprop [--sh-box-elevation-subtle=var(--shadow-m-subtle)] - Subtle elevation for the box
   */
  --sh-box-elevation-subtle: var(--shadow-m-subtle);
  /* 
   * @cssprop [--sh-box-elevation-moderate=var(--shadow-l-moderate)] - Moderate elevation for the box
   */
  --sh-box-elevation-moderate: var(--shadow-l-moderate);
  /* 
   * @cssprop [--sh-box-elevation-spotlight=var(--shadow-xl-moderate)] - Spotlight elevation for the box
   */
  --sh-box-elevation-spotlight: var(--shadow-xl-moderate);
  /* 
   * @cssprop [--sh-box-slot-spacing=0.5rem] - Spacing for the box slots
   */
  --sh-box-slot-spacing: 0.5rem;
  /* 
   * @cssprop [--sh-box-radius=var(--sh-radius-container, 0.5rem)] - Radius for the box
   */
  --sh-box-radius: var(--sh-radius-container, 0.5rem);
  /* 
   * @cssprop [--sh-box-color-inset-divider=rgba(var(--ui-1), var(--opacity-100))] - Color for the content gap fill
   */
  --sh-box-color-inset-divider: rgba(var(--ui-1), var(--opacity-100));
}

[theme=light],
[theme=light-classic] {
  --sh-box-divider-color: rgba(var(--ui-1), var(--opacity-100));
  --sh-box-color-inset-divider: rgba(var(--ui-1), var(--opacity-100));
}

[theme=dark],
[theme=dark-classic] {
  --sh-box-divider-color: rgba(var(--ui-7), var(--opacity-500));
  --sh-box-color-inset-divider: rgba(var(--ui-7), var(--opacity-500));
}

/* Base styles for supercontainer elements */
:host {
  display: flex;
  flex-direction: column;
  width: var(--sh-box-width, auto);
  height: var(--sh-box-height, auto);
  color: var(--text-secondary);
  font-size: var(--font-size-body-1);
  background-color: var(--background-color, var(--container-background));
  flex: 1 1 auto;
  padding-top: var(--padding-top, var(--container-padding));
  padding-right: var(--padding-right, var(--container-padding));
  padding-bottom: var(--padding-bottom, var(--container-padding));
  padding-left: var(--padding-left, var(--container-padding));
  overflow: auto;
}

:host([background=surface]) {
  --background-color: var(--sh-box-color-surface);
}

:host([background=surface-secondary]) {
  --background-color: var(--sh-box-color-surface-secondary);
}

:host([background=surface-tertiary]) {
  --background-color: var(--sh-box-color-surface-tertiary);
}

:host([background=transparent]) {
  --background-color: var(--sh-box-color-transparent);
}

/* Override padding if specified on sub-elements */
:host([padding=wide]) {
  --container-padding: var(--sh-box-padding-wide);
  --padding-top: var(--sh-box-padding-wide);
  --padding-right: var(--sh-box-padding-wide);
  --padding-bottom: var(--sh-box-padding-wide);
  --padding-left: var(--sh-box-padding-wide);
}

:host([padding=compact]) {
  --container-padding: var(--sh-box-padding-compact);
  --padding-top: var(--sh-box-padding-compact);
  --padding-right: var(--sh-box-padding-compact);
  --padding-bottom: var(--sh-box-padding-compact);
  --padding-left: var(--sh-box-padding-compact);
}

:host([padding=supercompact]) {
  --container-padding: var(--sh-box-padding-supercompact);
  --padding-top: var(--sh-box-padding-supercompact);
  --padding-right: var(--sh-box-padding-supercompact);
  --padding-bottom: var(--sh-box-padding-supercompact);
  --padding-left: var(--sh-box-padding-supercompact);
}

:host([padding=none]) {
  --container-padding: var(--sh-box-padding-none);
  --padding-top: var(--sh-box-padding-none);
  --padding-right: var(--sh-box-padding-none);
  --padding-bottom: var(--sh-box-padding-none);
  --padding-left: var(--sh-box-padding-none);
}

/* Handling named padding values */
:host([padding-top=wide]) {
  --padding-top: var(--sh-box-padding-wide);
}

:host([padding-top=compact]) {
  --padding-top: var(--sh-box-padding-compact);
}

:host([padding-top=supercompact]) {
  --padding-top: var(--sh-box-padding-supercompact);
}

:host([padding-top=none]) {
  --padding-top: var(--sh-box-padding-none);
}

:host([padding-right=wide]) {
  --padding-right: var(--sh-box-padding-wide);
}

:host([padding-right=compact]) {
  --padding-right: var(--sh-box-padding-compact);
}

:host([padding-right=supercompact]) {
  --padding-right: var(--sh-box-padding-supercompact);
}

:host([padding-right=none]) {
  --padding-right: var(--sh-box-padding-none);
}

:host([padding-bottom=wide]) {
  --padding-bottom: var(--sh-box-padding-wide);
}

:host([padding-bottom=compact]) {
  --padding-bottom: var(--sh-box-padding-compact);
}

:host([padding-bottom=supercompact]) {
  --padding-bottom: var(--sh-box-padding-supercompact);
}

:host([padding-bottom=none]) {
  --padding-bottom: var(--sh-box-padding-none);
}

:host([padding-left=wide]) {
  --padding-left: var(--sh-box-padding-wide);
}

:host([padding-left=compact]) {
  --padding-left: var(--sh-box-padding-compact);
}

:host([padding-left=supercompact]) {
  --padding-left: var(--sh-box-padding-supercompact);
}

:host([padding-left=none]) {
  --padding-left: var(--sh-box-padding-none);
}

:host(.vertical:not(:last-of-type)) {
  border-right: none;
  border-bottom: var(--content-gap, 0px) solid var(--sh-box-divider-color);
}

:host(.horizontal:not(:last-of-type)) {
  border-bottom: none;
  border-right: var(--content-gap, 0px) solid var(--sh-box-divider-color);
}`;