// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-menu-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-menu-item-item-height=2.5rem] - Height of the menu-item
   */
  --sh-menu-item-item-height: 2.5rem;
  /* 
   * @cssprop [--sh-menu-item-label-line-height=1.5rem] - Line height of the menu item label
   */
  --sh-menu-item-label-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-menu-item-item-padding=0.25rem 0] - padding of the menu item
   */
  --sh-menu-item-item-padding: 0.25rem 0;
  /* 
   * @cssprop [--sh-menu-item-item-padding-hover=0.25rem 0.5rem] - padding of the menu item on hover
   */
  --sh-menu-item-item-padding-hover: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-item-margin-hover=0 -0.5rem] - margin of the menu item on hover
   */
  --sh-menu-item-item-margin-hover: 0 -0.5rem;
  /* 
   * @cssprop [--sh-menu-item-item-width=calc(100% + 1rem)] - width of the item on hover or active
   */
  --sh-menu-item-item-width: calc(100% + 1rem);
  /* 
   * @cssprop [--sh-menu-item-icon-right-margin=0.5rem] - right margin of the icon
   */
  --sh-menu-item-icon-right-margin: 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-vertical-item-padding=0.5rem] - padding of the vertical menu item
   */
  --sh-menu-item-vertical-item-padding: 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-vertical-icon-margin=0rem auto 0.5rem] - margin of the vertical menu item icon
   */
  --sh-menu-item-vertical-icon-margin: 0rem auto 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-chapter-wrapper-margin-left=3.5rem] - left margin of the chapter wrapper
   */
  --sh-menu-item-chapter-wrapper-margin-left: 3.5rem;
  /* 
   * @cssprop [--sh-menu-item-chapter-arrow-margin-right=0.5rem] - right margin of the chapter arrow icon
   */
  --sh-menu-item-chapter-arrow-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-chapter-sibling-margin-left=2rem] - left margin of the chapter sibling
   */
  --sh-menu-item-chapter-sibling-margin-left: 2rem;
  /* 
   * @cssprop [--sh-menu-item-chapter-sibling-margin-left-hover=1.5rem] - left margin of the chapter sibling on hover
   */
  --sh-menu-item-chapter-sibling-margin-left-hover: 1.5rem;
  /* 
   * @cssprop [--sh-menu-item-functions-slot-margin=0.25rem 0rem 0rem -1.75rem] - margin of the elements inside functions slot
   */
  --sh-menu-item-functions-slot-margin: 0.25rem 0rem 0rem -1.75rem;
  /* 
   * @cssprop [--sh-menu-item-icon-size=1.5rem] - size of the icon
   */
  --sh-menu-item-icon-size: 1.5rem;
  /* 
   * @cssprop [--sh-menu-item-button-icon-right-xs=0rem] - right position of icon button in function slot in xs size
   */
  --sh-menu-item-button-icon-right-xs: 0rem;
  /* 
   * @cssprop [--sh-menu-item-button-icon-right-s=0rem] - right position of icon button in function slot in s size
   */
  --sh-menu-item-button-icon-right-s: 0rem;
  /* 
   * @cssprop [--sh-menu-item-button-icon-right-m=0rem] - right position of icon button in function slot in m size
   */
  --sh-menu-item-button-icon-right-m: 0rem;
  /* 
   * @cssprop [--sh-menu-item-button-icon-margin-left-xs=0.5rem] - margin-left of button icon in xs size of icon
   */
  --sh-menu-item-button-icon-margin-left-xs: 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-button-icon-margin-left-s=0.5rem] - margin-left of button icon in s size of icon
   */
  --sh-menu-item-button-icon-margin-left-s: 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-button-icon-margin-left-m=0.5rem] - margin-left of button icon in m size of icon
   */
  --sh-menu-item-button-icon-margin-left-m: 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-button-icon-margin-left-l=0.5rem] - margin-left of button icon in l size of icon
   */
  --sh-menu-item-button-icon-margin-left-l: 0.5rem;
  /* 
   * @cssprop [--sh-menu-item-border-radius=var(--sh-radius-control)] - border-radius of the menu-item
   */
  --sh-menu-item-border-radius: var(--sh-radius-control);
}

:host {
  width: 100%;
  outline: none;
  display: block;
  height: fit-content;
}

:host([icon]:not([checkbox]):not([vertical-alignment]):not(:focus-visible)) .menu-item-wrapper {
  padding: var(--sh-menu-item-item-padding);
  min-height: var(--sh-menu-item-item-height);
}

:host([icon]:not([checkbox]):not([vertical-alignment])) .menu-item-wrapper[active] {
  padding: var(--sh-menu-item-item-padding-hover);
}

.vertical-item-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

:host([vertical-alignment]) .vertical-item-wrapper {
  display: block;
  margin: auto;
}

:host([vertical-alignment]) #checkbox,
:host([vertical-alignment]) #menu-item-icon {
  margin: var(--sh-menu-item-vertical-icon-margin);
}

:host([vertical-alignment]) .menu-item-label {
  text-align: center;
  line-height: var(--sh-menu-item-label-line-height);
}

:host(:not(.touch-device):not([active])[vertical-alignment]) .menu-item-wrapper:hover,
:host([vertical-alignment]) .menu-item-wrapper[active],
:host([vertical-alignment]) .menu-item-wrapper {
  width: 100%;
  margin: 0rem;
  padding: var(--sh-menu-item-vertical-item-padding);
}

:host(:not([label-rows="1"])) #checkbox,
:host(:not([label-rows="1"])) #menu-item-icon,
:host(:not([label-rows="1"])) ::slotted(*[slot=functions]),
:host([vertical-alignment]) ::slotted(*[slot=functions]) {
  align-self: flex-start;
}

.menu-item-wrapper {
  height: fit-content;
  height: -ms-fit-content;
  height: -moz-fit-content;
  min-height: var(--sh-menu-item-item-height);
  width: 100%;
  box-sizing: border-box;
  padding: var(--sh-menu-item-item-padding);
  border-radius: var(--sh-menu-item-border-radius);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  outline: none;
  align-items: center;
  text-decoration: none;
}

:host(:focus-visible:not([disabled]):not([vertical-alignment])) .menu-item-wrapper {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
  padding: var(--sh-menu-item-item-padding-hover);
  margin: var(--sh-menu-item-item-margin-hover);
  width: var(--sh-menu-item-item-width);
}

:host(:focus-visible:not([disabled])[vertical-alignment]) .menu-item-wrapper {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
}

:host(:focus-visible:not([disabled]):not([chapter]).chapter-sibling) .menu-item-wrapper {
  padding: var(--sh-menu-item-item-padding-hover);
  margin: var(--sh-menu-item-item-margin-hover);
  margin-left: calc(var(--sh-menu-item-chapter-sibling-margin-left) - var(--sh-menu-item-chapter-arrow-margin-right)) !important;
  width: var(--sh-menu-item-item-width);
}

:host(:focus-visible:not([disabled])[chapter]) {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
  padding: 0 var(--sh-menu-item-chapter-arrow-margin-right) 0 0;
  margin: var(--sh-menu-item-item-margin-hover);
}
:host(:focus-visible:not([disabled])[chapter]) .menu-item-wrapper {
  outline: none;
}

:host(:focus-visible:not([disabled])[chapter].chapter-sibling) {
  padding: 0 var(--sh-menu-item-chapter-arrow-margin-right);
}

:host(.chapter-sibling) .menu-item-wrapper {
  overflow: hidden;
}

.functions-wrapper {
  display: flex;
  align-items: center;
  height: var(--sh-menu-item-icon-size);
}

/* active */
.menu-item-wrapper[active] {
  padding: var(--sh-menu-item-item-padding-hover);
  margin: var(--sh-menu-item-item-margin-hover);
  width: var(--sh-menu-item-item-width);
  background: rgba(var(--ui-1), var(--opacity-6));
}

.menu-item-wrapper.item-focused {
  padding: var(--sh-menu-item-item-padding-hover);
  margin: var(--sh-menu-item-item-margin-hover);
  width: var(--sh-menu-item-item-width);
}

:host(:not([chapter]).chapter-sibling) .item-wrapper > .menu-item-wrapper.item-focused {
  padding: var(--sh-menu-item-item-padding-hover);
  margin: 0 -0.5rem 0 1.5rem;
  width: var(--sh-menu-item-item-width);
}

:host([icon]:not([checkbox]):not([vertical-alignment])) .item-wrapper > .menu-item-wrapper.item-focused,
:host([icon]:not([checkbox]):not([vertical-alignment])) .item-wrapper > .menu-item-wrapper.item-focused:hover,
:host([icon]:not([checkbox]):not(.touch-device):not([vertical-alignment])) .item-wrapper > .menu-item-wrapper.hover {
  padding: var(--sh-menu-item-item-padding-hover) !important;
}

/* disabled */
:host([disabled]) {
  pointer-events: none;
  cursor: none;
}

.menu-item-label {
  font: var(--body-1);
  line-height: var(--sh-menu-item-label-line-height);
  font-weight: normal;
  color: var(--text-primary);
  flex: 1;
  transition: var(--transition-time) all ease-in-out;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  word-break: break-all;
  visibility: visible;
}

sh-icon#menu-item-icon {
  position: relative;
  color: var(--text-primary);
  margin-right: var(--sh-menu-item-icon-right-margin);
}

:host([vertical-alignment]) ::slotted(*[slot=functions]) {
  margin: var(--sh-menu-item-functions-slot-margin);
}

:host(:not([vertical-alignment])) ::slotted(*[slot=functions]) {
  margin-left: 0.5rem;
}

:host(:not([vertical-alignment])[label-rows="1"]) ::slotted(*[slot=functions]) {
  position: relative;
  align-self: center;
  flex-shrink: 0;
}

:host(:not([vertical-alignment])[label-rows="1"]) ::slotted([slot=functions][button][size=xs]) {
  right: var(--sh-menu-item-button-icon-right-xs);
  margin-left: var(--sh-menu-item-button-icon-margin-left-xs);
}

:host(:not([vertical-alignment])[label-rows="1"]) ::slotted([slot=functions][button][size=s]) {
  right: var(--sh-menu-item-button-icon-right-s);
  margin-left: var(--sh-menu-item-button-icon-margin-left-s);
}

:host(:not([vertical-alignment])[label-rows="1"]) ::slotted([slot=functions][button][size=m]) {
  right: var(--sh-menu-item-button-icon-right-m);
  margin-left: var(--sh-menu-item-button-icon-margin-left-m);
}

.item-wrapper {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
}

/* chapter menu */
.chapter-wrapper {
  transition: calc(var(--transition-time) * 0.5) all ease-in-out;
}

.chapter-wrapper > ::slotted([chapter]) {
  margin-left: calc(-1 * (var(--sh-icon-size-s) + var(--sh-menu-item-chapter-arrow-margin-right)));
  width: calc(100% + var(--sh-icon-size-s) + var(--sh-menu-item-chapter-arrow-margin-right));
}

.chapter-wrapper > ::slotted(*) {
  visibility: hidden;
}

:host([expanded]) .chapter-wrapper > ::slotted(*) {
  visibility: visible;
}

:host(:not([expanded])) > .chapter-wrapper {
  max-height: 0rem;
  overflow: hidden;
  pointer-events: none;
  transform: translateY(-0.5rem);
  opacity: 0;
}

:host([chapter]) > .chapter-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: var(--sh-menu-item-chapter-wrapper-margin-left);
}

.arrow-icon {
  margin-right: var(--sh-menu-item-chapter-arrow-margin-right);
}

:host([chapter][expanded]) > .item-wrapper > .arrow-icon {
  transform: rotate(90deg);
}

:host(:not([chapter]).chapter-sibling) > .item-wrapper > .menu-item-wrapper {
  margin-left: var(--sh-menu-item-chapter-sibling-margin-left);
}

/* hover */
/* CSS rules with .hover class are needed for use in dropdown component */
:host(:not(.touch-device):not([active])) .menu-item-wrapper:hover,
:host(:not(.touch-device):not([active])) .menu-item-wrapper.hover,
:host(.hover:not(.touch-device):not([active])) .menu-item-wrapper {
  background: rgba(var(--ui-1), var(--opacity-7));
  padding: var(--sh-menu-item-item-padding-hover);
  margin: var(--sh-menu-item-item-margin-hover);
  width: var(--sh-menu-item-item-width);
}

:host(.focus:not(.touch-device)) .menu-item-wrapper {
  outline: var(--focus-outline);
}

:host([icon]:not([checkbox]):not(.touch-device):not([active]):not([vertical-alignment])) .menu-item-wrapper:hover,
:host([icon]:not([checkbox]):not(.touch-device):not([active])) .menu-item-wrapper.hover,
:host(.hover[icon]:not([checkbox]):not(.touch-device):not([active])) .menu-item-wrapper {
  background: rgba(var(--ui-1), var(--opacity-7));
  padding: var(--sh-menu-item-item-padding-hover);
}

:host(.chapter-sibling:not([chapter]):not(.touch-device)) .menu-item-wrapper:hover,
:host(.chapter-sibling:not([chapter]):not(.touch-device)) .menu-item-wrapper.hover,
:host(.hover.chapter-sibling:not([chapter]):not(.touch-device)) .menu-item-wrapper,
:host(.chapter-sibling:not([chapter])[active]) .menu-item-wrapper {
  margin-left: var(--sh-menu-item-chapter-sibling-margin-left-hover);
}

:host(:not(.touch-device):not([active])) .menu-item-wrapper[disabled] {
  background: transparent;
}

:host([disabled]) .menu-item-label {
  color: var(--text-disabled);
}

:host([disabled]) #menu-item-icon {
  opacity: 0.2;
}

#checkbox {
  width: fit-content;
  width: -moz-fit-content;
}`;