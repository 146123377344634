// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

import { CSSResultGroup, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { SHDivider } from '../../sh-divider/src/sh-divider.js';
import { SHIcon } from '../../sh-icon/src/sh-icon.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { decimalSeparator } from '../../utils/decimal-separator.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import numericKeypadStyles from './sh-numeric-keypad.lit.scss.js';

/**
 * @fires key-press - *hide Fired when the keypad key is press(clicked/enter button pressed) on keypad key.
 * @fires closing - *hide Fired when the keypad key first key and last key is tabbed.
 */

@customElement('sh-numeric-keypad')
export class SHNumericKeypad extends FocusBlurMixin(LitElement) {
  /**Indicates whether the keypad is open or closed. Can also set it to true / false to force open/close the keypad. */
  /** @ignore */
  @property({ type: Boolean, reflect: true })
  visible?: boolean;

  /**If set to true, the keypad buttons will be disabled. */
  /** @ignore */
  @property({ type: Boolean, reflect: true })
  condensed?: boolean;

  /** Defines the id of the control for which the keypad will be shown */
  /** @ignore */
  @property({ type: String, reflect: true })
  target?: string;

  /** @ignore */
  @property({ type: String, reflect: true })
  decimals?: string;

  /**If set to true, the keypad buttons will be disabled. */
  /** @ignore */
  @property({ type: Boolean, reflect: true })
  disabled?: boolean;

  /**This property is auto-set or cleared initially when the component is loaded. If the browser's language follows European number conventions, then this property is auto-set to true. Else it is auto-cleared. This property can also be forced set to true to force it to follow European number conventions. Also, this property can be forced set to false to follow English number conventions. This would be particularly useful when used in systems like Android where changing the browser language is not so easy. */
  /** @ignore */
  @property({ type: Boolean, reflect: true, attribute: 'european-number-convention' })
  europeanNumberConvention?: boolean;

  /** @ignore */
  decimalSeparator = '.';

  /** @ignore */
  isKeypadButtonFocused = false;

  static override get styles(): CSSResultGroup {
    return [sharedStyles, numericKeypadStyles];
  }

  override render(): TemplateResult {
    return html`
      <div class="functions-wrapper">
        <slot name="functions" id="functionsSlot" @slotchange=${this.addMargins}></slot>
      </div>
      <div class="keypad-main-frame">
        ${this.createKeypadContent()}
        <div
          class="circle-buttons erase-button dont-disable"
          tabindex="0"
          @click="${(e: MouseEvent) => {
            this.circleButtonClick(e);
          }}"
          @keyup="${(e: KeyboardEvent) => {
            this.EnterKeyIsPressedOnButton(e, e.target as HTMLDivElement);
          }}"
          @keydown="${(e: KeyboardEvent) => {
            this.keyDownOnEraseButton(e);
          }}"
        >
          <sh-icon icon="delete-number" tabindex="-1"></sh-icon>
        </div>
      </div>
      <sh-divider class="divider" spacing="m"></sh-divider>
      <div class="footer-wrapper">
        <slot
          name="footer"
          id="footer"
          @slotchange=${this.hideOrDisplayDividerBasedOnFooter}
        ></slot>
      </div>
    `;
  }

  override connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  override update(changedProperties: Map<string, unknown>): void {
    if (changedProperties.has('europeanNumberConvention')) {
      this.europeanNumberConventionObserver();
    }
    super.update(changedProperties);
  }
  override updated(changedProperties: Map<string, unknown>): void {
    const listOfProperties = [
      'visible',
      'condensed',
      'target',
      'decimals',
      'disabled',
      'europeanNumberConvention',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }

  private createKeypadContent(): TemplateResult<1>[] {
    const keypadNumbersArray = [];
    const valueArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', this.decimalSeparator, '0'];
    for (let index = 0; index < valueArray.length; index++) {
      keypadNumbersArray.push(html`
        <div
          value=${valueArray[index]}
          class="circle-buttons ${valueArray[index] === this.decimalSeparator
            ? 'dont-disable decimals-point'
            : ''}"
          tabindex=${this.disabled && valueArray[index] !== this.decimalSeparator ? -1 : 0}
          @click="${(e: MouseEvent) => {
            this.circleButtonClick(e);
          }}"
          @keyup="${(e: KeyboardEvent) => {
            this.EnterKeyIsPressedOnButton(e, e.target as HTMLDivElement);
          }}"
          @keydown="${(e: KeyboardEvent) => {
            if (valueArray[index] == '1') {
              this.keyDownOnNumberOne(e);
            }
          }}"
        >
          ${valueArray[index]}
        </div>
      `);
    }
    return keypadNumbersArray;
  }

  private circleButtonClick(e: MouseEvent) {
    const shIconTag = 'SH-ICON';
    const isEraseButton =
      (e.target as SHIcon).tagName === shIconTag ||
      (e.target as HTMLDivElement).classList.contains('erase-button');
    const detail = {
      isEraseButton: isEraseButton,
      keyValueString: isEraseButton ? '' : (e.target as HTMLDivElement).getAttribute('value'),
      changeFocus: !this.isKeypadButtonFocused,
    };
    dispatchCustomEvent(this, 'key-press', detail);
    this.isKeypadButtonFocused = false;
  }

  private keyDownOnEraseButton(e: KeyboardEvent) {
    if (e.code === 'Tab' && !e.shiftKey) {
      const checkfooterNodes = true;
      if (!this.slotNodesPresent(checkfooterNodes)) {
        dispatchCustomEvent(this, 'closing', null);
      }
    }
  }

  private keyDownOnNumberOne(e: KeyboardEvent) {
    if (e.code === 'Tab' && e.shiftKey) {
      const checkfooterNodes = false;
      if (!this.slotNodesPresent(checkfooterNodes)) {
        dispatchCustomEvent(this, 'closing', null);
      }
    }
  }

  private slotNodesPresent(checkfooterNodes: boolean) {
    if (checkfooterNodes) {
      const footerSlot = this.shadowRoot?.querySelector('[name="footer"]') as HTMLSlotElement;
      if (footerSlot !== null) {
        const footerNodes = footerSlot?.assignedNodes({
          flatten: true,
        });
        if (footerNodes.length !== 0) {
          return true;
        }
      }
    } else {
      const functionSlot = this.shadowRoot?.querySelector('[name="functions"]') as HTMLSlotElement;
      if (functionSlot !== null) {
        const functionNodes = functionSlot.assignedNodes({
          flatten: true,
        });
        if (functionNodes.length !== 0) {
          return true;
        }
      }
    }
    return false;
  }

  private EnterKeyIsPressedOnButton(e: KeyboardEvent, element: HTMLDivElement) {
    const shIconTag = 'SH-ICON';
    if (
      (e.target as HTMLElement).tagName !== shIconTag &&
      (((e.code === 'Enter' || e.code === 'NumpadEnter') && !this.disabled) ||
        ((e.code === 'Enter' || e.code === 'NumpadEnter') &&
          element.classList.contains('dont-disable')))
    ) {
      element.focus();
      this.isKeypadButtonFocused = true;
      element.click();
    }
  }

  private hideOrDisplayDividerBasedOnFooter() {
    const footerNodes = (
      this.shadowRoot?.querySelector('#footer') as HTMLSlotElement
    )?.assignedNodes({
      flatten: true,
    });
    if (footerNodes.length !== 0) {
      (this.shadowRoot?.querySelector('.divider') as SHDivider).style.display = 'initial';
    } else {
      (this.shadowRoot?.querySelector('.divider') as SHDivider).style.display = 'none';
    }
  }
  private europeanNumberConventionObserver() {
    if (typeof this.europeanNumberConvention !== 'boolean') {
      this.decimalSeparator = decimalSeparator;
    } else {
      this.decimalSeparator = this.europeanNumberConvention ? ',' : '.';
    }
  }
  private addMargins() {
    const functionsWrapper = this.shadowRoot?.querySelector('.functions-wrapper') as HTMLDivElement;
    const functionNodes = (
      this.shadowRoot?.querySelector('#functionsSlot') as HTMLSlotElement
    ).assignedNodes({
      flatten: true,
    });
    functionsWrapper.style.marginBottom = functionNodes.length !== 0 ? '1rem' : '0rem';
  }
}
