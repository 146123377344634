// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { sharedStyles } from '../../styles';
import { generateDecadeData } from '../../utils/decade-data-generator.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { MultiItemFocusBlurMixin } from '../../utils/multi-item-focus-blur-mixin.js';
import privateYearSelectorStyles from './sh-private-year-selector.lit.scss.js';
/**
 * @fires year-clicked - Dispatched when clicked on a year in a decade. The clicked year is passed as part of detail object of event.
 */
let SHPrivateYearSelector = class SHPrivateYearSelector extends MultiItemFocusBlurMixin(
  ShuiLitElement
) {
  constructor() {
    super(...arguments);
    /** The selected date for this picker.*/
    this.selectedDate = null;
    /** The minimum date below which all dates will be disabled. `minDate` value should be of type Date.*/
    this.minDate = null;
    /** The maximum date above which all dates will be disabled. `maxDate` value should be of type Date.*/
    this.maxDate = null;
    /** @ignore */
    this.yearViewDatas = [];
  }
  static get styles() {
    return [super.styles, sharedStyles, privateYearSelectorStyles];
  }
  render() {
    return html`<div class="months">
      ${this.yearViewDatas.map((decadeData, decadeIndex) => {
        var _a, _b;
        return html`<div class="date-panel">
          <div class="header-area">
            ${decadeIndex === 0
              ? html`<sh-icon
                  icon="arrow-left-s"
                  size="m"
                  button
                  @click="${() => {
                    this.decade -= 10;
                    this.generateYearViewData();
                  }}"
                ></sh-icon>`
              : html`<div class="icon-dummy"></div>`}
            <div class="month-and-year-display">
              ${(_a = decadeData[1].date) === null || _a === void 0 ? void 0 : _a.getFullYear()} -
              ${(_b = decadeData[10].date) === null || _b === void 0 ? void 0 : _b.getFullYear()}
            </div>
            ${decadeIndex === this.yearViewDatas.length - 1
              ? html`<sh-icon
                  icon="arrow-right-s"
                  size="m"
                  button
                  @click="${() => {
                    this.decade += 10;
                    this.generateYearViewData();
                  }}"
                ></sh-icon>`
              : html`<div class="icon-dummy"></div>`}
          </div>
          <div class="date-grid">
            ${decadeData.map(
              (yearData, yearIndex) =>
                html`<span
                  class="${classMap(this.getComputedClasses(yearData, yearIndex))}"
                  @click="${() => {
                    this.yearClickedEvent.emit(
                      new CustomEvent('year-clicked', {
                        detail: { clickedYear: yearData.date.getFullYear() },
                      })
                    );
                  }}"
                  tabindex="${yearData.isDisabled ? '-1' : '0'}"
                >
                  ${yearData.date.getFullYear()}
                </span>`
            )}
          </div>
        </div> `;
      })}
    </div>`;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    } else {
      this.setAttribute('tabindex', '0');
    }
  }
  generateYearViewData() {
    let startdecade = this.decade;
    const yearViewDatas = [];
    for (let i = 0; i < this.months; i++) {
      yearViewDatas.push(
        generateDecadeData(
          startdecade,
          this.todaysDate,
          this.selectedDate,
          this.futureDateDisable,
          this.pastDateDisable,
          this.minDate,
          this.maxDate
        )
      );
      startdecade += 10;
    }
    this.yearViewDatas = yearViewDatas;
  }
  /**
   *
   * @param {YearDataContainer} yearObject
   */
  getComputedClasses(yearObject, yearIndex) {
    return {
      year: true,
      today: yearObject.isThisYear,
      selected: yearObject.isSelected,
      disabled: yearObject.isDisabled,
      'other-decade': yearIndex === 0 || yearIndex === 11,
      'focus-item': true,
    };
  }
  _enterKeyAction(focusedElement) {
    if (focusedElement !== this) {
      focusedElement.click();
    }
  }
};
__decorate([property({ type: Number })], SHPrivateYearSelector.prototype, 'months', void 0);
__decorate([property({ type: Number })], SHPrivateYearSelector.prototype, 'decade', void 0);
__decorate([property({ type: Object })], SHPrivateYearSelector.prototype, 'todaysDate', void 0);
__decorate([property({ type: Object })], SHPrivateYearSelector.prototype, 'selectedDate', void 0);
__decorate(
  [property({ type: Boolean })],
  SHPrivateYearSelector.prototype,
  'futureDateDisable',
  void 0
);
__decorate(
  [property({ type: Boolean })],
  SHPrivateYearSelector.prototype,
  'pastDateDisable',
  void 0
);
__decorate([property({ type: Object })], SHPrivateYearSelector.prototype, 'minDate', void 0);
__decorate([property({ type: Object })], SHPrivateYearSelector.prototype, 'maxDate', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHPrivateYearSelector.prototype,
  'responsive',
  void 0
);
__decorate([property({ type: Array })], SHPrivateYearSelector.prototype, 'yearViewDatas', void 0);
__decorate([event()], SHPrivateYearSelector.prototype, 'yearClickedEvent', void 0);
SHPrivateYearSelector = __decorate(
  [customElement('sh-private-year-selector')],
  SHPrivateYearSelector
);
export { SHPrivateYearSelector };
