import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { deviceIdentifier } from '../../utils/device-identifier';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin';
import accordionStyles from './sh-accordion.lit.scss.js';
export const AccordionListOfProperties = ['label', 'info', 'flat', 'expanded', 'icon', 'disabled'];
/**
 * @slot - The slot where the main content is rendered.
 * @slot header - Takes 'sh-text' as children. This slot is only provided for labels that need to be styled, e.g. text highlight. For that, the sh-text that goes in there should have the same type as the label, that is Header 2. No other property, such as 'icon' and 'information' can be used together with this slot.
 * @slot footer - Takes buttons as children.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires info-changed {InfoPropertyChangedEvent} - *hide emitted when the info property changes.
 * @fires icon-changed {IconPropertyChangedEvent} - *hide emitted when the icon property changes.
 * @fires flat-changed {FlatPropertyChangedEvent} - *hide emitted when the flat property changes.
 * @fires expanded-changed {ExpandedPropertyChangedEvent} - *hide emitted when the expanded property changes.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide emitted when the disabled property changes.
 */
let SHAccordion = class SHAccordion extends RefrainSpaceScrollMixin(ShuiLitElement) {
  constructor() {
    super(...arguments);
    this.emptyHeader = true;
    this.emptyFooter = true;
    /** The label of the accordion that will be shown at it's top. Mandatory property. */
    this.label = 'Accordion Label';
    /** Defines whether the accordion is open or closed. Can be set to true to be open by default. */
    this.expanded = false;
    /** If set to `true`, the accordion will have no background color nor elevation applied to it. */
    this.flat = false;
    /** If set to `true`, the accordion will be disabled. */
    this.disabled = false;
    /** If set to `true`, arrow will revert sides and will be positioned at the left. */
    this.arrowInvert = false;
  }
  static get styles() {
    return [accordionStyles];
  }
  firstUpdated() {
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  updated(changedProperties) {
    if (changedProperties.has('disabled')) {
      this.handleDisabled();
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, AccordionListOfProperties);
  }
  render() {
    return html`
      <div class="accordion-wrapper">
        <div
          class="header-wrapper"
          tabindex="${this.disabled ? '-1' : '0'}"
          @click="${() => this.handleExpand()}"
          @keyup="${(e) => this.handleHeaderWrapperKeyUp(e)}"
        >
          ${this.arrowInvert ? this.getArrowIcon() : nothing}
          ${this.icon && this.emptyHeader ? this.getIconWithEmptyHeader() : nothing}
          ${this.emptyHeader ? this.getEmptyHeader() : nothing}
          <div class="header-slot-wrapper" ?empty-header=${this.emptyHeader}>
            ${this.getHeaderSlot()}
          </div>
          ${!this.arrowInvert ? this.getArrowIcon() : nothing}
        </div>
        <div class="content-wrapper">
          <slot></slot>
          <div class="footer-wrapper" ?empty-footer=${this.emptyFooter}>
            <slot
              name="footer"
              class="footer"
              @slotchange="${(e) => this.footerSlotChange(e)}"
            ></slot>
          </div>
        </div>
      </div>
    `;
  }
  getHeaderSlot() {
    return html`<slot
      name="header"
      class="header"
      @slotchange="${(e) => {
        this.headerSlotChange(e);
      }}"
    ></slot>`;
  }
  getEmptyHeader() {
    return html` <sh-text size="header-2" class="accordion-label">${this.label}</sh-text>
      <sh-text size="body-1" color="secondary" class="accordion-info">${this.info}</sh-text>`;
  }
  getIconWithEmptyHeader() {
    return html` <sh-icon
      size="s"
      aria-hidden="true"
      .icon="${this.icon}"
      .color="${this.iconColor}"
      class="accordion-icon"
      ?disabled="${this.disabled}"
    ></sh-icon>`;
  }
  footerSlotChange(e) {
    if (e.target instanceof HTMLSlotElement) {
      this.updateSlotFlag(e.target);
    }
  }
  headerSlotChange(e) {
    if (e.target instanceof HTMLSlotElement) {
      this.updateHeaderSlotFlag(e.target);
    }
  }
  handleHeaderWrapperKeyUp(e) {
    if (
      keyboardInteraction(e, KEYCODE.SPACE) ||
      keyboardInteraction(e, KEYCODE.ENTER) ||
      e.code === KEYCODE.NUMPAD_ENTER.code
    ) {
      if (e.target instanceof HTMLElement) {
        e.target.click();
      }
    }
  }
  handleExpand() {
    this.expanded = !this.expanded;
  }
  getArrowIcon() {
    return html`<sh-icon
      aria-hidden="true"
      class="expand accordion-expand"
      icon="arrow-down-s"
      button
      tabindex="-1"
      ?disabled="${this.disabled}"
    ></sh-icon>`;
  }
  updateHeaderSlotFlag(slot) {
    const headerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyHeader = headerNodes === 0;
  }
  updateSlotFlag(slot) {
    const footerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = footerNodes === 0;
  }
  handleDisabled() {
    if (this.disabled) {
      this.expanded = false;
    }
  }
};
__decorate([event()], SHAccordion.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHAccordion.prototype, 'infoChangedEvent', void 0);
__decorate([event()], SHAccordion.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHAccordion.prototype, 'flatChangedEvent', void 0);
__decorate([event()], SHAccordion.prototype, 'expandedChangedEvent', void 0);
__decorate([event()], SHAccordion.prototype, 'disabledChangedEvent', void 0);
__decorate([state()], SHAccordion.prototype, 'emptyHeader', void 0);
__decorate([state()], SHAccordion.prototype, 'emptyFooter', void 0);
__decorate([property({ type: String, reflect: true })], SHAccordion.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHAccordion.prototype, 'info', void 0);
__decorate([property({ type: String, reflect: true })], SHAccordion.prototype, 'icon', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHAccordion.prototype,
  'iconColor',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'label-rows' })],
  SHAccordion.prototype,
  'labelRows',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHAccordion.prototype, 'expanded', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHAccordion.prototype, 'flat', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHAccordion.prototype, 'disabled', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'arrow-invert' })],
  SHAccordion.prototype,
  'arrowInvert',
  void 0
);
SHAccordion = __decorate([customElement('sh-accordion')], SHAccordion);
export { SHAccordion };
