// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { css } from 'lit';
import { property } from 'lit/decorators.js';
import { keyboardInteraction } from './keyboardInteraction';
import { KEYCODE } from './keycode';
/**
 * An advanced version of the focus-blur mixin.
 *
 * Adds item-focused class to multiple shadow-root
 * elements with class focus-item on focusing
 *
 * Adds class on host component by default
 *
 * set this.setHostFocus = false to prevent
 * adding class to host.
 *
 * Also has support for enter key press where
 * the deriving component can override the
 * _enterKeyAction function to implement what
 * happens on pressing enter on the focus-item.
 */
export const MultiItemFocusBlurMixin = (superClass) => {
  class MultiItemFocusBlurMixinExtends extends superClass {
    constructor() {
      super(...arguments);
      /**
       * Defines if adding class to host (true) or not (false)
       */
      this.setHostFocus = true;
    }
    /**
     * TODO: Add description to the method
     */
    static get styles() {
      return css`
        :host([set-host-focus]:not(.item-focused)),
        .focus-item:not(.item-focused) {
          outline: none;
        }
        :host(.item-focused:not(.touch-device)),
        :host(:not(.touch-device)) *.item-focused {
          outline: var(--focus-outline) !important;
          outline-offset: -0.125rem;
        }
      `;
    }
    /**
     * TODO: Add description to the method
     */
    connectedCallback() {
      var _a;
      super.connectedCallback();
      this.keyUpListener = this.keyUpCallBack.bind(this);
      this.shadowFocusOutListener = this.shadowFocusOutCallBack.bind(this);
      this.addEventListener('keyup', this.keyUpListener);
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.addEventListener('focusout', this.shadowFocusOutListener);
      if (this.setHostFocus) {
        this.focusOutListener = this.focusOutCallBack.bind(this);
        this.addEventListener('focusout', this.focusOutListener);
      }
    }
    /**
     * TODO: Add description to the method
     */
    disconnectedCallback() {
      var _a;
      this.removeEventListener('keyup', this.keyUpListener);
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.removeEventListener('focusout', this.shadowFocusOutListener);
      this.removeEventListener('focusout', this.focusOutListener);
      super.disconnectedCallback();
    }
    /**
     * TODO: Add description to the method
     */
    keyUpCallBack(e) {
      var _a, _b;
      const pressedEnter = keyboardInteraction(e, KEYCODE.ENTER);
      const pressedTab = keyboardInteraction(e, KEYCODE.TAB);
      const shadowRootActiveElement =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.activeElement;
      let focusedElement;
      if (pressedEnter || pressedTab) {
        if (shadowRootActiveElement) {
          const focusItems =
            (_b = this.shadowRoot) === null || _b === void 0
              ? void 0
              : _b.querySelectorAll('.focus-item');
          focusedElement = Array.from(focusItems).find((el) => e.composedPath()[0] === el);
          this.classList.remove('item-focused');
        } else if (this.setHostFocus && e.composedPath()[0] === this) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias -- Here's a description about why this configuration is necessary.
          focusedElement = this;
        }
        if (focusedElement && pressedEnter) {
          this._enterKeyAction(focusedElement);
        } else if (focusedElement && pressedTab) {
          focusedElement.classList.add('item-focused');
        }
      }
    }
    /**
     * TODO: Add description to the method
     */
    shadowFocusOutCallBack() {
      var _a;
      const focusedItem =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.item-focused');
      if (focusedItem) {
        focusedItem.classList.remove('item-focused');
      }
    }
    /**
     * TODO: Add description to the method
     */
    focusOutCallBack() {
      this.classList.remove('item-focused');
    }
    /**
     * TODO: Add description to the method
     */
    _enterKeyAction(focusedElement) {
      if (focusedElement) return;
    }
  }
  __decorate(
    [property({ type: Boolean, reflect: true, attribute: 'set-host-focus' })],
    MultiItemFocusBlurMixinExtends.prototype,
    'setHostFocus',
    void 0
  );
  return MultiItemFocusBlurMixinExtends;
};
