// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import worklineGroupStyles from '../src/sh-workline-group.lit.scss.js';
let SHWorklineGroup = class SHWorklineGroup extends LitElement {
  static get styles() {
    return [worklineGroupStyles];
  }
  render() {
    return html`<slot></slot>`;
  }
};
SHWorklineGroup = __decorate([customElement('sh-workline-group')], SHWorklineGroup);
export { SHWorklineGroup };
