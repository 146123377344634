// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

/**
 * ###
 * ___
 * Function that generates time values in Hours ( 12-Hour and  24-Hour) , Minutes and Seconds
 * ___
 * #### How to use this function ?
 * Import like this in the component:
 *
 * ```js
 * import { TwelveHourValues , TwentyFourHourValues, MinuteValues , SecondValues , AmPmValues } from './utils/time-value-generator'
 * ```
 * Then use the function like the following:
 *
 * ```js
 * var hourValues = TwelveHourValues();
 * ```
 *
 */
import { prependZero } from './number-formatter';

export const TwelveHourValues = () => {
  const hourValues = [];
  for (let i = 1; i <= 12; i++) {
    hourValues.push(prependZero(parseInt(i.toString(), 10)).toString());
  }
  return hourValues;
};

export const TwentyFourHourValues = () => {
  const hourValues = [];
  for (let i = 0; i < 24; i++) {
    hourValues.push(prependZero(parseInt(i.toString(), 10)).toString());
  }
  return hourValues;
};

export const MinuteValues = () => {
  const minuteValues = [];
  for (let i = 0; i < 60; i++) {
    minuteValues.push(prependZero(parseInt(i.toString(), 10)).toString());
  }

  return minuteValues;
};

export const SecondValues = () => {
  const secondValues = [];
  for (let i = 0; i < 60; i++) {
    secondValues.push(prependZero(parseInt(i.toString(), 10)).toString());
  }

  return secondValues;
};

export const AmPmValues = () => {
  return ['AM', 'PM'];
};
