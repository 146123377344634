import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import drawerStyles from './sh-drawer.lit.scss.js';
export const drawerListOfProperties = ['visible', 'label', 'position', 'sticky', 'drawerWidth'];
/**
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires visible-changed {VisiblePropertyChangedEvent} - *hide emitted when the visible property changes.
 * @fires drawer-width-changed {DrawerWidthPropertyChangedEvent} - *hide emitted when the drawerWidth property changes.
 * @fires sticky-changed {StickyPropertyChangedEvent} - *hide emitted when the sticky property changes.
 * @fires position-changed {PositionPropertyChangedEvent} - *hide emitted when the position property changes.
 * @fires drawer-closed {drawerClosedEvent} - *hide emitted when drawer closes.
 */
let SHDrawer = class SHDrawer extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** Defines the position of the drawer on the page. Possible values are `left`, `right`, `top` and `bottom`. */
    this.position = 'left';
    /** @ignore */
    this.emptyHeader = true;
    /** @ignore */
    this.emptyFooter = true;
    /** @ignore */
    this.emptyFunction = true;
    /** @ignore */
    this.documentKeyupListenerRef = this.handleDocumentKeyup.bind(this);
  }
  static get styles() {
    return [sharedStyles, drawerStyles];
  }
  render() {
    return html`
      <div
        class="drawer-wrapper"
        id="content"
        ?empty-footer="${this.emptyFooter}"
        ?empty-header="${this.emptyHeader}"
        ?empty-function="${this.emptyFunction}"
        style="--drawer-width:${this.drawerWidth ? this.drawerWidth : `var(--sh-drawer-width)`}"
      >
        <div class="label-wrapper">
          ${this.label
            ? html`<div class="drawer-label">${this.label}</div>`
            : html`<div class="drawer-label"></div>`}
          <div class="functions-wrapper">
            <slot
              name="functions"
              id="functions"
              @slotchange="${(e) => this.updateSlotFlag(e.target, 'emptyFunction')}"
            ></slot>
          </div>
        </div>
        <div class="header-wrapper">
          <slot
            name="header"
            id="header"
            @slotchange="${(e) => this.updateSlotFlag(e.target, 'emptyHeader')}"
          ></slot>
        </div>
        <div class="body-wrapper">
          <slot id="body"></slot>
        </div>
        <div class="footer-wrapper">
          <slot
            name="footer"
            id="footer"
            @slotchange="${(e) => this.updateSlotFlag(e.target, 'emptyFooter')}"
          ></slot>
        </div>
      </div>
    `;
  }
  update(changedProperties) {
    if (changedProperties.has('visible')) {
      this.drawerStatus();
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, drawerListOfProperties);
  }
  firstUpdated(changedProperties) {
    var _a, _b;
    super.firstUpdated(changedProperties);
    this.addEventListener('mousedown', () => {
      if (!this.sticky) {
        this.closeDrawer();
      }
    });
    (_b =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#content')) ===
      null || _b === void 0
      ? void 0
      : _b.addEventListener('mousedown', function (e) {
          e.stopPropagation();
        });
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
  }
  disconnectedCallback() {
    document.removeEventListener('keyup', this.documentKeyupListenerRef);
    super.disconnectedCallback();
  }
  updateSlotFlag(slot, emptySlotFlag) {
    const numberOfNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this[emptySlotFlag] = numberOfNodes === 0;
  }
  closeDrawer() {
    this.visible = false;
    // fire event on closing
    this.drawerClosedEvent.emit(new CustomEvent('drawer-closed'));
    console.warn(
      `The drawer-closed event is a legacy event of sh-drawer component and will be deprecated soon.\nPlease use the visible-changed event to observe changes in visible property`
    );
  }
  drawerStatus() {
    if (this.visible) {
      //focus inside the drawer to move out of natural tab order
      this.focus();
      this.style.outline = '0';
      document.addEventListener('keyup', this.documentKeyupListenerRef);
    } else {
      document.removeEventListener('keyup', this.documentKeyupListenerRef);
    }
  }
  handleDocumentKeyup(e) {
    /**
     * Preventing sticky drawer from closing when pressed on Escape
     * https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/26790
     */
    if (keyboardInteraction(e, KEYCODE.ESCAPE) && !this.sticky) {
      this.closeDrawer();
    }
  }
};
__decorate([event()], SHDrawer.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHDrawer.prototype, 'visibleChangedEvent', void 0);
__decorate([event()], SHDrawer.prototype, 'drawerWidthChangedEvent', void 0);
__decorate([event()], SHDrawer.prototype, 'stickyChangedEvent', void 0);
__decorate([event()], SHDrawer.prototype, 'positionChangedEvent', void 0);
__decorate([event()], SHDrawer.prototype, 'drawerClosedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHDrawer.prototype, 'label', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDrawer.prototype, 'visible', void 0);
__decorate([property({ type: String, reflect: true })], SHDrawer.prototype, 'position', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDrawer.prototype, 'sticky', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'drawer-width' })],
  SHDrawer.prototype,
  'drawerWidth',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHDrawer.prototype, 'emptyHeader', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDrawer.prototype, 'emptyFooter', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHDrawer.prototype,
  'emptyFunction',
  void 0
);
SHDrawer = __decorate([customElement('sh-drawer')], SHDrawer);
export { SHDrawer };
