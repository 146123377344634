// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import '../../styles';
import { baseFontSize } from '../../utils/base-font-size';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { ThrottlingMixin } from '../../utils/throttling-mixin.js';
import carouselStyles from './sh-carousel.lit.scss.js';
/**
 * @slot - The slot where the main content is rendered. Takes card, thumbnail and images as child.
 * @fires  left-arrow-click - Dispatched when the left arrow is clicked.
 * @fires  right-arrow-click - Dispatched when the right arrow is clicked.
 */
let SHCarousel = class SHCarousel extends ThrottlingMixin(ShuiLitElement) {
  constructor() {
    super(...arguments);
    /** Defines the item currently in focus. */
    this._currentItem = 0;
    /** Defines the total number of elements the carousel component has. */
    this._totalSlotElements = 0;
    /** Defines the width of the slotted components in "rem", "%", "px", etc. Please note for images, a change in width will also result a change in height if not specified. */
    this.elementWidth = '30%';
    /** Defines the space between elements inside the carousel in "rem", "px", etc. */
    this.gap = '0.5rem';
    /** Defines how elements inside the carousel snap to the container when scrolled. Possible values are `start`, `center`, `end`, `none`.
     * @type {"start"|"center"|"end"|"none"}
     */
    this.snap = 'start';
    /**Accepts 'numeric' (default) or 'dots' Defaults to numeric. If set to dots, displays dot navigation. */
    this.counterType = 'numeric';
    /**Accepts 'floating' (default), 'footer-left' or 'footer-right'. */
    this.arrowPlacement = 'floating';
    this.resizeObserver = new ResizeObserver(this.slotResizeObserverCallback.bind(this));
    this.currentScrollPosition = 0; // Track the intended scroll position
    this.isScrolling = true;
  }
  static get styles() {
    return [carouselStyles];
  }
  render() {
    const startDot = this.calculateStartDot();
    return html`
      <div style="width: 100%; display: flex; flex-direction: column;">
        <slot
          id="slot"
          style="${styleMap(this.getDimensionStyles())}"
          @scroll="${this.throttleScroll}"
          @slotchange="${(e) => this.contentSlotChange(e)}"
        ></slot>
        <div class="counter-wrapper">
          ${this.counter
            ? html` <div class="counter">
                ${this.counterType === 'numeric' || (this.counter && this.counterType !== 'dots')
                  ? `${this._currentItem} / ${this._totalSlotElements}`
                  : html`<div
                      class="dots"
                      style="transform: translateX(${-startDot *
                      parseFloat(getComputedStyle(this).getPropertyValue('--carousel-dot-height')) *
                      baseFontSize}px)"
                    >
                      ${this.getDots()}
                    </div>`}
              </div>`
            : ''}
          ${this.arrows
            ? html`${this.arrowPlacement !== 'footer-left' && this.arrowPlacement !== 'footer-right'
                ? html`
                    ${this._currentItem > 1
                      ? html` <sh-icon
                          button
                          icon="arrow-left-s"
                          size="m"
                          class="arrow left"
                          @click="${() => this.scrollToItem(this._currentItem, -1)}"
                        ></sh-icon>`
                      : ''}
                    ${!this.reachedEnd && typeof this.reachedEnd === 'boolean'
                      ? html` <sh-icon
                          icon="arrow-right-s"
                          button
                          @click="${() => this.scrollToItem(this._currentItem, 1)}"
                          class="arrow right"
                        ></sh-icon>`
                      : ''}
                  `
                : html`<div class="button-arrow-wrapper">
                    <sh-button
                      icon="arrow-line-left-s"
                      class="button-arrow left"
                      @click="${() => this.scrollToItem(this._currentItem, -1)}"
                      .disabled="${this._currentItem <= 1}"
                      layout="normal"
                      color="secondary"
                    ></sh-button>
                    <sh-button
                      icon="arrow-line-right-s"
                      class="button-arrow right"
                      .disabled="${this._currentItem === this._totalSlotElements ||
                      this.reachedEnd}"
                      @click="${() => this.scrollToItem(this._currentItem, 1)}"
                      layout="normal"
                      color="secondary"
                    ></sh-button>
                  </div>`}`
            : ''}
        </div>
      </div>
    `;
  }
  calculateStartDot() {
    const totalVisibleDots = 6; // Number of visible dots
    const currentSelectedOffset = 4;
    const startDot = Math.max(
      0,
      Math.min(
        this._currentItem - currentSelectedOffset,
        this._totalSlotElements - totalVisibleDots
      )
    );
    return startDot;
  }
  getDots() {
    const startDot = this.calculateStartDot();
    const endDot = startDot + 6;
    const dotsArray = [];
    for (let i = 0; i < this._totalSlotElements; i++) {
      dotsArray.push(
        html` <div
          class="dot ${i} ${i + 1 === this._currentItem ? 'current-selected' : ''} ${i >=
            startDot && i < endDot
            ? 'in-view'
            : ''} ${(i === startDot && startDot > 0) ||
          (i === endDot - 1 && endDot < this._totalSlotElements)
            ? 'edge'
            : ''}"
        ></div>`
      );
    }
    return dotsArray;
  }
  connectedCallback() {
    super.connectedCallback();
    this.updateComplete.then(() => {
      var _a;
      const slot =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
      this.resizeObserver.observe(slot);
    });
  }
  disconnectedCallback() {
    var _a;
    const slot =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
    this.resizeObserver.unobserve(slot);
    this.resizeObserver.disconnect();
    super.disconnectedCallback();
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('snap') && this.arrows) {
      this.snap = 'start';
    }
    if (changedProperties.has('arrows') && this.arrows && !(this.snap === 'start')) {
      this.snap = 'start';
    }
  }
  contentSlotChange(e) {
    const slot = e.target;
    const slotElements = slot.assignedElements({
      flatten: true,
    }).length;
    if (slotElements) {
      this._totalSlotElements = this.children.length;
      this.handleScroll();
    }
  }
  getDimensionStyles() {
    return {
      '--slot-component-width': this.elementWidth,
      gap: this.gap,
    };
  }
  handleScroll() {
    const slot = this.renderRoot.querySelector('slot');
    this.updateCurrentItemBasedOnSlot(slot);
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.isScrolling = true;
    }, 100);
  }
  throttleScroll() {
    // Throttle mixin
    this.throttle(this.handleScroll.bind(this), 50);
  }
  scrollToItem(itemIndex, addOrSubtract) {
    var _a;
    // scroll to a given item (start from 1) i.e given itemIndex becomes focusable.
    if (itemIndex < 1) {
      itemIndex = 1;
    }
    if (itemIndex > this.children.length) {
      itemIndex = this.children.length;
    }
    const slot =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
    if (slot) {
      // Calculate the amount to scroll based on the width of the visible area (clientWidth)
      // and the direction of the scroll (addOrSubtract determines left or right scroll).
      const scrollAmount = slot.clientWidth * addOrSubtract;
      // Update the intended scroll position by adding the calculated scroll amount.
      // This ensures that multiple rapid clicks are cumulatively accounted for,
      // moving the slider further each time.
      this.currentScrollPosition += scrollAmount;
      // Ensure the updated scroll position stays within the valid boundaries of the scrollable area.
      // It prevents scrolling beyond the first slide (0) or past the last slide
      // (slot.scrollWidth - slot.clientWidth).
      this.currentScrollPosition = Math.max(
        0,
        Math.min(this.currentScrollPosition, slot.scrollWidth - slot.clientWidth)
      );
      this.isScrolling = false;
      this.reachedEnd = this.currentScrollPosition + slot.clientWidth >= slot.scrollWidth;
      this._currentItem = this.reachedEnd
        ? this._totalSlotElements
        : Math.floor(
            this.currentScrollPosition / (this.children[0].clientWidth + parseInt(this.gap, 10)) + 1
          );
      slot.scrollLeft += scrollAmount;
      // Perform the scroll to the calculated position with a smooth transition effect.
      // This moves the slider to the intended position based on user interactions.
      slot.scroll({
        left: this.currentScrollPosition,
        behavior: 'smooth',
      });
      // Dispatch custom events based on the direction of the scroll
      const details = { detail: { currentItem: itemIndex }, bubbles: true, composed: true };
      if (addOrSubtract === -1) {
        this.LeftArrowClickEvent.emit(new CustomEvent('left-arrow-click', details));
      } else {
        this.RightArrowClickEvent.emit(new CustomEvent('right-arrow-click', details));
      }
    }
  }
  slotResizeObserverCallback(e) {
    const slot = e[0].target;
    this.updateCurrentItemBasedOnSlot(slot);
  }
  // This function updates the _currentItem when scrolled to the one which is focused.
  updateCurrentItemBasedOnSlot(slot) {
    const slotElementsPresent = slot.assignedElements({
      flatten: true,
    }).length;
    if (slotElementsPresent) {
      this.reachedEnd = slot.scrollLeft + slot.clientWidth >= slot.scrollWidth;
      if (this.isScrolling) {
        this._currentItem = this.reachedEnd
          ? this._totalSlotElements
          : Math.floor(
              slot.scrollLeft / (this.children[0].clientWidth + parseInt(this.gap, 10)) + 1
            );
        this.currentScrollPosition = slot.scrollLeft;
      }
      this._currentItem = this._currentItem === 0 ? 1 : this._currentItem;
    }
  }
};
__decorate([event()], SHCarousel.prototype, 'LeftArrowClickEvent', void 0);
__decorate([event()], SHCarousel.prototype, 'RightArrowClickEvent', void 0);
__decorate([state()], SHCarousel.prototype, '_currentItem', void 0);
__decorate([state()], SHCarousel.prototype, '_totalSlotElements', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'element-width' })],
  SHCarousel.prototype,
  'elementWidth',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHCarousel.prototype, 'gap', void 0);
__decorate([property({ type: String, reflect: true })], SHCarousel.prototype, 'snap', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHCarousel.prototype, 'counter', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHCarousel.prototype, 'arrows', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'counter-type' })],
  SHCarousel.prototype,
  'counterType',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'arrow-placement' })],
  SHCarousel.prototype,
  'arrowPlacement',
  void 0
);
__decorate([state()], SHCarousel.prototype, 'reachedEnd', void 0);
SHCarousel = __decorate([customElement('sh-carousel')], SHCarousel);
export { SHCarousel };
