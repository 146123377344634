// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-tag.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-tag-container-height=2rem] - height of container
   */
  --sh-tag-container-height: 2rem;
  /* 
   * @cssprop [--sh-tag-wrapper-border=0.0625rem] - border of tag wrapper
   */
  --sh-tag-wrapper-border: 0.0625rem;
  /* 
   * @cssprop [--sh-tag-padding=0 0.5rem] - left and right padding of tag
   */
  --sh-tag-padding: 0 0.5rem;
  /* 
   * @cssprop [--sh-tag-label-line-height=2rem] - line height of tag label
   */
  --sh-tag-label-line-height: 2rem;
  /* 
   * @cssprop [--sh-tag-icon-padding=0.5rem] - padding of the icon
   */
  --sh-tag-icon-padding: 0.5rem;
  /* 
   * @cssprop [--sh-tag-border-radius=var(--sh-radius-control)] - border radius
   */
  --sh-tag-border-radius: var(--sh-radius-control);
  /* 
   * @cssprop [--sh-tag-condensed-container-height=1.5rem] - height of the container in condensed view
   */
  --sh-tag-condensed-container-height: 1.5rem;
  /* 
   * @cssprop [--sh-tag-condensed-icon-padding=0.5rem] - padding of the icon in condensed view
   */
  --sh-tag-condensed-icon-padding: 0.5rem;
}

:host {
  height: var(--sh-tag-container-height);
  overflow-y: hidden;
  flex-direction: row;
  cursor: pointer;
  outline: 0;
  display: block;
  flex-shrink: 0;
}

:host([condensed]) {
  height: var(--sh-tag-condensed-container-height);
}

.tag-wrapper {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: -ms-fit-content;
  width: -moz-fit-content;
  height: 100%;
  border: var(--sh-tag-wrapper-border) solid rgba(var(--color), var(--opacity-5));
  box-sizing: border-box;
  border-radius: var(--sh-tag-border-radius);
  padding: var(--sh-tag-padding);
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) border-color;
}

:host([removable]) .tag-wrapper {
  padding-right: 0;
  justify-content: flex-end;
}

:host([color]:not([color=""])) .tag-wrapper {
  border: var(--sh-tag-wrapper-border) solid rgba(var(--color), var(--opacity-4));
}

:host([selectable][active]) .tag-wrapper {
  background-color: rgba(var(--color), var(--opacity-7));
}

:host([color]:not([color=""])[selectable][active]) .tag-wrapper {
  background-color: rgba(var(--color), var(--opacity-5));
  border-color: rgba(var(--color), var(--opacity-3));
}

:host([color]:not([color=""])[selectable][active]:hover) .tag-wrapper {
  background-color: rgba(var(--color), var(--opacity-5));
  border-color: rgba(var(--color), var(--opacity-2));
}

:host(:hover) .tag-wrapper {
  border-color: rgba(var(--color), var(--opacity-4));
}

:host([color][selectable]:hover) .tag-wrapper {
  border-color: rgba(var(--color), var(--opacity-3));
}

.tag-wrapper .tag-label {
  font: var(--body-1);
  color: var(--text-primary);
  line-height: var(--sh-tag-label-line-height);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

:host([condensed]) .tag-wrapper .tag-label {
  font: var(--body-2);
}

.tag-wrapper sh-icon {
  padding: var(--sh-tag-icon-padding);
}

:host([condensed]) .tag-wrapper sh-icon {
  padding: var(--sh-tag-condensed-icon-padding);
}

:host([removable]) .tag-wrapper .tag-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

:host([disabled]) {
  pointer-events: none;
}

:host([disabled]) .tag-wrapper {
  border-color: rgba(var(--color), var(--opacity-7));
}

:host([color][disabled]) .tag-wrapper {
  border-color: rgba(var(--color), var(--opacity-5));
}

:host([selectable][active][color][disabled]) .tag-wrapper {
  background-color: rgba(var(--color), var(--opacity-7));
  border-color: rgba(var(--color), var(--opacity-5));
}

:host([disabled]) .tag-wrapper .tag-label {
  color: rgba(var(--ui-1), var(--opacity-5));
}

:host([disabled]) sh-icon {
  color: rgba(var(--ui-1), var(--opacity-6));
}`;