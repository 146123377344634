// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
var SHPopover_1;
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { sharedStyles } from '../../styles';
import { BasicOverlayMixin } from '../../utils/basic-overlay-mixin.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { getOverlayPlacementInfo } from '../../utils/getOverlayPlacement.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import { ArrowPosition } from './popover-arrow-positioner.js';
import popoverStyles from './sh-popover.lit.scss.js';
export const popoverListOfProperties = [
  'label',
  'target',
  'visible',
  'sticky',
  'subMenu',
  'position',
  'type',
  'passive',
  'mobile',
];
/**
 * @fires overlay-closed {CustomEvent<string>} - Dispatched when popover is closed on clicking outside of the popover , or on the target , or on scoll when target is out of the viewport or when `scroll-action` is set to hide.
 * @slot footer - Takes buttons as children.
 * @slot functions - Takes icons as children.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires target-changed {TargetPropertyChangedEvent} - *hide emitted when the target property changes.
 * @fires visible-changed {VisiblePropertyChangedEvent} - *hide emitted when the visible property changes.
 * @fires sticky-changed {StickyPropertyChangedEvent} - *hide emitted when the sticky property changes.
 * @fires sub-menu-changed {SubMenuPropertyChangedEvent} - *hide emitted when the subMenu property changes.
 * @fires position-changed {PositionPropertyChangedEvent} - *hide emitted when the position property changes.
 * @fires type-changed {TypePropertyChangedEvent} - *hide emitted when the type property changes.
 * @fires passive-changed {PassivePropertyChangedEvent} - *hide emitted when the passive property changes.
 * @fires mobile-changed {MobilePropertyChangedEvent} - *hide emitted when the mobile property changes.
 */
let SHPopover = (SHPopover_1 = class SHPopover extends BasicOverlayMixin(ShuiLitElement) {
  get targetEl() {
    const target = this.target;
    if (target) {
      if (typeof target === 'string') {
        return this.parentElement
          ? this.parentElement.querySelector(`[id="${target}"]`) || document.getElementById(target)
          : document.getElementById(target);
      } else if (target instanceof HTMLElement) {
        return target;
      } else {
        throw new Error(
          `\n\nThe "target" property is not of valid type.` +
            `\nThe "target" property can take string value corresponding to the id of the target.` +
            `\nIt can also have its value equal to a DOM Node reference (instance of HTMLElement)\n`
        );
      }
    }
  }
  static get styles() {
    return [sharedStyles, popoverStyles];
  }
  render() {
    return html`
      <div
        class="popover-wrapper"
        ?empty-function="${this.emptyFunction}"
        ?empty-footer="${this.emptyFooter}"
      >
        <div class="header-wrapper">
          ${this.type === 'error' ? html` <sh-icon icon="error" size="s"></sh-icon>` : ''}
          ${this.type === 'alert' || this.type === 'warning'
            ? html` <sh-icon icon="warning" size="s"></sh-icon>`
            : ''}
          ${this.type === 'confirmation' || this.type === 'success'
            ? html` <sh-icon icon="success" size="s"></sh-icon>`
            : ''}
          ${this.icon && !this.type ? html` <sh-icon icon="${this.icon}" size="s"> </sh-icon>` : ''}
          ${this.label || this.label === ''
            ? html` <div class="popover-label">${this.label}</div>`
            : ''}
          <slot
            name="functions"
            @slotchange="${(e) => this.updateSlotFlag(e.target, 'emptyFunction')}"
          ></slot>
        </div>
        <div class="body-wrapper">
          <slot></slot>
        </div>
        <div class="footer-wrapper">
          <slot
            name="footer"
            id="footer"
            @slotchange="${(e) => this.updateSlotFlag(e.target, 'emptyFooter')}"
          ></slot>
        </div>
      </div>
      ${this.passive
        ? html` <div class="arrow" style="${styleMap(this.getArrowDimensions())}"></div>`
        : ''}
    `;
  }
  constructor() {
    super();
    /**
     * Default 'top'. Defines where the tooltip will be shown. Accepted values are - top, bottom, left, right, top-right, top-left, bottom-left, bottom-right, left-up, left-down, right-up, right-down
     */
    this.position = 'top';
    /**
     * *hide Default: document.body. Reference viewport node used for placement.
     */
    this.viewport = document.body;
    /**
     * *hide Min padding that will be kept from viewport boundaries.
     */
    this.viewportPadding = 0.5;
    /**
     * *hide distance to overlay target.
     */
    this.overlayTargetDistance = 0.5;
    /**  This component has position:fixed as its css. So when giving left/top to the component, it will
      be with respect to viewport. But if the parent element has either transform/perspective or other
      properties, then this component will position with respect to that parent component. Refer position fixed in https://developer.mozilla.org/en-US/docs/Web/CSS/position?v=example#values
      and https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block#identifying_the_containing_block This might position this component somewhere which is not desirable. So set this property to true, if any
      parent element has any of this css to correctly readjust this component to position with respect to the
      viewport. Also makes the popover stay in the same position while applying zoom.*/
    this.positionFixedAdjustment = false;
    this.emptyFunction = true;
    this.emptyFooter = true;
    this.documentClickListener = this.documentClickActions.bind(this);
    this.documentKeydownListener = this.documentKeyDownEventActions.bind(this);
    this.subMenuTargetMouseOverListener = this.show.bind(this);
    this.subMenuTargetMouseOutListener = this.hide.bind(this);
    this.mouseOverListener = this.mouseOverActions.bind(this);
    this.mouseOutListener = this.mouseOutActions.bind(this);
    this.thisResizeListener = this.handleMobile.bind(this);
    this.scrollAction = 'refit';
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.manualControlled) {
      document.body.addEventListener('click', this.documentClickListener);
    }
    if (this.scrollAction === 'hide' && !this.manualControlled) {
      document.body.addEventListener('keydown', this.documentKeydownListener);
    }
    if (!this.manualControlled) {
      this.onkeyup = (e) => {
        if (
          (keyboardInteraction(e, KEYCODE.SPACE) ||
            keyboardInteraction(e, KEYCODE.ENTER) ||
            keyboardInteraction(e, KEYCODE.ESCAPE)) &&
          this.visible &&
          !this.sticky
        ) {
          this.hide();
        }
      };
    }
    if (!this.mobile) {
      /** @ignore */
      this.thisResizeListener();
      window.addEventListener('resize', this.thisResizeListener);
    }
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  update(changedProperties) {
    if (changedProperties.has('target')) {
      const targetElement = this.targetEl;
      if (targetElement) {
        this.invokerNode = targetElement;
        this.attachNode = targetElement;
      }
    }
    if (
      this.canPositionOverlay() &&
      (changedProperties.has('position') || changedProperties.has('passive')) &&
      this.targetEl
    ) {
      this.setOverlayPlacement(this.targetEl);
    }
    /** The below if check is to set the invokerNode and attachNode once the popover is made visible
     * (so that even if the popover misses to identify the target when the target property changes, it is identified here)
     * In normal ( non manual controlled) situation, this is handled in the documentClick
     * method when it calls the show method **/
    if (changedProperties.has('visible') && this.visible && this.manualControlled) {
      const targetElement = this.targetEl;
      if (targetElement) {
        this.invokerNode = targetElement;
        this.attachNode = targetElement;
      }
    }
    if (
      changedProperties.has('visible') &&
      this.visible &&
      this.subMenu &&
      this.classList.contains('touch-device') &&
      this.targetEl
    ) {
      const targetParent = this.targetEl.parentElement;
      if (
        targetParent &&
        targetParent.tagName === 'SH-POPOVER' &&
        targetParent instanceof SHPopover_1
      ) {
        targetParent.visible = true;
      }
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, popoverListOfProperties);
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.documentClickListener);
    document.body.removeEventListener('keydown', this.documentKeydownListener);
    window.removeEventListener('resize', this.thisResizeListener);
    super.disconnectedCallback();
  }
  updateSlotFlag(slot, emptySlotFlag) {
    this[emptySlotFlag] =
      slot.assignedNodes({
        flatten: true,
      }).length === 0;
  }
  handleMobile() {
    this.mobile = document.documentElement.clientWidth < 640;
  }
  documentClickActions(e) {
    const targetElement = this.targetEl;
    if (e.composedPath().indexOf(targetElement) === -1) {
      if (!this.sticky && this.visible) {
        this.hide();
      }
    } else {
      if (this.visible) {
        this.hide();
      } else {
        this.show();
      }
    }
  }
  documentKeyDownEventActions(e) {
    if (this.scrollAction === 'hide' && !this.sticky) {
      if (
        keyboardInteraction(e, KEYCODE.ARROW_UP) ||
        keyboardInteraction(e, KEYCODE.ARROW_DOWN) ||
        keyboardInteraction(e, KEYCODE.SPACE)
      ) {
        this.hide();
      }
    }
  }
  mouseOverActions() {
    var _a;
    const parentPopover =
      (_a = this.targetEl) === null || _a === void 0 ? void 0 : _a.parentElement;
    if (parentPopover instanceof SHPopover_1 && parentPopover.subMenu) {
      parentPopover.show();
    }
    this.show();
  }
  mouseOutActions() {
    var _a;
    const parentPopover =
      (_a = this.targetEl) === null || _a === void 0 ? void 0 : _a.parentElement;
    if (parentPopover instanceof SHPopover_1 && parentPopover.subMenu) {
      parentPopover.hide();
    }
    this.hide();
  }
  addHoverEvents(targetEl) {
    targetEl.addEventListener('mouseover', this.subMenuTargetMouseOverListener);
    targetEl.addEventListener('mouseout', this.subMenuTargetMouseOutListener);
    this.addEventListener('mouseover', this.mouseOverListener);
    this.addEventListener('mouseout', this.mouseOutListener);
  }
  removeHoverEvents(targetEl) {
    targetEl.removeEventListener('mouseover', this.subMenuTargetMouseOverListener);
    targetEl.removeEventListener('mouseout', this.subMenuTargetMouseOutListener);
    this.removeEventListener('mouseover', this.mouseOverListener);
    this.removeEventListener('mouseout', this.mouseOutListener);
  }
  show() {
    const targetElement = this.targetEl;
    if (targetElement) {
      this.invokerNode = targetElement;
      this.attachNode = targetElement;
    }
    this.visible = true;
  }
  hide() {
    this.visible = false;
    this.overlayClosedEvent.emit(new CustomEvent('overlay-closed'));
  }
  setOverlayPlacement(attachNode) {
    const popoverScale = getComputedStyle(this).getPropertyValue('--current-scale').trim();
    // minPassiveOverlayDistance is the min distance from the target to the popover
    // as well as the min distance from the viewPort to the popover boundary.
    // It is applicable only for passive popover.
    const minPassiveOverlayDistance = popoverScale === 's' || popoverScale === 'm' ? 1 : 1.25;
    // Since the arrow height changes across scales,
    // overlayTargetDistance therefore acquires two different values based on it.
    // For sh-tool it should be '0'.
    if (!this.customPlacementLogic) {
      const targetIsWorklineItem = attachNode.tagName === 'SH-WORKLINE-ITEM';
      const viewportPadding = this.passive
        ? this.viewportPadding >= minPassiveOverlayDistance
          ? this.viewportPadding
          : minPassiveOverlayDistance
        : this.viewportPadding;
      const overlayTargetDistance = this.passive
        ? this.overlayTargetDistance >= minPassiveOverlayDistance
          ? this.overlayTargetDistance
          : minPassiveOverlayDistance
        : this.overlayTargetDistance;
      const worklinePlacementAlgorithm = {
        top: [
          {
            from: 'top',
            to: 'top-diag-left',
            increment: 'overlayRight',
            incrementBy: -1,
          },
          {
            from: 'top',
            to: 'top-diag-right',
            increment: 'overlayLeft',
            incrementBy: 1,
          },
        ],
      };
      // adjusting the viewport and the left, width and right
      // of the final viewport to make the viewport have the
      // left and right equal to the workline-wrapper's left and right
      // when the target is a workline-item so as to make the popover
      // stop at the left and right of workline-wrapper and make the
      // arrow point towards left or right when workline item goes
      // beneath the left/right slot of the workline.
      const closestWorkline = attachNode.closest('sh-workline');
      const viewport = targetIsWorklineItem
        ? closestWorkline.shadowRoot.querySelector('.workline-wrapper')
        : this.viewport;
      const thisViewportRect = this.viewport.getBoundingClientRect();
      const viewPortRect = JSON.parse(JSON.stringify(thisViewportRect));
      viewPortRect.left = viewport.getBoundingClientRect().left;
      viewPortRect.width = viewport.getBoundingClientRect().width;
      viewPortRect.right = viewport.getBoundingClientRect().right;
      const placementAlgorithm = targetIsWorklineItem ? worklinePlacementAlgorithm : null;
      const suppressPlacementWarning = targetIsWorklineItem ? true : this.suppressPlacementWarning;
      const alwaysStayWithin = targetIsWorklineItem ? true : this.alwaysStayWithin;
      const placementInfo = getOverlayPlacementInfo(
        this.position,
        this.getBoundingClientRect(),
        attachNode.getBoundingClientRect(),
        viewPortRect,
        overlayTargetDistance,
        viewportPadding,
        placementAlgorithm,
        placementAlgorithm,
        suppressPlacementWarning
      );
      if (placementInfo.targetIsOutsideViewport && !alwaysStayWithin) {
        this.visible = false;
        if (!suppressPlacementWarning) {
          console.warn(
            'Since the effective target is outside the effective viewport, the popover is hidden'
          );
        }
        this.overlayClosedEvent.emit(new CustomEvent('overlay-closed'));
      } else {
        let calculatedLeft = placementInfo.requiredLeft;
        let calculatedTop = placementInfo.requiredTop;
        if (this.positionFixedAdjustment) {
          const translateX = this.getBoundingClientRect().left - this.offsetLeft;
          const translateY = this.getBoundingClientRect().top - this.offsetTop;
          calculatedLeft = Math.round(calculatedLeft - translateX);
          calculatedTop = Math.round(calculatedTop - translateY);
        }
        this.style.left = calculatedLeft + 'px';
        this.style.top = calculatedTop + 'px';
      }
    } else {
      this.customPlacementLogic();
    }
    if (this.passive && this.targetEl) {
      let targetElement = this.targetEl;
      if (
        targetElement.tagName === 'SH-WORKLINE-ITEM' &&
        'type' in targetElement &&
        targetElement.type === 'information' &&
        targetElement.shadowRoot
      ) {
        targetElement = targetElement.shadowRoot.querySelector('sh-icon');
      }
      const popoverDimensions = this.getBoundingClientRect();
      const targetDimensions = targetElement.getBoundingClientRect();
      const arrowPositionData = new ArrowPosition(
        targetDimensions,
        popoverDimensions,
        popoverScale
      );
      this.arrowX = arrowPositionData.arrowPositionX;
      this.arrowY = arrowPositionData.arrowPositionY;
      this.arrowRotation = arrowPositionData.arrowRotation;
    }
  }
  canPositionOverlay() {
    return this.position && this.visible && !this.mobile;
  }
  doSomethingWithOldInvokerNode(invokerNode) {
    if (!this.manualControlled) {
      this.removeHoverEvents(invokerNode);
    }
  }
  doSomethingWithNewInvokerNode(invokerNode) {
    if (this.subMenu && !this.manualControlled && !this.classList.contains('touch-device')) {
      this.addHoverEvents(invokerNode);
    }
  }
  getArrowDimensions() {
    return {
      left: `${this.arrowX}rem`,
      top: `${this.arrowY}rem`,
      transform: `rotate(${this.arrowRotation}deg)`,
    };
  }
});
__decorate([property({ type: String, reflect: true })], SHPopover.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHPopover.prototype, 'target', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHPopover.prototype, 'sticky', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'sub-menu' })],
  SHPopover.prototype,
  'subMenu',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHPopover.prototype, 'position', void 0);
__decorate([property({ type: String, reflect: true })], SHPopover.prototype, 'type', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHPopover.prototype, 'passive', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'blur-background' })],
  SHPopover.prototype,
  'blurBackground',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'label-rows' })],
  SHPopover.prototype,
  'labelRows',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHPopover.prototype, 'icon', void 0);
__decorate(
  [
    property({
      type: Object,
      attribute: 'custom-placement-logic',
    }),
  ],
  SHPopover.prototype,
  'customPlacementLogic',
  void 0
);
__decorate(
  [
    property({
      type: Boolean,
      reflect: true,
      attribute: 'suppress-placement-warning',
    }),
  ],
  SHPopover.prototype,
  'suppressPlacementWarning',
  void 0
);
__decorate(
  [
    property({
      type: Boolean,
      reflect: true,
      attribute: 'always-stay-within',
    }),
  ],
  SHPopover.prototype,
  'alwaysStayWithin',
  void 0
);
__decorate(
  [
    property({
      type: Object,
    }),
  ],
  SHPopover.prototype,
  'viewport',
  void 0
);
__decorate(
  [
    property({
      type: Number,
      reflect: true,
      attribute: 'viewport-padding',
    }),
  ],
  SHPopover.prototype,
  'viewportPadding',
  void 0
);
__decorate(
  [
    property({
      type: Number,
      reflect: true,
      attribute: 'overlay-target-distance',
    }),
  ],
  SHPopover.prototype,
  'overlayTargetDistance',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'position-fixed-adjustment' })],
  SHPopover.prototype,
  'positionFixedAdjustment',
  void 0
);
__decorate([state()], SHPopover.prototype, 'emptyFunction', void 0);
__decorate([state()], SHPopover.prototype, 'emptyFooter', void 0);
__decorate([state()], SHPopover.prototype, 'arrowX', void 0);
__decorate([state()], SHPopover.prototype, 'arrowY', void 0);
__decorate([state()], SHPopover.prototype, 'arrowRotation', void 0);
__decorate([state()], SHPopover.prototype, 'targetEl', null);
__decorate([event()], SHPopover.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'targetChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'visibleChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'stickyChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'subMenuChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'positionChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'typeChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'passiveChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'mobileChangedEvent', void 0);
__decorate([event()], SHPopover.prototype, 'overlayClosedEvent', void 0);
SHPopover = SHPopover_1 = __decorate([customElement('sh-popover')], SHPopover);
export { SHPopover };
