// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
export const getUpdatedTime = (hour, min, sec) => {
  let resultTime = '';
  if (hour > 0) {
    resultTime += '' + hour + ':';
  }
  resultTime += '' + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '');
  resultTime += '' + sec;
  return resultTime;
};
export const convertToHourMinSec = (currentTime) => {
  const hour = Math.floor(currentTime / 3600);
  const min = Math.floor((currentTime % 3600) / 60);
  const sec = Math.floor(currentTime % 60);
  return { h: hour, m: min, s: sec };
};
