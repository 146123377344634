// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { KEYCODE } from './keycode.js';
// Since KeyboardEvent.keyCode is deprecated and KeyboardEvent.code is not yet fully supported by browsers
// Components should handle both
//currently ignoring this deprecation because e.code not yet implemented in all browsers
export function keyboardInteraction(e: KeyboardEvent, key: { code: string; keycode: number }) {
  return e.code === key.code || e.keyCode === key.keycode; //NOSONAR
}

export function numberkeyInteraction(e: KeyboardEvent) {
  return (
    (e.keyCode >= KEYCODE.NUM_0.keycode && //NOSONAR
      e.keyCode <= KEYCODE.NUM_9.keycode) || //NOSONAR
    e.code.match(/^Digit(\d)$/) ||
    (e.keyCode >= KEYCODE.NUMPAD_0.keycode && e.keyCode <= KEYCODE.NUMPAD_9.keycode) || //NOSONAR
    e.code.match(/^Numpad(\d)$/)
  );
}
