// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-switch.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:host {
  display: flex;
  flex-direction: row;
  border-radius: var(--sh-switch-border-radius);
  background-color: rgba(var(--ui-1), var(--opacity-50));
  width: -webkit-fit-content;
  width: fit-content;
  width: -ms-fit-content;
  width: -moz-fit-content;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

:host([variant=squared]) {
  border-radius: var(--sh-radius-control);
}

:host([stretch]) {
  width: 100%;
}

:host([stretch]) > ::slotted(sh-switch-item) {
  flex: 1;
}

:host([disabled]) {
  pointer-events: auto;
}

:host([condensed][variant=rounded]) {
  border-radius: var(--sh-switch-condensed-border-radius);
}

:host([condensed][variant=squared]) {
  border-radius: var(--sh-radius-control);
}

:host > ::slotted(sh-switch-item) {
  flex: 1;
}`;