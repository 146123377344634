// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/** ### TooltipEllipsisCheckerMixin
 * ___
 * A class aimed to control the display of the tooltip only when
 * the content has ellipsis shown.
 * ___
 * #### How to use this ?
 *
 * Import like this in the component:
 * ```js
 * import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin';
 * ```
 * ___
 *
 
 * #### How does this work ?
 * It checks for the scrollwidth/scrollHeight (total width/height including hidden element) of the target
 * to be greater that the offsetwidth/offsetHeight (outer width/height),
 * based on the direction parameter: if direction is 'horizontal' , it will check for the horizontal condition (scrollWidth > offsetWidth)
 * if direction is 'both', it will check for both vertical and horizontal condition.
 * Default value of direction is 'both'.
 * and if not a touch device then it will return true to display the tooltip.
 *
 * Else false to not display.
 * ___
 *
 */
export const TooltipEllipsisCheckerMixin = (superClass) => {
  class TooltipEllipsisCheckerMixinElementextends extends superClass {
    ellipsisChecker(scrollWidth, offsetWidth, scrollHeight, offsetHeight, direction = 'both') {
      switch (direction) {
        case 'horizontal':
          return scrollWidth > offsetWidth;
        default:
          return scrollWidth > offsetWidth || scrollHeight > offsetHeight;
      }
    }
  }
  return TooltipEllipsisCheckerMixinElementextends;
};
