// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import cardStyles from './sh-card.lit.scss.js';
export const cardListOfProperties = ['label', 'icon', 'image', 'cardHeight', 'disabled'];
/**
 * @slot - The slot where the main content is rendered. Takes any component as child.
 * @slot header - Takes children such as tabs.
 * @slot footer - Takes buttons as children.
 * @slot functions - Takes icons as children
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires icon-changed {IconPropertyChangedEvent} - *hide emitted when the visible property changes.
 * @fires image-changed {ImagePropertyChangedEvent} - *hide emitted when the type property changes.
 * @fires card-height-changed {CardHeightPropertyChangedEvent} - *hide emitted when the modalHeight property changes.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide emitted when the modalWidth property changes.
 */
let SHCard = class SHCard extends ShuiLitElement {
  constructor() {
    super(...arguments);
    this.emptyFooter = true;
    this.emptyHeader = true;
    this.emptyContent = true;
  }
  static get styles() {
    return [sharedStyles, cardStyles];
  }
  render() {
    return html`
      <div class="card-wrapper">
        ${this.image || this.imageIcon
          ? html`
              <div class="image-wrapper">
                ${this.image ? html` <img src="${this.image}" /> ` : ''}
                ${this.imageIcon && !this.image
                  ? html`
                      <sh-icon
                        color="rgba(var(--ui-1), var(--opacity-5))"
                        icon="${this.imageIcon}"
                        class="image-icon"
                      ></sh-icon>
                    `
                  : ''}
              </div>
            `
          : ''}
        ${this.label
          ? html`
              <div
                class="label-wrapper ${this.emptyFooter ? 'empty-footer' : ''}
                ${!this.emptyHeader ? 'header-data' : ''}
                ${this.emptyContent ? 'empty-content' : ''}"
              >
                <div class="icon-badge-wrapper">
                  ${this.icon
                    ? html`
                        <sh-icon
                          size="${(this.href || this.hasAttribute('href')) &&
                          !this.image &&
                          this.emptyContent
                            ? 'l'
                            : 's'}"
                          icon="${this.icon}"
                          .disabled="${(this.href || this.hasAttribute('href')) && this.disabled}"
                          .color="${this.iconColor}"
                          class="card-icon ${this.emptyContent ? 'empty-content' : ''}"
                        >
                        </sh-icon>
                        ${this.badge !== null &&
                        this.badge !== undefined &&
                        this.href !== null &&
                        this.href !== undefined
                          ? html`${this.badge === 'warning' ||
                            this.badge === 'success' ||
                            this.badge === 'error'
                              ? html`<sh-badge class="card-badge" type="${this.badge}"></sh-badge>`
                              : html`<sh-badge
                                  class="card-badge"
                                  label="${this.badge}"
                                ></sh-badge>`}`
                          : ''}
                      `
                    : ''}
                </div>
                <div class="card-label">${this.label}</div>
                ${!(this.emptyContent && this.href) ? html` <slot name="functions"></slot> ` : ''}
              </div>
            `
          : ''}
        ${!this.href
          ? html`
              <div class="header-wrapper ${this.emptyHeader ? 'empty' : ''}">
                <slot name="header" @slotchange="${(e) => this.headerSlotChange(e)}"></slot>
              </div>
            `
          : ''}
        <slot
          class="${this.emptyHeader && !this.label ? 'empty-header' : ''} ${this.emptyFooter
            ? 'empty-footer'
            : ''} ${this.emptyContent ? 'empty-content' : ''}"
          @slotchange="${(e) => this.contentSlotChange(e)}"
        ></slot>
        <slot
          name="footer"
          @slotchange="${(e) => this.footerSlotChange(e)}"
          class="${this.emptyFooter ? 'empty' : ''} ${this.emptyContent ? 'empty-content' : ''}"
        ></slot>
      </div>
    `;
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('cardHeight')) {
      console.warn(
        'You are using a legacy property (cardHeight) of the "sh-card" component. For adjusting the height of the Card please use CSS styles instead.'
      );
      this.handleCardHeight();
    }
    if (this.href && !this.disabled) {
      this.setAttribute('tabindex', '0');
    } else {
      this.removeAttribute('tabindex');
    }
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, cardListOfProperties);
  }
  firstUpdated() {
    this.addEventListener('click', () => {
      if (this.href && !this.disabled) {
        window.location.href = this.href;
      }
    });
    this.addEventListener('focus', () => this.handleFocus());
    this.addEventListener('blur', () => this.handleBlur());
  }
  headerSlotChange(e) {
    if (e.target instanceof HTMLSlotElement) {
      this.updateHeaderSlotFlag(e.target);
    }
  }
  updateHeaderSlotFlag(slot) {
    const headerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyHeader = headerNodes === 0;
  }
  footerSlotChange(e) {
    if (e.target instanceof HTMLSlotElement) {
      this.updateFooterSlotFlag(e.target);
    }
  }
  updateFooterSlotFlag(slot) {
    const footerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = footerNodes === 0;
  }
  contentSlotChange(e) {
    if (e.target instanceof HTMLSlotElement) {
      this.updateContentSlotFlag(e.target);
    }
  }
  updateContentSlotFlag(slot) {
    const assignedNodes = Array.from(slot.assignedNodes()).filter((el) =>
      el.data && el.tagName !== 'SH-CHART'
        ? el.data.trim().split('\n').join('').split(' ').join('')
        : ''
    );
    this.emptyContent = assignedNodes.length === 0 && slot.assignedElements().length === 0;
  }
  // adjust height style
  handleCardHeight() {
    var _a;
    const cardWrapper =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.card-wrapper');
    if (cardWrapper != null) {
      cardWrapper.style.minHeight = String(this.cardHeight);
      cardWrapper.style.maxHeight = String(this.cardHeight);
    }
    if (this.cardHeight === 'fit-content') {
      cardWrapper.style.maxHeight = 'unset';
    }
  }
  handleFocus() {
    this.addEventListener('keyup', this.handleKeyboard);
  }
  handleKeyboard(e) {
    if ((e.code === 'Space' || e.code === 'Enter' || e.code === 'NumpadEnter') && this.href) {
      window.location.href = this.href;
    }
    if (e.code === 'Tab' && !this.disabled && this.href) {
      this.style.outline = 'var(--focus-outline)';
    }
  }
  handleBlur() {
    this.style.outline = '';
    this.style.outlineOffset = '';
    this.removeEventListener('keyup', this.handleKeyboard);
  }
};
__decorate([property({ type: String, reflect: true })], SHCard.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHCard.prototype, 'image', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'image-contain' })],
  SHCard.prototype,
  'imageContain',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'image-icon' })],
  SHCard.prototype,
  'imageIcon',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHCard.prototype, 'icon', void 0);
__decorate([property({ type: String, reflect: true })], SHCard.prototype, 'badge', void 0);
__decorate([property({ type: String, reflect: true })], SHCard.prototype, 'href', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'card-height' })],
  SHCard.prototype,
  'cardHeight',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHCard.prototype, 'disabled', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHCard.prototype,
  'iconColor',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'label-rows' })],
  SHCard.prototype,
  'labelRows',
  void 0
);
__decorate([property({ type: Boolean })], SHCard.prototype, 'emptyFooter', void 0);
__decorate([property({ type: Boolean })], SHCard.prototype, 'emptyHeader', void 0);
__decorate([property({ type: Boolean })], SHCard.prototype, 'emptyContent', void 0);
__decorate([event()], SHCard.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHCard.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHCard.prototype, 'imageChangedEvent', void 0);
__decorate([event()], SHCard.prototype, 'cardHeightChangedEvent', void 0);
__decorate([event()], SHCard.prototype, 'disabledChangedEvent', void 0);
SHCard = __decorate([customElement('sh-card')], SHCard);
export { SHCard };
