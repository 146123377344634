// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ShuiLitElement } from '../../utils/event-decorator.js';
import spinnerStyles from './sh-spinner.lit.scss.js';
/**
 * @prop {String} label - Defines the label that will be displayed below the spinner.
 *
 * @prop {String} size - Default m. Defines the size of the spinner. Possible values are `m` (3rem) and `s` (2rem).
 * @prop {Boolean} overlay - Default false. If set to `true`, the spinner will appear over an overlay on top of the page.
 */
let SHSpinner = class SHSpinner extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**   Defines the size of the spinner. Possible values are `m` (3rem) and `s` (2rem). Default m.
     * @type{ "m "| "s"}
     */
    this.size = 'm';
    /* Default false. If set to `true`, the spinner will appear over an overlay on top of the page.*/
    this.overlay = false;
  }
  static get styles() {
    return [spinnerStyles];
  }
  render() {
    return html`
      <div class="loader"></div>
      ${this.label ? html` <div class="spinner-label">${this.label}</div> ` : ''}
    `;
  }
  update(changedProperties) {
    if (changedProperties.has('size')) {
      this._validateSize();
    }
    super.update(changedProperties);
  }
  _validateSize() {
    if (this.size !== 'm' && this.size !== 's') {
      this.size = 'm';
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHSpinner.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHSpinner.prototype, 'size', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSpinner.prototype, 'overlay', void 0);
SHSpinner = __decorate([customElement('sh-spinner')], SHSpinner);
export { SHSpinner };
