// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles/shared-styles.js';
import timepickerStyles from './sh-timepicker.lit.scss.js';
import { TimeModifierMixin } from './time-modifier-mixin.js';
/**
 * @prop {String} amPm - AM or PM
 * @prop {String} h - hours
 * @prop {String} m - minutes
 * @prop {String} s - seconds
 * @prop {String} step - Increment when stepping up and down.
 * @prop {String} clockFormat - Either `12-hour` or `24-hour`.
 * @prop {Boolean} mini - Enables mini mode for picker which hides seconds
 * @prop {Boolean} disabled - Disables timepicker.
 * @prop {Boolean} timeInputState - *hide Internal prop for what?
 * @prop {Boolean} active - *hide Internal prop for what?
 */
let SHTimepickerPanel = class SHTimepickerPanel extends TimeModifierMixin(LitElement) {
  constructor() {
    super(...arguments);
    this.h = '';
    this.m = '';
    this.s = '';
    /** @ignore */
    this.amPm = '';
    this.mini = false;
    this.step = 1;
    this.clockFormat = '12-hour';
    this.active = false;
  }
  static get styles() {
    return [timepickerStyles, sharedStyles];
  }
  render() {
    return html`
      <div class="timepicker-panel-wrapper">
        <div class="timepicker-panel">
          <div class="timepicker-controls-wrapper">
            <div class="timepicker-controls hour-control">
              <sh-icon
                icon="up-s"
                id="dropdown-icon"
                data-id="h"
                button=""
                size="m"
                @click=${this.incrementWithButton}
              ></sh-icon>
              <div class="control-value">
                <input
                  type="text"
                  readonly
                  placeholder="hh"
                  data-id="h-ip"
                  id="h"
                  min="1"
                  max="${this.clockFormat === '12-hour' ? 12 : 23}"
                  .value="${this.h}"
                  @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
                  size="2"
                />
              </div>
              <sh-icon
                icon="down-s"
                id="dropdown-icon"
                data-id="h"
                button=""
                size="m"
                @click=${this.decrementWithButton}
              ></sh-icon>
            </div>
            <div
              class="seperator ${!(
                this.h === '' &&
                this.m === '' &&
                this.s === '' &&
                this.amPm === ''
              )
                ? 'colon-active'
                : ''}"
            >
              :
            </div>
            <div class="timepicker-controls min-control">
              <sh-icon
                icon="up-s"
                id="dropdown-icon"
                data-id="m"
                button=""
                size="m"
                @click=${this.incrementWithButton}
              ></sh-icon>
              <div class="control-value">
                <input
                  type="text"
                  readonly
                  placeholder="mm"
                  data-id="m-ip"
                  id="m"
                  min="0"
                  max="59"
                  size="2"
                  maxlength="2"
                  .value="${this.m}"
                  @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
                  size="2"
                />
              </div>
              <sh-icon
                icon="down-s"
                id="dropdown-icon"
                data-id="m"
                button=""
                size="m"
                @click=${this.decrementWithButton}
              ></sh-icon>
            </div>
            ${!this.mini
              ? html` <div
                    class="seperator ${!(
                      this.h === '' &&
                      this.m === '' &&
                      this.s === '' &&
                      this.amPm === ''
                    )
                      ? 'colon-active'
                      : ''} full"
                  >
                    :
                  </div>
                  <div class="timepicker-controls sec-control full">
                    <sh-icon
                      icon="up-s"
                      id="dropdown-icon"
                      data-id="s"
                      button=""
                      size="m"
                      @click=${this.incrementWithButton}
                    ></sh-icon>
                    <div class="control-value">
                      <input
                        type="text"
                        readonly
                        placeholder="ss"
                        data-id="s-ip"
                        class="full"
                        id="s"
                        min="0"
                        max="59"
                        maxlength="2"
                        .value="${this.s}"
                        @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
                        size="2"
                      />
                    </div>
                    <sh-icon
                      icon="down-s"
                      id="dropdown-icon"
                      data-id="s"
                      button=""
                      size="m"
                      @click=${this.decrementWithButton}
                    ></sh-icon>
                  </div>`
              : ''}
            <div
              class="seperator ${!(
                this.h === '' &&
                this.m === '' &&
                this.s === '' &&
                this.amPm === ''
              )
                ? 'colon-active'
                : ''}"
            ></div>
            ${this.clockFormat === '12-hour'
              ? html` <div class="timepicker-controls ampm-control">
                  <sh-icon
                    icon="up-s"
                    id="dropdown-icon"
                    button=""
                    size="m"
                    data-id="ap"
                    @click=${this.incrementWithButton}
                  ></sh-icon>
                  <div class="control-value">
                    <input
                      type="text"
                      readonly
                      placeholder="AM"
                      readonly="true"
                      data-id="ap-ip"
                      id="amOrPm"
                      .value="${this.amPm}"
                      size="2"
                      maxlength="2"
                      @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
                    />
                  </div>
                  <sh-icon
                    icon="down-s"
                    id="dropdown-icon"
                    button=""
                    size="m"
                    data-id="ap"
                    @click=${this.decrementWithButton}
                  ></sh-icon>
                </div>`
              : ''}
          </div>
        </div>
      </div>
    `;
  }
  onArrowKeyUpOrDownPressUpdateTime(e) {
    this.incrementOrDecrementOnKeyDown(e);
  }
  incrementWithButton(e) {
    e.stopPropagation();
    this.incrementTime(e.target);
  }
  decrementWithButton(e) {
    e.stopPropagation();
    this.decrementTime(e.target);
  }
};
__decorate([property({ type: String, reflect: true })], SHTimepickerPanel.prototype, 'h', void 0);
__decorate([property({ type: String, reflect: true })], SHTimepickerPanel.prototype, 'm', void 0);
__decorate([property({ type: String, reflect: true })], SHTimepickerPanel.prototype, 's', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'am-pm' })],
  SHTimepickerPanel.prototype,
  'amPm',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepickerPanel.prototype,
  'mini',
  void 0
);
__decorate(
  [property({ type: Number, reflect: true })],
  SHTimepickerPanel.prototype,
  'step',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'clock-format' })],
  SHTimepickerPanel.prototype,
  'clockFormat',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepickerPanel.prototype,
  'active',
  void 0
);
SHTimepickerPanel = __decorate([customElement('sh-timepicker-panel')], SHTimepickerPanel);
export { SHTimepickerPanel };
