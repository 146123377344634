// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import { ControlStateMixin } from '../../utils/control-state-mixin.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import switchItemStyles from './sh-switch-item.lit.scss.js';
export const switchItemListOfProperties = ['label', 'active', 'condensed', 'icon', 'value'];
/**
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires active-changed {ActivePropertyChangedEvent} - *hide emitted when the active property changes.
 * @fires condensed-changed {CondensedPropertyChangedEvent} - *hide emitted when the condensed property changes.
 * @fires icon-changed {IconPropertyChangedEvent} - *hide emitted when the icon property changes.
 * @fires value-changed {ValuePropertyChangedEvent} - *hide emitted when the value property changes.
 * @attr {String} icon-color - Sets the color of the icon added through `icon` property.
 */
let SHSwitchItem = class SHSwitchItem extends ControlStateMixin(ShuiLitElement) {
  constructor() {
    super(...arguments);
    /** Default false. Defines whether the item is active or not. Will be set to true once the item is clicked, and to false if a sibling item is clicked.   */
    this.active = false;
  }
  static get styles() {
    return [switchItemStyles, extendInteractiveArea()];
  }
  render() {
    return html`
      ${(this.label && !this.icon) || (this.label && this.icon)
        ? html` <span class="label"> ${this.label} </span>`
        : ''}
      ${this.icon && !this.label
        ? html` <sh-icon
            icon="${this.icon}"
            .color="${this.iconColor}"
            class="switch-icon"
          ></sh-icon>`
        : ''}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    /** sh-switch-item handles the default tabindex value by itself */
    this.defaultTabIndex = '';
    if (!this.active) {
      this.setAttribute('tabindex', '-1');
    } else {
      this.setAttribute('tabindex', '0');
    }
  }
  update(changedProperties) {
    if (changedProperties.has('active')) {
      if (!this.active) {
        this.setAttribute('tabindex', '-1');
      } else {
        this.setAttribute('tabindex', '0');
      }
    }
    if (changedProperties.has('active') && this.active) {
      this.handleActiveChange();
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, switchItemListOfProperties);
    super.update(changedProperties);
  }
  firstUpdated() {
    this.addEventListener('click', () => this.handleClick());
    this.addEventListener('focus', () => this.handleFocus());
    this.addEventListener('blur', () => this.handleBlur());
  }
  handleClick() {
    this.active = true;
  }
  handleFocus() {
    this.addEventListener('keyup', this.handleKeyup);
  }
  handleBlur() {
    this.removeEventListener('keyup', this.handleKeyup);
    this.removeAttribute('blue-outline');
  }
  handleKeyup(e) {
    if (keyboardInteraction(e, KEYCODE.TAB) && !this.disabled) {
      this.setAttribute('blue-outline', '');
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_RIGHT) || keyboardInteraction(e, KEYCODE.ARROW_LEFT)) {
      this.setAttribute('blue-outline', '');
    }
    if (keyboardInteraction(e, KEYCODE.SPACE) || keyboardInteraction(e, KEYCODE.ENTER)) {
      this.click();
    }
    this.onkeydown = (e) => {
      this.handleKeydown(e);
    };
  }
  handleKeydown(e) {
    var _a;
    let target;
    const siblings =
      (_a = this.parentElement) === null || _a === void 0
        ? void 0
        : _a.querySelectorAll('SH-SWITCH-ITEM:not([disabled])');
    if (siblings) {
      const positionThis = Array.from(siblings).indexOf(this);
      if (keyboardInteraction(e, KEYCODE.ARROW_RIGHT)) {
        //next not disabled sibling
        if (positionThis === siblings.length - 1) {
          target = siblings[0];
        } else {
          target = siblings[positionThis + 1];
        }
        this.handleArrowInput(e, 'ArrowRight', target);
      } else if (keyboardInteraction(e, KEYCODE.ARROW_LEFT)) {
        //previous not disabled sibling
        if (positionThis === 0) {
          target = siblings[siblings.length - 1];
        } else {
          target = siblings[positionThis - 1];
        }
        this.handleArrowInput(e, 'ArrowLeft', target);
      } else {
        //do nothing ()
      }
    }
  }
  handleActiveChange() {
    var _a;
    const siblingItems = (_a = this.parentNode) === null || _a === void 0 ? void 0 : _a.children;
    const numberOfSiblings =
      siblingItems === null || siblingItems === void 0 ? void 0 : siblingItems.length;
    if (siblingItems && numberOfSiblings) {
      for (let i = 0; i < numberOfSiblings; i++) {
        const sibling = siblingItems[i];
        if (sibling.hasAttribute('active') && sibling !== this) {
          sibling.active = false;
        }
      }
    }
  }
  handleArrowInput(e, triggerKeys, target) {
    if (triggerKeys.includes(e.code) && target) {
      e.preventDefault();
      target.focus();
    }
  }
};
__decorate([event()], SHSwitchItem.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHSwitchItem.prototype, 'condensedChangedEvent', void 0);
__decorate([event()], SHSwitchItem.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHSwitchItem.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHSwitchItem.prototype, 'valueChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHSwitchItem.prototype, 'label', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSwitchItem.prototype, 'active', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHSwitchItem.prototype,
  'condensed',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHSwitchItem.prototype, 'icon', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHSwitchItem.prototype,
  'iconColor',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHSwitchItem.prototype, 'value', void 0);
SHSwitchItem = __decorate([customElement('sh-switch-item')], SHSwitchItem);
export { SHSwitchItem };
