import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ControlStateMixin } from '../../utils/control-state-mixin';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import { windowLocation } from '../../utils/window-location.js';
import iconFont from './sh-icon.font.lit.scss.js';
import iconStyles from './sh-icon.lit.scss.js';
export const iconListOfProperties = [
  'icon',
  'button',
  'color',
  'size',
  'href',
  'badge',
  'toggle',
  'active',
];
/**
 * @fires icon-changed {IconPropertyChangedEvent} - *hide emitted when the icon  property changes.
 * @fires button-changed {ButtonPropertyChangedEvent} - *hide emitted when the button  property changes.
 * @fires color-changed {ColorPropertyChangedEvent} - *hide emitted when the color property changes.
 * @fires size-changed {SizePropertyChangedEvent} - *hide emitted when the size property changes.
 * @fires href-changed {HrefPropertyChangedEvent} - *hide emitted when the href property changes.
 * @fires badge-changed {BadgePropertyChangedEvent} - *hide emitted when the badge  property changes.
 * @fires toggle-changed {TogglePropertyChangedEvent} - *hide emitted when the toggle  property changes.
 * @fires active-changed {ActivePropertyChangedEvent} - *hide emitted when the active property changes.
 */
let SHIcon = class SHIcon extends ControlStateMixin(FocusBlurMixin(ShuiLitElement)) {
  constructor() {
    super(...arguments);
    /** Defines the size of the icon. Accepted values are xs - 16px, s - 24px, m - 32px, l - 48px.
     * @type {"xs"|"s"|"m"|"l"}
     */
    this.size = 'm';
    /** If set to `true`, adds button behavior and styles to icon. */
    this.button = false;
    /**If the icon is of a toggle and button type, defines if it is active(true) or not (false). */
    this.active = false;
    /**If set to true, the icon button will have a toggle behavior, turning on and off upon click on the icon.*/
    this.toggle = false;
    /**If set to true it adds a background to toggleable icon buttons during hover and pressed states */
    this.ixBackground = false;
  }
  static get styles() {
    return [iconFont, iconStyles, extendInteractiveArea(':host([button])')];
  }
  render() {
    return html`<div class="first"></div>
      <div class="second"></div>
      <div class="icon">
        ${this.badge !== undefined && this.badge !== null
          ? html` <sh-badge class="badge" label="${this.badge}"></sh-badge>`
          : nothing}
      </div>
      ${this.toggle || this.active || (this.button && this.ixBackground)
        ? html` <div class="interactive-background"></div>`
        : nothing} `;
  }
  connectedCallback() {
    /** sh-icon handles the default tabindex value by itself */
    this.defaultTabIndex = '';
    if (!this.button && !this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '-1');
    }
    if (this.button && !this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('button')) {
      this.button ? this.setAttribute('role', 'button') : this.removeAttribute('role');
    }
    if (changedProperties.has('icon')) {
      this.updateIcon();
    }
    if (changedProperties.has('color')) {
      this.updateColor();
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, iconListOfProperties);
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this.addEventListener('click', this.clickEventListener);
    this.addEventListener('mousedown', function () {
      this.classList.add('active');
    });
    this.addEventListener('mouseup', function () {
      this.classList.remove('active');
    });
  }
  handleKeyup(e) {
    super.handleKeyup(e);
    if (keyboardInteraction(e, KEYCODE.ENTER)) {
      this.click();
    }
  }
  _spaceKeyAction() {
    this.click();
  }
  clickEventListener(e) {
    if (this.href !== undefined) {
      windowLocation.setWindowLocationHRef(this.href);
    }
    if (this.disabled) {
      e.stopImmediatePropagation();
    }
    if (e.type == 'click' && this.button) {
      this._toggleActive();
    }
  }
  updateIcon() {
    var _a;
    const firstDiv =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.first');
    if (firstDiv == null) return;
    if (this.icon && this.icon.indexOf('url') >= 0) {
      firstDiv.style.backgroundImage = this.icon;
    } else {
      firstDiv.style.backgroundImage = '';
    }
    switch (this.icon) {
      case 'patient-position-prone':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use 'position-supine' instead.`
        );
        break;
      case 'patient-position-supine':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use "position-prone" instead.`
        );
        break;
      case 'patient-position-lateral-right':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use "position-lateral-left" instead.`
        );
        break;
      case 'patient-position-lateral-left':
        console.warn(
          `You are using a legacy icon ${this.icon}. Please use "position-lateral-right" instead.`
        );
        break;
      default:
        return;
    }
  }
  updateColor() {
    if (this.color !== undefined) {
      this.style.color = this.color;
    }
  }
  _toggleActive() {
    if (this.toggle) {
      this.active = !this.active;
    }
  }
};
__decorate([event()], SHIcon.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHIcon.prototype, 'buttonChangedEvent', void 0);
__decorate([event()], SHIcon.prototype, 'colorChangedEvent', void 0);
__decorate([event()], SHIcon.prototype, 'sizeChangedEvent', void 0);
__decorate([event()], SHIcon.prototype, 'hrefChangedEvent', void 0);
__decorate([event()], SHIcon.prototype, 'badgeChangedEvent', void 0);
__decorate([event()], SHIcon.prototype, 'toggleChangedEvent', void 0);
__decorate([event()], SHIcon.prototype, 'activeChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'icon', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'size', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'color', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'badge', void 0);
__decorate([property({ type: String, reflect: true })], SHIcon.prototype, 'href', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHIcon.prototype, 'button', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHIcon.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHIcon.prototype, 'toggle', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'ix-background' })],
  SHIcon.prototype,
  'ixBackground',
  void 0
);
SHIcon = __decorate([customElement('sh-icon')], SHIcon);
export { SHIcon };
