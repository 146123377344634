// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

/**
 * Base event class for our legacy propXYZ-changed events
 */
export class LegacyPropertyChangedEvent<
  Prop extends string,
  TDetail = void,
> extends CustomEvent<TDetail> {
  override readonly type!: `${Prop}-changed`;

  constructor(prop: Prop, init?: CustomEventInit<TDetail>) {
    super(`${prop}-changed`, init);
  }
}

export class DisabledPropertyChangedEvent extends LegacyPropertyChangedEvent<'disabled', boolean> {
  constructor(init?: CustomEventInit<boolean>) {
    super('disabled', init);
  }
}

export class IconPropertyChangedEvent extends LegacyPropertyChangedEvent<'icon'> {
  constructor() {
    super('icon');
  }
}

export class LabelPropertyChangedEvent extends LegacyPropertyChangedEvent<'label'> {
  constructor() {
    super('label');
  }
}

export class SizePropertyChangedEvent extends LegacyPropertyChangedEvent<'size'> {
  constructor() {
    super('size');
  }
}

export class ColorPropertyChangedEvent extends LegacyPropertyChangedEvent<'color'> {
  constructor() {
    super('color');
  }
}

export class TooltipPropertyChangedEvent extends LegacyPropertyChangedEvent<'tooltip'> {
  constructor() {
    super('tooltip');
  }
}

export class HrefPropertyChangedEvent extends LegacyPropertyChangedEvent<'href'> {
  constructor() {
    super('href');
  }
}
export class DragAndDropPropertyChangedEvent extends LegacyPropertyChangedEvent<'dragAndDrop'> {
  constructor() {
    super('dragAndDrop');
  }
}
export class IconColorPropertyChangedEvent extends LegacyPropertyChangedEvent<'iconColor'> {
  constructor() {
    super('iconColor');
  }
}
export class HeaderPropertyChangedEvent extends LegacyPropertyChangedEvent<'header'> {
  constructor() {
    super('header');
  }
}
export class ButtonPropertyChangedEvent extends LegacyPropertyChangedEvent<'button'> {
  constructor() {
    super('button');
  }
}
export class BadgePropertyChangedEvent extends LegacyPropertyChangedEvent<'badge'> {
  constructor() {
    super('badge');
  }
}
export class TogglePropertyChangedEvent extends LegacyPropertyChangedEvent<'toggle'> {
  constructor() {
    super('toggle');
  }
}
export class StickyPropertyChangedEvent extends LegacyPropertyChangedEvent<'sticky'> {
  constructor() {
    super('sticky');
  }
}

export class TitlePropertyChangedEvent extends LegacyPropertyChangedEvent<'title'> {
  constructor() {
    super('title');
  }
}

export class VariationPropertyChangedEvent extends LegacyPropertyChangedEvent<'variation'> {
  constructor() {
    super('variation');
  }
}

export class TargetPropertyChangedEvent extends LegacyPropertyChangedEvent<'target'> {
  constructor() {
    super('target');
  }
}
export class NumberPropertyChangedEvent extends LegacyPropertyChangedEvent<'number'> {
  constructor() {
    super('number');
  }
}

export class VisiblePropertyChangedEvent extends LegacyPropertyChangedEvent<'visible'> {
  constructor() {
    super('visible');
  }
}

export class TypePropertyChangedEvent extends LegacyPropertyChangedEvent<'type'> {
  constructor() {
    super('type');
  }
}

export class ModalHeightPropertyChangedEvent extends LegacyPropertyChangedEvent<'modalHeight'> {
  constructor() {
    super('modalHeight');
  }
}

export class ModalWidthPropertyChangedEvent extends LegacyPropertyChangedEvent<'modalWidth'> {
  constructor() {
    super('modalWidth');
  }
}
export class PagesPropertyChangedEvent extends LegacyPropertyChangedEvent<'pages'> {
  constructor() {
    super('pages');
  }
}
export class CurrentPagePropertyChangedEvent extends LegacyPropertyChangedEvent<'current-page'> {
  constructor() {
    super('current-page');
  }
}
export class SpacingPropertyChangedEvent extends LegacyPropertyChangedEvent<'spacing'> {
  constructor() {
    super('spacing');
  }
}
export class VariantPropertyChangedEvent extends LegacyPropertyChangedEvent<'variant'> {
  constructor() {
    super('variant');
  }
}
export class VerticalPropertyChangedEvent extends LegacyPropertyChangedEvent<'vertical'> {
  constructor() {
    super('vertical');
  }
}
export class IndeterminatePropertyChangedEvent extends LegacyPropertyChangedEvent<'indeterminate'> {
  constructor() {
    super('indeterminate');
  }
}
export class NeutralPropertyChangedEvent extends LegacyPropertyChangedEvent<'neutral'> {
  constructor() {
    super('neutral');
  }
}
export class MultilinePropertyChangedEvent extends LegacyPropertyChangedEvent<'multiline'> {
  constructor() {
    super('multiline');
  }
}

export class FieldsPropertyChangedEvent extends LegacyPropertyChangedEvent<'fields'> {
  constructor() {
    super('fields');
  }
}

export class PlacementPropertyChangedEvent extends LegacyPropertyChangedEvent<'placement'> {
  constructor() {
    super('placement');
  }
}

export class ErrorPropertyChangedEvent extends LegacyPropertyChangedEvent<'error'> {
  constructor() {
    super('error');
  }
}
export class ImagePropertyChangedEvent extends LegacyPropertyChangedEvent<'image'> {
  constructor() {
    super('image');
  }
}

export class LabelAlignmentPropertyChangedEvent extends LegacyPropertyChangedEvent<'labelAlignment'> {
  constructor() {
    super('labelAlignment');
  }
}

export class OverlayPropertyChangedEvent extends LegacyPropertyChangedEvent<'overlay'> {
  constructor() {
    super('overlay');
  }
}

export class PausedPropertyChangedEvent extends LegacyPropertyChangedEvent<'paused'> {
  constructor() {
    super('paused');
  }
}

export class RadialPropertyChangedEvent extends LegacyPropertyChangedEvent<'radial'> {
  constructor() {
    super('radial');
  }
}

export class StatusPropertyChangedEvent extends LegacyPropertyChangedEvent<'status'> {
  constructor() {
    super('status');
  }
}

export class InfoPropertyChangedEvent extends LegacyPropertyChangedEvent<'info'> {
  constructor() {
    super('info');
  }
}

export class ModalPropertyChangedEvent extends LegacyPropertyChangedEvent<'modal'> {
  constructor() {
    super('modal');
  }
}

export class ProgressPropertyChangedEvent extends LegacyPropertyChangedEvent<'progress'> {
  constructor() {
    super('progress');
  }
}

export class SubHeadlinePropertyChangedEvent extends LegacyPropertyChangedEvent<'subHeadline'> {
  constructor() {
    super('subHeadline');
  }
}

export class VersionPropertyChangedEvent extends LegacyPropertyChangedEvent<'version'> {
  constructor() {
    super('version');
  }
}
export class NoClearPropertyChangedEvent extends LegacyPropertyChangedEvent<'noClear'> {
  constructor() {
    super('noClear');
  }
}
export class ActivePropertyChangedEvent extends LegacyPropertyChangedEvent<'active'> {
  constructor() {
    super('active');
  }
}
export class ValuePropertyChangedEvent extends LegacyPropertyChangedEvent<'value'> {
  constructor() {
    super('value');
  }
}

export class CurrentPropertyChangedEvent extends LegacyPropertyChangedEvent<'current'> {
  constructor() {
    super('current');
  }
}

export class ReadonlyPropertyChangedEvent extends LegacyPropertyChangedEvent<'readonly'> {
  constructor() {
    super('readonly');
  }
}

export class MessageVisiblePropertyChangedEvent extends LegacyPropertyChangedEvent<'messageVisible'> {
  constructor() {
    super('messageVisible');
  }
}

export class ThumbnailPropertyChangedEvent extends LegacyPropertyChangedEvent<'thumbnail'> {
  constructor() {
    super('thumbnail');
  }
}
export class FlatPropertyChangedEvent extends LegacyPropertyChangedEvent<'flat'> {
  constructor() {
    super('flat');
  }
}

export class ExpandedPropertyChangedEvent extends LegacyPropertyChangedEvent<'expanded'> {
  constructor() {
    super('expanded');
  }
}

export class MessagePropertyChangedEvent extends LegacyPropertyChangedEvent<'message'> {
  constructor() {
    super('message');
  }
}

export class NewPropertyChangedEvent extends LegacyPropertyChangedEvent<'new'> {
  constructor() {
    super('new');
  }
}
export class SubMenuPropertyChangedEvent extends LegacyPropertyChangedEvent<'subMenu'> {
  constructor() {
    super('subMenu');
  }
}

export class PositionPropertyChangedEvent extends LegacyPropertyChangedEvent<'position'> {
  constructor() {
    super('position');
  }
}

export class PassivePropertyChangedEvent extends LegacyPropertyChangedEvent<'passive'> {
  constructor() {
    super('passive');
  }
}

export class MobilePropertyChangedEvent extends LegacyPropertyChangedEvent<'mobile'> {
  constructor() {
    super('mobile');
  }
}

export class CondensedPropertyChangedEvent extends LegacyPropertyChangedEvent<'condensed'> {
  constructor() {
    super('condensed');
  }
}

export class NativePropertyChangedEvent extends LegacyPropertyChangedEvent<'native'> {
  constructor() {
    super('native');
  }
}

export class EmbeddedPropertyChangedEvent extends LegacyPropertyChangedEvent<'embedded'> {
  constructor() {
    super('embedded');
  }
}
export class TextareaPropertyChangedEvent extends LegacyPropertyChangedEvent<'textarea'> {
  constructor() {
    super('textarea');
  }
}
export class ResizablePropertyChangedEvent extends LegacyPropertyChangedEvent<'resizable'> {
  constructor() {
    super('resizable');
  }
}
export class CardHeightPropertyChangedEvent extends LegacyPropertyChangedEvent<'cardHeight'> {
  constructor() {
    super('cardHeight');
  }
}
export class ArrowPositionPropertyChangedEvent extends LegacyPropertyChangedEvent<'arrowPosition'> {
  constructor() {
    super('arrowPosition');
  }
}

export class BehaviorPropertyChangedEvent extends LegacyPropertyChangedEvent<'behavior'> {
  constructor() {
    super('behavior');
  }
}
export class StretchPropertyChangedEvent extends LegacyPropertyChangedEvent<'stretch'> {
  constructor() {
    super('stretch');
  }
}

export class currentActivePropertyChangedEvent extends LegacyPropertyChangedEvent<'currentActive'> {
  constructor() {
    super('currentActive');
  }
}

export class currentSelectedPropertyChangedEvent extends LegacyPropertyChangedEvent<'currentSelected'> {
  constructor() {
    super('currentSelected');
  }
}
