// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { HtmlDocumentModifier } from '../../utils/html-document-modifier.js';
import { pullToRefreshMixin } from '../../utils/pull-to-refresh-mixin.js';
import pageStyles from './sh-page.lit.scss.js';
/**
 * @slot unnamed - Takes any component as a child. Corresponds to the main content area.
 * @slot access - Takes Access Bar as a child.
 * @slot nav - Takes Navigation Bar as a child.
 * @slot left - Takes Pane as a child.
 * @slot right - Takes Pane as a child.
 * @slot footer - Takes Navigation Bar as a child.
 */
let SHPage = class SHPage extends pullToRefreshMixin(LitElement, {}) {
  constructor() {
    super(...arguments);
    /** Default light. Defines the color theme of the page. Accepted values are light and dark.
     * @type {light|dark}
     */
    this.theme = 'light';
    /** Default false. Defines whether the page scrolls or not. */
    this.scrollable = false;
    /** Default false. Changes the page background to Base 3 for cases when cards are not necessary for hosting the content. */
    this.flat = false;
    /** Default false. Changes the UI2, UI3 and UI4 colors of the page so that the orange shades can be adapted to DICOM compliant monitors. */
    this.dicom = false;
    /** Defines the page structure, background, shadows and corner radius. Possible values are 'normal', 'compact' and 'minimal'. */
    this.layout = 'classic';
  }
  static get styles() {
    return [sharedStyles, super.styles, pageStyles];
  }
  update(changedProperties) {
    if (changedProperties.has('theme')) {
      this.defineTheme();
    }
    if (changedProperties.has('dicom')) {
      this.dicomObserver();
    }
    super.update(changedProperties);
  }
  render() {
    return html`
      <div class="page-wrapper">
        <slot name="access" id="access"></slot>
        <slot name="nav" id="nav"></slot>
        <div class="page-content">
          <slot name="left" id="left"></slot>
          <div class="main-wrapper">
            ${this.pulltorefresh
              ? this.renderSpinner(this.pageContentTemplate())
              : this.pageContentTemplate()}
          </div>
          <slot name="right" id="right"> </slot>
        </div>
        <slot name="footer" id="footer"></slot>
      </div>
    `;
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    const listOfProperties = ['theme', 'scrollable', 'flat', 'dicom', 'noPadding'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  pageContentTemplate() {
    return html`
      <div class="body-wrapper" ?scrollable="${this.scrollable}">
        <slot></slot>
      </div>
    `;
  }
  defineTheme() {
    if (this.theme) {
      HtmlDocumentModifier.setHTMLAttribute('theme', this.theme);
    } else {
      HtmlDocumentModifier.removeHTMLAttribute('theme');
    }
  }
  dicomObserver() {
    if (this.dicom) {
      HtmlDocumentModifier.setHTMLAttribute('dicom', '');
    } else {
      HtmlDocumentModifier.removeHTMLAttribute('dicom');
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHPage.prototype, 'theme', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHPage.prototype, 'scrollable', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHPage.prototype, 'flat', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHPage.prototype, 'dicom', void 0);
__decorate([property({ type: String, reflect: true })], SHPage.prototype, 'layout', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'content-spacing' })],
  SHPage.prototype,
  'contentSpacing',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'access-bar' })],
  SHPage.prototype,
  'accessBar',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-padding' })],
  SHPage.prototype,
  'noPadding',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHPage.prototype, 'pulltorefresh', void 0);
SHPage = __decorate([customElement('sh-page')], SHPage);
export { SHPage };
