// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { calculatedBaseFontSize } from '../../utils/base-font-size.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import worklineItemStyles from '../src/sh-workline-item.lit.scss.js';
export const WorkLineItemlistOfProperties = [
  'label',
  'info',
  'color',
  'icon',
  'indeterminate',
  'active',
  'current',
  'disabled',
  'readonly',
  'messageVisible',
  'thumbnail',
  'new',
  'type',
];
/**
 * @slot badge - Takes icons or badge to indicate status related to the item.
 * @slot info - Takes icons or text that provides informtion such as delay time or voice commands. Is hown on the left side of the item.
 * @slot message - Is shown when message-visible property is set to true. Contains any HTML content that composes the message body (e.g. plain text, icons, buttons.
 * @fires workline-item-size-changed {CustomEvent<string>} - *hide Dispatched when the size of the workline item changes.
 * @fires workline-item-float-changed {CustomEvent<string>} - *hide  Dispatched when the float property of the workline item changes.
 * @fires workline-animation-ended {CustomEvent<string>} - *hide Dispatched when the animation of the workline item ends.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide Dispatched when label changes.
 * @fires info-changed {InfoPropertyChangedEvent} - *hide Dispatched when info changes.
 * @fires color-changed {ColorPropertyChangedEvent} - *hide Dispatched when color changes.
 * @fires icon-changed {IconPropertyChangedEvent} - *hide Dispatched when icon changes.
 * @fires indeterminate-changed {IndeterminatePropertyChangedEvent} - *hide Dispatched when indeterminate changes.
 * @fires active-changed {ActivePropertyChangedEvent} - *hide Dispatched when active changes.
 * @fires current-changed {CurrentPropertyChangedEvent} - *hide Dispatched when current changes.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide Dispatched when disabled changes.
 * @fires readonly-changed {ReadonlyPropertyChangedEvent} - *hide Dispatched when readonly changes.
 * @fires message-visible-changed {MessageVisiblePropertyChangedEvent} - *hide Dispatched when message-visible changes.
 * @fires thumbnail-changed {ThumbnailPropertyChangedEvent} - *hide Dispatched when thumbnail changes.
 * @fires new-changed {NewPropertyChangedEvent} - *hide Dispatched when new changes.
 * @fires type-changed {TypePropertyChangedEvent} - *hide Dispatched when type changes.
 
 */
let SHWorklineItem = class SHWorklineItem extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**Defines the progress of an individual item, which is reflected in the filling of the ring around it. */
    this.progress = '0';
    /**If set to true, the progress bar will spin indefinitely until the property is set back to false. */
    this.indeterminate = false;
    /**Defines whether an item is currently selected by the user or not. */
    this.active = false;
    /**If set to true, the styles of the item will adapt and it will not allow mouse or touch interactions. */
    this.disabled = false;
    /**If set to true, the item will not allow mouse or touch interactions. */
    this.readonly = false;
    /**If set to true, the popover message will be shown. */
    this.messageVisible = false;
    /**If set to true, the workline item will show the repeat animation. This animation only appears initially when the workline-item is attached to the DOM for the first time. */
    this.repeat = false;
    /**Flags an workline-item as new. The new property is removed after the item gets active. */
    this.new = false;
    /**Set to true when message slot is empty */
    this.emptyMessage = true;
    /**Set to true when info slot is empty */
    this.emptyinfo = true;
    /**Set to true when info right slot is empty */
    this.emptyinforight = true;
  }
  static get styles() {
    return [worklineItemStyles];
  }
  render() {
    return html`
      ${this.type !== 'information'
        ? html` <div class="info-wrapper">
            <slot
              name="info"
              id="info"
              @slotchange=${(e) => this.updateEmptySlotFlag(e.target, 'emptyinfo')}
            ></slot>
          </div>`
        : ''}
      <!-- Adding on-click="stopEvent" as a fix for the bug 22078 in Azure Devops -->
      <!-- Bug link : https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/22078 -->
      <sh-popover
        passive
        .visible="${this.messageVisible && !this.emptyMessage}"
        @visible-changed=${(e) => {
          e.target.visible = this.messageVisible && !this.emptyMessage;
        }}
        @mobile-changed=${(e) => {
          e.target.mobile = false;
        }}
        sticky
        @click="${this.stopEvent}"
        .target="${this.getPopoverTarget()}"
      >
        <slot
          name="message"
          id="messageSlot"
          @slotchange=${(e) => this.updateEmptySlotFlag(e.target, 'emptyMessage')}
        ></slot>
      </sh-popover>
      <div class="item-wrapper">
        ${this.type !== 'information'
          ? html` ${this.progress >= 0
              ? html`<div class="progress-wrapper">
              ${this.createDashRing()}
              ${this.createProgressRing()}
            </div> </div>`
              : ''}
            ${this.info && !this.icon
              ? html`<sh-text size="body-1" class="item-info">${this.info}</sh-text>`
              : ''}
            ${this.thumbnail
              ? html` <div class="thumbnail-wrapper">
                  <div id="itemThumbnail" style="background-image:url('${this.thumbnail}');"></div>
                </div>`
              : ''}`
          : ''}
        ${this.icon
          ? html` <sh-icon id="itemIcon" .color="${this.iconColor}" icon=${this.icon}></sh-icon>`
          : ''}
        ${this.type !== 'information'
          ? html` ${this.current
              ? html` <div class="triangles"></div>
                  ${!this.active && !this.emptyinforight
                    ? html` <div class="badge-wrapper-current">
                        <slot name="badge"></slot>
                      </div>`
                    : ''}`
              : ''}`
          : ''}
      </div>
      ${this.type !== 'information'
        ? html` <div class="info-right-wrapper">
              <slot
                name="info-right"
                id="infoRight"
                @slotchange=${(e) => this.updateEmptySlotFlag(e.target, 'emptyinforight')}
              ></slot>
            </div>
            ${!this.current || this.emptyinforight || (this.current && this.active)
              ? html` <div class="badge-wrapper">
                  <slot name="badge" id="badgeSlot"></slot>
                </div>`
              : ''}
            ${this.label
              ? html`<sh-text size="body-2" class="item-label">${this.label}</sh-text>`
              : ''}`
        : ''}
    `;
  }
  update(changedProperties) {
    if (changedProperties.has('float')) {
      this.updateComplete.then(() => {
        this.handleFloatChange();
      });
    }
    if (changedProperties.has('repeat')) {
      this.handleRepeatChange();
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    dispatchAttributeChangedEventTypesafe(this, changedProperties, WorkLineItemlistOfProperties);
  }
  firstUpdated(changedProperties) {
    var _a;
    super.firstUpdated(changedProperties);
    if (changedProperties.has('repeat')) {
      this.handleRepeatChange();
    }
    const infowrapper =
      (_a = this === null || this === void 0 ? void 0 : this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.info-wrapper');
    infowrapper === null || infowrapper === void 0
      ? void 0
      : infowrapper.removeEventListener('transitionend', this.transitionendHandler);
    infowrapper === null || infowrapper === void 0
      ? void 0
      : infowrapper.addEventListener('transitionend', this.transitionendHandler);
  }
  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(() => {
      this.requestUpdate();
    });
    this.resizeObserver.observe(this);
    this.transitionendListener = this.transitionendHandler.bind(this);
  }
  transitionendHandler() {
    var _a;
    (_a = this.worklineItemSizeChangedEvent) === null || _a === void 0
      ? void 0
      : _a.emit(new CustomEvent('workline-item-size-changed', { bubbles: true, composed: true }));
  }
  updateEmptySlotFlag(slot, emptySlotFlag) {
    this[emptySlotFlag] =
      slot.assignedNodes({
        flatten: true,
      }).length === 0;
  }
  handleFloatChange() {
    var _a;
    (_a = this.worklineItemFloatChangedEvent) === null || _a === void 0
      ? void 0
      : _a.emit(new CustomEvent('workline-item-float-changed', { bubbles: true, composed: true }));
  }
  handleRepeatChange() {
    if (this.repeat) {
      this.classList.add('repeat-animate');
      this.classList.add('no-transitions');
      // the animation class is removed here inorder to avoid
      // retriggering of the animation when the workline item
      // is removed from the DOM.
      this.addEventListener('animationend', () => {
        var _a;
        this.classList.remove('repeat-animate');
        this.classList.remove('no-transitions');
        (_a = this.worklineAnimationEndedEvent) === null || _a === void 0
          ? void 0
          : _a.emit(new CustomEvent('workline-animation-ended', { bubbles: true, composed: true }));
      });
    }
  }
  createProgressRing() {
    const computedSize = calculatedBaseFontSize / 16;
    const circleFillValue =
      computedSize *
      (parseFloat(getComputedStyle(this).getPropertyValue('--sh-workline-item-progress-circle')) *
        16 *
        Math.PI);
    return html`<svg class="progress-ring-svg">
      >
      <circle
        class="circle-ring"
        id="progress-ring"
        stroke="${this.color}"
        fill="transparent"
        stroke-dasharray="${circleFillValue * ((this.indeterminate ? 25 : this.progress) / 100)},
    ${circleFillValue}"
      />
    </svg>`;
  }
  createDashRing() {
    const computedSize = calculatedBaseFontSize / 16;
    return html`<svg class="progress-ring-svg">
      <circle
        id="dash-ring"
        class="circle-ring"
        stroke="${this.color}"
        stroke-dasharray="0, ${computedSize *
        parseFloat(
          getComputedStyle(this).getPropertyValue('--sh-workline-item-circle-stroke-dasharray')
        )}"
        fill="transparent"
        stroke-linecap="round"
      />
    </svg>`;
  }
  /**
   * Adding on-click="stopEvent" as a fix for the bug 22078 in Azure Devops.
   * Bug link : https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/22078
   */
  stopEvent(e) {
    e.stopPropagation();
  }
  getPopoverTarget() {
    return this;
  }
};
__decorate([event()], SHWorklineItem.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'infoChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'colorChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'indeterminateChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'currentChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'readonlyChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'messageVisibleChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'thumbnailChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'newChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'typeChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'worklineItemSizeChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'worklineItemFloatChangedEvent', void 0);
__decorate([event()], SHWorklineItem.prototype, 'worklineAnimationEndedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'info', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'color', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'icon', void 0);
__decorate(
  [property({ type: Number, reflect: true })],
  SHWorklineItem.prototype,
  'progress',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'indeterminate',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'active',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'current',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'readonly',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'message-visible' })],
  SHWorklineItem.prototype,
  'messageVisible',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'repeat',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHWorklineItem.prototype, 'float', void 0);
__decorate(
  [property({ type: String, reflect: true })],
  SHWorklineItem.prototype,
  'thumbnail',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHWorklineItem.prototype, 'new', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'type', void 0);
__decorate(
  [
    property({ type: Boolean, reflect: true }),
    /** @ignore */
  ],
  SHWorklineItem.prototype,
  'emptyMessage',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true, attribute: 'empty-info' }),
    /** @ignore */
  ],
  SHWorklineItem.prototype,
  'emptyinfo',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true, attribute: 'empty-info-right' }),
    /** @ignore */
  ],
  SHWorklineItem.prototype,
  'emptyinforight',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHWorklineItem.prototype,
  'iconColor',
  void 0
);
SHWorklineItem = __decorate([customElement('sh-workline-item')], SHWorklineItem);
export { SHWorklineItem };
