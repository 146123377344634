import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { sharedStyles } from '../../styles';
import { DateFormatHelper } from '../../utils/date-format-helper.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { MultiItemFocusBlurMixin } from '../../utils/multi-item-focus-blur-mixin.js';
import { generateYearData } from '../../utils/year-data-generator.js';
import privateMonthSelectorStyles from './sh-private-month-selector.lit.scss.js';
/**
 * Component that allows to select months from a
 * set of years.
 *
 * @fires year-header-clicked - Dispatched when clicked on the year-heading button. The decade to which the clicked year belongs is passed as part of detail object of event.
 * @fires month-clicked - Dispatched when clicked on a month in a year. The clicked month and the corresponding year to which the month belongs is passed as part of detail object of event.
 */
let SHPrivateMonthSelector = class SHPrivateMonthSelector extends MultiItemFocusBlurMixin(
  ShuiLitElement
) {
  constructor() {
    super(...arguments);
    /** The minimum date below which all dates will be disabled. `minDate` value should be of type Date.*/
    this.minDate = null;
    /** The maximum date above which all dates will be disabled. `maxDate` value should be of type Date.*/
    this.maxDate = null;
    /** @ignore */
    this.yearDecadeDatas = [];
    /** @ignore */
    this.monthViewDatas = [];
  }
  static get styles() {
    return [super.styles, sharedStyles, privateMonthSelectorStyles];
  }
  render() {
    return html`<div class="months">
      ${this.monthViewDatas.map(
        (monthViewData, yearIndex) =>
          html`<div class="date-panel">
            <div class="header-area">
              ${yearIndex === 0
                ? html`<sh-icon
                    icon="arrow-left-s"
                    button
                    size="m"
                    @click="${() => {
                      --this.year;
                      this.generateMonthViewData();
                    }}"
                  ></sh-icon>`
                : html`<div class="icon-dummy"></div>`}
              <div
                class="month-and-year-display focus-item"
                @click="${() => {
                  this.yearHeaderClickedEvent.emit(
                    new CustomEvent('year-header-clicked', {
                      detail: {
                        belongingDecade:
                          this.yearDecadeDatas[yearIndex].year -
                          (this.yearDecadeDatas[yearIndex].year % 10),
                      },
                    })
                  );
                }}"
                tabindex="0"
              >
                ${this.yearDecadeDatas[yearIndex].year}
              </div>
              ${yearIndex === this.monthViewDatas.length - 1
                ? html`<sh-icon
                    icon="arrow-right-s"
                    button
                    size="m"
                    @click="${() => {
                      ++this.year;
                      this.generateMonthViewData();
                    }}"
                  ></sh-icon>`
                : html`<div class="icon-dummy"></div>`}
            </div>
            <div class="date-grid">
              <span
                class="${classMap(this.getComputedClasses(monthViewData[0]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 0,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[0].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(0, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[1]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 1,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[1].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(1, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[2]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 2,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[2].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(2, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[3]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 3,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[3].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(3, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[4]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 4,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[4].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(4, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[5]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 5,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[5].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(5, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[6]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 6,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[6].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(6, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[7]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 7,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[7].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(7, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[8]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 8,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[8].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(8, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[9]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 9,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[9].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(9, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[10]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 10,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[10].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(10, this.locale)}
              </span>
              <span
                class="${classMap(this.getComputedClasses(monthViewData[11]))}"
                @click="${() => {
                  this.monthClickedEvent.emit(
                    new CustomEvent('month-clicked', {
                      detail: {
                        clickedMonth: 11,
                        clickedYear: this.yearDecadeDatas[yearIndex].year,
                      },
                    })
                  );
                }}"
                tabindex="${monthViewData[11].isDisabled ? '-1' : '0'}"
              >
                ${DateFormatHelper.getShortMonthName(11, this.locale)}
              </span>
            </div>
          </div>`
      )}
    </div>`;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    } else {
      this.setAttribute('tabindex', '0');
    }
  }
  generateMonthViewData() {
    // eslint-disable-next-line prefer-const
    let year = this.year;
    const monthViewDatas = [];
    const yearDecadeDatas = [];
    for (let i = 0; i < this.months; i++) {
      monthViewDatas.push(
        generateYearData(
          year,
          this.todaysDate,
          this.selectedDate,
          this.futureDateDisable,
          this.pastDateDisable,
          this.minDate,
          this.maxDate
        )
      );
      yearDecadeDatas.push({ year: year, decade: year - (year % 10) });
      ++year;
    }
    this.monthViewDatas = monthViewDatas;
    this.yearDecadeDatas = yearDecadeDatas;
  }
  /**
   *
   * @param {MonthData} monthObject
   */
  getComputedClasses(monthObject) {
    return {
      month: true,
      'focus-item': true,
      today: monthObject.isThisMonth,
      selected: monthObject.isSelected,
      disabled: monthObject.isDisabled,
    };
  }
  _enterKeyAction(focusedElement) {
    if (focusedElement !== this) {
      focusedElement.click();
    }
  }
};
__decorate([property({ type: Number })], SHPrivateMonthSelector.prototype, 'months', void 0);
__decorate([property({ type: Number })], SHPrivateMonthSelector.prototype, 'year', void 0);
__decorate([property({ type: Object })], SHPrivateMonthSelector.prototype, 'todaysDate', void 0);
__decorate([property({ type: Object })], SHPrivateMonthSelector.prototype, 'selectedDate', void 0);
__decorate(
  [property({ type: Boolean })],
  SHPrivateMonthSelector.prototype,
  'futureDateDisable',
  void 0
);
__decorate(
  [property({ type: Boolean })],
  SHPrivateMonthSelector.prototype,
  'pastDateDisable',
  void 0
);
__decorate([property({ type: Object })], SHPrivateMonthSelector.prototype, 'minDate', void 0);
__decorate([property({ type: Object })], SHPrivateMonthSelector.prototype, 'maxDate', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHPrivateMonthSelector.prototype,
  'responsive',
  void 0
);
__decorate([property({ type: String })], SHPrivateMonthSelector.prototype, 'locale', void 0);
__decorate(
  [property({ type: Array })],
  SHPrivateMonthSelector.prototype,
  'yearDecadeDatas',
  void 0
);
__decorate([property({ type: Array })], SHPrivateMonthSelector.prototype, 'monthViewDatas', void 0);
__decorate([event()], SHPrivateMonthSelector.prototype, 'yearHeaderClickedEvent', void 0);
__decorate([event()], SHPrivateMonthSelector.prototype, 'monthClickedEvent', void 0);
SHPrivateMonthSelector = __decorate(
  [customElement('sh-private-month-selector')],
  SHPrivateMonthSelector
);
export { SHPrivateMonthSelector };
