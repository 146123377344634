// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-thumbnail.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-thumbnail-image-wrapper-padding=0.5rem] - padding of image wrapper
   */
  --sh-thumbnail-image-wrapper-padding: 0.5rem;
  /* 
   * @cssprop [--sh-thumbnail-label-wrapper-margin=-0.25rem 0.5rem 0.25rem 0.5rem] - margin of label wrapper
   */
  --sh-thumbnail-label-wrapper-margin: -0.25rem 0.5rem 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-thumbnail-label-height=2rem] - height of label
   */
  --sh-thumbnail-label-height: 2rem;
  /* 
   * @cssprop [--sh-thumbnail-image-border-radius=var(--sh-radius-small-control)] - border radius of the image
   */
  --sh-thumbnail-image-border-radius: var(--sh-radius-small-control);
  /* 
   * @cssprop [--sh-thumbnail-border-radius=var(--sh-radius-control)] - border radius of the thumbnail
   */
  --sh-thumbnail-border-radius: var(--sh-radius-control);
  /* 
   * @cssprop [--sh-thumbnail-image-wrapper-padding-condensed=0.25rem] - padding of image wrapper in condensed mode
   */
  --sh-thumbnail-image-wrapper-padding-condensed: 0.25rem;
  /* 
   * @cssprop [--sh-thumbnail-label-wrapper-margin-condensed=0rem 0.25rem 0.25rem 0.25rem] - padding of image wrapper in condensed mode
   */
  --sh-thumbnail-label-wrapper-margin-condensed: 0rem 0.25rem 0.25rem 0.25rem;
  /* 
   * @cssprop [--sh-thumbnail-label-line-height=1rem] - line height of the label
   */
  --sh-thumbnail-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-thumbnail-icons-wrapper-margin=0rem 0rem 0.25rem 0.25rem] - margin of the icons in icons wrapper
   */
  --sh-thumbnail-icons-wrapper-margin: 0rem 0rem 0.25rem 0.25rem;
  /* 
   * @cssprop [--sh-thumbnail-thumbnail-border=0.0625rem] - border of the thumbnail
   */
  --sh-thumbnail-thumbnail-border: 0.0625rem;
  /* 
   * @cssprop [--sh-thumbnail-checkbox-border-radius=0.125rem] - border radius of the thumbnail checkbox
   */
  --sh-thumbnail-checkbox-border-radius: 0.125rem;
  /* 
   * @cssprop [--sh-thumbnail-new-icon-width=0.5rem] - width of the new icon
   */
  --sh-thumbnail-new-icon-width: 0.5rem;
  /* 
   * @cssprop [--sh-thumbnail-new-icon-height=0.5rem] - height of the new icon
   */
  --sh-thumbnail-new-icon-height: 0.5rem;
  /* 
   * @cssprop [--sh-thumbnail-new-icon-margin=0.5rem] - margin of the new icon
   */
  --sh-thumbnail-new-icon-margin: 0.5rem;
  /* 
   * @cssprop [--sh-thumbnail-badge-width=1rem] - width of the badge
   */
  --sh-thumbnail-badge-width: 1rem;
  /* 
   * @cssprop [--sh-thumbnail-badge-margin=0.25rem] - margin of the badge
   */
  --sh-thumbnail-badge-margin: 0.25rem;
  /* 
   * @cssprop [--sh-thumbnail-indications-wrapper-margin=0.125rem 0.125rem 0 0] - margin of the indications wrapper
   */
  --sh-thumbnail-indications-wrapper-margin: 0.125rem 0.125rem 0 0;
  /* 
   * @cssprop [--sh-thumbnail-indications-wrapper-margin-left=0.25rem] - margin of the indications wrapper
   */
  --sh-thumbnail-indications-wrapper-margin-left: 0.25rem;
  /* 
   * @cssprop [--sh-thumbnail-info-slot-wrapper-bottom=0.625rem] - vertical position of the info slot wrapper
   */
  --sh-thumbnail-info-slot-wrapper-bottom: 0.625rem;
  /* 
   * @cssprop [--sh-thumbnail-info-slot-wrapper-left=0.625rem] - horizontal position of the info slot wrapper
   */
  --sh-thumbnail-info-slot-wrapper-left: 0.625rem;
  /* 
   * @cssprop [--sh-thumbnail-info-slot-wrapper-padding=0.25rem] - padding of the info slot wrapper
   */
  --sh-thumbnail-info-slot-wrapper-padding: 0.25rem;
  /* 
   * @cssprop [--sh-thumbnail-checkbox-height=2rem] - height of the checkbox
   */
  --sh-thumbnail-checkbox-height: 2rem;
  /* 
   * @cssprop [--sh-thumbnail-checkbox-width=2rem] - height of the checkbox
   */
  --sh-thumbnail-checkbox-width: 2rem;
  /* 
   * @cssprop [--sh-thumbnail-thumbnail-icon-bottom=0.625rem] - vertical position of the thumbnail icon
   */
  --sh-thumbnail-thumbnail-icon-bottom: 0.625rem;
  /* 
   * @cssprop [--sh-thumbnail-thumbnail-icon-right=0.625rem] - horizontal position of the thumbnail icon
   */
  --sh-thumbnail-thumbnail-icon-right: 0.625rem;
  /* 
   * @cssprop [--sh-thumbnail-thumbnail-checkbox-top=0.625rem] - vertical position of the thumbnail checkbox
   */
  --sh-thumbnail-thumbnail-checkbox-top: 0.625rem;
  /* 
   * @cssprop [--sh-thumbnail-thumbnail-checkbox-left=0.625rem] - horizontal position of the thumbnail checkbox
   */
  --sh-thumbnail-thumbnail-checkbox-left: 0.625rem;
  /* 
   * @cssprop [--sh-thumbnail-label-margin-bottom=0rem] - margin bottom for label
   */
  --sh-thumbnail-label-margin-bottom: 0rem;
  /* 
   * @cssprop [--sh-thumbnail-img-size=contain] - background size of thumbnail image
   */
  --sh-thumbnail-img-size: contain;
  /* 
   * @cssprop [--sh-thumbnail-img-position=center center] - background position of thumbnail image
   */
  --sh-thumbnail-img-position: center center;
  /* 
   * @cssprop [--sh-thumbnail-img-repeat=no-repeat] - background repeat of thumbnail image
   */
  --sh-thumbnail-img-repeat: no-repeat;
}

:host {
  display: block;
  height: fit-content;
  width: 100%;
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) background;
  position: relative;
  border-radius: var(--sh-thumbnail-border-radius);
  border: var(--sh-thumbnail-thumbnail-border) solid transparent;
  box-sizing: border-box;
}

:host([selectable]:not([checked]):not(:hover)) .thumbnail-checkbox,
.thumbnail-wrapper:not([empty-data-type]) .thumbnail-icon {
  display: none;
}

.thumbnail-wrapper {
  display: block;
  cursor: pointer;
  color: rgba(var(--ui-1), var(--opacity-3));
  position: relative;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--sh-thumbnail-image-wrapper-padding);
}

:host([condensed]) .image-wrapper {
  padding: var(--sh-thumbnail-image-wrapper-padding-condensed);
}

.image {
  background-color: black;
  background-size: var(--sh-thumbnail-img-size, contain);
  background-position: var(--sh-thumbnail-img-position, center center);
  background-repeat: var(--sh-thumbnail-img-repeat, no-repeat);
  width: 100%;
  padding-top: 100%;
  box-sizing: border-box;
  transition: var(--transition-time) all ease-in-out;
  border-radius: var(--sh-thumbnail-image-border-radius);
}

.label {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex: 1;
  height: var(--sh-thumbnail-label-height);
  line-height: var(--sh-thumbnail-label-line-height);
  margin-bottom: var(--sh-thumbnail-label-margin-bottom);
}

.label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  color: rgba(var(--ui-1), var(--opacity-3));
  margin: var(--sh-thumbnail-label-wrapper-margin);
}

:host([condensed]) .label-wrapper {
  margin: var(--sh-thumbnail-label-wrapper-margin-condensed);
}

:host([selectable].touch-deviceq) .thumbnail-checkbox,
:host([selectable].touch-device:not([checked]):not(:hover)) .thumbnail-checkbox,
:host([selectable]:hover) .thumbnail-checkbox,
:host([checked]) .thumbnail-checkbox {
  display: inline-block;
  position: absolute;
  top: var(--sh-thumbnail-thumbnail-checkbox-top);
  left: var(--sh-thumbnail-thumbnail-checkbox-left);
  width: 1.5rem;
  padding: 0.25rem;
}

:host([selectable][condensed].touch-device) .thumbnail-checkbox,
:host([selectable][condensed].touch-device:not([checked]):not(:hover)) .thumbnail-checkbox,
:host([selectable][condensed]:hover) .thumbnail-checkbox,
:host([checked][condensed]) .thumbnail-checkbox {
  top: 0.375rem;
  left: 0.375rem;
}

:host([selectable][discard]) .thumbnail-checkbox {
  z-index: 1;
}

.thumbnail-checkbox::before,
.thumbnail-icon::before {
  top: 0rem;
  left: 0rem;
  content: "";
  background: var(--base-2);
  opacity: var(--opacity-2);
  position: absolute;
  border-radius: var(--sh-thumbnail-checkbox-border-radius);
}

.thumbnail-checkbox::before {
  height: var(--sh-thumbnail-checkbox-height);
  width: var(--sh-thumbnail-checkbox-width);
}

.thumbnail-icon::before {
  background: rgba(0, 0, 0, var(--opacity-3));
}

:host(:not([loading]):hover) {
  background: rgba(var(--ui-1), var(--opacity-7));
}

:host(:not([loading])[active]) {
  background: rgba(var(--ui-1), var(--opacity-6));
}

:host([active]) .label,
:host([visual-focus]) .label {
  color: rgba(var(--ui-1), var(--opacity-2));
}

:host(:not([loading])[visual-focus]) {
  border-color: rgb(var(--ui-0));
}

/* badge slot */
:host([badge]) .thumbnail-badge {
  display: block;
  width: var(--sh-thumbnail-badge-width);
  margin: var(--sh-thumbnail-badge-margin);
}

:host([badge=new]) .thumbnail-badge {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgb(var(--ui-0));
}

.thumbnail-icon {
  display: block;
  position: absolute;
  bottom: var(--sh-thumbnail-thumbnail-icon-bottom);
  right: var(--sh-thumbnail-thumbnail-icon-right);
  color: rgba(255, 255, 255, var(--opacity-3));
}

:host([condensed]) .thumbnail-icon {
  bottom: 0.375rem;
  right: 0.375rem;
}

/* icons slot */
.icons-wrapper {
  display: flex;
}

.icons-wrapper ::slotted(*) {
  margin: var(--sh-thumbnail-icons-wrapper-margin);
  color: var(--text-secondary);
}

:host(:not([condensed])) .icons-wrapper ::slotted(*:first-child) {
  margin-left: 0.5rem;
}

/* badge */
.new-icon {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  width: var(--sh-thumbnail-new-icon-width);
  margin: var(--sh-thumbnail-new-icon-margin);
  height: var(--sh-thumbnail-new-icon-height);
  border-radius: 0.5rem;
  background-color: rgb(var(--ui-0));
}

:host([condensed]) .new-icon {
  top: 0.375rem;
  right: 0.375rem;
}

/* info slot */
.info-slot-wrapper {
  display: flex;
  flex-direction: rows;
  position: absolute;
  bottom: var(--sh-thumbnail-info-slot-wrapper-bottom);
  left: var(--sh-thumbnail-info-slot-wrapper-left);
  background: var(--base-2);
  opacity: var(--opacity-2);
  padding: var(--sh-thumbnail-info-slot-wrapper-padding);
  border-radius: var(--sh-thumbnail-thumbnail-border-radius);
}

:host([condensed]) .info-slot-wrapper {
  bottom: 0.375rem;
  left: 0.375rem;
}

.thumbnail-wrapper[empty-info] .info-slot-wrapper {
  display: none;
}

:host([active]) .icons-wrapper ::slotted(*) {
  color: var(--text-primary);
}

/* data-type slot */
.data-type-wrapper {
  display: flex;
  bottom: 0.5rem;
  right: 0.5rem;
  margin: 0 0.125rem 0.125rem 0;
  position: absolute;
}

.thumbnail-wrapper[empty-data-type] .data-type-wrapper {
  display: none;
}

.data-type-wrapper ::slotted(*),
.indications-wrapper ::slotted(*) {
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  opacity: var(--opacity-3);
  margin-left: var(--sh-thumbnail-indications-wrapper-margin-left);
  border-radius: 0.125rem;
  line-height: 1.75rem;
}

:host([condensed]) .data-type-wrapper {
  bottom: 0.25rem;
  right: 0.25rem;
}

/* indications slot */
.indications-wrapper {
  display: flex;
  top: 0.5rem;
  right: 0.5rem;
  margin: var(--sh-thumbnail-indications-wrapper-margin);
  position: absolute;
}

:host([condensed]) .indications-wrapper {
  top: 0.25rem;
  right: 0.25rem;
}

sh-tooltip {
  max-width: 25rem;
  overflow-wrap: break-word;
}

sh-popover {
  pointer-events: all;
}

sh-overlay {
  background: transparent;
}

.discard-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, var(--opacity-3));
}

:host([discard]) .label {
  color: var(--text-disabled);
}

:host([discard]) .id-number,
:host([discard]) .icons-wrapper ::slotted(*),
:host([discard]) .data-type-wrapper ::slotted(*),
:host([discard]) .indications-wrapper ::slotted(*) {
  opacity: var(--opacity-4);
}

.discard-wrapper {
  position: absolute;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  background-color: rgba(0, 0, 0, var(--opacity-2));
  z-index: 1;
  display: flex;
}

.loading-wrapper {
  margin: 0.5rem;
  max-height: 100%;
}

.loading-image {
  border-radius: 0.125rem;
  margin-bottom: 0.25rem;
}

.loading-text {
  height: 1.5rem;
  width: 80%;
}

.loading {
  opacity: var(--opacity-5);
  background: linear-gradient(-70deg, rgba(var(--ui-1), var(--opacity-6)) 0%, rgba(var(--ui-1), var(--opacity-6)) 20%, rgba(var(--ui-1), var(--opacity-5)) 40%, rgba(var(--ui-1), var(--opacity-5)) 50%, rgba(var(--ui-1), var(--opacity-5)) 60%, rgba(var(--ui-1), var(--opacity-6)) 80%, rgba(var(--ui-1), var(--opacity-6)) 100%) repeat;
  background-size: 600% 200%;
  animation: gradient var(--animation-duration-thumbnail, 2s) ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
:host([outline-color]) .image {
  outline: 0.125rem solid var(--outline-color);
  box-shadow: inset 0px 0px 0px 0.125rem rgba(var(--ui-7), var(--opacity-2));
  border-radius: 0.0625rem;
}`;