// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ShuiLitElement } from '../../utils/event-decorator.js';
import homeScreenStyles from './sh-home-screen.lit.scss.js';
/**
 * @slot - Holds cards.
 */
let SHHomeScreen = class SHHomeScreen extends ShuiLitElement {
  constructor() {
    super(...arguments);
    this.iconVisible = false;
    /** Holds sh-menu-item for power-related functions such as Lock System, Restart, Shut Down, etc.. */
    this.functions = false;
    /** Defines mobile view */
    this.mobile = false;
  }
  static get styles() {
    return [homeScreenStyles];
  }
  render() {
    return html`
      <div class="cards-wrapper">
        <slot></slot>
      </div>
      ${this.iconVisible
        ? html` <sh-icon
            button
            icon="standby"
            id="functions-button"
            @click="${this.handleFunctions}"
          ></sh-icon>`
        : ''}
      <sh-card class="functions-card" ?active="${this.functions}">
        <div class="functions-div" @click="${this.handleFunctions}">
          <slot
            name="functions"
            @slotchange=${(e) => {
              this.slotChanged(e);
            }}
          ></slot>
        </div>
      </sh-card>
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    /** @ignore */
    this.windowDimensionObserver = this.handleResize.bind(this);
    window.addEventListener('resize', this.windowDimensionObserver);
  }
  disconnectedCallback() {
    window.removeEventListener('resize', this.windowDimensionObserver);
    super.disconnectedCallback();
  }
  handleResize() {
    this.mobile = document.documentElement.clientWidth < 767;
  }
  handleFunctions() {
    this.functions = !this.functions;
  }
  slotChanged(e) {
    const functionSlot = e.target;
    const nodes = functionSlot.assignedNodes({
      flatten: true,
    }).length;
    if (nodes > 0) {
      this.iconVisible = true;
    } else {
      this.iconVisible = false;
      this.functions = false;
    }
  }
};
__decorate([state()], SHHomeScreen.prototype, 'iconVisible', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHHomeScreen.prototype,
  'functions',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHHomeScreen.prototype, 'mobile', void 0);
SHHomeScreen = __decorate([customElement('sh-home-screen')], SHHomeScreen);
export { SHHomeScreen };
