// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
export const baseFontSize = 16;
export const calculatedBaseFontSize = getCalculatedBaseFontSize();
function getCalculatedBaseFontSize() {
  const calculatedBaseFontSize = parseFloat(
    window.getComputedStyle(document.querySelector('html'), null).getPropertyValue('font-size')
  );
  return calculatedBaseFontSize;
}
