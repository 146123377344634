import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import boxSectionStyles from './sh-box-section.lit.scss.js';
let SHBoxSection = class SHBoxSection extends LitElement {
  static get styles() {
    return [sharedStyles, boxSectionStyles];
  }
  render() {
    return html`<slot></slot> `;
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    if (!['wide', 'compact', 'supercompact', 'none'].includes(newval)) {
      const paddingAttributes = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'];
      if (name === 'padding') {
        this.style.setProperty(`--container-padding`, newval || `var(--container-padding)`);
        for (const eachItem of paddingAttributes) {
          this.style.setProperty(`--${eachItem}`, newval || `var(--${eachItem})`);
        }
      } else if (paddingAttributes.includes(name)) {
        this.style.setProperty(`--${name}`, newval || `var(--${name})`);
      }
    }
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHBoxSection.prototype,
  'background',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHBoxSection.prototype, 'padding', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-top' })],
  SHBoxSection.prototype,
  'paddingTop',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-right' })],
  SHBoxSection.prototype,
  'paddingRight',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-bottom' })],
  SHBoxSection.prototype,
  'paddingBottom',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'padding-left' })],
  SHBoxSection.prototype,
  'paddingLeft',
  void 0
);
SHBoxSection = __decorate([customElement('sh-box-section')], SHBoxSection);
export { SHBoxSection };
