// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

import { LitElement } from 'lit';
import { keyboardInteraction } from './keyboardInteraction.js';
import { KEYCODE } from './keycode.js';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type Constructor<T> = new (...args: any[]) => T;

/** ### FocusBlurMixin
 * ___
 * Superclass aimed to control the common events like `focus`
 * and `blur` along with `tabKey` and `spaceKey` functionality
 * for the components that import this mixin,
 * thereby reducing code duplication.
 * ___
 * #### How to use this mixin ?
 *
 * Import like this in the component:
 * ```js
 * import { FocusBlurMixin } from '@shui-web-components/utils/focus-blur-mixin.js';
 * ```
 * Then inherit this mixin like this:
 * ```js
 * export class SHComponent extends FocusBlurMixin(LitElement)
 * ```
 * ___
 *
 * #### Motivations for using this mixin ?
 *
 * -  the eventListener `focus` and `blur` and the subsequent handling of
 *    the functionality when `tabKey` or `spaceKey` is pressed is common among components.
 *    Thus grouping in a common class promotes code reusability and
 *    avoids code duplication. This in-turn helps in reducing the
 *    file size of the final build.
 * ___
 *
 * #### How does this mixin work ?
 *
 * - The eventListeners `focus` and `blur` are added to the host.
 *
 * #### Focus + Tab Key
 * - Pressing the tab key to focus the component adds a class by the name `item-focused`
 *   which will be present in the css of the respective component, where the outline will be defined.
 *
 * - This mixin gives the ease to add the blue outline while tabbing on any element by just adding a class `focus-item`
 *   which is then fetched by the mixin and thus class `item-focused` is added respectively.
 *
 * #### Focus + Space Key
 * - On pressing space key, a `_spaceKeyAction()` is called, which can be defined in the respective host component.
 *   `_spaceKeyAction()` will contain the respective actions that needs to be performed.
 *
 * - In case for components that do not have a defined behavior can either overwrite or do nothing.
 *
 * ___
 * Note:
 * The mixin will only work on components which are not `disabled` and thus
 * have a `tabindex` set;
 *
 */

export const FocusBlurMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class FocusBlurMixinElementextends extends superClass {
    protected handleFocus() {
      this.addEventListener('keyup', this.handleKeyup);
    }

    protected handleBlur() {
      this.removeEventListener('keyup', this.handleKeyup);
      const focusItem = this.shadowRoot?.querySelector('.focus-item');
      if (focusItem) {
        focusItem.classList.remove('item-focused');
      } else {
        this.classList.remove('item-focused');
      }
    }

    override firstUpdated() {
      this.addEventListener('focus', () => this.handleFocus());
      this.addEventListener('blur', () => this.handleBlur());
    }

    protected handleKeyup(e: KeyboardEvent) {
      const focusItem = this.shadowRoot?.querySelector('.focus-item');
      if (
        keyboardInteraction(e, KEYCODE.TAB) &&
        !(this as unknown as { disabled: boolean }).disabled
      ) {
        if (focusItem) {
          focusItem.classList.add('item-focused');
        } else {
          this.classList.add('item-focused');
        }
      }
      if (keyboardInteraction(e, KEYCODE.SPACE)) {
        this._spaceKeyAction();
      }
      if (keyboardInteraction(e, KEYCODE.ENTER)) {
        this._enterKeyAction();
      }
    }

    protected _spaceKeyAction() {
      return;
    }

    protected _enterKeyAction() {
      return;
    }
  }
  return FocusBlurMixinElementextends as unknown as Constructor<FocusBlurMixinInterface> & T;
};

/**
 * due to typescript limitations we must explicitly define a clas
 * with the externally used api here and cast the return value of our
 * mixin to this (intermediate unknown cast is so we don't need to have to list irrelevant fields)
 * read this: https://lit.dev/docs/composition/mixins/#mixins-in-typescript
 */
export declare class FocusBlurMixinInterface {
  protected handleKeyup(e: KeyboardEvent): void;
  protected _spaceKeyAction(): void;
  protected _enterKeyAction(): void;
  protected handleBlur(): void;
  protected handleFocus(): void;
}
