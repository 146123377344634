import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles/shared-styles.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
export const progressRadialListOfProperties = ['header', 'label', 'info', 'color', 'size', 'value'];
/**
 * @slot functions - Takes Icons and Text Links as children. Should be used for actions such as pause, cancel, resume.
 * @fires header-changed {HeaderPropertyChangedEvent} - *hide emitted when the header property changes.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires info-changed {InfoPropertyChangedEvent} - *hide emitted when the info property changes.
 * @fires color-changed {ColorPropertyChangedEvent} - *hide emitted when the color property changes.
 * @fires size-changed {SizePropertyChangedEvent} - *hide emitted when the size property changes.
 * @fires value-changed {ValuePropertyChangedEvent} - *hide emitted when the value property changes.
 */
let SHProgressRadial = class SHProgressRadial extends ShuiLitElement {
  static get styles() {
    return [sharedStyles];
  }
  render() {
    return html`
      <sh-progress
        radial
        .header="${this.header}"
        .label="${this.label}"
        .info="${this.info}"
        .color="${this.color}"
        .size="${this.size}"
        .value="${this.value}"
        .indeterminate="${this.indeterminate}"
        .overlay="${this.overlay}"
      ></sh-progress>
    `;
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, progressRadialListOfProperties);
  }
  constructor() {
    super();
    this.value = 0;
    this.size = 'm';
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'progressbar');
    }
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHProgressRadial.prototype,
  'header',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHProgressRadial.prototype,
  'label',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHProgressRadial.prototype, 'info', void 0);
__decorate(
  [property({ type: String, reflect: true })],
  SHProgressRadial.prototype,
  'status',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHProgressRadial.prototype,
  'color',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHProgressRadial.prototype, 'size', void 0);
__decorate(
  [property({ type: Number, reflect: true })],
  SHProgressRadial.prototype,
  'value',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHProgressRadial.prototype, 'type', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHProgressRadial.prototype,
  'indeterminate',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHProgressRadial.prototype,
  'paused',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHProgressRadial.prototype,
  'overlay',
  void 0
);
__decorate([event()], SHProgressRadial.prototype, 'headerChangedEvent', void 0);
__decorate([event()], SHProgressRadial.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHProgressRadial.prototype, 'infoChangedEvent', void 0);
__decorate([event()], SHProgressRadial.prototype, 'colorChangedEvent', void 0);
__decorate([event()], SHProgressRadial.prototype, 'sizeChangedEvent', void 0);
__decorate([event()], SHProgressRadial.prototype, 'valueChangedEvent', void 0);
SHProgressRadial = __decorate([customElement('sh-progress-radial')], SHProgressRadial);
export { SHProgressRadial };
