import { __awaiter, __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { convertToHourMinSec, getUpdatedTime } from '../../utils/media-time-converter.js';
import mediaProgressStyles from './sh-media-progress.lit.scss.js';
let SHMediaProgress = class SHMediaProgress extends LitElement {
  constructor() {
    super(...arguments);
    /** @ignore */
    this.progress = 0;
  }
  static get styles() {
    return [extendInteractiveArea('.input::-webkit-slider-thumb'), mediaProgressStyles];
  }
  render() {
    return html`
      <div class="progress-wrapper" style="--gradient-value: ${this.progress}">
        <input
          class="input"
          type="range"
          step="any"
          max="100"
          min="0"
          value="0"
          @click="${(e) => this.handleSliderMove(e)}"
          @input="${(e) => this.handleSliderMove(e)}"
          @mousedown="${() => this.handleMouseDown()}"
          @touchstart="${() => this.handleMouseDown()}"
          @mouseup="${() => this.handleMouseUp()}"
          @touchend="${() => this.handleMouseUp()}"
          aria-label="video time scrubber"
        />

        <sh-tooltip class="tooltip" visible
          >${this.getCurrentTime('current').toString()}</sh-tooltip
        >
        <div class="slider-progress"></div>
        <div class="buffer-progress"></div>
      </div>
    `;
  }
  handleMouseUp() {
    setTimeout(() => {
      var _a, _b;
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.input')) ===
        null || _b === void 0
        ? void 0
        : _b.classList.remove('active');
      this.removeAttribute('isDrag');
    }, 200);
  }
  firstUpdated() {
    var _a;
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    const controls = this.getRootNode().host;
    const player = controls === null || controls === void 0 ? void 0 : controls.parentElement;
    this.video =
      player === null || player === void 0 ? void 0 : player.getElementsByTagName('video')[0];
    (_a = this.video) === null || _a === void 0
      ? void 0
      : _a.addEventListener('timeupdate', (e) => this.updateProgressBar(e));
  }
  handleSliderMove(event) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      const input = event.target;
      const trackProgress =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.slider-progress');
      const tempSliderValue = parseFloat(input === null || input === void 0 ? void 0 : input.value);
      if (deviceIdentifier.isTouchDevice()) {
        setTimeout(
          () =>
            __awaiter(this, void 0, void 0, function* () {
              this.progress =
                (tempSliderValue /
                  parseFloat(input === null || input === void 0 ? void 0 : input.max)) *
                100;
              trackProgress.style.width = `${this.progress}%`;
            }),
          150
        );
      } else {
        this.progress =
          (tempSliderValue / parseFloat(input === null || input === void 0 ? void 0 : input.max)) *
          100;
        trackProgress.style.width = `${this.progress}%`;
      }
      dispatchCustomEvent(this, 'progress-changed', event.target);
    });
  }
  updateProgressBar(e) {
    var _a, _b, _c;
    const videoProgressDiv =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.slider-progress');
    const bufferProgressDiv =
      (_b = this.shadowRoot) === null || _b === void 0
        ? void 0
        : _b.querySelector('.buffer-progress');
    const input =
      (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('.input');
    const progress = (e.target.currentTime / e.target.duration) * 100;
    videoProgressDiv.style.width = `${progress}%`;
    input.value = progress.toString();
    if (e.target.buffered.length > 0) {
      const bufferedProgress =
        (e.target.buffered.end(e.target.buffered.length - 1) / e.target.duration) * 100;
      bufferProgressDiv.style.width = `${bufferedProgress}%`;
    }
  }
  getCurrentTime(isPrimarySecondary) {
    var _a;
    let time = 0;
    if (isPrimarySecondary === 'current') {
      time = (_a = this.video) === null || _a === void 0 ? void 0 : _a.currentTime;
    }
    let resultTime = '00:00';
    if (time) {
      const obj1 = convertToHourMinSec(time);
      resultTime = getUpdatedTime(obj1.h, obj1.m, obj1.s);
    }
    return resultTime;
  }
  handleMouseDown() {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      const input =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.input');
      input === null || input === void 0 ? void 0 : input.classList.add('active');
      this.setAttribute('isDrag', 'true');
    });
  }
};
__decorate(
  [property({ type: Number, reflect: false, attribute: 'progress' })],
  SHMediaProgress.prototype,
  'progress',
  void 0
);
SHMediaProgress = __decorate([customElement('sh-media-progress')], SHMediaProgress);
export { SHMediaProgress };
