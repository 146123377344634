// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { property } from 'lit/decorators.js';
import { dispatchAttributeChangedEventTypesafe, event } from './event-decorator.js';
export const ToggleMixinListOfProperties = ['active', 'neutral'];
export function CheckboxToggleMixin(superClass) {
  /**
   * @mixin CheckboxToggleMixinElement
   * @fires active-changed {ActivePropertyChangedEvent} - *hide Event emitted when active property changes
   * @fires neutral-changed {NeutralPropertyChangedEvent} -*hide Event emitted when active property changes
   */
  class CheckboxToggleMixinElement extends superClass {
    updated(changedProperties) {
      super.updated(changedProperties);
      dispatchAttributeChangedEventTypesafe(this, changedProperties, ToggleMixinListOfProperties);
    }
    firstUpdated() {
      this.addEventListener('click', () => this.handleClick());
      this.addEventListener('focus', () => this.handleFocus());
      this.addEventListener('blur', () => this.handleBlur());
    }
    handleClick() {
      this.active = !this.active;
    }
  }
  __decorate(
    [property({ type: Boolean, reflect: true })],
    CheckboxToggleMixinElement.prototype,
    'active',
    void 0
  );
  __decorate(
    [property({ type: Boolean, reflect: true })],
    CheckboxToggleMixinElement.prototype,
    'neutral',
    void 0
  );
  __decorate([event()], CheckboxToggleMixinElement.prototype, 'activeChangedEvent', void 0);
  __decorate([event()], CheckboxToggleMixinElement.prototype, 'neutralChangedEvent', void 0);
  return CheckboxToggleMixinElement;
}
