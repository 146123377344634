// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-icon.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-icon-size-xs=1rem] - size of icon
   */
  --sh-icon-size-xs: 1rem;
  /* 
   * @cssprop [--sh-icon-size-s=1.5rem] - size of icon
   */
  --sh-icon-size-s: 1.5rem;
  /* 
   * @cssprop [--sh-icon-size-m=2rem] - size of icon
   */
  --sh-icon-size-m: 2rem;
  /* 
   * @cssprop [--sh-icon-size-l=3rem] - size of icon
   */
  --sh-icon-size-l: 3rem;
  /* 
   * @cssprop [--sh-icon-font-size-xs=1rem] - size of icon image
   */
  --sh-icon-font-size-xs: 1rem;
  /* 
   * @cssprop [--sh-icon-font-size-s=1.5rem] - size of icon image
   */
  --sh-icon-font-size-s: 1.5rem;
  /* 
   * @cssprop [--sh-icon-font-size-m=2rem] - size of icon image
   */
  --sh-icon-font-size-m: 2rem;
  /* 
   * @cssprop [--sh-icon-font-size-l=3rem] - size of icon image
   */
  --sh-icon-font-size-l: 3rem;
  /* 
   * @cssprop [--sh-icon-badge-left=calc(100% - 0.25rem)] - left position the badg will be centered around
   */
  --sh-icon-badge-left: calc(100% - 0.25rem);
  /* 
   * @cssprop [--sh-icon-badge-bottom=calc(100% - 0.25rem)] - bottom position the badg will be centered around
   */
  --sh-icon-badge-bottom: calc(100% - 0.25rem);
  /* 
   * @cssprop [--sh-icon-interactive-background-width-size-xs=1.5rem] - interactive background width for size xs
   */
  --sh-icon-interactive-background-width-size-xs: 1.5rem;
  /* 
   * @cssprop [--sh-icon-interactive-background-width-size-s=2rem] - interactive background width for size s
   */
  --sh-icon-interactive-background-width-size-s: 2rem;
  /* 
   * @cssprop [--sh-icon-interactive-background-width-size-m=2.5rem] - interactive background width for size m
   */
  --sh-icon-interactive-background-width-size-m: 2.5rem;
  /* 
   * @cssprop [--sh-icon-interactive-background-width-size-l=3.25rem] - interactive background width for size l
   */
  --sh-icon-interactive-background-width-size-l: 3.25rem;
  /* 
   * @cssprop [--sh-icon-interactive-background-height-size-xs=1.5rem] - interactive background height for size xs
   */
  --sh-icon-interactive-background-height-size-xs: 1.5rem;
  /* 
   * @cssprop [--sh-icon-interactive-background-height-size-s=2rem] - interactive background height for size s
   */
  --sh-icon-interactive-background-height-size-s: 2rem;
  /* 
   * @cssprop [--sh-icon-interactive-background-height-size-m=2.5rem] - interactive background height for size m
   */
  --sh-icon-interactive-background-height-size-m: 2.5rem;
  /* 
   * @cssprop [--sh-icon-interactive-background-height-size-l=3.25rem] - interactive background height for size l
   */
  --sh-icon-interactive-background-height-size-l: 3.25rem;
  /* 
   * @cssprop [--sh-icon-border-radius=var(--sh-radius-control)] - button active border radius
   */
  --sh-icon-border-radius: var(--sh-radius-control);
}

:host {
  display: flex;
  color: var(--text-primary);
  outline: 0rem;
  position: relative;
  align-items: center;
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: "sh-icons";
}
:host > div {
  position: absolute;
  flex: 1;
}
:host > div:after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
}
:host > .first {
  background-size: var(--sh-tool-bg-size, contain);
  background-position: var(--sh-tool-bg-position, center center);
  background-repeat: var(--sh-tool-bg-repeat, no-repeat);
}

:host([button]) > div {
  transition: var(--hover-transition-time) opacity;
}

:host([button]) {
  color: rgba(var(--ui-1), var(--opacity-1000));
}

:host(.item-focused) {
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
}

:host(:not([icon])) {
  display: none;
}

:host([disabled]) {
  pointer-events: none;
  cursor: default;
  opacity: var(--opacity-300);
}

.badge {
  position: absolute;
  z-index: 1;
  bottom: var(--sh-icon-badge-bottom);
  left: var(--sh-icon-badge-left);
  transform: translate(-50%, 50%);
}

.badge[label=""],
.badge[label=""]:not(.number) {
  top: 0rem;
  right: 0rem;
  left: unset;
  transform: none;
}

:host([disabled]) .badge {
  pointer-events: none;
  cursor: default;
}

:host([button]:not([disabled]):not(.accordion-expand)) {
  cursor: pointer;
}
:host([button]:not([disabled]):not(.accordion-expand)) > .first,
:host([button]:not([disabled]):not(.accordion-expand)) > .second,
:host([button]:not([disabled]):not(.accordion-expand)) > .after {
  opacity: var(--icon-idle-opacity);
}

:host([button]:not(:hover):not([disabled]):not(.accordion-expand):not([active])) > .first,
:host([button]:not(:hover):not([disabled]):not(.accordion-expand):not([active])) > .second,
:host([button][ix-background]:not([disabled]):not([active])) > .first,
:host([button][ix-background]:not([disabled]):not([active])) > .second,
:host([button][ix-background]:hover:not(.touch-device):not([disabled]):not([active])) > .first,
:host([button][ix-background]:hover:not(.touch-device):not([disabled]):not([active])) > .second,
:host([button][toggle]:hover:not(.touch-device):not([disabled]):not([active])) > .first,
:host([button][toggle]:hover:not(.touch-device):not([disabled]):not([active])) > .second,
:host([button][toggle][ix-background]:hover:not(.touch-device):not([disabled]):not([active])) > .first,
:host([button][toggle][ix-background]:hover:not(.touch-device):not([disabled]):not([active])) > .second,
:host([button][toggle][ix-background]:active:not(.touch-device):not([disabled]):not([active])) > .first,
:host([button][toggle][ix-background]:active:not(.touch-device):not([disabled]):not([active])) > .second {
  opacity: var(--icon-idle-opacity);
}

/* hover*/
:host([button]:hover:not(:active):not(.touch-device):not([ix-background]):not([disabled]):not([toggle])) > .first,
:host([button]:hover:not(:active):not(.touch-device):not([ix-background]):not([disabled]):not([toggle])) > .second,
:host([button]:not(:active).touch-device):hover > .first,
:host([button]:not(:active).touch-device):hover > .second,
:host([button]:not(:active):not(.touch-device)[disabled]) > .first,
:host([button]:not(:active):not(.touch-device)[disabled]) > .second,
:host([button][toggle][active]) > .first,
:host([button][toggle][active]) > .second,
:host([button][toggle][active][ix-background]) > .first,
:host([button][toggle][active][ix-background]) > .second {
  opacity: var(--opacity-950);
}

/*pressed*/
:host([button]:active:not(:hover):not(.touch-device):not([ix-background]):not([toggle]):not([active])) > .first,
:host([button]:active:not(:hover):not(.touch-device):not([ix-background]):not([toggle]):not([active])) > .second,
:host([button]:active.touch-device):not([ix-background]):not([toggle]) > .first,
:host([button]:active.touch-device):not([ix-background]):not([toggle]) > .second {
  opacity: var(--opacity-600);
}

:host([button][active]) .interactive-background,
:host([button][active][toggle][ix-background]) .interactive-background {
  background: rgba(var(--ui-1), var(--opacity-100));
  border-radius: var(--sh-icon-border-radius);
  color: rgba(var(--ui-1), var(--opacity-950));
  border: 1px solid rgba(var(--ui-1), var(--opacity-50));
  box-sizing: border-box;
}

:host([size=xs]) .interactive-background {
  width: var(--sh-icon-interactive-background-width-size-xs);
  height: var(--sh-icon-interactive-background-height-size-xs);
}

:host([size=s]) .interactive-background {
  width: var(--sh-icon-interactive-background-width-size-s);
  height: var(--sh-icon-interactive-background-height-size-s);
}

:host([size=m]) .interactive-background {
  width: var(--sh-icon-interactive-background-width-size-m);
  height: var(--sh-icon-interactive-background-height-size-m);
}

:host([size=l]) .interactive-background {
  width: var(--sh-icon-interactive-background-width-size-l);
  height: var(--sh-icon-interactive-background-height-size-l);
}

:host([button][ix-background]:hover:not(.touch-device):not([disabled])) .interactive-background,
:host([button][toggle]:hover:not(.touch-device):not([disabled])) .interactive-background {
  background: rgba(var(--ui-1), var(--opacity-50));
  color: rgba(var(--ui-1), var(--opacity-700));
  border-radius: var(--sh-icon-border-radius);
}

:host([button][ix-background]:active:not(.touch-device):not([disabled])) .interactive-background,
:host([button][toggle]:active:not(.touch-device):not([disabled])) .interactive-background {
  background: rgba(var(--ui-1), var(--opacity-100));
  color: rgba(var(--ui-1), var(--opacity-700));
  border-radius: var(--sh-icon-border-radius);
}

:host([button][active]:hover:not(.touch-device):not([disabled])) .interactive-background,
:host([button][active][toggle][ix-background]:hover:not(.touch-device):not([disabled])) .interactive-background {
  background: rgba(var(--ui-1), var(--opacity-100));
  border-radius: var(--sh-icon-border-radius);
  color: rgba(var(--ui-1), var(--opacity-950));
  border: 1px solid rgba(var(--ui-1), var(--opacity-200));
  box-sizing: border-box;
}

:host([button][active]:active:not(.touch-device):not([disabled])) .interactive-background,
:host([button][active][toggle][ix-background]:active:not(.touch-device):not([disabled])) .interactive-background {
  background: rgba(var(--ui-1), var(--opacity-50));
  border-radius: var(--sh-icon-border-radius);
  color: rgba(var(--ui-1), var(--opacity-950));
  border: 1px solid rgba(var(--ui-1), var(--opacity-100));
  box-sizing: border-box;
}

:host(.badge-width) .badge {
  width: 1.375rem;
}

:host([size=xs]),
:host([size=xs]) > div {
  min-width: var(--sh-icon-font-size-xs);
  min-height: var(--sh-icon-font-size-xs);
  height: var(--sh-icon-font-size-xs);
  width: var(--sh-icon-font-size-xs);
  font-size: var(--sh-icon-font-size-xs);
  line-height: var(--sh-icon-font-size-xs);
}

:host([size=xs][button]) {
  min-width: var(--sh-icon-size-xs);
  min-height: var(--sh-icon-size-xs);
  width: var(--sh-icon-size-xs);
  height: var(--sh-icon-size-xs);
}

:host([size=s]),
:host([size=s]) > div {
  min-width: var(--sh-icon-font-size-s);
  min-height: var(--sh-icon-font-size-s);
  height: var(--sh-icon-font-size-s);
  width: var(--sh-icon-font-size-s);
  font-size: var(--sh-icon-font-size-s);
  line-height: var(--sh-icon-font-size-s);
}

:host([size=s][button]) {
  min-width: var(--sh-icon-size-s);
  min-height: var(--sh-icon-size-s);
  width: var(--sh-icon-size-s);
  height: var(--sh-icon-size-s);
}

:host([size=m]),
:host([size=m]) > div {
  min-width: var(--sh-icon-font-size-m);
  min-height: var(--sh-icon-font-size-m);
  height: var(--sh-icon-font-size-m);
  width: var(--sh-icon-font-size-m);
  font-size: var(--sh-icon-font-size-m);
  line-height: var(--sh-icon-font-size-m);
}

:host([size=m][button]) {
  min-width: var(--sh-icon-size-m);
  min-height: var(--sh-icon-size-m);
  width: var(--sh-icon-size-m);
  height: var(--sh-icon-size-m);
}

:host([size=l]),
:host([size=l]) > div {
  min-width: var(--sh-icon-font-size-l);
  min-height: var(--sh-icon-font-size-l);
  height: var(--sh-icon-font-size-l);
  width: var(--sh-icon-font-size-l);
  font-size: var(--sh-icon-font-size-l);
  line-height: var(--sh-icon-font-size-l);
}

:host([size=l][button]) {
  min-width: var(--sh-icon-size-l);
  min-height: var(--sh-icon-size-l);
  width: var(--sh-icon-size-l);
  height: var(--sh-icon-size-l);
}

:host {
  outline: var(--sh-debug-icon-outline-1);
}
:host > div {
  outline: var(--sh-debug-icon-outline-2);
}`;