// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-workline.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-workline-item-wrapper-height=5rem] - height of the items wrapper
   */
  --sh-workline-item-wrapper-height: 5rem;
  /* 
   * @cssprop [--sh-workline-wrapper-height=7.375rem] - height of the workline wrapper
   */
  --sh-workline-wrapper-height: 7.375rem;
  /* 
   * @cssprop [--sh-workline-wrapper-top-padding=0.5rem] - Top padding of the workline wrapper
   */
  --sh-workline-wrapper-top-padding: 0.5rem;
  /* 
   * @cssprop [--sh-workline-item-margin=1rem] - margin between the workline items
   */
  --sh-workline-item-margin: 1rem;
  /* 
   * @cssprop [--sh-workline-label-height=2rem] - height/line-height of the workline label 
   */
  --sh-workline-label-height: 2rem;
  /* 
   * @cssprop [--sh-workline-right-wrapper-margin=1rem] - right margin of the right wrapper
   */
  --sh-workline-right-wrapper-margin: 1rem;
  /* 
   * @cssprop [--sh-workline-right-wrapper-left-padding=1.5rem] - left padding of the right wrapper
   */
  --sh-workline-right-wrapper-left-padding: 1.5rem;
}

:host {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  overflow: visible;
  position: relative;
  width: 100%;
}

.workline-wrapper {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  height: var(--sh-workline-wrapper-height);
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 0.75rem;
  width: 100%;
  padding-top: var(--sh-workline-wrapper-top-padding);
}

.workline-label {
  position: relative;
}

.items-wrapper {
  -webkit-box-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: var(--sh-workline-item-wrapper-height);
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  background: var(--base-2);
  align-items: center;
}

/* slots */
.items-wrapper > ::slotted(sh-workline-item) {
  margin-left: var(--sh-workline-item-margin);
}

.left-wrapper > ::slotted(*) {
  display: -webkit-box;
  margin-left: var(--sh-workline-wrapper-top-padding);
}

.right-wrapper > ::slotted(*) {
  margin-right: var(--sh-workline-right-wrapper-margin);
}

.right-wrapper {
  height: var(--sh-workline-item-wrapper-height);
  padding-left: var(--sh-workline-right-wrapper-left-padding);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-top-right-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
  border-bottom-right-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
  background: var(--base-2);
  position: relative;
  margin-top: var(--sh-workline-wrapper-top-padding);
}

.left-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: flex;
  border-top-left-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
  border-bottom-left-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
  background: var(--base-2);
  position: relative;
  height: var(--sh-workline-item-wrapper-height);
  padding-right: 1.5rem;
  -webkit-box-align: center;
  align-items: center;
  margin-top: var(--sh-workline-wrapper-top-padding);
}

/* hide slots */
:host([emptyright]) .right-wrapper,
:host([emptyleft]) .left-wrapper {
  display: none;
}

.total-wrapper {
  display: -webkit-box;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
}

:host([withoutanyslots]) .items-wrapper {
  border-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
}

:host([onlyleftslot]) .items-wrapper {
  border-top-right-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
  border-bottom-right-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
}

:host([onlyrightslot]) .items-wrapper {
  border-top-left-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
  border-bottom-left-radius: calc(var(--sh-workline-item-wrapper-height) / 2);
}

.left-float,
.right-float {
  position: absolute;
  height: var(--sh-workline-wrapper-height);
  z-index: 1;
  margin-top: var(--sh-workline-wrapper-top-padding);
  display: flex;
}

:host([no-scrollbar]) .workline-wrapper::-webkit-scrollbar {
  display: none;
}

:host([no-scrollbar]) .workline-wrapper {
  padding-bottom: 0rem;
}

.right-float {
  right: 0rem;
  flex-direction: row-reverse;
  background-image: linear-gradient(to bottom, transparent, transparent var(--sh-workline-item-wrapper-height), var(--base-1) var(--sh-workline-item-wrapper-height), var(--base-1) var(--sh-workline-wrapper-height)), linear-gradient(to right, transparent, transparent calc(var(--sh-workline-item-svg-height-width) / 2), var(--base-2) calc(var(--sh-workline-item-svg-height-width) / 2)) !important;
}

.left-float {
  left: 0rem;
  background-image: linear-gradient(to bottom, transparent, transparent var(--sh-workline-item-wrapper-height), var(--base-1) var(--sh-workline-item-wrapper-height), var(--base-1) calc(var(--sh-workline-wrapper-height) + 0.25rem)), linear-gradient(to left, transparent calc(var(--sh-workline-item-svg-height-width) / 2), var(--base-2) calc(var(--sh-workline-item-svg-height-width) / 2)) !important;
}

.items-wrapper > ::slotted(*.stuck-right),
.items-wrapper > ::slotted(*.stuck-left) {
  background-color: rgba(var(--ui-5), var(--opacity-1));
  box-shadow: var(--shadow-overlay);
}

.items-wrapper > ::slotted(*.stuck-right[active]),
.items-wrapper > ::slotted(*.stuck-left[active]) {
  box-shadow: var(--shadow-overlay), inset 0rem 0rem 0rem 0.0625rem rgba(var(--ui-1), var(--opacity-1));
}`;