// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { property, state } from 'lit/decorators.js';
import { event } from './event-decorator';
export const BasicOverlayMixin = (superClass) => {
  class BasicOverlayMixinElement extends superClass {
    constructor() {
      super(...arguments);
      /**
       * Decides if the popover tracks the target position-change on scroll of target or on any movement of the target . The target position-change
       * is normally tracked when there is a resize of the target and window resize. In addition to this:
       * If the value is `scrolling`, then the target position-change is tracked if it is
       * due to scrolling.
       * If the value is `any`, the target position change is tracked whenever the target changes position. The value of `any` is not needed in most cases. If the value of
       * `any` is used, internally requestAnimationFrame will be used to track the target and is performance intensive. (Will cause change detections if framework is Angular)
       */
      this.trackTargetOn = 'scrolling';
      this.onParentScroll = this.parentScrollActions.bind(this);
      this.windowResizeListener = this.windowResizeActions.bind(this);
      this.resizeObserverCallback = this.resizeObserverActions.bind(this);
      this.resizeObserver = new ResizeObserver(this.resizeObserverCallback);
      // the amount of time that the stop detector takes additionally,
      // to repeatedly check
      // if the target has truly stopped moving or not.
      this.additionalStopCheckTime = 1000;
      // dividing by the time taken for 1 raf = 16ms.
      // So that on every raf, we decrement this number
      // so that it takes (this._additionalStopCheckTime)ms
      // in total to wait before the stop detector confirms
      // that the target has truly stopped.
      this.defaultAdditionalStopCheckDecrementNumber = this.additionalStopCheckTime / 16;
      // the variable that is actually decremented
      this.currentStopCheckDecrementNumber = this.defaultAdditionalStopCheckDecrementNumber;
      this.documentNodes = [];
    }
    update(changedProperties) {
      if (changedProperties.has('invokerNode')) {
        const oldInvokerNode = changedProperties.get('invokerNode');
        if (oldInvokerNode instanceof Element) {
          this.doSomethingWithOldInvokerNode(oldInvokerNode);
        }
        if (this.invokerNode) {
          this.doSomethingWithNewInvokerNode(this.invokerNode);
        }
      }
      if (changedProperties.has('attachNode') || changedProperties.has('visible')) {
        this.removeScrollResizeObservers();
        if (this.visible && this.attachNode) {
          this.setTargetPositionTrackers();
        }
      }
      super.update(changedProperties);
    }
    setTargetPositionTrackers() {
      this.addResizeObservers();
      if (this.trackTargetOn === 'scrolling') {
        this.addScrollListeners();
      } else {
        this.trackTargetUsingRAF();
      }
      if (this.canPositionOverlay()) {
        this.setOverlayPlacement(this.attachNode);
      }
    }
    addResizeObservers() {
      window.addEventListener('resize', this.windowResizeListener);
      this.resizeObserver.observe(this.attachNode);
      this.resizeObserver.observe(this);
    }
    removeScrollResizeObservers() {
      window.removeEventListener('resize', this.windowResizeListener);
      this.removeScrollListeners();
      this.resizeObserver.disconnect();
      this.stopRafTimers();
      this.resetRafTrackSettings();
    }
    stopRafTimers() {
      if (this.motionDetectTimer) {
        cancelAnimationFrame(this.motionDetectTimer);
      }
      if (this.stopDetectTimer) {
        cancelAnimationFrame(this.stopDetectTimer);
      }
    }
    resetRafTrackSettings() {
      this.currentStopCheckDecrementNumber = this.defaultAdditionalStopCheckDecrementNumber;
      this.targetLeft = null;
      this.targetTop = null;
    }
    disconnectedCallback() {
      this.removeScrollResizeObservers();
      if (this.invokerNode) {
        this.doSomethingWithOldInvokerNode(this.invokerNode);
      }
      super.disconnectedCallback();
    }
    resizeObserverActions() {
      this.positionOverlayIfPossible();
    }
    windowResizeActions() {
      this.positionOverlayIfPossible();
    }
    addScrollListeners() {
      if (this.documentNodes.length === 0) {
        this.documentNodes = [];
        // Listen for scroll events in all shadowRoots hosting this overlay only
        // when in native ShadowDOM.
        let node = this.attachNode;
        while (node) {
          if (node instanceof ShadowRoot) {
            this.documentNodes.push(node);
            node = node.host;
          } else if (node instanceof Element) {
            node = node.assignedSlot || node.parentNode;
          } else {
            node = node.parentNode;
          }
        }
        this.documentNodes.push(document);
        this.documentNodes.forEach((el) => {
          el.addEventListener('scroll', this.onParentScroll, {
            capture: true,
            passive: true,
          });
        });
      }
    }
    parentScrollActions() {
      if (this.scrollAction === 'refit' && this.visible) {
        this.positionOverlayIfPossible();
      } else if (
        (this.scrollAction === 'cancel' || this.scrollAction === 'hide') &&
        this.visible &&
        !this.manualControlled
      ) {
        this.visible = false;
        this.overlayClosedEvent.emit(new CustomEvent('overlay-closed'));
      } else {
        // added this as if else if needs to end with else. No method / function to be called in this case.
      }
    }
    removeScrollListeners() {
      this.documentNodes.forEach((el) => {
        el.removeEventListener('scroll', this.onParentScroll, {
          capture: true,
        });
      });
      this.documentNodes = [];
    }
    // can be overriden in implementing components
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setOverlayPlacement(_attachNode) {
      // Pls implement this function in sub classes
    }
    // can be overriden in implementing components
    canPositionOverlay() {
      return this.visible && this.attachNode && !this.mobile;
    }
    // can be overriden in implementing components
    positionOverlayIfPossible() {
      if (this.canPositionOverlay() && this.attachNode) {
        this.setOverlayPlacement(this.attachNode);
      }
    }
    // can be overriden in implementing components
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    doSomethingWithNewInvokerNode(_invokerNode) {
      // Please use this function in the implementing components with ${invokerNode}
    }
    // can be overriden in implementing components
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    doSomethingWithOldInvokerNode(_invokerNode) {
      // Please use this function in the implementing components with ${invokerNode}
    }
    trackTargetUsingRAF() {
      // here we track the attachNode using RAF (requestAnimationFrame)
      this.motionDetectTimer = requestAnimationFrame(this.motionDetect.bind(this));
    }
    // the idea is to track the start of motion from the rest state using
    // the _motionDetectTimer and to track the stopping of motion by the _stopDetectTimer
    // requestAnimation Frames.
    // at a time , only one of these timers will be running.
    // the repositioning logic is applied in the _stopDetectTimer which continuosly tracks
    // the target and detects if the target has stopped moving.
    motionDetect() {
      // cancelling the frames here so that any repeated calls
      // of these functions will only be if they are explicitly
      // called below
      if (this.stopDetectTimer) {
        cancelAnimationFrame(this.stopDetectTimer);
      }
      if (this.motionDetectTimer) {
        cancelAnimationFrame(this.motionDetectTimer);
      }
      const attachNode = this.attachNode;
      if (this.trackTargetOn === 'any' && this.visible && attachNode) {
        const motionDetectTimeout = setTimeout(() => {
          clearTimeout(motionDetectTimeout);
          const targetRect = attachNode.getBoundingClientRect();
          const { left, top } = targetRect;
          if (left !== this.targetLeft || top !== this.targetTop) {
            // motion started...
            this.currentStopCheckDecrementNumber = this.defaultAdditionalStopCheckDecrementNumber;
            this.stopDetectTimer = requestAnimationFrame(this.stopDetect.bind(this));
          } else {
            this.motionDetectTimer = requestAnimationFrame(this.motionDetect.bind(this));
          }
        }, 1000);
      }
    }
    stopDetect() {
      // cancelling the frames here so that any repeated calls
      // of these functions will only be if they are explicitly
      // called below
      // using the _currentStopCheckDecrementNumber to wait for an extra 1s
      // to detect any ongoing motion within that timeframe of 1s
      // and if that wait exceeds 1s, then inferring that the target
      // has stopped and will restart the _motionDetect timer.
      if (this.stopDetectTimer) {
        cancelAnimationFrame(this.stopDetectTimer);
      }
      if (this.motionDetectTimer) {
        cancelAnimationFrame(this.motionDetectTimer);
      }
      const attachNode = this.attachNode;
      if (this.trackTargetOn === 'any' && this.visible && attachNode) {
        const targetRect = attachNode.getBoundingClientRect();
        const { left, top } = targetRect;
        if (left !== this.targetLeft || top !== this.targetTop) {
          this.targetLeft = left;
          this.targetTop = top;
          this.parentScrollActions();
          this.currentStopCheckDecrementNumber = this.defaultAdditionalStopCheckDecrementNumber;
          this.stopDetectTimer = requestAnimationFrame(this.stopDetect.bind(this));
        } else if (this.currentStopCheckDecrementNumber > 0) {
          --this.currentStopCheckDecrementNumber;
          this.stopDetectTimer = requestAnimationFrame(this.stopDetect.bind(this));
        } else {
          this.motionDetectTimer = requestAnimationFrame(this.motionDetect.bind(this));
        }
      }
    }
  }
  __decorate(
    [property({ type: Boolean, reflect: true })],
    BasicOverlayMixinElement.prototype,
    'visible',
    void 0
  );
  __decorate(
    [property({ type: String, reflect: true, attribute: 'track-target-on' })],
    BasicOverlayMixinElement.prototype,
    'trackTargetOn',
    void 0
  );
  __decorate(
    [property({ type: String, reflect: true, attribute: 'scroll-action' })],
    BasicOverlayMixinElement.prototype,
    'scrollAction',
    void 0
  );
  __decorate(
    [property({ type: Boolean, reflect: true, attribute: 'manual-controlled' })],
    BasicOverlayMixinElement.prototype,
    'manualControlled',
    void 0
  );
  __decorate(
    [property({ type: Boolean, reflect: true })],
    BasicOverlayMixinElement.prototype,
    'mobile',
    void 0
  );
  __decorate([state()], BasicOverlayMixinElement.prototype, 'invokerNode', void 0);
  __decorate([state()], BasicOverlayMixinElement.prototype, 'attachNode', void 0);
  __decorate([event()], BasicOverlayMixinElement.prototype, 'overlayClosedEvent', void 0);
  return BasicOverlayMixinElement;
};
