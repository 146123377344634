// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from "tslib";
import { property } from 'lit/decorators.js';
const inputState = Object.freeze({
    hasCursor: 'hasCursor',
    hasValue: 'hasValue',
    noValue: 'noValue',
});
export const TimeInputStateHandlerMixin = (superClass) => {
    class TimeInputStateHandlerMixin extends superClass {
        connectedCallback() {
            super.connectedCallback();
            this.addEventListener('focus', this.updateInputFieldStateIfFocused.bind(this));
            this.addEventListener('blur', (e) => this.updateInputFieldStateBasedOnInputValue(e, true));
        }
        updateInputFieldStateBasedOnInputValue(e, isBlur) {
            const element = e ? e.currentTarget : this;
            if (element &&
                (element.tagName === 'SH-TIMEPICKER' || element.tagName === 'SH-TIMEPICKER-INPUT')) {
                if (!element.responsive && isBlur) {
                    element.active = false;
                }
                this.timeInputState =
                    String(this.h).length > 0 ||
                        String(this.m).length > 0 ||
                        String(this.s).length > 0 ||
                        String(this.amPm).length > 0
                        ? inputState.hasValue
                        : inputState.noValue;
                if (this.timeInputState === inputState.hasValue ||
                    this.active) {
                    this.classList.remove('noValue');
                    this.classList.remove('hasCursor');
                    this.classList.add('hasValue');
                }
                else {
                    this.classList.remove('hasValue');
                    this.classList.remove('hasCursor');
                    this.classList.add('noValue');
                }
            }
            else {
                if (e) {
                    e.stopPropagation();
                }
            }
        }
        updateInputFieldStateIfFocused(e) {
            const element = e.currentTarget;
            if (!element.disabled && element.timeInputState !== inputState.hasCursor) {
                element.timeInputState = inputState.hasCursor;
                this.classList.remove('noValue');
                this.classList.remove('hasValue');
                this.classList.add(inputState.hasCursor);
            }
            else {
                e.stopPropagation();
            }
        }
    }
    __decorate([
        property({ type: String })
    ], TimeInputStateHandlerMixin.prototype, "timeInputState", void 0);
    return TimeInputStateHandlerMixin;
};
