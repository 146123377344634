// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { sharedStyles } from '../../styles';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { ValuePropertyChangedEvent } from '../../utils/events';
import { isKeyboardActive } from '../../utils/is-keyboard-active';
import { spreadAttributes } from '../../utils/spread-attributes-directive.js';
import inputTextStyles from './sh-input-text.lit.scss.js';
export const inputTextListOfProperties = [
  'label',
  'textarea',
  'resizable',
  'type',
  'condensed',
  'error',
  'readonly',
  'disabled',
];
/**
 * @slot icon - Takes sh-icon as children. Will render icon in the sh-input-text.
 * @fires clear-clicked -  emitted when clear icon is clicked (if notifyClearClick is set to true)
 * @fires input-clicked - *hide emitted when input is clicked (if notifyInputClick is set to true)
 * @fires input-focused - *hide emitted when input is focused (if notifyInputFocus is set to true)
 * @fires hint-clicked - *hide emitted when hint is clicked (if notifyInputClick is set to true)
 * @fires value-changed - *hide emitted when value is changed
 * @fires input-hover - *hide emitted when hover on input
 * @fires label-hover - *hide emitted when hover on label
 * @fires label-changed {LabelPropertyChangedEvent}- *hide emitted when label property changes
 * @fires textarea-changed {TextareaPropertyChangedEvent} - *hide emitted when textarea property changes
 * @fires resizable-changed {ResizablePropertyChangedEvent} - *hide emitted when resizable property changes
 * @fires type-changed {TypePropertyChangedEvent} - *hide emitted when type property changes
 * @fires condensed-changed {CondensedPropertyChangedEvent] - *hide emitted when condensed property changes
 * @fires error-changed {ErrorPropertyChangedEvent} - *hide emitted when error property changes
 * @fires readonly-changed {ReadonlyPropertyChangedEvent} - *hide emitted when readonly property changes
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide emitted when disabled property changes
 */
let SHInputText = class SHInputText extends ShuiLitElement {
  constructor() {
    super();
    /** Defines the label of the input. If the value is empty, it will be shown as a placeholder. */
    this.label = 'label';
    /** If set to password, the value will be shown as dots. */
    this.type = 'text';
    /** Represents the current value of the input. Can be used for data binding and changes upon user input.  */
    this.value = '';
    /** If set to `true`, the first sh-input-text having this attribute as true, will have focus on page load. Behaviour similar to autofocus attribute of HTML5 text input.
     * **Note:** There is a known limitation in Safari on iOS where autofocus is not maintained after the keyboard appears. This behavior is consistent across iOS devices and versions as iOS doesn't support autofocus.*/
    this.isAutofocus = false;
    this.ariaDefaultAttributes = () => ({
      'aria-label': this.label,
      'aria-labelledby': 'label',
      'aria-invalid': this.error ? 'true' : 'false',
      'aria-describedby': this.ariaDescribedBy,
      'aria-required': this.mandatory ? 'true' : 'false',
    });
    this.addEventListener('focus', () => {
      if (isKeyboardActive()) {
        this.setAttribute('has-focus', '');
      }
    });
    this.addEventListener('blur', () => {
      this.removeAttribute('has-focus');
    });
  }
  get ariaDescribedBy() {
    const ids = [];
    if (this.error && this.errorMessage) {
      ids.push('error-message');
    }
    if (this.hint) {
      ids.push('hint-message');
    }
    return ids.length > 0 ? ids.join(' ') : undefined;
  }
  static get styles() {
    return [inputTextStyles, sharedStyles];
  }
  render() {
    return html`
      <div class="outer-wrapper">
        <div class="input-wrapper">
          <div id="shinputDiv" class="content-wrapper">
            ${!this.textarea
              ? html`
                  <input
                    id="input"
                    type="${this.type}"
                    @input="${(e) => {
                      this.value = e.target.value;
                      this.valueChangedEvent.emit(new ValuePropertyChangedEvent());
                    }}"
                    ?readonly="${this.readonly || this.inputReadonly}"
                    .value="${!this.value ? '' : this.value}"
                    maxlength="${ifDefined(this.maxlength)}"
                    autocomplete="${ifDefined(this.autocomplete)}"
                    ?disabled="${this.disabled}"
                    ?autofocus="${this.isAutofocus}"
                    tabindex="${this.disabled ||
                    (this.value === '' && (this.showTooltip || this.readonly))
                      ? '-1'
                      : '0'}"
                    @click="${() => {
                      if (this.notifyInputClick) {
                        this.inputClickedEvent.emit(new CustomEvent('input-clicked'));
                      }
                    }}"
                    @focus="${() => {
                      if (this.notifyInputFocus) {
                        this.inputFocusedEvent.emit(new CustomEvent('input-focused'));
                      }
                    }}"
                    @mouseover=${() => {
                      if (this.showTooltip) {
                        this.inputHoverEvent.emit(new CustomEvent('input-hover'));
                      }
                    }}
                    ${spreadAttributes(
                      Object.assign(
                        Object.assign({}, this.ariaDefaultAttributes()),
                        this.inputAttributes || {}
                      )
                    )}
                  />
                `
              : nothing}
            ${this.textarea && this.condensed
              ? html`
                  <textarea
                    id="input"
                    ${spreadAttributes(
                      Object.assign(
                        Object.assign({}, this.ariaDefaultAttributes()),
                        this.inputAttributes || {}
                      )
                    )}
                    class="textarea"
                    .value="${!this.value ? '' : this.value}"
                    @input="${(e) => {
                      this.value = e.target.value;
                      this.valueChangedEvent.emit(new ValuePropertyChangedEvent());
                    }}"
                    maxlength="${ifDefined(this.maxlength)}"
                    rows="${this.rows ? this.rows : 3}"
                    autocomplete="${ifDefined(this.autocomplete)}"
                    ?readonly="${this.readonly}"
                    ?disabled="${this.disabled}"
                    ?autofocus="${this.isAutofocus}"
                    tabindex="${this.disabled || (this.value === '' && this.readonly) ? '-1' : '0'}"
                  >
${this.value}</textarea
                  >
                `
              : nothing}

            <div class="input-text-label">
              ${this.label
                ? html`
                    <label
                      id="label"
                      class="label-wrapper"
                      @mouseover="${() => {
                        if (this.showTooltip) {
                          this.labelHoverEvent.emit(new CustomEvent('label-hover'));
                        }
                      }}"
                    >
                      ${this.label}
                    </label>
                  `
                : nothing}
              ${this.mandatory ? html` <span id="mandatory"> *</span> ` : ''}
            </div>
          </div>
          <div class="icon-wrapper">
            ${this.value && !this.noClear && !this.disabled && !this.readonly
              ? html`
                  <sh-icon
                    size="s"
                    button
                    aria-label="Clear"
                    icon="cancel"
                    tabindex="-1"
                    id="clear"
                    @click="${() => {
                      this.clearField();
                      this.clearClickedEvent.emit(
                        new CustomEvent('clear-clicked', { bubbles: true, composed: true })
                      );
                    }}"
                  ></sh-icon>
                `
              : nothing}
            ${this.success || this.error
              ? html`
                  <sh-icon
                    icon="${this.error ? 'error' : 'success'}"
                    size="s"
                    ?disabled="${this.disabled}"
                  ></sh-icon>
                `
              : nothing}
            <slot name="icon"></slot>
          </div>
        </div>
        ${this.textarea && !this.condensed
          ? html` <textarea
              id="input"
              ${spreadAttributes(
                Object.assign(
                  Object.assign({}, this.ariaDefaultAttributes()),
                  this.inputAttributes || {}
                )
              )}
              class="textarea"
              .value="${!this.value ? '' : this.value}"
              @input="${(e) => {
                this.value = e.target.value;
                this.valueChangedEvent.emit(new ValuePropertyChangedEvent());
              }}"
              maxlength="${ifDefined(this.maxlength)}"
              autocomplete="${ifDefined(this.autocomplete)}"
              rows="${this.rows ? this.rows : '3'}"
              ?readonly="${this.readonly}"
              ?disabled="${this.disabled}"
              ?autofocus="${this.isAutofocus}"
              tabindex="${this.disabled || (this.value === '' && this.readonly) ? '-1' : '0'}"
              @focus=${() => {
                if (!this.readonly) {
                  this.setAttribute('textarea-focused', '');
                }
              }}
              @blur=${() => {
                this.removeAttribute('textarea-focused');
              }}
            >
${this.value}</textarea
            >`
          : nothing}
      </div>
      ${(this.hint && !this.errorMessage) || (this.hint && this.errorMessage && !this.error)
        ? html`
            <sh-text
              id="hint-message"
              size="body-2"
              class="helper-text hint"
              color="secondary"
              @click="${() => {
                if (this.notifyInputClick) {
                  this.hintClickedEvent.emit(new CustomEvent('hint-clicked'));
                }
              }}"
              >${this.hint}</sh-text
            >
          `
        : nothing}
      ${this.error && this.errorMessage
        ? html`
            <sh-text
              id="error-message"
              size="body-2"
              class="helper-text error-message"
              color="secondary"
              @click="${() => {
                if (this.notifyInputClick) {
                  this.hintClickedEvent.emit(new CustomEvent('hint-clicked'));
                }
              }}"
              >${this.errorMessage}</sh-text
            >
          `
        : nothing}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    if (this.textarea) {
      document.body.addEventListener('click', this.handleTextareaLabelClick);
    }
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.handleTextareaLabelClick);
    super.disconnectedCallback();
  }
  update(changedProperties) {
    if (changedProperties.has('value')) {
      this.handleValueChanged();
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, inputTextListOfProperties);
  }
  focus() {
    var _a, _b, _c, _d;
    if (this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.focus();
    } else {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('input')) ===
        null || _d === void 0
        ? void 0
        : _d.focus();
    }
  }
  defocus() {
    var _a, _b;
    (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('input')) ===
      null || _b === void 0
      ? void 0
      : _b.blur();
  }
  select() {
    var _a, _b, _c, _d;
    if (this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.select();
    } else {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('input')) ===
        null || _d === void 0
        ? void 0
        : _d.select();
    }
  }
  handleTextareaLabelClick(e) {
    var _a, _b;
    if (e.target === this && this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.focus();
    }
  }
  handleValueChanged() {
    if (this.value) {
      this.classList.remove('empty');
    } else {
      this.classList.add('empty');
    }
  }
  clearField() {
    var _a, _b, _c, _d;
    this.value = '';
    if (this.textarea) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('textarea')) === null || _b === void 0
        ? void 0
        : _b.focus();
    } else {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('input')) ===
        null || _d === void 0
        ? void 0
        : _d.focus();
    }
    this.valueChangedEvent.emit(new ValuePropertyChangedEvent());
  }
};
__decorate([event()], SHInputText.prototype, 'clearClickedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'inputClickedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'inputFocusedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'hintClickedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'valueChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'inputHoverEvent', void 0);
__decorate([event()], SHInputText.prototype, 'labelHoverEvent', void 0);
__decorate([event()], SHInputText.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'textareaChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'resizableChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'condensedChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'errorChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'readonlyChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHInputText.prototype, 'typeChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'type', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'value', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'textarea', void 0);
__decorate([property({ type: Number, reflect: true })], SHInputText.prototype, 'rows', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'resizable',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'mandatory',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'readonly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'disabled', void 0);
__decorate([property({ type: Number, reflect: true })], SHInputText.prototype, 'maxlength', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'error', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'success', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHInputText.prototype, 'safety', void 0);
__decorate([property({ type: String, reflect: true })], SHInputText.prototype, 'hint', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'error-message' })],
  SHInputText.prototype,
  'errorMessage',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'condensed',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHInputText.prototype,
  'isAutofocus',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-border' })],
  SHInputText.prototype,
  'noBorder',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-clear' })],
  SHInputText.prototype,
  'noClear',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-tooltip' })],
  SHInputText.prototype,
  'showTooltip',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-outline' })],
  SHInputText.prototype,
  'showOutline',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHInputText.prototype,
  'autocomplete',
  void 0
);
__decorate([state()], SHInputText.prototype, 'notifyInputClick', void 0);
__decorate([state()], SHInputText.prototype, 'inputReadonly', void 0);
__decorate([state()], SHInputText.prototype, 'notifyInputFocus', void 0);
__decorate([property({ attribute: false })], SHInputText.prototype, 'inputAttributes', void 0);
SHInputText = __decorate([customElement('sh-input-text')], SHInputText);
export { SHInputText };
