import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { getPatientIllustration } from './patient-illustration';
import { getMultiLayerPatientList, getPatientStates, regionMapping } from './patient-states.js';
import patientIllustrationStyles from './sh-patient-illustration.lit.scss.js';
export const patientIllustrationListOfProperties = [
  'active',
  'image',
  'disabled',
  'currentSelected',
  'currentActive',
  'activeRegions',
];
/**
 * @fires active-changed {ActivePropertyChangedEvent} - Dispatched when clicking on a body region.
 * @fires image-changed {ImagePropertyChangedEvent} - Dispatched when the disabled array is changed.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - Dispatched when the image is changed.
 * @fires current-active-changed {CurrentActivePropertyChangedEvent} - Dispatched when the current active region is changed.
 * @fires current-selected-changed {CurrentSelectedPropertyChangedEvent} - Dispatched when the current selected region is changed.
 * @fires active-regions-changed {ActiveRegionsPropertyChangedEvent} - Dispatched when the active regions are changed.
 */
let SHPatientIllustration = class SHPatientIllustration extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**  Default ct_adult_anatomical. Defines the image shown. Possible values are `ct_adult_anatomical`, `ct_adult_vascular`, `ct_child_anatomical`, `ct_child_vascular`, `xp_all_in_one` and `xp_adult_fluoro`.*/
    this.image = 'ct_adult_anatomical';
    /** Sets the body regions to active area (Applies to images `ct_adult_anatomical`, `ct_adult_vascular`, `ct_child_anatomical`, and `ct_child_vascular`). Applicable only when `multi-select` is set*/
    this.activeRegions = [];
    /** Allow the user to select multiple regions (Applies to images `ct_adult_anatomical`, `ct_adult_vascular`, `ct_child_anatomical`, and `ct_child_vascular`.
     */
    this.multiSelect = false;
    /** @ignore */
    this.illustrationLevel = 0;
    //unreleased variants
    this.unreleasedVariants = [
      'adult_anatomical',
      'child_anatomical',
      'adult_multi-level',
      'child_multi-level',
      'adult_anatomical_adipose',
      'adult_multi-level_adipose',
    ];
    /** @ignore */
    this.lastImage = '';
  }
  static get styles() {
    return [sharedStyles, patientIllustrationStyles];
  }
  get svg() {
    var _a;
    return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('svg');
  }
  get regions() {
    var _a;
    return (_a = this.svg) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.region');
  }
  render() {
    return html`${getPatientIllustration(this.image)}`;
  }
  connectedCallback() {
    super.connectedCallback();
    this.clickListener = this.clickHandler.bind(this);
    this.mouseEnterListener = this.mouseEnterHandler.bind(this);
    this.mouseLeaveListener = this.mouseLeaveHandler.bind(this);
    this.xpclickListener = this.xpclickHandler.bind(this);
    this.checkVariant(this.image);
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  update(changedProperties) {
    if (changedProperties.has('active') || changedProperties.has('image')) {
      this.updateComplete.then(() => {
        if (changedProperties.has('active')) {
          this.handleactiveChanged();
        }
        if (changedProperties.has('image')) {
          this.handleImageChanged();
          if (this.lastImage != '' && this.lastImage != this.image) {
            this.currentActive = '';
            this.currentSelected = '';
            this.illustrationLevel = 0;
            this.lastSelected = '';
          }
          this.lastImage = this.image;
        }
        this.updateNeuroActive();
      });
    }
    if (changedProperties.has('currentActive') && getMultiLayerPatientList().includes(this.image)) {
      this.setCurrentSelected(this.currentActive);
      this.updateComplete.then(() => {
        this.handleCurrentActiveChanged();
      });
    }
    if (
      changedProperties.has('currentSelected') &&
      getMultiLayerPatientList().includes(this.image)
    ) {
      if (!changedProperties.has('currentActive') && this.currentActive) {
        this.currentActive = '';
      }
      this.resetState();
      this.lastSelected = this.currentSelected;
      this.updateComplete.then(() => {
        this.handlecurrentSelectedChanged();
      });
    }
    if (changedProperties.has('disabled')) {
      this.updateComplete.then(() => {
        this.handledisabledChanged();
      });
    }
    if (changedProperties.has('activeRegions') || changedProperties.has('multiSelect')) {
      this.updateComplete.then(() => {
        if (this.multiSelect) this.handlemultiActiveChanged();
      });
    }
    if (changedProperties.has('imageHeight') || changedProperties.has('image')) {
      this.updateComplete.then(() => {
        var _a;
        (_a = this.svg) === null || _a === void 0
          ? void 0
          : _a.style.setProperty('--image-height', this.imageHeight ? this.imageHeight : null);
      });
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(
      this,
      changedProperties,
      patientIllustrationListOfProperties
    );
  }
  disconnectedCallback() {
    this.removeEventListener('click', this.xpclickListener);
    this.removeEventListener('touchend', this.xpclickListener);
    const regions = this.regions;
    if (regions) {
      for (const element of regions) {
        if (!getMultiLayerPatientList().includes(this.image)) {
          element.removeEventListener('click', this.clickListener);
        }
        if (!deviceIdentifier.isTouchDevice()) {
          element.removeEventListener('mouseenter', this.mouseEnterListener);
          element.removeEventListener('mouseleave', this.mouseLeaveListener);
        }
      }
    }
    super.disconnectedCallback();
  }
  handleImageChanged() {
    this.areaStates = getPatientStates(this.image);
    const svg = this.svg;
    svg === null || svg === void 0 ? void 0 : svg.removeAttribute('hidden');
    const regions = this.regions;
    if (regions) {
      for (const element of regions) {
        if (!getMultiLayerPatientList().includes(this.image)) {
          element.addEventListener('click', this.clickListener);
        }
        if (!deviceIdentifier.isTouchDevice()) {
          element.addEventListener('mouseenter', this.mouseEnterListener);
          element.addEventListener('mouseleave', this.mouseLeaveListener);
        }
      }
    }
    this.addEventListener('click', this.xpclickListener);
    this.handleactiveChanged();
    this.handledisabledChanged();
  }
  mouseEnterHandler(event) {
    var _a;
    const regions = this.regions;
    if (regions) {
      for (const element of regions) {
        if (
          element.getAttribute('index') ===
          ((_a = event.target) === null || _a === void 0 ? void 0 : _a.getAttribute('index'))
        ) {
          element.setAttribute('hover', 'true');
        }
      }
    }
  }
  mouseLeaveHandler(event) {
    var _a;
    const regions = this.regions;
    if (regions) {
      for (const element of regions) {
        if (
          element.getAttribute('index') ===
          ((_a = event.target) === null || _a === void 0 ? void 0 : _a.getAttribute('index'))
        ) {
          element.removeAttribute('hover');
        }
      }
    }
  }
  handledisabledChanged() {
    if (this.disabled) {
      const regions = this.regions;
      if (regions) {
        for (const element of regions) {
          const index = element.getAttribute('index');
          if (this.disabled.indexOf(index) >= 0) {
            element.setAttribute('disabled', 'true');
          } else {
            element.removeAttribute('disabled');
          }
        }
      }
    }
  }
  handleactiveChanged() {
    if (getMultiLayerPatientList().includes(this.image)) {
      return;
    }
    const regions = this.regions;
    if (regions) {
      for (const element of regions) {
        const index = element.getAttribute('index');
        if (!this.multiSelect) {
          if (parseInt(index, 10) === Number(this.active)) {
            element.setAttribute('active', 'true');
          } else {
            element.removeAttribute('active');
          }
        }
      }
    }
  }
  handlecurrentSelectedChanged() {
    var _a, _b;
    if (!getMultiLayerPatientList().includes(this.image)) {
      return;
    }
    (_a = this.regions) === null || _a === void 0
      ? void 0
      : _a.forEach((region) => region.removeAttribute('active'));
    this.illustrationLevel = 0;
    if (this.currentSelected) {
      this.illustrationLevel = 1;
      //get state for last selected in JSON String
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const json = this.areaStates[this.currentSelected];
      this.setStates(json, false);
      if ((_b = this.svg) === null || _b === void 0 ? void 0 : _b.querySelector('#default')) {
        this.svg.querySelector('#default').style.opacity = '0';
      }
    }
  }
  updateNeuroActive() {
    var _a;
    if (['adult_neuro', 'adult_neuro_adipose', 'child_neuro'].includes(this.image)) {
      const selectionNeuro =
        (_a = this.svg) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.selection');
      if (selectionNeuro) {
        selectionNeuro.forEach((neuro) => {
          neuro.getAttribute('index') === String(this.active) && neuro.hasAttribute('hidden')
            ? neuro.removeAttribute('hidden')
            : neuro.setAttribute('hidden', '');
        });
      }
    }
  }
  clickHandler(e) {
    var _a;
    e.preventDefault();
    const index =
      (_a = e.target.closest('.region')) === null || _a === void 0
        ? void 0
        : _a.getAttribute('index');
    if (!this.multiSelect) {
      if (Number(this.active) !== Number(index)) {
        this.active = index;
      } else {
        this.removeAttribute('active');
        this.active = undefined;
      }
    } else {
      const activeIndex = this.activeRegions.findIndex((x) => x === index);
      if (activeIndex === -1) {
        this.activeRegions = this.activeRegions.concat(index);
      } else {
        this.activeRegions = this.activeRegions.filter((x) => x !== index);
      }
      this.handlemultiActiveChanged();
    }
  }
  handlemultiActiveChanged() {
    if (getMultiLayerPatientList().includes(this.image)) {
      return;
    }
    const regions = this.regions;
    regions === null || regions === void 0
      ? void 0
      : regions.forEach((element) => {
          const index = element.getAttribute('index');
          if (this.activeRegions.includes(index)) {
            element.setAttribute('active', 'true');
          } else {
            if (element.getAttribute('active')) {
              element.removeAttribute('active');
            }
          }
        });
  }
  xpclickHandler(event) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    event.preventDefault();
    //changes done for firefox console error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-ignore*/
    const path = event.path || (event.composedPath && event.composedPath());
    if (path[0].id === 'outline') {
      return;
    }
    if (path[1].id === 'back-to-default') {
      this.backToDefault();
    }
    if (this.illustrationLevel === 1) {
      //if second element is currentSelected + "_selection"
      this.setSelectedLevel1(path);
    }
    if (this.illustrationLevel === 2) {
      //same level 1 but different level 2
      if (this.currentSelected + '_selection' === path[1].id && this.currentActive !== path[0].id) {
        //one path
        (_b =
          (_a = this.svg) === null || _a === void 0
            ? void 0
            : _a.querySelector('#' + this.currentActive)) === null || _b === void 0
          ? void 0
          : _b.removeAttribute('active');
        (_d =
          (_c = this.svg) === null || _c === void 0
            ? void 0
            : _c.querySelector('#' + path[0].id)) === null || _d === void 0
          ? void 0
          : _d.setAttribute('active', 'true');
        this.currentActive = path[0].id;
        this.illustrationLevel = 2;
      } else if (
        this.currentSelected + '_selection' === path[2].id &&
        this.currentActive !== path[1].id
      ) {
        //group with several path
        (_f =
          (_e = this.svg) === null || _e === void 0
            ? void 0
            : _e.querySelector('#' + this.currentActive)) === null || _f === void 0
          ? void 0
          : _f.removeAttribute('active');
        (_h =
          (_g = this.svg) === null || _g === void 0
            ? void 0
            : _g.querySelector('#' + path[1].id)) === null || _h === void 0
          ? void 0
          : _h.setAttribute('active', 'true');
        this.currentActive = path[1].id;
        this.illustrationLevel = 2;
      } else if (this.currentActive === path[0].id || this.currentActive === path[1].id) {
        //currentActive clicked again
        return;
      } else {
        //different level 1 click
        this.illustrationLevel = 1;
        this.lastSelected = this.currentSelected;
        this.illustrationLevel = 0;
        this.currentSelected = '';
      }
    }
    if (this.illustrationLevel === 0) {
      this.setSelectedLevel0(path);
    }
  }
  setSelectedLevel0(path) {
    if (path[0].tagName === 'path' && path[1].id !== 'back-to-default') {
      if (path[0].id === '') {
        this.selectArea(path, 1);
      } else {
        this.selectArea(path, 0);
      }
    }
  }
  setSelectedLevel1(path) {
    var _a, _b, _c, _d;
    if (path[1].id === this.currentSelected + '_selection') {
      (_b =
        (_a = this.svg) === null || _a === void 0 ? void 0 : _a.querySelector('#' + path[0].id)) ===
        null || _b === void 0
        ? void 0
        : _b.setAttribute('active', 'true');
      this.currentActive = path[0].id;
      this.illustrationLevel = 2;
      //-> jump to second level
    }
    if (path[2].id === this.currentSelected + '_selection') {
      (_d =
        (_c = this.svg) === null || _c === void 0 ? void 0 : _c.querySelector('#' + path[1].id)) ===
        null || _d === void 0
        ? void 0
        : _d.setAttribute('active', 'true');
      this.currentActive = path[1].id;
      this.illustrationLevel = 2;
      //-> jump to second level
    }
    if (path[0].tagName === 'path' && path[0].id === '') {
      this.selectArea(path, 1);
    }
    if (path[0].tagName === 'path' && path[0].id !== '') {
      this.selectArea(path, 0);
    }
  }
  backToDefault() {
    this.lastSelected = this.currentSelected;
    this.illustrationLevel = 0;
    this.currentSelected = '';
  }
  resetState() {
    var _a, _b, _c;
    if (this.lastSelected === undefined) {
      return;
    }
    //check if it is a jump from level 0
    if (this.lastSelected) {
      //get state for last selected in JSON String
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const json = this.areaStates[this.lastSelected];
      this.setStates(json, true);
      if (this.currentActive && this.illustrationLevel < 2) {
        (_b =
          (_a = this.svg) === null || _a === void 0
            ? void 0
            : _a.querySelector('#' + this.currentActive)) === null || _b === void 0
          ? void 0
          : _b.removeAttribute('active');
        if (!this.currentSelected) {
          this.currentActive = '';
        }
      }
    }
    if (this.illustrationLevel === 0) {
      if ((_c = this.svg) === null || _c === void 0 ? void 0 : _c.querySelector('#default')) {
        this.svg.querySelector('#default').style.opacity = '1';
      }
    }
  }
  setStates(json, reset) {
    const toHide = json['hidden'];
    const toDisable = json['disabled'];
    const toActive = json['active'];
    //reset all related changes
    if (toHide) {
      toHide.forEach((element) => {
        this.setState(element, 'hidden');
      });
    }
    if (toDisable) {
      toDisable.forEach((element) => {
        this.setState(element, 'disabled');
      });
    }
    if (toActive) {
      toActive.forEach((element) => {
        this.setState(element, 'active');
        if (!reset) {
          this.currentActive = element;
        }
      });
      if (!reset) {
        this.illustrationLevel = 2;
      }
    }
  }
  selectArea(item, position) {
    if (this.illustrationLevel === 0) {
      this.currentSelected = item[position].id;
    }
    if (this.illustrationLevel === 1) {
      this.lastSelected = this.currentSelected;
      this.currentSelected = item[position].id;
    }
  }
  setState(id, attribute) {
    var _a;
    const path = (_a = this.svg) === null || _a === void 0 ? void 0 : _a.querySelector('#' + id);
    if (path === null || path === void 0 ? void 0 : path.hasAttribute(attribute)) {
      path.removeAttribute(attribute);
    } else {
      path === null || path === void 0 ? void 0 : path.setAttribute(attribute, 'true');
    }
  }
  checkVariant(variant) {
    if (this.unreleasedVariants.includes(variant)) {
      console.warn(
        `IMPORTANT: The patient illustration variant "${variant}" is currently in a pre-release state. ` +
          `This design is for internal use only and must not be publicly disclosed or distributed at this time. ` +
          `Please consult with the Shui Team before any external use or publication.`
      );
    }
  }
  setCurrentSelected(subRegion) {
    if (subRegion) {
      for (const key in regionMapping) {
        if (regionMapping[key].includes(subRegion)) {
          this.currentSelected = key;
          return;
        }
      }
    }
  }
  handleCurrentActiveChanged() {
    var _a;
    if (this.currentActive) {
      (_a = this.svg) === null || _a === void 0
        ? void 0
        : _a.querySelectorAll('.region').forEach((region) => {
            if (region.id === this.currentActive) {
              this.updateComplete.then(() => {
                region.setAttribute('active', 'true');
                this.illustrationLevel = 2;
              });
            } else {
              region.removeAttribute('active');
            }
          });
    }
  }
};
__decorate(
  [property({ type: String, reflect: true })],
  SHPatientIllustration.prototype,
  'image',
  void 0
);
__decorate(
  [property({ type: Number, reflect: true })],
  SHPatientIllustration.prototype,
  'active',
  void 0
);
__decorate(
  [property({ type: Array, reflect: true })],
  SHPatientIllustration.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: Array, reflect: true, attribute: 'active-regions' })],
  SHPatientIllustration.prototype,
  'activeRegions',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'multi-select' })],
  SHPatientIllustration.prototype,
  'multiSelect',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'current-selected' })],
  SHPatientIllustration.prototype,
  'currentSelected',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'current-active' })],
  SHPatientIllustration.prototype,
  'currentActive',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'image-height' })],
  SHPatientIllustration.prototype,
  'imageHeight',
  void 0
);
__decorate(
  [property({ type: Number })],
  SHPatientIllustration.prototype,
  'illustrationLevel',
  void 0
);
__decorate([property({ type: String })], SHPatientIllustration.prototype, 'lastSelected', void 0);
__decorate([property({ type: Object })], SHPatientIllustration.prototype, 'areaStates', void 0);
__decorate([property({ type: String })], SHPatientIllustration.prototype, 'lastImage', void 0);
__decorate([event()], SHPatientIllustration.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHPatientIllustration.prototype, 'imageChangedEvent', void 0);
__decorate([event()], SHPatientIllustration.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHPatientIllustration.prototype, 'currentActiveChangedEvent', void 0);
__decorate([event()], SHPatientIllustration.prototype, 'currentSelectedChangedEvent', void 0);
__decorate([event()], SHPatientIllustration.prototype, 'activeRegionsChangedEvent', void 0);
SHPatientIllustration = __decorate(
  [customElement('sh-patient-illustration')],
  SHPatientIllustration
);
export { SHPatientIllustration };
