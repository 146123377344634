// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-page.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-page-body-wrapper-padding=1rem] - padding of body wrapper
   */
  --sh-page-body-wrapper-padding: 1rem;
  /* 
   * @cssprop [--sh-page-normal-content-gap=0.5rem] - content gap for the layout normal
   */
  --sh-page-normal-content-gap: 0.5rem;
  /* 
   * @cssprop [--sh-page-normal-page-gap=0.5rem] - page gap for the layout normal
   */
  --sh-page-normal-page-gap: 0.5rem;
  /* 
   * @cssprop [--sh-page-normal-page-margin=0.5rem] - page margin for the layout normal
   */
  --sh-page-normal-page-margin: 0.5rem;
  /* 
   * @cssprop [--sh-page-compact-content-gap=0.25rem] - content gap for the layout compact
   */
  --sh-page-compact-content-gap: 0.25rem;
  /* 
   * @cssprop [--sh-page-compact-page-gap=0.25rem] - page gap for the layout compact
   */
  --sh-page-compact-page-gap: 0.25rem;
  /* 
   * @cssprop [--sh-page-compact-page-margin=0.25rem] - page margin for the layout compact
   */
  --sh-page-compact-page-margin: 0.25rem;
  /* 
   * @cssprop [--sh-page-minimal-content-gap=0.125rem] - content gap for layout minimal
   */
  --sh-page-minimal-content-gap: 0.125rem;
  /* 
   * @cssprop [--sh-page-minimal-page-gap=0.125rem] - page gap for the layout minimal
   */
  --sh-page-minimal-page-gap: 0.125rem;
  /* 
   * @cssprop [--sh-page-minimal-page-margin=0] - page margin for the layout minimal
   */
  --sh-page-minimal-page-margin: 0;
  /* 
   * @cssprop [--sh-page-content-gap-100=0.25rem] - page content gap for content spacing 100
   */
  --sh-page-content-gap-100: 0.25rem;
  /* 
   * @cssprop [--sh-page-content-padding-100=0.25rem] - page padding for content spacing 100
   */
  --sh-page-content-padding-100: 0.25rem;
  /* 
   * @cssprop [--sh-page-content-gap-200=0.5rem] - page content gap for content spacing 200
   */
  --sh-page-content-gap-200: 0.5rem;
  /* 
   * @cssprop [--sh-page-content-padding-200=0.5rem] - page padding for content spacing 200
   */
  --sh-page-content-padding-200: 0.5rem;
  /* 
   * @cssprop [--sh-page-content-gap-400=1rem] - page content gap for content spacing 400
   */
  --sh-page-content-gap-400: 1rem;
  /* 
   * @cssprop [--sh-page-content-padding-400=1rem] - page padding for content spacing 400
   */
  --sh-page-content-padding-400: 1rem;
  /* 
   * @cssprop [--sh-page-content-gap-600=1.5rem] - page content gap for content spacing 600
   */
  --sh-page-content-gap-600: 1.5rem;
  /* 
   * @cssprop [--sh-page-content-padding-600=1.5rem] - page padding for content spacing 600
   */
  --sh-page-content-padding-600: 1.5rem;
  /* 
   * @cssprop [--sh-page-layout-based-access-bar-height=4rem] - height of access bar with respect to layout of the page
   */
  --sh-page-layout-based-access-bar-height: 4rem;
}

/* ******************************************
* Layout definitions: normal, compact, minimal
****************************************** */
/** Page variables set for layout based styling */
:host([layout=normal]) {
  --sh-public-page-margin: var(--sh-page-normal-page-margin);
  --sh-public-page-gap: var(--sh-page-normal-page-gap);
  --sh-public-page-content-gap: var(--sh-page-normal-content-gap);
}

:host([layout=compact]) {
  --sh-public-page-margin: var(--sh-page-compact-page-margin);
  --sh-public-page-gap: var(--sh-page-compact-page-gap);
  --sh-public-page-content-gap: var(--sh-page-compact-content-gap);
  --sh-box-radius: var(--sh-radius-100) !important;
}

:host([layout=minimal]) {
  --sh-public-page-margin: var(--sh-page-minimal-page-margin);
  --sh-public-page-gap: var(--sh-page-minimal-page-gap);
  --sh-public-page-content-gap: var(--sh-page-minimal-content-gap);
  --sh-box-radius: var(--sh-radius-none) !important;
}

/** Page variables set for content spacing based styling */
:host([content-spacing="100"]) {
  --sh-public-page-content-gap: var(--sh-page-content-gap-100);
  --sh-public-page-content-padding: var(--sh-page-content-padding-100);
  --sh-box-radius: var(--sh-radius-100) !important;
}

:host([content-spacing="200"]) {
  --sh-public-page-content-gap: var(--sh-page-content-gap-200);
  --sh-public-page-content-padding: var(--sh-page-content-padding-200);
  --sh-box-radius: var(--sh-radius-200) !important;
}

:host([content-spacing="400"]) {
  --sh-public-page-content-gap: var(--sh-page-content-gap-400);
  --sh-public-page-content-padding: var(--sh-page-content-padding-400);
  --sh-box-radius: var(--sh-radius-200) !important;
}

:host([content-spacing="600"]) {
  --sh-public-page-content-gap: var(--sh-page-content-gap-600);
  --sh-public-page-content-padding: var(--sh-page-content-padding-600);
  --sh-box-radius: var(--sh-radius-200) !important;
}

/** layout classic styles */
:host {
  width: 100%;
  height: 100%;
  display: block;
  background-color: var(--base-1);
}

:host([sh-vrt-mode]) {
  width: initial;
  height: initial;
  display: inline-block;
}

:host([sh-vrt-mode]) .page-content {
  /* background-color: initial; */
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.page-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  transition: var(--transition-time) all ease-in-out;
  overflow: hidden;
}

:host([flat][layout=classic]) .page-content {
  background-color: var(--base-3);
}

.main-wrapper {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.body-wrapper {
  flex: 1;
  height: 100%;
  overflow: hidden;
  padding: var(--sh-public-page-content-padding, var(--sh-page-body-wrapper-padding));
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  gap: var(--sh-public-page-content-gap);
}

.body-wrapper[scrollable] {
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  overflow-y: auto;
}

/* layout page padding and content-spacing should have precedance over no-padding */
:host([no-padding]) .body-wrapper {
  padding: var(--sh-public-page-content-padding, 0);
}

.body-wrapper > ::slotted(*:not(sh-button)),
.body-wrapper > ::slotted(sh-button[size=full-width]) {
  width: 100%;
}

.body-wrapper > ::slotted(sh-button:not([icon-position])),
.body-wrapper > ::slotted(sh-badge),
.body-wrapper > ::slotted(sh-tag),
.body-wrapper > ::slotted(sh-tooltip),
.body-wrapper > ::slotted(sh-notifications) {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
}

/* ******************************************
* Layout normal, compact and minimal styles
****************************************** */
/** Layout based styling */
:host(:is([layout=normal], [layout=compact], [layout=minimal])[theme=dark]) {
  background-color: var(--base-0);
}

:host(:not([layout=classic])) .body-wrapper {
  --sh-page-body-wrapper-padding: 0;
}

:host(:not([layout=classic]):not([scrollable])) .page-content,
:host(:not([layout=classic]):not([scrollable])) .body-wrapper,
:host(:not([layout=classic]):not([scrollable])) .main-wrapper {
  overflow: initial;
}

:host(:not([layout=classic]):not([scrollable])) .page-content {
  min-height: 0;
}

/** Layout based styling: Access Bar */
:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-wrapper > ::slotted(sh-access-bar[slot=access]) {
  box-shadow: none;
}

:host(:is([layout=normal], [layout=compact])) .page-wrapper > ::slotted(sh-access-bar[slot=access]) {
  background: transparent;
}

:host([access-bar=solid][layout=normal]) .page-wrapper > ::slotted(sh-access-bar[slot=access]),
:host([access-bar=solid][layout=compact]) .page-wrapper > ::slotted(sh-access-bar[slot=access]) {
  background: var(--base-0);
}

/** The access bar height change for all layouts in all scales except 'xl' */
:host(:not([sh-scale=xl]):is([layout=normal], [layout=compact], [layout=minimal])) .page-wrapper > ::slotted([slot=access]) {
  --sh-access-bar-height: var(--sh-page-layout-based-access-bar-height);
}

/** Layout based styling: Nav Bar */
:host([layout=normal]) .page-wrapper > ::slotted(sh-nav-bar[slot=nav]) {
  border-radius: var(--sh-radius-200);
}

:host([layout=compact]) .page-wrapper > ::slotted(sh-nav-bar[slot=nav]) {
  border-radius: var(--sh-radius-100);
}

:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-wrapper > ::slotted(sh-nav-bar[slot=nav]) {
  box-shadow: none;
  margin: var(--sh-public-page-margin) var(--sh-public-page-margin) 0;
  width: auto;
}

:host([layout=minimal]) .page-wrapper > ::slotted(sh-nav-bar[slot=nav]) {
  margin-top: var(--sh-public-page-gap);
}

/** Layout based styling: Page Content */
:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-content {
  padding: var(--sh-public-page-gap) var(--sh-public-page-margin) var(--sh-public-page-margin);
}

/** Layout based styling: Left and right slots and Pane */
:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-content > ::slotted(*[slot=left]) {
  margin-right: var(--sh-public-page-gap);
}

:host(:is([layout=normal], [layout=compact])) .page-content > ::slotted(*[slot=left]:not(sh-pane)) :host(:is([layout=normal], [layout=compact])) .page-content > ::slotted(*[slot=right]:not(sh-pane)) {
  box-shadow: var(--shadow-navigation);
}

:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-content > ::slotted(sh-pane[slot=left]),
:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-content > ::slotted(sh-pane[slot=right]) {
  box-shadow: none;
}

:host([layout=normal]) .page-content > ::slotted(sh-pane[slot=left]),
:host([layout=normal]) .page-content > ::slotted(sh-pane[slot=right]) {
  border-radius: var(--sh-radius-200);
}

:host([layout=compact]) .page-content > ::slotted(sh-pane[slot=left]),
:host([layout=compact]) .page-content > ::slotted(sh-pane[slot=right]) {
  border-radius: var(--sh-radius-100);
}

:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-content > ::slotted(*[slot=right]) {
  margin-left: var(--sh-public-page-gap);
}

/** Collapsed Pane adjustments with 'normal' and 'compact' layouts */
:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-content > ::slotted(sh-pane[slot=left][size=collapsed]) {
  margin-left: calc(-1 * var(--sh-public-page-margin));
  margin-right: var(--sh-public-page-margin);
}

:host(:is([layout=normal], [layout=compact], [layout=minimal])) .page-content > ::slotted(sh-pane[slot=right][size=collapsed]) {
  margin-right: calc(-1 * var(--sh-public-page-margin));
  margin-left: var(--sh-public-page-margin);
}`;