import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { DateFormatHelper } from '../../utils/date-format-helper.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { TABLET_BREAKPOINT } from '../../utils/tablet-breakpoint.js';
import { windowSize } from '../../utils/window-size.js';
import datepickerStyles from './sh-datepicker.lit.scss.js';
import './sh-private-calendar-view.js';
export const datepickerListOfProperties = ['active', 'value', 'error', 'disabled', 'noClear'];
/**
 * @fires active-changed {ActivePropertyChangedEvent} - *hide Dispatched when the datepicker is opened or closed. The active state is passed as part of detail object of event.
 * @fires value-changed {ValuePropertyChangedEvent} - *hide Dispatched when the value of the datepicker is changed. The value is passed as part of detail object of event.
 * @fires error-changed {ErrorPropertyChangedEvent} - *hide Dispatched when the error state of the datepicker is changed. The error state is passed as part of detail object of event.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide Dispatched when the disabled state of the datepicker is changed. The disabled state is passed as part of detail object of event.
 * @fires no-clear-changed {NoClearPropertyChangedEvent} - *hide Dispatched when the no-clear state of the datepicker is changed. The no-clear state is passed as part of detail object of event.
 */
let SHDatepicker = class SHDatepicker extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** Defines the label of the datepicker input.*/
    this.label = 'Label';
    /** Defines the format of the date to be displayed in input. Any combination of the parts `D` ,`DD`,`M`, `MM`,`MMM` and `YYYY` can be a format. `D` and `M` stands for dates and months whose value if less than 10, will have only 1 character. (example: `2`) and will have two characters for values greater than 10 (example: `12`) . `DD` and `MM` stands for dates and months whose value if less than 10, will have 2 characters. (example: `02`) and will have two characters for values greater than 10 (example: `12`). `MMM` stands for month-name. (Examples of valid format : `MMDDYYYY`,`MMMDD`,`MMMYYYYDD` , etc) (Examples of invalid format : `YYYYMYYYY`, `YYYYmmdd` , etc)*/
    this.format = 'MMDDYYYY';
    /** Defines the separator of the date to be displayed in input. Separator can be any string of any length. Empty-space character `''` (no-separator condition) is not allowed. Blank space character `' '` is allowed. (Read the description of `range` property for the usage of `-` as a separator)*/
    this.separator = '/';
    /** Defines the number of months to be shown in datepicker either 2 or 3 months.*/
    this.months = 1;
    /** If set, won't allow mouse events and disabled styles will be applied to the datepicker input.*/
    this.disabled = false;
    /** If true, datepicker cannot be modified.*/
    this.readonly = false;
    /** If set, allows to select date range (from and to date). A mix of `-` and any other characters (including `-` itself) (example: `--` or `- `) results in incorrect behaviour when range property is used. So these separators must be avoided when using range property. Atmost 1 instance of `-` (example: `separator="-"`) can be used as separator when range property is used.*/
    this.range = false;
    /** If true, future dates will be disabled.*/
    this.futureDateDisable = false;
    /** If true, past dates will be disabled.*/
    this.pastDateDisable = false;
    /** If set, then the condensed format of datepicker is used to display information.*/
    this.condensed = false;
    /** If set to true, the user will be able to enter the date manually along with the selection in the calendar.*/
    this.editable = false;
    /** defines if Datepicker will use alternate mobile version or not.*/
    this.responsive = 'false';
    /** Enables a scrollable date picker view. In responsive mode, displays in a drawer.
          In non-responsive mode, shows in a popover overlay. This non-responsive behavior
           is experimental and subject to change based on evaluation.*/
    this.scrollpicker = 'false';
    /** If set to true, the datepicker  displays styles for invalid input.*/
    this.error = false;
    /** If set to true, the datepicker  displays styles for valid input.*/
    this.success = false;
    /** If set to true, the datepicker  displays styles for safety-relevant input (yellow outline).*/
    this.safety = false;
    /** If set to true, the bottom border will not be displayed.*/
    this.noBorder = false;
    /** If set to true, sets an asterisk near to the label.*/
    this.mandatory = false;
    /** Dates above this date will be disabled. Has to be in the format YYYY-MM-DD.*/
    this.max = '';
    /** Dates below this date will be disabled. Has to be in the format YYYY-MM-DD.*/
    this.min = '';
    /** The locale value used for formatting the month and week days in the datepicker views. Locale **will not** change based on browser language automatically. The implementing project is responsible for setting the locale. The officially supported and tested locale values are `en`, `de`, `es`, `fr`, `ja`, `zh`, `ru-ru`, `it-it`, `ko-kr`, `pt`, `pt-br`,`sv-SE`*/
    this.locale = 'en';
    /** Array of standard Date object representation of the value or values (in case of range datepicker) present in the `value` property. If the value is invalid or empty, this will be an empty array. This array get updated on every change of value property.*/
    this.standardValue = [];
    /** @ignore */
    this.currentView = '';
    /** @ignore */
    this.selectedDates = [];
    /** @ignore */
    this.scrollpickerItems = [];
    /** @ignore */
    this.scrollpickerScroll = false;
    /** @ignore */
    this.scrollpickerValues = [];
    /** @ignore */
    this.startMondayLocales = [
      'de',
      'es',
      'zh',
      'fr',
      'ru-ru',
      'it-it',
      'ko-kr',
      'pt',
      'pt-br',
      'sv-SE',
    ];
    this.disableInternalApplyBtn = true;
    this.cancelBtnClickListener = this.closeDatepicker.bind(this);
    this.applyBtnClickListener = this.applyValuesAndCloseDatepicker.bind(this);
    this.requireApplyBtnDOM = () => html`
      <slot
        name="cancel-button"
        slot="cancel-button"
        @slotchange="${(e) => {
          this.getCancelButton(e.currentTarget);
        }}"
      >
        <sh-button
          aria-label="Cancel"
          label="Cancel"
          color="secondary"
          @click="${() => {
            this.closeDatepicker();
          }}"
        ></sh-button>
      </slot>
      <slot
        name="apply-button"
        slot="apply-button"
        @slotchange="${(e) => {
          this.getApplyButton(e.currentTarget);
        }}"
      >
        <sh-button
          id="internalApplyBtn"
          aria-label="Apply"
          label="Apply"
          color="primary"
          .disabled="${this.disableInternalApplyBtn}"
          @click="${() => {
            this.applyValuesAndCloseDatepicker();
          }}"
        ></sh-button>
      </slot>
    `;
  }
  static get styles() {
    return [datepickerStyles];
  }
  render() {
    return html`${this.inputDOM()}${this.overlayDOM()}`;
  }
  overlayDOM() {
    if (this.canShowDesktopOverlay()) {
      return this.desktopDOM();
    }
    if (this.canShowDatepickerInDrawerOverlay()) {
      return this.datePickerInDrawerDOM();
    }
    if (this.canShowScrollpickerOverlay()) {
      return this.scrollPickerDOM();
    }
    if (this.canShowScrollpickerInOverlay()) {
      return this.scrollpickerInOverlayDOM();
    }
    return html``;
  }
  canShowOverlay() {
    return this.showOverlay && !this.readonly;
  }
  canShowDesktopOverlay() {
    return this.canShowOverlay() && !this._responsive && !this._scrollpicker;
  }
  canShowScrollpickerOverlay() {
    return this.canShowOverlay() && this._responsive && this._scrollpicker;
  }
  canShowDatepickerInDrawerOverlay() {
    return this.canShowOverlay() && this._responsive && !this._scrollpicker;
  }
  canShowScrollpickerInOverlay() {
    return this.canShowOverlay() && !this._responsive && this._scrollpicker;
  }
  inputDOM() {
    return html`<sh-input-text
      .label="${this.label}"
      .error="${this.error}"
      .errorMessage="${this.errorMessage}"
      .hint="${this.hint}"
      .success="${this.success}"
      .disabled="${this.disabled}"
      .safety="${this.safety}"
      .mandatory="${this.mandatory}"
      .readonly="${this.readonly}"
      .value="${this.value}"
      .inputReadonly="${!this.editable}"
      .showOutline="${this.active && !this.readonly}"
      .condensed="${this.condensed}"
      .noBorder="${this.noBorder}"
      ?no-clear="${this.noClear}"
      .notifyInputClick="${true}"
      @input-clicked="${(e) => {
        e.stopPropagation();
        this.toggleActive();
      }}"
      @hint-clicked="${(e) => {
        e.stopPropagation();
        this.active = false;
      }}"
      @input="${(e) => {
        this.active = false;
        this.value = e.target.value;
      }}"
      @clear-clicked="${(e) => {
        e.stopPropagation();
        this.value = '';
        if (!this.active && !this.editable) {
          e.target.defocus();
        } else {
          this.active = false;
        }
      }}"
      @value-changed="${(e) => {
        e.stopPropagation();
      }}"
      class="input"
    >
      ${!this.readonly
        ? html`<sh-icon
            slot="icon"
            icon="calendar"
            size="s"
            button
            @click="${() => {
              this.toggleActive();
            }}"
            .disabled="${this.disabled}"
          ></sh-icon>`
        : ''}
    </sh-input-text>`;
  }
  datePickerInDrawerDOM() {
    return html`<sh-drawer
      .visible="${this.showOverlay && !this.readonly}"
      position="bottom"
      @transitionend="${(e) => {
        e.stopPropagation();
        if (!e.target.visible) {
          this.active = false;
        }
      }}"
      @drawer-closed="${() => {
        this.active = false;
      }}"
      class="initial-slide-on-connect"
    >
      <sh-private-calendar-view
        .currentView="${this.currentView}"
        .months="${this.months}"
        .startingMonth="${this.month}"
        .startingYear="${this.year}"
        .todaysDate="${this.todaysDate}"
        .selectedDates="${this.selectedDates}"
        .futureDateDisable="${this.futureDateDisable}"
        .minDate="${this.minDate}"
        .maxDate="${this.maxDate}"
        .range="${this.range}"
        .pastDateDisable="${this.pastDateDisable}"
        .attachNode="${this}"
        .responsive="${true}"
        .locale="${this.locale}"
        .startMondayLocales="${this.startMondayLocales}"
        @ready-to-update-value="${(e) => {
          e.stopPropagation();
          this.updateValue(e.detail.selectedDates);
          // to trigger the transitionend of the drawer component to have that fade-out-slide-out effect.
          // and here we don't need the calendar-closed event since the drawer itself will detect outside clicks.
          e.target.parentElement.visible = false;
          if (!this.range) {
            this.active = false;
          }
        }}"
      ></sh-private-calendar-view>
    </sh-drawer>`;
  }
  scrollPickerDOM() {
    return html` <sh-drawer
      position="bottom"
      class="point5-vertical-padding initial-slide-on-connect"
      visible="${this.showOverlay && !this.readonly}"
      @visible-changed="${(e) => {
        e.stopPropagation();
        if (!e.target.visible) {
          this.active = false;
        }
      }}"
      @drawer-closed="${() => {
        this.active = false;
      }}"
      >${this.renderScrollpicker()}</sh-drawer
    >`;
  }
  scrollpickerInOverlayDOM() {
    return html`<div class="overlay-container">
      <sh-overlay
        .opened=${this.showOverlay && !this.readonly}
        scroll-action="cancel"
        no-overlap
        horizontal-align="auto"
        auto-fit-on-attach
        .noCancelOnOutsideClick="${true}"
        @overlay-canceled=${(e) => {
          this.overlayCancelledCallback(e);
        }}
        @opened="${() => {
          this.initialiseScrollpicker();
        }}"
      >
        ${this.showOverlay
          ? html`<div class="parent-container">${this.renderScrollpicker()}</div>`
          : ''}
      </sh-overlay>
    </div> `;
  }
  renderScrollpicker() {
    return html`
      <sh-scrollpicker
        .attachNode="${this}"
        .items="${this.scrollpickerItems}"
        .autoScroll="${this.scrollpickerScroll}"
        .values="${this.scrollpickerValues}"
        @values-changed="${(e) => {
          e.stopPropagation();
          this.updateValueFromScrollPicker(e.target);
        }}"
        @scrollpicker-closed="${(e) => {
          e.stopPropagation();
          this.active = false;
        }}"
        @scroll-changed="${(e) => {
          e.stopPropagation();
          this.scrollpickerScroll = e.target.autoScroll;
        }}"
      ></sh-scrollpicker>
    `;
  }
  desktopDOM() {
    return html`
      <div class="overlay-container">
        <sh-overlay
          .opened=${this.showOverlay && !this.readonly}
          scroll-action="cancel"
          no-overlap
          horizontal-align="auto"
          auto-fit-on-attach
          .noCancelOnOutsideClick="${true}"
          @overlay-canceled=${(e) => {
            this.overlayCancelledCallback(e);
          }}
        >
          ${this.showOverlay
            ? html`<sh-private-calendar-view
                .currentView="${this.currentView}"
                .months="${this.months}"
                .startingMonth="${this.month}"
                .startingYear="${this.year}"
                .todaysDate="${this.todaysDate}"
                .selectedDates="${this.selectedDates}"
                .futureDateDisable="${this.futureDateDisable}"
                .minDate="${this.minDate}"
                .maxDate="${this.maxDate}"
                .range="${this.range}"
                .pastDateDisable="${this.pastDateDisable}"
                .attachNode="${this}"
                .locale="${this.locale}"
                .startMondayLocales="${this.startMondayLocales}"
                .requireApply="${this.requireApply}"
                .firstDayOfWeek="${this.firstDayOfWeek}"
                @ready-to-update-value="${(e) => {
                  // This event gets called only when 2 dates are
                  // selected in the case of range datepicker and
                  // when 1 date is selected in case of other datepicker
                  e.stopPropagation();
                  if (this.requireApply) {
                    if (this.applyBtn) {
                      // enabling the apply Btn here once
                      // both dates are selected
                      this.applyBtn.disabled = false;
                    }
                    // Using shallow copy here to update the date-selector
                    this.selectedDates = [...e.detail.selectedDates];
                    this.disableInternalApplyBtn = false;
                  } else {
                    this.updateValue(e.detail.selectedDates);
                    e.target.close = true; // to have that fade-out animation before destroying this DOM
                  }
                }}"
                @apply-first-selection="${(e) => {
                  e.stopPropagation();
                  // Using shallow copy here to update the date-selector
                  this.selectedDates = [...e.detail.selectedDates];
                  this.disableInternalApplyBtn = true;
                  if (this.applyBtn) {
                    this.applyBtn.disabled = true;
                  }
                }}"
                @calendar-closed="${(e) => {
                  e.stopPropagation();
                  e.target.close = true; // to have that fade-out animation before destroying this DOM
                }}"
                @animationend="${(e) => {
                  e.stopPropagation();
                  if (e.target.close) {
                    this.active = false;
                  }
                }}"
              >
                ${this.requireApply ? this.requireApplyBtnDOM() : nothing}
              </sh-private-calendar-view>`
            : ''}
        </sh-overlay>
      </div>
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (
      windowSize.util.valueMatches(document.documentElement.clientWidth, '<', TABLET_BREAKPOINT)
    ) {
      this.responsive = 'true';
    }
  }
  update(changedProperties) {
    if (changedProperties.has('active')) {
      this.handleActive();
    }
    if (changedProperties.has('min')) {
      this.handleMin();
    }
    if (changedProperties.has('max')) {
      this.handleMax();
    }
    if (changedProperties.has('scrollpicker')) {
      this._scrollpicker = JSON.parse(this.scrollpicker);
    }
    if (changedProperties.has('responsive')) {
      this._responsive = JSON.parse(this.responsive);
    }
    if (changedProperties.has('value')) {
      this.handleSeperator();
      this.setResetFromToDates();
      if (!this.value) {
        this.clearSelectedDates();
      }
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    dispatchAttributeChangedEventTypesafe(this, changedProperties, datepickerListOfProperties);
  }
  getApplyButton(slot) {
    // This function is called once when the datepicker opens
    // and it triggers chronologically after the datepicker's active property
    // is set
    if (this.applyBtn) {
      this.applyBtn.removeEventListener('click', this.applyBtnClickListener);
    }
    this.applyBtn = slot.assignedElements({ flatten: true })[0];
    if (this.applyBtn) {
      this.applyBtn.disabled = true;
      this.applyBtn.addEventListener('click', this.applyBtnClickListener);
    }
    if (this.applyBtn && this.selectedDates.length < 2) {
      this.applyBtn.disabled = true;
    }
  }
  closeDatepicker() {
    this.active = false;
  }
  getCancelButton(slot) {
    // This function is called once when the datepicker opens
    // and it triggers chronologically after the datepicker's active property
    // is set
    if (this.cancelBtn) {
      this.cancelBtn.removeEventListener('click', this.cancelBtnClickListener);
    }
    this.cancelBtn = slot.assignedElements({ flatten: true })[0];
    if (this.cancelBtn) {
      this.cancelBtn.addEventListener('click', this.cancelBtnClickListener);
    }
  }
  applyValuesAndCloseDatepicker() {
    var _a;
    this.updateValue(this.selectedDates);
    this.closeDatepicker();
    ((_a = this.shadowRoot) === null || _a === void 0
      ? void 0
      : _a.querySelector('sh-input-text')
    ).defocus();
  }
  overlayCancelledCallback(e) {
    var _a;
    const event = e.detail;
    if (event.type === 'scroll') {
      this.active = false;
    }
    const shInputText = e.currentTarget.previousElementSibling;
    const shadowInput =
      (_a = shInputText === null || shInputText === void 0 ? void 0 : shInputText.shadowRoot) ===
        null || _a === void 0
        ? void 0
        : _a.querySelector('#input');
    // doing this blurring so that if the overlay closes on scrolling and the
    // there was no value entered, the label will come down and the
    // focus will also lose from the input so that the underline
    // color won't be seen. To see this issue, comment the below line and
    // click on an empty datepicker to open the overlay and then scroll the
    // datepicker (the overlay closes). Now in the issue case, the label will
    // still be on top and the border-bottom of input will be highlighted.
    // The below code makes the label go back down and the border-bottom will also restore
    // back to idle colors.
    shadowInput === null || shadowInput === void 0 ? void 0 : shadowInput.blur();
  }
  handleMin() {
    if (!this.min) {
      this.minDate = null;
    } else {
      const todaysDate = this.getTodaysDate();
      const minDateIsValid = DateFormatHelper.isValidDate(
        this.min,
        'YYYYMMDD',
        '-',
        'en',
        todaysDate
      );
      if (minDateIsValid) {
        this.minDate = DateFormatHelper.parseDate(this.min, 'YYYY-MM-DD', '-', 'en', todaysDate);
      } else {
        this.minDate = null;
      }
    }
  }
  handleMax() {
    if (!this.max) {
      this.maxDate = null;
    } else {
      const todaysDate = this.getTodaysDate();
      const maxDateIsValid = DateFormatHelper.isValidDate(
        this.max,
        'YYYYMMDD',
        '-',
        'en',
        todaysDate
      );
      if (maxDateIsValid) {
        this.maxDate = DateFormatHelper.parseDate(this.max, 'YYYY-MM-DD', '-', 'en', todaysDate);
      } else {
        this.maxDate = null;
      }
    }
  }
  updateValue(selectedDates) {
    var _a, _b, _c, _d;
    if (selectedDates.length === 2) {
      this.value = `${DateFormatHelper.getFormattedDate(selectedDates[0], this.format, this.separator, this.locale)} – ${DateFormatHelper.getFormattedDate(selectedDates[1], this.format, this.separator, this.locale)}`;
    } else {
      this.value = `${DateFormatHelper.getFormattedDate(selectedDates[0], this.format, this.separator, this.locale)}`;
    }
    if (this.editable || this.value) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('sh-input-text')) === null || _b === void 0
        ? void 0
        : _b.focus();
    } else if (!this.value) {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('sh-input-text')) === null || _d === void 0
        ? void 0
        : _d.defocus();
    }
  }
  setMonthYearDate(date) {
    this.month = date.getMonth();
    this.year = date.getFullYear();
    this.scrollpickerDate = date.getDate();
  }
  setDefaultVariables(todaysDate) {
    this.setMonthYearDate(todaysDate);
    this.selectedDates = [];
  }
  setSingleDateVariables(singleDate) {
    this.setMonthYearDate(singleDate);
    this.selectedDates = [singleDate];
  }
  setRangeDateVariables(rangeDates) {
    this.setMonthYearDate(rangeDates[1]);
    this.selectedDates = rangeDates;
  }
  updateVariables() {
    this.todaysDate = this.getTodaysDate();
    this.currentView = 'date';
    if (this.value) {
      const actualFormat = DateFormatHelper.insertSeperator(this.separator, this.format);
      const isValueValid = DateFormatHelper.isValueValid(
        this.value,
        this.range,
        this.separator,
        this.format,
        this.futureDateDisable,
        this.pastDateDisable,
        this.todaysDate,
        this.min,
        this.max,
        this.locale
      );
      if (isValueValid) {
        if (!this.range) {
          const parsedDate = DateFormatHelper.parseDate(
            this.value,
            actualFormat,
            this.separator,
            this.locale,
            this.todaysDate
          );
          this.setSingleDateVariables(parsedDate);
        } else {
          const parsedRangeDates = DateFormatHelper.parseRangeDates(
            this.value,
            actualFormat,
            this.separator,
            this.locale,
            this.todaysDate
          );
          this.setRangeDateVariables(parsedRangeDates);
        }
      } else {
        this.setDefaultVariables(this.todaysDate);
      }
    } else {
      this.setDefaultVariables(this.todaysDate);
    }
  }
  getTodaysDate() {
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    return todaysDate;
  }
  toggleActive() {
    this.active = !this.active;
  }
  handleActive() {
    if (this.active) {
      if (this.value && this.range) {
        this.handleSeperator();
      }
      this.updateVariables();
      this.showOverlay = true;
      if (this._scrollpicker && this._responsive) {
        this.initialiseScrollpicker();
      }
      this.disableInternalApplyBtn = true;
      if (this.applyBtn) {
        this.applyBtn.disabled = true;
      }
    }
    if (!this.active) {
      this.showOverlay = false;
      this.scrollpickerItems = [];
      this.scrollpickerValues = [];
      this.scrollpickerScroll = false;
      // clearing the selected dates after
      // every closing of overlay.
      // since the selected Dates will
      // be updated again when it is opened
      // from the value property.
      this.clearSelectedDates();
    }
  }
  handleSeperator() {
    /* In the code below, we will evaluate this.value for the date range. If a value is present
             in the date-range picker (e.g., 11-11-2010-12-11-2010), the date-picker will automatically
             reformat it upon loading (e.g., 11-11-2010 – 12-11-2010).
             If the value is already in the correct format (i.e., 11-11-2010 – 12-11-2010),
             it will be displayed as is. */
    const actualFormat = DateFormatHelper.insertSeperator(this.separator, this.format);
    let oldRangeFormat = false;
    const totalMinusInActualFormat = actualFormat.split('').reduce((count, character) => {
      if (character === '-') {
        count++;
      }
      return count;
    }, 0);
    let indexOfFromToSeperatingMinus;
    let count = 0;
    if (this.value) {
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i] === '-') {
          ++count;
        }
        if (count === totalMinusInActualFormat + 1) {
          indexOfFromToSeperatingMinus = i;
          break;
        }
      }
      if (this.value.indexOf('–') === -1 && indexOfFromToSeperatingMinus) {
        const frontValue = this.value[indexOfFromToSeperatingMinus + 1];
        const previousValue = this.value[indexOfFromToSeperatingMinus - 1];
        if (frontValue !== ' ' && previousValue !== ' ') {
          oldRangeFormat = true;
        }
        if (oldRangeFormat) {
          const valueArray = Array.from(this.value);
          valueArray[indexOfFromToSeperatingMinus] = ' – ';
          this.value = valueArray.join('');
        }
      }
    }
  }
  /**
   * Scrollpicker Code
   */
  /* The basic initialisation code */
  initialiseScrollpicker() {
    const months = new Array(12)
      .fill(0)
      .map((_, index) => DateFormatHelper.getShortMonthName(index, this.locale));
    const years = new Array(201).fill(0).map((_, index) => index + 1900);
    const dayCount = this.getDayCountforMonthYear(this.month, this.year);
    const days = new Array(dayCount).fill(0).map((_, index) => index + 1);
    this.scrollpickerItems = [months, days, years];
    this.scrollpickerValues = [
      DateFormatHelper.getShortMonthName(this.month, this.locale),
      this.scrollpickerDate,
      this.year,
    ];
    this.scrollpickerScroll = true;
  }
  getDayCountforMonthYear(monthIndex, year) {
    let dayCount = 0;
    for (let i = 1; i <= 31; i++) {
      const testDate = new Date(year, monthIndex, i, 0, 0, 0, 0);
      if (testDate.getMonth() === monthIndex) {
        ++dayCount;
      } else {
        break;
      }
    }
    return dayCount;
  }
  updateValueFromScrollPicker(scrollpicker) {
    var _a;
    const values = scrollpicker.values;
    const monthIndex = DateFormatHelper.getMonthIndex(values[0], this.locale);
    const year = values[2];
    const date = values[1];
    const dayCount = this.getDayCountforMonthYear(monthIndex, year);
    const dayCountIsNotCorrect =
      dayCount !== ((_a = scrollpicker.items[1]) === null || _a === void 0 ? void 0 : _a.length);
    const requiredDate = dayCountIsNotCorrect ? (date > dayCount ? dayCount : date) : date; // if date is 31 and only 30 days are present in that month, then we correct to 30th instead of 31st.
    /**
     * _______________________________________________________________
     *
     * Case 1:
     * _______________________________________________________________
     * Assume this function was called when changing month from Nov to
     * Dec and the date was 30 and year was 2021
     *
     * So the current scrollpicker values will be Dec 30 2021.
     *
     * Then we check if the days are same after the scroll has
     * happened, so that if it is December and the current
     * dayCount is 30 (which it will be since previous month was Nov),
     * it is wrong. So to correct it, we set the values
     * of scrollpicker and value of this datepicker to Dec 30 2021.
     * Then we make scrollpicker scroll to that value incase if the current scroll position is wrong
     * by setting the scrollpickerScroll property to true.
     * After this function is executed the update cycle of datepicker is
     * triggered since we changed three properties of datepicker (value and scrollpickerValues and scrollpickerScroll).
     * This forces the render to happen again setting the previously set scrollpickerValues
     * and scroll property to the scrollpicker. This forces an update cycle of scrollpicker since "scroll" property is changed to true from false
     * where it will trigger the observer for the scroll boolean property.
     * The scroll boolean of scrollpicker will wait for the scrollpicker's update and render to finish
     * so that we wait for any unnecessary scroll due to change of list item length of a scroll-container
     * and after that it is made to scroll to the selected values
     * so that there is no chance of changing values unnecessarily.
     *
     * __________________________________________________________________
     *
     * Case 2:
     * __________________________________________________________________
     * Assume this function was called when changing month from Dec to
     * Nov and the date was 31 and year was 2021
     *
     * So the current scrollpicker values will be Nov 31 2021.
     *
     * Then we check if the days are same after the scroll has
     * happened, so that if it is November and the current
     * dayCount is 31 (which it will be since previous month was Dec),
     * it is wrong. So to correct it, we set the values
     * of scrollpicker and value of this datepicker to Nov 30 2021.
     *
     * Here we arrived at Nov 30 2021 by correcting the date to the max dayCount
     * if it exceeds the dayCount and retaining all other parameters
     *
     * Then we make scrollpicker scroll to that value incase if the current scroll position is wrong
     * by setting the scrollpickerScroll property to true.
     * After this function is executed the update cycle of datepicker is
     * triggered since we changed three properties of datepicker (value and scrollpickerValues and scrollpickerScroll).
     *
     * This forces the render to happen again setting the previously set scrollpickerValues
     * and scroll property to the scrollpicker.
     *
     * This forces an update cycle of scrollpicker since "scroll" property is changed to true from false
     * and values is also changed where it will trigger the observer for the values property and dispatch event again.
     * This change is captured again by this function and the values are now Nov 30 2021.
     *
     * The dayCount is same. so it will exit this function and then go back to the updated of scrollpicker
     * where it will continue executing to execute the observer for scroll property (which we set in previous cycle)
     * then it will scroll to the values (by this time, the scrollpicker dom is as expected since it went through some time here)
     * and then reset scroll to false.
     *
     * then updated cycle again happens in scrollpicker(due to setting values property in previous step) where it checks if the
     * new and old values are same or not. Since they are same, no more values-changed event is fired. The scrollpickerScroll property
     * is reset to false since an event binding is present.
     *
     */
    this.scrollpickerValues = [
      DateFormatHelper.getShortMonthName(monthIndex, this.locale),
      requiredDate,
      year,
    ];
    this.value = DateFormatHelper.getFormattedDate(
      new Date(year, monthIndex, requiredDate, 0, 0, 0, 0),
      this.format,
      this.separator,
      this.locale
    );
    if (dayCountIsNotCorrect) {
      const days = new Array(dayCount).fill(0).map((_, index) => index + 1);
      this.scrollpickerItems[1] = days;
      this.scrollpickerScroll = true;
    }
  }
  setResetFromToDates() {
    this.todaysDate = this.getTodaysDate();
    let standardValue = [],
      fromDate = null,
      toDate = null;
    if (this.value) {
      const actualFormat = DateFormatHelper.insertSeperator(this.separator, this.format);
      const isValueValid = DateFormatHelper.isValueValid(
        this.value,
        this.range,
        this.separator,
        this.format,
        this.futureDateDisable,
        this.pastDateDisable,
        this.todaysDate,
        this.min,
        this.max,
        this.locale
      );
      if (isValueValid) {
        if (this.range) {
          [fromDate, toDate] = DateFormatHelper.extractFromToParts(
            this.value,
            this.separator,
            actualFormat
          );
          const parsedRangeDates = DateFormatHelper.parseRangeDates(
            this.value,
            actualFormat,
            this.separator,
            this.locale,
            this.todaysDate
          );
          standardValue = parsedRangeDates;
        } else {
          const parsedDate = DateFormatHelper.parseDate(
            this.value,
            actualFormat,
            this.separator,
            this.locale,
            this.todaysDate
          );
          standardValue = [parsedDate];
        }
      }
    }
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.standardValue = standardValue;
  }
  clearSelectedDates() {
    this.selectedDates = [];
  }
  disconnectedCallback() {
    if (this.cancelBtn) {
      this.cancelBtn.removeEventListener('click', this.cancelBtnClickListener);
    }
    if (this.applyBtn) {
      this.applyBtn.removeEventListener('click', this.applyBtnClickListener);
    }
    super.disconnectedCallback();
  }
};
__decorate([property({ type: String, reflect: true })], SHDatepicker.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHDatepicker.prototype, 'format', void 0);
__decorate(
  [property({ type: String, reflect: true })],
  SHDatepicker.prototype,
  'separator',
  void 0
);
__decorate([property({ type: Number, reflect: true })], SHDatepicker.prototype, 'months', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHDatepicker.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHDatepicker.prototype,
  'readonly',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHDatepicker.prototype, 'range', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'future-date-disable' })],
  SHDatepicker.prototype,
  'futureDateDisable',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'past-date-disable' })],
  SHDatepicker.prototype,
  'pastDateDisable',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHDatepicker.prototype,
  'condensed',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHDatepicker.prototype,
  'editable',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHDatepicker.prototype,
  'responsive',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHDatepicker.prototype,
  'scrollpicker',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHDatepicker.prototype, 'error', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDatepicker.prototype, 'success', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDatepicker.prototype, 'safety', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'hint' })],
  SHDatepicker.prototype,
  'hint',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'error-message' })],
  SHDatepicker.prototype,
  'errorMessage',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-border' })],
  SHDatepicker.prototype,
  'noBorder',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'from-date' })],
  SHDatepicker.prototype,
  'fromDate',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'to-date' })],
  SHDatepicker.prototype,
  'toDate',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHDatepicker.prototype, 'active', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHDatepicker.prototype,
  'mandatory',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHDatepicker.prototype, 'max', void 0);
__decorate([property({ type: String, reflect: true })], SHDatepicker.prototype, 'min', void 0);
__decorate([property({ type: String, reflect: true })], SHDatepicker.prototype, 'value', void 0);
__decorate([property({ type: String, reflect: true })], SHDatepicker.prototype, 'locale', void 0);
__decorate([property({ type: Array })], SHDatepicker.prototype, 'standardValue', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-clear' })],
  SHDatepicker.prototype,
  'noClear',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'require-apply' })],
  SHDatepicker.prototype,
  'requireApply',
  void 0
);
__decorate([property({ type: String })], SHDatepicker.prototype, 'currentView', void 0);
__decorate([property({ type: Array })], SHDatepicker.prototype, 'selectedDates', void 0);
__decorate([property({ type: Object })], SHDatepicker.prototype, 'todaysDate', void 0);
__decorate([property({ type: Boolean })], SHDatepicker.prototype, 'showOverlay', void 0);
__decorate([property({ type: Number })], SHDatepicker.prototype, 'month', void 0);
__decorate([property({ type: Number })], SHDatepicker.prototype, 'year', void 0);
__decorate([property({ type: Date })], SHDatepicker.prototype, 'minDate', void 0);
__decorate([property({ type: Date })], SHDatepicker.prototype, 'maxDate', void 0);
__decorate([property({ type: Boolean })], SHDatepicker.prototype, '_scrollpicker', void 0);
__decorate([property({ type: Boolean })], SHDatepicker.prototype, '_responsive', void 0);
__decorate([property({ type: Array })], SHDatepicker.prototype, 'scrollpickerItems', void 0);
__decorate([property({ type: Boolean })], SHDatepicker.prototype, 'scrollpickerScroll', void 0);
__decorate([property({ type: Array })], SHDatepicker.prototype, 'scrollpickerValues', void 0);
__decorate([property({ type: Array })], SHDatepicker.prototype, 'startMondayLocales', void 0);
__decorate([property({ type: Number })], SHDatepicker.prototype, 'scrollpickerDate', void 0);
__decorate(
  [property({ type: String, attribute: 'first-day-of-week' })],
  SHDatepicker.prototype,
  'firstDayOfWeek',
  void 0
);
__decorate([state()], SHDatepicker.prototype, 'disableInternalApplyBtn', void 0);
__decorate([event()], SHDatepicker.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHDatepicker.prototype, 'valueChangedEvent', void 0);
__decorate([event()], SHDatepicker.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHDatepicker.prototype, 'noClearChangedEvent', void 0);
__decorate([event()], SHDatepicker.prototype, 'errorChangedEvent', void 0);
SHDatepicker = __decorate([customElement('sh-datepicker')], SHDatepicker);
export { SHDatepicker };
