// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import { ControlStateMixin } from '../../utils/control-state-mixin';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import '../../styles';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin.js';
import buttonStyles from './sh-button.lit.scss.js';
export const buttonListOfProperties = ['icon', 'label', 'size', 'color', 'tooltip', 'href'];
/**
 * @attr {String} shape - Defines the shape of the button. Possible values are `pill-s`, `pill-m`, `pill-l`, `circle`.
 *
 * @fires icon-changed {IconPropertyChangedEvent} - *hide emitted when the icon property changes.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires size-changed {SizePropertyChangedEvent} - *hide emitted when the size property changes.
 * @fires color-changed {ColorPropertyChangedEvent} - *hide emitted when the color property changes.
 * @fires tooltip-changed {TooltipPropertyChangedEvent} - *hide emitted when the tooltip property changes.
 * @fires href-changed {HrefPropertyChangedEvent} - *hide emitted when the href property changes.
 
 */
let SHButton = class SHButton extends RefrainSpaceScrollMixin(
  ControlStateMixin(FocusBlurMixin(ShuiLitElement))
) {
  static get styles() {
    return [extendInteractiveArea(), buttonStyles];
  }
  render() {
    return html`
      ${this.icon
        ? html`<sh-icon
            icon="${this.icon}"
            size="${this.getIconPosition()}"
            .disabled="${this.disabled}"
            class="btn-icon"
          ></sh-icon>`
        : nothing}
      ${this.tooltip
        ? html` <sh-tooltip
            .target="${this}"
            placement="${this.tooltip}"
            label="${this.label}"
            position-fixed-adjustment
            class="tooltip-wc"
          ></sh-tooltip>`
        : nothing}
      ${(this.label && !this.icon) || (this.label && this.icon && this.iconPosition)
        ? html`<div class="label">${this.label}</div> `
        : nothing}
    `;
  }
  constructor() {
    super();
    /** Defines the button's label in all shapes (in circle shape it will only be shown if tooltip is also defined). */
    this.label = 'Label';
    /** If icon is not set, defines the size of the button. Possible values are `s`, `m`, `l`, `auto` (in which size grows based on label's length with `l` size being the maximum viable width), `max-content` (in which size grows based on label's length without having a maximum width) and `full-width` (in which size covers the whole width of the parent container independent of label's length. This variation should be used for mobile's use case only).
     * @type {"s"|"m"|"l"|"auto"|"max-content"|"full-width"}
     */
    this.size = 's';
    /** Defines the color of the button. Possible values are `primary`, 'primary-neutral', `secondary`, `tertiary`.
     *  @type {"primary"|"primary-neutral"|"secondary"|"tertiary"}
     */
    this.color = 'primary';
    /** Defines the button layout. Defaults to `classic`, to maintain full compatibility with previous Shui versions. `normal` introduces a larger button variant, while `compact` is optimized for space-constrained interfaces.
     *  @type {"classic"|"compact"|"normal"}
     */
    this.layout = 'classic';
    /**Sets the button width for `normal` and `compact` layouts. Accepts any valid CSS width value. Defaults to `auto` with a preset max-width. Not applicable to `classic` layout.   */
    this.width = 'auto';
  }
  // do not access attributes or any other dom api of your element in the constructor
  // do it in connectedCallback instead
  // https://html.spec.whatwg.org/multipage/custom-elements.html#custom-element-conformance
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'button');
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('href') && this.href) {
      this.addHrefListener();
    }
    if (changedProperties.has('layout') || changedProperties.has('size')) {
      if (this.layout === 'compact' || this.layout === 'normal') {
        this.removeAttribute('size');
      }
    }
    if (changedProperties.has('width') || changedProperties.has('layout')) {
      if (
        this.style.width !== this.width &&
        this.width !== 'auto' &&
        (this.layout === 'compact' || this.layout === 'normal')
      ) {
        this.style.width = this.width;
      }
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, buttonListOfProperties);
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    this.addEventListener('focus', () => this.handleFocus());
    this.addEventListener('blur', () => this.handleBlur());
    if (this.hasAttribute('shape')) {
      this.handleLegacyAttributes();
    }
  }
  addHrefListener() {
    this.addEventListener('click', () => {
      if (this.href) window.location.href = this.href;
    });
  }
  handleLegacyAttributes() {
    const shape = this.getAttribute('shape');
    if (shape == 'pill-s') {
      this.size = 's';
    } else if (shape == 'pill-m') {
      this.size = 'm';
    } else if (shape == 'pill-l') {
      this.size = 'l';
    } else if (shape == 'circle') {
      if (!this.icon) {
        // legacy default icon property value
        this.icon = 'save';
      }
    }
    console.warn(
      `You are using a legacy property (${shape}) of sh-button component. Please use the new 'size' property instead.`
    );
  }
  handleFocus() {
    this.addEventListener('keyup', this.handleKeyup);
  }
  handleKeyup(e) {
    super.handleKeyup(e);
    if (keyboardInteraction(e, KEYCODE.ENTER) || keyboardInteraction(e, KEYCODE.SPACE)) {
      this.click();
    }
  }
  handleBlur() {
    super.handleBlur();
    this.removeEventListener('keyup', this.handleKeyup);
  }
  getIconPosition() {
    return this.iconPosition ? 's' : 'm';
  }
};
__decorate([event()], SHButton.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHButton.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHButton.prototype, 'sizeChangedEvent', void 0);
__decorate([event()], SHButton.prototype, 'colorChangedEvent', void 0);
__decorate([event()], SHButton.prototype, 'tooltipChangedEvent', void 0);
__decorate([event()], SHButton.prototype, 'hrefChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'icon', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-position' })],
  SHButton.prototype,
  'iconPosition',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'size', void 0);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'color', void 0);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'tooltip', void 0);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'href', void 0);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'layout', void 0);
__decorate([property({ type: String, reflect: true })], SHButton.prototype, 'width', void 0);
SHButton = __decorate([customElement('sh-button')], SHButton);
export { SHButton };
