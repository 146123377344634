// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-pane.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-pane-wrapper-width=22rem] - width of the pane
   */
  --sh-pane-wrapper-width: 22rem;
  /* 
   * @cssprop [--sh-pane-wrapper-padding=1rem] - padding of the head and body wrapper
   */
  --sh-pane-wrapper-padding: 1rem;
  /* 
   * @cssprop [--sh-pane-arrow-placement-padding=0.75rem] - padding of the arrow when it is placed on top or bottom
   */
  --sh-pane-arrow-placement-padding: 0.75rem;
  /* 
   * @cssprop [--sh-pane-arrow-position=-2rem] - position of the arrow from the left or right
   */
  --sh-pane-arrow-position: -2rem;
  /* 
   * @cssprop [--sh-pane-condensed-width=6.25rem] - max-width of the condensed wrapper
   */
  --sh-pane-condensed-width: 6.25rem;
  /* 
   * @cssprop [--sh-pane-body-wrapper-margin=0.5rem] - margin of the elements inside the body slot
   */
  --sh-pane-body-wrapper-margin: 0.5rem;
  /* 
   * @cssprop [--sh-pane-arrow-wrapper-padding=0.25rem 0] - padding of the arrow wrapper to center align the arrow icon
   */
  --sh-pane-arrow-wrapper-padding: 0.25rem 0;
  /* 
   * @cssprop [--sh-pane-border-radius-left=0rem 0.125rem 0.125rem 0rem] - border-radius of the left pane
   */
  --sh-pane-border-radius-left: 0rem 0.125rem 0.125rem 0rem;
  /* 
   * @cssprop [--sh-pane-border-radius-right=0.125rem 0rem 0rem 0.125rem] - border-radius of the right pane
   */
  --sh-pane-border-radius-right: 0.125rem 0rem 0rem 0.125rem;
  /* 
   * @cssprop [--sh-pane-box-shadow-left=0.125rem 0rem 0.25rem rgba(0, 0, 0, 0.1)] - box-shadow of the left pane
   */
  --sh-pane-box-shadow-left: 0.125rem 0rem 0.25rem rgba(0, 0, 0, 0.1);
  /* 
   * @cssprop [--sh-pane-box-shadow-right=-0.125rem 0rem 0.25rem rgba(0, 0, 0, 0.1)] - box-shadow of the right pane
   */
  --sh-pane-box-shadow-right: -0.125rem 0rem 0.25rem rgba(0, 0, 0, 0.1);
  /* 
   * @cssprop [--sh-pane-slot-child-margin-left=0.5rem] - Left margin of the items placed inside functions, footer slot
   */
  --sh-pane-slot-child-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-pane-functions-slot-last-child-margin=0rem 0rem 0 var(--sh-pane-slot-child-margin-left)] - Margin of the last item placed inside functions slot
   */
  --sh-pane-functions-slot-last-child-margin: 0rem 0rem 0 var(--sh-pane-slot-child-margin-left);
  /* 
   * @cssprop [--sh-pane-functions-slot-not-last-child-margin=0rem 0rem 0 var(--sh-pane-slot-child-margin-left)] - Margin of the all items except last placed inside functions slot
   */
  --sh-pane-functions-slot-not-last-child-margin: 0rem 0rem 0 var(--sh-pane-slot-child-margin-left);
  /* 
   * @cssprop [--sh-pane-arrow-icon-size=2rem] - size of the arrow icon
   */
  --sh-pane-arrow-icon-size: 2rem;
  /* 
   * @cssprop [--sh-pane-body-wrapper-line-height=1.25rem] - line-height of body-wrapper
   */
  --sh-pane-body-wrapper-line-height: 1.25rem;
}

:host {
  z-index: 2; /**
  Setting z-index is necessary here inorder to make the pane appear.

  Why the value is set to 2?

  Since
    Nav-bar has z-index=3,
    access-bar has z-index=4,
    a reasonable value of 2 is chosen. 

*/
  display: block;
  background-color: var(--base-2);
  transition: var(--transition-time) all ease-in-out;
  box-shadow: var(--shadow-navigation);
  width: unset;
  min-width: unset;
}

.pane-wrapper {
  width: var(--sh-pane-wrapper-width);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: var(--transition-time) all ease-in-out;
}

.label-wrapper {
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  padding: var(--sh-pane-wrapper-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-wrapper {
  padding: var(--sh-pane-wrapper-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.body-wrapper {
  flex: 1;
  padding: var(--sh-pane-wrapper-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  font: var(--body-1);
  line-height: var(--sh-pane-body-wrapper-line-height);
  color: var(--text-primary);
}

.body-wrapper > ::slotted(*:not(:last-child):not(sh-menu-item)) {
  margin-bottom: var(--sh-pane-body-wrapper-margin);
}

.condensed-wrapper > ::slotted(*:not(sh-menu-item)) {
  margin-bottom: var(--sh-pane-body-wrapper-margin);
}

.footer-wrapper {
  min-height: fit-content;
  min-height: -ms-fit-content;
  min-height: -moz-fit-content;
  padding: var(--sh-pane-wrapper-padding);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}

.footer-wrapper > ::slotted(sh-button[slot=footer]),
.footer-wrapper > ::slotted(sh-icon[slot=footer]),
.label-wrapper > ::slotted([slot=functions]) {
  margin-left: var(--sh-pane-slot-child-margin-left);
}

.label-wrapper > ::slotted(:not(:last-child)[slot=functions]) {
  margin: var(--sh-pane-functions-slot-not-last-child-margin);
}

.label-wrapper > ::slotted(sh-icon[slot=functions]:last-of-type) {
  margin: var(--sh-pane-functions-slot-last-child-margin);
}

.pane-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-primary);
}

.header-wrapper > ::slotted(sh-tabs[slot=header]) {
  margin: -1rem 0 0 0;
}

.pane-wrapper[empty-header] .header-wrapper,
.pane-wrapper[empty-footer] .footer-wrapper {
  display: none;
}

.pane-wrapper:not([empty-header]) .body-wrapper {
  padding-top: 0rem;
}

.pane-wrapper:not([empty-footer]) .body-wrapper {
  padding-bottom: 0rem;
}

/* collapse / condense behavior */
:host([size=expanded]) .pane-wrapper {
  max-width: var(--sh-pane-wrapper-width);
}

:host([size=collapsed]) .pane-wrapper {
  max-width: 0rem;
}

:host([size=condensed]) .pane-wrapper {
  max-width: var(--sh-pane-condensed-width);
}

:host([size$=expanded]) .transition,
:host([size$=px]) .transition,
:host([size$="%"]) .transition,
:host([size$=rem]) .transition,
:host([size$=vw]) .transition {
  transition: var(--transition-time) opacity ease-in-out var(--transition-time);
}

:host([size=expanded]) .label-wrapper,
:host([size=expanded]) .header-wrapper,
:host([size=expanded]) .body-wrapper,
:host([size=expanded]) .footer-wrapper {
  width: var(--sh-pane-wrapper-width);
}

:host(:not([size=expanded]):not([size=resizable]:not([resizable])):not([size*=px]):not([size*=rem]):not([size*="%"]):not([size*=vw])) .label-wrapper,
:host(:not([size=expanded]):not([size=resizable]:not([resizable])):not([size*=px]):not([size*=rem]):not([size*="%"]):not([size*=vw])) .header-wrapper,
:host(:not([size=expanded]):not([size=resizable]:not([resizable])):not([size*=px]):not([size*=rem]):not([size*="%"]):not([size*=vw])) .body-wrapper,
:host(:not([size=expanded]):not([size=resizable]:not([resizable])):not([size*=px]):not([size*=rem]):not([size*="%"]):not([size*=vw])) .footer-wrapper {
  width: 0rem;
  max-height: 0rem;
  opacity: 0;
  transition: calc(var(--transition-time) * 0) all ease-in-out;
  pointer-events: none;
}

:host([size=condensed]) .condensed-wrapper {
  transition: var(--transition-time) opacity ease-in-out var(--transition-time);
  height: 100%;
  position: absolute;
  width: var(--sh-pane-condensed-width);
  padding: var(--sh-pane-wrapper-padding);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

:host(:not([size=condensed])) .condensed-wrapper {
  opacity: 0;
  width: 0rem;
  height: 0rem;
}

.arrow-wrapper {
  padding: var(--sh-pane-arrow-wrapper-padding);
  position: absolute;
  transition: var(--transition-time) all ease-in-out;
  background-color: var(--base-2);
  transform: translateZ(0);
}

:host([slot=left]) .arrow-wrapper {
  right: var(--sh-pane-arrow-position);
  box-shadow: var(--sh-pane-box-shadow-left);
  border-radius: var(--sh-pane-border-radius-left);
}

:host([slot=right]) .arrow-wrapper {
  left: var(--sh-pane-arrow-position);
  box-shadow: var(--sh-pane-box-shadow-right);
  border-radius: var(--sh-pane-border-radius-right);
}

.arrow {
  --sh-icon-size-m: var(--sh-pane-arrow-icon-size);
}

/* arrow position */
:host([arrow-position=top]) .arrow-wrapper {
  top: var(--sh-pane-arrow-placement-padding);
}

:host([arrow-position=bottom]) .arrow-wrapper {
  bottom: var(--sh-pane-arrow-placement-padding);
}

:host([arrow-position=center]) .arrow-wrapper {
  top: calc(50% - (var(--sh-icon-size-m) + 0.5rem) / 2);
}

/* arrow */
:host([slot=right][size=condensed][behavior=condense]) .arrow,
:host([slot=right][size=collapsed][behavior=collapse]) .arrow,
:host([slot=right][size=collapsed][behavior=condense]) .arrow,
:host([slot=left][size=condensed][behavior=collapse]) .arrow,
:host([slot=left][size=expanded]) .arrow,
:host([slot=left][size$=px]) .arrow,
:host([slot=left][size$=rem]) .arrow,
:host([slot=left][size$=vw]) .arrow,
:host([slot=left][size$="%"]) .arrow {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.resize-handle:active,
.resize-handle,
.resize-handle:hover {
  position: absolute;
  height: 100%;
  width: 0.125rem;
  display: block;
  cursor: ew-resize;
}

/* positioning */
:host([slot=left]) .resize-handle {
  right: 0rem;
}

:host([slot=right]) .resize-handle {
  left: 0rem;
}

:host([size=resizable]) .label-wrapper,
:host([size=resizable]) .header-wrapper,
:host([size=resizable]) .body-wrapper,
:host([size=resizable]) .footer-wrapper,
:host([size=resizable]) .condensed-wrapper,
:host([resizable]) .label-wrapper,
:host([resizable]) .header-wrapper,
:host([resizable]) .body-wrapper,
:host([resizable]) .footer-wrapper,
:host([resizable]) .condensed-wrapper {
  max-width: unset;
  width: 100%;
  -webkit-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
}

:host([size=resizable]),
:host([resizable]),
:host([size$=rem]),
:host([size$=px]),
:host([size$="%"]),
:host([size$=vw]) {
  -webkit-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

:host([size=resizable]) .pane-wrapper,
:host([resizable]) .pane-wrapper,
:host([size$=px]) .pane-wrapper,
:host([size$=rem]) .pane-wrapper,
:host([size$="%"]) .pane-wrapper,
:host([size$=vw]) .pane-wrapper {
  -webkit-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
  width: var(--sh-pane-wrapper-width);
}

:host(:not([size=resizable]):not([resizable])) .resize-handle {
  display: none;
}

:host([is-Resize]) {
  cursor: ew-resize;
}

:host([label]) .body-wrapper {
  padding-top: 0rem;
}`;