// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { TimeValueChangedEvent } from '../../utils/events.js';
import { prependZero } from '../../utils/number-formatter.js';
import timepickerStyles from './sh-timepicker.lit.scss.js';
import { TimeInputStateHandlerMixin } from './time-input-state-handler-mixin.js';
import { TimeInputValidatorMixin } from './time-input-validator-mixin.js';
import { TimeModifierMixin } from './time-modifier-mixin.js';
/**
 * @prop {Boolean} timeInputState  - *hide Defines the state of the input. Possible values are `hasValue`, `hasCursor`, `noValue`
 * @fires time-value-changed {TimeValueChangedEvent} - *hide event fired when value(h/m/s) changes which is listened to in timepicker to updates it's value
 */
let SHTimepickerInput = class SHTimepickerInput extends TimeInputValidatorMixin(
  TimeModifierMixin(TimeInputStateHandlerMixin(ShuiLitElement))
) {
  constructor() {
    super(...arguments);
    /**Defines hours field of the Timepicker */
    this.h = '';
    /**Defines minutes field of the Timepicker */
    this.m = '';
    /**Defines seconds field of the Timepicker*/
    this.s = '';
    /**Defines am/pm field of the Timepicker, applicable when `clock-format` is set to `12-hour` */
    this.amPm = '';
    /**Defines alternate format HH:MM AM/PM for Timepicker */
    this.mini = false;
    /**Defines increment/decrement step for minute and second selector (5,10,15,30). Default value is `1` */
    this.step = 1;
    /**Defines whether time mode is 12-hour / 24-hour . Possible values are `"12-hour"` or `"24-hour"` */
    this.clockFormat = '12-hour';
    /**If set, won't allow mouse events and disabled styles will be applied to the timepicker input.*/
    this.disabled = false;
  }
  static get styles() {
    return [timepickerStyles];
  }
  render() {
    return html`
      <div class="time-input-wrapper">
        <input
          type="text"
          placeholder="hh"
          data-id="h-ip"
          id="h-label"
          min="1"
          max="${this.clockFormat === '12-hour' ? 12 : 23}"
          @keyup=${this.onKeyupValidateTimeInput}
          maxlength="2"
          .value="${this.h}"
          @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
          size="2"
          ?disabled="${this.disabled}"
          @blur="${this.appendZeroAndUpdateTimeValue}"
        />
        <div
          class="seperator  ${!(this.h === '' && this.m === '' && this.s === '' && this.amPm === '')
            ? 'colon-active'
            : ''}"
        >
          :
        </div>
        <input
          type="text"
          placeholder="mm"
          data-id="m-ip"
          id="m-label"
          min="0"
          max="59"
          @keyup=${this.onKeyupValidateTimeInput}
          maxlength="2"
          .value="${this.m}"
          @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
          size="2"
          ?disabled="${this.disabled}"
          @blur="${this.appendZeroAndUpdateTimeValue}"
        />
        ${!this.mini
          ? html`
              <div
                class="seperator ${!(
                  this.h === '' &&
                  this.m === '' &&
                  this.s === '' &&
                  this.amPm === ''
                )
                  ? 'colon-active'
                  : ''} full"
              >
                :
              </div>
              <input
                type="text"
                placeholder="ss"
                data-id="s-ip"
                class="full"
                id="s-label"
                min="0"
                max="59"
                @keyup=${this.onKeyupValidateTimeInput}
                maxlength="2"
                .value="${this.s}"
                @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
                size="2"
                ?disabled="${this.disabled}"
                @blur="${this.appendZeroAndUpdateTimeValue}"
              />
            `
          : ''}
        <div
          class="seperator ${!(this.h === '' && this.m === '' && this.s === '' && this.amPm === '')
            ? 'colon-active'
            : ''}"
        ></div>
        ${this.clockFormat === '12-hour'
          ? html` <input
              type="text"
              data-id="ap-ip"
              id="ap-label"
              placeholder="AM"
              .value="${this.amPm}"
              size="2"
              maxlength="2"
              @keydown=${this.onArrowKeyUpOrDownPressUpdateTime}
              @keyup=${this.onKeyupValidateAmPm}
              ?disabled="${this.disabled}"
              @blur=${this.appendZeroAndUpdateTimeValue}
            />`
          : ''}
      </div>
    `;
  }
  firstUpdated() {
    if (
      Number(this.h) > 0 ||
      this.h === '00' ||
      Number(this.m) > 0 ||
      Number(this.s) > 0 ||
      Number(this.amPm) > 0
    ) {
      this.updateTimeWhenInputFieldHasValue();
    }
  }
  update(changedProperties) {
    if (changedProperties.has('timeInputState')) {
      this.handleInputFieldChanged();
    }
    super.update(changedProperties);
  }
  handleInputFieldChanged() {
    if (this.timeInputState === 'hasValue') {
      this.updateTimeWhenInputFieldHasValue();
    }
  }
  onArrowKeyUpOrDownPressUpdateTime(e) {
    this.incrementOrDecrementOnKeyDown(e);
  }
  appendZeroAndUpdateTimeValue(e) {
    const element = e.target;
    const elementId = element.id;
    if (element.value !== '') {
      element.value = prependZero(element.value);
      switch (elementId) {
        case 'h-label':
          this.h = element.value;
          break;
        case 'm-label':
          this.m = element.value;
          break;
        case 's-label':
          this.s = element.value;
          break;
        default:
          this.amPm = element.value;
      }
    }
  }
  updateTimeWhenInputFieldHasValue() {
    var _a, _b, _c, _d;
    const hour =
      (_a = this === null || this === void 0 ? void 0 : this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#h-label');
    const minute =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#m-label');
    this.convertValueToZeroZeroIfValueIsEmptyForInput(hour);
    this.convertValueToZeroZeroIfValueIsEmptyForInput(minute);
    if (this.clockFormat === '12-hour') {
      const ampm =
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#ap-label');
      this.convertValueToAMIfValueIsEmptyForInput(ampm);
      this.amPm = ampm.value;
    }
    this.h = hour.value;
    this.m = minute.value;
    if (!this.mini) {
      const second =
        (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('#s-label');
      this.convertValueToZeroZeroIfValueIsEmptyForInput(second);
      this.s = second.value;
    } else {
      this.s = '00';
    }
    this.timeValueChangedEvent.emit(new TimeValueChangedEvent('time-value-changed'));
  }
  convertValueToZeroZeroIfValueIsEmptyForInput(inputBox) {
    if (inputBox.value === '' || inputBox.value === '00') {
      if (inputBox.id === 'h-label') {
        inputBox.value = this.getHourValueIfClockFormat24();
      } else {
        inputBox.value = '00';
      }
    }
  }
  convertValueToAMIfValueIsEmptyForInput(amPMInputBox) {
    if (amPMInputBox.value === '') {
      amPMInputBox.value = 'AM';
    }
  }
};
__decorate([event()], SHTimepickerInput.prototype, 'timeValueChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHTimepickerInput.prototype, 'h', void 0);
__decorate([property({ type: String, reflect: true })], SHTimepickerInput.prototype, 'm', void 0);
__decorate([property({ type: String, reflect: true })], SHTimepickerInput.prototype, 's', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'am-pm' })],
  SHTimepickerInput.prototype,
  'amPm',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepickerInput.prototype,
  'mini',
  void 0
);
__decorate(
  [property({ type: Number, reflect: true })],
  SHTimepickerInput.prototype,
  'step',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'clock-format' })],
  SHTimepickerInput.prototype,
  'clockFormat',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepickerInput.prototype,
  'disabled',
  void 0
);
SHTimepickerInput = __decorate([customElement('sh-timepicker-input')], SHTimepickerInput);
export { SHTimepickerInput };
