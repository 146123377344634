// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import boxStyles from './sh-box.lit.scss.js';
export const boxListOfProperties = ['background', 'padding', 'elevation', 'contentGap', 'layout'];
/**
 * @slot - The default slot where sh-box-section components are rendered. Accepts multiple sections that will be arranged according to the layout property.
 * @slot header - Takes an sh-box-header component for the box header content.
 * @slot footer - Takes an sh-box-footer component for the box footer content.
 * @fires background-changed {BackgroundPropertyChangedEvent} - *hide emitted when the background property changes.
 * @fires padding-changed {PaddingPropertyChangedEvent} - *hide emitted when the padding property changes.
 * @fires elevation-changed {ElevationPropertyChangedEvent} - *hide emitted when the elevation property changes.
 * @fires content-gap-changed {ContentGapPropertyChangedEvent} - *hide emitted when the contentGap property changes.
 * @fires layout-changed {LayoutPropertyChangedEvent} - *hide emitted when the layout property changes.
 */
let SHBox = class SHBox extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** Defines the background of the box. Valid values are 'surface', 'surface-secondary', 'surface-tertiary', or 'transparent'. */
    this.background = 'surface';
    /** Defines the padding of the box. Accepts predefined values 'wide', 'compact', 'supercompact', 'none', or a custom CSS value. The padding scales appropriately based on viewport size when using predefined values. */
    this.padding = 'compact';
    /** Defines the elevation style for the box. Valid values are 'none', 'subtle', 'moderate', or 'spotlight', controlling the shadow appearance of the box. */
    this.elevation = 'none';
    /** Defines the gap between content sections inside the box. Valid values are 0, 1, 2, 4, or 8, representing the gap size in pixels. */
    this.contentGap = 0; // defaults to 0 (none), can be set to 1px, 2px, 4px or 8px
    /** Defines the layout direction for box sections. Valid values are 'vertical' or 'horizontal', determining how sections are arranged and how their heights are calculated. Vertical layout uses natural height while horizontal layout matches all sections to the tallest section's height. */
    this.layout = 'vertical';
  }
  static get styles() {
    return [sharedStyles, boxStyles];
  }
  render() {
    return html`
      <slot name="header"></slot>
      <div class="box-section">
        <div class="sections-wrapper">
          <slot></slot>
        </div>
      </div>
      <slot name="footer"></slot>
    `;
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, boxListOfProperties);
    const sections = this.querySelectorAll('sh-box-section');
    for (let i = 0; i < sections.length; i++) {
      // Remove existing layout-related classes
      sections[i].classList.remove('horizontal', 'vertical');
      // Add the new class based on the layout
      sections[i].classList.add(this.layout);
    }
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    if (!['wide', 'compact', 'supercompact', 'none'].includes(newval)) {
      const paddingAttributes = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'];
      if (name === 'padding') {
        this.style.setProperty(`--container-padding`, newval || `var(--container-padding)`);
        for (const eachItem of paddingAttributes) {
          this.style.setProperty(`--${eachItem}`, newval || `var(--${eachItem})`);
        }
      }
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'background', void 0);
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'padding', void 0);
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'elevation', void 0);
__decorate(
  [property({ type: Number, reflect: true, attribute: 'content-gap' })],
  SHBox.prototype,
  'contentGap',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'layout', void 0);
__decorate([event()], SHBox.prototype, 'backgroundChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'paddingChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'elevationChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'contentGapChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'layoutChangedEvent', void 0);
SHBox = __decorate([customElement('sh-box')], SHBox);
export { SHBox };
