// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-media-controls.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-media-controls-volume-tooltip=none] - Volume tooltip display
   */
  --sh-media-controls-volume-tooltip: none;
  /* 
   * @cssprop [--sh-media-controls-volume-popover-padding=1rem 0] - Volume popover padding
   */
  --sh-media-controls-volume-popover-padding: 1rem 0;
  /* 
   * @cssprop [--sh-media-controls-volume-slider-height=8rem] - Height of the volume slider
   */
  --sh-media-controls-volume-slider-height: 8rem;
  /* 
   * @cssprop [--sh-media-controls-wrapper-padding=0 1rem] - Control wrapper padding
   */
  --sh-media-controls-wrapper-padding: 0 1rem;
  /* 
   * @cssprop [--sh-media-controls-padding-top-bottom=1rem] - Control wrapper padding top & bottom
   */
  --sh-media-controls-padding-top-bottom: 1rem;
  /* 
   * @cssprop [--sh-media-controls-player-wrapper-margin=0 0.5rem 0 0] - Margin of the player controls
   */
  --sh-media-controls-player-wrapper-margin: 0 0.5rem 0 0;
  /* 
   * @cssprop [--sh-media-controls-settings-wrapper-margin=0 1rem 0 0.5rem] - Margin of the settings controls
   */
  --sh-media-controls-settings-wrapper-margin: 0 1rem 0 0.5rem;
  /* 
   * @cssprop [--sh-media-controls-settings-child-margin-left=0.5rem] - Margin of the settings controls first child
   */
  --sh-media-controls-settings-child-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-media-controls-settings-child-margin-right=1rem] - Margin of the settings controls last child
   */
  --sh-media-controls-settings-child-margin-right: 1rem;
  /* 
   * @cssprop [--sh-media-controls-player-content-gap=1rem] - gap between the player controls
   */
  --sh-media-controls-player-content-gap: 1rem;
  /* 
   * @cssprop [--sh-media-controls-feature-wrapper-margin=0 0 0 0.5rem] - margin of the feature control wrapper
   */
  --sh-media-controls-feature-wrapper-margin: 0 0 0 0.5rem;
  /* 
   * @cssprop [--sh-media-controls-time-wrapper-margin=0 0.5rem 0 1rem] - margin of the time wrapper
   */
  --sh-media-controls-time-wrapper-margin: 0 0.5rem 0 1rem;
  /* 
   * @cssprop [--sh-media-controls-progress-height=0.125rem] - height of the media progress bar
   */
  --sh-media-controls-progress-height: 0.125rem;
  /* 
   * @cssprop [--sh-media-controls-progress-top=-0.75rem] - top position value for media progress bar
   */
  --sh-media-controls-progress-top: -0.75rem;
  /* 
   * @cssprop [--sh-media-controls-volume-popover-min-width=2.5rem] - Volume popover min width
   */
  --sh-media-controls-volume-popover-min-width: 2.5rem;
  /* 
   * @cssprop [--sh-media-controls-volume-popover-bottom=3rem] - Volume popover position bottom
   */
  --sh-media-controls-volume-popover-bottom: 3rem;
  /* 
   * @cssprop [--sh-media-controls-volume-popover-left=-0.5rem] - Volume popover position left
   */
  --sh-media-controls-volume-popover-left: -0.5rem;
  /* 
   * @cssprop [--sh-media-controls-playback-speed-size=1.75rem] - height and width of the playback speed
   */
  --sh-media-controls-playback-speed-size: 1.75rem;
  /* 
   * @cssprop [--sh-media-controls-playback-popover-width=fit-content] - width and min-width of the playback popover
   */
  --sh-media-controls-playback-popover-width: fit-content;
  /* 
   * @cssprop [--sh-media-controls-text-line-height=0.875rem] - line height of the primary & secondary text
   */
  --sh-media-controls-text-line-height: 0.875rem;
  /* 
   * @cssprop [--sh-media-controls-separator-margin=0rem] - margin for the extended slot separator 
   */
  --sh-media-controls-separator-margin: 0rem;
  /* 
   * @cssprop [--sh-media-controls-divider-height=1.5rem] - height of the divider that separates
   */
  --sh-media-controls-divider-height: 1.5rem;
  /* 
   * @cssprop [--sh-media-controls-input-left=-0.125rem] - position left of the input inside the media progress
   */
  --sh-media-controls-input-left: -0.125rem;
  /* 
   * @cssprop [--sh-media-controls-progress-left=0] - position left of the slider & buffer progress inside the media progress
   */
  --sh-media-controls-progress-left: 0;
  /* 
   * @cssprop [--sh-media-controls-progress-margin-left=0.5rem] - progress left margin 
   */
  --sh-media-controls-progress-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-media-controls-playback-text-outline-offset=calc(-2 * var(--sh-icon-active-padding-size-s))] - playback text outline offset on focus
   */
  --sh-media-controls-playback-text-outline-offset: calc(-2 * var(--sh-icon-active-padding-size-s));
  /* 
   * @cssprop [--sh-media-controls-border-radius=var(--sh-radius-container)] - border-radius of the media control
   */
  --sh-media-controls-border-radius: var(--sh-radius-container);
}

:host {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --sh-slider-tooltip-display: var(--sh-media-controls-volume-tooltip);
}

.control-wrapper {
  width: 100%;
  height: calc(var(--sh-icon-size-s) + var(--sh-media-controls-padding-top-bottom) * 2);
  display: flex;
  align-items: center;
  border-radius: var(--sh-media-controls-border-radius);
  padding: var(--sh-media-controls-wrapper-padding);
  box-sizing: border-box;
}

:host([background-style=blur][slot=footer]) .control-wrapper::before,
:host([progress-position=top][background-style=blur]) .control-wrapper::before,
:host(:not([empty-extended])[background-style=blur]) .control-wrapper::before,
:host([slot=footer]) .control-wrapper,
:host([progress-position=top]) .control-wrapper,
:host(:not([empty-extended])) .control-wrapper {
  border-radius: 0 0 0.125rem 0.125rem;
}

:host([background-style=blur]:not([empty-extended])) .extended-slot-wrapper::before,
:host([background-style=blur]) .control-wrapper::before {
  background-color: rgba(var(--blur-background-1), var(--opacity-3));
  backdrop-filter: blur(var(--blur-1));
  height: 100%;
  width: 100%;
  border-radius: var(--sh-media-controls-border-radius);
  position: absolute;
  left: 0;
  content: "";
}

:host([background-style=solid]) .control-wrapper,
:host([background-style=solid]:not([empty-extended])) .extended-slot-wrapper {
  background-color: var(--base-4);
}

:host([background-style=flat]) .control-wrapper,
:host([background-style=flat]:not([empty-extended])) .extended-slot-wrapper {
  background-color: transparent;
}

.control-progress-wrapper {
  position: relative;
  height: calc(var(--sh-icon-size-s) + var(--sh-media-controls-padding-top-bottom) * 2);
}

.player-controls-wrapper {
  display: flex;
  align-items: center;
  gap: var(--sh-media-controls-player-content-gap);
  margin: var(--sh-media-controls-player-wrapper-margin);
}

.setting-controls-wrapper {
  display: flex;
  align-items: center;
  gap: var(--sh-media-controls-player-content-gap);
  position: relative;
}

:host([progress-position=center]:not([playback-speed-control=hidden])) .setting-controls-wrapper > *:nth-last-child(2) {
  margin-right: var(--sh-media-controls-settings-child-margin-right);
}

:host([progress-position=center][playback-speed-control=hidden]) .setting-controls-wrapper > *:last-child {
  margin-right: var(--sh-media-controls-settings-child-margin-right);
}

:host([progress-position=center]:not([playback-speed-control])) .setting-controls-wrapper > *:last-child,
:host([progress-position=center][playback-speed-control=hidden]) .setting-controls-wrapper > *:last-child {
  margin-right: var(--sh-media-controls-settings-child-margin-right);
}

:host(:not([playback-speed-control]):not([loop-control])[progress-position=center]:not([empty-settings-left])) .setting-controls-wrapper,
:host(:not([empty-settings-left])[progress-position=center][playback-speed-control=hidden][loop-control=hidden]) .setting-controls-wrapper {
  margin-right: var(--sh-media-controls-settings-child-margin-right);
}

:host([progress-position=center][empty-settings-left]) .setting-controls-wrapper > :nth-child(2) {
  margin-left: var(--sh-media-controls-settings-child-margin-left);
}

:host([progress-position=center]:not([empty-settings-left])) .setting-controls-wrapper {
  margin-left: var(--sh-media-controls-settings-child-margin-left);
}

.feature-controls-wrapper {
  display: flex;
  align-items: center;
  gap: var(--sh-media-controls-player-content-gap);
}

:host(:not([empty-feature-right])[fullscreen-control=hidden][volume-control=hidden]) .feature-controls-wrapper,
:host(:not([empty-feature-right]):not([fullscreen-control]):not([volume-control])) .feature-controls-wrapper {
  margin-left: var(--sh-media-controls-settings-child-margin-left);
}

.feature-controls-wrapper > *:first-child {
  margin: var(--sh-media-controls-feature-wrapper-margin);
}

.time-wrapper {
  margin: var(--sh-media-controls-time-wrapper-margin);
  width: max-content;
}

.divider {
  position: relative;
  height: var(--sh-media-controls-divider-height);
  width: 1rem;
}

:host([progress-position=top]) sh-media-progress {
  --sh-media-controls-input-left: 0rem;
  --sh-media-controls-progress-left: 0.125rem;
  left: -0.125rem;
  position: absolute;
  height: var(--sh-media-controls-progress-height);
  --sh-media-progress-progress-border-radius: 0rem;
  z-index: 1;
}

:host([progress-position=center]) sh-media-progress {
  height: var(--sh-media-controls-progress-height);
}

:host([progress-position=center]) sh-media-progress .input {
  border-radius: 0.125rem;
}

:host(:not([loop-control]):not([playback-speed-control])[empty-settings-left][progress-position=center]) sh-media-progress,
:host([loop-control=hidden][playback-speed-control=hidden][empty-settings-left][progress-position=center]) sh-media-progress {
  margin-left: var(--sh-media-controls-progress-margin-left);
}

:host([progress-position=top]) .player-controls-wrapper {
  justify-content: center;
  flex: 1;
}

:host([progress-position=top]) .setting-controls-wrapper {
  flex: 1;
}

#playback-speed {
  display: flex;
  align-items: center;
  width: max-content;
  --sh-icon-size-s: var(--sh-media-controls-playback-speed-size);
  justify-content: center;
  min-width: var(--sh-icon-size-s);
  min-height: var(--sh-icon-size-s);
  height: var(--sh-icon-size-s);
  box-sizing: border-box;
  padding: 0 var(--sh-icon-active-padding-size-s);
  cursor: pointer;
}

#playback-speed:hover {
  color: rgba(var(--ui-1), var(--opacity-2));
}

#playback-speed.active {
  background: rgba(var(--ui-1), var(--opacity-6));
  border-radius: var(--sh-icon-border-radius);
  color: rgba(var(--ui-1), var(--opacity-2));
}

#playback-speed:focus-visible {
  outline: var(--focus-outline);
  outline-offset: var(--sh-media-controls-playback-text-outline-offset);
}

#volume-popover {
  --sh-popover-headerless-body-top-padding: var(--sh-media-controls-volume-popover-padding);
  --sh-popover-min-width: var(--sh-media-controls-volume-popover-min-width);
  width: var(--sh-media-controls-volume-popover-min-width);
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: var(--sh-media-controls-volume-popover-bottom);
  left: var(--sh-media-controls-volume-popover-left);
}

.playback-popover {
  --sh-popover-min-width: var(--sh-media-controls-playback-popover-width);
  width: var(--sh-media-controls-playback-popover-width);
}

:host(:not([empty-extended])) .extended-slot-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  min-height: calc(var(--sh-icon-size-s) + var(--sh-media-controls-padding-top-bottom) * 2);
  align-items: center;
  border-radius: 0.125rem 0.125rem 0 0;
  box-sizing: border-box;
  padding: var(--sh-media-controls-wrapper-padding);
}

:host(:not([empty-extended])[background-style=blur]) .extended-slot-wrapper::before {
  border-radius: 0.125rem 0.125rem 0 0;
}

:host([slot=footer]:not([empty-extended])[background-style=blur]) .extended-slot-wrapper::before,
:host([slot=footer]:not([empty-extended])) .extended-slot-wrapper {
  border-radius: 0;
}

:host([progress-position=center]:not([empty-extended])) .extended-slot-wrapper {
  position: relative;
  bottom: -0.0625rem;
}

.extended-slot-wrapper {
  gap: var(--sh-media-controls-player-content-gap);
}

#volume-slider {
  height: var(--sh-media-controls-volume-slider-height);
}

.right-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  position: relative;
}

.primary-text,
.secondary-text {
  line-height: var(--sh-media-controls-text-line-height);
}

.separator {
  --sh-divider-horizontal-s: var(--sh-media-controls-separator-margin);
  z-index: 1;
  width: 100%;
}

#volume {
  padding: 0.75rem 0;
  position: relative;
}

.extended-slot-wrapper > ::slotted([slot=extended-controls]) {
  position: relative;
}`;