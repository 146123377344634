// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
export const SliderInputRectifierMixin = (base) => {
  class InputRectifierMixin extends base {
    constructor() {
      super(...arguments);
      this.stableValue = 0;
      this.lowStable = 0;
      this.highStable = 0;
    }
    getLowerValue(value, lowerValue, upperValue, min, max) {
      this.lowStable = lowerValue;
      // If lower input control is empty
      if (value === null || undefined) {
        value = min;
      } else {
        value = Number(value);
      }
      // Check if the input's value is in allowed range (min <= x <= max)
      if (value >= min && value <= max) {
        // If lower input control is greater than upper value (l > h)
        if (value > upperValue) {
          value = this.lowStable !== undefined ? this.lowStable : min;
        }
      }
      // If lower input control < minimum or > maximum
      if (value < min || value > max) {
        value = this.lowStable !== undefined ? this.lowStable : min;
      }
      // If l <= h and min <= l <= max, then l is stable
      if (value <= upperValue && value >= min && value <= max) {
        this.lowStable = value;
      }
      // If computed number is not a number, then replace with stable values
      value = !Number.isNaN(value) ? this.lowStable : min;
      return value;
    }
    getUpperValue(value, lowerValue, upperValue, min, max) {
      this.highStable = upperValue;
      // If upper input control is empty
      if (value === null || undefined) {
        value = max;
      } else {
        value = Number(value);
      }
      // Check if the input's value is in allowed range (min <= x <= max)
      if (value >= min && value <= max) {
        // If upper input control is lesser than lower value (h < l)
        if (value < lowerValue) {
          value = this.highStable !== undefined ? this.highStable : max;
        }
      }
      // If upper input control > maximum or < minimum
      if (value > max || value < min) {
        value = this.highStable !== undefined ? this.highStable : max;
      }
      // If l <= h and min <= h <= max,then h is stable
      if (value >= lowerValue && value >= min && value <= max) {
        this.highStable = value;
      }
      // If computed number is not a number, then replace with stable values
      value = !Number.isNaN(value) ? this.highStable : max;
      return value;
    }
    getValue(value, sliderValue, min, max) {
      this.stableValue = sliderValue;
      // If input control value is empty
      if (value === null || undefined) {
        value = min;
      } else {
        value = Number(value);
      }
      // If input value is less than minimum or greater than maximum (x < min or x > max)
      if (value < min || value > max) {
        value = this.stableValue !== undefined ? this.stableValue : min;
      }
      // If value is in range, (min <= x <= max), then value is stable
      if (value >= min && value <= max) {
        this.stableValue = value;
      }
      //If value is not a number, then replace input with stable values
      value = !Number.isNaN(value) ? this.stableValue : min;
      return value;
    }
  }
  return InputRectifierMixin;
};
