// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin.js';
import dropdownStyles from './sh-dropdown.lit.scss.js';
export const dropdownListOfProperties = [
  'label',
  'value',
  'hint',
  'active',
  'icon',
  'errorMessage',
  'readonly',
  'disabled',
  'condensed',
  'multiSelect',
  'noBorder',
  'noClear',
  'mandatory',
  'error',
  'success',
  'safety',
  'editable',
];
let SHDropdown = class SHDropdown extends RefrainSpaceScrollMixin(ShuiLitElement) {
  constructor() {
    super(...arguments);
    /** Defines the label of the dropdown. Will be shown as a placeholder if the value is empty. **/
    this.label = 'label';
    /** Defines whether the clear icon will be displayed. **/
    this.noClear = true;
    this.currentFocus = -1;
    this.multiValue = [];
    this.handleMenuItemActiveChange = this.updateDropdown.bind(this);
    this.resizeListener = this.handleResize.bind(this);
  }
  static get styles() {
    return [sharedStyles, dropdownStyles];
  }
  render() {
    return html`
      <div class="dropdown-wrapper">
        <div class="content-wrapper">
          ${
            this.icon && !this.multiSelect
              ? html` <sh-icon
                  icon="${this.icon}"
                  tabindex="-1"
                  size="${this.condensed ? 's' : 'm'}"
                  class="dropdown-icon"
                  ?disabled="${this.disabled}"
                ></sh-icon>`
              : ''
          }
          <sh-input-text
          id="dropdownInput"
          .label="${this.label}"
          .error="${this.error}"
          .errorMessage="${this.errorMessage}"
          .hint="${this.hint}"
          ?success="${this.success}"
          ?disabled="${this.disabled}"
          ?safety="${this.safety}"
          ?mandatory="${this.mandatory}"
          ?readonly="${this.readonly}"
          ?no-clear="${this.noClear}"
          .value="${this.value ? this.value : ''}"
          .inputReadonly="${!this.editable}"
          .showOutline="${this.active && !this.readonly}"
          .condensed="${this.condensed}"
          .noBorder="${this.noBorder}"
          .notifyInputClick="${true}"
          .showTooltip="${true}"
          .maxlength="${this.maxlength}"
          @clear-clicked="${this.resetValue}"
          type="text"
          @input="${this.inputChangeListener}"
          @value-changed="${(e) => e.stopPropagation()}"
          @input-hover="${(e) => {
            var _a;
            const input =
              (_a = e.target.shadowRoot) === null || _a === void 0
                ? void 0
                : _a.querySelector('#input');
            if (input.offsetWidth < input.scrollWidth) {
              input.setAttribute('title', this.value);
            } else {
              input.removeAttribute('title');
            }
          }}"
            @label-hover="${(e) => {
              var _a;
              const label =
                (_a = e.target.shadowRoot) === null || _a === void 0
                  ? void 0
                  : _a.querySelector('.label-wrapper');
              if (label.offsetWidth < label.scrollWidth) {
                label.setAttribute('title', this.label);
              } else {
                label.removeAttribute('title');
              }
            }}"
          >
          <sh-icon
          button
          tabindex="-1"
          slot="icon"
          id="dropdown-icon"
          icon="down-s"
          ></sh-icon>
        </sh-input-text>
      </div>
      <div class="overlay-container">
        <!-- @focus event specifically added as fix to a dropdown bug on firefox browser to get the focus back to the sh-dropdown 's outer
         wrapper as thefocus is lost on wrapper -(only when sh-text exists inside the sh-dropdown & only Firefox)
         upon Enter press & also when overlay is closed without any selection of menu item in the dropdown - happens because the focus goes inside of the overlay-->
        <sh-overlay
        class="overlay"
        style="width:${getComputedStyle(this).width}; top: calc(top - 16px);"
        @click="${(event) => (this.multiSelect ? event.stopPropagation() : '')}"
        scroll-action="cancel"
        no-overlap
        horizontal-align="left"
        @focus="${() => {
          var _a, _b;
          const inputText =
            (_a = this.shadowRoot) === null || _a === void 0
              ? void 0
              : _a.querySelector('#dropdownInput');
          const outerWrapper =
            (_b = inputText.shadowRoot) === null || _b === void 0
              ? void 0
              : _b.querySelector('.outer-wrapper');
          outerWrapper.focus();
        }}"
        @opened="${(e) => this.overlayOpenCallback(e)}"
       >
        <slot id="dropdownMenu" @slotchange="${this.handleSlotChange}"></slot>
      </sh-overlay>
    </div>
  </div>
</div>
`;
  }
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'listbox');
    this.addEventListener('active-changed', this.handleMenuItemActiveChange);
    this.addEventListener('click', this.handleClick);
    window.addEventListener('resize', this.resizeListener);
  }
  update(changedProperties) {
    var _a;
    if (changedProperties.has('active')) {
      const overlay =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.overlay');
      // Doing this check so that initially, when it reaches update
      // before the render is called (when active is set by default),
      // the overlay won't be present in the DOM.
      // Because of this, it would fail to open the overlay resulting in a console error.
      // So to avoid that, we wait for update to complete (so that render is complete).
      // In other cases, we do not need to wait for an update to complete, so we call
      // the function directly.
      if (overlay) {
        this.toggleDropdown();
      } else {
        this.updateComplete.then(() => {
          this.toggleDropdown();
        });
      }
    }
    if (changedProperties.has('value')) {
      this.valueObserver();
    }
    if (changedProperties.has('multiSelect')) {
      this.addMenuItemCheckboxes();
    }
    if (changedProperties.has('items')) {
      this.updateMaxHeight();
    }
    if (changedProperties.has('editable')) {
      this.noClear = false;
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, dropdownListOfProperties);
  }
  updateMaxHeight() {
    if (this.items) {
      const menuHeight = getComputedStyle(this).getPropertyValue('--sh-dropdown-overlay-height');
      let finalMenuHeight = Number(menuHeight.replace('rem', ''));
      finalMenuHeight = finalMenuHeight === 0 ? 2.5 : finalMenuHeight;
      this.style.setProperty('--max-height', `${this.items * finalMenuHeight}rem`);
    }
  }
  inputChangeListener(e) {
    var _a;
    const menuItems = this.querySelectorAll('sh-menu-item');
    const value = (this.value = e.target.value);
    let itemData;
    this.hideCategories();
    for (const menuItem of menuItems) {
      itemData = this.getItemData(menuItem.label, menuItem.value);
      if (itemData.toLowerCase().indexOf(value.toLowerCase()) === -1) {
        menuItem.classList.add('hidden');
      } else {
        menuItem.classList.remove('hidden');
      }
    }
    if (this.editable) {
      this.active = true;
      const overlay =
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('sh-overlay');
      overlay._fireResize();
      overlay.refit();
    }
  }
  hideCategories() {
    const categories = this.querySelectorAll(':not(sh-menu-item)');
    if (categories.length > 0) {
      for (const category of categories) {
        category.classList.add('hidden');
      }
    }
  }
  firstUpdated() {
    var _a;
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    this.addEventListener('overlay-canceled', this.overlayCancelledCallback);
    this.addEventListener('focus', () => this.handleFocus());
    //To prevent scrolling of parent element
    this.addEventListener('keydown', (e) => this.handleKeydown(e));
    const dropdownIcon =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#dropdown-icon');
    if (dropdownIcon) {
      dropdownIcon.setAttribute('tabindex', '-1');
    }
    this.updateMaxHeight();
  }
  handleResize() {
    this.active = false;
  }
  disconnectedCallback() {
    this.removeEventListener('active-changed', this.handleMenuItemActiveChange);
    window.removeEventListener('resize', this.resizeListener);
    super.disconnectedCallback();
  }
  addMenuItemCheckboxes() {
    const menuItems = this.querySelectorAll('sh-menu-item');
    for (const menuItem of menuItems) {
      menuItem.checkbox = true;
    }
  }
  handleSlotChange() {
    if (this.multiSelect) {
      this.addMenuItemCheckboxes();
    }
  }
  valueObserver() {
    if (this.value) {
      this.updateMenuItemState();
    } else {
      this.icon = null;
      if (this.editable) {
        this.removeHiddenClass();
        this.unselectActiveItems();
      }
    }
  }
  // Sets active property of required menuItem when dropdown value is set
  updateMenuItemState() {
    if (this.multiSelect) {
      return;
    }
    let menuItemFound = false;
    const menuItems = this.querySelectorAll('sh-menu-item');
    let activeMenuItemCount = 0;
    for (const menuItem of menuItems) {
      const itemData = this.getItemData(menuItem.label, menuItem.value);
      const itemIcon = menuItem.icon;
      if (this.icon && this.value === itemData && this.icon === itemIcon) {
        menuItem.active = true;
        menuItemFound = true;
        activeMenuItemCount += 1;
        this.removeHiddenClass();
      } else if (!this.icon && this.value === itemData) {
        menuItem.active = true;
        menuItemFound = true;
        activeMenuItemCount += 1;
        this.removeHiddenClass();
      } else {
        menuItem.active = false;
      }
    }
    if (activeMenuItemCount > 1) {
      console.warn(`Multiple menu-items are found to be active. This happens when no difference is found in terms of label or icon used.
        If not multi-select it is advisable to have only a single menu-item as active.`);
    }
    if (!menuItemFound) {
      this.icon = null;
    }
  }
  updateDropdown(e) {
    if (e.target === this) {
      return;
    }
    const menuItem = e.target;
    const clickevent = menuItem.clicked;
    if (this.multiSelect) {
      this.updateMultiSelectDropdownValue(menuItem);
    } else {
      this.updateDropdownValue(menuItem, clickevent);
    }
  }
  overlayCancelledCallback(e) {
    const event = e.detail;
    if (event.type === 'click' && event.target !== this) {
      this.active = false;
    } else if ((event.type === 'keydown' && event.code === 'Escape') || event.type === 'scroll') {
      this.active = false;
      if (!this.active) {
        this.focusInputText();
      }
    }
  }
  handleClick(e) {
    var _a, _b;
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#dropdownInput');
    const input =
      (_b = inputText.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#input');
    if (
      e.target === this &&
      !this.disabled &&
      !this.readonly &&
      !e.composedPath().find((x) => {
        var _a;
        return (_a = x.classList) === null || _a === void 0 ? void 0 : _a.contains('helper-text');
      })
    ) {
      this.active = !this.active;
    }
    if (input) {
      if (this.active) {
        input.focus({ preventScroll: true });
      } else {
        input.blur();
      }
    }
    if (this.editable && this.active) {
      inputText.setAttribute('show-outline', 'true');
      input.removeAttribute('readonly');
    }
  }
  overlayOpenCallback(e) {
    var _a, _b;
    if (this.editable) {
      const inputText =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#dropdownInput');
      const input =
        (_b = inputText.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#input');
      input.focus();
    }
    const activeItem = this.querySelector('sh-menu-item[active]');
    if (activeItem && !this.multiSelect) {
      /**
       * The below code is responsible to scroll the menu-items to the one selected as the value of the dropdown.
       * offsetTop gives the desired value in chrome and edge but fails in firefox and safari browser
       * because of offsetParent being different. Hence replaced it with getBoundingClientRect().
       * To place it in the middle of the overlay, half of target and menu-item height is subtracted.
       */
      const activeTop = activeItem.getBoundingClientRect().top;
      const targetTop = e.target.getBoundingClientRect().top;
      const target = e.target;
      const activeItemOffsetTop = activeTop - targetTop + target.scrollTop;
      target.scrollTo({
        top: activeItemOffsetTop - (target.offsetHeight / 2 - activeItem.offsetHeight / 2),
      });
    }
  }
  handleKeydown(e) {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown' || e.code === 'Home' || e.code === 'End') {
      e.preventDefault();
      e.stopPropagation();
    } else if (e.code === 'Tab' && !e.shiftKey) {
      this.active = false;
      if (e.composedPath()[0].id === 'input') {
        this.toggleDropdown();
      } else {
        this.handleTabKey(e);
      }
    } else if (e.code === 'Tab' && e.shiftKey) {
      this.active = false;
      if (e.composedPath()[0].id === 'input') {
        this.toggleDropdown();
      } else {
        this.handleTabKey(e);
      }
    }
    if (keyboardInteraction(e, KEYCODE.SPACE) && !this.editable) {
      e.preventDefault();
    }
  }
  toggleDropdown() {
    var _a;
    const overlay =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.overlay');
    if (this.active) {
      overlay.open();
    } else {
      overlay.close();
      this.resetMenuItemHoverStyle();
      this.currentFocus = -1;
    }
  }
  updateDropdownValue(menuItem, clickevent) {
    const itemData = this.getItemData(menuItem.label, menuItem.value);
    if (menuItem.active) {
      this.value = itemData;
      this.updateDropdownIcon(menuItem);
      this.unselectSiblings(menuItem);
      this.active = false;
    } else if (!menuItem.active && this.value === itemData && clickevent) {
      if (!this.icon) {
        menuItem.active = true;
      } else {
        menuItem.active = this.icon === menuItem.icon;
      }
    }
  }
  updateDropdownIcon(menuItem) {
    if (this.value && menuItem.active && !this.multiSelect) {
      this.icon = menuItem.icon;
    } else if (!this.value || this.multiSelect) {
      this.removeAttribute('icon');
    }
  }
  unselectSiblings(menuItem) {
    const activeItems = this.querySelectorAll('sh-menu-item[active]');
    for (let i = 0; i < activeItems.length; i++) {
      if (this.icon && this.icon !== activeItems[i].icon) {
        activeItems[i].active = false;
      } else {
        // refer https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/29508/
        const thisActiveMenuItemNeedsToBeTurnedOff = this.doesThisActiveMenuItemNeedToBeTurnedOff(
          menuItem.label,
          menuItem.value,
          activeItems[i].label,
          activeItems[i].value
        );
        if (thisActiveMenuItemNeedsToBeTurnedOff) {
          activeItems[i].active = false;
        }
      }
    }
  }
  updateMultiSelectDropdownValue(menuItem) {
    const itemData = this.getItemData(menuItem.label, menuItem.value);
    if (menuItem.active) {
      this.multiValue.push(itemData);
    } else {
      this.multiValue = this.multiValue.filter((val) => val !== itemData);
    }
    this.value = this.multiValue.toString();
  }
  resetValue() {
    this.removeHiddenClass();
    this.active = false;
    this.value = '';
    this.unselectActiveItems();
    this.focusInputText();
  }
  removeHiddenClass() {
    const children = this.querySelectorAll('*');
    for (const child of children) {
      child.classList.remove('hidden');
    }
  }
  resetMenuItemHoverStyle() {
    if (this.currentFocus !== -1) {
      const menuItems = this.querySelectorAll('sh-menu-item.hover');
      for (const menuItem of menuItems) {
        menuItem.classList.remove('hover');
        menuItem.classList.remove('focus');
        menuItem.blur();
      }
    }
  }
  unselectActiveItems() {
    const activeItems = this.querySelectorAll('sh-menu-item[active]');
    for (let i = 0; i < activeItems.length; i++) {
      activeItems[i].active = false;
    }
    this.value = '';
  }
  handleFocus() {
    this.onkeyup = (e) => {
      this.keyEvents(e);
    };
  }
  keyEvents(e) {
    var _a;
    const overlay =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.overlay');
    if (
      e.code === 'Tab' &&
      e.composedPath()[0].id !== 'clear' &&
      e.shiftKey &&
      e.composedPath()[0].classList[0] === 'outer-wrapper'
    ) {
      this.inputDeSelect();
      this.focusInputText();
    }
    this.handleArrowSpaceKeyUp(e, overlay);
  }
  handleArrowSpaceKeyUp(e, overlay) {
    if (
      keyboardInteraction(e, KEYCODE.ENTER) ||
      (keyboardInteraction(e, KEYCODE.SPACE) && !this.editable)
    ) {
      this.handleEnterSpaceKeyUp(e);
    } else if ((e.code === 'ArrowDown' || e.code === 'Home') && !this.readonly) {
      const menuItems = this.querySelectorAll(`sh-menu-item:not([class='hidden']):not([disabled])`);
      this.handleArrowDownHomeKeyUp(overlay, menuItems, e);
    } else if ((e.code === 'ArrowUp' || e.code === 'End') && !this.readonly) {
      const menuItems = this.querySelectorAll(`sh-menu-item:not([class='hidden']):not([disabled])`);
      this.handleArrowUpEndKeyUp(overlay, menuItems, e);
    } else if (e.code === 'Backspace' && this.value === '' && !overlay.opened) {
      this.focusInputText();
    }
  }
  handleArrowUpEndKeyUp(overlay, menuItems, e) {
    if (!overlay.opened) {
      this.active = !this.active;
    } else {
      if (menuItems.length !== 0) {
        this.decrementCurrentFocus(menuItems);
        this.updateFocusedItem(e);
      }
    }
  }
  handleArrowDownHomeKeyUp(overlay, menuItems, e) {
    if (!overlay.opened) {
      this.active = !this.active;
    } else {
      if (menuItems.length !== 0) {
        this.currentFocus = ++this.currentFocus % menuItems.length;
        this.updateFocusedItem(e);
      }
    }
  }
  handleEnterSpaceKeyUp(e) {
    var _a, _b;
    const source = this.getEventSource(e);
    if (!this.readonly && source.tagName !== 'SH-MENU-ITEM') {
      const inputText =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#dropdownInput');
      const input =
        (_b = inputText.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#input');
      this.active = !this.active;
      if (this.editable) {
        if (!this.active) {
          inputText.removeAttribute('show-outline');
          input.setAttribute('readonly', 'true');
        } else {
          input.removeAttribute('readonly');
          inputText.setAttribute('show-outline', 'true');
          input.focus();
        }
      }
    } else {
      if (!this.active && !this.readonly) {
        this.focusInputText();
      }
    }
  }
  handleTabKey(e) {
    var _a, _b;
    const source = this.getEventSource(e);
    if (!this.readonly && source.tagName !== 'SH-MENU-ITEM') {
      const inputText =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#dropdownInput');
      const input =
        (_b = inputText.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#input');
      if (this.editable) {
        if (!this.active) {
          input.setAttribute('readonly', 'true');
        }
      }
    } else {
      if (!this.active && !this.readonly) {
        this.focusInputText();
      }
    }
  }
  updateFocusedItem(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
    const menuItems = this.querySelectorAll(`sh-menu-item:not([class='hidden']):not([disabled])`);
    this.resetMenuItemHoverStyle();
    menuItems[this.currentFocus].classList.add('hover');
    menuItems[this.currentFocus].classList.add('focus');
    menuItems[this.currentFocus].focus();
  }
  decrementCurrentFocus(menuItems) {
    if (this.currentFocus === -1) {
      this.currentFocus = (this.currentFocus + menuItems.length) % menuItems.length;
    } else {
      this.currentFocus = (this.currentFocus - 1 + menuItems.length) % menuItems.length;
    }
  }
  getEventSource(e) {
    const source = e.composedPath() ? e.composedPath()[0] : null;
    return source;
  }
  focusInputText() {
    var _a, _b;
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#dropdownInput');
    const outerWrapper =
      (_b = inputText.shadowRoot) === null || _b === void 0
        ? void 0
        : _b.querySelector('.outer-wrapper');
    outerWrapper.focus();
  }
  inputDeSelect() {
    var _a, _b;
    const inputText =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#dropdownInput');
    const input =
      (_b = inputText.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#input');
    input.focus();
    input.setSelectionRange(0, 0);
    input.blur();
  }
  // refer https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/29508/
  getItemData(menuItemLabel, menuItemValue) {
    switch (this.display) {
      case 'label': {
        return menuItemLabel;
      }
      case 'value': {
        return menuItemValue;
      }
      default: {
        return menuItemValue || menuItemLabel;
      }
    }
  }
  // refer https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/29508/
  doesThisActiveMenuItemNeedToBeTurnedOff(
    currentSelectedItemLabel,
    currentSelectedItemValue,
    testActiveItemLabel,
    testActiveItemValue
  ) {
    const currentSelectedItemHasLabel = Boolean(currentSelectedItemLabel);
    const currentSelectedItemHasValue = Boolean(currentSelectedItemValue);
    const hasLabelMismatch = testActiveItemLabel !== currentSelectedItemLabel;
    const hasValueMismatch = testActiveItemValue !== currentSelectedItemValue;
    switch (this.display) {
      case 'label': {
        return hasLabelMismatch;
      }
      case 'value': {
        return hasValueMismatch;
      }
      default: {
        return Boolean(
          (currentSelectedItemHasLabel && hasLabelMismatch) ||
            (currentSelectedItemHasValue && hasValueMismatch)
        );
      }
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHDropdown.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHDropdown.prototype, 'value', void 0);
__decorate([property({ type: String, reflect: true })], SHDropdown.prototype, 'hint', void 0);
__decorate([property({ type: String, reflect: true })], SHDropdown.prototype, 'icon', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'active', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'error-message' })],
  SHDropdown.prototype,
  'errorMessage',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'readonly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'disabled', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'condensed', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'multi-select' })],
  SHDropdown.prototype,
  'multiSelect',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-border' })],
  SHDropdown.prototype,
  'noBorder',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-clear' })],
  SHDropdown.prototype,
  'noClear',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'mandatory', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'error', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'success', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'safety', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDropdown.prototype, 'editable', void 0);
__decorate([property({ type: Number, reflect: true })], SHDropdown.prototype, 'items', void 0);
__decorate([property({ type: String, reflect: true })], SHDropdown.prototype, 'maxlength', void 0);
__decorate([property({ type: String, reflect: true })], SHDropdown.prototype, 'display', void 0);
__decorate([state()], SHDropdown.prototype, 'currentFocus', void 0);
__decorate([state()], SHDropdown.prototype, 'multiValue', void 0);
__decorate([event()], SHDropdown.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'valueChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'hintChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'errorMessageChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'readonlyChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'condensedChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'multiSelectChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'noBorderChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'noClearChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'mandatoryChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'errorChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'successChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'safetyChangedEvent', void 0);
__decorate([event()], SHDropdown.prototype, 'editableChangedEvent', void 0);
SHDropdown = __decorate([customElement('sh-dropdown')], SHDropdown);
export { SHDropdown };
