// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from "tslib";
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { calculatedBaseFontSize } from '../../utils/base-font-size.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { dispatchAttributeChangedEventTypesafe, event, ShuiLitElement, } from '../../utils/event-decorator';
import { ChangeEndEvent, LowerValuePropertyChangedEvent, UpperValuePropertyChangedEvent, } from '../../utils/events.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import { convertToPixel } from '../../utils/rem-to-pixel-convertor.js';
import { SliderInputRectifierMixin } from '../../utils/slider-input-rectifier-mixin.js';
import { SliderStepMarkerMixin } from '../../utils/slider-step-marker-mixin.js';
import rangeSliderStyles from './range-slider.lit.scss.js';
export const rangeSliderListOfProperties = ['lowerValue', 'upperValue'];
/**
 * @fires lower-value-changed {LowerValueChangedEvent} - *hide emitted when the value property changes.
 * @fires upper-value-changed {UpperValueChangedEvent} - *hide emitted when the upper value property changes.
 * @fires value-change-end {ValueChangedEndEvent} - *hide emitted when the value property changes.
 * @fires range-value-change-end {RangeValueChangeEndEvent} - *hide emitted when the lower range value or upper range value property changes.
 */
let SHRangeSlider = class SHRangeSlider extends SliderStepMarkerMixin(SliderInputRectifierMixin(ShuiLitElement), {}) {
    constructor() {
        super(...arguments);
        /** Default false. If set to `true`, user interaction won't be allowed and the styles of the component will change. */
        this.disabled = false;
        /** Label disabled in range slider. */
        this.label = 'Label';
        /** Default 0. Defines the minimum value allowed. */
        this.min = 0;
        /** Default 100. Defines the maximum value allowed. */
        this.max = 100;
        /** Default 1. Defines the intervals of the slider. If set to 0.1 for example, decimals will be allowed. */
        this.step = 1;
        /** Default 0. Defines the current upper value of the range in a slider . Must be a number between min and max values and cannot be less than the lower value. */
        this.upperValue = 0;
        /** Default 0. Defines the current lower value of the range in a slider . Must be a number between min and max values and cannot be more than the upper value. */
        this.lowerValue = 0;
        this.stepMarkers = [];
        /**Default false. If set to `true`,  tooltip is hidden in the slider on hover. */
        this.hideTooltip = false;
    }
    static get styles() {
        return [rangeSliderStyles];
    }
    render() {
        return html `
      ${this.label
            ? html ` <div class="label-wrapper">
            <sh-text class="slider-label" size="body-1" color="secondary">${this.label}</sh-text>
            ${this.showValue
                ? html ` <input
                    id="lowerInputText"
                    type="number"
                    min="${this.min}"
                    max="${this.max}"
                    step="${this.step}"
                    .value="${this.lowerValue}"
                    maxlength="4"
                    ?disabled="${this.disabled}"
                    @keyup="${this.onKeyPress}"
                    @blur="${this.handleOnBlur}"
                  />
                  <span class="value-separator">-</span>
                  <input
                    id="upperInputText"
                    type="number"
                    min="${this.min}"
                    max="${this.max}"
                    step="${this.step}"
                    .value="${this.upperValue}"
                    maxlength="4"
                    ?disabled="${this.disabled}"
                    @keyup="${this.onKeyPress}"
                    @blur="${this.handleOnBlur}"
                  />`
                : ''}
          </div>`
            : ''}
      <div
        id="rangeWrapper"
        class="range-wrapper"
        style="--min:${this.min}; --max:${this.max}; --lower-value:${this
            .lowerValue}; --upper-value:${this.upperValue};"
      >
        <div
          id="rangeTrack"
          class="range-track"
          @click="${(e) => this.moveThumbOnRangeTrack(e)}"
          @touchstart="${(e) => this.moveThumbOnRangeTrack(e)}"
        >
          <ul id="range_step_ticks" class="step_ticks">
            ${this.stepMarkers.map((item) => html ` <li
                  class="sh-step-thumb-item"
                  @click="${(e) => this.onTickClick(e)}"
                  data-value="${item.position}"
                  .style="${item.style}"
                  tabindex="0"
                  @keyup="${(e) => {
            if (e.code === 'Space' || e.code === 'Enter') {
                e.target.click();
            }
        }}"
                ></li>`)}
          </ul>
        </div>
        <div
          id="handleTrackWrapper"
          class="handle-track-wrapper"
          tabindex="${this.disabled ? '-1' : '0'}"
          @mousedown="${(e) => this.thumbDown(e)}"
          @touchstart="${(e) => this.thumbDown(e)}"
        >
          <div
            id="handleTrack"
            class="handle-track"
            @mousedown="${(e) => this.thumbDown(e)}"
            @touchstart="${(e) => this.thumbDown(e)}"
          ></div>
        </div>
        <input
          type="range"
          id="lower"
          class="range-track"
          min="${this.min}"
          max="${this.max}"
          label="${this.label}"
          step="${this.step}"
          .value="${this.lowerValue}"
          ?disabled="${this.disabled}"
          @mousedown="${(e) => this.thumbDown(e)}"
          @touchstart="${(e) => this.thumbDown(e)}"
          @input="${(e) => this.thumbOverlapValueCheckRange(e)}"
        />
        ${!this.hideTooltip
            ? html `<sh-text id="lowerRangeTooltip" color="secondary">${this.lowerValue}</sh-text>`
            : ''}
        <input
          type="range"
          id="upper"
          class="range-track"
          min="${this.min}"
          max="${this.max}"
          label="${this.label}"
          step="${this.step}"
          .value="${this.upperValue}"
          ?disabled="${this.disabled}"
          @mousedown="${(e) => this.thumbDown(e)}"
          @touchstart="${(e) => this.thumbDown(e)}"
          @input="${(e) => this.thumbOverlapValueCheckRange(e)}"
        />
        ${!this.hideTooltip
            ? html `<sh-text id="upperRangeTooltip" color="secondary">${this.upperValue}</sh-text>`
            : ''}
      </div>
    `;
    }
    update(changedProperties) {
        super.update(changedProperties);
        if (changedProperties.has('step')) {
            this.renderStepMarkersRange();
            this.thumbOverlapLowerValueCheckRange();
            this.thumbOverlapUpperValueCheckRange();
            this.generateHandleTrack();
            this.setInputWidth();
        }
        if (changedProperties.has('lowerValue')) {
            this.thumbOverlapLowerValueCheckRange();
            this.generateHandleTrack();
            this.lowerValueChangedEvent.emit(new LowerValuePropertyChangedEvent({ bubbles: true, composed: true }));
        }
        if (changedProperties.has('upperValue')) {
            this.thumbOverlapUpperValueCheckRange();
            this.generateHandleTrack();
            this.upperValueChangedEvent.emit(new UpperValuePropertyChangedEvent({ bubbles: true, composed: true }));
        }
        if (changedProperties.has('min') || changedProperties.has('max')) {
            this.renderStepMarkersRange();
            this.thumbOverlapLowerValueCheckRange();
            this.thumbOverlapUpperValueCheckRange();
            this.generateHandleTrack();
            this.setInputWidth();
        }
        if (changedProperties.has('isDrag')) {
            this.isDragObserver();
        }
    }
    updated(changedProperties) {
        dispatchAttributeChangedEventTypesafe(this, changedProperties, rangeSliderListOfProperties);
    }
    connectedCallback() {
        super.connectedCallback();
        // accessing the window events
        this.boundMove = this.thumbMove.bind(this);
        this.boundUp = this.thumbUp.bind(this);
        this.addEventListener('touchmove', this.boundMove);
        this.addEventListener('touchend', this.boundUp);
    }
    firstUpdated(changedProperties) {
        var _a, _b, _c, _d, _e, _f;
        super.firstUpdated(changedProperties);
        if (deviceIdentifier.isTouchDevice()) {
            this.classList.add('touch-device');
        }
        const lower = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#lower');
        const upper = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#upper');
        const handleTrack = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#handleTrackWrapper');
        const track = (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('#handleTrack');
        const sliderToolLower = (_e = this.shadowRoot) === null || _e === void 0 ? void 0 : _e.querySelector('#lowerRangeTooltip');
        const sliderToolUpper = (_f = this.shadowRoot) === null || _f === void 0 ? void 0 : _f.querySelector('#upperRangeTooltip');
        lower === null || lower === void 0 ? void 0 : lower.addEventListener('focus', () => this.handleFocus(lower, sliderToolLower));
        lower === null || lower === void 0 ? void 0 : lower.addEventListener('blur', () => this.handleBlur(lower, sliderToolLower));
        upper === null || upper === void 0 ? void 0 : upper.addEventListener('focus', () => this.handleFocus(upper, sliderToolUpper));
        upper === null || upper === void 0 ? void 0 : upper.addEventListener('blur', () => this.handleBlur(upper, sliderToolUpper));
        handleTrack.addEventListener('focus', () => this.handleFocusRangeTrack(handleTrack, track, sliderToolUpper, sliderToolLower, lower, upper));
        handleTrack.addEventListener('blur', () => this.handleBlurRangeTrack(handleTrack, track, sliderToolUpper, sliderToolLower, lower, upper));
        handleTrack.addEventListener('mouseover', () => this.addElementFocus(lower, upper));
        handleTrack.addEventListener('mouseout', () => this.removeElementFocus(lower, upper));
    }
    handleFocus(thumb, tooltip) {
        thumb.onkeyup = (e) => {
            tooltip === null || tooltip === void 0 ? void 0 : tooltip.classList.add('tab_tooltip');
            if (keyboardInteraction(e, KEYCODE.TAB)) {
                thumb === null || thumb === void 0 ? void 0 : thumb.classList.add('tab_outline');
            }
            if (keyboardInteraction(e, KEYCODE.ARROW_RIGHT) ||
                keyboardInteraction(e, KEYCODE.ARROW_UP) ||
                keyboardInteraction(e, KEYCODE.ARROW_LEFT) ||
                keyboardInteraction(e, KEYCODE.ARROW_DOWN)) {
                this.eventTargetSelect(e);
            }
        };
    }
    setInputWidth() {
        let totalLength;
        const maxValue = parseFloat(this.getAttribute('max'));
        const minValue = parseFloat(this.getAttribute('min'));
        const stepValue = parseFloat(this.getAttribute('step'));
        const absMaxValue = Math.abs(maxValue);
        const absMinValue = Math.abs(minValue);
        const integerPartLength = Math.ceil(Math.log10(Math.max(absMaxValue, absMinValue) + 1));
        const decimalPartLength = stepValue.toString().includes('.')
            ? stepValue.toString().split('.')[1].length
            : 0;
        if (minValue < 0) {
            // +1 for the decimal point and +1 for the minus sign
            totalLength = integerPartLength + (decimalPartLength > 0 ? decimalPartLength + 1 : 0) + 1;
        }
        else {
            totalLength = integerPartLength + (decimalPartLength > 0 ? decimalPartLength + 1 : 0);
        }
        this.style.setProperty('--inp-w', totalLength + 'ch');
    }
    handleBlur(thumb, tooltip) {
        thumb === null || thumb === void 0 ? void 0 : thumb.classList.remove('tab_outline');
        tooltip === null || tooltip === void 0 ? void 0 : tooltip.classList.remove('tab_tooltip');
    }
    handleFocusRangeTrack(handleTrack, track, sliderToolUpper, sliderToolLower, lower, upper) {
        // to prevent the scrollbar from scrolling in a scrolling container for range track
        handleTrack.onkeydown = (e) => {
            if (keyboardInteraction(e, KEYCODE.ARROW_UP) ||
                keyboardInteraction(e, KEYCODE.ARROW_DOWN) ||
                keyboardInteraction(e, KEYCODE.ARROW_LEFT) ||
                keyboardInteraction(e, KEYCODE.ARROW_RIGHT)) {
                e.preventDefault();
            }
        };
        handleTrack.onkeyup = (e) => {
            if (keyboardInteraction(e, KEYCODE.TAB)) {
                handleTrack === null || handleTrack === void 0 ? void 0 : handleTrack.classList.add('tab_outline');
                track === null || track === void 0 ? void 0 : track.classList.add('tab_outline');
                sliderToolUpper === null || sliderToolUpper === void 0 ? void 0 : sliderToolUpper.classList.add('tab_tooltip');
                sliderToolLower === null || sliderToolLower === void 0 ? void 0 : sliderToolLower.classList.add('tab_tooltip');
                lower === null || lower === void 0 ? void 0 : lower.classList.add('tab_outline');
                upper === null || upper === void 0 ? void 0 : upper.classList.add('tab_outline');
            }
            if ((keyboardInteraction(e, KEYCODE.ARROW_RIGHT) || keyboardInteraction(e, KEYCODE.ARROW_UP)) &&
                Number(upper.value) < this.max) {
                this.lowerValue = parseFloat(lower.value) + this.step;
                this.upperValue = parseFloat(upper.value) + this.step;
                this.eventTargetSelect(e);
            }
            if ((keyboardInteraction(e, KEYCODE.ARROW_LEFT) ||
                keyboardInteraction(e, KEYCODE.ARROW_DOWN)) &&
                this.lowerValue > this.min) {
                this.lowerValue = parseFloat(lower.value) - this.step;
                this.upperValue = parseFloat(upper.value) - this.step;
                this.eventTargetSelect(e);
            }
        };
    }
    handleBlurRangeTrack(handleTrack, track, sliderToolUpper, sliderToolLower, lower, upper) {
        handleTrack === null || handleTrack === void 0 ? void 0 : handleTrack.classList.remove('tab_outline');
        track === null || track === void 0 ? void 0 : track.classList.remove('tab_outline');
        sliderToolUpper === null || sliderToolUpper === void 0 ? void 0 : sliderToolUpper.classList.remove('tab_tooltip');
        sliderToolLower === null || sliderToolLower === void 0 ? void 0 : sliderToolLower.classList.remove('tab_tooltip');
        lower === null || lower === void 0 ? void 0 : lower.classList.remove('tab_outline');
        upper === null || upper === void 0 ? void 0 : upper.classList.remove('tab_outline');
    }
    eventTargetSelect(e) {
        const target = e.target;
        this.selectedThumb = target.id;
        this.dispatchValueChangeEndEvent();
    }
    addElementFocus(lower, upper) {
        lower.classList.add('focus');
        upper.classList.add('focus');
    }
    removeElementFocus(lower, upper) {
        lower.classList.remove('focus');
        upper.classList.remove('focus');
    }
    renderStepMarkersRange() {
        var _a, _b;
        if (this.step === 0) {
            return;
        }
        const stepTickDistance = convertToPixel(parseFloat(getComputedStyle(this).getPropertyValue('--sh-slider-step-tick-min-width')));
        const input = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.range-track');
        const slider_step = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#range_step_ticks');
        const containerLength = input === null || input === void 0 ? void 0 : input.offsetWidth;
        const stepTickHalfWidth = parseFloat(getComputedStyle(this).getPropertyValue('--sh-slider-step-tick-width')) / 2;
        // calculates the distance between two consecutive step-markers
        const distance = this.getCalculatedStepDistance(containerLength, this.min, this.max, this.step, stepTickHalfWidth);
        if (distance >= stepTickDistance) {
            slider_step === null || slider_step === void 0 ? void 0 : slider_step.classList.add('markers');
            // calculates the step-marker points on track and store it in points array
            this.calculatePoints(containerLength, stepTickHalfWidth);
        }
        else {
            slider_step === null || slider_step === void 0 ? void 0 : slider_step.classList.remove('markers');
        }
    }
    onTickClick(e) {
        var _a, _b;
        e.stopPropagation();
        const upperValue = parseFloat(((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#upper')).value);
        const lowerValue = parseFloat(((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#lower')).value);
        const target = e.target;
        const tickValue = Number(target.dataset.value);
        if (tickValue > upperValue) {
            this.upperValue = tickValue;
        }
        else if (tickValue < lowerValue) {
            this.lowerValue = tickValue;
        }
    }
    thumbOverlapValueCheckRange(e) {
        const target = e.target;
        if (target.id === 'lower') {
            this.thumbOverlapLowerValueCheckRange();
        }
        else {
            this.thumbOverlapUpperValueCheckRange();
        }
    }
    thumbOverlapLowerValueCheckRange() {
        var _a, _b;
        const lower = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#lower');
        const upper = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#upper');
        lower.value = String(Math.min(lower.value, upper.value));
        this.updateLowerValue();
    }
    thumbOverlapUpperValueCheckRange() {
        var _a, _b;
        const lower = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#lower');
        const upper = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#upper');
        upper.value = String(Math.max(lower.value, upper.value));
        this.updateUpperValue();
    }
    generateHandleTrack() {
        var _a, _b, _c, _d;
        const handleTrackWrapper = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#handleTrackWrapper');
        const rangeWrapper = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#rangeWrapper');
        const lower = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#lower');
        const upper = (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('#upper');
        const lowerPosVal = ((Number(lower === null || lower === void 0 ? void 0 : lower.value) - this.min) * 100) / (this.max - this.min);
        const upperPosVal = ((Number(upper === null || upper === void 0 ? void 0 : upper.value) - this.min) * 100) / (this.max - this.min);
        const track = (rangeWrapper === null || rangeWrapper === void 0 ? void 0 : rangeWrapper.getBoundingClientRect().width) / calculatedBaseFontSize;
        const newLowerPosVal = (lowerPosVal / 100) * track;
        const newUpperPosVal = (upperPosVal / 100) * track;
        if (handleTrackWrapper) {
            handleTrackWrapper.style.left = newLowerPosVal + 'rem';
            if (this.upperValue === this.lowerValue) {
                handleTrackWrapper.style.width = '0rem';
            }
            else {
                handleTrackWrapper.style.width = newUpperPosVal - newLowerPosVal + 'rem';
            }
        }
    }
    thumbDown(e) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const target = e.target;
        const handleTrackWrapper = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#handleTrackWrapper');
        this.isDrag = true;
        this.selectedThumb = target.id;
        if (this.selectedThumb === 'handleTrackWrapper' || this.selectedThumb === 'handleTrack') {
            e.preventDefault();
            const offset = handleTrackWrapper.getBoundingClientRect();
            if (e.touches) {
                this.initialX =
                    (e.touches[0].clientX - offset.left) / calculatedBaseFontSize;
            }
            else {
                this.initialX = (e.clientX - offset.left) / calculatedBaseFontSize;
            }
            (_c = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#handleTrack')) === null || _c === void 0 ? void 0 : _c.classList.add('active');
            (_e = (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('#upper')) === null || _e === void 0 ? void 0 : _e.classList.add('active');
            (_g = (_f = this.shadowRoot) === null || _f === void 0 ? void 0 : _f.querySelector('#lower')) === null || _g === void 0 ? void 0 : _g.classList.add('active');
        }
        else if (this.selectedThumb === 'lower') {
            (_j = (_h = this.shadowRoot) === null || _h === void 0 ? void 0 : _h.querySelector('#lower')) === null || _j === void 0 ? void 0 : _j.classList.add('thumbActive');
        }
        else if (this.selectedThumb === 'upper') {
            (_l = (_k = this.shadowRoot) === null || _k === void 0 ? void 0 : _k.querySelector('#upper')) === null || _l === void 0 ? void 0 : _l.classList.add('thumbActive');
        }
        window.removeEventListener('mousemove', this.boundMove);
        window.removeEventListener('mouseup', this.boundUp);
        window.addEventListener('mousemove', this.boundMove);
        window.addEventListener('mouseup', this.boundUp);
    }
    thumbMove(e) {
        var _a, _b;
        if (this.isDrag) {
            if (this.selectedThumb === 'handleTrackWrapper' || this.selectedThumb === 'handleTrack') {
                this.moveThumbOnTrack(e);
            }
            if (!this.showValue && !this.hideTooltip) {
                ((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#lowerRangeTooltip')).style.display =
                    'flex';
                ((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#upperRangeTooltip')).style.display =
                    'flex';
            }
        }
    }
    thumbUp() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (this.isDrag) {
            this.isDrag = false;
            (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#handleTrack')) === null || _b === void 0 ? void 0 : _b.classList.remove('active');
            (_d = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#lower')) === null || _d === void 0 ? void 0 : _d.classList.remove('active');
            (_f = (_e = this.shadowRoot) === null || _e === void 0 ? void 0 : _e.querySelector('#upper')) === null || _f === void 0 ? void 0 : _f.classList.remove('active');
            (_h = (_g = this.shadowRoot) === null || _g === void 0 ? void 0 : _g.querySelector('#lower')) === null || _h === void 0 ? void 0 : _h.classList.remove('thumbActive');
            (_k = (_j = this.shadowRoot) === null || _j === void 0 ? void 0 : _j.querySelector('#upper')) === null || _k === void 0 ? void 0 : _k.classList.remove('thumbActive');
            if (!this.hideTooltip) {
                ((_l = this.shadowRoot) === null || _l === void 0 ? void 0 : _l.querySelector('#lowerRangeTooltip')).style.removeProperty('display');
                ((_m = this.shadowRoot) === null || _m === void 0 ? void 0 : _m.querySelector('#upperRangeTooltip')).style.removeProperty('display');
            }
        }
        window.removeEventListener('mousemove', this.boundMove);
        window.removeEventListener('mouseup', this.boundUp);
    }
    isDragObserver() {
        if (!this.isDrag) {
            this.dispatchValueChangeEndEvent();
        }
    }
    moveThumbOnTrack(e) {
        if (e.touches) {
            e.preventDefault();
        }
        this.calculatePositionInTrack(e);
        this.adjustThumbPositionToHandle();
    }
    calculatePositionInTrack(e) {
        var _a, _b;
        const decimalPoints = this.decimalNumberPrecision();
        const track = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#rangeWrapper');
        const handle = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#handleTrackWrapper');
        let maxLength, newThumbPos, checkVal;
        const offset = track.getBoundingClientRect();
        maxLength = Number(window.getComputedStyle(track).width.split('p')[0]) / calculatedBaseFontSize;
        newThumbPos = (e.clientX - offset.left) / calculatedBaseFontSize;
        //check for touch input
        if (e.touches !== undefined) {
            newThumbPos = (e.touches[0].clientX - offset.left) / calculatedBaseFontSize;
        }
        const handleWidth = handle.style.width.split('r')[0];
        const handleLeft = handle.style.left.split('r')[0];
        const sliderWidth = maxLength;
        maxLength -= Number(handleWidth);
        newThumbPos -= this.initialX;
        newThumbPos = this.calcPositionForRangeJump(newThumbPos, decimalPoints);
        const upperThumbPos = parseFloat(handleLeft) + parseFloat(handleWidth);
        const tempVal = (upperThumbPos / sliderWidth) * 100;
        checkVal = (tempVal * (this.max - this.min)) / 100 + this.min;
        checkVal = this.refineValueBasedOnStep(checkVal, decimalPoints);
        // update the thumb position
        switch (newThumbPos != null) {
            case newThumbPos < 0:
                this.setThumbToPosition(0);
                break;
            case newThumbPos > maxLength:
                if (checkVal + this.step <= this.max) {
                    this.setThumbToPosition(maxLength);
                }
                break;
            case newThumbPos >= 0 && newThumbPos <= maxLength:
                this.setThumbToPosition(newThumbPos);
                break;
            default:
                console.log('error adjusting thumb');
        }
    }
    adjustThumbPositionToHandle() {
        var _a, _b, _c;
        const handleTrack = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#handleTrackWrapper');
        const handleLeft = Number(handleTrack.style.left.split('r')[0]);
        const handleWidth = Number(handleTrack.style.width.split('r')[0]);
        const lowerPosVal = this.thumbPosition(handleLeft);
        const upperPosVal = this.thumbPosition(handleLeft + handleWidth);
        ((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#lower')).value = String(lowerPosVal);
        ((_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#upper')).value = String(upperPosVal);
        this.updateLowerValue();
        this.updateUpperValue();
    }
    thumbPosition(pointOnTrack) {
        const decimalPoints = this.decimalNumberPrecision();
        let posVal = this.calcThumbPosition(pointOnTrack);
        posVal = this.refineValueBasedOnStep(posVal, decimalPoints);
        return posVal;
    }
    calcThumbPosition(pointOnTrack) {
        var _a;
        const range = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#rangeWrapper');
        const divisor = Number(window.getComputedStyle(range).width.split('p')[0]) / calculatedBaseFontSize;
        const tempVal = (pointOnTrack / divisor) * 100;
        const posVal = (tempVal * (this.max - this.min)) / 100 + this.min;
        return posVal;
    }
    calcPositionForRangeJump(newThumbPos, decimalPoints) {
        var _a;
        const track = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#rangeWrapper');
        const divisor = Number(window.getComputedStyle(track).width.split('p')[0]) / calculatedBaseFontSize;
        const tempVal = this.calcThumbPosition(newThumbPos);
        const newlowVal = this.refineValueBasedOnStep(tempVal, decimalPoints);
        const posValue = ((newlowVal - this.min) * 100) / (this.max - this.min);
        newThumbPos = (posValue * divisor) / 100;
        return newThumbPos;
    }
    refineValueBasedOnStep(tempVal, decimalPoints) {
        let diff, newVal;
        const val = Number(tempVal.toFixed(decimalPoints)) - this.min;
        const quotient = Math.round(val / this.step);
        const num = this.step * quotient;
        diff = val - num;
        diff = Number(diff.toFixed(decimalPoints));
        newVal = tempVal - diff;
        newVal = Number(newVal.toFixed(decimalPoints));
        return newVal;
    }
    decimalNumberPrecision() {
        const stepInString = this.step.toString();
        const minInString = this.min.toString();
        let numberOfDecimalPoints;
        numberOfDecimalPoints = 0;
        if (stepInString.includes('.')) {
            numberOfDecimalPoints = stepInString.split('.')[1].length;
        }
        else if (minInString.includes('.')) {
            if (stepInString.split('.').length > 1) {
                numberOfDecimalPoints = stepInString.split('.')[1].length;
            }
        }
        return numberOfDecimalPoints;
    }
    setThumbToPosition(newThumbPos) {
        var _a;
        let thumbId = this.selectedThumb;
        if (thumbId === 'handleTrack') {
            thumbId = 'handleTrackWrapper';
        }
        const thumb = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#' + thumbId);
        thumb.style.left = newThumbPos + 'rem';
    }
    moveThumbOnRangeTrack(e) {
        var _a, _b, _c, _d;
        if (e.touches) {
            e.preventDefault();
        }
        if (!this.isDrag) {
            const range = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#rangeWrapper');
            let pointOnTrack, posVal;
            const offset = range.getBoundingClientRect();
            pointOnTrack = (e.clientX - offset.left) / calculatedBaseFontSize;
            //check for touch input
            if (e.touches !== undefined) {
                pointOnTrack =
                    (e.touches[0].clientX - offset.left) / calculatedBaseFontSize;
            }
            posVal = this.thumbPosition(pointOnTrack);
            if (posVal < this.min) {
                posVal = this.min;
            }
            if (posVal > this.max) {
                posVal = this.max;
            }
            const upPos = parseFloat(((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#upper')).value);
            if (posVal > upPos) {
                ((_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('#upper')).value = String(posVal);
                this.upperValue = posVal;
                this.selectedThumb = 'upper';
            }
            else {
                ((_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('#lower')).value = String(posVal);
                this.lowerValue = posVal;
                this.selectedThumb = 'upper';
            }
            this.dispatchValueChangeEndEvent();
        }
    }
    updateLowerValue() {
        var _a, _b;
        this.lowerValue =
            // If the step is non-decimal number, Lower value should be rounded off.
            this.step % 1 === 0
                ? Math.round(Number(((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#lower')).value))
                : Number(((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#lower')).value);
    }
    updateUpperValue() {
        var _a, _b;
        this.upperValue =
            // If the step is non-decimal number, Upper value should be rounded off.
            this.step % 1 === 0
                ? Math.round(Number(((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#upper')).value))
                : Number(((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#upper')).value);
    }
    onKeyPress(e) {
        if (!(keyboardInteraction(e, KEYCODE.ARROW_UP) ||
            keyboardInteraction(e, KEYCODE.ARROW_DOWN) ||
            keyboardInteraction(e, KEYCODE.ENTER))) {
            return;
        }
        const input = e.target;
        this.updateSelectedThumbForValidation(e);
        if (keyboardInteraction(e, KEYCODE.ARROW_UP)) {
            this.valueValidation(input, true);
            input.focus();
        }
        if (keyboardInteraction(e, KEYCODE.ARROW_DOWN)) {
            this.valueValidation(input, true);
            input.focus();
        }
        // If user press ENTER, then do validation
        if (keyboardInteraction(e, KEYCODE.ENTER)) {
            this.valueValidation(input, true);
            /* Enter case: isValueChanged is sent as true -it will dispatch the valueChangeEnd on enter
              irrespective of whether or not value has changed */
        }
    }
    handleOnBlur(e) {
        this.updateSelectedThumbForValidation(e);
        // on blur of input do the validation
        this.valueValidation(e.target, false);
    }
    updateSelectedThumbForValidation(e) {
        const target = e.target;
        if (target.id === 'lowerInputText') {
            this.selectedThumb = 'lower';
        }
        else {
            this.selectedThumb = 'upper';
        }
    }
    valueValidation(input, isValueChanged) {
        /*where isValueChanged is added to add a check to dispatch the valueChangeEnd only on change in value*/
        const value = Number(input.value);
        if (this.selectedThumb === 'lower') {
            this.lowerValue = Number(this.getLowerValue(value, this.lowerValue, this.upperValue, this.min, this.max));
            input.value = String(this.lowerValue);
        }
        else {
            this.upperValue = Number(this.getUpperValue(value, this.lowerValue, this.upperValue, this.min, this.max));
            input.value = String(this.upperValue);
        }
        if (isValueChanged) {
            this.dispatchValueChangeEndEvent();
        }
    }
    disconnectedCallback() {
        window.removeEventListener('mousemove', this.boundMove);
        window.removeEventListener('mouseup', this.boundUp);
        super.disconnectedCallback();
    }
    dispatchValueChangeEndEvent() {
        if (this.selectedThumb === 'handleTrackWrapper' || this.selectedThumb === 'handleTrack') {
            this.rangeValueChangeEndEvent.emit(new ChangeEndEvent('range-value-change-end', {
                bubbles: true,
                composed: true,
                detail: {
                    lowerValue: this.lowerValue,
                    upperValue: this.upperValue,
                },
            }));
        }
        else if (this.selectedThumb === 'lower') {
            this.valueChangeEndEvent.emit(new ChangeEndEvent('value-change-end', {
                detail: { lowerValue: this.lowerValue },
                bubbles: true,
                composed: true,
            }));
        }
        else {
            this.valueChangeEndEvent.emit(new ChangeEndEvent('value-change-end', {
                detail: { upperValue: this.upperValue },
                bubbles: true,
                composed: true,
            }));
        }
    }
};
__decorate([
    event()
], SHRangeSlider.prototype, "lowerValueChangedEvent", void 0);
__decorate([
    event()
], SHRangeSlider.prototype, "upperValueChangedEvent", void 0);
__decorate([
    event()
], SHRangeSlider.prototype, "valueChangeEndEvent", void 0);
__decorate([
    event()
], SHRangeSlider.prototype, "rangeValueChangeEndEvent", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SHRangeSlider.prototype, "disabled", void 0);
__decorate([
    property({ type: String, reflect: true })
], SHRangeSlider.prototype, "label", void 0);
__decorate([
    property({ type: Number, reflect: true })
], SHRangeSlider.prototype, "min", void 0);
__decorate([
    property({ type: Number, reflect: true })
], SHRangeSlider.prototype, "max", void 0);
__decorate([
    property({ type: Number, reflect: true })
], SHRangeSlider.prototype, "step", void 0);
__decorate([
    property({ type: Boolean, reflect: true, attribute: 'show-value' })
], SHRangeSlider.prototype, "showValue", void 0);
__decorate([
    property({ type: Number, reflect: true, attribute: 'upper-value' })
], SHRangeSlider.prototype, "upperValue", void 0);
__decorate([
    property({ type: Number, reflect: true, attribute: 'lower-value' })
], SHRangeSlider.prototype, "lowerValue", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], SHRangeSlider.prototype, "isDrag", void 0);
__decorate([
    property({ type: Boolean, reflect: true, attribute: 'hide-tooltip' })
], SHRangeSlider.prototype, "hideTooltip", void 0);
SHRangeSlider = __decorate([
    customElement('range-slider')
], SHRangeSlider);
export { SHRangeSlider };
