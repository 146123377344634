// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-checkbox.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-checkbox-inner-width=1rem] - checkbox width
   */
  --sh-checkbox-inner-width: 1rem;
  /* 
   * @cssprop [--sh-checkbox-inner-height=1rem] - checkbox height
   */
  --sh-checkbox-inner-height: 1rem;
  /* 
   * @cssprop [--sh-checkbox-outer-width=1.5rem] - checkbox outer width
   */
  --sh-checkbox-outer-width: 1.5rem;
  /* 
   * @cssprop [--sh-checkbox-outer-height=1.5rem] - checkbox outer height
   */
  --sh-checkbox-outer-height: 1.5rem;
  /* 
   * @cssprop [--sh-checkbox-tick-line-height=105%] - checkbox tick line height
   */
  --sh-checkbox-tick-line-height: 105%;
  /* 
   * @cssprop [--sh-checkbox-tick-font-size=0.875rem] - tick font size
   */
  --sh-checkbox-tick-font-size: 0.875rem;
  /* 
   * @cssprop [--sh-checkbox-border-width=0.0625rem] - checkbox border width
   */
  --sh-checkbox-border-width: 0.0625rem;
  /* 
   * @cssprop [--sh-checkbox-border-width-hover=0.125rem] - checkbox border width on hover
   */
  --sh-checkbox-border-width-hover: 0.125rem;
  /* 
   * @cssprop [--sh-checkbox-border-radius=var(--sh-radius-small-control)] - checkbox border radius
   */
  --sh-checkbox-border-radius: var(--sh-radius-small-control);
  /* 
   * @cssprop [--sh-checkbox-label-line-height=1.25rem] - checkbox label line height
   */
  --sh-checkbox-label-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-checkbox-margin-right=0.25rem] - checkbox margin right
   */
  --sh-checkbox-margin-right: 0.25rem;
  /* 
   * @cssprop [--sh-checkbox-margin-around-checkbox=0.25rem] - margin around checkbox
   */
  --sh-checkbox-margin-around-checkbox: 0.25rem;
  /* 
   * @cssprop [--sh-checkbox-outline-offset=0.125rem] - outline ofset
   */
  --sh-checkbox-outline-offset: 0.125rem;
  /* 
   * @cssprop [--sh-checkbox-label-width=1.75rem] - label width
   */
  --sh-checkbox-label-width: 1.75rem;
  /* 
   * @cssprop [--sh-checkbox-padding=0rem] - checkbox top and bottom padding
   */
  --sh-checkbox-padding: 0rem;
  /* 
   * @cssprop [--sh-checkbox-label-padding=0.125rem 0] - label padding
   */
  --sh-checkbox-label-padding: 0.125rem 0;
}

:host {
  display: flex;
  flex-direction: row;
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  outline: none;
  align-items: baseline;
  width: 100%;
  cursor: pointer;
  position: relative;
  font: var(--body-1);
  height: fit-content;
  padding: var(--sh-checkbox-padding) 0;
  border-color: rgba(var(--ui-1), var(--opacity-600));
}

:host([readonly]) {
  pointer-events: none;
}

#checkbox {
  width: var(--sh-checkbox-inner-width);
  height: var(--sh-checkbox-inner-height);
  opacity: 0;
  position: absolute;
  margin: 0 var(--sh-checkbox-margin-around-checkbox);
  top: var(--sh-checkbox-margin-around-checkbox);
  align-self: flex-start;
}

.checkboxLabel {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - var(--sh-checkbox-label-width));
  line-height: var(--sh-checkbox-label-line-height);
  padding: var(--sh-checkbox-label-padding);
  word-break: break-word;
}

#pseudoCheckbox::before {
  border-style: solid;
  border-width: var(--sh-checkbox-border-width);
  border-color: rgba(var(--ui-1), var(--opacity-600));
  width: var(--sh-checkbox-inner-width);
  height: var(--sh-checkbox-inner-height);
  position: absolute;
  margin: var(--sh-checkbox-margin-around-checkbox);
  border-radius: var(--sh-checkbox-border-radius);
  content: "\\EF2A";
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  box-sizing: border-box;
  transition: 0.05s ease-in-out all;
  font-family: "sh-icons";
  font-weight: 700;
  color: transparent;
  font-size: var(--sh-checkbox-tick-font-size);
  line-height: var(--sh-checkbox-tick-line-height);
  text-align: center;
  vertical-align: middle;
}

#pseudoCheckbox {
  width: var(--sh-checkbox-outer-width);
  height: var(--sh-checkbox-outer-height);
  cursor: pointer;
  position: relative;
  margin-right: var(--sh-checkbox-margin-right);
  align-self: flex-start;
  display: inline-block;
}

:host([label=""]),
:host(:not([label])) {
  width: fit-content;
  width: -moz-fit-content;
}

.item-focused:before {
  outline: var(--focus-outline);
  outline-offset: var(--sh-checkbox-outline-offset);
}

:host([active]) #pseudoCheckbox::before {
  color: var(--base-3);
  background: rgba(var(--ui-2), var(--opacity-1000));
  border-color: transparent;
}

:host([neutral][active]:not([disabled])) #pseudoCheckbox::before {
  background: var(--selection-ctrl-neutral-active-fill);
}

:host(:not([active]):hover:not(.touch-device)) #pseudoCheckbox::before {
  border-width: var(--sh-checkbox-border-width-hover);
}

:host(:not([active]):active:not(.touch-device)) #pseudoCheckbox::before {
  border-color: rgba(var(--ui-1), var(--opacity-900));
}

:host([active]:hover:not(.touch-device)) #pseudoCheckbox::before {
  background: rgba(var(--ui-3), var(--opacity-1000));
}

:host([active]:active:not(.touch-device)) #pseudoCheckbox::before {
  opacity: 1;
  background: rgba(var(--ui-2), var(--opacity-1000));
  border-color: transparent;
}

:host([neutral][active]:hover:not(.touch-device)) #pseudoCheckbox::before {
  background: rgba(var(--ui-1), var(--opacity-900));
}

:host([neutral][active]:active:not(.touch-device)) #pseudoCheckbox::before {
  background: rgba(var(--ui-1), var(--opacity-700));
  opacity: 1;
  border-color: transparent;
}

:host([indeterminate]) #pseudoCheckbox::before {
  content: "\\E011";
}

:host([readonly]) #pseudoCheckbox::before {
  opacity: 0.3;
}

:host([disabled]) {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--text-disabled);
}

:host([readonly]:not([active])) #pseudoCheckbox::before {
  background: rgba(var(--ui-1), var(--opacity-50));
  border-color: rgba(var(--ui-1), var(--opacity-600));
  opacity: 0.3;
}

:host([disabled]:not([active])) #pseudoCheckbox::before {
  border-color: rgba(var(--ui-1), var(--opacity-600));
  opacity: 0.3;
}

:host([active][disabled]:not([neutral])) #pseudoCheckbox::before {
  background: rgba(var(--ui-3), var(--opacity-1000));
  opacity: 0.3;
  border-color: transparent;
}

:host([active][readonly]:not([neutral])) #pseudoCheckbox::before {
  background: rgba(var(--ui-3), var(--opacity-1000));
  opacity: 0.3;
  border-color: transparent;
}

:host([neutral][active][disabled]) #pseudoCheckbox::before {
  background: rgba(var(--ui-1), var(--opacity-700));
  opacity: 0.3;
}

:host([neutral][active][readonly]) #pseudoCheckbox::before {
  background: rgba(var(--ui-1), var(--opacity-700));
  opacity: 0.3;
}`;