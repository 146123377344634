// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import boxStyles from './sh-box.lit.scss.js';
export const boxListOfProperties = ['background', 'padding', 'elevation', 'contentGap', 'layout'];
/**
 * * @slot - The slot where the main content is rendered. Takes any component as child.
 * @fires background-changed {BackgroundPropertyChangedEvent} - *hide emitted when the background property changes.
 * @fires padding-changed {PaddingPropertyChangedEvent} - *hide emitted when the padding property changes.
 * @fires elevation-changed {ElevationPropertyChangedEvent} - *hide emitted when the elevation property changes.
 * @fires content-gap-changed {ContentGapPropertyChangedEvent} - *hide emitted when the contentGap property changes.
 * @fires layout-changed {LayoutPropertyChangedEvent} - *hide emitted when the layout property changes.
 */
let SHBox = class SHBox extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** Defines the background of the box. */
    this.background = 'surface';
    /** Defines the padding of the box. Accepts predefined values or a custom CSS value. */
    this.padding = 'compact';
    /** Defines the elevation style for the box. */
    this.elevation = 'none';
    /** Defines the gap between content inside the box. */
    this.contentGap = 0; // defaults to 0 (none), can be set to 1px, 2px, or 4px
    /** Defines the layout of the box's content. */
    this.layout = 'vertical';
  }
  static get styles() {
    return [sharedStyles, boxStyles];
  }
  render() {
    return html`
      <slot name="header"></slot>
      <div class="box-section">
        <div class="sections-wrapper">
          <slot></slot>
        </div>
      </div>
      <slot name="footer"></slot>
    `;
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, boxListOfProperties);
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    if (!['wide', 'compact', 'supercompact', 'none'].includes(newval)) {
      const paddingAttributes = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'];
      if (name === 'padding') {
        this.style.setProperty(`--container-padding`, newval || `var(--container-padding)`);
        for (const eachItem of paddingAttributes) {
          this.style.setProperty(`--${eachItem}`, newval || `var(--${eachItem})`);
        }
      }
    }
  }
};
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'background', void 0);
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'padding', void 0);
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'elevation', void 0);
__decorate(
  [property({ type: Number, reflect: true, attribute: 'content-gap' })],
  SHBox.prototype,
  'contentGap',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHBox.prototype, 'layout', void 0);
__decorate([event()], SHBox.prototype, 'backgroundChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'paddingChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'elevationChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'contentGapChangedEvent', void 0);
__decorate([event()], SHBox.prototype, 'layoutChangedEvent', void 0);
SHBox = __decorate([customElement('sh-box')], SHBox);
export { SHBox };
