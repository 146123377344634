import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { event, ShuiLitElement } from '../../utils/event-decorator.js';
import listItemStyles from './sh-list-item.lit.scss.js';
/**
 * @fires active-changed {ActivePropertyChangedEvent} - *hide emitted when the active property changes.
 */
let SHListItem = class SHListItem extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**Defines the selectable property of the list Item. Adds checkbox/ radio-button based on the `selection-type` of the list*/
    this.selectable = false;
    /** Defines the active state of the list Item.  If set to `true`, the style will adapt to represent that the item is currently active. */
    this.active = false;
    /** Defines the toggling of the active state of the list Item. */
    this.toggle = false;
    /** If set to true, the item will be shown as disabled and won't allow clicks. */
    this.disabled = false;
    /** Defines the divider style placed between the list item. Possible values are `inset-left`,`inset`,`full-width`,`none`.
     * @type {"inset-left"|"inset"|"full-width"|"none"}
     */
    this.divider = 'inset-left';
    /** Defines the  icon size of the list item icon. Possible values are `s`, `m`, `l`.
     * @type { "s"|"m"|"l"}
     */
    this.iconSize = 's';
    /** Defines the vertical alignment of the list Item's checkbox when `selectable` is set or arrow when `href` is set. Possible values are `center` and `top`.
     * @type {"center"|"top"}
     */
    this.verticalAlign = 'center';
    /** Defines the interactive state of the list Item. When set, list item hover styles are applied and it is clickable */
    this.clickable = false;
    /** Hides the arrow shown when `href` is set along with this property . */
    this.hideLinkArrow = false;
  }
  static get styles() {
    return [listItemStyles];
  }
  render() {
    var _a;
    return html`
      <div class="outer-wrapper">
        ${this.selectable
          ? html` <div class="select-wrapper">
              ${((_a = this.closest('sh-list')) === null || _a === void 0
                ? void 0
                : _a.selectionType) == 'single'
                ? html`<sh-radio-button
                    class="select"
                    label=""
                    @click="${() => this.handleSelectClicked()}"
                    .active=${this.active}
                  ></sh-radio-button>`
                : html`<sh-checkbox
                    class="select"
                    label=""
                    .active=${this.active}
                    @click="${() => this.handleSelectClicked()}"
                  ></sh-checkbox>`}
            </div>`
          : ''}
        <slot name="left" class="left-slot"></slot>
        <div class="content-wrapper">
          <div class="icon-text-wrapper">
            ${this.icon
              ? html`<sh-icon
                  class="icon"
                  size="${this.iconSize}"
                  color="${this.iconColor}"
                  icon="${this.icon}"
                ></sh-icon>`
              : ''}

            <div class="text-wrapper">
              ${this.label
                ? html`<sh-text class="label" size="title-1" color="primary"
                    >${this.label}</sh-text
                  >`
                : ''}
              ${this.info
                ? html`<sh-text class="info" size="body-2" color="secondary">${this.info}</sh-text>`
                : ''}
              <slot class="slot-body"></slot>
            </div>

            <slot name="right" class="right"></slot>
            ${this.href && !this.hideLinkArrow
              ? html`<sh-icon class="arrow" button icon="arrow-right-s"></sh-icon>`
              : ''}
          </div>
          <slot name="bottom"></slot>
        </div>
        ${this.draggable
          ? html` <sh-icon class="draggable" icon="draggable-vertical-s" button></sh-icon>`
          : ''}
      </div>
      ${this.divider !== 'none' ? html` <div class="divider-wrapper"></div>` : ''}
    `;
  }
  firstUpdated() {
    this.addEventListener('click', () => {
      if (this.href && !this.disabled) {
        window.location.href = this.href;
      }
      this.handleClick();
    });
  }
  update(changedProperties) {
    if (changedProperties.has('selectable') && this.selectable) {
      this.toggle = true;
    }
    if (changedProperties.has('active')) {
      this.activeChangedEvent.emit(
        new CustomEvent('active-changed', {
          detail: this.active,
          bubbles: true,
          composed: true,
        })
      );
    }
    super.update(changedProperties);
  }
  handleClick() {
    var _a;
    if (this.toggle) {
      this.active = !this.active;
    }
    if (
      ((_a = this.closest('sh-list')) === null || _a === void 0 ? void 0 : _a.selectionType) ==
      'single'
    ) {
      this.handleSingleSelection();
    }
  }
  handleSelectClicked() {
    var _a;
    this.toggle = true;
    if (
      ((_a = this.closest('sh-list')) === null || _a === void 0 ? void 0 : _a.selectionType) ==
      'single'
    ) {
      this.handleSingleSelection();
    }
  }
  handleSingleSelection() {
    var _a;
    const listItems =
      (_a = this.closest('sh-list')) === null || _a === void 0
        ? void 0
        : _a.querySelectorAll('sh-list-item[active]');
    listItems === null || listItems === void 0
      ? void 0
      : listItems.forEach((element) => {
          element.active = false;
        });
  }
};
__decorate([event()], SHListItem.prototype, 'activeChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHListItem.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHListItem.prototype, 'info', void 0);
__decorate([property({ type: String, reflect: true })], SHListItem.prototype, 'icon', void 0);
__decorate([property({ type: String, reflect: true })], SHListItem.prototype, 'href', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHListItem.prototype,
  'selectable',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHListItem.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHListItem.prototype, 'toggle', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHListItem.prototype, 'disabled', void 0);
__decorate([property({ type: String, reflect: true })], SHListItem.prototype, 'divider', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHListItem.prototype,
  'iconColor',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-size' })],
  SHListItem.prototype,
  'iconSize',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'vertical-align' })],
  SHListItem.prototype,
  'verticalAlign',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHListItem.prototype, 'clickable', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'hide-link-arrow' })],
  SHListItem.prototype,
  'hideLinkArrow',
  void 0
);
SHListItem = __decorate([customElement('sh-list-item')], SHListItem);
export { SHListItem };
