import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { customElement, property } from 'lit/decorators.js';
import { ShuiLitElement } from '../../utils/event-decorator.js';
import listStyles from './sh-list.lit.scss.js';
import { html } from 'lit';
let SHList = class SHList extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** If set to `true`, the list will have no background color nor elevation applied to it. */
    this.flat = false;
    /** Defines the padding of the list items placed inside */
    this.integrated = false;
    /** Defines the selectable state of the list items. Possible values are `multiple` and `single`
     * @type {multiple|single}
     */
    this.selectionType = 'multiple';
  }
  static get styles() {
    return [listStyles];
  }
  render() {
    return html` <slot></slot> `;
  }
};
__decorate([property({ type: Boolean, reflect: true })], SHList.prototype, 'flat', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHList.prototype, 'integrated', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'selection-type' })],
  SHList.prototype,
  'selectionType',
  void 0
);
SHList = __decorate([customElement('sh-list')], SHList);
export { SHList };
