// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-tab-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-tab-item-text-line-height=1.5rem] - text line height
   */
  --sh-tab-item-text-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-height=3.5rem] - icon height
   */
  --sh-tab-item-icon-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-line-height=2rem] - icon line-height
   */
  --sh-tab-item-icon-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-icon-padding=0rem 1rem] - icon padding
   */
  --sh-tab-item-icon-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-padding=0rem 1rem] - icon label padding
   */
  --sh-tab-item-icon-label-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-padding-access-bar=0rem 1rem] - icon label padding in access bar
   */
  --sh-tab-item-icon-label-padding-access-bar: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-line-height=2rem] - icon label line-height
   */
  --sh-tab-item-icon-label-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-label-line-height=1rem] - label line-height
   */
  --sh-tab-item-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-tab-item-icon-label-height=3.5rem] - icon label height
   */
  --sh-tab-item-icon-label-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-height=4.5rem] - access-bar icon height
   */
  --sh-tab-item-access-bar-icon-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-padding=0rem 1rem] - access-bar icon padding
   */
  --sh-tab-item-access-bar-icon-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-line-height=2rem] - access-bar icon line height
   */
  --sh-tab-item-access-bar-icon-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-padding=0.25rem 1rem 0.125rem] - condensed icon padding
   */
  --sh-tab-item-condensed-icon-padding: 0.25rem 1rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-line-height=2rem] - condensed icon line height
   */
  --sh-tab-item-condensed-icon-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-height=2.5rem] - condensed icon height
   */
  --sh-tab-item-condensed-icon-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-height=4.5rem] - height of patient tab
   */
  --sh-tab-item-patient-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-height=2.5rem] - height of condensed tab
   */
  --sh-tab-item-condensed-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-normal-height=3.5rem] - height of tab
   */
  --sh-tab-item-normal-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-arrow-icons-padding=0 0.5rem] - padding in-between arrow icons
   */
  --sh-tab-item-arrow-icons-padding: 0 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-arrow-icons-line-height=2rem] - line-height of arrow icons
   */
  --sh-tab-item-arrow-icons-line-height: 2rem;
  /* 
   * @cssprop [--sh-tab-item-tab-item-padding=0rem 1rem] - padding of tab-item
   */
  --sh-tab-item-tab-item-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-tab-item-line-height=1.5rem] - line-height of tab-item
   */
  --sh-tab-item-tab-item-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-padding=0rem 1rem] - padding of tab-item access bar
   */
  --sh-tab-item-access-bar-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-height=4.5rem] - height of tab-item access bar
   */
  --sh-tab-item-access-bar-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-line-height=1.5rem] - line-height of tab-item access bar
   */
  --sh-tab-item-access-bar-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-padding=0.5rem 1rem 0.375rem] - padding of condensed tab-item
   */
  --sh-tab-item-condensed-padding: 0.5rem 1rem 0.375rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-line-height=1.5rem] - line-height of condensed tab-item
   */
  --sh-tab-item-condensed-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-badge-margin=-1.8125rem -0.625rem 0rem 0.375rem] - badge margin of tab-item
   */
  --sh-tab-item-badge-margin: -1.8125rem -0.625rem 0rem 0.375rem;
  /* 
   * @cssprop [--sh-tab-item-empty-badge-margin=-2rem -0.5rem 0rem 0.5rem] - empty badge margin of tab-item
   */
  --sh-tab-item-empty-badge-margin: -2rem -0.5rem 0rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-slottedfn-mg-right=2rem] - margin right of patient slotted function in tab-item
   */
  --sh-tab-item-slottedfn-mg-right: 2rem;
  /* 
   * @cssprop [--sh-tab-item-slottedfn-mg-left=-0.5rem] - margin left of patient slotted function in tab-item
   */
  --sh-tab-item-slottedfn-mg-left: -0.5rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-right=0.5rem] - position of close icon right in tab-item
   */
  --sh-tab-item-close-icon-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-top=0.5rem] - position of close icon top in tab-item
   */
  --sh-tab-item-close-icon-top: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-mg-right=0.5rem] - margin right of patient icon in tab-item
   */
  --sh-tab-item-patient-mg-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-mg-left=0rem] - margin left of patient icon in tab-item
   */
  --sh-tab-item-patient-mg-left: 0rem;
  /* 
   * @cssprop [--sh-tab-item-pt-label-lh=1rem] - line-height of patient label in tab-item
   */
  --sh-tab-item-pt-label-lh: 1rem;
  /* 
   * @cssprop [--sh-tab-item-pt-info-lh=1rem] - line-height of patient info in tab-item
   */
  --sh-tab-item-pt-info-lh: 1rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-accessbar-top=0.5rem] - close icon position top in accessbar
   */
  --sh-tab-item-close-icon-accessbar-top: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-close-icon-accessbar-right=0.5rem] - close icon position right in accessbar
   */
  --sh-tab-item-close-icon-accessbar-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-empty-badge-margin=-0.65rem -0.65rem 0.5rem 0.5rem] - empty badge margin for condensed tab
   */
  --sh-tab-item-condensed-empty-badge-margin: -0.65rem -0.65rem 0.5rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-badge-margin=-0.4rem -0.7rem 0.5rem 0.5rem] - badge margin for condensed tab
   */
  --sh-tab-item-condensed-badge-margin: -0.4rem -0.7rem 0.5rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-close-margin=0.8rem] - badge margin along with close icon
   */
  --sh-tab-item-tab-close-margin: 0.8rem;
  /* 
   * @cssprop [--sh-tab-item-tab-max-width=11.5rem] - max-width for a tab
   */
  --sh-tab-item-tab-max-width: 11.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-min-width=3.5rem] - min-width for a tab
   */
  --sh-tab-item-tab-min-width: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-l-max-width=23.5rem] - max-width of l size for a tab
   */
  --sh-tab-item-tab-l-max-width: 23.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-active-max-width=17.5rem] - max-width for patient tab
   */
  --sh-tab-item-patient-active-max-width: 17.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-min-width=11.5rem] - min-width for patient tab
   */
  --sh-tab-item-patient-min-width: 11.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-text-margin-right=1.5rem] - margin-right of text for patient tab
   */
  --sh-tab-item-patient-text-margin-right: 1.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-patient-collapsed-input-height=2.5rem] - collapsed patient input-height of condensed view
   */
  --sh-tab-item-condensed-patient-collapsed-input-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-patient-collapsed-input-padding=0rem 0.5rem] - collapsed patient padding of condensed view
   */
  --sh-tab-item-condensed-patient-collapsed-input-padding: 0rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-popover-max-height=12.25rem] - collapsed patient popover max-height of condensed view
   */
  --sh-tab-item-patient-popover-max-height: 12.25rem;
  /* 
   * @cssprop [--sh-tab-item-patient-collapsed-height=3.5rem] - collapsed patient popover item height
   */
  --sh-tab-item-patient-collapsed-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-popover-max-height=11.25rem] - collapsed popover max-height
   */
  --sh-tab-item-popover-max-height: 11.25rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-input-height=4.5rem] - collapsed input height
   */
  --sh-tab-item-collapsed-input-height: 4.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-collapsed-input-height=2.5rem] - condensed collapsed input height
   */
  --sh-tab-item-condensed-collapsed-input-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-text-lh=1rem] - text line-height
   */
  --sh-tab-item-text-lh: 1rem;
  /* 
   * @cssprop [--sh-tab-item-patient-close-icon-right=0.5rem] - position of close icon right in tab-item
   */
  --sh-tab-item-patient-close-icon-right: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-context-closable-padding-right=2rem] - padding-right of close icon of closable tab inside access-context
   */
  --sh-tab-item-access-context-closable-padding-right: 2rem;
  /* 
   * @cssprop [--sh-tab-item-expand-icon-font-size=2rem] - font size of expand-icon
   */
  --sh-tab-item-expand-icon-font-size: 2rem;
  /* 
   * @cssprop [--sh-tab-item-patient-padding=0rem 0.5rem] - padding of pattient tab
   */
  --sh-tab-item-patient-padding: 0rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-label-line-height=2.5rem] - line-height of collapsed tab
   */
  --sh-tab-item-collapsed-label-line-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-patient-href-wrapper-margin=1.25rem 0.5rem] - margin of patient href wrapper
   */
  --sh-tab-item-patient-href-wrapper-margin: 1.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-patient-href-wrapper-margin=0.75rem 0.5rem] - margin of patient in collapsed href wrapper
   */
  --sh-tab-item-collapsed-patient-href-wrapper-margin: 0.75rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-icon-href-wrapper-margin=0rem 1rem] - margin of patient href wrapper in access bar
   */
  --sh-tab-item-access-bar-icon-href-wrapper-margin: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-access-bar-icon-href-wrapper-margin=0.75rem 0rem] - margin of patient href wrapper in collapsed tab in access bar
   */
  --sh-tab-item-collapsed-access-bar-icon-href-wrapper-margin: 0.75rem 0rem;
  /* 
   * @cssprop [--sh-tab-item-href-wrapper-margin=0rem 1rem] - margin of href wrapper
   */
  --sh-tab-item-href-wrapper-margin: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-href-badge-margin-top=-0.6875rem] - margin top of href badge
   */
  --sh-tab-item-href-badge-margin-top: -0.6875rem;
  /* 
   * @cssprop [--sh-tab-item-href-empty-badge-margin-top=-0.5rem] - margin top of href empty badge
   */
  --sh-tab-item-href-empty-badge-margin-top: -0.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-href-padding=0rem 1rem] - padding of icon href
   */
  --sh-tab-item-icon-href-padding: 0rem 1rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-href-badge-margin-top=-0.3125rem] - margin top of href badge in access bar
   */
  --sh-tab-item-access-bar-href-badge-margin-top: -0.3125rem;
  /* 
   * @cssprop [--sh-tab-item-access-bar-href-default-badge-margin-top=0rem] - margin top of href default badge in access bar
   */
  --sh-tab-item-access-bar-href-default-badge-margin-top: 0rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-access-bar-href-default-badge-margin-top=-0.0625rem] - margin top of href default badge in condensed access bar
   */
  --sh-tab-item-condensed-access-bar-href-default-badge-margin-top: -0.0625rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-pt-href-wrapper-margin=0.25rem 0.5rem] - margin of condensed patient href wrapper 
   */
  --sh-tab-item-condensed-pt-href-wrapper-margin: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-condensed-pt-href-wrapper-margin=0.25rem 0.5rem] - margin of collapsed condensed patient href wrapper 
   */
  --sh-tab-item-collapsed-condensed-pt-href-wrapper-margin: 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-href-wrapper-margin=0.25rem 0rem 0.125rem] - margin of condensed icon href wrapper 
   */
  --sh-tab-item-condensed-icon-href-wrapper-margin: 0.25rem 0rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-href-condensed-height=2.5rem] - height of condensed href anchor tag
   */
  --sh-tab-item-href-condensed-height: 2.5rem;
  /* 
   * @cssprop [--sh-tab-item-icon-href-wrapper-margin=0rem 0.5rem] - margin of patient href wrapper
   */
  --sh-tab-item-icon-href-wrapper-margin: 0rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-href-badge-margin-top=-0.1875rem] - margin top of condensed badge
   */
  --sh-tab-item-condensed-href-badge-margin-top: -0.1875rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-href-empty-badge-margin-top=-0.0625rem] - margin top of condensed empty badge
   */
  --sh-tab-item-condensed-href-empty-badge-margin-top: -0.0625rem;
  /* 
   * @cssprop [--sh-tab-item-closable-condensed-href-badge-margin-top=-0.3125rem] - margin top of condensed closable badge
   */
  --sh-tab-item-closable-condensed-href-badge-margin-top: -0.3125rem;
  /* 
   * @cssprop [--sh-tab-item-closable-condensed-empty-href-badge-margin-top=-0.625rem] - margin top of closable condensed empty badge
   */
  --sh-tab-item-closable-condensed-empty-href-badge-margin-top: -0.625rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-collapsed-icon-href-wrapper-margin=0.25rem 0rem 0.125rem] - margin of condensed collapsed icon href wrapper 
   */
  --sh-tab-item-condensed-collapsed-icon-href-wrapper-margin: 0.25rem 0rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-href-accessbar-height=3.5rem] - collapsed href access bar height
   */
  --sh-tab-item-collapsed-href-accessbar-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-tab-item-icon-margin=0 0.5rem 0 0] - margin of tab item icon in collapsed view
   */
  --sh-tab-item-tab-item-icon-margin: 0 0.5rem 0 0;
  /* 
   * @cssprop [--sh-tab-item-condensed-collapsed-pt-icon-href-wrapper-margin-left=0.5rem] - margin of condensed collapsed patient icon href wrapper
   */
  --sh-tab-item-condensed-collapsed-pt-icon-href-wrapper-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-collapsed-icon-height=3.5rem] - collapsed icon height
   */
  --sh-tab-item-collapsed-icon-height: 3.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-pt-href-wrapper-margin=0.25rem 0.5rem 0.25rem 0.5rem] - margin of condensed icon href wrapper in patient tab
   */
  --sh-tab-item-condensed-icon-pt-href-wrapper-margin: 0.25rem 0.5rem 0.25rem 0.5rem;
  /* 
   * @cssprop [--sh-tab-item-condensed-icon-only-href-wrapper-margin=0.25rem 1rem 0.125rem] - margin of condensed only icon href wrapper 
   */
  --sh-tab-item-condensed-icon-only-href-wrapper-margin: 0.25rem 1rem 0.125rem;
  /* 
   * @cssprop [--sh-tab-item-min-width-tab=4rem] - min width of the tab item
   */
  --sh-tab-item-min-width-tab: 4rem;
}

:host {
  font: var(--title-1);
  color: var(--color-text-inactive);
  position: relative;
  overflow: hidden;
  outline: 0;
  max-width: var(--sh-tab-item-tab-max-width);
  min-width: var(--sh-tab-item-tab-min-width);
  height: var(--sh-tab-item-normal-height);
  box-sizing: border-box;
  padding: var(--sh-tab-item-tab-item-padding);
  line-height: var(--sh-tab-item-tab-item-line-height);
  border-bottom: 0.125rem solid transparent;
  transition: var(--transition-time) border ease-in-out;
  text-align: center;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  display: flex;
  align-items: center;
}

:host(:not([condensed]):not([collapsed])),
:host([patient][collapsed]:not([condensed])) {
  border-top: 0.125rem solid transparent;
}

:host(:not([label])) {
  min-width: var(--sh-tab-item-min-width-tab);
}

:host([disabled]) {
  background: transparent;
  color: var(--color-text-disabled);
  pointer-events: none;
}

.text {
  overflow: hidden;
  font-size: var(--title-1);
  line-height: var(--sh-tab-item-text-line-height);
}

:host([href]) .href-wrapper {
  margin: 1rem;
}

:host([patient][href]) .href-wrapper,
:host([href][badge]:not([icon])) .href-wrapper {
  display: flex;
}

:host([icon][href][access-context]:not([label]):not([condensed])) .href-wrapper {
  margin: var(--sh-tab-item-access-bar-icon-href-wrapper-margin);
}

:host([icon][href][access-context][collapsed]:not([label]):not([condensed])) .href-wrapper {
  margin: var(--sh-tab-item-collapsed-access-bar-icon-href-wrapper-margin);
}

:host([access-context]) {
  padding: var(--sh-tab-item-access-bar-padding);
  height: var(--sh-tab-item-access-bar-height);
  line-height: var(--sh-tab-item-access-bar-line-height);
}

:host([access-context][patient]) {
  height: var(--sh-tab-item-patient-height);
}

:host([condensed][access-context][patient]) {
  padding: var(--sh-tab-item-condensed-padding);
  height: var(--sh-tab-item-condensed-height);
  line-height: var(--sh-tab-item-condensed-line-height);
}

:host([access-context][patient][href][icon]) .href-wrapper,
:host([access-context][patient][href]) .href-wrapper {
  display: flex;
  align-items: center;
  margin: var(--sh-tab-item-patient-href-wrapper-margin);
  min-width: 0rem;
}

:host([icon][href][access-context][patient][collapsed]:not([condensed])) .href-wrapper,
:host([access-context][patient][href][collapsed]:not([condensed])) .href-wrapper {
  margin: var(--sh-tab-item-collapsed-patient-href-wrapper-margin);
}

:host([access-context][href]) .href-wrapper {
  margin: var(--sh-tab-item-href-wrapper-margin);
}

:host([condensed][href]) .href-wrapper {
  margin: 0.5rem 1rem;
}

:host([condensed][href][patient]) .href-wrapper {
  margin: var(--sh-tab-item-condensed-pt-href-wrapper-margin);
}

:host([condensed][href][patient][collapsed]) .href-wrapper {
  margin: var(--sh-tab-item-collapsed-condensed-pt-href-wrapper-margin);
}

:host([icon][access-context][href]:not([collapsed])) .href-wrapper,
:host([icon][href]:not([label]):not([condensed])) .href-wrapper {
  margin: var(--sh-tab-item-icon-href-wrapper-margin);
}

:host([icon][href]) .href-wrapper,
:host([icon][access-context][href][condensed]) .href-wrapper {
  margin: var(--sh-tab-item-condensed-icon-href-wrapper-margin);
}

:host([icon][href]:not([label])) .href-wrapper,
:host([icon][href][access-context]:not([label])[condensed]) .href-wrapper {
  margin: var(--sh-tab-item-condensed-icon-only-href-wrapper-margin);
}

:host([icon][access-context][href][condensed][patient]) .href-wrapper {
  margin: var(--sh-tab-item-condensed-icon-pt-href-wrapper-margin);
}

:host([access-context][patient][href]) a,
:host([access-context][href][collapsed]) a,
:host([access-context][href][collapsed]) .href-wrapper {
  display: flex;
  align-items: center;
}

:host([access-context][href][collapsed]) .href-wrapper {
  min-width: 0rem;
}

:host([icon][access-context][href][condensed][collapsed]) .href-wrapper {
  margin: var(--sh-tab-item-condensed-collapsed-icon-href-wrapper-margin);
}

:host([icon][access-context][href][condensed][collapsed][patient]) .href-wrapper {
  margin-left: var(--sh-tab-item-condensed-collapsed-pt-icon-href-wrapper-margin-left);
}

:host([icon][href][label]),
:host([icon][href][access-context]),
:host([icon]:not([label])[href]) {
  padding: var(--sh-tab-item-icon-padding);
}

:host([icon][condensed][href][label]),
:host([icon][condensed][href][access-context]),
:host([icon][condensed]:not([label])[href]) {
  padding: var(--sh-tab-item-condensed-icon-padding);
}

:host([icon][access-context][href][condensed]:not([collapsed])) {
  border-top: 0.125rem solid transparent;
  padding: var(--sh-tab-item-access-bar-icon-padding);
}

:host([icon][access-context][label][condensed][collapsed]:not([patient])),
:host([icon][access-context][label][collapsed]:not([patient])) {
  padding: 0.5rem;
}

:host a {
  color: var(--color-text-inactive);
  text-decoration: none;
  margin: -1rem;
  width: calc(100% + 2rem);
}

:host([active]) a {
  color: var(--color-text-active);
}

:host([access-context][href]) a {
  height: var(--sh-tab-item-access-bar-height);
  margin: -1.5rem -1rem;
}

:host([access-context][href]:not([condensed])) a {
  display: flex;
  align-items: center;
  justify-content: center;
}

:host([access-context][href][collapsed]) a {
  height: var(--sh-tab-item-collapsed-href-accessbar-height);
}

:host([access-context][href][condensed]) a {
  height: var(--sh-tab-item-href-condensed-height);
}

:host([access-context][patient][href]) a {
  margin: -1.5rem -0.5rem;
  width: calc(100% + 1rem);
}

:host([condensed][href]) a {
  margin: -0.5rem -1rem;
}

:host([condensed][patient]) a {
  margin: -0.25rem -0.5rem;
}

:host([icon][href]) a {
  margin: 0;
}

:host([icon][href][condensed]:not([access-context])) a {
  margin: auto;
}

:host([icon]) .label {
  line-height: var(--sh-tab-item-label-line-height);
}

.label,
.info,
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: var(--transition-time) all ease-in-out, var(--hover-transition-time) color;
}
.label::after,
.info::after,
.name::after {
  content: "";
  display: block;
}

:host([active]) #tab-item-icon {
  color: var(--tab-icon-color-active);
}

:host([disabled]) #tab-item-icon {
  color: var(--color-text-disabled);
}

:host([patient][disabled]) #close {
  display: none;
}

:host([icon]) #tab-item:not([collapsed]) {
  padding: 0.25rem 1rem 0.125rem 1rem;
  line-height: 1rem;
  font-size: 0.75rem;
}

:host([icon]:not([label])) {
  height: var(--sh-tab-item-icon-height);
  line-height: var(--sh-tab-item-icon-line-height);
  padding: var(--sh-tab-item-icon-padding);
}

:host([access-context][icon][href]) {
  height: var(--sh-tab-item-access-bar-height);
}

:host([access-context][icon][condensed][collapsed]),
:host([access-context][icon][href][condensed]) {
  height: var(--sh-tab-item-condensed-icon-height);
}

:host([access-context][icon][href][collapsed]:not([condensed])) {
  height: var(--sh-tab-item-collapsed-icon-height);
}

:host([icon][href]) {
  height: var(--sh-tab-item-icon-height);
}

:host([active]) {
  color: var(--color-text-active);
  border-bottom: 0.125rem solid rgba(var(--ui-2), var(--opacity-1));
  z-index: 1;
}

:host([active][neutral]:not([collapsed])),
:host([patient][active][neutral]:not([collapsed])) {
  color: var(--color-text-active);
  border-bottom: 0.125rem solid rgba(var(--ui-1), var(--opacity-950));
}

#tab-item-icon {
  color: var(--tab-icon-color-idle);
}

:host([patient]) #tab-item-icon {
  margin-right: var(--sh-tab-item-patient-mg-right);
  margin-left: var(--sh-tab-item-patient-mg-left);
}

/* functions slot */
:host([href]:not([active])) slot[name=functions]::slotted(*) {
  display: none;
}

:host([href][patient]) slot[name=functions]::slotted(*),
:host([patient]) > ::slotted(*[slot=functions]) {
  width: 2rem;
  margin-right: var(--sh-tab-item-slottedfn-mg-right);
  margin-left: var(--sh-tab-item-slottedfn-mg-left);
}

:host(:not([active])) > ::slotted(*[slot=functions]) {
  width: 0rem;
  display: none;
  min-width: unset;
  margin: 0rem;
  opacity: 0;
}

/* patient tab */
sh-icon#close {
  opacity: 0;
  position: absolute;
  top: 0rem;
  right: var(--sh-tab-item-close-icon-right);
}

:host([patient]) .text {
  margin-right: var(--sh-tab-item-patient-text-margin-right);
}

:host([patient][href]) .text {
  margin: auto var(--sh-tab-item-patient-text-margin-right) auto 0;
}

sh-icon[icon=patient] {
  margin-right: var(--sh-tab-item-patient-mg-right);
  margin-left: var(--sh-tab-item-patient-mg-left);
}

:host([patient]) sh-icon[icon=patient] {
  display: flex;
  color: var(--tab-icon-color-idle);
}

:host([patient][active]) sh-icon[icon=patient] {
  color: rgba(var(--ui-1), var(--opacity-950));
}

:host([patient]) {
  display: flex;
  padding: var(--sh-tab-item-patient-padding) !important;
  line-height: 1rem;
  text-align: left;
  align-items: center;
  width: fit-content;
  min-width: var(--sh-tab-item-patient-min-width);
  max-width: var(--sh-tab-item-patient-min-width);
}

:host([patient][active]:not([collapsed])) {
  max-width: var(--sh-tab-item-patient-active-max-width);
}

:host([patient]) .label {
  font: var(--title-1);
  line-height: var(--sh-tab-item-pt-label-lh);
}

:host([patient]) .info {
  display: block;
  font: var(--body-2);
  line-height: var(--sh-tab-item-pt-info-lh);
}

.info {
  color: var(--color-text-inactive);
}

:host([disabled]) .info {
  color: var(--color-text-disabled);
}

:host([disabled]) sh-icon[icon=patient] {
  color: var(--color-text-disabled);
}

:host([patient][condensed]) {
  padding: 0.25rem 0.5rem 0.125rem 0.5rem !important;
}

:host([icon][label]) {
  padding: var(--sh-tab-item-icon-label-padding);
  line-height: var(--sh-tab-item-icon-label-line-height);
  height: var(--sh-tab-item-icon-label-height);
  width: fit-content;
  display: flex;
}

:host([icon][label]:not([patient])) {
  flex-direction: column;
}

:host([icon][label]:not([condensed]):not([patient])) {
  justify-content: center;
}

:host([icon][label][access-context]:not([href])) {
  padding: var(--sh-tab-item-icon-label-padding-access-bar);
}

:host([icon][label][collapsed]) {
  width: 100%;
}

:host([icon][label]) #tab-item-icon {
  display: block;
}

:host([patient][active][size=l]),
:host([size=l]:not([patient])) {
  max-width: var(--sh-tab-item-tab-l-max-width);
}

/* access bar */
:host([icon][label][access-context]) #tab-item-icon,
:host([icon][label][footer-context]) #tab-item-icon {
  display: block;
}

:host([icon][label][access-context]:not([href])) {
  height: var(--sh-tab-item-patient-height);
}

:host([icon][access-context]:not([label]):not([href])) {
  height: var(--sh-tab-item-access-bar-icon-height);
  padding: var(--sh-tab-item-access-bar-icon-padding);
  line-height: var(--sh-tab-item-access-bar-icon-line-height);
}

:host([icon][access-context][collapsed]:not([label]):not([condensed])) {
  height: var(--sh-tab-item-normal-height);
}

:host([condensed]) {
  padding: var(--sh-tab-item-condensed-padding);
  height: var(--sh-tab-item-condensed-height);
  line-height: var(--sh-tab-item-condensed-line-height);
  display: flex;
}

:host([icon][label][condensed]) {
  line-height: var(condensed-icon-line-height);
}

:host([icon][condensed]),
:host([icon][condensed]:not([label]):not([href])),
:host([icon][label][condensed][access-context]:not([href])) {
  padding: var(--sh-tab-item-condensed-icon-padding);
  line-height: var(--sh-tab-item-condensed-icon-line-height);
  height: var(--sh-tab-item-condensed-icon-height);
  margin-top: 0rem;
}

/* collapsed */
:host([collapsed]) {
  height: var(--sh-tab-item-condensed-collapsed-input-height);
  padding: 0.5rem;
  width: 100%;
  max-width: none;
  text-align: left;
  font-weight: 500;
  border: 0rem transparent;
  display: flex;
}

:host([icon][label][access-context][collapsed]:not([href]):not([condensed])),
:host([patient][collapsed]) {
  height: var(--sh-tab-item-patient-collapsed-height);
}

:host([icon][collapsed]),
:host([patient][collapsed]:not([info])) {
  height: 3rem;
}

:host([patient][collapsed]:hover:not(.touch-device)) #close {
  right: var(--sh-tab-item-patient-close-icon-right);
  background: transparent;
}

:host([active][collapsed]) {
  background: rgba(var(--ui-1), var(--opacity-100));
}

:host([collapsed]:not([label])) .name {
  display: flex;
  font: var(--body-1);
}

:host([collapsed]:not([label])) {
  padding: 0.5rem !important;
}

:host([collapsed]:not([patient])) .name,
:host([collapsed]:not([patient])) .label {
  line-height: var(--sh-tab-item-collapsed-label-line-height);
}

:host([icon][collapsed]) .name,
:host([icon][collapsed]) .label {
  line-height: 2rem;
}

:host([icon][collapsed][patient]) .name,
:host([icon][collapsed][patient]) .label {
  line-height: var(--sh-tab-item-pt-label-lh);
}

:host([collapsed][icon]:not([patient])) {
  display: flex;
}

:host([collapsed]) #tab-item-icon {
  margin: var(--sh-tab-item-tab-item-icon-margin);
}

/* hover */
:host(:hover) .label {
  color: var(--color-text-strong);
}
:host(:hover) #tab-item-icon {
  color: rgba(var(--ui-1), var(--opacity-900));
}

:host([collapsed]:hover) {
  background-color: rgba(var(--ui-1), var(--opacity-50));
}

:host(:active) .text .info,
:host([active]) .text .info {
  color: var(--color-text-strong);
}
:host(:active) #tab-item-icon,
:host([active]) #tab-item-icon {
  color: rgba(var(--ui-1), var(--opacity-950));
}

:host([patient]:hover:not(.touch-device)) #close,
:host([patient][active].touch-device) #close,
:host([patient].touch-device) #close,
#close:focus {
  opacity: var(--tab-icon-opacity-active);
  top: var(--sh-tab-item-close-icon-top);
}

:host([patient]:not([condensed]):hover:not(.touch-device)) #close,
:host([patient][active]:not([condensed]).touch-device) #close,
:host([patient]:not([condensed]).touch-device) #close,
#close:focus {
  opacity: var(--tab-icon-opacity-active);
  top: calc(var(--sh-tab-item-close-icon-top) - 0.125rem);
}

#close.item-focused {
  outline: var(--focus-outline);
}

:host([patient]:hover:not(.touch-device):not([active])) sh-icon[icon=patient],
:host([patient].touch-device):hover sh-icon[icon=patient] {
  color: var(--color-text-strong);
}

:host([icon][href]:not([patient])) {
  display: flex;
}

:host([icon][label]:not([patient]):not([collapsed])) #tab-item-icon {
  margin: 0 auto;
}

:host([icon][access-context]) sh-badge {
  position: absolute;
  right: 0.375rem;
  top: 0.375rem;
  margin: 0rem;
}

:host([icon][access-context][badge=""]) sh-badge {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  margin: 0rem;
}

:host([icon][badge=""]) sh-badge,
:host([icon]) sh-badge {
  position: absolute;
  right: 0.25rem;
  top: 0.125rem;
  margin: 0rem;
}

sh-icon:not(#close) {
  transition: 0s color;
}

:host([access-context][badge][collapsed][href]:not([closable]):not([condensed])) sh-badge,
:host([access-context]) sh-badge {
  margin-top: -1.125rem;
}

:host([access-context][badge=""][href][collapsed]:not([closable]):not([condensed])) sh-badge,
:host([access-context][badge=""]) sh-badge {
  margin-top: -1rem;
}

:host(:not([patient]):not([icon])) {
  overflow: unset;
}

sh-badge {
  margin: var(--sh-tab-item-badge-margin);
  transition: var(--transition-time) all ease-in-out;
}

:host([badge][href]:not([closable])) sh-badge {
  margin-top: var(--sh-tab-item-href-badge-margin-top);
}

:host([badge][href][label][icon]:not([closable])) sh-badge {
  margin-top: 0px;
}

:host([badge=""][href]:not([closable])) sh-badge {
  margin-top: var(--sh-tab-item-href-empty-badge-margin-top);
}

:host([badge][href][access-context]:not([closable]):not([condensed])) sh-badge {
  margin-top: var(--sh-tab-item-access-bar-href-badge-margin-top);
}

:host([badge=""][href][access-context]:not([closable]):not([collapsed]):not([condensed])) sh-badge {
  margin-top: var(--sh-tab-item-access-bar-href-default-badge-margin-top);
}

:host([badge=""][href][access-context][condensed]) sh-badge {
  margin-top: var(--sh-tab-item-condensed-access-bar-href-default-badge-margin-top);
}

:host([condensed][badge][href]) sh-badge {
  margin-top: var(--sh-tab-item-condensed-href-badge-margin-top);
}

:host([condensed][badge=""][href]:not([closable]):not([collapsed]):not([access-context])) sh-badge {
  margin-top: var(--sh-tab-item-condensed-href-empty-badge-margin-top);
}

:host([condensed][badge][href][closable]) sh-badge {
  margin-top: var(--sh-tab-item-closable-condensed-href-badge-margin-top);
}

:host([condensed][badge=""][href][closable]) sh-badge {
  margin-top: var(--sh-tab-item-closable-condensed-empty-href-badge-margin-top);
}

:host([href][closable]) .href-wrapper {
  display: flex;
  align-items: center;
}

:host([badge=""]) sh-badge {
  margin: var(--sh-tab-item-empty-badge-margin);
}

:host([badge=""]) sh-badge + .tab-close {
  margin-left: var(--sh-tab-item-tab-close-margin);
}

:host([condensed]) sh-badge {
  margin: var(--sh-tab-item-condensed-badge-margin);
}

:host([badge=""][href][access-context][condensed][collapsed]:not([closable])) sh-badge,
:host([condensed][badge=""]) sh-badge {
  margin: var(--sh-tab-item-condensed-empty-badge-margin);
}

:host([condensed][badge=""]) sh-badge + .tab-close {
  margin-left: 0.75rem;
}

:host([active].item-focused) {
  box-shadow: var(--focus-color) 0px 0.125rem 0px 0px inset, var(--focus-color) 0.125rem 0px 0px 0px inset, var(--focus-color) -0.125rem 0px 0px 0px inset;
  outline: none;
}

:host([active][collapsed].item-focused) {
  outline: var(--focus-outline);
}

:host(.item-focused) {
  box-shadow: none;
  outline: var(--focus-outline);
  outline-offset: -0.125rem;
}

.tab-close,
:host([access-context][closable][disabled][condensed]) sh-badge {
  margin-left: 0.5rem;
}

:host([closable]:not([disabled])) {
  padding-right: 0.5rem;
}

:host([access-context][closable]:not([disabled])) {
  padding-right: var(--sh-tab-item-access-context-closable-padding-right);
}

:host([access-context][closable]:not([disabled])) .tab-close {
  margin: 0rem;
  position: absolute;
  top: calc(var(--sh-tab-item-close-icon-accessbar-top) - 0.125rem);
  right: var(--sh-tab-item-close-icon-accessbar-right);
}

:host([access-context][closable][condensed]:not([disabled])) .tab-close,
:host([patient][closable][condensed]:not([disabled])) .tab-close,
:host([closable][collapsed]:not([disabled])) .tab-close {
  top: var(--sh-tab-item-close-icon-accessbar-top);
}

:host([access-context][closable]:not([disabled])) .label {
  margin-right: 0.5rem;
}

:host([access-context][closable][condensed]) sh-badge {
  margin: -0.125rem 0 0 0;
}

:host([access-context][closable]) sh-badge {
  margin: -1.125rem 0 0 0;
}

:host([access-context]:not([condensed])) {
  --sh-tab-item-normal-height: var(--sh-access-bar-height);
  --sh-tab-item-access-bar-height: var(--sh-access-bar-height);
  --sh-tab-item-patient-height: var(--sh-access-bar-height);
  --sh-tab-item-icon-height: var(--sh-access-bar-height);
  --sh-tab-item-icon-label-height: var(--sh-access-bar-height);
  --sh-tab-item-access-bar-icon-height: var(--sh-access-bar-height);
}`;