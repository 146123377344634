// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-tool.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-tool-padding=0.188rem] - padding of tool
   */
  --sh-tool-padding: 0.188rem;
  /* 
   * @cssprop [--sh-tool-bottom-padding-with-icon=0.25rem] - padding-bottom of the tool when icon is present
   */
  --sh-tool-bottom-padding-with-icon: 0.25rem;
  /* 
   * @cssprop [--sh-tool-top-padding-with-icon=0.375rem] - padding-top of the tool when icon is present
   */
  --sh-tool-top-padding-with-icon: 0.375rem;
  /* 
   * @cssprop [--sh-tool-right-left-padding-with-icon=0.188rem] - padding-right and padding-left of the tool when icon is present
   */
  --sh-tool-right-left-padding-with-icon: 0.188rem;
  /* 
   * @cssprop [--sh-tool-height=4.25rem] - height of tool
   */
  --sh-tool-height: 4.25rem;
  /* 
   * @cssprop [--sh-tool-width=var(--sh-tool-height)] - width of tool
   */
  --sh-tool-width: var(--sh-tool-height);
  /* 
   * @cssprop [--sh-tool-condensed-padding=0.125rem] - padding of the condensed tool
   */
  --sh-tool-condensed-padding: 0.125rem;
  /* 
   * @cssprop [--sh-tool-condensed-height=2.5rem] - height of the condensed tool
   */
  --sh-tool-condensed-height: 2.5rem;
  /* 
   * @cssprop [--sh-tool-condensed-width=var(--sh-tool-condensed-height)] - width of the condesed tool
   */
  --sh-tool-condensed-width: var(--sh-tool-condensed-height);
  /* 
   * @cssprop [--sh-tool-icon-value-label-font-size=0.75rem] - label font-size for select value and palette value of tool
   */
  --sh-tool-icon-value-label-font-size: 0.75rem;
  /* 
   * @cssprop [--sh-tool-icon-value-label-line-height=var(--sh-tool-icon-value-label-font-size)] - label line-height for select value and palette value of tool
   */
  --sh-tool-icon-value-label-line-height: var(--sh-tool-icon-value-label-font-size);
  /* 
   * @cssprop [--sh-tool-value-label-line-height=0.75rem] - label line-height for select and palette with value and without icon
   */
  --sh-tool-value-label-line-height: 0.75rem;
  /* 
   * @cssprop [--sh-tool-label-line-height=1rem] - line-height of label
   */
  --sh-tool-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-tool-icon-label-margin-top=0.25rem] - margin-top of label with icon property
   */
  --sh-tool-icon-label-margin-top: 0.25rem;
  /* 
   * @cssprop [--sh-tool-palette-height=1.25rem] - height of the palette
   */
  --sh-tool-palette-height: 1.25rem;
  /* 
   * @cssprop [--sh-tool-palette-width=var(--sh-tool-palette-height)] - width of the palette
   */
  --sh-tool-palette-width: var(--sh-tool-palette-height);
  /* 
   * @cssprop [--sh-tool-arrow-height=0.375rem] - height of the arrow in palette
   */
  --sh-tool-arrow-height: 0.375rem;
  /* 
   * @cssprop [--sh-tool-arrow-width=var(--sh-tool-arrow-height)] - width of the arrow in palette
   */
  --sh-tool-arrow-width: var(--sh-tool-arrow-height);
  /* 
   * @cssprop [--sh-tool-arrow-border=0.188rem] - border of the arrow in palette
   */
  --sh-tool-arrow-border: 0.188rem;
  /* 
   * @cssprop [--sh-tool-popover-max-height=12.25rem] - max-height of the popover
   */
  --sh-tool-popover-max-height: 12.25rem;
  /* 
   * @cssprop [--sh-tool-condensed-palette-height=0.75rem] - height of condensed palette
   */
  --sh-tool-condensed-palette-height: 0.75rem;
  /* 
   * @cssprop [--sh-tool-condensed-palette-width=var(--sh-tool-condensed-palette-height)] - width of condensed palette
   */
  --sh-tool-condensed-palette-width: var(--sh-tool-condensed-palette-height);
  /* 
   * @cssprop [--sh-tool-bg-size=contain] - background size
   */
  --sh-tool-bg-size: contain;
  /* 
   * @cssprop [--sh-tool-bg-position=center center] - background position
   */
  --sh-tool-bg-position: center center;
  /* 
   * @cssprop [--sh-tool-bg-repeat=no-repeat] - background repeat
   */
  --sh-tool-bg-repeat: no-repeat;
  /* 
   * @cssprop [--sh-tool-border-radius=var(--sh-radius-small-container)] - border-radius of the tool
   */
  --sh-tool-border-radius: var(--sh-radius-small-container);
  /* 
   * @cssprop [--sh-tool-arrow-top-right=0.25rem] - top and right position of arrow
   */
  --sh-tool-arrow-top-right: 0.25rem;
  /* 
   * @cssprop [--sh-tool-label-value-padding=0rem] - padding between label and value
   */
  --sh-tool-label-value-padding: 0rem;
  /* 
   * @cssprop [--sh-tool-condensed-label-line-height=1rem] - line-height of label in condensed tool
   */
  --sh-tool-condensed-label-line-height: 1rem;
  /* 
   * @cssprop [--sh-tool-wrapper-min-height=1rem] - minimum height of label and value wrapper
   */
  --sh-tool-wrapper-min-height: 1rem;
  /* 
   * @cssprop [--sh-tool-border=0.0625rem] - border of the tool
   */
  --sh-tool-border: 0.0625rem;
  /* 
   * @cssprop [--sh-tool-condensed-icon-size=2rem] - size of icon in condensed tool
   */
  --sh-tool-condensed-icon-size: 2rem;
}

:host {
  --sh-popover-border-radius: var(--sh-radius-small-container);
  padding: var(--sh-tool-padding);
  border: var(--sh-tool-border) solid transparent;
  background: rgba(var(--ui-1), var(--opacity-50));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--sh-tool-border-radius);
  cursor: pointer;
  transition: 0.2s all ease-in-out, 0.1s background;
  height: var(--sh-tool-height);
  min-height: var(--sh-tool-height);
  max-height: var(--sh-tool-height);
  flex: 1;
  position: relative;
  outline: 0rem;
}

:host([active]) {
  background: var(--tint-150);
  border-color: var(--tint-150);
}

:host([icon][label]:not([condensed]):not([label=""])) {
  padding-bottom: calc(var(--sh-tool-bottom-padding-with-icon) - var(--sh-tool-border));
  padding-top: calc(var(--sh-tool-top-padding-with-icon) - var(--sh-tool-border));
  padding-right: calc(var(--sh-tool-right-left-padding-with-icon) - var(--sh-tool-border));
  padding-left: calc(var(--sh-tool-right-left-padding-with-icon) - var(--sh-tool-border));
}

:host(:not([active]):not([disabled]):not(.touch-device):hover) {
  background: rgba(var(--ui-1), var(--opacity-100));
}

:host(:not([active]):not([disabled]):not(.touch-device):active) {
  background: rgba(var(--ui-1), var(--opacity-150));
}

:host([active]:not(.touch-device):hover) {
  background: var(--tint-200);
  border-color: var(--tint-200);
}

:host([active]:active) {
  background: var(--tint-250);
  border-color: var(--tint-250);
}

:host(:not([stretch])) {
  width: var(--sh-tool-width);
  min-width: var(--sh-tool-width);
  max-width: var(--sh-tool-width);
  flex: 0;
}

:host([select][value]) .tool-label,
:host([palette][value]) .tool-label {
  font-size: var(--sh-tool-icon-value-label-font-size);
  line-height: var(--sh-tool-value-label-line-height);
}

:host([icononly][icon]) .tool-label,
:host([condensed][icon]) .tool-label {
  display: none;
}

:host([icon]) .tool-value {
  font: var(--body-2);
}

.tool-label,
.tool-value {
  height: auto;
  text-align: center;
  color: var(--text-primary);
  line-height: var(--sh-tool-label-line-height);
  overflow: visible;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow-wrap: normal;
}

:host([icon]) .tool-label {
  height: fit-content;
  line-height: var(--sh-tool-icon-value-label-line-height);
  font-size: var(--sh-tool-icon-value-label-font-size);
}

.palette {
  box-sizing: border-box;
  height: var(--sh-tool-palette-height);
  width: var(--sh-tool-palette-width);
  position: absolute;
  top: -0.0625rem;
  right: -0.0625rem;
}

.arrow {
  box-sizing: border-box;
  height: var(--sh-tool-arrow-height);
  width: var(--sh-tool-arrow-width);
  border-top: solid var(--sh-tool-arrow-border) rgba(var(--ui-1), var(--opacity-4));
  border-right: solid var(--sh-tool-arrow-border) rgba(var(--ui-1), var(--opacity-4));
  border-left: solid var(--sh-tool-arrow-border) transparent;
  border-bottom: solid var(--sh-tool-arrow-border) transparent;
  position: absolute;
  top: calc(var(--sh-tool-arrow-top-right) - var(--sh-tool-border));
  right: calc(var(--sh-tool-arrow-top-right) - var(--sh-tool-border));
}

:host([active][inverted-active]) .arrow {
  border-top: solid var(--sh-tool-arrow-border) rgba(var(--ui-7), var(--opacity-400));
  border-right: solid var(--sh-tool-arrow-border) rgba(var(--ui-7), var(--opacity-400));
}

:host([readonly]) .arrow {
  opacity: 0;
}

:host(:not([palette]):not([select])) sh-popover {
  transition: none;
}

/* flat styles */
:host([flat]) {
  border-style: none;
  background: transparent;
  box-shadow: none;
}

:host([active][flat]) {
  border: 0.0625rem solid rgba(var(--ui-1), var(--opacity-4));
  background: rgba(var(--ui-1), var(--opacity-5));
}

:host(:not([active]):not([disabled]):not(.touch-device):hover[flat]) {
  background: rgba(var(--ui-1), var(--opacity-50));
}

:host(:not([active]):not([disabled]):not(.touch-device):active[flat]) {
  background: rgba(var(--ui-1), var(--opacity-100));
}

:host([flat][active]) {
  background: var(--tint-150);
  border-color: var(--tint-150);
}

:host([flat][active]:not(.touch-device):hover) {
  background: var(--tint-200);
  border-color: var(--tint-200);
}

:host([flat][active]:active) {
  background: var(--tint-250);
  border-color: var(--tint-250);
}

:host([flat][inverted-active][active]) {
  background: rgba(var(--ui-1), var(--opacity-800));
  border-color: rgba(var(--ui-1), var(--opacity-800));
}

:host([flat][inverted-active][active]:not(.touch-device):hover) {
  background: rgba(var(--ui-1), var(--opacity-900));
  border-color: rgba(var(--ui-1), var(--opacity-900));
}

:host([flat][inverted-active][active]:active) {
  background: rgba(var(--ui-1), var(--opacity-700));
  border-color: rgba(var(--ui-1), var(--opacity-700));
}

/* condensed styles */
:host([condensed]) {
  padding: var(--sh-tool-condensed-padding);
  height: var(--sh-tool-condensed-height);
  min-height: var(--sh-tool-condensed-height);
  max-height: var(--sh-tool-condensed-height);
}

:host([condensed]:not([stretch])) {
  min-width: var(--sh-tool-condensed-width);
  max-width: var(--sh-tool-condensed-width);
}

:host([condensed]) .palette {
  height: var(--sh-tool-condensed-palette-height);
  width: var(--sh-tool-condensed-palette-width);
}

/* disabled styles */
:host([disabled]) {
  cursor: default;
  pointer-events: none;
  opacity: var(--opacity-300);
}

:host([disabled]) .arrow {
  opacity: 0.2;
}

sh-popover:not([mobile]) .popover-slot-wrapper {
  max-height: calc(var(--sh-tool-popover-max-height) - 2 * var(--sh-popover-headerless-body-top-padding));
}

.popover-slot-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--sh-popover-headerless-body-top-padding);
  margin: calc(-1 * var(--sh-popover-headerless-body-top-padding));
}

:host([inverted-active][active]) {
  background: rgba(var(--ui-1), var(--opacity-800));
  border-color: rgba(var(--ui-1), var(--opacity-800));
}

:host([inverted-active][active]:hover) {
  background: rgba(var(--ui-1), var(--opacity-900));
  border-color: rgba(var(--ui-1), var(--opacity-900));
}

:host([inverted-active][active]:active) {
  background: rgba(var(--ui-1), var(--opacity-700));
  border-color: rgba(var(--ui-1), var(--opacity-700));
}

:host([inverted-active][active][disabled]) {
  background: rgba(var(--ui-1), var(--opacity-800));
  border-color: rgba(var(--ui-1), var(--opacity-800));
}

:host([label]) .tool-label {
  color: var(--color-text-moderate);
}

:host([label][active]) .tool-label {
  color: var(--color-text-active);
}

:host([label][active][inverted-active]) .tool-label {
  color: var(--color-text-invert-active);
}

:host([label][icon]) .tool-label {
  color: var(--color-text-subtle);
}

:host([label][icon]) .tool-icon {
  color: rgba(var(--ui-1), var(--opacity-700));
}

:host([label][icon][active]) .tool-label {
  color: var(--color-text-active);
}

:host([label][icon][active]) .tool-icon {
  color: rgba(var(--ui-1), var(--opacity-950));
}

:host([label][icon][active][inverted-active]) .tool-label {
  color: var(--color-text-invert-active);
}

:host([label][icon][active][inverted-active]) .tool-icon {
  color: rgba(var(--ui-7), var(--opacity-950));
}

:host([icon]) .tool-icon,
:host([icononly][icon]) .tool-icon {
  color: rgba(var(--ui-1), var(--opacity-700));
}

:host([icon][active]) .tool-icon,
:host([icononly][icon][active]) .tool-icon {
  color: rgba(var(--ui-1), var(--opacity-950));
}

:host([icon][active][inverted-active]) .tool-icon,
:host([icononly][icon][active][inverted-active]) .tool-icon {
  color: rgba(var(--ui-7), var(--opacity-950));
}

:host([condensed][label]:not([icon])) .tool-label {
  color: var(--color-text-moderate);
}

:host([condensed][label]:not([icon])[active]) .tool-label {
  color: var(--color-text-active);
}

:host([condensed][label]:not([icon])[active][inverted-active]) .tool-label {
  color: var(--color-text-invert-active);
}

:host([select][label]) .tool-label {
  color: var(--color-text-subtle);
}

:host([select][label][inverted-active][active]) .tool-label {
  color: var(--color-text-invert-subtle);
}

:host([select][value]) .tool-value {
  color: var(--color-text-strong);
}

:host([select][value][active]) .tool-value {
  color: var(--color-text-active);
}

:host([select][value][inverted-active][active]) .tool-value {
  color: var(--color-text-invert-active);
}

:host([icon][label]:not([condensed]):not([label=""])) .label-wrapper {
  flex-grow: 1;
  flex-shrink: 0;
}

:host([condensed][label]) .tool-label {
  line-height: var(--sh-tool-condensed-label-line-height);
}

:host([value][label][select]) .label-wrapper,
:host([value][label][select]) .value-wrapper {
  min-height: var(--sh-tool-wrapper-min-height);
}

:host([select][label][value]) .label-wrapper {
  margin-bottom: var(--sh-tool-label-value-padding);
}

.label-wrapper,
.value-wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

:host([inverted-active][active][disabled]) .tool-label,
:host([inverted-active][active][disabled]) .tool-value,
:host([inverted-active][active][disabled]) .tool-icon {
  color: rgba(var(--ui-7), var(--opacity-1000));
}

:host([label][icon][disabled]) .tool-label,
:host([value][icon][disabled]) .tool-value,
:host([label][icon][disabled]) .tool-icon,
:host([label][disabled]) .tool-label,
:host([icon][disabled]) .tool-icon,
:host([value][disabled]) .tool-value {
  color: var(--color-text-solid);
}

.tool-label {
  word-break: var(--sh-tool-label-word-break, normal);
}

.tool-value {
  word-break: var(--sh-tool-value-word-break, normal);
}

:host([icon][condensed]) sh-icon {
  --sh-icon-font-size-m: var(--sh-tool-condensed-icon-size);
}

:host([label][value]) .value-wrapper,
:host([label][value]) .label-wrapper,
:host([label]:not([icon]):not([value])) .label-wrapper {
  padding-bottom: 1px;
  padding-top: 1px;
}`;