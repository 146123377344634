// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import ImageTextStyles from './sh-image-text.lit.scss.js';
/**
 * @slot -unnamed slot takes plain text as a child element.
 */
let SHImageText = class SHImageText extends LitElement {
  static get styles() {
    return [ImageTextStyles];
  }
  render() {
    return html`<slot></slot> `;
  }
};
__decorate([property({ type: String, reflect: true })], SHImageText.prototype, 'alignment', void 0);
SHImageText = __decorate([customElement('sh-image-text')], SHImageText);
export { SHImageText };
