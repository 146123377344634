// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

export const KEYCODE = {
  TAB: { keycode: 9, code: 'Tab' },
  ENTER: { keycode: 13, code: 'Enter' },
  SPACE: { keycode: 32, code: 'Space' },
  ESCAPE: { keycode: 27, code: 'Escape' },
  ARROW_LEFT: { keycode: 37, code: 'ArrowLeft' },
  ARROW_UP: { keycode: 38, code: 'ArrowUp' },
  ARROW_RIGHT: { keycode: 39, code: 'ArrowRight' },
  ARROW_DOWN: { keycode: 40, code: 'ArrowDown' },
  NUM_0: { keycode: 48, code: 'Digit0' },
  NUM_1: { keycode: 49, code: 'Digit1' },
  NUM_9: { keycode: 57, code: 'Digit9' },
  NUMPAD_0: { keycode: 96, code: 'Numpad0' },
  NUMPAD_1: { keycode: 97, code: 'Numpad1' },
  NUMPAD_9: { keycode: 105, code: 'Numpad9' },
  V: { keycode: 86, code: 'KeyV' },
  C: { keycode: 67, code: 'KeyC' },
  A: { keycode: 65, code: 'KeyA' },
  X: { keycode: 88, code: 'KeyX' },
  Y: { keycode: 89, code: 'KeyY' },
  Z: { keycode: 90, code: 'KeyZ' },
  NUMPAD_ENTER: { code: 'NumpadEnter' },
};
