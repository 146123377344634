// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { keyboardInteraction } from './keyboardInteraction';
import { KEYCODE } from './keycode';
/** ### RefrainSpaceScrollMixin
 * ___
 * Superclass aimed to not scroll the parent when `space`
 * key is used to perform some action on the component.
 * ___
 * #### How to use this mixin ?
 *
 * Import like this in the component:
 * ```js
 * import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin.js';
 * ```
 * Then inherit this mixin like this:
 * ```js
 * export class SHComponent extends RefrainSpaceScrollMixin(LitElement)
 * ```
 * ___
 *
 * #### How does this mixin work ?
 *
 * It will add a `keydown` event listener to the component
 * and if space will prevent the scroll from happening.
 *
 * ___
 * Note:
 * Please check if the component already has a functionality
 * added on keydown. Make sure all the key functionalities work as usual.
 * ___
 *
 */
export const RefrainSpaceScrollMixin = (superClass) => {
  // important read: https://lit.dev/docs/composition/mixins/#mixins-in-typescript
  class RefrainSpaceScrollMixinElement extends superClass {
    connectedCallback() {
      super.connectedCallback();
      this._preventScroll = this._preventSpaceScroll.bind(this);
      this.addEventListener('keydown', this._preventScroll);
    }
    _preventSpaceScroll(e) {
      const element = e.composedPath()[0];
      if (
        keyboardInteraction(e, KEYCODE.SPACE) &&
        element.tagName !== 'INPUT' &&
        element.tagName !== 'TEXTAREA'
      ) {
        e.preventDefault();
      }
    }
    disconnectedCallback() {
      this.removeEventListener('keydown', this._preventScroll);
      super.disconnectedCallback();
    }
  }
  /**
   * if you want to understand the type coersion here
   * read this: https://lit.dev/docs/composition/mixins/#mixins-in-typescript
   */
  return RefrainSpaceScrollMixinElement;
};
