// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { convertToRem } from '../../utils/pixel-to-rem-convertor.js';
import { convertToPixel } from '../../utils/rem-to-pixel-convertor.js';
class Point {
    constructor(point, rotation = 0) {
        this.x = point[0];
        this.y = point[1];
        this.rotation = rotation;
    }
}
// returns position of arrow wrt popover
export class ArrowPosition {
    constructor(targetDimensions, popoverDimensions, popoverScale) {
        this.targetDimensions = targetDimensions;
        this.popoverDimensions = popoverDimensions;
        this.arrowPositionX = 0;
        this.arrowPositionY = 0;
        this.arrowRotation = 0;
        // the starting reference point of arrow in x direction- give this as left value in rem to see the position.
        this.arrowX0 = popoverScale === 's' || popoverScale === 'm' ? -0.375 : -0.5625;
        // the starting reference point of arrow in y direction- give this as top value in rem to see the position.
        this.arrowY0 = popoverScale === 's' || popoverScale === 'm' ? -0.5 : -0.75;
        this.arrowRotation0 = -270;
        // correcting the target so as to accurately track the icon of the
        // workline type information.
        // the half-width of the upright triangle from one end to its midpoint in the base
        this.arrowBaseHalfWidth = convertToPixel(-this.arrowX0);
        // the distance between the triangle and the popover edge.
        this.arrowPopoverEdgeBoundaryDistance = convertToPixel(-this.arrowY0);
        this.rangeCorrectedPopoverLeft =
            this.popoverDimensions.left +
                (this.arrowBaseHalfWidth + this.arrowPopoverEdgeBoundaryDistance);
        this.rangeCorrectedPopoverRight =
            this.popoverDimensions.right -
                (this.arrowBaseHalfWidth + this.arrowPopoverEdgeBoundaryDistance);
        this.rangeCorrectedPopoverTop =
            this.popoverDimensions.top +
                (this.arrowBaseHalfWidth + this.arrowPopoverEdgeBoundaryDistance);
        this.rangeCorrectedPopoverBottom =
            this.popoverDimensions.bottom -
                (this.arrowBaseHalfWidth + this.arrowPopoverEdgeBoundaryDistance);
        this.yIntersection = this.getIntersectionExtremes(this.targetDimensions.left, this.targetDimensions.right, this.popoverDimensions.left, this.popoverDimensions.right);
        this.xIntersection = this.getIntersectionExtremes(this.targetDimensions.top, this.targetDimensions.bottom, this.popoverDimensions.top, this.popoverDimensions.bottom);
        this.setArrowPosition();
    }
    setArrowPosition() {
        if (this.yIntersection.length > 0) {
            // means there are common points in the x-axis
            this.setParametersForArrowForCommonXAxisPointsBetweenTargetAndPopover();
        }
        else if (this.xIntersection.length > 0) {
            // means there are common points in the y-axis
            this.setParametersForArrowForCommonYAxisPointsBetweenTargetAndPopover();
        }
        else if (this.xIntersection.length === 0 && this.yIntersection.length === 0) {
            // means there are no common points in x-axis and y-axis
            this.setParametersForArrowForNonIntersectingPositionOfPopoverWithTarget();
        }
    }
    setParametersForArrowForNonIntersectingPositionOfPopoverWithTarget() {
        let specialPoint;
        if (this.targetDimensions.left > this.popoverDimensions.right) {
            if (this.targetDimensions.top > this.popoverDimensions.bottom) {
                specialPoint = this.getSpecialPoint('right-down');
                this.setArrowParameters(specialPoint.rotation, specialPoint.x, specialPoint.y);
            }
            else if (this.targetDimensions.bottom < this.popoverDimensions.top) {
                specialPoint = this.getSpecialPoint('right-up');
                this.setArrowParameters(specialPoint.rotation, specialPoint.x, specialPoint.y);
            }
        }
        else if (this.targetDimensions.right < this.popoverDimensions.left) {
            if (this.targetDimensions.top > this.popoverDimensions.bottom) {
                specialPoint = this.getSpecialPoint('left-down');
                this.setArrowParameters(specialPoint.rotation, specialPoint.x, specialPoint.y);
            }
            else if (this.targetDimensions.bottom < this.popoverDimensions.top) {
                specialPoint = this.getSpecialPoint('left-up');
                this.setArrowParameters(specialPoint.rotation, specialPoint.x, specialPoint.y);
            }
        }
    }
    setParametersForArrowForCommonYAxisPointsBetweenTargetAndPopover() {
        let yMid = (this.xIntersection[0] + this.xIntersection[this.xIntersection.length - 1]) / 2;
        yMid =
            yMid > this.rangeCorrectedPopoverBottom
                ? this.rangeCorrectedPopoverBottom
                : yMid < this.rangeCorrectedPopoverTop
                    ? this.rangeCorrectedPopoverTop
                    : yMid;
        let x, rotation;
        if (this.targetDimensions.left < this.popoverDimensions.left) {
            x = this.popoverDimensions.left;
            rotation = 180;
            this.setArrowParameters(rotation, x, yMid);
        }
        else if (this.targetDimensions.right > this.popoverDimensions.right) {
            x = this.popoverDimensions.right;
            rotation = 0;
            this.setArrowParameters(rotation, x, yMid);
        }
    }
    setParametersForArrowForCommonXAxisPointsBetweenTargetAndPopover() {
        let xMid = (this.yIntersection[0] + this.yIntersection[this.yIntersection.length - 1]) / 2;
        xMid =
            xMid > this.rangeCorrectedPopoverRight
                ? this.rangeCorrectedPopoverRight
                : xMid < this.rangeCorrectedPopoverLeft
                    ? this.rangeCorrectedPopoverLeft
                    : xMid;
        let y, rotation;
        if (this.targetDimensions.top < this.popoverDimensions.top) {
            y = this.popoverDimensions.top;
            rotation = 90;
            this.setArrowParameters(rotation, xMid, y);
        }
        else if (this.targetDimensions.bottom > this.popoverDimensions.bottom) {
            y = this.popoverDimensions.bottom;
            rotation = 270;
            this.setArrowParameters(rotation, xMid, y);
        }
    }
    convertAbsoluteValuesToRelativeValues(rotation, x, y) {
        rotation = this.arrowRotation0 - rotation;
        x = convertToRem(x - this.popoverDimensions.left) + this.arrowX0;
        y = convertToRem(y - this.popoverDimensions.top) + this.arrowY0;
        return { rotation, x, y };
    }
    setArrowParameters(rotation, x, y) {
        const arrowParameters = this.convertAbsoluteValuesToRelativeValues(rotation, x, y);
        this.arrowPositionX = arrowParameters.x;
        this.arrowPositionY = arrowParameters.y;
        this.arrowRotation = arrowParameters.rotation;
    }
    getSpecialPoint(position) {
        const specialPointData = {
            'left-up': new Point([this.popoverDimensions.left, this.rangeCorrectedPopoverTop], 180),
            'left-down': new Point([this.popoverDimensions.left, this.rangeCorrectedPopoverBottom], 180),
            'right-up': new Point([this.popoverDimensions.right, this.rangeCorrectedPopoverTop], 0),
            'right-down': new Point([this.popoverDimensions.right, this.rangeCorrectedPopoverBottom], 0),
        };
        return specialPointData[position];
    }
    /**
     * There will be some common points for
     * the same side of popover and target, if
     * the target's side extremes, are within the
     * popover's side's extremes.
     *
     * For example the target's left maybe after the popover's left
     * and the target's right might be less than the popover's right
     * This means the popover and target are having some common points
     * in the horizontal direction.
     *
     * This method returns the 2 extreme common points if they have any
     * common points or it returns an empty array.
     *
     * Will return [] if no intersection is present.
     */
    getIntersectionExtremes(t1, t2, p1, p2) {
        let intersectingPoints = [];
        // if target is to the left/top of popover (target right/bottom is less than popover left/top [t2<p1])
        // OR
        // if target is to the right/bottom of popover (target left/top is greater than popover right/bottom [t1>p2])
        // then there is no intersection. In that case return empty array.
        const noIntersection = t1 > p2 || t2 < p1;
        if (!noIntersection) {
            intersectingPoints = [Math.max(t1, p1), Math.min(t2, p2)];
        }
        return intersectingPoints;
    }
}
