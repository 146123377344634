// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import '../../utils/device-identifier.js'; // just to pull in modrnzr
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { ActivePropertyChangedEvent } from '../../utils/events';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import tableRowStyles from './sh-table-row.lit.scss.js';
/**
 * @fires active-changed - emitted when active attribute state has changed.
 * @fires clicked - emitted when row is clicked.
 * @fires collapse - emitted when row enters collapsed state.
 * @fires expand - emitted when row enters expand state.
 * @fires middle-scrolled - *hide emitted when content is scrolled.
 * @fires slots-modified - *hide emitted so that the table component can adjust the scrollwidth and left position
 * @slot Takes sh-table-head and sh-table-cell as children.
 * @slot frozen-left - This slot is for placing components in the left-most frozen block in a frozen table. Takes sh-table-cell and sh-table-head as children. Refer example of frozen-table for information on usage.
 * @slot scrollable - This slot is for placing components in the scrollable block in a frozen table. Takes sh-table-cell and sh-table-head as children. Refer example of frozen-table for information on usage.
 * @slot frozen-right - This slot is for placing components in the right-most frozen block in a frozen table. Takes sh-table-cell and sh-table-head as children. Refer example of frozen-table for information on usage.
 * @attr {String} label-rows -  Row with slot as header (Header row only) can have multiple lines when set.
 */
let SHTableRow = class SHTableRow extends FocusBlurMixin(ShuiLitElement) {
  constructor() {
    super(...arguments);
    /** *hide Default 33.3%. Sets the max-width, width, min-width of the frozen-left block in a frozen table.*/
    this.lwidth = '33.3%';
    /** *hide Default 33.3%. Sets the max-width, width, min-width of the scrollable block in a frozen table*/
    this.mwidth = '33.3%';
    /** *hide Default 33.3%. Sets the max-width, width, min-width of the frozen-right block in a frozen table.*/
    this.rwidth = '33.3%';
    /** @ignore */
    this.scrollLeft = 0;
  }
  static get styles() {
    return [sharedStyles, tableRowStyles];
  }
  render() {
    return html`
      <div
        id="wrapper"
        class="${this.active ? 'active' : ''} focus-item"
        @click="${(e) => {
          return this.handleClick(e);
        }}"
      >
        ${this.chapter
          ? html`
              <sh-icon
                id="tableExpandIcon"
                button
                icon="arrow-right-s"
                size="m"
                class="arrow-icon"
                @click="${() => {
                  this.handleExpanded();
                }}"
              ></sh-icon>
            `
          : ''}
        <slot id="mainBody" @slotchange="${() => this.mainBodyObserver()}"></slot>
        ${this.freeze
          ? html` <div
                class="frozen-left"
                style="display: flex; max-width: ${this.lwidth}; width: ${this
                  .lwidth}; min-width: ${this.lwidth}"
              >
                <slot name="frozen-left" id="frozenLeftSlot"></slot>
              </div>
              <div
                class="scrollable"
                style="display: flex; max-width: ${this.mwidth}; width: ${this
                  .mwidth}; min-width:${this.mwidth}"
                @scroll="${(e) => this.dispatchCustomScrollEvent(e)}"
              >
                <slot name="scrollable" id="scrollableSlot"></slot>
              </div>
              <div
                class="frozen-right"
                style="display: flex; max-width: ${this.rwidth};  width: ${this
                  .rwidth}; min-width: ${this.rwidth}"
              >
                <slot name="frozen-right" id="frozenRightSlot"></slot>
              </div>`
          : ''}
      </div>
      ${this.chapter
        ? html` <div class="chapter-wrapper">
            <slot name="chapterSlot"></slot>
          </div>`
        : ''}
    `;
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    if (name === 'condensed') {
      this.condensedObserver();
    } else if (name === 'readonly') {
      this.readonlyObserver();
    }
  }
  updated(changedProperties) {
    if (changedProperties.has('active')) {
      this.activeChangedEvent.emit(new ActivePropertyChangedEvent());
      this.updateComplete.then(() => {
        var _a, _b;
        const table = this.closest('sh-table');
        /*update selectedRows of the sh-table on active change*/
        if (this.active && table != null) {
          (_a = table.selectedRows) === null || _a === void 0 ? void 0 : _a.push(this);
        } else {
          /*when row is deselected, remove it from the selectedRows array*/
          if ((_b = table.selectedRows) === null || _b === void 0 ? void 0 : _b.includes(this)) {
            table.selectedRows.splice(table.selectedRows.indexOf(this), 1);
          }
        }
      });
      super.updated(changedProperties);
    }
    if (changedProperties.has('scrollLeft')) {
      this.scrollLeftChanged();
    }
    if (changedProperties.has('chapter')) {
      this.setExpandIconPosition();
    }
  }
  condensedObserver() {
    const children = this.children;
    for (let i = 0; i < children.length; i++) {
      children[i].condensed = this.condensed;
    }
  }
  readonlyObserver() {
    const children = this.children;
    for (let i = 0; i < children.length; i++) {
      children[i].readonly = this.readonly;
    }
  }
  scrollLeftChanged() {
    var _a;
    const element =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.scrollable');
    if (element) {
      element.scrollLeft = this.scrollLeft;
    }
  }
  connectedCallback() {
    super.connectedCallback();
    const children = this.children;
    let counter = 0;
    let index = 0;
    if (this.slot === 'header') {
      const headerChildren = this.children;
      for (let i = 0; i < headerChildren.length; i++) {
        if (headerChildren[i].sorted) {
          counter++;
          index = i;
        }
      }
      if (counter > 1) {
        let suffix;
        switch (index + 1) {
          case 2:
            suffix = 'nd';
            break;
          case 3:
            suffix = 'rd';
            break;
          default:
            suffix = 'th';
            break;
        }
        console.warn(
          `%c\t${counter} table-head have sorted property.\n`,
          'color:white;font-weight:900;text-align:center'
        );
        console.warn(
          `%c\tOnly one table-head with the sorted property is allowed.\n`,
          'color:white;font-weight:900;text-align:center'
        );
        console.warn(
          `%c\t${index + 1}${suffix} table-head with the sorted property is given the arrow.\n`,
          'color:white;font-weight:900;text-align:center'
        );
        this.removeDuplicateSortedProperty(index);
      }
      this.addEventListener('showarrow', (event) => {
        for (let i = 0; i < children.length; i++) {
          if (children[i] === event.target) {
            index = i;
            break;
          }
        }
        this.removeDuplicateSortedProperty(index);
      });
    } else {
      this.setAttribute('tabindex', '0');
    }
  }
  setLabelRows() {
    const headerChildren = this.children;
    for (let i = 0; i < headerChildren.length; i++) {
      //required for adding css styles in sh -table-head when labelrows property set
      headerChildren[i].labelRows = this.labelRows;
      //required for setting webkit-line-clamp dyanamically
      headerChildren[i].style.setProperty('--label-rows', String(this.labelRows));
    }
  }
  removeDuplicateSortedProperty(index) {
    for (let i = 0; i < this.children.length; i++) {
      const children = this.children;
      if (index !== i) {
        children[i].sorted = false;
      }
    }
  }
  firstUpdated(changedProperties) {
    var _a, _b, _c, _d, _e, _f;
    super.firstUpdated(changedProperties);
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    if (this.freeze) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#scrollableSlot')) === null || _b === void 0
        ? void 0
        : _b.addEventListener('slotchange', () => this.dispatchSlotModifiedEventWithData());
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('#frozenLeftSlot')) === null || _d === void 0
        ? void 0
        : _d.addEventListener('slotchange', () => this.dispatchSlotModifiedEventWithData());
      (_f =
        (_e = this.shadowRoot) === null || _e === void 0
          ? void 0
          : _e.querySelector('#frozenRightSlot')) === null || _f === void 0
        ? void 0
        : _f.addEventListener('slotchange', () => this.dispatchSlotModifiedEventWithData());
    }
    this.addEventListener('focus', () => this.handleFocus());
  }
  handleFocus() {
    this.addEventListener('keyup', this.handleKeyup);
  }
  handleKeyup(e) {
    const target = e.target;
    if (keyboardInteraction(e, KEYCODE.TAB)) {
      const targetChildren = target === null || target === void 0 ? void 0 : target.children;
      this.setTabIndex(targetChildren);
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_DOWN)) {
      e.preventDefault();
      //This is nested case scenario -where its is already expanded
      if (
        (target === null || target === void 0 ? void 0 : target.expanded) &&
        (target === null || target === void 0 ? void 0 : target.chapter)
      ) {
        for (
          let i = 0;
          i < (target === null || target === void 0 ? void 0 : target.children.length);
          i++
        ) {
          if (target.children[i].tagName === 'SH-TABLE-ROW') {
            const children = target.children;
            children[i].focus();
            break; //else focus goes to last child row of the nested row
          }
        } //normal -Default case
      } else if (target.nextElementSibling !== null && target.tagName === 'SH-TABLE-ROW') {
        const nextElementSibling = target.nextElementSibling;
        nextElementSibling.focus();
      } else {
        //added to remove code smell comment
      }
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_UP)) {
      e.preventDefault();
      if (target.previousElementSibling !== null && target.tagName === 'SH-TABLE-ROW') {
        const previousElementSibling = target.previousElementSibling;
        previousElementSibling.focus();
        if (
          target.previousElementSibling !== null &&
          target.previousElementSibling.tagName === 'SH-TABLE-CELL'
        ) {
          const parentNode = target.parentNode;
          parentNode === null || parentNode === void 0 ? void 0 : parentNode.focus();
        }
      }
    }
    if (keyboardInteraction(e, KEYCODE.ENTER)) {
      e.preventDefault();
      this.handleClick(e);
      e.stopPropagation();
    }
  }
  setTabIndex(tableRowChildren) {
    Array.from(tableRowChildren).forEach((element) => {
      if (element.tagName === 'SH-TABLE-ROW') {
        element.setAttribute('tabindex', '0');
      }
    });
  }
  disconnectedCallback() {
    this.removeEventListener('focus', this.handleFocus);
    super.disconnectedCallback();
  }
  handleClick(event) {
    const rowType = this.getAttribute('slot');
    if (rowType !== 'header' && event.target.id !== 'tableExpandIcon' && !this.readonly) {
      this.clickedEvent.emit(
        new CustomEvent('clicked', {
          bubbles: true,
          composed: true,
        })
      );
      /*Allows multiselection with ctrl and shft key press*/
      if (this.closest('sh-table')) {
        /*added to check whether the table contains any closest row (edge case: if row contains delete functionality and all rows are deleted)*/
        const table = this.closest('sh-table');
        if ((table === null || table === void 0 ? void 0 : table.selectionMode) === 'metakey') {
          this.onMultiSelectKeyPress(event);
        } else if (!table.multiselect) {
          /*If not multiselect , single row selection should happen on click*/
          this.active = true;
        }
      }
    }
  }
  /*Handles Multiselection for ctrl key and shift key press*/
  onMultiSelectKeyPress(event) {
    const table = this.closest('sh-table');
    const allTableRows = table.querySelectorAll('sh-table-row');
    if (event.ctrlKey || event.metaKey) {
      this.active = !this.active;
    } else if (event.shiftKey) {
      this.onShiftKeyPress(allTableRows);
    } else if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
      for (let i = 0; i < allTableRows.length; i++) {
        allTableRows[i].removeAttribute('active');
      }
      this.active = true;
      table.selectedRows = [];
    }
  }
  /*Handles selection of range of rows for row click + shift key press*/
  onShiftKeyPress(tableRows) {
    var _a;
    let prevRowIndex, currentRowIndex;
    const table = this.closest('sh-table');
    const selectedRows = table.selectedRows;
    const previousActiveRow = selectedRows[selectedRows.length - 1];
    const rowsArray = Array.prototype.slice.call(tableRows);
    /*Index of previously clicked row*/
    if (rowsArray.includes(previousActiveRow)) {
      prevRowIndex = rowsArray.indexOf(previousActiveRow);
    }
    /*Index of currently clicked row*/
    if (rowsArray.includes(this)) {
      currentRowIndex = rowsArray.indexOf(this);
    }
    /*select or deselect row based on it's previous state(active/inactive)*/
    if (
      prevRowIndex != undefined &&
      prevRowIndex >= 0 &&
      currentRowIndex != undefined &&
      currentRowIndex >= 0
    ) {
      if (!((_a = tableRows[currentRowIndex]) === null || _a === void 0 ? void 0 : _a.active)) {
        this.selectDeselectRows(tableRows, prevRowIndex, currentRowIndex, true);
      } else {
        this.selectDeselectRows(tableRows, prevRowIndex, currentRowIndex, false);
      }
    } else if ((prevRowIndex && !currentRowIndex) || (currentRowIndex && !prevRowIndex)) {
      /*if either previous active or current active is undefined , single row selection should happen based on the active index*/
      const activeIndex = currentRowIndex ? currentRowIndex : prevRowIndex;
      if (activeIndex) tableRows[activeIndex].active = true;
    }
  }
  /*Handles selection / deselection of range of rows based on it's active value*/
  selectDeselectRows(tableRows, prev, curr, activeValue) {
    const startIndex = prev;
    /*select range of rows (currently clicked row - previously active row)*/
    const range = Math.abs(curr - prev);
    for (let k = 0; k <= range; k++) {
      /*select range of rows Ex: (1 to 4)*/
      if (prev < curr) {
        tableRows[prev].active = activeValue;
        prev++;
      } else if (prev > curr) {
        /*select range of rows Ex: (4 to 1)*/
        tableRows[prev].active = activeValue;
        prev--;
      }
    }
    /*current clicked row is active for both selection / deselection*/
    if (activeValue) {
      tableRows[curr].active = activeValue;
    } else if (startIndex === curr) {
      /*if currently clicked row is same as previously clicked -> select/deselect based on it's previous state*/
      tableRows[curr].active = !tableRows[curr].active;
    }
  }
  dispatchCustomScrollEvent(e) {
    const table = e.target;
    const scrollLeft = table.scrollLeft;
    this.middleScrolledEvent.emit(
      new CustomEvent('middle-scrolled', {
        bubbles: true,
        composed: true,
        detail: { event: e, scrollLeft },
      })
    );
  }
  handleExpanded() {
    if (this.expanded === true) {
      this.expanded = false;
      this.collapseEvent.emit(
        new CustomEvent('collapse', {
          detail: { detail: this },
          composed: true,
          bubbles: true,
        })
      );
    } else {
      this.expanded = true;
      this.expandEvent.emit(
        new CustomEvent('expand', {
          detail: { detail: this },
          composed: true,
          bubbles: true,
        })
      );
    }
  }
  mainBodyObserver() {
    this.nestRows();
    const numberOfChildren = this.children.length;
    for (let i = 0; i < numberOfChildren; i++) {
      /*below code is required for bundle consumption nested scenario - for child nested rows to get readonly property -
            as the children will be an empty list & the propety won't be set to child table rows in the readonlyObserver */
      if (this.children[i].tagName === 'SH-TABLE-ROW') {
        const children = this.children;
        if (this.readonly != undefined) {
          children[i].readonly = this.readonly;
        }
      }
      if (this.labelRows && this.slot === 'header') {
        /*call  the below function inside firstUpdated() - as the header childen would come as an empty HTML collection in connectedCallback()
              in bundle consumption & label rows property won't set on the sh-table-head & therefore the feature  won't work in bundle.
              Calling the function from here resolves issue*/
        this.setLabelRows();
      }
    }
  }
  dispatchSlotModifiedEventWithData() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    /**
     * Event named 'slots-modifed' is dispatched so that the table component can adjust the scrollwidth and left position
     * of floating scrollbar of the parent table component.
     *
     * The data that is sent along with this event are:
     *
     * 1. innerWidth    -  the scrollwidth of the scrollable div - sending to adjust width of floating scrollbar thumb.
     * 2. width         -  the computed width of the scrollable div - sending to adjust width of floating scrollbar.
     * 3. leftPosition  -  the computed width of the frozen-left div - sending to adjust left position of floating scrollbar.
     *
     *
     */
    if (
      ((_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.scrollable')) ||
      ((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.frozen-left'))
    ) {
      const innerWidth =
        (_d =
          (_c = this.shadowRoot) === null || _c === void 0
            ? void 0
            : _c.querySelector('.scrollable')) === null || _d === void 0
          ? void 0
          : _d.scrollWidth;
      const width =
        (_f =
          (_e = this.shadowRoot) === null || _e === void 0
            ? void 0
            : _e.querySelector('.scrollable')) === null || _f === void 0
          ? void 0
          : _f.getBoundingClientRect().width;
      const leftPosition =
        (_h =
          (_g = this.shadowRoot) === null || _g === void 0
            ? void 0
            : _g.querySelector('.frozen-left')) === null || _h === void 0
          ? void 0
          : _h.getBoundingClientRect().width;
      this.slotsModifiedEvent.emit(
        new CustomEvent('slots-modified', {
          bubbles: true,
          composed: true,
          detail: { innerWidth, width, leftPosition },
        })
      );
    }
  }
  getParents(elem) {
    const parents = [];
    for (; elem && elem !== document && elem.tagName === 'SH-TABLE-ROW'; elem = elem.parentNode) {
      parents.push(elem);
    }
    return parents;
  }
  nestRows() {
    var _a, _b, _c;
    //check if row is in the header slot
    if (this.slot !== 'header') {
      // Get all table rows
      const childRows = Array.prototype.slice.call(this.childNodes).filter(function (element) {
        if (element.tagName === 'SH-TABLE-ROW') {
          return element;
        }
      });
      // if it has table rows within it, then this is a chapter
      // set the slot of each child rows to chapterSlot
      if (childRows.length > 0) {
        this.chapter = true;
        childRows.forEach((element) => {
          element.setAttribute('slot', 'chapterSlot');
        });
      }
    }
    // if this is is a chapter,
    // add class chapter-sibling to each sibling element.
    if (this.chapter) {
      const siblingItems = (_a = this.parentNode) === null || _a === void 0 ? void 0 : _a.children;
      const numberOfSiblings =
        siblingItems === null || siblingItems === void 0 ? void 0 : siblingItems.length;
      if (
        ((_b = this.parentNode) === null || _b === void 0 ? void 0 : _b.tagName) !== 'SH-TABLE-ROW'
      ) {
        if (numberOfSiblings) {
          for (let i = 0; i < numberOfSiblings; i++) {
            (_c = siblingItems[i]) === null || _c === void 0
              ? void 0
              : _c.classList.add('chapter-sibling');
          }
        }
      }
    }
    this.checkIfCheckbox();
  }
  checkIfCheckbox() {
    var _a, _b, _c;
    const mainBodySlot =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#mainBody');
    const slotFirstChild =
      mainBodySlot === null || mainBodySlot === void 0
        ? void 0
        : mainBodySlot.assignedElements()[0];
    let isCheckbox = false;
    if (slotFirstChild) {
      isCheckbox = slotFirstChild.hasAttribute('checkbox');
    }
    // if this is a chapter or this has class as chapter-sibling or this has been assigned to slot chapterSlot
    if (
      this.chapter ||
      this.classList.contains('chapter-sibling') ||
      this.slot === 'chapterSlot' ||
      isCheckbox
    ) {
      //add padding by level of nesting
      this.setCellPosition(isCheckbox);
    } else {
      // Dyanamic removal of checkbox -adjust the padding left via the following classes addition & removal
      const header = this.querySelector('sh-table-head');
      (_b = header === null || header === void 0 ? void 0 : header.classList) === null ||
      _b === void 0
        ? void 0
        : _b.add('first-header-cell');
      const tableCell = this.querySelector('sh-table-cell');
      (_c = tableCell === null || tableCell === void 0 ? void 0 : tableCell.classList) === null ||
      _c === void 0
        ? void 0
        : _c.remove('checkbox');
    }
  }
  setCellPosition(isCheckbox) {
    var _a;
    const nestingLevel = this.getParents(this).length - 1;
    const firstCell = this.querySelector('sh-table-cell:not([checkbox])');
    const firstheader =
      this.slot === 'header' ? this.querySelector('sh-table-head:not([checkbox])') : '';
    this.style.setProperty('--nesting-level', String(nestingLevel));
    if (firstCell) {
      if (nestingLevel === 0 && !isCheckbox) {
        firstCell.classList.add('first-nested-cell');
      } else {
        // row
        if (
          (_a =
            document === null || document === void 0
              ? void 0
              : document.querySelector('sh-table-row')) === null || _a === void 0
            ? void 0
            : _a.closest('.chapter-sibling')
        ) {
          this.classList.add('nested');
          // cell
          firstCell.classList.add('nested-cell');
        } else {
          const checkBoxCell = this.querySelector('sh-table-cell[checkbox]')
            ? this.querySelector('sh-table-cell[checkbox]')
            : '';
          if (checkBoxCell) {
            checkBoxCell.classList.add('non-nested-cell');
          }
        }
      }
      const checkBoxCell = this.querySelector('sh-table-cell[checkbox]');
      if (checkBoxCell) {
        firstCell.classList.add('checkbox');
        checkBoxCell.classList.add('checkbox-cell');
      } else {
        firstCell.classList.remove('checkbox');
      }
    }
    if (firstheader) {
      const checkboxHeader = this.querySelector('sh-table-head[checkbox]');
      if (checkboxHeader) {
        checkboxHeader.style.position = 'absolute';
      }
      // first header cell
      if (isCheckbox) {
        // remove existing class first then add first-header-cell-checkbox
        firstheader.classList.remove('first-header-cell');
        firstheader.classList.add('first-header-cell-checkbox');
      } else {
        firstheader.classList.add('first-header-cell');
      }
    }
  }
  setExpandIconPosition() {
    var _a, _b;
    if (!this.chapter) return;
    const expandIcon =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#tableExpandIcon');
    const nestingLevel = this.getParents(this).length - 1;
    this.style.setProperty('--nesting-level', String(nestingLevel));
    const mainBodySlot =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#mainBody');
    let firstColumnCheckBox;
    if (
      mainBodySlot === null || mainBodySlot === void 0 ? void 0 : mainBodySlot.assignedElements()[0]
    ) {
      firstColumnCheckBox = mainBodySlot.assignedElements()[0].hasAttribute('checkbox');
    }
    if (firstColumnCheckBox) {
      //nested table with multiselect checkbox
      expandIcon === null || expandIcon === void 0
        ? void 0
        : expandIcon.classList.add('multiselect-checkbox');
    } else {
      //default nested table without checkbox multiselect
      expandIcon === null || expandIcon === void 0
        ? void 0
        : expandIcon.classList.add('default-nested');
    }
  }
};
__decorate([event()], SHTableRow.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHTableRow.prototype, 'middleScrolledEvent', void 0);
__decorate([event()], SHTableRow.prototype, 'slotsModifiedEvent', void 0);
__decorate([event()], SHTableRow.prototype, 'clickedEvent', void 0);
__decorate([event()], SHTableRow.prototype, 'collapseEvent', void 0);
__decorate([event()], SHTableRow.prototype, 'expandEvent', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableRow.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableRow.prototype, 'freeze', void 0);
__decorate([property({ type: String, reflect: true })], SHTableRow.prototype, 'lwidth', void 0);
__decorate([property({ type: String, reflect: true })], SHTableRow.prototype, 'mwidth', void 0);
__decorate([property({ type: String, reflect: true })], SHTableRow.prototype, 'rwidth', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableRow.prototype, 'condensed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableRow.prototype, 'expanded', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableRow.prototype, 'readonly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableRow.prototype, 'chapter', void 0);
__decorate([property({ type: Number, reflect: true })], SHTableRow.prototype, 'scrollLeft', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'label-rows' })],
  SHTableRow.prototype,
  'labelRows',
  void 0
);
SHTableRow = __decorate([customElement('sh-table-row')], SHTableRow);
export { SHTableRow };
