import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { deviceIdentifier } from '../../utils/device-identifier';
import textStyles from './sh-text.lit.scss.js';
let SHText = class SHText extends LitElement {
  constructor() {
    super(...arguments);
    /** Default body-1. Sets the size of the text element. For available text sizes, see the 'Size' reference table under the 'Design' tab. */
    this.size = 'body-1';
    /** Default primary. Sets the color of the text element. secondary color can be set on title-2, body-1 , body-2 text sizes. If no color is specified, the default ones will be applied. */
    this.color = 'primary';
    /** Default false. If set to `true`, will update the text color and disable pointer events (in case of links). */
    this.disabled = false;
  }
  static get styles() {
    return textStyles;
  }
  render() {
    return this.href && (this.size === 'body-1' || this.size === 'body-2')
      ? html`<a href="${this.href}" tabindex=${this.isDisabled()}><slot></slot></a>`
      : html`<slot></slot>`;
  }
  isDisabled() {
    return this.disabled ? -1 : 0;
  }
  attributeChangedCallback(name, oldval, newval) {
    super.attributeChangedCallback(name, oldval, newval);
    this.dispatchEvent(new Event(`${name}-changed`));
  }
  firstUpdated() {
    this.handleLegacyAttributes();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
  }
  // remove default values for backwards compatibility
  handleLegacyAttributes() {
    const arr = ['super-header', 'header', 'body-1', 'body-2', 'title-1', 'title-2'];
    arr.forEach((attr) => {
      if (this.hasAttribute(attr)) {
        console.warn(
          `You are using a legacy property (${attr}) of sh-text component. Please use the new 'size' and 'color' properties instead.`
        );
        if (this.size) {
          this.removeAttribute('size');
        }
      }
    });
  }
};
__decorate([property({ type: String, reflect: true })], SHText.prototype, 'size', void 0);
__decorate([property({ type: String, reflect: true })], SHText.prototype, 'color', void 0);
__decorate([property({ type: String, reflect: true })], SHText.prototype, 'href', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHText.prototype, 'disabled', void 0);
SHText = __decorate([customElement('sh-text')], SHText);
export { SHText };
