import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { calculatedBaseFontSize } from '../../utils/base-font-size.js';
import { ControlStateMixin } from '../../utils/control-state-mixin.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import { convertToPixel } from '../../utils/rem-to-pixel-convertor.js';
import { SliderInputRectifierMixin } from '../../utils/slider-input-rectifier-mixin.js';
import { SliderStepMarkerMixin } from '../../utils/slider-step-marker-mixin.js';
import './arrow-controls.js';
import './range-slider.js';
import sliderStyles from './sh-slider.lit.scss.js';
let SHSlider = class SHSlider extends ControlStateMixin(
  SliderStepMarkerMixin(SliderInputRectifierMixin(LitElement), {})
) {
  constructor() {
    super(...arguments);
    /** Defines the label of the slider. Will be shown as a placeholder on top of it or hidden if it's empty or undefined. It is only available in the horizontal direction. */
    this.label = 'Label';
    /** Default 0. Defines the current value of the slider. Must be a number between the min and max values. */
    this.value = 0;
    /** Default 1. Defines the intervals of the slider. If set to 0.1 for example, decimals will be allowed. */
    this.step = 1;
    /** Default 0. Defines the minimum value allowed. */
    this.min = 0;
    /** Default 100. Defines the maximum value allowed. */
    this.max = 100;
    /** Default 0. Defines the current upper value of the range in a slider . Must be a number between min and max values and cannot be less than the lower value. */
    this.upperValue = 0;
    /** Default 0. Defines the current lower value of the range in a slider . Must be a number between min and max values and cannot be more than the upper value. */
    this.lowerValue = 0;
    /** A set of numbers in between the min and max range of the slider where markers will be displayed. Range related properties like show-range, upper-value and lower-value will not be available when the markers property has been defined. */
    this.ticks = [];
    this.stepMarkers = [];
    /**Default false. If set to `true`,  tooltip is hidden in the slider on hover. */
    this.hideTooltip = false;
  }
  static get styles() {
    return [
      extendInteractiveArea(
        '.sliderTrack::-webkit-slider-thumb, .verticalTrack::-webkit-slider-thumb'
      ),
      extendInteractiveArea('.sliderTrack::-moz-range-thumb,.verticalTrack::-moz-range-thumb'),
      sliderStyles,
    ];
  }
  render() {
    var _a, _b, _c, _d;
    return html`
      ${this.label && !this.showRange
        ? html` <div class="label-wrapper">
            <sh-text class="slider-label" size="body-1" color="secondary">${this.label}</sh-text>
            ${!this.vertical
              ? html` <input
                  id="inputText"
                  type="number"
                  min="${this.min}"
                  max="${this.max}"
                  step="${this.step}"
                  .value="${this.value}"
                  ?disabled="${this.disabled}"
                  @keyup="${this.onKeyPress}"
                  @blur="${this.handleOnBlur}"
                />`
              : ''}
          </div>`
        : ''}
      ${!this.showRange
        ? html` ${!this.vertical
            ? // slider implementation with adjustment controls
              html` <div class="outer-wrapper">
                <div
                  class="sliderWrapper"
                  style="--value:${this.value}; --min:${this.min}; --max:${this.max}"
                >
                  <input
                    type="range"
                    id="horizontalSlider"
                    class="sliderTrack"
                    min="${this.min}"
                    max="${this.max}"
                    label="${this.label}"
                    step="${this.step}"
                    .value="${this.value}"
                    ?disabled="${this.disabled}"
                    @mousedown="${this.thumbDown}"
                    @mouseup="${this.thumbUp}"
                    @touchstart="${this.thumbDown}"
                    @touchmove="${() => {
                      if (!this.showValue) {
                        this.showTooltipAndUpdateValue();
                      }
                    }}"
                    @touchend="${this.thumbUp}"
                    @click="${this.updateValue}"
                  />
                  ${!this.hideTooltip
                    ? html`<sh-text id="sliderTooltip" color="secondary"> ${this.value} </sh-text>`
                    : ''}
                  <ul id="step_ticks" class="step_ticks">
                    ${(_a = this.stepMarkers) === null || _a === void 0
                      ? void 0
                      : _a.map(
                          (item) =>
                            html` <li
                              class="sh-step-thumb-item"
                              @click="${(e) => this.onTickClick(e)}"
                              data-value="${item.position}"
                              .style="${item.style}"
                              tabindex="0"
                              @keyup="${(e) => {
                                if (e.code === 'Space' || e.code === 'Enter') {
                                  e.target.click();
                                }
                              }}"
                            ></li>`
                        )}
                  </ul>
                  ${this.ticks
                    ? html` <ul id="ticks_list" class="ticks-list">
                        ${(_b = this.renderedTicks) === null || _b === void 0
                          ? void 0
                          : _b.map(
                              (item) =>
                                html` <li
                                  class="tick-item"
                                  tabindex="0"
                                  data-value="${item.position}"
                                  .style="${item.style}"
                                  @click="${(e) => this.onTickClick(e)}"
                                  @keyup="${(e) => {
                                    if (e.code === 'Space' || e.code === 'Enter') {
                                      e.target.click();
                                    }
                                  }}"
                                ></li>`
                            )}
                      </ul>`
                    : ''}
                </div>
                ${this.showControls
                  ? html` <arrow-controls
                      horizontal
                      .min="${this.min}"
                      .max="${this.max}"
                      .step="${this.step}"
                      .value="${this.value}"
                      ?disabled="${this.disabled}"
                    ></arrow-controls>`
                  : ''}
              </div>`
            : // vertical slider implementation
              html` <div id="inputLabel">
                  <input
                    type="number"
                    id="verticalInput"
                    min="${this.min}"
                    max="${this.max}"
                    step="${this.step}"
                    .value="${this.value}"
                    ?disabled="${this.disabled}"
                    @keyup="${this.onKeyPress}"
                    @blur="${this.handleOnBlur}"
                    @focus="${this.verticalInputClickActions}"
                    @focusout="${this.verticalInputFousOutActions}"
                  />
                  <span
                    id="verticalMax"
                    @click="${this.verticalInputClickActions}"
                    @focusout="${this.verticalInputFousOutActions}"
                    >/${this.max}</span
                  >
                </div>
                <div class="vertical-slider-wrapper">
                  <div
                    class="vertical-wrapper"
                    style="--value:${this.value}; --min:${this.min}; --max:${this.max}"
                  >
                    <input
                      type="range"
                      id="verticalSlider"
                      class="verticalTrack"
                      min="${this.min}"
                      max="${this.max}"
                      label="${this.label}"
                      step="${this.step}"
                      .value="${this.value}"
                      ?disabled="${this.disabled}"
                      @mousedown="${this.thumbDown}"
                      @mouseup="${this.thumbUp}"
                      @touchstart="${this.thumbDown}"
                      @touchmove="${() => {
                        if (!this.showValue) {
                          this.showTooltipAndUpdateValue();
                        }
                      }}"
                      @touchend="${this.thumbUp}"
                      @click="${this.updateValue}"
                    />
                    ${!this.hideTooltip
                      ? html`<sh-text id="verticalTooltip" color="secondary">
                          ${this.value}
                        </sh-text>`
                      : ''}
                    <ul id="vertical_step_ticks" class="step_ticks">
                      ${(_c = this.stepMarkers) === null || _c === void 0
                        ? void 0
                        : _c.map(
                            (item) =>
                              html` <li
                                class="sh-step-thumb-item"
                                @click="${(e) => this.onTickClick(e)}"
                                data-value="${item.position}"
                                .style="${item.style}"
                                tabindex="0"
                                @keyup="${(e) => {
                                  if (e.code === 'Space' || e.code === 'Enter') {
                                    e.target.click();
                                  }
                                }}"
                              ></li>`
                          )}
                    </ul>
                    ${this.ticks
                      ? html` <ul id="ticks_list" class="ticks-list">
                          ${(_d = this.renderedTicks) === null || _d === void 0
                            ? void 0
                            : _d.map(
                                (item) =>
                                  html` <li
                                    class="tick-item"
                                    tabindex="0"
                                    data-value="${item.position}"
                                    .style="${item.style}"
                                    @click="${(e) => this.onTickClick(e)}"
                                    @keyup="${(e) => {
                                      if (e.code === 'Space' || e.code === 'Enter') {
                                        e.target.click();
                                      }
                                    }}"
                                  ></li>`
                              )}
                        </ul>`
                      : ''}
                  </div>
                </div>
                ${this.showControls
                  ? html` <arrow-controls
                      .min="${this.min}"
                      .max="${this.max}"
                      .step="${this.step}"
                      .value="${this.value}"
                      ?disabled="${this.disabled}"
                    ></arrow-controls>`
                  : ''}`}`
        : // range slider implementation
          html` <range-slider
            .label="${this.label}"
            .min="${this.min}"
            .max="${this.max}"
            .step="${this.step}"
            .lowerValue="${this.lowerValue}"
            .upperValue="${this.upperValue}"
            .showValue="${this.showValue}"
            ?disabled="${this.disabled}"
            ?hide-tooltip="${this.hideTooltip}"
          ></range-slider>`}
    `;
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('value')) {
      this.updateValue();
      this.tooltipSelection();
    }
    if (changedProperties.has('ticks')) {
      this.thumbTickCollisionCheck = true;
      this.renderTicks();
    }
    if (changedProperties.has('step')) {
      if (!this.showRange) {
        this.renderStepMarkers();
      }
      this.updateValue();
      this.setInputWidth();
    }
    if (changedProperties.has('min') || changedProperties.has('max')) {
      this.renderTicks();
      if (!this.showRange) {
        this.renderStepMarkers();
      }
      this.updateValue();
      this.setInputWidth();
      this.tooltipSelection();
    }
    if (changedProperties.has('disabled') && !this.showRange) {
      this.checkTickThumbCollision();
    }
    if (changedProperties.has('isDrag')) {
      this.isDragObserver();
    }
    if (changedProperties.has('ticksRendered') && this.ticksRendered) {
      if (!this.showRange && this.thumbTickCollisionCheck) {
        this.checkTickThumbCollision();
        this.thumbTickCollisionCheck = false;
      }
      this.ticksRendered = false;
    }
    if (changedProperties.has('max') || changedProperties.has('value')) {
      this.setInputWidth();
    }
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    const listOfProperties = ['value', 'min', 'max', 'step', 'label', 'showControls', 'showRange'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  connectedCallback() {
    super.connectedCallback();
    /** sh-slider does not have any default tabindex from ControlStateMixin */
    this.defaultTabIndex = '';
    // Resize Observer
    this.browserResize = new ResizeObserver(() => {
      var _a, _b, _c, _d, _e, _f, _g;
      if (this.vertical) {
        const resizeChecker =
          (_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('.vertical-slider-wrapper');
        const input =
          (_b = this.shadowRoot) === null || _b === void 0
            ? void 0
            : _b.querySelector('.verticalTrack');
        const resizeCheckerHeight =
          ((_c =
            resizeChecker === null || resizeChecker === void 0
              ? void 0
              : resizeChecker.getBoundingClientRect()) === null || _c === void 0
            ? void 0
            : _c.height) / calculatedBaseFontSize;
        input === null || input === void 0
          ? void 0
          : input.style.setProperty('width', `${resizeCheckerHeight}rem`);
        if (this.classList.contains('touch-device')) {
          this.verticalTickCollisionForTouchCheck();
        }
      }
      this.renderTicks();
      if (this.showRange) {
        (_e =
          (_d = this.shadowRoot) === null || _d === void 0
            ? void 0
            : _d.querySelector('range-slider')) === null || _e === void 0
          ? void 0
          : _e.generateHandleTrack();
        (_g =
          (_f = this.shadowRoot) === null || _f === void 0
            ? void 0
            : _f.querySelector('range-slider')) === null || _g === void 0
          ? void 0
          : _g.renderStepMarkersRange();
      } else {
        this.renderStepMarkers();
      }
    });
    this.browserResize.observe(this);
  }
  disconnectedCallback() {
    var _a, _b;
    super.disconnectedCallback();
    (_a = this.browserResize) === null || _a === void 0 ? void 0 : _a.unobserve(this);
    (_b = this.browserResize) === null || _b === void 0 ? void 0 : _b.disconnect();
  }
  firstUpdated(changedProperties) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    super.firstUpdated(changedProperties);
    const rangeSlider =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('range-slider');
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    if (!this.showRange) {
      if (this.showControls) {
        (_c =
          (_b = this.shadowRoot) === null || _b === void 0
            ? void 0
            : _b.querySelector('arrow-controls')) === null || _c === void 0
          ? void 0
          : _c.addEventListener('control-value-change', (e) => {
              e.stopPropagation();
              this.value = Number(e.target.value);
            });
        (_e =
          (_d = this.shadowRoot) === null || _d === void 0
            ? void 0
            : _d.querySelector('arrow-controls')) === null || _e === void 0
          ? void 0
          : _e.addEventListener('control-value-change-end', (e) => {
              e.stopPropagation();
              this.dispatchValueChangeEndEvent();
            });
      }
      let sliderThumb, tooltip;
      if (this.vertical) {
        sliderThumb =
          (_f = this.shadowRoot) === null || _f === void 0
            ? void 0
            : _f.querySelector('.verticalTrack');
        tooltip =
          (_g = this.shadowRoot) === null || _g === void 0
            ? void 0
            : _g.querySelector('#verticalTooltip');
      } else {
        sliderThumb =
          (_h = this.shadowRoot) === null || _h === void 0
            ? void 0
            : _h.querySelector('.sliderTrack');
        tooltip =
          (_j = this.shadowRoot) === null || _j === void 0
            ? void 0
            : _j.querySelector('#sliderTooltip');
      }
      sliderThumb === null || sliderThumb === void 0
        ? void 0
        : sliderThumb.addEventListener('input', () => {
            this.tooltipSelection();
            this.updateValue();
          });
      sliderThumb === null || sliderThumb === void 0
        ? void 0
        : sliderThumb.addEventListener('focus', () => this.handleFocus(sliderThumb, tooltip));
      sliderThumb === null || sliderThumb === void 0
        ? void 0
        : sliderThumb.addEventListener('blur', () => this.handleBlur(sliderThumb, tooltip));
    } else {
      rangeSlider === null || rangeSlider === void 0
        ? void 0
        : rangeSlider.addEventListener('lower-value-changed', (e) => {
            this.lowerValue = Number(e.target.lowerValue);
          });
      rangeSlider === null || rangeSlider === void 0
        ? void 0
        : rangeSlider.addEventListener('upper-value-changed', (e) => {
            this.upperValue = Number(e.target.upperValue);
          });
    }
  }
  handleFocus(thumb, tooltip) {
    thumb.onkeyup = (e) => {
      tooltip === null || tooltip === void 0 ? void 0 : tooltip.classList.add('tab_tooltip');
      if (keyboardInteraction(e, KEYCODE.TAB)) {
        thumb === null || thumb === void 0 ? void 0 : thumb.classList.add('tab_outline');
      }
      if (keyboardInteraction(e, KEYCODE.ARROW_RIGHT) || keyboardInteraction(e, KEYCODE.ARROW_UP)) {
        this.updateValue();
        this.dispatchValueChangeEndEvent();
      }
      if (
        keyboardInteraction(e, KEYCODE.ARROW_LEFT) ||
        keyboardInteraction(e, KEYCODE.ARROW_DOWN)
      ) {
        this.updateValue();
        this.dispatchValueChangeEndEvent();
      }
    };
  }
  setInputWidth() {
    let totalLength;
    const maxVal = parseFloat(this.getAttribute('max'));
    const minVal = parseFloat(this.getAttribute('min'));
    const stepVal = parseFloat(this.getAttribute('step'));
    const absMaxVal = Math.abs(maxVal);
    const absMinVal = Math.abs(minVal);
    const integerPartLength = Math.ceil(Math.log10(Math.max(absMaxVal, absMinVal) + 1));
    const decimalPartLength = stepVal.toString().includes('.')
      ? stepVal.toString().split('.')[1].length
      : 0;
    if (minVal < 0) {
      // +1 for the decimal point and +1 for the minus sign
      totalLength = integerPartLength + (decimalPartLength > 0 ? decimalPartLength + 1 : 0) + 1;
    } else {
      totalLength = integerPartLength + (decimalPartLength > 0 ? decimalPartLength + 1 : 0);
    }
    this.style.setProperty('--inp-w', totalLength + 'ch');
  }
  handleBlur(thumb, tooltip) {
    thumb === null || thumb === void 0 ? void 0 : thumb.classList.remove('tab_outline');
    tooltip === null || tooltip === void 0 ? void 0 : tooltip.classList.remove('tab_tooltip');
  }
  renderTicks() {
    var _a, _b, _c;
    if (this.showRange) {
      return;
    }
    let input;
    if (this.vertical) {
      input =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#verticalSlider');
    } else {
      input =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('#horizontalSlider');
    }
    const TICK_RADIUS_REM =
      parseFloat(getComputedStyle(this).getPropertyValue('--sh-slider-tick-diameter')) / 2;
    const containerLengthInRem =
      (input === null || input === void 0 ? void 0 : input.offsetWidth) / calculatedBaseFontSize -
      2 * TICK_RADIUS_REM;
    /* renderedTicks is an array that holds style values (left position) for each tick rendered.
          this is used in the map function in the dom to create ticks based on the passed position for each tick.
        */
    const markers =
      (_c = this.ticks) === null || _c === void 0
        ? void 0
        : _c.map((value) => {
            let current_position = (value - this.min) / (this.max - this.min);
            current_position = current_position * containerLengthInRem;
            const style = `left: ${current_position}rem`;
            return { style, position: value };
          });
    this.renderedTicks = markers;
    this.ticksRendered = true;
  }
  renderStepMarkers() {
    var _a, _b, _c, _d;
    if (this.step === 0) {
      return;
    }
    const stepTickDistance = convertToPixel(
      parseFloat(getComputedStyle(this).getPropertyValue('--sh-slider-step-tick-min-width'))
    );
    let input, slider_step;
    if (this.vertical) {
      input =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.verticalTrack');
      slider_step =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('#vertical_step_ticks');
    } else {
      input =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('.sliderTrack');
      slider_step =
        (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('#step_ticks');
    }
    const containerLength = input === null || input === void 0 ? void 0 : input.offsetWidth;
    const stepTickHalfWidth =
      parseFloat(getComputedStyle(this).getPropertyValue('--sh-slider-step-tick-width')) / 2;
    // calculates the distance between two consecutive step-markers
    const distance = this.getCalculatedStepDistance(
      containerLength,
      this.min,
      this.max,
      this.step,
      stepTickHalfWidth
    );
    if (distance >= stepTickDistance) {
      slider_step === null || slider_step === void 0
        ? void 0
        : slider_step.classList.add('markers');
      // calculates the step-marker points on track and store it in points array
      this.calculatePoints(containerLength, stepTickHalfWidth);
    } else {
      slider_step === null || slider_step === void 0
        ? void 0
        : slider_step.classList.remove('markers');
    }
  }
  onTickClick(e) {
    this.value = Number(e.target.dataset.value);
    this.dispatchValueChangeEndEvent();
  }
  updateValue() {
    var _a, _b;
    let input;
    if (!this.showRange) {
      if (this.vertical) {
        input =
          (_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('.verticalTrack');
      } else {
        input =
          (_b = this.shadowRoot) === null || _b === void 0
            ? void 0
            : _b.querySelector('.sliderTrack');
      }
      this.value = Number(input === null || input === void 0 ? void 0 : input.value);
      this.trackHighlightUpdate();
    }
  }
  trackHighlightUpdate() {
    var _a, _b;
    let sliderWrapper;
    if (this.vertical) {
      sliderWrapper =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.vertical-wrapper');
    } else {
      sliderWrapper =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('.sliderWrapper');
    }
    if (this.activeTrack || this.activeTrackInvert) {
      const trackGradientValue = ((this.value - this.min) / (this.max - this.min)) * 100;
      sliderWrapper === null || sliderWrapper === void 0
        ? void 0
        : sliderWrapper.style.setProperty('--gradient-value', `${trackGradientValue}%`);
    }
  }
  verticalInputClickActions() {
    var _a, _b, _c, _d, _e;
    (_b =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#verticalMax')) === null || _b === void 0
      ? void 0
      : _b.classList.add('textbox_click');
    (_d =
      (_c = this.shadowRoot) === null || _c === void 0
        ? void 0
        : _c.querySelector('#verticalInput')) === null || _d === void 0
      ? void 0
      : _d.classList.add('text_align');
    ((_e = this.shadowRoot) === null || _e === void 0
      ? void 0
      : _e.querySelector('#verticalInput')
    ).focus();
  }
  verticalInputFousOutActions() {
    var _a, _b, _c, _d;
    (_b =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#verticalMax')) === null || _b === void 0
      ? void 0
      : _b.classList.remove('textbox_click');
    (_d =
      (_c = this.shadowRoot) === null || _c === void 0
        ? void 0
        : _c.querySelector('#verticalInput')) === null || _d === void 0
      ? void 0
      : _d.classList.remove('text_align');
  }
  tooltipSelection() {
    var _a, _b;
    if (!this.showRange) {
      let sliderThumb;
      if (this.vertical) {
        sliderThumb =
          (_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('.verticalTrack');
      } else {
        sliderThumb =
          (_b = this.shadowRoot) === null || _b === void 0
            ? void 0
            : _b.querySelector('.sliderTrack');
      }
      let newValue = Number(
        (Number(sliderThumb === null || sliderThumb === void 0 ? void 0 : sliderThumb.value) -
          Number(sliderThumb === null || sliderThumb === void 0 ? void 0 : sliderThumb.min) * 100) /
          (Number(sliderThumb === null || sliderThumb === void 0 ? void 0 : sliderThumb.max) -
            Number(sliderThumb === null || sliderThumb === void 0 ? void 0 : sliderThumb.min))
      );
      if (Number.isNaN(newValue)) {
        newValue = 0;
      }
      // checking for thumb collision with ticks on dynamic value change
      this.checkTickThumbCollision();
    }
  }
  thumbDown(e) {
    var _a, _b, _c, _d;
    this.isDrag = true;
    // Preventing right-click and middle-click functionality on the mouse
    if (e.button === 1 || e.button === 2) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (this.vertical) {
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.verticalTrack')) === null || _b === void 0
        ? void 0
        : _b.classList.add('active');
    } else {
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('.sliderTrack')) === null || _d === void 0
        ? void 0
        : _d.classList.add('active');
    }
  }
  // showTooltipAndUpdateValue is for mainly setting the tooltip to show when the slider is dragged in the
  // touch device scenario. Otherwise this method is not needed for desktop scenarios as the
  // style to show tooltip is already mentioned in css for hover of slider component.
  showTooltipAndUpdateValue() {
    var _a, _b;
    if (!this.showValue && !this.hideTooltip) {
      if (this.vertical) {
        ((_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('#verticalTooltip')
        ).style.display = 'flex';
      } else {
        ((_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('#sliderTooltip')
        ).style.display = 'flex';
      }
    }
    this.updateValue();
  }
  thumbUp() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (this.isDrag) {
      this.isDrag = false;
      if (this.vertical) {
        (_b =
          (_a = this.shadowRoot) === null || _a === void 0
            ? void 0
            : _a.querySelector('#verticalTooltip')) === null || _b === void 0
          ? void 0
          : _b.style.removeProperty('display');
        (_d =
          (_c = this.shadowRoot) === null || _c === void 0
            ? void 0
            : _c.querySelector('.verticalTrack')) === null || _d === void 0
          ? void 0
          : _d.classList.remove('active');
      } else {
        (_f =
          (_e = this.shadowRoot) === null || _e === void 0
            ? void 0
            : _e.querySelector('#sliderTooltip')) === null || _f === void 0
          ? void 0
          : _f.style.removeProperty('display');
        (_h =
          (_g = this.shadowRoot) === null || _g === void 0
            ? void 0
            : _g.querySelector('.sliderTrack')) === null || _h === void 0
          ? void 0
          : _h.classList.remove('active');
      }
    }
  }
  isDragObserver() {
    if (!this.isDrag) {
      this.dispatchValueChangeEndEvent();
    }
  }
  onKeyPress(e) {
    if (
      !(
        keyboardInteraction(e, KEYCODE.ARROW_UP) ||
        keyboardInteraction(e, KEYCODE.ARROW_DOWN) ||
        keyboardInteraction(e, KEYCODE.ENTER)
      )
    ) {
      return;
    }
    const input = e.target;
    if (keyboardInteraction(e, KEYCODE.ARROW_UP)) {
      this.valueValidation(input, true);
      input.focus();
    }
    if (keyboardInteraction(e, KEYCODE.ARROW_DOWN)) {
      this.valueValidation(input, true);
      input.focus();
    }
    // If user press ENTER, then do validation
    if (keyboardInteraction(e, KEYCODE.ENTER)) {
      this.valueValidation(input, true);
      /* Enter case: isValueChanged is sent as true -it will dispatch the valueChangeEnd on enter
              irrespective of whether or not value has changed */
    }
  }
  handleOnBlur(e) {
    // on blur of input do the validation
    this.valueValidation(e.target, false);
  }
  valueValidation(input, isValueChanged) {
    const value = this.getValue(Number(input.value), this.value, this.min, this.max);
    this.value = Number(value);
    input.value = String(this.value);
    //fire custom-event for input-value changed
    if (isValueChanged) {
      this.dispatchValueChangeEndEvent();
    }
  }
  verticalTickCollisionForTouchCheck() {
    var _a, _b;
    const THUMB_RADIUS_REM =
      parseFloat(getComputedStyle(this).getPropertyValue('--sh-slider-thumb-diameter')) / 2;
    const THUMB_RADIUS_PX = convertToPixel(THUMB_RADIUS_REM);
    const HALO_THUMB_DIAM_REM = 2.5;
    const HALO_THUMB_RADIUS_REM = HALO_THUMB_DIAM_REM / 2;
    const HALO_RING_RADIUS_REM = HALO_THUMB_RADIUS_REM - THUMB_RADIUS_REM;
    const input =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.verticalTrack');
    let containerLength = input.offsetWidth - 2 * THUMB_RADIUS_PX;
    let current_position = (Number(input.value) - this.min) / (this.max - this.min);
    current_position = current_position * containerLength;
    // start of ring point till circ ofactual thumb = 20 - 7 = 13 = 0.8125rem
    const valuePositionLeft = current_position - convertToPixel(HALO_RING_RADIUS_REM);
    // full circle diam = 2.5rem
    const valuePositionRight = valuePositionLeft + convertToPixel(HALO_THUMB_DIAM_REM);
    const TICK_RADIUS_REM =
      parseFloat(getComputedStyle(this).getPropertyValue('--sh-slider-tick-diameter')) / 2;
    const TICK_RADIUS_PX = convertToPixel(TICK_RADIUS_REM);
    containerLength = input.offsetWidth - 2 * TICK_RADIUS_PX;
    const collisionArray = [];
    this.ticks.forEach((value) => {
      let current_position = (value - this.min) / (this.max - this.min);
      current_position = current_position * containerLength;
      collisionArray.push(
        this.detectCollision(
          valuePositionLeft,
          valuePositionRight,
          current_position,
          current_position + 2 * TICK_RADIUS_PX
        )
      );
    });
    const trackTicks =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelectorAll('.tick-item');
    for (let i = 0; i < trackTicks.length; ++i) {
      if (collisionArray[i]) {
        trackTicks[i].classList.add('tick_collision_z');
      } else {
        trackTicks[i].classList.remove('tick_collision_z');
      }
    }
  }
  detectCollision(thumbLeft, thumbRight, tickLeft, tickRight) {
    const noCollision =
      (thumbLeft < tickLeft && thumbRight < tickLeft) ||
      (thumbLeft > tickRight && thumbRight > tickRight);
    return !noCollision;
  }
  checkTickThumbCollision() {
    var _a, _b, _c;
    // Color the thumb in petrol color if it lands on the tick
    let sliderThumb;
    const trackTicks =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.tick-item');
    if (this.vertical) {
      sliderThumb =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('.verticalTrack');
    } else {
      sliderThumb =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('.sliderTrack');
    }
    sliderThumb === null || sliderThumb === void 0
      ? void 0
      : sliderThumb.classList.remove('ticks_collision');
    for (let i = 0; i < trackTicks.length; i++) {
      trackTicks[i].classList.remove('tick_collision_z');
    }
    for (let i = 0; i < trackTicks.length; i++) {
      if (this.value === this.ticks[i] && !this.disabled) {
        trackTicks[i].classList.add('tick_collision_z');
        sliderThumb === null || sliderThumb === void 0
          ? void 0
          : sliderThumb.classList.add('ticks_collision');
        break;
      }
    }
    if (this.classList.contains('touch-device') && this.vertical) {
      this.verticalTickCollisionForTouchCheck();
    }
  }
  dispatchValueChangeEndEvent() {
    setTimeout(() => dispatchCustomEvent(this, 'value-change-end', { value: this.value }), 0);
  }
};
__decorate([property({ type: String, reflect: true })], SHSlider.prototype, 'label', void 0);
__decorate([property({ type: Number, reflect: true })], SHSlider.prototype, 'value', void 0);
__decorate(
  [
    property({ type: Number, reflect: true }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
  ],
  SHSlider.prototype,
  'step',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-value' })],
  SHSlider.prototype,
  'showValue',
  void 0
);
__decorate([property({ type: Number, reflect: true })], SHSlider.prototype, 'min', void 0);
__decorate([property({ type: Number, reflect: true })], SHSlider.prototype, 'max', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSlider.prototype, 'disabled', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-controls' })],
  SHSlider.prototype,
  'showControls',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'show-range' })],
  SHSlider.prototype,
  'showRange',
  void 0
);
__decorate(
  [property({ type: Number, reflect: true, attribute: 'upper-value' })],
  SHSlider.prototype,
  'upperValue',
  void 0
);
__decorate(
  [property({ type: Number, reflect: true, attribute: 'lower-value' })],
  SHSlider.prototype,
  'lowerValue',
  void 0
);
__decorate([property({ type: Array, reflect: true })], SHSlider.prototype, 'ticks', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSlider.prototype, 'vertical', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'direction-toggle' })],
  SHSlider.prototype,
  'directionToggle',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHSlider.prototype, 'right', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'hide-max' })],
  SHSlider.prototype,
  'hideMax',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'active-track' })],
  SHSlider.prototype,
  'activeTrack',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'active-track-invert' })],
  SHSlider.prototype,
  'activeTrackInvert',
  void 0
);
__decorate([property({ type: Array })], SHSlider.prototype, 'renderedTicks', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHSlider.prototype,
  'ticksRendered',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHSlider.prototype, 'isDrag', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'hide-tooltip' })],
  SHSlider.prototype,
  'hideTooltip',
  void 0
);
SHSlider = __decorate([customElement('sh-slider')], SHSlider);
export { SHSlider };
