import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ControlStateMixin } from '../../utils/control-state-mixin';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin';
import stepperStyles from './sh-stepper.lit.scss.js';
export const stepperItemListOfProperties = [
  'label',
  'info',
  'number',
  'type',
  'active',
  'condensed',
  'color',
];
/**
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires info-changed {InfoPropertyChangedEvent} - *hide emitted when the info property changes.
 * @fires number-changed {NumberPropertyChangedEvent} - *hide emitted when the number property changes.
 * @fires type-changed {TypePropertyChangedEvent} - *hide emitted when the type property changes.
 * @fires active-changed {ActivePropertyChangedEvent} - *hide emitted when the active property changes.
 * @fires condensed-changed {CondensedPropertyChangedEvent} - *hide emitted when the condensed property changes.
 * @fires color-changed {ColorPropertyChangedEvent} - *hide emitted when the color property changes.
 */
let SHStepperItem = class SHStepperItem extends ControlStateMixin(FocusBlurMixin(ShuiLitElement)) {
  constructor() {
    super(...arguments);
    /**Default neutral. Adds the primary or neutral styles as per the value 'primary' or 'neutral'.*/
    this.color = 'neutral';
  }
  static get styles() {
    return [stepperStyles];
  }
  render() {
    return html` ${!this.vertical
      ? html` <div class="line-left"></div>
          <div class="line-right"></div>
          ${this.getCircle()} ${this.getLabelInfo()}`
      : html`
          <div class="vertical-stepper-wrapper">
            <div class="line-top"></div>
            <div class="line-bottom"></div>
            ${this.getCircle()}
            <div class="label-info">${this.getLabelInfo()}</div>
          </div>
        `}`;
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('active')) {
      this.handleActiveChange();
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, stepperItemListOfProperties);
  }
  _spaceKeyAction() {
    super._spaceKeyAction();
    this.click();
  }
  handleActiveChange() {
    if (this.disabled && this.active) {
      this.disabled = false;
    }
  }
  getCircle() {
    return html` <div class="circle focus-item" id="stepperCircle">
      ${this.number && !this.condensed ? html` ${this.number}` : ''}
      ${this.type
        ? html` <sh-icon .icon="${this.type}" class="stepper-type" size="xs"></sh-icon>`
        : ''}
    </div>`;
  }
  getLabelInfo() {
    return html` ${this.label && !this.condensed
      ? html`<sh-text class="label" size="title-1">${this.label}</sh-text>`
      : ''}
    ${this.info ? html`<sh-text class="info" size="body-2">${this.info}</sh-text>` : ''}`;
  }
};
__decorate([event()], SHStepperItem.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHStepperItem.prototype, 'condensedChangedEvent', void 0);
__decorate([event()], SHStepperItem.prototype, 'infoChangedEvent', void 0);
__decorate([event()], SHStepperItem.prototype, 'colorChangedEvent', void 0);
__decorate([event()], SHStepperItem.prototype, 'numberChangedEvent', void 0);
__decorate([event()], SHStepperItem.prototype, 'typeChangedEvent', void 0);
__decorate([event()], SHStepperItem.prototype, 'activeChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHStepperItem.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHStepperItem.prototype, 'info', void 0);
__decorate([property({ type: Number, reflect: true })], SHStepperItem.prototype, 'number', void 0);
__decorate([property({ type: String, reflect: true })], SHStepperItem.prototype, 'type', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHStepperItem.prototype,
  'disabled',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHStepperItem.prototype, 'active', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHStepperItem.prototype,
  'condensed',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHStepperItem.prototype, 'color', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHStepperItem.prototype,
  'vertical',
  void 0
);
SHStepperItem = __decorate([customElement('sh-stepper-item')], SHStepperItem);
export { SHStepperItem };
