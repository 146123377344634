import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2024. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import navbarStyles from './sh-nav-bar.lit.scss.js';
/**
 * @slot - Main slot for navigation items. Takes tabs as children.
 * @slot functions - Holds page functions such as Save, Export, Delete, etc. Takes icons as children.
 */
let SHNavBar = class SHNavBar extends LitElement {
  constructor() {
    super(...arguments);
    /** TODO: add documentation for emptyFunction */
    this.emptyFunction = true;
  }
  static get styles() {
    return [sharedStyles, navbarStyles];
  }
  render() {
    return html`
      <div class="nav-bar-wrapper">
        <div class="tabs-wrapper">
          <slot id="tabs" @slotchange=${() => this.tabsSlotUpdated()}></slot>
        </div>

        <!-- emptyFooter is used to check any slots should be displayed -->

        <div class="functions-wrapper" ?empty-function="${this.emptyFunction}">
          <slot
            name="functions"
            @slotchange="${this.handleSlotNodes.bind(this)}"
            id="functionsSlot"
          ></slot>
        </div>
      </div>
    `;
  }
  firstUpdated() {
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'button');
    }
  }
  tabsSlotUpdated() {
    const tabs = this.querySelectorAll('sh-tabs');
    // trigger nav prop of tabs
    if (this.slot === 'nav') {
      for (const tab of tabs) {
        tab.setAttribute('nav-context', '');
      }
    }
    // trigger footer prop of tabs
    if (this.slot === 'footer') {
      for (const tab of tabs) {
        tab.setAttribute('footer-context', '');
      }
    }
    this.handleCondensed();
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('condensed')) {
      this.handleCondensed();
    }
  }
  handleCondensed() {
    const tabItems = this.querySelectorAll('sh-tab-item');
    const tabs = this.querySelectorAll('sh-tabs');
    tabs.forEach((el) => {
      el.condensed = this.condensed;
    });
    tabItems.forEach((el) => {
      el.condensed = this.condensed;
    });
  }
  handleSlotNodes() {
    var _a;
    const functionSlot =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#functionsSlot');
    const functionNodesLength = functionSlot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFunction = functionNodesLength === 0;
  }
};
__decorate([property({ type: Boolean, reflect: true })], SHNavBar.prototype, 'condensed', void 0);
__decorate([property({ type: Boolean })], SHNavBar.prototype, 'emptyFunction', void 0);
SHNavBar = __decorate([customElement('sh-nav-bar')], SHNavBar);
export { SHNavBar };
