// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
let SHMenuGroup = class SHMenuGroup extends LitElement {
  constructor() {
    super(...arguments);
    /** *hide */
    this.selected = 'defaultId';
    /** *hide */
    this.group = 'defaultGroup';
  }
  static get styles() {
    return [
      css`
        :host {
          width: 100%;
          display: block;
        }

        .menu-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        #grouped {
          flex: 1;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }
  render() {
    return html`
      <div class="menu-wrapper">
        <slot id="grouped" @slotchange="${this.handleSlotChange}"></slot>
      </div>
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'listgroup');
    this._handleMenuItemActiveChange = this.activeHandle.bind(this);
    this.addEventListener('clicked', this._handleMenuItemActiveChange);
  }
  activeHandle() {
    const childElement = this.querySelectorAll('sh-menu-item[active]');
    for (const child of childElement) {
      child.removeAttribute('active');
    }
  }
  handleSlotChange() {
    for (const child of this.children) {
      if (!child.hasAttribute('disabled')) {
        child.setAttribute('tabindex', '0');
      }
    }
  }
  disconnectedCallback() {
    this.removeEventListener('clicked', this._handleMenuItemActiveChange);
    super.disconnectedCallback();
  }
};
__decorate([property({ type: String, reflect: true })], SHMenuGroup.prototype, 'selected', void 0);
__decorate([property({ type: String, reflect: true })], SHMenuGroup.prototype, 'group', void 0);
SHMenuGroup = __decorate([customElement('sh-menu-group')], SHMenuGroup);
export { SHMenuGroup };
