// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { sharedStyles } from '../../styles/shared-styles.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator.js';
import { EventFiredWithinElementChecker } from '../../utils/event-fired-within-element-checker.js';
import toolStyles from './sh-tool.lit.scss.js';
export const toolListOfProperties = [
  'label',
  'value',
  'active',
  'icon',
  'expanded',
  'disabled',
  'flat',
];
/**
 * @fires icon-changed {IconPropertyChangedEvent} - *hide emitted when the icon property changes.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires value-changed {ValuePropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires active-changed {ActivePropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires expanded-changed {ExpandedPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires flat-changed {FlatPropertyChangedEvent} - *hide emitted when the label property changes.
 */
let SHTool = class SHTool extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** Default 'right-up'. Defines the placement of the tool submenu popover as specified.*/
    this.popoverPosition = 'right-up';
    this.clickCount = 0;
  }
  static get styles() {
    return [toolStyles, sharedStyles];
  }
  render() {
    return html`
      ${this.icon || (this.icononly && this.icon !== undefined)
        ? html`<sh-icon icon="${this.icon}" .color="${this.iconColor}" class="tool-icon"></sh-icon>`
        : html``}
      ${this.label && !this.icononly
        ? html`<div class="label-wrapper">
            <sh-text size="${this.value || this.icon ? 'body-2' : 'body-1'}" class="tool-label"
              >${this.label}</sh-text
            >
          </div>`
        : html``}
      ${this.value && this.select && (!this.icononly || !this.icon)
        ? html`<div class="value-wrapper">
            <sh-text size="body-1" class="tool-value">${this.value}</sh-text>
          </div>`
        : html``}
      ${this.palette || this.select ? html`<div class="arrow"></div>` : html``}
      ${this.palette
        ? html`<div class="palette" @click="${(e) => this.expandPalette(e)}"></div>`
        : html``}
      ${this.palette || this.select
        ? html`
            <sh-popover
              id="menu"
              manual-controlled
              .target=${this}
              position=${ifDefined(this.popoverPosition)}
              ?passive="${this.popoverPassive}"
              ?visible="${this.expanded}"
              overlay-target-distance="0"
              suppress-placement-warning
              @click="${(e) => {
                this.checkIfClickedWithinPopoverWrapper(e);
              }}"
              @visible-changed="${(e) => {
                const popover = e.target;
                this.expanded = popover.visible;
              }}"
            >
              <div class="popover-slot-wrapper"><slot></slot></div>
            </sh-popover>
          `
        : html``}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('aria-labelledby') && this.label) {
      this.setAttribute('aria-labelledby', this.label);
    }
    this.addEventListener('click', (e) => {
      const target = e.target;
      if (target.tagName === 'SH-TOOL') {
        if (target.toggle && e.composedPath()[0].className !== 'palette') {
          this.active = !this.active;
        }
        if (this.select) {
          this.expanded = true;
          this.setActiveState();
          this.clickCount++;
          //on even click count , close the popover if it is already open without having to select any option from dropdown.
          this.closeSelect(e);
        }
      }
    });
    this.setInitialValuesOnLoad();
    this.documentClick = this.closeTool.bind(this);
  }
  closeSelect(e) {
    var _a;
    const popover =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.getElementById('menu');
    if (this.expanded && e.type == 'click') {
      //check the counter value & also popover visiblity - make expanded false only if both are true
      if (this.clickCount % 2 == 0 && popover.visible) {
        this.expanded = false;
      }
    }
  }
  firstUpdated() {
    var _a;
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    // listen to dynamic menu-item change
    this.addEventListener('menu-item-active', this.activeChanged);
    // listen to menu-item clicked event
    const menu =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#menu');
    if (menu) {
      menu.addEventListener('clicked', this.activeChanged);
    }
    // remove context menu default behavior
    this.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
    // long touch palette listener
    this.addEventListener('touchstart', function (e) {
      if (this.palette) {
        this.delay = setTimeout(() => {
          this.expandPalette(e);
        }, 500);
      }
    });
    this.addEventListener('touchend', function () {
      clearTimeout(this.delay);
    });
  }
  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('disabled')) {
      this.expanded = false;
    }
    if (changedProperties.has('expanded')) {
      this.handleExpandedChanged();
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, toolListOfProperties);
  }
  handleExpandedChanged() {
    if (this.expanded) {
      document.body.addEventListener('click', this.documentClick, true);
      document.body.addEventListener('touchstart', this.documentClick, true);
    } else {
      document.body.removeEventListener('click', this.documentClick, true);
      document.body.removeEventListener('touchstart', this.documentClick, true);
    }
  }
  disconnectedCallback() {
    if (this.expanded) {
      document.body.removeEventListener('click', this.documentClick, true);
      document.body.removeEventListener('touchstart', this.documentClick, true);
    }
    super.disconnectedCallback();
  }
  closeTool(e) {
    if (e.target !== this && !e.touches) {
      this.expanded = false;
    }
    if (e.touches && e.composedPath().indexOf(this) === -1) {
      this.expanded = false;
    }
  }
  expandPalette(e) {
    if (e) {
      e.stopPropagation();
    }
    this.setActiveState();
    this.expanded = true;
  }
  setActiveState() {
    const childrenItems = this.children;
    for (let i = 0; i < childrenItems.length; i++) {
      if (this.label === childrenItems[i].label || this.value === childrenItems[i].label) {
        childrenItems[i].active = true;
        break;
      }
    }
  }
  setInitialValuesOnLoad() {
    const childrenItems = this.children;
    for (let i = 0; i < this.childElementCount; i++) {
      if (childrenItems[i].active) {
        this.setMenuItemLabelIcon(childrenItems[i]);
      }
    }
  }
  setMenuItemLabelIcon(menuItem) {
    var _a;
    if (!this.select) {
      this.icon = menuItem.icon
        ? menuItem.icon
        : (_a = menuItem.parentNode) === null || _a === void 0
          ? void 0
          : _a.icon;
      if (this.palette) {
        this.label = menuItem.label;
      }
    } else {
      /**
       * If tool is of type 'select' and
       * if the clicked menu item does not have icon,
       * then the tool's value gets updated with the label of the menu-item.
       * hide the icon and show only value and label.
       *
       * if the clicked menu-item has icon,
       * then the tool's label gets updated with the label of the menu-item.
       * hide the value and show only icon and label.
       */
      /*Reset the click counter upon menu selection in case of select type tool*/
      this.clickCount = 0;
      if (!menuItem.icon) {
        this.value = menuItem.label;
      } else {
        this.icon = menuItem.icon;
        this.label = menuItem.label;
      }
    }
  }
  activeChanged(e) {
    const target = e.target;
    const tool = target === null || target === void 0 ? void 0 : target.parentElement;
    if (e.type === 'clicked' && target.tagName === 'SH-MENU-ITEM') {
      e.stopPropagation();
      tool.expanded = false;
    }
    const childrenItems = this.children;
    for (let i = 0; i < childrenItems.length; i++) {
      if (this.label === childrenItems[i].label) {
        childrenItems[i].active = true;
        break;
      }
    }
    tool.unselectSiblings(target.label);
    tool.setMenuItemLabelIcon(e.target);
  }
  unselectSiblings(label) {
    const childrenItems = this.children;
    for (let i = 0; i < this.children.length; i++) {
      if (label !== childrenItems[i].label) {
        this.children[i].removeAttribute('active');
      }
    }
  }
  checkIfClickedWithinPopoverWrapper(e) {
    var _a;
    const target = e.currentTarget;
    if (target === null || target === void 0 ? void 0 : target.mobile) {
      const eventFiredWithinChecker = new EventFiredWithinElementChecker();
      const { left, right, top, bottom } = (
        (_a = target.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.popover-wrapper')
      ).getBoundingClientRect();
      const clickedWithinPopoverWrapper = eventFiredWithinChecker.checkClickEvent(
        e,
        left,
        top,
        right,
        bottom
      );
      if (!clickedWithinPopoverWrapper) {
        this.expanded = false;
      }
    }
  }
};
__decorate([event()], SHTool.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHTool.prototype, 'valueChangedEvent', void 0);
__decorate([event()], SHTool.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHTool.prototype, 'expandedChangedEvent', void 0);
__decorate([event()], SHTool.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHTool.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHTool.prototype, 'flatChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHTool.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHTool.prototype, 'value', void 0);
__decorate([property({ type: String, reflect: true })], SHTool.prototype, 'icon', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'icononly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'select', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'palette', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'toggle', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'expanded', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'disabled', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'condensed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'stretch', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTool.prototype, 'flat', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHTool.prototype,
  'iconColor',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'popover-position' })],
  SHTool.prototype,
  'popoverPosition',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'popover-passive' })],
  SHTool.prototype,
  'popoverPassive',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'inverted-active' })],
  SHTool.prototype,
  'invertedActive',
  void 0
);
SHTool = __decorate([customElement('sh-tool')], SHTool);
export { SHTool };
