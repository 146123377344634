// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { getFlippedAlgorithm } from './overlay-placement-flipped-algorithm';
import { getUnFlippedAlgorithm } from './overlay-placement-unflipped-algorithm';
import { convertToPixel } from './rem-to-pixel-convertor';
export function getOverlayPlacementInfo(
  placement,
  overlayRect,
  targetRect,
  viewportRect,
  overlayTargetDistance,
  viewportPadding,
  flippedAlgorithm,
  unflippedAlgorithm,
  suppressPlacementWarning = false
) {
  var _a, _b;
  getComputedStyle(document.documentElement); // forcing pending style updates to complete
  let actualViewport;
  ({ overlayRect, targetRect, actualViewport } = getRectParameters(
    viewportRect,
    targetRect,
    overlayRect,
    viewportPadding
  ));
  const { overlayTop, overlayLeft, overlayRight, overlayBottom } = getPlacementInfo(
    placement,
    targetRect,
    overlayRect,
    overlayTargetDistance
  );
  let requiredLeft = overlayLeft;
  let requiredTop = overlayTop;
  let hasFoundIdeal = false;
  const overlayWillBeWithinViewport = isWithinViewport(
    overlayTop,
    overlayLeft,
    overlayRight,
    overlayBottom,
    actualViewport
  );
  const targetIsOutsideViewport = isTargetOutsideViewport(targetRect, actualViewport);
  // if overlay's new position is outside viewport
  if (!overlayWillBeWithinViewport) {
    const overlayPlacementAlgorithmOutput = overlayPlacementAlgorithm(
      placement,
      requiredLeft,
      requiredTop,
      targetRect,
      overlayRect,
      overlayTargetDistance,
      actualViewport,
      flippedAlgorithm,
      unflippedAlgorithm
    );
    hasFoundIdeal = overlayPlacementAlgorithmOutput.hasFoundIdeal;
    if (!hasFoundIdeal) {
      const correctedPlacementWrtViewportInfo = getCorrectedCurrentPlacementWRTViewport(
        placement,
        targetRect,
        overlayRect,
        overlayTargetDistance,
        actualViewport
      );
      if (!suppressPlacementWarning) {
        console.warn(
          'Could not find an ideal position within the viewport for the overlay without overlap with the target\n\nSo trying to fit the overlay within the viewport',
          '\nby correcting for the given placement: \t',
          placement,
          '\n\nNote: The target will be overlapped.' +
            '\n\n' +
            "Suggestion: Try to place the target in some other position within the viewport OR try to reduce the overlay's width and height"
        );
      }
      requiredLeft =
        (_a =
          correctedPlacementWrtViewportInfo === null || correctedPlacementWrtViewportInfo === void 0
            ? void 0
            : correctedPlacementWrtViewportInfo.placementInfo) === null || _a === void 0
          ? void 0
          : _a.overlayLeft;
      requiredTop =
        (_b =
          correctedPlacementWrtViewportInfo === null || correctedPlacementWrtViewportInfo === void 0
            ? void 0
            : correctedPlacementWrtViewportInfo.placementInfo) === null || _b === void 0
          ? void 0
          : _b.overlayTop;
      if (
        !(correctedPlacementWrtViewportInfo === null || correctedPlacementWrtViewportInfo === void 0
          ? void 0
          : correctedPlacementWrtViewportInfo.foundIdealWrtViewport) &&
        !suppressPlacementWarning
      ) {
        console.warn(
          'Could not fit the Overlay within the Viewport.\n\n',
          'Are you sure the overlay width and/or height is less than effective viewport width (viewport width -' +
            convertToPixel(viewportPadding) +
            'px ) and/or effective viewport height (viewport height -' +
            convertToPixel(viewportPadding) +
            'px ) ?'
        );
      }
    } else {
      requiredLeft = overlayPlacementAlgorithmOutput.idealLeft;
      requiredTop = overlayPlacementAlgorithmOutput.idealTop;
    }
  }
  if (targetIsOutsideViewport && !suppressPlacementWarning) {
    console.warn(
      'The effective target (target boundary + ' +
        convertToPixel(overlayTargetDistance) +
        'px margin around it) is outside the effective viewport (viewport boundary - ' +
        convertToPixel(viewportPadding) +
        'px inner padding)'
    );
  }
  return { requiredLeft, requiredTop, targetIsOutsideViewport };
}
export function getRectParameters(viewportRect, targetRect, overlayRect, viewportPadding) {
  viewportRect = JSON.parse(JSON.stringify(viewportRect));
  targetRect = JSON.parse(JSON.stringify(targetRect));
  overlayRect = JSON.parse(JSON.stringify(overlayRect));
  const viewportRectLeft = viewportRect.left + convertToPixel(viewportPadding);
  const viewportRectRight = viewportRect.right - convertToPixel(viewportPadding);
  const viewportRectTop = viewportRect.top + convertToPixel(viewportPadding);
  const viewportRectBottom = viewportRect.bottom - convertToPixel(viewportPadding);
  const actualViewport = {
    left: viewportRectLeft,
    right: viewportRectRight,
    top: viewportRectTop,
    bottom: viewportRectBottom,
    width: viewportRectRight - viewportRectLeft,
    height: viewportRectBottom - viewportRectTop,
  };
  return { overlayRect, targetRect, actualViewport };
}
export function getPlacementInfo(placement, targetRect, overlayRect, overlayTargetDistance) {
  let overlayTop = 0;
  let overlayLeft = 0;
  let overlayRight = 0;
  let overlayBottom = 0;
  switch (placement) {
    case 'top':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height) -
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) +
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.width) / 2 -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width) / 2;
      }
      break;
    case 'top-left':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height) -
          convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect === null || targetRect === void 0 ? void 0 : targetRect.left;
      }
      break;
    case 'top-right':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height) -
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width);
      }
      break;
    case 'bottom':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) +
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) +
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.width) / 2 -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width) / 2;
      }
      break;
    case 'bottom-left':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) +
          convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect === null || targetRect === void 0 ? void 0 : targetRect.left;
      }
      break;
    case 'bottom-right':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) +
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width);
      }
      break;
    case 'left':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) +
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.height) / 2 -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height) / 2;
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width) -
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'left-up':
      {
        overlayTop = targetRect === null || targetRect === void 0 ? void 0 : targetRect.top;
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width) -
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'left-down':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width) -
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'right':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) +
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.height) / 2 -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height) / 2;
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) +
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'right-up':
      {
        overlayTop = targetRect === null || targetRect === void 0 ? void 0 : targetRect.top;
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) +
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'right-down':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) +
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'top-diag-left':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height) -
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width) -
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'top-diag-right':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height) -
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) +
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'bottom-diag-left':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) +
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) -
          (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width) -
          convertToPixel(overlayTargetDistance);
      }
      break;
    case 'bottom-diag-right':
      {
        overlayTop =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) +
          convertToPixel(overlayTargetDistance);
        overlayLeft =
          (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) +
          convertToPixel(overlayTargetDistance);
      }
      break;
  }
  overlayTop = Math.round(overlayTop);
  overlayLeft = Math.round(overlayLeft);
  overlayRight =
    overlayLeft + (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width);
  overlayBottom =
    overlayTop + (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height);
  return { overlayTop, overlayLeft, overlayRight, overlayBottom };
}
export function overlayPlacementAlgorithm(
  placement,
  left,
  top,
  targetRect,
  overlayRect,
  overlayTargetDistance,
  actualViewport,
  flippedAlgorithm,
  unflippedAlgorithm
) {
  let hasFoundIdeal = false,
    idealLeft = left,
    idealTop = top;
  const algorithmSteps = getAlgorithmSteps(
    placement,
    satisfiesFlipCondition(
      placement,
      targetRect,
      overlayRect,
      overlayTargetDistance,
      actualViewport
    ),
    flippedAlgorithm,
    unflippedAlgorithm
  );
  for (
    let step = 0;
    step < (algorithmSteps === null || algorithmSteps === void 0 ? void 0 : algorithmSteps.length);
    ++step
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const algorithmStep = algorithmSteps[step];
    const fromPlacement =
      algorithmStep === null || algorithmStep === void 0 ? void 0 : algorithmStep.from;
    const toPlacement =
      algorithmStep === null || algorithmStep === void 0 ? void 0 : algorithmStep.to;
    const incrementValue =
      algorithmStep === null || algorithmStep === void 0 ? void 0 : algorithmStep.increment;
    const incrementBy =
      algorithmStep === null || algorithmStep === void 0 ? void 0 : algorithmStep.incrementBy;
    const fromPlacementData = getPlacementInfo(
      fromPlacement,
      targetRect,
      overlayRect,
      overlayTargetDistance
    );
    const toPlacementData = getPlacementInfo(
      toPlacement,
      targetRect,
      overlayRect,
      overlayTargetDistance
    );
    for (
      let i = fromPlacementData[incrementValue];
      incrementBy === 1 ? i < toPlacementData[incrementValue] : i > toPlacementData[incrementValue];
      incrementBy === 1 ? ++i : --i
    ) {
      const overlayData = getOtherOverlaySides(incrementValue, i, fromPlacementData, overlayRect);
      if (
        isWithinViewport(
          overlayData === null || overlayData === void 0 ? void 0 : overlayData.overlayTop,
          overlayData === null || overlayData === void 0 ? void 0 : overlayData.overlayLeft,
          overlayData === null || overlayData === void 0 ? void 0 : overlayData.overlayRight,
          overlayData === null || overlayData === void 0 ? void 0 : overlayData.overlayBottom,
          actualViewport
        )
      ) {
        idealLeft =
          overlayData === null || overlayData === void 0 ? void 0 : overlayData.overlayLeft;
        idealTop = overlayData === null || overlayData === void 0 ? void 0 : overlayData.overlayTop;
        hasFoundIdeal = true;
        break;
      }
    }
    if (hasFoundIdeal) {
      break;
    }
  }
  return { hasFoundIdeal, idealTop, idealLeft };
}
export function isWithinViewport(top, left, right, bottom, actualViewport) {
  return (
    left >= (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.left) &&
    right <=
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.right) &&
    top >= (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.top) &&
    bottom <=
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.bottom)
  );
}
export function satisfiesFlipCondition(
  placement,
  targetRect,
  overlayRect,
  overlayTargetDistance,
  actualViewport
) {
  const placementInfo = getPlacementInfo(placement, targetRect, overlayRect, overlayTargetDistance);
  const flipConditionDictionary = {
    top:
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayTop) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.top),
    bottom:
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayBottom) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.bottom),
    left:
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayLeft) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.left),
    right:
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayRight) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.right),
    'top-left':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayTop) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.top),
    'top-right':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayTop) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.top),
    'bottom-left':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayBottom) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.bottom),
    'bottom-right':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayBottom) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.bottom),
    'left-up':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayLeft) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.left),
    'left-down':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayLeft) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.left),
    'right-up':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayRight) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.right),
    'right-down':
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayRight) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.right),
  };
  return flipConditionDictionary[placement];
}
export function getCorrectedCurrentPlacementWRTViewport(
  placement,
  targetRect,
  overlayRect,
  overlayTargetDistance,
  actualViewport
) {
  let foundIdealWrtViewport = false;
  let placementInfo = getPlacementInfo(placement, targetRect, overlayRect, overlayTargetDistance);
  const viewportCorrectionOrder = getViewportCorrectionOrder(placement);
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[0],
    placementInfo,
    overlayRect,
    actualViewport
  );
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[1],
    placementInfo,
    overlayRect,
    actualViewport
  );
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[2],
    placementInfo,
    overlayRect,
    actualViewport
  );
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[3],
    placementInfo,
    overlayRect,
    actualViewport
  );
  foundIdealWrtViewport = isWithinViewport(
    placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayTop,
    placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayLeft,
    placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayRight,
    placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayBottom,
    actualViewport
  );
  return { foundIdealWrtViewport, placementInfo };
}
function isTargetOutsideViewport(targetRect, actualViewport) {
  return (
    (targetRect === null || targetRect === void 0 ? void 0 : targetRect.right) <=
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.left) ||
    (targetRect === null || targetRect === void 0 ? void 0 : targetRect.left) >=
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.right) ||
    (targetRect === null || targetRect === void 0 ? void 0 : targetRect.bottom) <=
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.top) ||
    (targetRect === null || targetRect === void 0 ? void 0 : targetRect.top) >=
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.bottom)
  );
}
function getOtherOverlaySides(incrementSide, incrementValue, placementData, overlayRect) {
  const overlaySidesDictionary = {
    overlayLeft: {
      overlayTop: placementData.overlayTop,
      overlayLeft: incrementValue,
    },
    overlayTop: {
      overlayTop: incrementValue,
      overlayLeft: placementData.overlayLeft,
    },
    overlayRight: {
      overlayTop: placementData.overlayTop,
      overlayLeft:
        incrementValue -
        (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width),
    },
    overlayBottom: {
      overlayTop:
        incrementValue -
        (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height),
      overlayLeft: placementData.overlayLeft,
    },
  };
  const topLeftData = overlaySidesDictionary[incrementSide];
  const overlayRight =
    (topLeftData === null || topLeftData === void 0 ? void 0 : topLeftData.overlayLeft) +
    (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.width);
  const overlayBottom =
    (topLeftData === null || topLeftData === void 0 ? void 0 : topLeftData.overlayTop) +
    (overlayRect === null || overlayRect === void 0 ? void 0 : overlayRect.height);
  return Object.assign(Object.assign({}, topLeftData), { overlayRight, overlayBottom });
}
function getAlgorithmSteps(placement, flipped, flippedAlgorithm, unflippedAlgorithm) {
  let algorithm;
  if (flipped) {
    algorithm = flippedAlgorithm || getFlippedAlgorithm(placement);
  } else {
    algorithm = unflippedAlgorithm || getUnFlippedAlgorithm(placement);
  }
  return algorithm;
}
function correctToViewportForSide(side, placementInfo, overlayRect, actualViewport) {
  if (side === 'overlayTop') {
    if (
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayTop) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.top)
    ) {
      placementInfo.overlayTop =
        actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.top;
    }
  }
  if (side === 'overlayRight') {
    if (
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayRight) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.right)
    ) {
      placementInfo.overlayRight =
        actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.right;
    }
  }
  if (side === 'overlayLeft') {
    if (
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayLeft) <
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.left)
    ) {
      placementInfo.overlayLeft =
        actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.left;
    }
  }
  if (side === 'overlayBottom') {
    if (
      (placementInfo === null || placementInfo === void 0 ? void 0 : placementInfo.overlayBottom) >
      (actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.bottom)
    ) {
      placementInfo.overlayBottom =
        actualViewport === null || actualViewport === void 0 ? void 0 : actualViewport.bottom;
    }
  }
  placementInfo = getOtherOverlaySides(side, placementInfo[side], placementInfo, overlayRect);
  return placementInfo;
}
function getViewportCorrectionOrder(placement) {
  let order = [];
  switch (placement) {
    case 'top':
    case 'top-left':
    case 'top-right':
      order = ['overlayTop', 'overlayRight', 'overlayBottom', 'overlayLeft'];
      break;
    case 'bottom':
    case 'bottom-left':
    case 'bottom-right':
      order = ['overlayBottom', 'overlayLeft', 'overlayTop', 'overlayRight'];
      break;
    case 'left':
    case 'left-up':
    case 'left-down':
      order = ['overlayLeft', 'overlayTop', 'overlayRight', 'overlayBottom'];
      break;
    case 'right':
    case 'right-up':
    case 'right-down':
      order = ['overlayRight', 'overlayBottom', 'overlayLeft', 'overlayTop'];
      break;
  }
  return order;
}
