// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __awaiter, __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { convertToPixel } from '../../utils/rem-to-pixel-convertor.js';
import scrollpickerStyles from './sh-scrollpicker.lit.scss.js';
export const scrollpickerListOfProperties = ['visible'];
/**
 * Component that allows to select from a set of values by swiping.
 * Comes with a drawer component.
 * @fires visible-changed {VisiblePropertyChangedEvent} - *hide Dispatched when the visible property is changed.
 * @fires values-changed - *hide Dispatched when the values property is changed.
 * @fires scroll-changed - *hide Dispatched when the autoScroll property is changed.
 * @fires scrollpicker-closed - *hide Dispatched when the scrollpicker is closed.
 */
let SHScrollpicker = class SHScrollpicker extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**The total items of scrollpicker. The number of scrollers is decided by
     * the length of this array. What to show in each scroller is decided by
     * the arrays within the items array. */
    this.items = [];
    /**The visible property of scrollpicker. Set this to true or false to
     * open/close the drawer within. Also the visible changed event can be
     * listened when visible is set to false to remove scrollpicker from DOM
     * when not required. */
    this.visible = false;
    /**The selected values of scrollpicker. Set this array to scroll to those
     * values. */
    this.values = [];
    /**The individual styles for each row of each scroller. */
    this.scrollerStyles = [];
    /**Set this true to scroll to the values set in the array. */
    this.autoScroll = false;
    /**Set this true to display content inside a container i.e(div). */
    this.defaultVisible = false;
    this.documentBodyClickListener = this.documentBodyClickActions.bind(this);
    /**@ignore */
    this.rowClicklistener = {
      handleEvent(e) {
        var _a, _b;
        const target = e.target;
        (_b =
          (_a = target.previousElementSibling) === null || _a === void 0
            ? void 0
            : _a.previousElementSibling) === null || _b === void 0
          ? void 0
          : _b.scrollIntoView({
              behavior: 'smooth',
            });
      },
      passive: true,
    };
    /**@ignore */
    this._previousValues = [];
  }
  static get styles() {
    return [scrollpickerStyles];
  }
  connectedCallback() {
    super.connectedCallback();
    document.body.addEventListener('click', this.documentBodyClickListener);
    this.resizeObserver = new ResizeObserver(() => {
      this.requestUpdate();
    });
    this.resizeObserver.observe(this);
  }
  render() {
    return html`${this.defaultVisible
      ? html`<div class="parent-container">${this.defaultView()}</div>`
      : html`<sh-drawer
          position="bottom"
          class="point5-vertical-padding initial-slide-on-connect"
          .visible="${this.visible}"
          @transitionend="${(e) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const drawer = e.target;
            if (!drawer.visible) {
              this.visible = false;
            }
          }}"
          >${this.defaultView()}</sh-drawer
        >`}`;
  }
  defaultView() {
    return html`
      <div class="main-container">
        <div class="selector"></div>
        <div class="scrollers-container">${this.getScrollerDOM()}</div>
      </div>
    `;
  }
  update(changedProperties) {
    if (
      changedProperties.has('values') &&
      this.values &&
      this.values.length > 0 &&
      this.visible &&
      JSON.stringify(this._previousValues) !== JSON.stringify(this.values)
    ) {
      this._previousValues = this.values;
      this.valuesChangedEvent.emit(new CustomEvent('values-changed'));
    }
    if (changedProperties.has('autoScroll')) {
      if (this.autoScroll) {
        this.scrollToValues();
      }
      this.scrollChangedEvent.emit(new CustomEvent('scroll-changed'));
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, scrollpickerListOfProperties);
  }
  disconnectedCallback() {
    var _a;
    document.body.removeEventListener('click', this.documentBodyClickListener);
    (_a = this.resizeObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
    super.disconnectedCallback();
  }
  /**
   * Function to scroll to the values specified
   * in the `values` array
   * @param {Array} values - the array of specified values
   * to which the scrollpicker has to scroll.
   */
  scrollToValues() {
    return __awaiter(this, arguments, void 0, function* (values = this.values) {
      if (values.length !== this.items.length) {
        console.warn('Items and values array should have same length');
      }
      yield this.updateComplete;
      this.scrollAllScrollersToValues(values);
      this.autoScroll = false;
    });
  }
  getScrollerDOM() {
    const DOM = [];
    if (this.items && this.items.length > 0) {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        DOM.push(
          html`<div
            class="scroll-container"
            @scroll="${(e) => {
              this.scrollerScrollTasks(e);
            }}"
          >
            <div class="row no-click"></div>
            <div class="row no-click"></div>
            ${this.getScrollDOMArray(item, i)}
            <div class="row no-click"></div>
            <div class="row no-click"></div>
          </div>`
        );
      }
    }
    return DOM;
  }
  scrollerScrollTasks(e) {
    const target = e.target;
    clearTimeout(target._scrollCorrectTimeout);
    const scroller = e.target;
    scroller === null || scroller === void 0 ? void 0 : scroller.setAttribute('is-scrolling', '');
    target._scrollCorrectTimeout = setTimeout(
      this.scrollerScrollEndTasks.bind(this),
      300,
      scroller
    );
  }
  setScrollerSelectedValue(scroller) {
    var _a, _b;
    const ROW_HEIGHT_IN_REM = this.getScrollerRowHeight();
    const arr = (_a = scroller.parentNode) === null || _a === void 0 ? void 0 : _a.children;
    const scrollerIndex =
      (_b = Array.from(arr)) === null || _b === void 0 ? void 0 : _b.indexOf(scroller);
    const scrollTop = scroller.scrollTop;
    const rowHeightInPx = convertToPixel(ROW_HEIGHT_IN_REM);
    scroller._selectedValue = this.items[scrollerIndex][Math.round(scrollTop / rowHeightInPx)];
  }
  updateValues() {
    var _a, _b;
    const arr =
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.scrollers-container')) === null || _b === void 0
        ? void 0
        : _b.children;
    const scrollers = Array.from(arr);
    const newValues = [];
    for (let i = 0; i < scrollers.length; i++) {
      const scroller = scrollers[i];
      this.setScrollerSelectedValue(scroller);
      newValues[i] = scroller._selectedValue;
    }
    const newValuesStr = newValues.join('');
    const valuesStr = this.values.join('');
    if (newValuesStr !== valuesStr) {
      this.values = newValues;
    }
  }
  scrollerScrollEndTasks(scroller) {
    if ((this.getBoundingClientRect().height && this.defaultVisible) || !this.defaultVisible) {
      clearTimeout(scroller._scrollCorrectTimeout);
      const ROW_HEIGHT_IN_REM = this.getScrollerRowHeight();
      const scrollTop = scroller.scrollTop;
      const rowHeightInPx = convertToPixel(ROW_HEIGHT_IN_REM);
      if (this.scrollerHasNotStoppedAtMiddle(scrollTop, rowHeightInPx)) {
        this.nudgeScroller(scroller, scrollTop);
      } else {
        scroller.removeAttribute('is-scrolling');
        scroller.blur();
        if (this.hasAllScrollersStopped()) {
          this.updateValues();
        }
      }
    }
  }
  nudgeScroller(scroller, scrollTop) {
    scroller.scrollTop = scrollTop;
  }
  scrollerHasNotStoppedAtMiddle(scrollTop, rowHeight) {
    return scrollTop % rowHeight !== 0;
  }
  hasAllScrollersStopped() {
    var _a, _b;
    const arr =
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.scrollers-container')) === null || _b === void 0
        ? void 0
        : _b.children;
    const scrollers = Array.from(arr);
    let scrollingScrollerCount = 0;
    for (let i = 0; i < scrollers.length; i++) {
      const scroller = scrollers[i];
      if (scroller.hasAttribute('is-scrolling')) {
        ++scrollingScrollerCount;
        break;
      }
    }
    return scrollingScrollerCount === 0;
  }
  scrollAllScrollersToValues(values) {
    var _a, _b;
    const arr =
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.scrollers-container')) === null || _b === void 0
        ? void 0
        : _b.children;
    const scrollers = Array.from(arr);
    let staticScrollerCount = 0;
    for (let i = 0; i < values.length; i++) {
      const ROW_HEIGHT_IN_REM = this.getScrollerRowHeight();
      const value = values[i];
      const valueIndex = this.getValueIndexForColumn(i, value);
      const rowHeightInPx = convertToPixel(ROW_HEIGHT_IN_REM);
      const selectedValueScrollTop = valueIndex * rowHeightInPx;
      const scroller = scrollers[i];
      if (scroller) {
        if (scroller.scrollTop !== selectedValueScrollTop) {
          this.setScrollerScrollTop(selectedValueScrollTop, scroller);
        } else {
          ++staticScrollerCount;
        }
      }
    }
    if (staticScrollerCount === scrollers.length) {
      this.updateValues();
    }
  }
  getValueIndexForColumn(scrollerIndex, selectedValue) {
    var _a;
    return (_a = this.items[scrollerIndex]) === null || _a === void 0
      ? void 0
      : _a.indexOf(selectedValue);
  }
  setScrollerScrollTop(scrollTop, scroller) {
    scroller.scrollTop = scrollTop;
  }
  getScrollerRowHeight() {
    const scrollpickerScale = getComputedStyle(this).getPropertyValue('--current-scale').trim();
    if (scrollpickerScale === 's') {
      return 2.5;
    } else if (scrollpickerScale === 'm') {
      return 3;
    } else if (scrollpickerScale === 'l') {
      return 3.5;
    } else {
      return 4.5;
    }
  }
  documentBodyClickActions(e) {
    if (e.composedPath().indexOf(this) === -1 && e.composedPath().indexOf(this.attachNode) === -1) {
      this.scrollpickerClosedEvent.emit(new CustomEvent('scrollpicker-closed'));
    }
  }
  getScrollDOMArray(item, scrollerIndex) {
    const itemLength = item.length;
    const itemDOM = [];
    for (let i = 0; i < itemLength; i++) {
      itemDOM.push(
        html`<div
          class="row
            ${this.values[scrollerIndex] && this.values[scrollerIndex] === item[i]
            ? ' selected'
            : ''}"
          @click="${this.rowClicklistener}"
          data-value="${item[i]}"
          style="${this.scrollerStyles[scrollerIndex] ? this.scrollerStyles[scrollerIndex] : ''}"
        >
          ${item[i]}
        </div>`
      );
    }
    return itemDOM;
  }
};
__decorate([property({ type: Array })], SHScrollpicker.prototype, 'items', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHScrollpicker.prototype,
  'visible',
  void 0
);
__decorate([property({ type: Array, reflect: true })], SHScrollpicker.prototype, 'values', void 0);
__decorate(
  [property({ type: Array, reflect: true })],
  SHScrollpicker.prototype,
  'scrollerStyles',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'scroll' })],
  SHScrollpicker.prototype,
  'autoScroll',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'default-visible' })],
  SHScrollpicker.prototype,
  'defaultVisible',
  void 0
);
__decorate([property({ type: Object })], SHScrollpicker.prototype, 'attachNode', void 0);
__decorate([event()], SHScrollpicker.prototype, 'visibleChangedEvent', void 0);
__decorate([event()], SHScrollpicker.prototype, 'valuesChangedEvent', void 0);
__decorate([event()], SHScrollpicker.prototype, 'scrollChangedEvent', void 0);
__decorate([event()], SHScrollpicker.prototype, 'scrollpickerClosedEvent', void 0);
SHScrollpicker = __decorate([customElement('sh-scrollpicker')], SHScrollpicker);
export { SHScrollpicker };
