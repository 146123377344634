// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-card.lit.scss INSTEAD!
import { css } from 'lit';
export default css`[sh-scale=s] {
  /* 
   * @cssprop [--sh-card-border-radius=var(--sh-radius-container)] - card border radius
   */
  --sh-card-border-radius: var(--sh-radius-container);
  /* 
   * @cssprop [--sh-card-label-height=1.5rem] - card label height
   */
  --sh-card-label-height: 1.5rem;
  /* 
   * @cssprop [--sh-card-padding=1rem] - card padding
   */
  --sh-card-padding: 1rem;
  /* 
   * @cssprop [--sh-card-icon-margin-right=0.5rem] -  card icon margin right
   */
  --sh-card-icon-margin-right: 0.5rem;
  /* 
   * @cssprop [--sh-card-icon-line-height=1.5rem] - card icon line height
   */
  --sh-card-icon-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-card-icon-width=2rem] - card icon width
   */
  --sh-card-icon-width: 2rem;
  /* 
   * @cssprop [--sh-card-header-wrapper-padding=1rem] - card header wrapperpadding
   */
  --sh-card-header-wrapper-padding: 1rem;
  /* 
   * @cssprop [--sh-card-slot-not-name-line-height=1.25rem] - line height of the slot in card whivh is not name
   */
  --sh-card-slot-not-name-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-card-header-tab-margin-top=-1rem] - margin top for header slot slotted tab
   */
  --sh-card-header-tab-margin-top: -1rem;
  /* 
   * @cssprop [--sh-card-empty-header-not-empty-content-padding=1rem] - padding top for slot which is not name and is empty header and has content
   */
  --sh-card-empty-header-not-empty-content-padding: 1rem;
  /* 
   * @cssprop [--sh-card-empty-footer-not-empty-content-padding=1rem] - padding bottom for slot which is not name and is empty footer and has content
   */
  --sh-card-empty-footer-not-empty-content-padding: 1rem;
  /* 
   * @cssprop [--sh-card-image-icon-label-wrapper-padding=0.5rem] - padding bottom between image icon and label wrapper
   */
  --sh-card-image-icon-label-wrapper-padding: 0.5rem;
  /* 
   * @cssprop [--sh-card-footer-margin=0.5rem] - margin  bottom for slotted footer not first child
   */
  --sh-card-footer-margin: 0.5rem;
  /* 
   * @cssprop [--sh-card-footer-margin-left-not-first-child=0.5rem] - margin -left for slotted footer not first child
   */
  --sh-card-footer-margin-left-not-first-child: 0.5rem;
  /* 
   * @cssprop [--sh-card-footer-not-empty-margin=0.5rem] - margin for slotted footer not empty and not image
   */
  --sh-card-footer-not-empty-margin: 0.5rem;
  /* 
   * @cssprop [--sh-card-footer-not-empty-padding=0.5rem] - padding for slotted footer not empty and not image
   */
  --sh-card-footer-not-empty-padding: 0.5rem;
  /* 
   * @cssprop [--sh-card-label-icon-badge-margin=0rem] - margin for label
   */
  --sh-card-label-icon-badge-margin: 0rem;
  /* 
   * @cssprop [--sh-card-label-padding=1rem] - card label padding
   */
  --sh-card-label-padding: 1rem;
  /* 
   * @cssprop [--sh-card-icon-font-size-l=2.5rem] - applied to interllay used icon as --sh-icon-font-size-l
   */
  --sh-card-icon-font-size-l: 2.5rem;
  /* 
   * @cssprop [--sh-card-href-not-image-not-empty-footer-slot-margin=0 1rem] - card with link empty footer margin
   */
  --sh-card-href-not-image-not-empty-footer-slot-margin: 0 1rem;
  /* 
   * @cssprop [--sh-card-href-not-image-not-empty-footer-slot-padding=1rem 0] - card with link empty footer padding
   */
  --sh-card-href-not-image-not-empty-footer-slot-padding: 1rem 0;
  /* 
   * @cssprop [--sh-card-href-label-padding=0] - card with link  padding
   */
  --sh-card-href-label-padding: 0;
}

:host {
  width: 100%;
}

.card-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--sh-card-border-radius);
  background-color: var(--base-3);
  box-shadow: var(--shadow-raised);
  transition: var(--transition-time) all ease-in-out;
}

.label-wrapper {
  display: flex;
  flex-direction: row;
  padding: var(--sh-card-padding);
  align-items: center;
  min-height: 1.5rem;
}

:host([label-rows=auto]) .label-wrapper,
:host([label-rows=auto]) slot[name=functions] {
  align-items: unset;
}

sh-icon.card-icon {
  margin-right: var(--sh-card-icon-margin-right);
}

.card-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--header-2);
  font-weight: bold;
  line-height: var(--sh-card-label-height) !important;
  flex: 1;
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  max-width: 100%;
  margin: var(--sh-card-label-icon-badge-margin);
  word-break: break-all;
  padding: var(--sh-card-label-padding);
}

/* slots */
slot {
  display: flex;
}

.header-wrapper,
slot[name=footer] {
  padding: var(--sh-card-header-wrapper-padding);
}

slot[name=functions] {
  align-items: center;
}

.header-wrapper {
  height: fit-content;
  overflow: hidden;
}

slot:not([name]) {
  padding: var(--sh-card-slot-not-name-padding);
  flex: 1;
  box-sizing: border-box;
  flex-direction: column;
  overflow: auto;
  font: var(--body-1);
  color: var(--text-primary);
  line-height: var(--sh-card-slot-not-name-line-height);
}

slot[name=footer] {
  height: fit-content;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

slot[name=header]::slotted(sh-tabs) {
  margin-top: var(--sh-card-header-tab-margin-top);
}

slot:not([name]).empty-header:not(.empty-content) {
  padding-top: var(--sh-card-empty-header-not-empty-content-padding);
}

slot:not([name]).empty-footer:not(.empty-content) {
  padding-bottom: var(--sh-card-empty-footer-not-empty-content-padding);
}

slot.empty,
.header-wrapper.empty {
  padding: 0rem;
}

/* image */
.image-wrapper {
  height: 0rem;
  padding-top: 56.25%;
  position: relative;
  background-color: rgba(var(--ui-1), var(--opacity-7));
  overflow: hidden;
  border-top-left-radius: var(--sh-card-border-radius);
  border-top-right-radius: var(--sh-card-border-radius);
}

.image-wrapper sh-icon,
.image-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.image-wrapper sh-icon {
  --sh-icon-size-m: 4rem;
  --sh-icon-font-size-m: 4rem;
}

img {
  height: 100%;
  width: 100%;
  display: block;
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
}

:host([image-contain]) img {
  object-fit: contain;
}

:host([image][href]) .label-wrapper,
:host([image]) .label-wrapper,
:host([image-icon]) .label-wrapper {
  padding-bottom: var(--sh-card-image-icon-label-wrapper-padding);
}

/* Content Spacing */
slot:not([name])::slotted(:not(:last-child)) {
  margin-bottom: var(--sh-card-footer-margin);
}

slot[name=functions]::slotted(*),
slot[name=footer]::slotted(*:not(:first-child)) {
  margin-left: var(--sh-card-footer-margin-left-not-first-child);
}

slot[name=functions]::slotted(:first-child) {
  margin-left: var(--sh-card-functions-slot-margin-left-first-child);
}

slot:not([name])::slotted(sh-list-item),
slot:not([name])::slotted(sh-menu-item) {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

/* href */
:host([href]) {
  cursor: pointer;
  display: block;
  height: inherit;
  outline: none;
}

:host([href]:hover) .card-wrapper {
  box-shadow: var(--shadow-overlay);
}

:host([href]) .card-wrapper::before {
  pointer-events: none;
  transition: var(--transition-time) all ease-in-out;
  content: "";
  border-radius: var(--sh-card-border-radius);
  height: 100%;
  width: 100%;
  position: absolute;
}

.card-badge {
  position: absolute;
  left: calc(100% - 0.25rem);
  bottom: calc(100% - 0.25rem);
  transform: translate(-50%, 50%);
}

.icon-badge-wrapper {
  position: relative;
  margin: var(--sh-card-label-icon-badge-margin);
}

:host([href]) .icon-badge-wrapper {
  position: relative;
  margin: 0;
}

:host([href]:hover) .card-wrapper::before {
  background-color: rgba(var(--ui-5), var(--opacity-7));
}

:host([href]:not([image])) .label-wrapper.empty-content {
  padding: var(--sh-card-href-label-wrapper-empty-content-not-image-padding);
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

:host([href]:not([image])) .label-wrapper.empty-content:not(.empty-footer) {
  padding-bottom: 0rem;
}

:host([href]) .card-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font: var(--header-2);
  font-weight: bold;
  line-height: var(--sh-card-label-height) !important;
  flex: 1;
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  max-width: 100%;
  word-break: break-all;
  padding: var(--sh-card-href-label-padding);
  margin: 0;
}

:host([href]) .label-wrapper.empty-content .card-label {
  flex: unset;
}

:host([href]:not([image])) .card-icon.empty-content {
  margin-right: unset;
  margin-bottom: var(--sh-card-href-card-icon-empty-content);
}

:host([href]) slot[name=footer]::slotted(*:not(:first-child)) {
  margin: unset;
}

:host([href]:not([image])) slot[name=footer]:not(.empty).empty-content {
  border-top: 0.0625rem solid rgba(var(--ui-1), var(--opacity-6));
}

:host([href]:not([image])) slot[name=footer]:not(.empty) {
  margin: var(--sh-card-href-not-image-not-empty-footer-slot-margin);
  padding: var(--sh-card-href-not-image-not-empty-footer-slot-padding);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* href disabled */
:host([href][disabled]) {
  pointer-events: none;
}

:host([href][disabled]) .card-wrapper {
  opacity: var(--opacity-4);
}

:host([href][disabled]) .card-label {
  color: var(--text-disabled);
}

:host([label-rows=auto]) .card-label {
  white-space: unset;
  word-break: break-word;
}

/* touch */
@media (pointer: coarse) {
  slot:not([name])::slotted(*:not(:first-child)) {
    margin-top: 1rem;
  }
  slot[name=functions]::slotted(*:not(:first-child)),
  slot[name=footer]::slotted(*:not(:first-child)) {
    margin-left: 1rem;
  }
}
:host([blue-outline]) {
  box-shadow: inset 0rem 0rem 0rem 0.125rem var(--focus-color);
}

sh-icon {
  --sh-icon-font-size-l: var(--sh-card-icon-font-size-l);
}

.header-data {
  padding-bottom: 0px;
}`;