// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { deviceIdentifier } from '../../utils/device-identifier';
import { ShuiLitElement } from '../../utils/event-decorator';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin';
import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin';
import { windowLocation } from '../../utils/window-location';
import breadcrumbItemStyles from './sh-breadcrumb-item.lit.scss.js';
let SHBreadcrumbItem = class SHBreadcrumbItem extends TooltipEllipsisCheckerMixin(
  FocusBlurMixin(ShuiLitElement)
) {
  static get styles() {
    return [sharedStyles, breadcrumbItemStyles];
  }
  render() {
    return html`
      ${this.label
        ? html`<sh-text
              id="breadcrumbtexttooltip"
              class="focus-item"
              @click="${() => this._navigateByHref()}"
              @mouseenter="${(e) => this._showTooltip(e)}"
              @focus="${(e) => this._showTooltip(e)}"
              >${this.label}</sh-text
            ><sh-tooltip
              .target="${this.breadcrumbText}"
              .label="${this.label}"
              placement="bottom-left"
              position-fixed-adjustment
            ></sh-tooltip>`
        : nothing}
      ${!this.active ? html` <sh-icon icon="arrow-right-s" size="s"></sh-icon> ` : nothing}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'link');
    }
  }
  firstUpdated(changedProperties) {
    var _a;
    super.firstUpdated(changedProperties);
    (_a = this.breadcrumbText) === null || _a === void 0
      ? void 0
      : _a.setAttribute('tabindex', '0');
  }
  _showTooltip(e) {
    const target = e.target;
    if (!deviceIdentifier.isTouchDevice()) {
      const isEllipsis = this.ellipsisChecker(
        target.scrollWidth,
        target.offsetWidth,
        target.scrollHeight,
        target.offsetHeight,
        'horizontal'
      );
      if (isEllipsis === true) {
        this.breadcrumbTooltip.setAttribute('visible', '');
      } else {
        this.breadcrumbTooltip.setAttribute('target', '');
        this.breadcrumbTooltip.removeAttribute('visible');
      }
    }
  }
  _navigateByHref() {
    if (this.href && !this.active) {
      windowLocation.setWindowLocationHRef(this.href);
    }
  }
  _enterKeyAction() {
    this._navigateByHref();
  }
};
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHBreadcrumbItem.prototype,
  'active',
  void 0
);
__decorate(
  [property({ type: String, reflect: true })],
  SHBreadcrumbItem.prototype,
  'label',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHBreadcrumbItem.prototype, 'href', void 0);
__decorate([query('sh-text')], SHBreadcrumbItem.prototype, 'breadcrumbText', void 0);
__decorate([query('sh-tooltip', true)], SHBreadcrumbItem.prototype, 'breadcrumbTooltip', void 0);
SHBreadcrumbItem = __decorate([customElement('sh-breadcrumb-item')], SHBreadcrumbItem);
export { SHBreadcrumbItem };
