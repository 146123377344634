// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-carousel.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-carousel-counter-line-height=1rem] - line height of counter
   */
  --sh-carousel-counter-line-height: 1rem;
  /* 
   * @cssprop [--sh-carousel-counter-bottom=0.25rem] - placement of counter from bottom
   */
  --sh-carousel-counter-bottom: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-counter-left-right=0.25rem] - placement of counter left and right
   */
  --sh-carousel-counter-left-right: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-counter-border-radius=0.125rem] - placement of counter bottom
   */
  --sh-carousel-counter-border-radius: 0.125rem;
  /* 
   * @cssprop [--sh-carousel-counter-padding=0.25rem] - the padding for counter
   */
  --sh-carousel-counter-padding: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-counter-padding-top=0.25rem] - the padding top for counter
   */
  --sh-carousel-counter-padding-top: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-arrow-left=0.5rem] - the position of arrow from left
   */
  --sh-carousel-arrow-left: 0.5rem;
  /* 
   * @cssprop [--sh-carousel-arrow-right=0.5rem] - the position of arrow from right
   */
  --sh-carousel-arrow-right: 0.5rem;
  /* 
   * @cssprop [--sh-carousel-arrow-padding=0.25rem] - the padding for arrow
   */
  --sh-carousel-arrow-padding: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-icon-width=2rem] - the width of arrow icon
   */
  --sh-carousel-icon-width: 2rem;
  /* 
   * @cssprop [--sh-carousel-icon-height=2rem] - the height of arrow icon
   */
  --sh-carousel-icon-height: 2rem;
  /* 
   * @cssprop [--sh-carousel-idle-opacity=0.9] - idle opacity of icon
   */
  --sh-carousel-idle-opacity: 0.9;
  /* 
   * @cssprop [--sh-carousel-dot-size=0.25rem] - height and width of the dot
   */
  --sh-carousel-dot-size: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-dot-in-view=0.5rem] - height and width of the dot when in view
   */
  --sh-carousel-dot-in-view: 0.5rem;
  /* 
   * @cssprop [--sh-carousel-dot-in-view-margin=0.25rem] - margin of the dot when in view
   */
  --sh-carousel-dot-in-view-margin: 0.25rem;
  /* 
   * @cssprop [--sh-carousel-dot-edge-margin=0.375rem] - margin of the dot when in view
   */
  --sh-carousel-dot-edge-margin: 0.375rem;
  /* 
   * @cssprop [--sh-carousel-dot-scale=0.5] - scale of the dot
   */
  --sh-carousel-dot-scale: 0.5;
  /* 
   * @cssprop [--sh-carousel-footer-margin-top=0.5rem] - margin top of the footer elements
   */
  --sh-carousel-footer-margin-top: 0.5rem;
  /* 
   * @cssprop [--sh-carousel-dot-counter-height=1rem] - height of the dot counter
   */
  --sh-carousel-dot-counter-height: 1rem;
  /* 
   * @cssprop [--sh-carousel-arrow-gap=0.5rem] - gap between arrows
   */
  --sh-carousel-arrow-gap: 0.5rem;
  /* 
   * @cssprop [--sh-carousel-dot-current-active-size=1.5rem] - size of the current active dot
   */
  --sh-carousel-dot-current-active-size: 1.5rem;
}

:host {
  display: flex;
  position: relative;
  font: var(--body-1);
  width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  --icon-idle-opacity: var(--sh-carousel-idle-opacity);
  --carousel-dot-height: var(--sh-carousel-dot-counter-height);
}

slot::-webkit-scrollbar {
  display: none;
}

slot {
  display: flex;
  align-items: center;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 100%;
  height: fit-content;
  scrollbar-width: none;
}

::slotted(*) {
  flex: none;
  width: var(--slot-component-width);
}

/* snap */
:host([snap=start]) ::slotted(*) {
  scroll-snap-align: start;
}

:host([snap=end]) ::slotted(*) {
  scroll-snap-align: end;
}

:host([snap=center]) ::slotted(*) {
  scroll-snap-align: center;
}

/* arrow */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  padding: var(--sh-carousel-arrow-padding);
  background: rgba(var(--ui-6), var(--opacity-2));
  transition: var(--transition-time) all ease-in-out;
}

:host([counter-type=dots]) .arrow {
  transform: translateY(calc(-50% - var(--sh-carousel-dot-counter-height)));
}

:host(:is([arrow-placement=floating], :not([arrow-placement]))) .arrow.left {
  left: var(--sh-carousel-arrow-left);
}
:host(:is([arrow-placement=floating], :not([arrow-placement]))) .arrow.right {
  right: var(--sh-carousel-arrow-right);
}

:host([arrow-placement=footer-right]) .button-arrow-wrapper {
  order: 2;
}

.button-arrow-wrapper {
  display: flex;
  gap: var(--sh-carousel-arrow-gap);
}

sh-icon {
  width: var(--sh-carousel-icon-width);
  height: var(--sh-carousel-icon-height);
  color: rgba(var(--ui-1), var(--opacity-2));
  background: rgba(var(--ui-6), var(--opacity-2));
}

sh-icon.arrow:before {
  content: "";
  background: rgba(var(--ui-1), var(--opacity-6));
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  transition: var(--transition-time) all ease-in-out;
}

sh-icon:not(.touch-device).arrow:hover:before {
  opacity: 1;
}

/*dots*/
.dots {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.3, 0, 0.2, 1);
}

.dot {
  pointer-events: none;
  flex: 0 0 var(--sh-carousel-dot-in-view);
  width: var(--sh-carousel-dot-in-view);
  height: var(--sh-carousel-dot-in-view);
  border-radius: 0.5rem;
  transform: scale(0.5);
  margin: var(--sh-carousel-dot-in-view-margin);
  background-color: rgb(var(--ui-1));
  opacity: var(--opacity-300);
  transition: flex var(--dot-transition), opacity var(--dot-transition), transform var(--dot-transition), background-color 0.3s;
}

.dot.in-view {
  margin: var(--sh-carousel-dot-in-view-margin);
  transform: scale(1);
}

.dot.edge {
  transform: scale(var(--sh-carousel-dot-scale));
}

.dot.current-selected {
  flex: 0 0 var(--sh-carousel-dot-current-active-size);
  transform: scaleX(1) scaleY(1);
  opacity: var(--opacity-800);
}

:host(:is([counter-type=numeric], :not([counter-type]))) .counter {
  border-radius: var(--sh-carousel-counter-border-radius);
  line-height: var(--sh-carousel-counter-line-height);
  color: var(--text-primary);
  padding: var(--sh-carousel-counter-padding);
  background: rgba(var(--ui-6), var(--opacity-2));
  max-height: var(--sh-carousel-counter-line-height);
}

/*counter*/
:host(:is([counter-type=numeric], :not([counter-type])):not([arrow-placement^=footer])) .counter {
  position: absolute;
  left: 50%;
  bottom: var(--sh-carousel-counter-bottom);
  transform: translateX(-50%);
}

:host([counter-type=dots]) .counter {
  background: transparent;
  overflow: hidden;
  width: calc(5 * var(--sh-carousel-dot-counter-height) + var(--sh-carousel-dot-current-active-size) + 2 * var(--sh-carousel-dot-in-view-margin));
  height: var(--sh-carousel-dot-counter-height);
}

:host(:is([arrow-placement=floating], :not([arrow-placement]))[counter-type=dots]) .counter {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}

.counter-wrapper {
  display: flex;
  justify-content: space-between;
}

:host([arrow-placement=footer-left]) .counter {
  order: 2;
}

:host([arrow-placement=footer-right]:not([counter])) .counter-wrapper,
:host([arrow-placement=footer-left]:not([arrows])) .counter-wrapper {
  justify-content: flex-end;
}

:host([arrow-placement=footer-right]:not([arrows])) .counter-wrapper {
  justify-content: flex-start;
}

.counter::-webkit-scrollbar {
  display: none;
}

:host(:is([counter-type=dots][counter],
[arrow-placement=footer-right]:is([arrows], [counter]),
[arrow-placement=footer-left]:is([arrows], [counter]))) .counter-wrapper {
  margin-top: var(--sh-carousel-footer-margin-top);
}`;