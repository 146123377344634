// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2024. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
/**
 * ### convertToRem()
 * ___
 * Function that converts pixels to rem.
 * ___
 * #### How to use this function ?
 * Import like this in the component:
 *
 * ```js
 * import { convertToRem } from '@shui-web-components/utils/pixel-to-rem-convertor.js'
 * ```
 * Then use the function like the following:
 *
 * Suppose you want to convert 18px to rem.
 * For that, you can do as following:
 *
 * ```js
 * const remValue = convertToRem(18);
 * ```
 *
 * @param {Number} pxValue The pixel value in number
 * @returns Rem value in number
 */

export const convertToRem = (pxValue: number) => {
  const htmlFontSize = Number(
    getComputedStyle(document.documentElement).getPropertyValue('font-size').split('px')[0]
  );
  return pxValue / htmlFontSize;
};
