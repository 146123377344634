// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-notification-item.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-notification-item-header-margin=1rem 1rem 0 1rem] - margin of header area
   */
  --sh-notification-item-header-margin: 1rem 1rem 0 1rem;
  /* 
   * @cssprop [--sh-notification-item-header-icon-margin=0 0.5rem 0 0] - margin of header icon
   */
  --sh-notification-item-header-icon-margin: 0 0.5rem 0 0;
  /* 
   * @cssprop [--sh-notification-item-header-line-height=1.5rem] - header line height
   */
  --sh-notification-item-header-line-height: 1.5rem;
  /* 
   * @cssprop [--sh-notification-item-header-max-height=3rem] - header max height
   */
  --sh-notification-item-header-max-height: 3rem;
  /* 
   * @cssprop [--sh-notification-item-header-text-margin=0 0.5rem 0 0] - margin of header text
   */
  --sh-notification-item-header-text-margin: 0 0.5rem 0 0;
  /* 
   * @cssprop [--sh-notification-item-header-close-button-margin=0] - margin of header close button
   */
  --sh-notification-item-header-close-button-margin: 0;
  /* 
   * @cssprop [--sh-notification-item-description-container-margin=1rem] - margin of description container
   */
  --sh-notification-item-description-container-margin: 1rem;
  /* 
   * @cssprop [--sh-notification-item-no-label-description-container-margin=1rem] - margin of description container when there is no label
   */
  --sh-notification-item-no-label-description-container-margin: 1rem;
  /* 
   * @cssprop [--sh-notification-item-description-icon-margin=0 0.5rem 0 0] - margin of description icon
   */
  --sh-notification-item-description-icon-margin: 0 0.5rem 0 0;
  /* 
   * @cssprop [--sh-notification-item-description-line-height=1.25rem] - description line height
   */
  --sh-notification-item-description-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-notification-item-description-text-margin=0.125rem 0.5rem 0 0] - description text margin
   */
  --sh-notification-item-description-text-margin: 0.125rem 0.5rem 0 0;
  /* 
   * @cssprop [--sh-notification-item-description-close-button-margin=0rem] - margin of description close button
   */
  --sh-notification-item-description-close-button-margin: 0rem;
  /* 
   * @cssprop [--sh-notification-item-footer-margin=0 1rem 1rem 1rem] - margin of footer area
   */
  --sh-notification-item-footer-margin: 0 1rem 1rem 1rem;
  /* 
   * @cssprop [--sh-notification-item-footer-margin-with-no-description=1rem] - footer margin when no description is preset
   */
  --sh-notification-item-footer-margin-with-no-description: 1rem;
  /* 
   * @cssprop [--sh-notification-item-description-slot-elements-margin-top=0rem] - margin between elements added in description slot
   */
  --sh-notification-item-description-slot-elements-margin-top: 0rem;
  /* 
   * @cssprop [--sh-notification-item-badge-margin=0.5rem 1rem 0.5rem 1rem] - margin for the notification badge
   */
  --sh-notification-item-badge-margin: 0.5rem 1rem 0.5rem 1rem;
  /* 
   * @cssprop [--sh-notification-item-footer-slot-child-margin-left=0.5rem] - margin-left for the components in the footer slot
   */
  --sh-notification-item-footer-slot-child-margin-left: 0.5rem;
  /* 
   * @cssprop [--sh-notification-item-animation-curve=cubic-bezier(0.4, 0, 0.2, 1)] - speed curve of animation effect
   */
  --sh-notification-item-animation-curve: cubic-bezier(0.4, 0, 0.2, 1);
  /* 
   * @cssprop [--sh-notification-item-border-radius=var(--sh-radius-small-container)] - border-radius of notification
   */
  --sh-notification-item-border-radius: var(--sh-radius-small-container);
}

:host {
  width: 100%;
  height: auto;
  height: -ms-fit-content;
  height: -moz-fit-content;
  background: var(--base-4);
  box-shadow: var(--shadow-overlay);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: var(--sh-notification-item-border-radius);
  box-sizing: border-box;
  overflow: hidden;
  transition: var(--transition-time) all var(--sh-notification-item-animation-curve), 0s max-height var(--sh-notification-item-animation-curve), 0s width var(--sh-notification-item-animation-curve), 0s padding var(--sh-notification-item-animation-curve), 0s margin var(--sh-notification-item-animation-curve);
}

/* flat */
:host([flat]) {
  background: transparent;
  box-shadow: var(--shadow-flat);
}

:host([inline]) {
  background: rgba(var(--ui-1), var(--opacity-7));
  box-shadow: none;
  border-left: 4px solid rgba(var(--ui-1), var(--opacity-3));
  transition: none;
}

:host([inline][type=alert]),
:host([inline][type=warning]),
:host(:not([flat])[type=alert]),
:host(:not([flat])[type=warning]) {
  border-left: 4px solid rgb(var(--functional-yellow));
}

:host([inline][type=confirmation]),
:host([inline][type=success]),
:host(:not([flat])[type=confirmation]),
:host(:not([flat])[type=success]) {
  border-left: 4px solid rgb(var(--functional-green));
}

:host([inline][type=error]),
:host(:not([flat])[type=error]) {
  border-left: 4px solid rgb(var(--functional-red));
}

:host([flat]:hover) {
  background: rgba(var(--ui-1), var(--opacity-7));
}

/* hidden */
:host(:not([visible])) {
  max-height: 0rem;
  min-height: unset;
  width: 0rem;
  padding: 0;
  margin: 0 !important;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition-time) all var(--sh-notification-item-animation-curve), 0s max-height var(--sh-notification-item-animation-curve) var(--transition-time), 0s width var(--sh-notification-item-animation-curve) var(--transition-time), 0s padding var(--sh-notification-item-animation-curve) var(--transition-time), 0s margin var(--sh-notification-item-animation-curve) var(--transition-time);
  transition-timing-function: var(--sh-notification-item-animation-curve);
}

:host(:not([visible])[inline]) {
  transition: none;
}

/* header */
.header-wrapper {
  display: flex;
  margin: var(--sh-notification-item-header-margin);
}

.header-wrapper > sh-icon:not([icon=cancel]) {
  margin: var(--sh-notification-item-header-icon-margin);
}

.header-wrapper > sh-text {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--sh-notification-item-header-line-height);
  max-height: var(--sh-notification-item-header-max-height);
  margin: var(--sh-notification-item-header-text-margin);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.default {
  margin: var(--sh-notification-item-description-container-margin);
}

:host(:not([label])) .default {
  display: flex;
  flex-direction: row-reverse;
  margin: var(--sh-notification-item-no-label-description-container-margin);
}

.default > sh-icon:not([icon=cancel]) {
  margin: var(--sh-notification-item-description-icon-margin);
}

/* body */
sh-text[size=body-1] {
  line-height: var(--sh-notification-item-description-line-height);
}

:host(:not([label])) sh-text[size=body-1] {
  line-height: var(--sh-notification-item-description-line-height);
  margin: var(--sh-notification-item-description-text-margin);
}

sh-text[size=body-1] > ::slotted(*:first-child),
sh-text[size=body-1] > ::slotted(sh-menu-item) {
  margin-top: 0rem;
}

sh-text[size=body-1] > ::slotted(*) {
  margin-top: var(--sh-notification-item-description-slot-elements-margin-top);
}

/* footer */
.footer-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: var(--sh-notification-item-footer-margin);
}

.footer-wrapper[empty-footer] {
  display: none;
}

.footer-wrapper > ::slotted(*) {
  margin-left: var(--sh-notification-item-footer-slot-child-margin-left);
}

/* no label */
:host(:not([label])) sh-text[body-1] {
  margin-right: 2.5rem;
  margin-top: -2.5rem;
  width: calc(100% - 2.5rem);
}

:host(:not([label])[type]) sh-text[body-1] {
  margin-left: 2.5rem;
  width: calc(100% - 5rem);
}

.badge {
  margin: var(--sh-notification-item-badge-margin);
}`;