// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/**
 * Date object used to store information about a date like:
 * the date corresponding to the date, whether the date is selected
 * or not, whether the month is today's month or not and whether the month
 * is disabled or not.
 *
 * @property {Date} date - the date corresponding to 1st of the month
 * @property {Boolean} isOtherMonthDay - whether the date does not belong to the current month or not.
 * @property {Boolean} isSelected - whether the date is selected or not.
 * @property {Boolean} isToday - whether the date is today or not.
 * @property {Boolean} isWithinRange - whether the date is within the range or not.
 * @property {Boolean} isDisabled - whether the date is disabled or not.
 */
export class DateData {
  constructor() {
    this.date = null;
    this.isOtherMonthDay = false;
    this.isSelected = false;
    this.isToday = false;
    this.isWithinRange = false;
    this.isDisabled = false;
  }
}
/**
 * Function that generates a static list of 42 `DateData` for the given parameters.
 *
 * @param {Number} month - the month corrresponding to the dates
 * @param {Number} year - the year to which the month belongs
 * @param {Date} todaysDate - today's Date with hours, minutes, seconds and milliseconds set to zero
 * @param {Array<Date>} selectedDates - Array of selected dates with hours, minutes, seconds and milliseconds set to zero
 * @param {Boolean} futureDateDisable - whether the dates coming after today's date is disabled or not.
 * @param {Date} minDate - minimum date with hours, minutes, seconds and milliseconds set to zero
 * @param {Date} maxDate - maximum date with hours, minutes, seconds and milliseconds set to zero
 * @returns - An array of `DateData` corresponding to the given parameters.
 */
export function generateCalendarData(
  month,
  year,
  todaysDate,
  selectedDates = [],
  futureDateDisable = false,
  pastDateDisable = false,
  minDate = null,
  maxDate = null,
  startDayAddendum = 0,
  firstDayOfWeek
) {
  if (firstDayOfWeek) {
    // Based on the firstDayOfWeek, setting startDayAddendum to be 1 (Monday) or 0 (Sunday).
    startDayAddendum = firstDayOfWeek === 'monday' ? 1 : 0;
  }
  const calendarData = [];
  const dayOneDay = new Date(year, month, 1, 0, 0, 0, 0).getDay();
  const startDayLogic =
    dayOneDay === 0 && startDayAddendum === 1 ? 1 - 6 : 1 - dayOneDay + startDayAddendum;
  /**
   * currentDate will be our starting date and will be incremented at the end of every
   * iteration cycle.
   * We start with the first day of the week. Depending on the startDayAddendum, it will
   * be either date of the Sunday or Monday of the first week of the month.
   * If the startDayAddendum is 0, then the first day of the week will be Sunday.
   * If the startDayAddendum is 1, then the first day of the week will be Monday.
   */
  const currentDate = new Date(year, month, startDayLogic, 0, 0, 0, 0);
  // 42 stands for 6 weeks * 7 days
  for (let i = 0; i < 42; i++) {
    const dateData = new DateData();
    dateData.date = new Date(JSON.parse(JSON.stringify(currentDate)));
    dateData.isOtherMonthDay = dateData.date.getMonth() !== month;
    dateData.isDisabled = isDisabledDate(
      currentDate,
      todaysDate,
      futureDateDisable,
      pastDateDisable,
      minDate,
      maxDate
    );
    /**
     * Skipping the isSelected, isToday and isWithinRange
     * check if it is of other month or disabled
     */
    if (!dateData.isOtherMonthDay && !dateData.isDisabled) {
      dateData.isSelected =
        selectedDates.length === 2
          ? dateData.date.getTime() === selectedDates[0].getTime() ||
            dateData.date.getTime() === selectedDates[1].getTime()
          : selectedDates.length === 1
            ? dateData.date.getTime() === selectedDates[0].getTime()
            : false;
      dateData.isToday = dateData.date.getTime() === todaysDate.getTime();
      dateData.isWithinRange =
        selectedDates.length === 2
          ? dateData.date.getTime() >= selectedDates[0].getTime() &&
            dateData.date.getTime() <= selectedDates[1].getTime()
          : false;
    }
    calendarData.push(dateData);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return calendarData;
}
function isDisabledDate(
  currentDate,
  todaysDate,
  futureDateDisable,
  pastDateDisable,
  minDate,
  maxDate
) {
  const currentDateTime = currentDate.getTime();
  const todaysDateTime = todaysDate.getTime();
  let isDisabled =
    (Boolean(futureDateDisable) && currentDateTime > todaysDateTime) ||
    (Boolean(pastDateDisable) && currentDateTime < todaysDateTime);
  if (!isDisabled && minDate) {
    if (currentDate.getTime() < minDate.getTime()) {
      isDisabled = true;
    }
  }
  if (!isDisabled && maxDate) {
    if (currentDate.getTime() > maxDate.getTime()) {
      isDisabled = true;
    }
  }
  return isDisabled;
}
