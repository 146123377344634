// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import switchStyles from './sh-switch.lit.scss.js';
export const switchListOfProperties = ['stretch', 'disabled', 'condensed'];
/**
 * @fires stretch-changed {StretchPropertyChangedEvent} - *hide emitted when the stretch property changes.
 * @fires condensed-changed {CondensedPropertyChangedEvent} - *hide emitted when the condensed property changes.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide emitted when the disabled property changes.

 * @prop {Boolean} stretch - Default false. If set to `true`, the switch will occupy the entire width of it's container.
 * @prop {Boolean} disabled - Default false. If set to `true`, the switch will not allow any interaction.
 * @prop {Boolean} condensed - Default false. If set to `true`, the switch shows condensed styles. Applicable only for text-switches.
 */
let SHSwitch = class SHSwitch extends ShuiLitElement {
  constructor() {
    super(...arguments);
    this.stretch = false;
    this.disabled = undefined;
    this.condensed = false;
  }
  static get styles() {
    return [switchStyles];
  }
  render() {
    return html` <slot></slot> `;
  }
  updated(changedProperties) {
    if (changedProperties.has('disabled')) {
      this.handleDisabledChange();
    }
    if (changedProperties.has('condensed')) {
      this.handleCondensedChange();
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, switchListOfProperties);
  }
  handleDisabledChange() {
    const childrenItems = this.children;
    for (const childrenItem of childrenItems) {
      childrenItem.disabled = this.disabled;
    }
  }
  handleCondensedChange() {
    const switchItemHasIcon = this.querySelector('sh-switch-item[icon]:not([icon=""])');
    if (!switchItemHasIcon) {
      const switchItems = this.querySelectorAll('sh-switch-item');
      for (const switchItem of switchItems) {
        switchItem.condensed = this.condensed;
      }
    }
    if (switchItemHasIcon && this.condensed) {
      console.warn(
        'Condensed style will not be applied to switch and condensed property will not be applied to switch-items , if any switch-item has icon'
      );
    }
  }
};
__decorate([event()], SHSwitch.prototype, 'stretchChangedEvent', void 0);
__decorate([event()], SHSwitch.prototype, 'condensedChangedEvent', void 0);
__decorate([event()], SHSwitch.prototype, 'disabledChangedEvent', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSwitch.prototype, 'stretch', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSwitch.prototype, 'disabled', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHSwitch.prototype, 'condensed', void 0);
SHSwitch = __decorate([customElement('sh-switch')], SHSwitch);
export { SHSwitch };
