// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { property, state } from 'lit/decorators.js';
import { event } from './event-decorator.js';
import { DisabledPropertyChangedEvent } from './events.js';
/** ### ControlStateMixin
 * ___
 * Superclass aimed to control the common states of `tabindex`
 * and `disabled` for the components that import this mixin,
 * thereby reducing code duplication.
 * ___
 * #### How to use this mixin ?
 *
 * Import like this in the component:
 * ```js
 * import { ControlStateMixin } from '@shui-web-components/utils/control-state-mixin.js';
 * ```
 * Then inherit this mixin like this:
 * ```js
 * export class SHComponent extends ControlStateMixin(LitElement)
 * ```
 * ___
 *
 * #### Motivations for using this mixin ?
 *
 * - `tabindex` is supposed to be an author-set attribute that
 *    must not be overriden except for the case of disabled.
 *
 * -  the behavior of `disabled` and the subsequent handling of
 *   `tabindex` is common among components. So grouping such
 *    behaviors in a common class promotes code reusability and
 *    avoids code duplication. This in-turn helps in reducing the
 *    file size of the final build.
 * ___
 *
 * #### How does this mixin work ?
 *
 * ##### Default tabindex:
 *
 * A property named `defaultTabIndex` is present which is set to 0 by default. If the
 * component has a different default value for its tabindex attribute, then `defaultTabIndex` property must
 * be set to that value. If the component does not have any default tabindex or handles the default tabindex value
 * by itself , then `defaultTabIndex` property must be set to `''` (empty string)
 *
 * ##### When disabled property changes:
 *
 * - When the `disabled` property is set to true,
 *    - If there is a `tabindex` atttribute currently,
 *      - The value of the current `tabindex` attribute is given to a new attribute `data-previous-tabindex`.
 *      - And the `tabindex` attribute is given a value of -1.
 *    - If there is no `tabindex` currently,
 *      - The value `no-previous-tabindex` is given to a new attribute `data-previous-tabindex`.
 *      - And the `tabindex` attribute is given a value of -1
 * - When the `disabled` property is set to false,
 *    - If there is `data-previous-tabindex` attribute,
 *      - If the value of `data-previous-tabindex` is not `no-previous-tabindex`,
 *        - Then the `tabindex` attribute is set with a value same as that of `data-previous-tabindex`
 *        - And the `data-previous-tabindex` attribute is removed.
 *      - If the value of `data-previous-tabindex` is `no-previous-tabindex`,
 *        - Then the `tabindex` attribute is removed.
 *        - And the `data-previous-tabindex` is removed
 *    - If there is no `data-previous-tabindex` attribute, then no change.
 * - This class also sets the value of `aria-disabled` attribute to `true` or `false`
 * if disabled property is set/cleared respectively
 */
export function ControlStateMixin(superClass) {
  /**
   * @mixin ControlStateMixinElement
   * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide Event emitted when disabled property changes
   */
  class ControlStateMixinElement extends superClass {
    constructor() {
      super(...arguments);
      this.defaultTabIndex = '0';
    }
    connectedCallback() {
      super.connectedCallback();
      if (!this.hasAttribute('tabindex') && this.defaultTabIndex) {
        this.setAttribute('tabindex', this.defaultTabIndex);
      }
    }
    attributeChangedCallback(name, oldval, newval) {
      super.attributeChangedCallback(name, oldval, newval);
      if (name === 'disabled' && typeof this.disabled === 'boolean') {
        this.setAttribute('aria-disabled', JSON.stringify(this.disabled));
      }
    }
    update(changedProperties) {
      super.update(changedProperties);
      if (changedProperties.has('disabled')) {
        this.handleDisabledChange();
      }
    }
    updated(changedProperties) {
      super.updated(changedProperties);
      this.disabledChangedEvent.emit(new DisabledPropertyChangedEvent({ detail: !!this.disabled }));
    }
    handleDisabledChange() {
      if (this.disabled) {
        const currentTabindex = this.getAttribute('tabindex');
        this.setAttribute(
          'data-previous-tabindex',
          currentTabindex ? currentTabindex : 'no-previous-tabindex'
        );
        this.setAttribute('tabindex', '-1');
        this.blur();
      } else {
        // some components are setting to disabled=false by default.(eg: stepper-item)
        // in such scenarios, we don't want to do anything.
        // at that moment, data-previous-tabindex attribute won't be
        // present
        if (this.hasAttribute('data-previous-tabindex')) {
          const dataPreviousTabindexValue = this.getAttribute('data-previous-tabindex');
          if (dataPreviousTabindexValue !== 'no-previous-tabindex') {
            this.setAttribute('tabindex', dataPreviousTabindexValue);
          } else {
            this.removeAttribute('tabindex');
          }
          this.removeAttribute('data-previous-tabindex');
        }
      }
    }
  }
  __decorate(
    [property({ type: Boolean, reflect: true })],
    ControlStateMixinElement.prototype,
    'disabled',
    void 0
  );
  __decorate([event()], ControlStateMixinElement.prototype, 'disabledChangedEvent', void 0);
  __decorate([state()], ControlStateMixinElement.prototype, 'defaultTabIndex', void 0);
  return ControlStateMixinElement;
}
