// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/**
 * Base event class for our legacy propXYZ-changed events
 */
export class LegacyPropertyChangedEvent extends CustomEvent {
  constructor(prop, init) {
    super(`${prop}-changed`, init);
  }
}
export class DisabledPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor(init) {
    super('disabled', init);
  }
}
export class IconPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('icon');
  }
}
export class LabelPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('label');
  }
}
export class SizePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('size');
  }
}
export class ColorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('color');
  }
}
export class TooltipPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('tooltip');
  }
}
export class HrefPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('href');
  }
}
export class DragAndDropPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('dragAndDrop');
  }
}
export class IconColorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('iconColor');
  }
}
export class HeaderPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('header');
  }
}
export class ButtonPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('button');
  }
}
export class BadgePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('badge');
  }
}
export class TogglePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('toggle');
  }
}
export class StickyPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('sticky');
  }
}
export class TitlePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('title');
  }
}
export class VariationPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('variation');
  }
}
export class TargetPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('target');
  }
}
export class NumberPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('number');
  }
}
export class VisiblePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('visible');
  }
}
export class TypePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('type');
  }
}
export class ModalHeightPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('modalHeight');
  }
}
export class ModalWidthPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('modalWidth');
  }
}
export class PagesPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('pages');
  }
}
export class CurrentPagePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('current-page');
  }
}
export class SpacingPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('spacing');
  }
}
export class VariantPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('variant');
  }
}
export class VerticalPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('vertical');
  }
}
export class IndeterminatePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('indeterminate');
  }
}
export class NeutralPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('neutral');
  }
}
export class MultilinePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('multiline');
  }
}
export class FieldsPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('fields');
  }
}
export class PlacementPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('placement');
  }
}
export class ErrorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('error');
  }
}
export class ImagePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('image');
  }
}
export class LabelAlignmentPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('labelAlignment');
  }
}
export class OverlayPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('overlay');
  }
}
export class PausedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('paused');
  }
}
export class RadialPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('radial');
  }
}
export class StatusPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('status');
  }
}
export class InfoPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('info');
  }
}
export class ModalPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('modal');
  }
}
export class ProgressPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('progress');
  }
}
export class SubHeadlinePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('subHeadline');
  }
}
export class VersionPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('version');
  }
}
export class NoClearPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('noClear');
  }
}
export class ActivePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('active');
  }
}
export class ValuePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('value');
  }
}
export class CurrentPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('current');
  }
}
export class ReadonlyPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('readonly');
  }
}
export class MessageVisiblePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('messageVisible');
  }
}
export class ThumbnailPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('thumbnail');
  }
}
export class FlatPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('flat');
  }
}
export class ExpandedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('expanded');
  }
}
export class MessagePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('message');
  }
}
export class NewPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('new');
  }
}
export class SubMenuPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('subMenu');
  }
}
export class PositionPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('position');
  }
}
export class PassivePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('passive');
  }
}
export class MobilePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('mobile');
  }
}
export class CondensedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('condensed');
  }
}
export class NativePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('native');
  }
}
export class EmbeddedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('embedded');
  }
}
export class TextareaPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('textarea');
  }
}
export class ResizablePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('resizable');
  }
}
export class CardHeightPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('cardHeight');
  }
}
export class ArrowPositionPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('arrowPosition');
  }
}
export class BehaviorPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('behavior');
  }
}
export class StretchPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('stretch');
  }
}
export class currentActivePropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('currentActive');
  }
}
export class currentSelectedPropertyChangedEvent extends LegacyPropertyChangedEvent {
  constructor() {
    super('currentSelected');
  }
}
