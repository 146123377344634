// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { deviceIdentifier } from '../../utils/device-identifier';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin';
import tableHeadStyles from './sh-table-head.lit.scss.js';
/**
 
 * @slot Takes any component or text string as children.
 * @fires toggle - Dispatched when clicked on sortable head.
 * @fires head-ready - Dispatched when head has been first rendered.
 * @fires showarrow - Dispatched when sorting arrow is shown
 * @attr {String} label-rows -  Header can have multiple lines when set.
 */
let SHTableHead = class SHTableHead extends TooltipEllipsisCheckerMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**If a value in px is defined, the head won't scale horizontally below that value, and horizontal scrolling may be applied. */
    /** @ignore */
    this.minWidth = '2.5rem';
    /** @ignore */
    this.sorted = false;
  }
  static get styles() {
    return [tableHeadStyles];
  }
  render() {
    return html`
      <div class="head-wrapper">
        ${!this.unsortable && this.number
          ? html` <sh-icon
              icon="${this.sortDescending ? 'up-s' : 'down-s'}"
              class="sort-icon"
            ></sh-icon>`
          : ''}
        ${this.labelRows
          ? html`<div class="head-wrapper-text" @mouseenter="${(e) => this.showTooltip(e)}">
              <slot id="headerSlot"></slot>
              <sh-tooltip placement="bottom-left" position-fixed-adjustment></sh-tooltip>
            </div>`
          : html`<div class="head-wrapper-text">
              <slot></slot>
            </div>`}
        ${!this.unsortable && !this.number
          ? html` <sh-icon
              icon="${this.sortDescending ? 'up-s' : 'down-s'}"
              class="sort-icon"
            ></sh-icon>`
          : ''}
      </div>
    `;
  }
  update(changedProperties) {
    if (changedProperties.has('sorted')) {
      this.toggleArrowVisibility();
    }
    if (changedProperties.has('unsortable')) {
      this.unsortableChanged();
    }
    if (changedProperties.has('minWidth')) {
      this.adjustMinWidth();
    }
    /* below code for fixing issue in frozen table.  The text remains on left side even when number property was present on header.
        Since the frozen table has display flex - margin-left style needs to be set to align the text content to right*/
    /*margin-left style needs to be set up this way (on ts file) as it is not working when adding a class
        (the page is rendered before the class is added)*/
    if ((this.slot == 'frozen-left' || this.slot == 'frozen-right') && this.number) {
      this.style.marginLeft = 'auto';
    }
    super.update(changedProperties);
  }
  unsortableChanged() {
    if (this.unsortable) {
      this.sorted = false;
      this.sortDescending = false;
      this.tabIndex = Number(this.getAttribute('tabindex'));
      this.removeAttribute('tabindex');
    } else {
      this.tabIndex
        ? this.setAttribute('tabindex', String(this.tabIndex))
        : this.setAttribute('tabindex', '0');
    }
  }
  showTooltip(e) {
    var _a, _b;
    if (!deviceIdentifier.isTouchDevice()) {
      const tooltip = this.renderRoot.querySelector('sh-tooltip');
      if (tooltip) {
        const target = e.target;
        const isEllipsis = this.ellipsisChecker(
          target.scrollWidth,
          target.offsetWidth,
          target.scrollHeight,
          target.offsetHeight,
          'both'
        );
        if (isEllipsis) {
          const slot =
            (_a = this.shadowRoot) === null || _a === void 0
              ? void 0
              : _a.getElementById('headerSlot');
          const slotNodes = slot === null || slot === void 0 ? void 0 : slot.assignedNodes();
          const slotNodesLength =
            (_b = slot === null || slot === void 0 ? void 0 : slot.assignedNodes()) === null ||
            _b === void 0
              ? void 0
              : _b.length;
          /*loop through slot children as the text could be placed directly inside the slot of table head or within a hierarchy
                    of divs. So in order to avoid a blank tooltip from showing up - we have to find the exact slot child that has long
                    label with ellipsis and display its content in the tooltip */
          for (let i = 0; i < slotNodesLength; i++) {
            const currentSlotNode = slotNodes[i];
            /*Direct Case (placed directly inside of table head) - inner text does not exist.
                        In case of hierarchy of divs present in  the sh-table-head,  inner text will exist for the div with the header text
                        & in both cases,the content of header will be present in the textContent of the slotNode*/
            if (
              slotNodesLength === 1 ||
              (slotNodesLength > 1 &&
                (currentSlotNode === null || currentSlotNode === void 0
                  ? void 0
                  : currentSlotNode.innerText))
            ) {
              const textContent = currentSlotNode.textContent;
              tooltip.target = target;
              tooltip.visible = true;
              tooltip.label = textContent;
            }
          }
        } else {
          tooltip.target = '';
          tooltip.visible = false;
          tooltip.label = '';
        }
      }
    }
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.icon && !this.hasAttribute('tabindex') && !this.unsortable) {
      this.setAttribute('tabindex', '0');
    } else if (this.icon) {
      const child = this.children[0];
      if (
        (child === null || child === void 0 ? void 0 : child.nodeName) === 'SH-ICON' &&
        (child === null || child === void 0 ? void 0 : child.icon) === ''
      ) {
        this.setAttribute('tabindex', '-1');
      }
    } else {
      //added to remove code smell comment
    }
  }
  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this.addEventListener('click', () => this.handleClick());
    this.addEventListener('focus', () => this.handleFocus());
    this.addEventListener('blur', () => this.handleBlur());
    this.dispatchEvent(
      new CustomEvent('head-ready', {
        detail: this,
        bubbles: true,
      })
    );
  }
  adjustMinWidth() {
    if (this.minWidth !== undefined) {
      this.style.minWidth = this.minWidth;
    }
  }
  handleClick() {
    this.toggleIcon();
    if (!this.unsortable) {
      this.sortDescending = !this.sortDescending;
      this.sorted = true;
    }
  }
  handleFocus() {
    var _a, _b;
    const wrapper =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.head-wrapper');
    const icon =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.sort-icon');
    this.onkeyup = (e) => {
      if (keyboardInteraction(e, KEYCODE.TAB) && !this.unsortable) {
        wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.add('focus');
        icon.style.visibility = 'visible';
      } else if (keyboardInteraction(e, KEYCODE.ENTER) || keyboardInteraction(e, KEYCODE.SPACE)) {
        this.handleClick();
        this.toggleIcon();
      }
    };
  }
  handleBlur() {
    var _a, _b;
    if (!this.unsortable) {
      const wrapper =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.head-wrapper');
      const icon =
        (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.sort-icon');
      // This condition will solve the header all-selection checkbox focus key-board navigation issue.
      this.onkeyup = (e) => {
        if (keyboardInteraction(e, KEYCODE.TAB) && !this.unsortable) {
          wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.remove('focus');
        }
      };
      if (!this.unsortable) {
        wrapper === null || wrapper === void 0 ? void 0 : wrapper.classList.remove('focus');
      }
      icon.style.visibility = '';
    }
  }
  /*Dispatch event for parent row to control sorted
      property of table-heads */
  toggleArrowVisibility() {
    var _a, _b, _c, _d;
    if (this.sorted) {
      this.dispatchEvent(
        new CustomEvent('showarrow', {
          detail: this,
          bubbles: true,
          composed: true,
        })
      );
      (_b =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.sort-icon')) === null || _b === void 0
        ? void 0
        : _b.classList.add('alwaysStay');
    } else if (!this.unsortable) {
      this.sortDescending = false;
      (_d =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('.sort-icon')) === null || _d === void 0
        ? void 0
        : _d.classList.remove('alwaysStay');
    }
  }
  toggleIcon() {
    if (!this.unsortable) {
      this.dispatchEvent(
        new CustomEvent('toggle', {
          detail: this,
          bubbles: true,
        })
      );
    }
  }
};
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTableHead.prototype,
  'unsortable',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHTableHead.prototype, 'number', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTableHead.prototype, 'icon', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTableHead.prototype,
  'condensed',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHTableHead.prototype, 'columns', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'min-width' })],
  SHTableHead.prototype,
  'minWidth',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHTableHead.prototype, 'checkbox', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'sort-descending' })],
  SHTableHead.prototype,
  'sortDescending',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHTableHead.prototype, 'sorted', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'label-rows' })],
  SHTableHead.prototype,
  'labelRows',
  void 0
);
SHTableHead = __decorate([customElement('sh-table-head')], SHTableHead);
export { SHTableHead };
