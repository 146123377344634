// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { calculatedBaseFontSize } from '../../utils/base-font-size.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import worklineItemStyles from '../src/sh-workline-item.lit.scss.js';
let SHWorklineItem = class SHWorklineItem extends LitElement {
  constructor() {
    super(...arguments);
    /**Defines the progress of an individual item, which is reflected in the filling of the ring around it. */
    this.progress = '0';
    /**If set to true, the progress bar will spin indefinitely until the property is set back to false. */
    this.indeterminate = false;
    /**Defines whether an item is currently selected by the user or not. */
    this.active = false;
    /**If set to true, the styles of the item will adapt and it will not allow mouse or touch interactions. */
    this.disabled = false;
    /**If set to true, the item will not allow mouse or touch interactions. */
    this.readonly = false;
    /**If set to true, the popover message will be shown. */
    this.messageVisible = false;
    /**If set to true, the workline item will show the repeat animation. This animation only appears initially when the workline-item is attached to the DOM for the first time. */
    this.repeat = false;
    /**Flags an workline-item as new. The new property is removed after the item gets active. */
    this.new = false;
    /**Set to true when message slot is empty */
    this.emptyMessage = true;
    /**Set to true when info slot is empty */
    this.emptyinfo = true;
    /**Set to true when info right slot is empty */
    this.emptyinforight = true;
  }
  static get styles() {
    return [worklineItemStyles];
  }
  render() {
    return html`
      ${this.type !== 'information'
        ? html` <div class="info-wrapper">
            <slot
              name="info"
              id="info"
              @slotchange=${(e) => this.updateEmptySlotFlag(e.target, 'emptyinfo')}
            ></slot>
          </div>`
        : ''}
      <!-- Adding on-click="stopEvent" as a fix for the bug 22078 in Azure Devops -->
      <!-- Bug link : https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/22078 -->
      <sh-popover
        passive
        .visible="${this.messageVisible && !this.emptyMessage}"
        @visible-changed=${(e) => {
          e.target.visible = this.messageVisible && !this.emptyMessage;
        }}
        @mobile-changed=${(e) => {
          e.target.mobile = false;
        }}
        sticky
        @click="${this.stopEvent}"
        .target="${this.getPopoverTarget()}"
      >
        <slot
          name="message"
          id="messageSlot"
          @slotchange=${(e) => this.updateEmptySlotFlag(e.target, 'emptyMessage')}
        ></slot>
      </sh-popover>
      <div class="item-wrapper">
        ${this.type !== 'information'
          ? html` ${this.progress >= 0
              ? html`<div class="progress-wrapper">
              ${this.createDashRing()}
              ${this.createProgressRing()}
            </div> </div>`
              : ''}
            ${this.info && !this.icon
              ? html`<sh-text size="body-1" class="item-info">${this.info}</sh-text>`
              : ''}
            ${this.thumbnail
              ? html` <div class="thumbnail-wrapper">
                  <div id="itemThumbnail" style="background-image:url('${this.thumbnail}');"></div>
                </div>`
              : ''}`
          : ''}
        ${this.icon
          ? html` <sh-icon id="itemIcon" .color="${this.iconColor}" icon=${this.icon}></sh-icon>`
          : ''}
        ${this.type !== 'information'
          ? html` ${this.current
              ? html` <div class="triangles"></div>
                  ${!this.active && !this.emptyinforight
                    ? html` <div class="badge-wrapper-current">
                        <slot name="badge"></slot>
                      </div>`
                    : ''}`
              : ''}`
          : ''}
      </div>
      ${this.type !== 'information'
        ? html` <div class="info-right-wrapper">
              <slot
                name="info-right"
                id="infoRight"
                @slotchange=${(e) => this.updateEmptySlotFlag(e.target, 'emptyinforight')}
              ></slot>
            </div>
            ${!this.current || this.emptyinforight || (this.current && this.active)
              ? html` <div class="badge-wrapper">
                  <slot name="badge" id="badgeSlot"></slot>
                </div>`
              : ''}
            ${this.label
              ? html`<sh-text size="body-2" class="item-label">${this.label}</sh-text>`
              : ''}`
        : ''}
    `;
  }
  update(changedProperties) {
    if (changedProperties.has('float')) {
      this.updateComplete.then(() => {
        this.handleFloatChange();
      });
    }
    if (changedProperties.has('repeat')) {
      this.handleRepeatChange();
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    const listOfProperties = [
      'label',
      'info',
      'color',
      'icon',
      'indeterminate',
      'active',
      'current',
      'disabled',
      'readonly',
      'messageVisible',
      'thumbnail',
      'new',
      'type',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  firstUpdated(changedProperties) {
    var _a;
    super.firstUpdated(changedProperties);
    if (changedProperties.has('repeat')) {
      this.handleRepeatChange();
    }
    const infowrapper =
      (_a = this === null || this === void 0 ? void 0 : this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.info-wrapper');
    infowrapper === null || infowrapper === void 0
      ? void 0
      : infowrapper.removeEventListener('transitionend', this.transitionendHandler);
    infowrapper === null || infowrapper === void 0
      ? void 0
      : infowrapper.addEventListener('transitionend', this.transitionendHandler);
  }
  connectedCallback() {
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(() => {
      this.requestUpdate();
    });
    this.resizeObserver.observe(this);
    this.transitionendListener = this.transitionendHandler.bind(this);
  }
  transitionendHandler() {
    dispatchCustomEvent(this, 'workline-item-size-changed');
  }
  updateEmptySlotFlag(slot, emptySlotFlag) {
    this[emptySlotFlag] =
      slot.assignedNodes({
        flatten: true,
      }).length === 0;
  }
  handleFloatChange() {
    dispatchCustomEvent(this, 'workline-item-float-changed');
  }
  handleRepeatChange() {
    if (this.repeat) {
      this.classList.add('repeat-animate');
      this.classList.add('no-transitions');
      // the animation class is removed here inorder to avoid
      // retriggering of the animation when the workline item
      // is removed from the DOM.
      this.addEventListener('animationend', function animationEndListener() {
        this.classList.remove('repeat-animate');
        this.classList.remove('no-transitions');
        this.removeEventListener('animationend', animationEndListener);
        dispatchCustomEvent(this, 'workline-animation-ended');
      });
    }
  }
  createProgressRing() {
    const computedSize = calculatedBaseFontSize / 16;
    const circleFillValue =
      computedSize *
      (parseFloat(getComputedStyle(this).getPropertyValue('--sh-workline-item-progress-circle')) *
        16 *
        Math.PI);
    return html`<svg class="progress-ring-svg">
      >
      <circle
        class="circle-ring"
        id="progress-ring"
        stroke="${this.color}"
        fill="transparent"
        stroke-dasharray="${circleFillValue * ((this.indeterminate ? 25 : this.progress) / 100)},
    ${circleFillValue}"
      />
    </svg>`;
  }
  createDashRing() {
    const computedSize = calculatedBaseFontSize / 16;
    return html`<svg class="progress-ring-svg">
      <circle
        id="dash-ring"
        class="circle-ring"
        stroke="${this.color}"
        stroke-dasharray="0, ${computedSize *
        parseFloat(
          getComputedStyle(this).getPropertyValue('--sh-workline-item-circle-stroke-dasharray')
        )}"
        fill="transparent"
        stroke-linecap="round"
      />
    </svg>`;
  }
  /**
   * Adding on-click="stopEvent" as a fix for the bug 22078 in Azure Devops.
   * Bug link : https://dev.azure.com/shs-ti-ux/SHUI/_workitems/edit/22078
   */
  stopEvent(e) {
    e.stopPropagation();
  }
  getPopoverTarget() {
    return this;
  }
};
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'info', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'color', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'icon', void 0);
__decorate(
  [property({ type: Number, reflect: true })],
  SHWorklineItem.prototype,
  'progress',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'indeterminate',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'active',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'current',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'readonly',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'message-visible' })],
  SHWorklineItem.prototype,
  'messageVisible',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHWorklineItem.prototype,
  'repeat',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHWorklineItem.prototype, 'float', void 0);
__decorate(
  [property({ type: String, reflect: true })],
  SHWorklineItem.prototype,
  'thumbnail',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHWorklineItem.prototype, 'new', void 0);
__decorate([property({ type: String, reflect: true })], SHWorklineItem.prototype, 'type', void 0);
__decorate(
  [
    property({ type: Boolean, reflect: true }),
    /** @ignore */
  ],
  SHWorklineItem.prototype,
  'emptyMessage',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true, attribute: 'empty-info' }),
    /** @ignore */
  ],
  SHWorklineItem.prototype,
  'emptyinfo',
  void 0
);
__decorate(
  [
    property({ type: Boolean, reflect: true, attribute: 'empty-info-right' }),
    /** @ignore */
  ],
  SHWorklineItem.prototype,
  'emptyinforight',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHWorklineItem.prototype,
  'iconColor',
  void 0
);
SHWorklineItem = __decorate([customElement('sh-workline-item')], SHWorklineItem);
export { SHWorklineItem };
