// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import radioGroupStyles from './sh-radio-group.lit.scss.js';
let SHRadioGroup = class SHRadioGroup extends LitElement {
  constructor() {
    super(...arguments);
    /** Default false. Defines if a radio button direction by defauly direction is vertical. If true, direction is set to horizontal. */
    this.horizontal = false;
  }
  static get styles() {
    return [radioGroupStyles];
  }
  render() {
    return html` <div class="radio-wrapper">
      <slot id="grouped"></slot>
    </div>`;
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'radiogroup');
    }
  }
  firstUpdated() {
    this.addEventListener('click', (e) => {
      this.handleClick(e);
    });
  }
  handleClick(e) {
    const target = e.target;
    // If the clicked target is not disabled and not radio-group, but is a radio-button, then only toggle
    // active for child radio-buttons.
    if (
      !target.disabled &&
      !target.readonly &&
      target.tagName !== 'SH-RADIO-GROUP' &&
      target.tagName === 'SH-RADIO-BUTTON'
    ) {
      const activeEls = this.querySelectorAll('[active]:not([readonly])');
      for (const activeEl of activeEls) {
        if (activeEl !== target) {
          activeEl.active = false;
        }
      }
    }
  }
};
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioGroup.prototype,
  'horizontal',
  void 0
);
SHRadioGroup = __decorate([customElement('sh-radio-group')], SHRadioGroup);
export { SHRadioGroup };
