// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __awaiter, __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import {
  AmPmValues,
  MinuteValues,
  SecondValues,
  TwelveHourValues,
  TwentyFourHourValues,
} from '../../utils/time-value-generator';
import { wait } from '../../utils/wait';
import './sh-timepicker-input.js';
import './sh-timepicker-panel.js';
import timepickerStyles from './sh-timepicker.lit.scss.js';
import { TimeInputStateHandlerMixin } from './time-input-state-handler-mixin.js';
import { TimeInputValidatorMixin } from './time-input-validator-mixin.js';
import { TimeModifierMixin } from './time-modifier-mixin.js';
export const TimepickerListOfProperties = [
  'mini',
  'condensed',
  'h',
  'm',
  's',
  'amPm',
  'step',
  'clockFormat',
  'val',
  'noClear',
];
/**
 * @attr {String} am-pm - Defines am/pm field of the Timepicker, applicable when `clock-format` is set to `12-hour`
 * @attr {String} error-message - Defines the message shown when error is set to true
 * @attr {Boolean} no-border - Default false. If set to true, the bottom border will not be displayed
 * @attr {String} clock-format - Defines whether time mode is 12-hour / 24-hour . Possible values are `"12-hour"` or `"24-hour"`
 * @prop {Boolean} timeInputState - *hide Defines the state of the input. Possible values are `hasValue`, `hasCursor`, `noValue`
 * @prop {Boolean} active - *hide when set, panel is open for the timepicker
 * @fires condensed-changed {CondensedPropertyChangedEvent} - *hide Dispatched when condensed changes.
 * @fires h-changed {HPropertyChangedEvent} - *hide Dispatched when h changes.
 * @fires m-changed {MPropertyChangedEvent} - *hide Dispatched when m changes.
 * @fires s-changed {SPropertyChangedEvent} - *hide Dispatched when s changes.
 * @fires amPm-changed {AmPmPropertyChangedEvent} - *hide Dispatched when amPm changes.
 * @fires step-changed {StepPropertyChangedEvent} - *hide Dispatched when step changes.
 * @fires clockFormat-changed {ClockFormatPropertyChangedEvent} - *hide Dispatched when clockFormat changes.
 * @fires val-changed {ValPropertyChangedEvent} - *hide Dispatched when val changes.
 * @fires mini-changed {MiniPropertyChangedEvent} - *hide Dispatched when mini changes.
 * @fires time-value-changed {TimeValueChangedEvent} - *hide internal event fired when value(h/m/s) changes
 * @fires no-clear-changed {NoClearPropertyChangedEvent} - *hide Dispatched when noClear changes.
 */
let SHTimepicker = class SHTimepicker extends TimeInputValidatorMixin(
  TimeInputStateHandlerMixin(TimeModifierMixin(ShuiLitElement))
) {
  constructor() {
    super(...arguments);
    /**Defines alternate format HH:MM AM/PM for Timepicker */
    this.mini = false;
    /**Defines increment/decrement step for minute and second selector (5,10,15,30).Default value is `1`. */
    this.step = 1;
    /**When set to true, the timepicker is displayed within a drawer placed at the bottom of the screen. It is applicable for mobile devices. */
    this.responsive = false;
    /**Defines the label of the Timepicker. Default value is `Time` */
    this.label = 'Time';
    /**If set, won't allow mouse events and disabled styles will be applied to the timepicker input. */
    this.disabled = false;
    /**Defines hours field of the Timepicker */
    this.h = '';
    /**Defines minutes field of the Timepicker */
    this.m = '';
    /**Defines seconds field of the Timepicker */
    this.s = '';
    /**Defines am/pm field of the Timepicker, applicable when `clock-format` is set to `12-hour` */
    this.amPm = '';
    /**Defines whether time mode is 12-hour / 24-hour . Possible values are `"12-hour"` or `"24-hour"` */
    this.clockFormat = '12-hour';
    /**If set to `true`, the input text displays styles for invalid input */
    this.error = false;
    /**If set to `true`, the input text displays styles for valid input */
    this.success = false;
    /**If set to `true`, the input text displays styles for safety-relevant input (yellow underline). Default is `false` */
    this.safety = false;
    /**If set to `true`, the bottom border will not be displayed. Default `false`.  */
    this.noBorder = false;
    /**If set to `true`, the input text displays styles for mandatory input */
    this.mandatory = false;
    /** @ignore */
    this.open = false;
    /** @ignore */
    this.close = false;
    /**If time picker is inside a table, then the condensed format of time picker is used to display information. This feature cannot be overriden. */
    this.condensed = false;
    /**@ignore */
    this.showDrawerDOM = false;
    /**@ignore */
    this.showOverlay = false;
    this.documentClickListener = this.documentClickActions.bind(this);
  }
  static get styles() {
    return [timepickerStyles];
  }
  render() {
    return html`
      <div class="timepicker-wrapper" id="timepickerWrapper" @click="${this.handleClick}">
        <div class="timepicker-input" @click=${this.makeMobileTimepickerActive}>
          <div
            class="label-area"
            @click="${() => {
              if (this.active) {
                this.active = false;
              }
            }}"
          >
            <sh-timepicker-input
              id="time-input-field"
              @time-value-changed=${(e) => this.updateTimeBasedOnInputOrPanelValue(e)}
              .h="${this.h}"
              .m="${this.m}"
              .s="${this.s}"
              .amPm="${this.amPm}"
              .step=${this.step}
              .mini=${this.mini}
              ?disabled=${this.disabled}
              clock-format="${this.clockFormat}"
            >
            </sh-timepicker-input>
            <div class="label-slot">
              ${this.label ? html` <div class="label">${this.label}</div> ` : ''}
              ${this.mandatory ? html` <span id="mandatoryStarSymbol">*</span>` : ''}
            </div>
          </div>
          <div class="icon-wrapper">
            ${this.val && !this.noClear && !this.disabled
              ? html`
                  <sh-icon
                    button
                    tabindex="0"
                    @click=${(e) => {
                      this.clearTimeInputField(e);
                      this.active = false;
                    }}
                    size="s"
                    icon="cancel"
                    tabIndex=${this.h !== '' || this.m !== '' || this.s !== '' || this.amPm !== ''
                      ? '0'
                      : '-1'}
                    class="close-button"
                    id="closeIcon"
                  >
                  </sh-icon>
                `
              : ''}
            ${this.error || this.success
              ? html` <sh-icon
                  class="error-success-icon"
                  icon=${this.error ? 'error' : 'success'}
                  size="s"
                  ?disabled="${this.disabled}"
                ></sh-icon>`
              : ''}
            <sh-icon
              button
              tabindex=${this.disabled ? -1 : 0}
              @click=${this.makeTimepickerActive}
              size="s"
              icon="scheduled"
              id="scheduledIcon"
            >
            </sh-icon>
          </div>
        </div>
        ${this.responsive
          ? this.showDrawerDOM
            ? this.timepickerMobileTemplate()
            : ''
          : html`
              <sh-overlay
                class="overlay"
                style="width:${getComputedStyle(this).width};display: none"
                scroll-action="cancel"
                no-overlap
                auto-fit-on-attach
                horizontal-align="left"
                .opened=${this.showOverlay}
                .noCancelOnOutsideClick="${true}"
                @overlay-canceled=${(e) => {
                  this.overlayCancelledCallback(e);
                }}
              >
                ${this.showOverlay
                  ? html` <sh-timepicker-panel
                      id="time-panel"
                      @time-value-changed=${(e) => this.updateTimeBasedOnInputOrPanelValue(e)}
                      .h="${this.h}"
                      .m="${this.m}"
                      .s="${this.s}"
                      .amPm="${this.amPm}"
                      .step=${this.step}
                      .mini=${this.mini}
                      active=${this.active}
                      clock-format="${this.clockFormat}"
                    ></sh-timepicker-panel>`
                  : ''}
              </sh-overlay>
            `}
      </div>

      ${this.hint
        ? html` <sh-text
            size="body-2"
            class="helper-text hint"
            color="secondary"
            @click=${() => {
              this.active = false;
            }}
            >${this.hint}</sh-text
          >`
        : ''}
      ${this.error && this.errorMessage
        ? html` <sh-text
            size="body-2"
            class="helper-text error-message"
            color="secondary"
            @click=${() => {
              this.active = false;
            }}
            >${this.errorMessage}</sh-text
          >`
        : ''}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    this.updateInputFieldStateBasedOnInputValue(undefined, false);
    if (!this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
    this.addEventListener(
      'keyup',
      this.toggleSubMenuIfPressedOnEnterAnywhereExceptScheduleIcon.bind(this)
    );
    document.body.addEventListener('click', this.documentClickListener);
  }
  update(changedProperties) {
    if (changedProperties.has('active')) {
      this.handleActiveChanged();
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    if (
      changedProperties.has('h') ||
      changedProperties.has('m') ||
      changedProperties.has('s') ||
      changedProperties.has('amPm')
    ) {
      this.updateTimeInValue();
    }
    if (changedProperties.has('disabled')) {
      this.tabIndex = this.disabled ? -1 : 0;
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, TimepickerListOfProperties);
  }
  makeTimepickerActive() {
    if (!this.responsive) {
      this.active = !this.active;
    }
  }
  overlayCancelledCallback(e) {
    //const event = e.detail;
    if (e.type === 'scroll') {
      this.active = false;
    }
  }
  documentClickActions(e) {
    if (e.composedPath().indexOf(this) === -1 && !this.responsive) {
      this.active = false;
      if (
        this.h === '' &&
        this.m === '' &&
        !this.mini &&
        this.s === '' &&
        this.clockFormat === '12-hour' &&
        this.amPm === ''
      ) {
        this.classList.remove('hasValue');
      }
    }
  }
  updateTimeBasedOnInputOrPanelValue(e) {
    const timepicker = e.target;
    this.h = timepicker.h;
    this.m = timepicker.m;
    this.s = timepicker.s;
    this.amPm = timepicker.amPm;
    this.step = timepicker.step;
    this.clockFormat = timepicker.clockFormat;
    if (
      (Number(this.h) > 0 && this.clockFormat === '12-hour') ||
      ((Number(this.h) > 0 || this.h === '00') && this.clockFormat === '24-hour') ||
      Number(this.m) > 0 ||
      Number(this.s) > 0 ||
      Number(this.amPm) > 0
    ) {
      this.classList.remove('noValue');
      this.classList.remove('hasCursor');
      this.classList.add('hasValue');
    }
    this.prependZero();
  }
  prependZero() {
    const prefixZero = (value) => (value.length < 2 ? '0' + value : value);
    if (this.h && Number(this.h) >= 0 && Number(this.h) <= 9) {
      this.h = prefixZero(this.h);
    }
    if (this.m && Number(this.m) >= 0 && Number(this.m) <= 9) {
      this.m = prefixZero(this.m);
    }
    if (this.s && Number(this.s) >= 0 && Number(this.s) <= 9) {
      this.s = prefixZero(this.s);
    }
  }
  toggleSubMenuIfPressedOnEnterAnywhereExceptScheduleIcon(e) {
    var _a, _b;
    let enteredOnScheduleIcon = false;
    const scheduleIcon =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#scheduledIcon');
    const closeIcon =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('#closeIcon');
    const keyupComposedPath = e.composedPath();
    for (let i = 0; i < keyupComposedPath.length; i++) {
      if (keyupComposedPath[i] === scheduleIcon || keyupComposedPath[i] === closeIcon) {
        enteredOnScheduleIcon = true;
        break;
      }
    }
    if (keyboardInteraction(e, KEYCODE.ENTER) && !enteredOnScheduleIcon) {
      scheduleIcon === null || scheduleIcon === void 0 ? void 0 : scheduleIcon.click();
    }
  }
  updateTimeInValue() {
    this.validateTime();
    this.val = `${this.h}:${this.m}${!this.mini ? ':' + this.s : ''}${this.clockFormat === '12-hour' ? `:` + this.amPm : ''}`;
  }
  handleOverlay() {
    if (this.active && !this.responsive) {
      this.showOverlay = true;
    }
  }
  clearTimeInputField(e) {
    var _a;
    e.stopPropagation();
    if (
      e.currentTarget ===
      ((_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.close-button'))
    ) {
      this.h = this.m = this.s = this.amPm = '';
    }
  }
  handleActiveChanged() {
    if (this.responsive) {
      this.active ? this.openMobileDrawer() : this.closeMobileDrawer();
    } else {
      this.showOverlay = this.active;
    }
  }
  /*mobile*/
  timepickerMobileTemplate() {
    return html` <sh-drawer
      position="bottom"
      class="point5-vertical-padding initial-slide-on-connect"
      visible
      @visible-changed="${(e) => {
        e.stopPropagation();
        if (!e.target.visible) {
          this.active = false;
        }
      }}"
      ><sh-scrollpicker
        @values-changed="${(e) => {
          this.updateValueFromScrollPicker(e.target);
        }}"
      ></sh-scrollpicker
    ></sh-drawer>`;
  }
  handleClick(e) {
    var _a, _b;
    const hourInput =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('#time-input-field');
    const hourfield =
      (_b = hourInput === null || hourInput === void 0 ? void 0 : hourInput.shadowRoot) === null ||
      _b === void 0
        ? void 0
        : _b.querySelector('#h-label');
    if (
      !(hourfield === null || hourfield === void 0 ? void 0 : hourfield.value) &&
      !this.responsive &&
      e.composedPath()[0].tagName !== 'INPUT'
    ) {
      hourfield.focus();
    }
  }
  makeMobileTimepickerActive() {
    if (this.responsive) {
      this.active = !this.active;
    } else {
      this.handleOverlay();
    }
  }
  openMobileDrawer() {
    return __awaiter(this, void 0, void 0, function* () {
      const beforeDrawerOpensWaitTime = 50;
      this.showDrawerDOM = true;
      yield wait(beforeDrawerOpensWaitTime);
      this.initialiseScrollpicker();
    });
  }
  /* The basic initialisation code */
  initialiseScrollpicker() {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      const scrollPicker =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('sh-scrollpicker');
      let hourValues = [];
      if (this.clockFormat === '12-hour') {
        hourValues = TwelveHourValues();
      } else {
        hourValues = TwentyFourHourValues();
      }
      const minuteValues = MinuteValues();
      const secValues = SecondValues();
      const amPmValues = AmPmValues();
      /**
       * here is where you initialise the scrollpicker
       * for example if you want to change width of each section,
       * or alignment of each section, etc.
       */
      if (scrollPicker) {
        if (this.clockFormat === '12-hour') {
          if (!this.mini) {
            scrollPicker.items = [hourValues, minuteValues, secValues, amPmValues];
          } else {
            scrollPicker.items = [hourValues, minuteValues, amPmValues];
          }
        } else {
          if (!this.mini) {
            scrollPicker.items = [hourValues, minuteValues, secValues];
          } else {
            scrollPicker.items = [hourValues, minuteValues];
          }
        }
        if (
          !this.h ||
          !this.m ||
          (!this.s && !this.mini) ||
          (!this.amPm && this.clockFormat === '12-hour')
        ) {
          this.updateWithSystemTime(this);
          const selectedTimeValues = this.getSelectedValuesArray();
          scrollPicker.scrollToValues(selectedTimeValues);
        } else {
          const selectedTimeValues = this.getSelectedValuesArray();
          scrollPicker.scrollToValues(selectedTimeValues);
        }
      }
    });
  }
  getSelectedValuesArray() {
    const valuesArray =
      this.mini && this.clockFormat === '12-hour'
        ? [this.h.toString(), this.m.toString(), this.amPm]
        : !this.mini && this.clockFormat === '24-hour'
          ? [this.h.toString(), this.m.toString(), this.s.toString()]
          : this.mini && this.clockFormat === '24-hour'
            ? [this.h.toString(), this.m.toString()]
            : !this.mini && this.clockFormat === '12-hour'
              ? [this.h.toString(), this.m.toString(), this.s.toString(), this.amPm]
              : '';
    return valuesArray;
  }
  updateValueFromScrollPicker(scrollpicker) {
    if (!this.mini) {
      this.s = scrollpicker.values[2];
    }
    this.m = scrollpicker.values[1];
    this.h = scrollpicker.values[0];
    if (this.clockFormat === '12-hour') {
      this.amPm = this.mini ? scrollpicker.values[2] : scrollpicker.values[3];
    }
  }
  closeMobileDrawer() {
    this.showDrawerDOM = false;
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.documentClickListener);
    super.disconnectedCallback();
  }
};
__decorate([event()], SHTimepicker.prototype, 'condensedChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'valChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'miniChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'hChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'mChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'sChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'amPmChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'stepChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'clockFormatChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'timeValueChangedEvent', void 0);
__decorate([event()], SHTimepicker.prototype, 'noClearChangedEvent', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTimepicker.prototype, 'active', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTimepicker.prototype, 'mini', void 0);
__decorate([property({ type: Number, reflect: true })], SHTimepicker.prototype, 'step', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepicker.prototype,
  'responsive',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHTimepicker.prototype, 'label', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepicker.prototype,
  'disabled',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHTimepicker.prototype, 'h', void 0);
__decorate([property({ type: String, reflect: true })], SHTimepicker.prototype, 'm', void 0);
__decorate([property({ type: String, reflect: true })], SHTimepicker.prototype, 's', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'am-pm' })],
  SHTimepicker.prototype,
  'amPm',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHTimepicker.prototype, 'val', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'clock-format' })],
  SHTimepicker.prototype,
  'clockFormat',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHTimepicker.prototype, 'error', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'error-message' })],
  SHTimepicker.prototype,
  'errorMessage',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHTimepicker.prototype, 'hint', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTimepicker.prototype, 'success', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTimepicker.prototype, 'safety', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepicker.prototype,
  'noBorder',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepicker.prototype,
  'mandatory',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHTimepicker.prototype, 'open', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTimepicker.prototype, 'close', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepicker.prototype,
  'condensed',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-clear' })],
  SHTimepicker.prototype,
  'noClear',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepicker.prototype,
  'showDrawerDOM',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHTimepicker.prototype,
  'showOverlay',
  void 0
);
SHTimepicker = __decorate([customElement('sh-timepicker')], SHTimepicker);
export { SHTimepicker };
