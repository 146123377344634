// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { ControlStateMixin } from '../../utils/control-state-mixin.js';
import { dispatchCustomEvent } from '../../utils/custom-event-dispatcher.js';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction.js';
import { KEYCODE } from '../../utils/keycode.js';
import { TooltipEllipsisCheckerMixin } from '../../utils/tooltip-ellipsis-checker-mixin';
import tabItemStyles from './sh-tab-item.lit.scss.js';
/**
 * @slot functions - For patient tabs, this slot holds function icons such as report and data protection. Is only visible on active tabs.
 * @fires clicked - Dispatched when clicked on the tab item.
 * @fires closed - Dispatched when a patient tab's 'close' item is clicked.
 */
let SHTabItem = class SHTabItem extends FocusBlurMixin(
  ControlStateMixin(TooltipEllipsisCheckerMixin(LitElement))
) {
  constructor() {
    super(...arguments);
    /**Defines the size of an active tab. Supports two values l (23.5rem) and s (17.5rem).*/
    this.size = 'm';
  }
  static get styles() {
    return [sharedStyles, tabItemStyles];
  }
  render() {
    return html`
      ${this.href
        ? html`<a href="${this.href}">
            <div class="href-wrapper">${this.getTabItemTemplate()}</div>
          </a>`
        : this.getTabItemTemplate()}
      ${!this.sticky && this.patient
        ? html` <sh-icon
            icon="cancel"
            button
            size="s"
            @click="${(e) => this.removeTab(e)}"
            id="close"
            tabindex="0"
          ></sh-icon>`
        : ''}
    `;
  }
  getTabItemTemplate() {
    return html`${this.patient && !this.icon
      ? html` <sh-icon icon="patient"></sh-icon>`
      : this.icon
        ? html` <sh-icon icon="${this.icon}" id="tab-item-icon"></sh-icon>`
        : ''}
    ${this.label || this.name || this.info
      ? html` <div class="text">
          ${this.label
            ? html` <div class="label" @mouseenter="${(e) => this.showTooltip(e, this.label)}">
                ${this.label}
              </div>`
            : ''}
          ${this.collapsed && !this.label && this.name
            ? html` <div class="name">${this.name}</div>`
            : ''}
          ${this.patient && this.info
            ? html` <div class="info" @mouseenter="${(e) => this.showTooltip(e, this.info)}">
                ${this.info}
              </div>`
            : ''}
          <sh-tooltip placement="bottom-left" position-fixed-adjustment></sh-tooltip>
        </div>`
      : ''}
    ${!this.patient &&
    this.badge !== null &&
    (this.badge === 'error' || this.badge === 'success' || this.badge === 'warning')
      ? html` <sh-badge type="${this.badge}" ?disabled="${this.disabled}"></sh-badge>`
      : ''}
    ${!this.patient &&
    this.badge !== null &&
    this.badge &&
    this.badge !== '' &&
    this.badge !== 'error' &&
    this.badge !== 'success' &&
    this.badge !== 'warning'
      ? html` <sh-badge label="${this.badge}" ?disabled="${this.disabled}"></sh-badge>`
      : ''}
    ${!this.patient && this.badge !== null && this.badge === '' && this.closable
      ? html` <sh-badge ?disabled="${this.disabled}"></sh-badge>`
      : ''}
    ${!this.patient && this.closable && !this.disabled
      ? html` <sh-icon
          icon="cancel"
          size="s"
          button
          ?disabled="${this.disabled}"
          class="tab-close"
          @click="${(e) => this.removeTab(e)}"
        ></sh-icon>`
      : ''}
    ${this.patient ? html` <slot name="functions"></slot>` : ''}
    ${!this.patient && this.badge !== null && this.badge === '' && !this.closable
      ? html` <sh-badge ?disabled="${this.disabled}"></sh-badge>`
      : ''} `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'tab');
    }
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    this.addEventListener('focus', this.handleFocus);
    this.addEventListener('blur', this.handleBlur);
  }
  firstUpdated() {
    this.setContext();
    this.addEventListener('click', this.clickEventListener);
    //only active tab is focusable
    if (!this.active) {
      this.setAttribute('tabindex', '-1');
    } else {
      this.setAttribute('tabindex', '0');
    }
  }
  update(changedProperties) {
    //if active changes adjust tabindex
    if (changedProperties.has('active')) {
      if (!this.active) {
        this.setAttribute('tabindex', '-1');
      } else {
        this.setAttribute('tabindex', '0');
        dispatchCustomEvent(this, 'item-active');
      }
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    const listOfProperties = [
      'label',
      'name',
      'info',
      'href',
      'patient',
      'active',
      'disabled',
      'icon',
      'size',
      'collapsed',
      'condensed',
      'sticky',
      'badge',
    ];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  showTooltip(e, content) {
    if (!deviceIdentifier.isTouchDevice()) {
      const tooltip = this.renderRoot.querySelector('sh-tooltip');
      if (content && tooltip) {
        const target = e.target;
        const isEllipsis = this.ellipsisChecker(
          target.scrollWidth,
          target.offsetWidth,
          target.scrollHeight,
          target.offsetHeight,
          'horizontal'
        );
        if (isEllipsis) {
          tooltip.target = target;
          tooltip.visible = true;
          tooltip.label = content;
        } else {
          tooltip.target = '';
          tooltip.visible = false;
          tooltip.label = '';
        }
      }
    }
  }
  setContext() {
    const tabs = this.closest('sh-tabs');
    if (tabs) {
      this.accessContext = tabs.accessContext;
      this.footerContext = tabs.footerContext;
    }
  }
  clickEventListener(event) {
    this.dispatchClickedEvent(event);
    if (this.href === undefined || this.href === null) return;
    if (
      !(
        (event === null || event === void 0 ? void 0 : event.ctrlKey) ||
        (event === null || event === void 0 ? void 0 : event.metaKey)
      )
    ) {
      window.location.href = this.href;
    }
  }
  removeTab(e) {
    e.stopPropagation();
    dispatchCustomEvent(this, 'closed');
  }
  dispatchClickedEvent(event) {
    if (
      !(
        (event === null || event === void 0 ? void 0 : event.ctrlKey) ||
        (event === null || event === void 0 ? void 0 : event.metaKey)
      )
    ) {
      dispatchCustomEvent(this, 'clicked');
      this.active = true;
    }
  }
  /**
   *
   * Overriding handleKeyup function of
   * FocusBlurMixin to satisfy requirements
   * of tab component.
   *
   */
  handleKeyup(e) {
    var _a, _b, _c;
    const siblings =
      (_a = this.parentElement) === null || _a === void 0
        ? void 0
        : _a.querySelectorAll('SH-TAB-ITEM:not([disabled])');
    let target;
    if (keyboardInteraction(e, KEYCODE.TAB) && !this.disabled) {
      if (
        document.activeElement === this &&
        !((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.activeElement)
      ) {
        this.classList.add('item-focused');
      } else {
        this.classList.remove('item-focused');
      }
    }
    if (
      (keyboardInteraction(e, KEYCODE.SPACE) || keyboardInteraction(e, KEYCODE.ENTER)) &&
      document.activeElement === this &&
      !((_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.activeElement)
    ) {
      this.dispatchClickedEvent(e);
    } else if (keyboardInteraction(e, KEYCODE.ARROW_RIGHT)) {
      //next not disabled sibling
      const posThis = Array.from(siblings).indexOf(this);
      if (posThis === siblings.length - 1) {
        target = siblings[0];
      } else {
        target = siblings[posThis + 1];
      }
      this.handleArrowInput(e, target);
    } else if (keyboardInteraction(e, KEYCODE.ARROW_LEFT)) {
      //previous not disabled sibling
      const posThis = Array.from(siblings).indexOf(this);
      if (posThis === 0) {
        target = siblings[siblings.length - 1];
      } else {
        target = siblings[posThis - 1];
      }
      this.handleArrowInput(e, target);
    }
  }
  handleArrowInput(e, target) {
    if (target) {
      e.stopPropagation();
      target.focus();
      target.classList.add('item-focused');
    }
  }
  handleFocus() {
    super.handleFocus();
    this.addEventListener('keyup', this.handleKeyup);
  }
  handleBlur() {
    super.handleBlur();
    this.removeEventListener('keyup', this.handleKeyup);
  }
};
__decorate([property({ type: String, reflect: true })], SHTabItem.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHTabItem.prototype, 'name', void 0);
__decorate([property({ type: String, reflect: true })], SHTabItem.prototype, 'info', void 0);
__decorate([property({ type: String, reflect: true })], SHTabItem.prototype, 'href', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabItem.prototype, 'patient', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabItem.prototype, 'active', void 0);
__decorate([property({ type: String, reflect: true })], SHTabItem.prototype, 'icon', void 0);
__decorate([property({ type: String, reflect: true })], SHTabItem.prototype, 'size', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabItem.prototype, 'collapsed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabItem.prototype, 'condensed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabItem.prototype, 'sticky', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'access-context' })],
  SHTabItem.prototype,
  'accessContext',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'footer-context' })],
  SHTabItem.prototype,
  'footerContext',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHTabItem.prototype, 'badge', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabItem.prototype, 'closable', void 0);
SHTabItem = __decorate([customElement('sh-tab-item')], SHTabItem);
export { SHTabItem };
