// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

/*
A decimal separator is a symbol used to separate the integer part from the fractional part of a number written in decimal form (e.g., "." or "," in 12.45 or 12,45)
decimalSeparator can be used to identify the type of decimal separator based on browser language
*/

export const decimalSeparator = () => {
  let decimalSeperatorContent = '.';
  const nonEuropeanNumberFormat = '120.94';
  const EuropeanNumberFormat = '120,94';
  if (Number(nonEuropeanNumberFormat).toLocaleString() === EuropeanNumberFormat) {
    decimalSeperatorContent = ',';
  } else if (Number(nonEuropeanNumberFormat).toLocaleString() === nonEuropeanNumberFormat) {
    decimalSeperatorContent = '.';
  }
  return decimalSeperatorContent;
};
