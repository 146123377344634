// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import mediaPlayerStyles from './sh-media-player.lit.scss.js';
let SHMediaPlayer = class SHMediaPlayer extends LitElement {
  constructor() {
    super(...arguments);
    /** This affects both the media (e.g., video) and its native poster, making them fit or fill the media area without altering aspect ratios. Possible values are `fit` & `fill`.*/
    this.imageContain = 'fit';
    /** @ignore */
    this.emptyFooter = true;
    /** @ignore */
    this.isSpinnerVisible = false;
  }
  static get styles() {
    return [mediaPlayerStyles];
  }
  render() {
    return html`
      <slot @slotchange="${(e) => this.bodySlotChange(e)}"></slot>
      ${this.isSpinnerVisible ? html` <sh-spinner class="spinner"></sh-spinner>` : ''}
      <slot
        name="footer"
        @slotchange="${(e) => this.footerSlotChange(e)}"
        class="${this.emptyFooter ? 'emptyFooter' : ''}"
      ></slot>
    `;
  }
  bodySlotChange(e) {
    var _a, _b;
    this.video = !this.video ? this.querySelector('video') : this.video;
    if (this.video) {
      this.video.controls = false;
    }
    (_a = this.video) === null || _a === void 0
      ? void 0
      : _a.addEventListener('waiting', () => {
          this.isSpinnerVisible = true;
        });
    (_b = this.video) === null || _b === void 0
      ? void 0
      : _b.addEventListener('playing', () => {
          this.isSpinnerVisible = false;
        });
    const target = e.target;
    const mediaControls =
      target === null || target === void 0
        ? void 0
        : target
            .assignedNodes({
              flatten: true,
            })
            .filter((x) => x.tagName === 'SH-MEDIA-CONTROLS');
    if (mediaControls.length > 0) {
      const mediaControl = mediaControls[0];
      mediaControl.backgroundStyle = mediaControl.backgroundStyle
        ? mediaControl.backgroundStyle
        : 'blur';
    }
  }
  footerSlotChange(e) {
    const target = e.target;
    const mediaControls =
      target === null || target === void 0
        ? void 0
        : target
            .assignedNodes({
              flatten: true,
            })
            .filter((x) => x.tagName === 'SH-MEDIA-CONTROLS');
    if (e.target instanceof HTMLSlotElement) {
      this.updateFooterSlotFlag(e.target);
    }
    if (mediaControls.length > 0) {
      const mediaControl = mediaControls[0];
      mediaControl.backgroundStyle = mediaControl.backgroundStyle
        ? mediaControl.backgroundStyle
        : 'flat';
    }
  }
  updateFooterSlotFlag(slot) {
    const footerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = footerNodes === 0;
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('progress-changed', (e) => {
      this.handleProgressChange(e);
    });
  }
  disconnectedCallback() {
    this.removeEventListener('progress-changed', (e) => {
      this.handleProgressChange(e);
    });
    super.disconnectedCallback();
  }
  handleProgressChange(e) {
    var _a;
    if ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.value) {
      const video = this.video;
      const value = e.detail.value;
      video.currentTime = (parseFloat(value) / 100) * (video.duration ? video.duration : 0);
    }
  }
};
__decorate(
  [property({ type: String, reflect: true, attribute: 'image-contain' })],
  SHMediaPlayer.prototype,
  'imageContain',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'empty-footer' })],
  SHMediaPlayer.prototype,
  'emptyFooter',
  void 0
);
__decorate([property({ type: Boolean })], SHMediaPlayer.prototype, 'isSpinnerVisible', void 0);
SHMediaPlayer = __decorate([customElement('sh-media-player')], SHMediaPlayer);
export { SHMediaPlayer };
