// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { sharedStyles } from '../../styles/shared-styles.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import modalStyles from './sh-modal.lit.scss.js';
export const modalListOfProperties = [
  'label',
  'visible',
  'type',
  'icon',
  'sticky',
  'modalHeight',
  'modalWidth',
];
/**
 * @attr {String} modal-height -Defines the height of the modal. Takes a number or css attribute (e.g. fit-content).
 * @attr {String} modal-width - Defines the width of the modal. Takes a number or css attribute (e.g. fit-content).
 * @attr {String} icon-color - Sets the color of the icon added through `icon` property.
 * @slot - The slot where the main content is rendered.
 * @slot header - Takes children such as tabs.
 * @slot functions - Takes children such as icons.
 * @slot footer - Takes buttons as children.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide emitted when the label property changes.
 * @fires visible-changed {VisiblePropertyChangedEvent} - *hide emitted when the visible property changes.
 * @fires type-changed {TypePropertyChangedEvent} - *hide emitted when the type property changes.
 * @fires icon-changed {IconPropertyChangedEvent} - *hide emitted when the icon property changes.
 * @fires sticky-changed {StickyPropertyChangedEvent} - *hide emitted when the sticky property changes.
 * @fires modal-height-changed {ModalHeightPropertyChangedEvent} - *hide emitted when the modalHeight property changes.
 * @fires modal-width-changed {ModalWidthPropertyChangedEvent} - *hide emitted when the modalWidth property changes.
 * @fires modal-closed {CustomEvent<{type:string}>} - Dispatched when closing the modal.
 */
let SHModal = class SHModal extends ShuiLitElement {
  static get styles() {
    return [sharedStyles, modalStyles];
  }
  render() {
    return html`
      <div
        class="modal-wrapper"
        style="height: ${this.modalHeight}; width: ${this.modalWidth}"
        ?empty-header=${this.emptyHeader}
        ?empty-footer=${this.emptyFooter}
      >
        <focus-trap class="focus-wrapper">
          <div class="label-wrapper">
            ${this.icon
              ? html` <sh-icon
                  icon="${this.icon}"
                  .color="${this.iconColor}"
                  class="modal-icon"
                  size="s"
                ></sh-icon>`
              : ''}
            ${this.type === 'error' ? html` <sh-icon icon="error" size="s"></sh-icon>` : ''}
            ${this.type === 'alert' || this.type === 'warning'
              ? html` <sh-icon icon="warning" size="s"></sh-icon>`
              : ''}
            ${this.type === 'confirmation' || this.type === 'success'
              ? html` <sh-icon icon="success" size="s"></sh-icon>`
              : ''}
            <div class="modal-label">${this.label}</div>
            <div class="functions-wrapper">
              <slot name="functions"></slot>
              ${!this.sticky
                ? html` <sh-icon
                    button
                    icon="cancel"
                    class="close-button"
                    size="s"
                    @click="${(e) => this.closeModal(e)}"
                  ></sh-icon>`
                : ''}
            </div>
          </div>
          <div class="header-wrapper">
            <div class="header-slot-wrapper">
              <slot
                name="header"
                class="header"
                @slotchange="${(e) => this.updateHeaderSlotFlag(e.target)}"
              ></slot>
            </div>
            ${!this.sticky
              ? html` <sh-icon
                  button
                  icon="cancel"
                  class="close-button-2"
                  size="s"
                  @click="${(e) => this.closeModal(e)}"
                ></sh-icon>`
              : ''}
          </div>
          <div class="body-wrapper">
            <slot></slot>
          </div>
          <div class="footer-wrapper">
            <slot
              name="footer"
              class="footer"
              @slotchange="${(e) => this.updateFooterSlotFlag(e.target)}"
            ></slot>
          </div>
        </focus-trap>
      </div>
    `;
  }
  constructor() {
    super();
    /** Defines the height of the modal. Takes a number or css attribute (e.g. fit-content). */
    this.modalHeight = '30rem';
    /** Defines the width of the modal. Takes a number or css attribute (e.g. fit-content). */
    this.modalWidth = '30rem';
    /** Defines if the modal is shown or not. */
    this.visible = false;
    this.emptyFooter = true;
    this.emptyHeader = true;
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'dialog');
    }
    if (!this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
  }
  updated(changedProperties) {
    if (changedProperties.has('visible')) {
      this.handleVisibleChanged();
    }
    dispatchAttributeChangedEventTypesafe(this, changedProperties, modalListOfProperties);
  }
  firstUpdated() {
    var _a;
    this.addEventListener('mousedown', this.closeNonstickyModal);
    this.addEventListener('dblclick', this.closeNonstickyModal);
    const modalWrapper =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.modal-wrapper');
    modalWrapper === null || modalWrapper === void 0
      ? void 0
      : modalWrapper.addEventListener('mousedown', (e) => {
          e.stopPropagation();
        });
    modalWrapper === null || modalWrapper === void 0
      ? void 0
      : modalWrapper.addEventListener('dblclick', (e) => {
          e.stopPropagation();
        });
    this.keyEventHandler = this.handleKeyup.bind(this);
  }
  updateHeaderSlotFlag(slot) {
    const headerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyHeader = headerNodes === 0;
  }
  updateFooterSlotFlag(slot) {
    const footerNodes = slot.assignedNodes({
      flatten: true,
    }).length;
    this.emptyFooter = footerNodes === 0;
  }
  handleVisibleChanged() {
    var _a, _b;
    const modalWrapper =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.modal-wrapper');
    if (this.visible) {
      this.focus();
      modalWrapper.style.outline = '0';
      document.addEventListener('keyup', this.keyEventHandler);
      ((_b = this.shadowRoot) === null || _b === void 0
        ? void 0
        : _b.querySelector('.body-wrapper')
      ).scrollTop = 0;
    } else {
      document.removeEventListener('keyup', this.keyEventHandler);
    }
  }
  handleKeyup(e) {
    const target = e.target;
    e.stopPropagation();
    if (e.code === 'Escape') {
      this.closeNonstickyModal(e);
    }
    if (e.code === 'Tab' && !this.contains(target)) {
      this.focus();
    }
  }
  closeNonstickyModal(e) {
    // Checking for sticky modal
    if (!this.sticky) {
      this.closeModal(e);
    }
  }
  closeModal(e) {
    this.visible = false;
    /*sending the detail parameter for logging method of closure of modal in the modal-closed event so that e.detail.type
        will give reason of closure (click, keyup, mousedown)*/
    this.modalClosedEvent.emit(
      new CustomEvent('modal-closed', {
        detail: { type: e.type },
      })
    );
  }
  disconnectedCallback() {
    document.removeEventListener('keyup', this.keyEventHandler);
    super.disconnectedCallback();
  }
};
__decorate([property({ type: String, reflect: true })], SHModal.prototype, 'label', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'modal-height' })],
  SHModal.prototype,
  'modalHeight',
  void 0
);
__decorate(
  [property({ type: String, reflect: true, attribute: 'modal-width' })],
  SHModal.prototype,
  'modalWidth',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHModal.prototype, 'type', void 0);
__decorate([property({ type: String, reflect: true })], SHModal.prototype, 'icon', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHModal.prototype, 'sticky', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHModal.prototype, 'visible', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'icon-color' })],
  SHModal.prototype,
  'iconColor',
  void 0
);
__decorate([property({ type: Boolean })], SHModal.prototype, 'emptyFooter', void 0);
__decorate([property({ type: Boolean })], SHModal.prototype, 'emptyHeader', void 0);
__decorate([event()], SHModal.prototype, 'modalClosedEvent', void 0);
__decorate([event()], SHModal.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHModal.prototype, 'iconChangedEvent', void 0);
__decorate([event()], SHModal.prototype, 'stickyChangedEvent', void 0);
__decorate([event()], SHModal.prototype, 'visibleChangedEvent', void 0);
__decorate([event()], SHModal.prototype, 'typeChangedEvent', void 0);
__decorate([event()], SHModal.prototype, 'modalHeightChangedEvent', void 0);
__decorate([event()], SHModal.prototype, 'modalWidthChangedEvent', void 0);
SHModal = __decorate([customElement('sh-modal')], SHModal);
export { SHModal };
