// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { ActivePropertyChangedEvent, NeutralPropertyChangedEvent } from './events.js';
import { EventEmitter, dispatchAttributeChangedEventTypesafe, event } from './event-decorator.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T> = new (...args: any[]) => T;
export const ToggleMixinListOfProperties = ['active', 'neutral'];

export function CheckboxToggleMixin<T extends Constructor<LitElement>>(superClass: T) {
  /**
   * @mixin CheckboxToggleMixinElement
   * @fires active-changed {ActivePropertyChangedEvent} - *hide Event emitted when active property changes
   * @fires neutral-changed {NeutralPropertyChangedEvent} -*hide Event emitted when active property changes
   */
  class CheckboxToggleMixinElement extends superClass {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;

    /**Default false. Defines if a Toggle is selected or not. Changes when clicked.*/
    @property({ type: Boolean, reflect: true })
    public active: boolean | undefined;

    /** Default false. If true, the active state will be gray instead of orange. */
    @property({ type: Boolean, reflect: true })
    public neutral: boolean | undefined;

    @event() protected activeChangedEvent!: EventEmitter<ActivePropertyChangedEvent>;
    @event() protected neutralChangedEvent!: EventEmitter<NeutralPropertyChangedEvent>;

    override updated(changedProperties: Map<string, unknown>): void {
      super.updated(changedProperties);

      dispatchAttributeChangedEventTypesafe(this, changedProperties, ToggleMixinListOfProperties);
    }
    override firstUpdated() {
      this.addEventListener('click', () => this.handleClick());
      this.addEventListener('focus', () => this.handleFocus());
      this.addEventListener('blur', () => this.handleBlur());
    }
    protected handleClick() {
      this.active = !this.active;
    }
  }
  return CheckboxToggleMixinElement as unknown as Constructor<CheckboxToggleMixinInterface> & T;
}

/**
 * due to typescript limitations we must explicitly define a clas
 * with the externally used api here and cast the return value of our
 * mixin to this (intermediate unknown cast is so we don't need to have to list irrelevant fields)
 * read this: https://lit.dev/docs/composition/mixins/#mixins-in-typescript
 */
export declare class CheckboxToggleMixinInterface {
  public active: boolean | undefined;
  protected activeChangedEvent: EventEmitter<ActivePropertyChangedEvent>;
  protected neutralChangedEvent: EventEmitter<NeutralPropertyChangedEvent>;
  protected handleClick: () => void;
}
