import { __decorate } from 'tslib';
// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { event, ShuiLitElement } from '../../utils/event-decorator';
import privateCalenderViewStyles from './sh-private-calender-view.lit.scss.js';
import './sh-private-date-selector.js';
import './sh-private-month-selector.js';
import './sh-private-year-selector.js';
/**
 * A calendar-view component that switches the view between sh-private-date-selector,
 * sh-private-month-selector and sh-private-year-selector corresponding to the value given
 * to the currentView property.
 * @fires calendar-closed - *hide Dispatched when the calendar-view is closed.
 **/
let SHPrivateCalendarView = class SHPrivateCalendarView extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** The array of selected dates if any. Maximum it can have 2 selected dates for the case of range property
          and only one value if there is no range property.*/
    this.selectedDates = [];
    this.documentBodyClickListener = this.documentBodyClickActions.bind(this);
    /** @ignore */
    this.startMondayLocales = [];
  }
  static get styles() {
    return [privateCalenderViewStyles];
  }
  connectedCallback() {
    super.connectedCallback();
    document.body.addEventListener('click', this.documentBodyClickListener);
  }
  render() {
    return html`${this.currentView === 'date'
      ? html`<sh-private-date-selector
          .months="${this.months}"
          .month="${this.startingMonth}"
          .year="${this.startingYear}"
          .todaysDate="${this.todaysDate}"
          .selectedDates="${this.selectedDates}"
          .futureDateDisable="${this.futureDateDisable}"
          .pastDateDisable="${this.pastDateDisable}"
          .minDate="${this.minDate}"
          .maxDate="${this.maxDate}"
          .range="${this.range}"
          .startMondayLocales="${this.startMondayLocales}"
          .requireApply="${this.requireApply}"
          .firstDayOfWeek="${this.firstDayOfWeek}"
          @selected-dates-changed="${(e) => {
            e.stopPropagation();
            this.selectedDates = e.target.selectedDates;
          }}"
          @month-header-clicked="${(e) => {
            var _a;
            e.stopPropagation();
            this.startingYear = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.clickedYear;
            this.currentView = 'month';
          }}"
          .responsive="${this.responsive}"
          .locale="${this.locale}"
        ></sh-private-date-selector>`
      : ''}
    ${this.currentView === 'month'
      ? html`<sh-private-month-selector
          .months="${this.months}"
          .year="${this.startingYear}"
          .todaysDate="${this.getMonthViewFormatDate(this.todaysDate)}"
          .minDate="${this.getMonthViewFormatDate(this.minDate)}"
          .maxDate="${this.getMonthViewFormatDate(this.maxDate)}"
          .selectedDate="${this.getMonthViewSelectedDate(this.selectedDates)}"
          .futureDateDisable="${this.futureDateDisable}"
          .pastDateDisable="${this.pastDateDisable}"
          .locale="${this.locale}"
          @month-clicked="${(e) => {
            e.stopPropagation();
            this.startingMonth = e.detail.clickedMonth;
            this.startingYear = e.detail.clickedYear;
            this.currentView = 'date';
          }}"
          @year-header-clicked="${(e) => {
            e.stopPropagation();
            this.startingDecade = e.detail.belongingDecade;
            this.currentView = 'year';
          }}"
          .responsive="${this.responsive}"
        ></sh-private-month-selector>`
      : ''}
    ${this.currentView === 'year'
      ? html`<sh-private-year-selector
          .months="${this.months}"
          .decade="${this.startingDecade}"
          .todaysDate="${this.getYearViewFormatDate(this.todaysDate)}"
          .selectedDate="${this.getYearViewSelectedDate(this.selectedDates)}"
          .minDate="${this.getYearViewFormatDate(this.minDate)}"
          .maxDate="${this.getYearViewFormatDate(this.maxDate)}"
          .futureDateDisable="${this.futureDateDisable}"
          .pastDateDisable="${this.pastDateDisable}"
          @year-clicked="${(e) => {
            e.stopPropagation();
            this.startingYear = e.detail.clickedYear;
            this.currentView = 'month';
          }}"
          .responsive="${this.responsive}"
        ></sh-private-year-selector>`
      : ''}
    ${this.requireApply
      ? html`<div class="datepicker-footer">
          <slot name="cancel-button"></slot>
          <slot name="apply-button"></slot>
        </div>`
      : nothing} `;
  }
  update(changedProperties) {
    if (changedProperties.has('currentView') && this.currentView) {
      this.updateComplete.then(() => {
        this.openCurrentView();
      });
    }
    super.update(changedProperties);
  }
  disconnectedCallback() {
    document.body.removeEventListener('click', this.documentBodyClickListener);
    super.disconnectedCallback();
  }
  documentBodyClickActions(e) {
    if (
      e.composedPath().indexOf(this) === -1 &&
      e.composedPath().indexOf(this.attachNode) === -1 &&
      !this.responsive
    ) {
      this.calendarClosedEvent.emit(new CustomEvent('calendar-closed'));
    }
  }
  /**
   * Returns a copy of the input date
   * in the format required for month-view.
   *
   * @param {Date} date
   * @returns copy of date formatted for month-view
   */
  getMonthViewFormatDate(date) {
    let dateCopy;
    if (date) {
      dateCopy = new Date(date.getTime());
      dateCopy.setDate(1);
      dateCopy.setHours(0, 0, 0, 0);
    }
    return dateCopy;
  }
  /**
   * Returns a copy of the input date
   * in the format required for year-view.
   *
   * @param {Date} date
   * @returns copy of date formatted for year-view
   */
  getYearViewFormatDate(date) {
    let dateCopy;
    if (date) {
      dateCopy = new Date(date.getTime());
      dateCopy.setMonth(0, 1);
      dateCopy.setHours(0, 0, 0, 0);
    }
    return dateCopy;
  }
  getMonthViewSelectedDate(selectedDates) {
    if (selectedDates.length === 2 && this.range) {
      return this.getMonthViewFormatDate(this.selectedDates[1]);
    } else if (selectedDates.length === 1 && !this.range) {
      return this.getMonthViewFormatDate(this.selectedDates[0]);
    } else {
      return null;
    }
  }
  getYearViewSelectedDate(selectedDates) {
    if (selectedDates.length === 2 && this.range) {
      return this.getYearViewFormatDate(this.selectedDates[1]);
    } else if (selectedDates.length === 1 && !this.range) {
      return this.getYearViewFormatDate(this.selectedDates[0]);
    } else {
      return null;
    }
  }
  openCurrentView() {
    var _a, _b, _c;
    switch (this.currentView) {
      case 'date':
        {
          const dateSelector =
            (_a = this.shadowRoot) === null || _a === void 0
              ? void 0
              : _a.querySelector('sh-private-date-selector');
          dateSelector.generateCalendarDatas();
          dateSelector === null || dateSelector === void 0 ? void 0 : dateSelector.focus();
        }
        break;
      case 'month':
        {
          const monthSelector =
            (_b = this.shadowRoot) === null || _b === void 0
              ? void 0
              : _b.querySelector('sh-private-month-selector');
          monthSelector.generateMonthViewData();
          monthSelector === null || monthSelector === void 0 ? void 0 : monthSelector.focus();
        }
        break;
      case 'year':
        {
          const yearSelector =
            (_c = this.shadowRoot) === null || _c === void 0
              ? void 0
              : _c.querySelector('sh-private-year-selector');
          yearSelector.generateYearViewData();
          yearSelector === null || yearSelector === void 0 ? void 0 : yearSelector.focus();
        }
        break;
      default: {
      }
    }
  }
};
__decorate([property({ type: String })], SHPrivateCalendarView.prototype, 'currentView', void 0);
__decorate([property({ type: Number })], SHPrivateCalendarView.prototype, 'startingMonth', void 0);
__decorate([property({ type: Number })], SHPrivateCalendarView.prototype, 'startingYear', void 0);
__decorate([property({ type: Number })], SHPrivateCalendarView.prototype, 'startingDecade', void 0);
__decorate([property({ type: Object })], SHPrivateCalendarView.prototype, 'todaysDate', void 0);
__decorate([property({ type: Array })], SHPrivateCalendarView.prototype, 'selectedDates', void 0);
__decorate(
  [property({ type: Boolean })],
  SHPrivateCalendarView.prototype,
  'futureDateDisable',
  void 0
);
__decorate(
  [property({ type: Boolean })],
  SHPrivateCalendarView.prototype,
  'pastDateDisable',
  void 0
);
__decorate([property({ type: Object })], SHPrivateCalendarView.prototype, 'minDate', void 0);
__decorate([property({ type: Object })], SHPrivateCalendarView.prototype, 'maxDate', void 0);
__decorate([property({ type: Number })], SHPrivateCalendarView.prototype, 'months', void 0);
__decorate([property({ type: Boolean })], SHPrivateCalendarView.prototype, 'range', void 0);
__decorate([property({ type: Object })], SHPrivateCalendarView.prototype, 'attachNode', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHPrivateCalendarView.prototype,
  'responsive',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHPrivateCalendarView.prototype,
  'close',
  void 0
);
__decorate([property({ type: String })], SHPrivateCalendarView.prototype, 'locale', void 0);
__decorate(
  [property({ type: String, reflect: true })],
  SHPrivateCalendarView.prototype,
  'firstDayOfWeek',
  void 0
);
__decorate(
  [property({ type: Array })],
  SHPrivateCalendarView.prototype,
  'startMondayLocales',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'require-apply' })],
  SHPrivateCalendarView.prototype,
  'requireApply',
  void 0
);
__decorate([event()], SHPrivateCalendarView.prototype, 'calendarClosedEvent', void 0);
SHPrivateCalendarView = __decorate(
  [customElement('sh-private-calendar-view')],
  SHPrivateCalendarView
);
export { SHPrivateCalendarView };
