// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT range-slider.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-slider-input-height=1.5rem] - height of show-value slider input
   */
  --sh-slider-input-height: 1.5rem;
  /* 
   * @cssprop [--sh-slider-input-width=2.5rem] - width of show-value slider input
   */
  --sh-slider-input-width: 2.5rem;
  /* 
   * @cssprop [--sh-slider-slider-area-height=2rem] - height of actual slider-area
   */
  --sh-slider-slider-area-height: 2rem;
  /* 
   * @cssprop [--sh-slider-slider-area-margin-top=0rem] - margin top of actual slider-area
   */
  --sh-slider-slider-area-margin-top: 0rem;
  /* 
   * @cssprop [--sh-slider-thumb-diameter=0.875rem] - diameter of the thumb
   */
  --sh-slider-thumb-diameter: 0.875rem;
  /* 
   * @cssprop [--sh-slider-track-height=0.125rem] - height of track
   */
  --sh-slider-track-height: 0.125rem;
  /* 
   * @cssprop [--sh-slider-input-padding=0.125rem 0.25rem] - padding of the input
   */
  --sh-slider-input-padding: 0.125rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-horizontal-tooltip-thumb-distance=0.5625rem] - distance between thumb and tooltip in horizontal slider
   */
  --sh-slider-horizontal-tooltip-thumb-distance: 0.5625rem;
  /* 
   * @cssprop [--sh-slider-tooltip-width=2.5rem] - width of tooltip
   */
  --sh-slider-tooltip-width: 2.5rem;
  /* 
   * @cssprop [--sh-slider-tooltip-height=1.5rem] - height of tooltip
   */
  --sh-slider-tooltip-height: 1.5rem;
  /* 
   * @cssprop [--sh-slider-tooltip-padding=0rem 0.25rem] - padding of tooltip- for range slider
   */
  --sh-slider-tooltip-padding: 0rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-tick-diameter=0.375rem] - diameter of round ticks without hover
   */
  --sh-slider-tick-diameter: 0.375rem;
  /* 
   * @cssprop [--sh-slider-tick-diameter-on-hover=0.5rem] - diameter of round ticks with hover
   */
  --sh-slider-tick-diameter-on-hover: 0.5rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-tooltip-thumb-distance=var(--sh-slider-horizontal-tooltip-thumb-distance)] - the distance between the thumb and the tooltip for the vertical slider
   */
  --sh-slider-vertical-slider-tooltip-thumb-distance: var(--sh-slider-horizontal-tooltip-thumb-distance);
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-slider-distance=1rem] - the distance between the input and the slider for the vertical slider
   */
  --sh-slider-vertical-slider-input-slider-distance: 1rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-container-width=4.5rem] - the width of the container of input of vertical slider
   */
  --sh-slider-vertical-slider-input-container-width: 4.5rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-input-label-input-width=1.9375rem] - the width of the input of vertical slider
   */
  --sh-slider-vertical-slider-input-label-input-width: 1.9375rem;
  /* 
   * @cssprop [--sh-slider-step-tick-width=0.0625rem] - width of the step tick
   */
  --sh-slider-step-tick-width: 0.0625rem;
  /* 
   * @cssprop [--sh-slider-step-tick-height=0.5rem] - height of the step tick
   */
  --sh-slider-step-tick-height: 0.5rem;
  /* 
   * @cssprop [--sh-slider-range-input-separator-width=0.25rem] - width of range input separator
   */
  --sh-slider-range-input-separator-width: 0.25rem;
  /* 
   * @cssprop [--sh-slider-range-input-separator-side-margin=0.25rem] - margin left and margin right of range input separator
   */
  --sh-slider-range-input-separator-side-margin: 0.25rem;
  /* 
   * @cssprop [--sh-slider-step-tick-min-width=2rem] - min-width between step-ticks
   */
  --sh-slider-step-tick-min-width: 2rem;
  /* 
   * @cssprop [--sh-slider-arrow-controls-inter-icon-gap=0rem] - gap between the icons in arrow-controls
   */
  --sh-slider-arrow-controls-inter-icon-gap: 0rem;
  /* 
   * @cssprop [--sh-slider-outer-wrapper-gap=0rem] - gap between the icons in arrow-controls
   */
  --sh-slider-outer-wrapper-gap: 0rem;
  /* 
   * @cssprop [--sh-slider-vertical-slider-span-padding-right=0.25rem] - padding right of span max value of vertocal slider
   */
  --sh-slider-vertical-slider-span-padding-right: 0.25rem;
  /* 
   * @cssprop [--sh-slider-input-padding-left-right=0.5rem] - combined left right padding of input
   */
  --sh-slider-input-padding-left-right: 0.5rem;
  /* 
   * @cssprop [--sh-slider-label-wrapper-input-padding=0rem 0.25rem] - padding of input label wrapper
   */
  --sh-slider-label-wrapper-input-padding: 0rem 0.25rem;
  /* 
   * @cssprop [--sh-slider-tooltip-display=flex] - display of the slider tooltip
   */
  --sh-slider-tooltip-display: flex;
}

:host {
  width: 100%;
  outline: 0;
  display: block;
}

.range-wrapper {
  position: relative;
  height: var(--sh-slider-slider-area-height);
  outline: none;
  display: flex;
  width: 100%;
  align-items: center;
  --available-slider-length: calc(100% - var(--sh-slider-thumb-diameter));
  --percent-lower-value: calc((var(--lower-value) - var(--min)) / (var(--max) - var(--min)));
  --percent-upper-value: calc((var(--upper-value) - var(--min)) / (var(--max) - var(--min)));
  --lower-value-left: calc(
    (var(--percent-lower-value) * var(--available-slider-length)) +
      (var(--sh-slider-thumb-diameter) / 2)
  );
  --upper-value-left: calc(
    (var(--percent-upper-value) * var(--available-slider-length)) +
      (var(--sh-slider-thumb-diameter) / 2)
  );
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: none;
}

#lower::-webkit-slider-thumb,
#upper::-webkit-slider-thumb {
  appearance: none;
  position: relative;
  width: var(--sh-slider-thumb-diameter);
  height: var(--sh-slider-thumb-diameter);
  cursor: pointer;
  border-radius: 50%;
  border: none;
  outline: 0;
  z-index: 1;
  background: rgba(var(--ui-5), var(--opacity-1));
  -webkit-appearance: none;
  user-select: none;
  -webkit-user-select: none;
  transition: var(--hover-transition-time) background;
  pointer-events: all;
}

#lower::-moz-range-thumb,
#upper::-moz-range-thumb {
  position: relative;
  width: var(--sh-slider-thumb-diameter);
  height: var(--sh-slider-thumb-diameter);
  cursor: pointer;
  border-radius: 50%;
  border: none;
  z-index: 1;
  background: rgba(var(--ui-5), var(--opacity-1));
  transition: var(--hover-transition-time) background;
  pointer-events: all;
}

:host(.touch-device) #lower::-webkit-slider-thumb,
:host(.touch-device) #upper::-webkit-slider-thumb {
  background-clip: padding-box;
  border: calc((2.5rem - var(--sh-slider-thumb-diameter)) / 2) solid transparent;
  box-sizing: unset;
}

:host(.touch-device) #handleTrackWrapper {
  height: 2.5rem;
}

:host(.touch-device) #rangeTrack {
  background-clip: padding-box;
}

:host(:not(.touch-device)) #rangeTrack {
  background-clip: padding-box;
}

:host(:not(.touch-device)) #lower:not(:active)::-webkit-slider-thumb:hover,
:host(:not(.touch-device)) #upper:not(:active)::-webkit-slider-thumb:hover {
  background: rgba(var(--ui-2), var(--opacity-1));
}

:host(:not(.touch-device)) #lower::-moz-range-thumb:hover,
:host(:not(.touch-device)) #upper::-moz-range-thumb:hover {
  background: rgba(var(--ui-2), var(--opacity-1));
}

:host(.touch-device) .thumbActive#lower::-webkit-slider-thumb,
:host(.touch-device) .thumbActive#upper::-webkit-slider-thumb {
  border: calc((2.5rem - var(--sh-slider-thumb-diameter)) / 2) solid rgba(var(--ui-3), var(--opacity-5));
  background-color: rgba(var(--ui-3), var(--opacity-1));
}

#lower::-webkit-slider-thumb:active,
#upper::-webkit-slider-thumb:active {
  background: rgba(var(--ui-3), var(--opacity-1));
}

#lower::-moz-range-thumb:active,
#upper::-moz-range-thumb:active {
  background: rgba(var(--ui-3), var(--opacity-1));
}

:host(.touch-device) #lower::-webkit-slider-runnable-track,
:host(.touch-device) #upper::-webkit-slider-runnable-track {
  --halo-ring-radius: calc((2.5rem - var(--sh-slider-thumb-diameter)) / 2);
  margin-left: calc(0rem - var(--halo-ring-radius));
  margin-right: calc(0rem - var(--halo-ring-radius));
}

.range-track {
  width: 100%;
  height: var(--sh-slider-track-height);
  outline: 0;
  background-color: rgba(var(--ui-1), var(--opacity-6));
  cursor: pointer;
  position: absolute;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.handle-track {
  height: var(--sh-slider-track-height);
  background: rgba(var(--ui-5), var(--opacity-1));
  cursor: pointer;
  width: 100%;
}

.handle-track-wrapper {
  height: 2rem;
  position: absolute;
  transition: var(--hover-transition-time) background;
  left: calc(var(--lower-value-left));
  width: calc(var(--upper-value-left) - var(--lower-value-left));
  display: flex;
  align-items: center;
}

#handleTrackWrapper {
  cursor: pointer;
  outline: 0;
}

#lower,
#upper {
  background-color: transparent;
  opacity: unset;
  pointer-events: none;
}

:host(:not(.touch-device)) .handle-track-wrapper:hover .handle-track,
.focus#lower::-webkit-slider-thumb,
.focus#upper::-webkit-slider-thumb {
  background: rgba(var(--ui-2), var(--opacity-1));
}

.active#lower::-webkit-slider-thumb,
.active#upper::-webkit-slider-thumb,
.active#handleTrack {
  background: rgba(var(--ui-3), var(--opacity-1));
}

.focus#lower::-moz-range-thumb,
.focus#upper::-moz-range-thumb {
  background: rgba(var(--ui-2), var(--opacity-1));
}

.active#lower::-moz-range-thumb,
.active#upper::-moz-range-thumb {
  background: rgba(var(--ui-3), var(--opacity-1));
}

/* disabled styles */
:host([disabled]) #upper::-webkit-slider-thumb,
:host([disabled]) #lower::-webkit-slider-thumb,
:host([disabled]) #handleTrack {
  background-color: rgba(var(--ui-6), var(--opacity-1));
  pointer-events: none;
}

:host([disabled]) #upper::-moz-range-thumb,
:host([disabled]) #lower::-moz-range-thumb {
  background: rgba(var(--ui-6), var(--opacity-1));
  pointer-events: none;
}

:host([disabled]) #rangeTrack {
  background-color: rgb(var(--ui-1), var(--opacity-7));
}

.label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-label {
  flex: 1 1 auto;
  padding-right: 0.5rem;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: var(--transition-time) all ease-in-out;
}

.label-wrapper input {
  outline: 0;
  border: none;
  height: var(--sh-slider-input-height);
  font: var(--body-1);
  line-height: var(--sh-slider-input-height);
  color: var(--text-secondary);
  background: rgba(var(--ui-1), var(--opacity-7));
  border-radius: 0.125rem;
  box-sizing: border-box;
  padding: var(--sh-slider-label-wrapper-input-padding);
  transition: var(--transition-time) all ease-in-out;
  text-align: center;
  width: max(var(--inp-w) + var(--sh-slider-input-padding-left-right), var(--sh-slider-input-width));
  -moz-appearance: textfield;
  flex-shrink: 0;
}

.value-separator {
  height: var(--sh-slider-input-height);
  width: var(--sh-slider-range-input-separator-width);
  text-align: center;
  line-height: var(--sh-slider-input-height);
  margin: 0 var(--sh-slider-range-input-separator-side-margin);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}

:host([disabled]) .value-separator {
  color: rgba(var(--ui-6), var(--opacity-1));
}

input::-webkit-inner-spin-button {
  display: none;
}

:host(:not([label])) .label-wrapper {
  display: none;
}

:host([show-value]) .label-wrapper input {
  display: inline-flex;
}

:host([disabled]) .slider-label,
:host([disabled]) .label-wrapper input {
  color: var(--text-disabled);
  opacity: unset;
}

/* stepTicks */
.step_ticks {
  position: absolute;
  padding: 0;
  display: none;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
}

.step_ticks li {
  height: var(--sh-slider-step-tick-height);
  width: var(--sh-slider-step-tick-width);
  -moz-user-select: none;
  -webkit-user-select: none;
  list-style: none;
  position: absolute;
}

:host .sh-step-thumb-item {
  cursor: pointer;
  outline: 0;
  background: rgba(var(--ui-1), var(--opacity-6));
  -moz-appearance: none;
  -webkit-appearance: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

:host([disabled]) .sh-step-thumb-item {
  background: rgba(var(--ui-1), var(--opacity-7));
}

/* Tooltip styles */
#lowerRangeTooltip,
#upperRangeTooltip {
  --thumb-radius: calc(var(--sh-slider-thumb-diameter) / 2);
  display: none;
  position: absolute;
  pointer-events: none;
  outline: 0;
  width: auto;
  padding: var(--sh-slider-tooltip-padding);
  min-width: var(--sh-slider-tooltip-width);
  height: var(--sh-slider-tooltip-height);
  text-align: center;
  background: var(--base-3);
  box-shadow: var(--shadow-raised);
  border-radius: 0.125rem;
  box-sizing: border-box;
  z-index: 5;
  transform: translateX(-50%) translateY(calc(-50% - var(--thumb-radius) - var(--sh-slider-horizontal-tooltip-thumb-distance)));
  justify-content: center;
  align-items: center;
}

#lowerRangeTooltip {
  left: var(--lower-value-left);
}

#upperRangeTooltip {
  left: var(--upper-value-left);
}

:host(:not([show-value]):hover:not(.touch-device):not([hide-tooltip])) #lowerRangeTooltip,
:host(:not([show-value]):hover:not(.touch-device):not([hide-tooltip])) #upperRangeTooltip {
  display: flex;
}

:host .markers.step_ticks {
  display: flex;
}

/* tab-outline */
:host .tab_outline.handle-track-wrapper {
  outline: var(--focus-outline) !important;
}

:host .tab_outline.handle-track {
  background: rgba(var(--ui-2), var(--opacity-1));
}

:host(:not([show-value])) .tab_tooltip#lowerRangeTooltip,
:host(:not([show-value])) .tab_tooltip#upperRangeTooltip {
  display: flex;
}

:host .tab_outline#lower::-webkit-slider-thumb,
:host .tab_outline#upper::-webkit-slider-thumb {
  outline: var(--focus-outline);
  background: rgba(var(--ui-2), var(--opacity-1));
}

:host .tab_outline#lower::-moz-range-thumb,
:host .tab_outline#upper::-moz-range-thumb {
  outline: var(--focus-outline);
  background: rgba(var(--ui-2), var(--opacity-1));
}

.sh-step-thumb-item:focus-visible {
  outline: var(--focus-outline);
}`;