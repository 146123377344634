// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

import { SVGTemplateResult } from 'lit';
import adiposeAnatomical from './assets/adult-anatomical-adipose.svg.js';
import adultAnatomical from './assets/adult-anatomical.svg.js';
import adultMultiLevelAdipose from './assets/adult-multi-level-adipose.svg.js';
import adultMultiLevel from './assets/adult-multi-level.svg.js';
import adultNeuroAdipose from './assets/adult-neuro-adipose.svg.js';
import adultNeuro from './assets/adult-neuro.svg.js';
import childAnatomical from './assets/child-anatomical.svg.js';
import childMultiLevel from './assets/child-multi-level.svg.js';
import childNeuro from './assets/child-neuro.svg.js';
import ctAdultAnatomical from './assets/ct-adult-anatomical.svg.js';
import ctAdultVascular from './assets/ct-adult-vascular.svg.js';
import ctChildAnatomical from './assets/ct-child-anatomical.svg.js';
import ctChildVascular from './assets/ct-child-vascular.svg.js';
import xpAdultFlouro from './assets/xp-adult-fluoro.svg.js';
import xpAllInOne from './assets/xp-all-in-one.svg.js';

export function getPatientIllustration(image: string): SVGTemplateResult {
  switch (image) {
    case 'ct_adult_vascular':
      return ctAdultVascular;

    case 'ct_child_anatomical':
      return ctChildAnatomical;

    case 'ct_child_vascular':
      return ctChildVascular;

    case 'xp_all_in_one':
      return xpAllInOne;

    case 'xp_adult_fluoro':
      return xpAdultFlouro;

    case 'adult_anatomical':
      return adultAnatomical;

    case 'child_anatomical':
      return childAnatomical;

    case 'adult_anatomical_adipose':
      return adiposeAnatomical;

    case 'adult_multi-level':
      return adultMultiLevel;

    case 'child_multi-level':
      return childMultiLevel;

    case 'adult_multi-level_adipose':
      return adultMultiLevelAdipose;

    case 'adult_neuro':
      return adultNeuro;

    case 'child_neuro':
      return childNeuro;

    case 'adult_neuro_adipose':
      return adultNeuroAdipose;

    default:
      return ctAdultAnatomical;
  }
}
