// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-box.lit.scss INSTEAD!
import { css } from 'lit';
export default css`[sh-scale=s] {
  /* 
   * @cssprop [--sh-box-color-surface=var(--base-3)] - Background color of the box
   */
  --sh-box-color-surface: var(--base-3);
  /* 
   * @cssprop [--sh-box-color-surface-secondary=var(--base-2)] - Secondary background color of the box
   */
  --sh-box-color-surface-secondary: var(--base-2);
  /* 
   * @cssprop [--sh-box-color-surface-tertiary=rgba(var(--ui-1), var(--opacity-50))] - Tertiary background color of the box
   */
  --sh-box-color-surface-tertiary: rgba(var(--ui-1), var(--opacity-50));
  /* 
   * @cssprop [--sh-box-color-transparent=transparent] - Transparent color
   */
  --sh-box-color-transparent: transparent;
  /* 
   * @cssprop [--sh-box-padding-wide=1.5rem] - Wide padding for the box
   */
  --sh-box-padding-wide: 1.5rem;
  /* 
   * @cssprop [--sh-box-padding-compact=1rem] - Compact padding for the box
   */
  --sh-box-padding-compact: 1rem;
  /* 
   * @cssprop [--sh-box-padding-supercompact=0.5rem] - Supercompact padding for the box
   */
  --sh-box-padding-supercompact: 0.5rem;
  /* 
   * @cssprop [--sh-box-padding-none=0] - No padding for the box
   */
  --sh-box-padding-none: 0;
  /* 
   * @cssprop [--sh-box-content-gap-0=0] - No gap between content
   */
  --sh-box-content-gap-0: 0;
  /* 
   * @cssprop [--sh-box-content-gap-1=1px] - 1px gap between content
   */
  --sh-box-content-gap-1: 1px;
  /* 
   * @cssprop [--sh-box-content-gap-2=2px] - 2px gap between content
   */
  --sh-box-content-gap-2: 2px;
  /* 
   * @cssprop [--sh-box-content-gap-4=4px] - 4px gap between content
   */
  --sh-box-content-gap-4: 4px;
  /* 
   * @cssprop [--sh-box-content-gap-8=8px] - 8px gap between content
   */
  --sh-box-content-gap-8: 8px;
  /* 
   * @cssprop [--sh-box-elevation-none=none] - No elevation for the box
   */
  --sh-box-elevation-none: none;
  /* 
   * @cssprop [--sh-box-elevation-subtle=var(--shadow-m-subtle)] - Subtle elevation for the box
   */
  --sh-box-elevation-subtle: var(--shadow-m-subtle);
  /* 
   * @cssprop [--sh-box-elevation-moderate=var(--shadow-l-moderate)] - Moderate elevation for the box
   */
  --sh-box-elevation-moderate: var(--shadow-l-moderate);
  /* 
   * @cssprop [--sh-box-elevation-spotlight=var(--shadow-xl-moderate)] - Spotlight elevation for the box
   */
  --sh-box-elevation-spotlight: var(--shadow-xl-moderate);
  /* 
   * @cssprop [--sh-box-slot-spacing=0.5rem] - Spacing for the box slots
   */
  --sh-box-slot-spacing: 0.5rem;
  /* 
   * @cssprop [--sh-box-radius=var(--sh-radius-container, 0.5rem)] - Radius for the box
   */
  --sh-box-radius: var(--sh-radius-container, 0.5rem);
  /* 
   * @cssprop [--sh-box-color-inset-divider=rgba(var(--ui-1), var(--opacity-100))] - Color for the content gap fill
   */
  --sh-box-color-inset-divider: rgba(var(--ui-1), var(--opacity-100));
}

[theme=light],
[theme=light-classic] {
  --sh-box-divider-color: rgba(var(--ui-1), var(--opacity-100));
  --sh-box-color-inset-divider: rgba(var(--ui-1), var(--opacity-100));
}

[theme=dark],
[theme=dark-classic] {
  --sh-box-divider-color: rgba(var(--ui-7), var(--opacity-500));
  --sh-box-color-inset-divider: rgba(var(--ui-7), var(--opacity-500));
}

:host {
  width: 100%;
}

:host(sh-box),
:host(sh-box-header),
:host(sh-box-footer) {
  display: flex;
  padding: 0;
  margin: 0;
  width: var(--sh-box-width, auto);
  color: var(--text-secondary);
  font-size: var(--font-size-body-1);
}

:host(sh-box-header),
:host(sh-box-footer) {
  height: var(--sh-box-height, fit-content);
  flex-direction: row;
  position: relative;
}

:host(sh-box) {
  height: var(--sh-box-height, max-content);
  flex-direction: column;
  overflow: hidden;
  border-radius: var(--sh-box-radius);
  color: var(--color-text-moderate);
  font: var(--body-1);
}

:host > * {
  position: relative;
  direction: ltr;
}

:host(sh-box-footer[divider=full-width]) .header-divider-wrapper,
:host(sh-box-footer[divider=full-width]) .footer-divider-wrapper,
:host(sh-box-header[divider=full-width]) .header-divider-wrapper,
:host(sh-box-header[divider=full-width]) .footer-divider-wrapper,
:host(sh-box-footer[divider=inset]) .header-divider-wrapper,
:host(sh-box-footer[divider=inset]) .footer-divider-wrapper,
:host(sh-box-header[divider=inset]) .header-divider-wrapper,
:host(sh-box-header[divider=inset]) .footer-divider-wrapper {
  position: absolute;
  left: 0;
  right: 0;
}
:host(sh-box-footer[divider=full-width]) .header-divider-wrapper,
:host(sh-box-header[divider=full-width]) .header-divider-wrapper,
:host(sh-box-footer[divider=inset]) .header-divider-wrapper,
:host(sh-box-header[divider=inset]) .header-divider-wrapper {
  bottom: 0;
}
:host(sh-box-footer[divider=full-width]) .footer-divider-wrapper,
:host(sh-box-header[divider=full-width]) .footer-divider-wrapper,
:host(sh-box-footer[divider=inset]) .footer-divider-wrapper,
:host(sh-box-header[divider=inset]) .footer-divider-wrapper {
  top: 0;
}

:host(sh-box-footer[divider=full-width]) .header-divider-wrapper,
:host(sh-box-footer[divider=full-width]) .footer-divider-wrapper,
:host(sh-box-header[divider=full-width]) .header-divider-wrapper,
:host(sh-box-header[divider=full-width]) .footer-divider-wrapper {
  border-bottom: 1px solid var(--sh-box-divider-color);
}

:host(sh-box-footer[divider=inset]) .header-divider-wrapper,
:host(sh-box-footer[divider=inset]) .footer-divider-wrapper,
:host(sh-box-header[divider=inset]) .header-divider-wrapper,
:host(sh-box-header[divider=inset]) .footer-divider-wrapper {
  border-bottom: 1px solid var(--sh-box-color-inset-divider);
  margin-right: var(--padding-right, var(--container-padding));
  margin-left: var(--padding-left, var(--container-padding));
}

/* ******************************************
* sh-box-header, sh-box-section sh-box-footer settings
****************************************** */
:host(sh-box-header),
:host > slot:not([name])::slotted(sh-box-header) {
  align-items: center;
  display: flex;
  flex-direction: row;
}

:host(sh-box-footer),
:host > slot:not([name])::slotted(sh-box-footer) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--sh-box-slot-spacing, 0.5rem);
}

:host > slot:not([name])::slotted(sh-box-header) > *,
:host(sh-box-header) > * {
  flex: 1;
}

:host(sh-box-header) > ::slotted(:not(sh-icon)) {
  flex: 1;
}

/* Apply box padding to all children by default */
:host(sh-box-header),
:host(sh-box-footer),
slot:not([name])::slotted(sh-box-header),
slot:not([name])::slotted(sh-box-footer) {
  padding-top: var(--padding-top, var(--container-padding));
  padding-right: var(--padding-right, var(--container-padding));
  padding-bottom: var(--padding-bottom, var(--container-padding));
  padding-left: var(--padding-left, var(--container-padding));
}

/* Override padding if specified on sub-elements */
:host([padding=wide]) {
  --container-padding: var(--sh-box-padding-wide);
  --padding-top: var(--sh-box-padding-wide);
  --padding-right: var(--sh-box-padding-wide);
  --padding-bottom: var(--sh-box-padding-wide);
  --padding-left: var(--sh-box-padding-wide);
}

:host([padding=compact]) {
  --container-padding: var(--sh-box-padding-compact);
  --padding-top: var(--sh-box-padding-compact);
  --padding-right: var(--sh-box-padding-compact);
  --padding-bottom: var(--sh-box-padding-compact);
  --padding-left: var(--sh-box-padding-compact);
}

:host([padding=supercompact]) {
  --container-padding: var(--sh-box-padding-supercompact);
  --padding-top: var(--sh-box-padding-supercompact);
  --padding-right: var(--sh-box-padding-supercompact);
  --padding-bottom: var(--sh-box-padding-supercompact);
  --padding-left: var(--sh-box-padding-supercompact);
}

:host([padding=none]) {
  --container-padding: var(--sh-box-padding-none);
  --padding-top: var(--sh-box-padding-none);
  --padding-right: var(--sh-box-padding-none);
  --padding-bottom: var(--sh-box-padding-none);
  --padding-left: var(--sh-box-padding-none);
}

/* Handling named padding values */
:host([padding-top=wide]) {
  --padding-top: var(--sh-box-padding-wide);
}

:host([padding-top=compact]) {
  --padding-top: var(--sh-box-padding-compact);
}

:host([padding-top=supercompact]) {
  --padding-top: var(--sh-box-padding-supercompact);
}

:host([padding-top=none]) {
  --padding-top: var(--sh-box-padding-none);
}

:host([padding-right=wide]) {
  --padding-right: var(--sh-box-padding-wide);
}

:host([padding-right=compact]) {
  --padding-right: var(--sh-box-padding-compact);
}

:host([padding-right=supercompact]) {
  --padding-right: var(--sh-box-padding-supercompact);
}

:host([padding-right=none]) {
  --padding-right: var(--sh-box-padding-none);
}

:host([padding-bottom=wide]) {
  --padding-bottom: var(--sh-box-padding-wide);
}

:host([padding-bottom=compact]) {
  --padding-bottom: var(--sh-box-padding-compact);
}

:host([padding-bottom=supercompact]) {
  --padding-bottom: var(--sh-box-padding-supercompact);
}

:host([padding-bottom=none]) {
  --padding-bottom: var(--sh-box-padding-none);
}

:host([padding-left=wide]) {
  --padding-left: var(--sh-box-padding-wide);
}

:host([padding-left=compact]) {
  --padding-left: var(--sh-box-padding-compact);
}

:host([padding-left=supercompact]) {
  --padding-left: var(--sh-box-padding-supercompact);
}

:host([padding-left=none]) {
  --padding-left: var(--sh-box-padding-none);
}

/* ******************************************
 * BACKGROUND
 ****************************************** */
:host([background=surface]) {
  --container-background: var(--sh-box-color-surface);
}

:host([background=surface-secondary]) {
  --container-background: var(--sh-box-color-surface-secondary);
}

:host([background=surface-tertiary]) {
  --container-background: var(--sh-box-color-surface-tertiary);
}

:host([background=transparent]) {
  --container-background: var(--sh-box-color-transparent);
  --sh-box-radius: 0;
}

/* Only apply styles to the sh-box host, not to subcomponents' hosts */
:host(sh-box-header),
:host(sh-box-footer),
:host > slot:not([name])::slotted(sh-box-header),
:host > div > div.sections-wrapper,
:host > slot:not([name])::slotted(sh-box-footer) {
  background-color: var(--background-color, var(--container-background));
}

/* Override background color if specified on sub-elements */
:host(sh-footer[background=surface]) {
  display: block;
}

/* ******************************************
 * CONTENT GAP FOR SLOTTED CONTAINERS
 ****************************************** */
/* Default gap */
:host > div > div.sections-wrapper,
:host > slot:not([name])::slotted(div.sections-wrapper) {
  height: fit-content;
  overflow: auto;
  background-color: transparent;
}

:host([layout=vertical]) > div > div.sections-wrapper,
:host([layout=vertical]) > slot:not([name])::slotted(div.sections-wrapper) {
  display: flex;
  flex-direction: column;
}

:host([layout=horizontal]) > div > div.sections-wrapper,
:host([layout=horizontal]) > slot:not([name])::slotted(div.sections-wrapper) {
  display: flex;
  flex-direction: row;
}

/* Apply content gap based on content-gap attribute */
:host([content-gap="0"]) {
  --content-gap: var(--sh-box-content-gap-0);
}

:host([content-gap="1"]) {
  --content-gap: var(--sh-box-content-gap-1);
}

:host([content-gap="2"]) {
  --content-gap: var(--sh-box-content-gap-2);
}

:host([content-gap="4"]) {
  --content-gap: var(--sh-box-content-gap-4);
}

:host([content-gap="8"]) {
  --content-gap: var(--sh-box-content-gap-8);
}

/* Apply elevation to the whole box */
:host(sh-box[elevation=none]) {
  box-shadow: var(--sh-box-elevation-none);
}

:host(sh-box[elevation=subtle]:not(sh-box[background=transparent])) {
  box-shadow: var(--sh-box-elevation-subtle);
}

:host(sh-box[elevation=moderate]:not(sh-box[background=transparent])) {
  box-shadow: var(--sh-box-elevation-moderate);
}

:host(sh-box[elevation=spotlight]:not(sh-box[background=transparent])) {
  box-shadow: var(--sh-box-elevation-spotlight);
}

.box-section {
  height: 100%;
  overflow: auto;
}

:host(sh-box[layout=horizontal]) > div.box-section > div.sections-wrapper,
:host(sh-box[layout=vertical]) > div.box-section > div.sections-wrapper {
  height: 100%;
  min-height: -moz-available; /* WebKit-based browsers will ignore this. */
  min-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  min-height: fill-available;
}`;