// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
/**
 * Month object used to store information about a month like:
 * the date corresponding to the month, whether the month is selected
 * or not, whether the month is today's month or not and whether the month
 * is disabled or not.
 *
 * @property {Date} date - the date corresponding to 1st of the month
 * @property {Boolean} isSelected - whether the date is selected or not.
 * @property {Boolean} isThisMonth - whether the date is today or not.
 * @property {Boolean} isDisabled - whether the date is disabled or not.
 */
export class MonthData {
  constructor() {
    this.date = null;
    this.isSelected = false;
    this.isThisMonth = false;
    this.isDisabled = false;
  }
}
/**
 * Function that generates static year data.
 *
 * @param {Number} year - the year to which the months belong
 * @param {Date} todaysDate - the 1st of this month's date with hours, minutes, seconds and milliseconds set to zero
 * @param {Date} selectedDate - the 1st of the selected month's date with hours, minutes, seconds and milliseconds set to zero
 * @param {Boolean} futureDateDisable - whether the future months (months coming after todaysDate) are disabled or not.
 * @param {Date} minDate - the 1st of the minimum month's date with hours, minutes, seconds and milliseconds set to zero.
 * @param {Date} maxDate - the 1st of the maximum month's date with hours, minutes, seconds and milliseconds set to zero
 * @returns An array of `MonthData` corresponding to the given parameters.
 */
export function generateYearData(
  year,
  todaysDate,
  selectedDate = null,
  futureDateDisable = false,
  pastDateDisable = false,
  minDate = null,
  maxDate = null
) {
  const yearData = [];
  for (let i = 0; i < 12; i++) {
    const monthData = new MonthData();
    monthData.date = new Date(year, i, 1, 0, 0, 0, 0);
    monthData.isDisabled = isDisabledMonth(
      monthData.date,
      todaysDate,
      futureDateDisable,
      pastDateDisable,
      minDate,
      maxDate
    );
    if (!monthData.isDisabled) {
      monthData.isSelected =
        Boolean(selectedDate) &&
        monthData.date.getTime() ===
          (selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.getTime());
      monthData.isThisMonth = monthData.date.getTime() === todaysDate.getTime();
    }
    yearData.push(monthData);
  }
  return yearData;
}
function isDisabledMonth(
  currentDate,
  todaysDate,
  futureDateDisable,
  pastDateDisable,
  minDate,
  maxDate
) {
  const currentDateTime = currentDate.getTime();
  const todaysDateTime = todaysDate.getTime();
  let isDisabled =
    (Boolean(futureDateDisable) && currentDateTime > todaysDateTime) ||
    (Boolean(pastDateDisable) && currentDateTime < todaysDateTime);
  if (!isDisabled && minDate) {
    if (currentDate.getTime() < minDate.getTime()) {
      isDisabled = true;
    }
  }
  if (!isDisabled && maxDate) {
    if (currentDate.getTime() > maxDate.getTime()) {
      isDisabled = true;
    }
  }
  return isDisabled;
}
