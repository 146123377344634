// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator.js';
import dividerStyles from './sh-divider.lit.scss.js';
export const dividerListOfProperties = ['vertical', 'spacing', 'variant'];
/**
 *@fires spacing-changed {SpacingPropertyChangedEvent} - *hide emitted when the spacing property changes.
 *@fires vertical-changed {VerticalPropertyChangedEvent} - *hide emitted when the vertical property changes.
 *@fires variant-changed {VariantPropertyChangedEvent} - *hide emitted when the variant property changes.
 */
let SHDivider = class SHDivider extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /** Defines the margin around the divider line. Possible values are `s` (0.5rem), `m` (1rem), `l` (1.5rem), `xl` (3rem) and 'none' (0)
     * @type {"s"|"m"|"l"|"xl"|"none"}
     */
    this.spacing = 's';
    /** If set, the divider will have a vertical orientation  */
    this.vertical = false;
    /**This attribute will control the visual emphasis of the divider */
    this.variant = 'subtle';
  }
  static get styles() {
    return [dividerStyles];
  }
  render() {
    return html` <div class="divider-wrapper"></div> `;
  }
  updated(changedProperties) {
    dispatchAttributeChangedEventTypesafe(this, changedProperties, dividerListOfProperties);
  }
};
__decorate([event()], SHDivider.prototype, 'spacingChangedEvent', void 0);
__decorate([event()], SHDivider.prototype, 'verticalChangedEvent', void 0);
__decorate([event()], SHDivider.prototype, 'variantChangedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHDivider.prototype, 'spacing', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHDivider.prototype, 'vertical', void 0);
__decorate([property({ type: String, reflect: true })], SHDivider.prototype, 'variant', void 0);
SHDivider = __decorate([customElement('sh-divider')], SHDivider);
export { SHDivider };
