// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../styles';
import { deviceIdentifier } from '../../utils/device-identifier';
import '../../utils/device-identifier.js'; // just to pull in modrnzr
import { event, ShuiLitElement } from '../../utils/event-decorator';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { keyboardInteraction } from '../../utils/keyboardInteraction';
import { KEYCODE } from '../../utils/keycode';
import radioButtonStyles from './sh-radio-button.lit.scss.js';
/**
 * @fires active-changed {CustomEvent<string>}- Emitted when active state changes to true.
 * @fires clicked  {CustomEvent<string>} - DEPRECATED, use active-changed instead. Emitted when active state changes.
 */
let SHRadioButton = class SHRadioButton extends ShuiLitElement {
  constructor() {
    super(...arguments);
    /**Default label. Defines the label of the radio button. */
    this.label = 'label';
    /** Default false. Defines if a radio button is checked or not. Changes when clicked. */
    this.active = false;
    /** Default false. Defines if a radio button is disabled. If true, it won't allow clicks.*/
    this.disabled = false;
    /** Default false. If true, the active state will be gray instead of orange. */
    this.neutral = false;
    /** If true, the radio button is non-interactive, but the label keeps its appearance and remains fully readable.*/
    this.readonly = false;
    /** @ignore */
    this.role = 'radio';
    /** @ignore */
    this.ariaLabel = this.label;
  }
  static get styles() {
    return [extendInteractiveArea(), radioButtonStyles];
  }
  render() {
    return html`
      <div class="radio-container">
        <span class="pseudo-radio focus-item"></span>
        ${this.label ? html`<div class="radio-label" role="presentation">${this.label}</div>` : ''}
      </div>
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('no-hovermq');
    }
    this.addEventListener('click', this.handleActive);
    this.addEventListener('keydown', this.handleKeydown);
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('click', this.handleActive);
    this.removeEventListener('keydown', this.handleKeydown);
  }
  updated(changedProperties) {
    if (changedProperties.has('label')) {
      this.ariaLabel = this.label;
    }
    if (changedProperties.has('active') && this.active) {
      this.activeChangedEvent.emit(new CustomEvent(`active-changed`));
    }
    if (changedProperties.has('active')) {
      this.setAttribute('aria-checked', this.active.toString());
    }
    if (
      changedProperties.has('disabled') ||
      changedProperties.has('readonly') ||
      changedProperties.has('active')
    ) {
      const isDisabled = this.disabled || this.readonly;
      this.setAttribute('tabindex', isDisabled ? '-1' : this.active ? '0' : '-1');
    }
    if (changedProperties.has('disabled') || changedProperties.has('readonly')) {
      const isDisabled = this.disabled || this.readonly;
      this.setAttribute('aria-disabled', isDisabled.toString());
    }
  }
  firstUpdated() {
    this.addEventListener('focus', () => {
      this.handleFocus();
    });
    this.addEventListener('blur', () => {
      this.handleBlur();
    });
    if (this.readonly && !this.hasAttribute('tabindex')) {
      this.setAttribute('tabindex', '0');
    }
  }
  handleFocus() {
    var _a;
    const pseudoRadio =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.pseudo-radio');
    if (!this.disabled) {
      this.onkeyup = function (e) {
        if (keyboardInteraction(e, KEYCODE.TAB)) {
          pseudoRadio === null || pseudoRadio === void 0
            ? void 0
            : pseudoRadio.classList.add('focus');
        }
      };
      this.onkeydown = (e) => {
        this.handleKeydown(e);
      };
    }
  }
  handleKeydown(e) {
    var _a;
    const siblings =
      (_a = this.parentElement) === null || _a === void 0
        ? void 0
        : _a.querySelectorAll(
            "SH-RADIO-BUTTON[tabindex='0'][readonly], SH-RADIO-BUTTON:not([disabled]):not([readonly])"
          );
    if (siblings) {
      const isShiftPressed = e.shiftKey; // Check if Shift is held down
      if (
        keyboardInteraction(e, KEYCODE.ARROW_RIGHT) ||
        keyboardInteraction(e, KEYCODE.ARROW_DOWN)
      ) {
        this.handleTarget(e, ['ArrowRight', 'ArrowDown'], siblings, 'next', isShiftPressed);
      } else if (
        keyboardInteraction(e, KEYCODE.ARROW_LEFT) ||
        keyboardInteraction(e, KEYCODE.ARROW_UP)
      ) {
        this.handleTarget(e, ['ArrowLeft', 'ArrowUp'], siblings, 'prev', isShiftPressed);
      } else if (keyboardInteraction(e, KEYCODE.SPACE)) {
        // Handle space key press to select the radio button
        e.preventDefault(); // Prevent default scrolling behavior
        this.handleActive(); // Activate the radio button
      }
    }
  }
  handleTarget(e, targetKeys, siblings, traversal, isShiftPressed) {
    let target;
    const posThis = Array.from(siblings).indexOf(this);
    if (traversal === 'next') {
      //next not disabled sibling
      target = posThis === siblings.length - 1 ? siblings[0] : siblings[posThis + 1];
    } else {
      //previous not disabled sibling
      target = posThis === 0 ? siblings[siblings.length - 1] : siblings[posThis - 1];
    }
    this.handleArrowInput(e, targetKeys, target, isShiftPressed);
    this.handleTabs(e, ['Tab'], target);
  }
  handleTabs(e, triggerKeys, target) {
    if (triggerKeys.includes(e.code) && target) {
      e.preventDefault();
    }
  }
  handleArrowInput(e, triggerKeys, target, isShiftPressed) {
    var _a, _b;
    if (triggerKeys.includes(e.code) && target) {
      e.preventDefault();
      if (!isShiftPressed) {
        if (this.readonly && !target.active && !target.readonly) {
          const parentElement = this.parentElement;
          const activeEls = parentElement.querySelectorAll('[active]');
          for (const activeEl of activeEls) {
            if (activeEl !== target) {
              activeEl.active = false;
            }
          }
          target.active = true;
        } else if (!target.readonly) {
          const pseudoRadio =
            (_a = target.shadowRoot) === null || _a === void 0
              ? void 0
              : _a.querySelector('.pseudo-radio');
          pseudoRadio === null || pseudoRadio === void 0
            ? void 0
            : pseudoRadio.classList.add('focus');
          this.active = false;
          target.active = true;
        } else if (target.readonly && target.tabIndex !== -1) {
          const pseudoRadio =
            (_b = target.shadowRoot) === null || _b === void 0
              ? void 0
              : _b.querySelector('.pseudo-radio');
          pseudoRadio === null || pseudoRadio === void 0
            ? void 0
            : pseudoRadio.classList.add('focus');
        }
        target.focus();
      }
    }
  }
  handleBlur() {
    var _a;
    const pseudoRadio =
      (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.pseudo-radio');
    pseudoRadio === null || pseudoRadio === void 0 ? void 0 : pseudoRadio.classList.remove('focus');
  }
  handleActive() {
    if (!this.readonly) {
      this.active = true;
      this.focus();
      //TODO This has to be removed in future as we are already dispatching active-changed event
      this.clickedEvent.emit(new CustomEvent(`clicked`));
    }
  }
};
__decorate([event()], SHRadioButton.prototype, 'activeChangedEvent', void 0);
__decorate([event()], SHRadioButton.prototype, 'clickedEvent', void 0);
__decorate([property({ type: String, reflect: true })], SHRadioButton.prototype, 'label', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHRadioButton.prototype, 'active', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioButton.prototype,
  'disabled',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioButton.prototype,
  'neutral',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHRadioButton.prototype,
  'readonly',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHRadioButton.prototype, 'role', void 0);
__decorate(
  [property({ type: String, reflect: true, attribute: 'aria-label' })],
  SHRadioButton.prototype,
  'ariaLabel',
  void 0
);
SHRadioButton = __decorate([customElement('sh-radio-button')], SHRadioButton);
export { SHRadioButton };
