// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { directive, Directive } from 'lit/directive.js';
export class SpreadAttributes extends Directive {
  isEqual(o, v) {
    return Object.is(o, v);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(_attribs) {}
  update(part, [attribs]) {
    var _a;
    (_a = this.element) !== null && _a !== void 0 ? _a : (this.element = part.element);
    for (const [a, v] of Object.entries(attribs)) {
      v ? this.element.setAttribute(a, v) : this.element.removeAttribute(a);
    }
  }
}
export const spreadAttributes = directive(SpreadAttributes);
