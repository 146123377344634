// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

import { keyBoolean, keyNumber, keyStringNumber } from './interface';
import { AlgorithmInfo, getFlippedAlgorithm } from './overlay-placement-flipped-algorithm';
import { getUnFlippedAlgorithm } from './overlay-placement-unflipped-algorithm';
import { convertToPixel } from './rem-to-pixel-convertor';

interface DOMRect extends DOMRectReadOnly {
  height: number;
  width: number;
  x: number;
  y: number;
}

interface DOMRectReadOnly {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
  readonly x: number;
  readonly y: number;
  toJSON(): unknown;
}

export function getOverlayPlacementInfo(
  placement: string,
  overlayRect: DOMRect,
  targetRect: DOMRect,
  viewportRect: keyNumber,
  overlayTargetDistance: number,
  viewportPadding: number,
  flippedAlgorithm: AlgorithmInfo | null,
  unflippedAlgorithm: AlgorithmInfo | null,
  suppressPlacementWarning = false
) {
  getComputedStyle(document.documentElement); // forcing pending style updates to complete
  let actualViewport;
  ({ overlayRect, targetRect, actualViewport } = getRectParameters(
    viewportRect,
    targetRect,
    overlayRect,
    viewportPadding
  ));
  const { overlayTop, overlayLeft, overlayRight, overlayBottom } = getPlacementInfo(
    placement,
    targetRect,
    overlayRect,
    overlayTargetDistance
  );
  let requiredLeft = overlayLeft;
  let requiredTop = overlayTop;
  let hasFoundIdeal = false;
  const overlayWillBeWithinViewport = isWithinViewport(
    overlayTop,
    overlayLeft,
    overlayRight,
    overlayBottom,
    actualViewport
  );
  const targetIsOutsideViewport = isTargetOutsideViewport(targetRect, actualViewport);
  // if overlay's new position is outside viewport
  if (!overlayWillBeWithinViewport) {
    const overlayPlacementAlgorithmOutput = overlayPlacementAlgorithm(
      placement,
      requiredLeft,
      requiredTop,
      targetRect,
      overlayRect,
      overlayTargetDistance,
      actualViewport,
      flippedAlgorithm,
      unflippedAlgorithm
    );
    hasFoundIdeal = overlayPlacementAlgorithmOutput.hasFoundIdeal;
    if (!hasFoundIdeal) {
      const correctedPlacementWrtViewportInfo = getCorrectedCurrentPlacementWRTViewport(
        placement,
        targetRect,
        overlayRect,
        overlayTargetDistance,
        actualViewport
      );
      if (!suppressPlacementWarning) {
        console.warn(
          'Could not find an ideal position within the viewport for the overlay without overlap with the target\n\nSo trying to fit the overlay within the viewport',
          '\nby correcting for the given placement: \t',
          placement,
          '\n\nNote: The target will be overlapped.' +
            '\n\n' +
            "Suggestion: Try to place the target in some other position within the viewport OR try to reduce the overlay's width and height"
        );
      }

      requiredLeft = correctedPlacementWrtViewportInfo?.placementInfo?.overlayLeft;
      requiredTop = correctedPlacementWrtViewportInfo?.placementInfo?.overlayTop;
      if (!correctedPlacementWrtViewportInfo?.foundIdealWrtViewport && !suppressPlacementWarning) {
        console.warn(
          'Could not fit the Overlay within the Viewport.\n\n',
          'Are you sure the overlay width and/or height is less than effective viewport width (viewport width -' +
            convertToPixel(viewportPadding) +
            'px ) and/or effective viewport height (viewport height -' +
            convertToPixel(viewportPadding) +
            'px ) ?'
        );
      }
    } else {
      requiredLeft = overlayPlacementAlgorithmOutput.idealLeft;
      requiredTop = overlayPlacementAlgorithmOutput.idealTop;
    }
  }
  if (targetIsOutsideViewport && !suppressPlacementWarning) {
    console.warn(
      'The effective target (target boundary + ' +
        convertToPixel(overlayTargetDistance) +
        'px margin around it) is outside the effective viewport (viewport boundary - ' +
        convertToPixel(viewportPadding) +
        'px inner padding)'
    );
  }
  return { requiredLeft, requiredTop, targetIsOutsideViewport };
}

export function getRectParameters(
  viewportRect: keyNumber,
  targetRect: DOMRect,
  overlayRect: DOMRect,
  viewportPadding: number
) {
  viewportRect = JSON.parse(JSON.stringify(viewportRect));
  targetRect = JSON.parse(JSON.stringify(targetRect));
  overlayRect = JSON.parse(JSON.stringify(overlayRect));
  const viewportRectLeft = viewportRect.left + convertToPixel(viewportPadding);
  const viewportRectRight = viewportRect.right - convertToPixel(viewportPadding);
  const viewportRectTop = viewportRect.top + convertToPixel(viewportPadding);
  const viewportRectBottom = viewportRect.bottom - convertToPixel(viewportPadding);
  const actualViewport = {
    left: viewportRectLeft,
    right: viewportRectRight,
    top: viewportRectTop,
    bottom: viewportRectBottom,
    width: viewportRectRight - viewportRectLeft,
    height: viewportRectBottom - viewportRectTop,
  };
  return { overlayRect, targetRect, actualViewport };
}

export function getPlacementInfo(
  placement: string,
  targetRect: DOMRect,
  overlayRect: DOMRect,
  overlayTargetDistance: number
) {
  let overlayTop = 0;
  let overlayLeft = 0;
  let overlayRight = 0;
  let overlayBottom = 0;
  switch (placement) {
    case 'top':
      {
        overlayTop = targetRect?.top - overlayRect?.height - convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.left + targetRect?.width / 2 - overlayRect?.width / 2;
      }
      break;
    case 'top-left':
      {
        overlayTop = targetRect?.top - overlayRect?.height - convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.left;
      }
      break;
    case 'top-right':
      {
        overlayTop = targetRect?.top - overlayRect?.height - convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.right - overlayRect?.width;
      }
      break;
    case 'bottom':
      {
        overlayTop = targetRect?.bottom + convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.left + targetRect?.width / 2 - overlayRect?.width / 2;
      }
      break;
    case 'bottom-left':
      {
        overlayTop = targetRect?.bottom + convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.left;
      }
      break;
    case 'bottom-right':
      {
        overlayTop = targetRect?.bottom + convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.right - overlayRect?.width;
      }
      break;
    case 'left':
      {
        overlayTop = targetRect?.top + targetRect?.height / 2 - overlayRect?.height / 2;
        overlayLeft = targetRect?.left - overlayRect?.width - convertToPixel(overlayTargetDistance);
      }
      break;
    case 'left-up':
      {
        overlayTop = targetRect?.top;
        overlayLeft = targetRect?.left - overlayRect?.width - convertToPixel(overlayTargetDistance);
      }
      break;
    case 'left-down':
      {
        overlayTop = targetRect?.bottom - overlayRect?.height;
        overlayLeft = targetRect?.left - overlayRect?.width - convertToPixel(overlayTargetDistance);
      }
      break;
    case 'right':
      {
        overlayTop = targetRect?.top + targetRect?.height / 2 - overlayRect?.height / 2;
        overlayLeft = targetRect?.right + convertToPixel(overlayTargetDistance);
      }
      break;
    case 'right-up':
      {
        overlayTop = targetRect?.top;
        overlayLeft = targetRect?.right + convertToPixel(overlayTargetDistance);
      }
      break;
    case 'right-down':
      {
        overlayTop = targetRect?.bottom - overlayRect?.height;
        overlayLeft = targetRect?.right + convertToPixel(overlayTargetDistance);
      }
      break;
    case 'top-diag-left':
      {
        overlayTop = targetRect?.top - overlayRect?.height - convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.left - overlayRect?.width - convertToPixel(overlayTargetDistance);
      }
      break;
    case 'top-diag-right':
      {
        overlayTop = targetRect?.top - overlayRect?.height - convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.right + convertToPixel(overlayTargetDistance);
      }
      break;
    case 'bottom-diag-left':
      {
        overlayTop = targetRect?.bottom + convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.left - overlayRect?.width - convertToPixel(overlayTargetDistance);
      }
      break;
    case 'bottom-diag-right':
      {
        overlayTop = targetRect?.bottom + convertToPixel(overlayTargetDistance);
        overlayLeft = targetRect?.right + convertToPixel(overlayTargetDistance);
      }
      break;
  }
  overlayTop = Math.round(overlayTop);
  overlayLeft = Math.round(overlayLeft);
  overlayRight = overlayLeft + overlayRect?.width;
  overlayBottom = overlayTop + overlayRect?.height;
  return { overlayTop, overlayLeft, overlayRight, overlayBottom };
}

export function overlayPlacementAlgorithm(
  placement: string,
  left: number,
  top: number,
  targetRect: DOMRect,
  overlayRect: DOMRect,
  overlayTargetDistance: number,
  actualViewport: keyNumber,
  flippedAlgorithm: AlgorithmInfo | null,
  unflippedAlgorithm: AlgorithmInfo | null
) {
  let hasFoundIdeal = false,
    idealLeft = left,
    idealTop = top;
  const algorithmSteps = getAlgorithmSteps(
    placement,
    satisfiesFlipCondition(
      placement,
      targetRect,
      overlayRect,
      overlayTargetDistance,
      actualViewport
    ),
    flippedAlgorithm,
    unflippedAlgorithm
  ) as string | number[];

  for (let step = 0; step < algorithmSteps?.length; ++step) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const algorithmStep = algorithmSteps[step] as unknown as any;
    const fromPlacement = algorithmStep?.from;
    const toPlacement = algorithmStep?.to;
    const incrementValue: string = algorithmStep?.increment;
    const incrementBy: number = algorithmStep?.incrementBy;
    const fromPlacementData: keyNumber = getPlacementInfo(
      fromPlacement,
      targetRect,
      overlayRect,
      overlayTargetDistance
    );
    const toPlacementData: keyNumber = getPlacementInfo(
      toPlacement,
      targetRect,
      overlayRect,
      overlayTargetDistance
    );
    for (
      let i = fromPlacementData[incrementValue];
      incrementBy === 1 ? i < toPlacementData[incrementValue] : i > toPlacementData[incrementValue];
      incrementBy === 1 ? ++i : --i
    ) {
      const overlayData: keyNumber = getOtherOverlaySides(
        incrementValue,
        i,
        fromPlacementData,
        overlayRect
      );
      if (
        isWithinViewport(
          overlayData?.overlayTop,
          overlayData?.overlayLeft,
          overlayData?.overlayRight,
          overlayData?.overlayBottom,
          actualViewport
        )
      ) {
        idealLeft = overlayData?.overlayLeft;
        idealTop = overlayData?.overlayTop;
        hasFoundIdeal = true;
        break;
      }
    }
    if (hasFoundIdeal) {
      break;
    }
  }
  return { hasFoundIdeal, idealTop, idealLeft };
}

export function isWithinViewport(
  top: number,
  left: number,
  right: number,
  bottom: number,
  actualViewport: keyNumber
) {
  return (
    left >= actualViewport?.left &&
    right <= actualViewport?.right &&
    top >= actualViewport?.top &&
    bottom <= actualViewport?.bottom
  );
}

export function satisfiesFlipCondition(
  placement: string,
  targetRect: DOMRect,
  overlayRect: DOMRect,
  overlayTargetDistance: number,
  actualViewport: keyNumber
) {
  const placementInfo = getPlacementInfo(placement, targetRect, overlayRect, overlayTargetDistance);
  const flipConditionDictionary: keyBoolean = {
    top: placementInfo?.overlayTop < actualViewport?.top,
    bottom: placementInfo?.overlayBottom > actualViewport?.bottom,
    left: placementInfo?.overlayLeft < actualViewport?.left,
    right: placementInfo?.overlayRight > actualViewport?.right,
    'top-left': placementInfo?.overlayTop < actualViewport?.top,
    'top-right': placementInfo?.overlayTop < actualViewport?.top,
    'bottom-left': placementInfo?.overlayBottom > actualViewport?.bottom,
    'bottom-right': placementInfo?.overlayBottom > actualViewport?.bottom,
    'left-up': placementInfo?.overlayLeft < actualViewport?.left,
    'left-down': placementInfo?.overlayLeft < actualViewport?.left,
    'right-up': placementInfo?.overlayRight > actualViewport?.right,
    'right-down': placementInfo?.overlayRight > actualViewport?.right,
  };
  return flipConditionDictionary[placement];
}

export function getCorrectedCurrentPlacementWRTViewport(
  placement: string,
  targetRect: DOMRect,
  overlayRect: DOMRect,
  overlayTargetDistance: number,
  actualViewport: keyNumber
) {
  let foundIdealWrtViewport = false;
  let placementInfo: keyNumber = getPlacementInfo(
    placement,
    targetRect,
    overlayRect,
    overlayTargetDistance
  );
  const viewportCorrectionOrder = getViewportCorrectionOrder(placement);
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[0],
    placementInfo,
    overlayRect,
    actualViewport
  );
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[1],
    placementInfo,
    overlayRect,
    actualViewport
  );
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[2],
    placementInfo,
    overlayRect,
    actualViewport
  );
  placementInfo = correctToViewportForSide(
    viewportCorrectionOrder[3],
    placementInfo,
    overlayRect,
    actualViewport
  );
  foundIdealWrtViewport = isWithinViewport(
    placementInfo?.overlayTop,
    placementInfo?.overlayLeft,
    placementInfo?.overlayRight,
    placementInfo?.overlayBottom,
    actualViewport
  );
  return { foundIdealWrtViewport, placementInfo };
}

function isTargetOutsideViewport(targetRect: DOMRect, actualViewport: keyNumber) {
  return (
    targetRect?.right <= actualViewport?.left ||
    targetRect?.left >= actualViewport?.right ||
    targetRect?.bottom <= actualViewport?.top ||
    targetRect?.top >= actualViewport?.bottom
  );
}

function getOtherOverlaySides(
  incrementSide: string,
  incrementValue: number,
  placementData: keyNumber,
  overlayRect: DOMRect
) {
  const overlaySidesDictionary: keyStringNumber = {
    overlayLeft: {
      overlayTop: placementData.overlayTop,
      overlayLeft: incrementValue,
    },
    overlayTop: {
      overlayTop: incrementValue,
      overlayLeft: placementData.overlayLeft,
    },
    overlayRight: {
      overlayTop: placementData.overlayTop,
      overlayLeft: incrementValue - overlayRect?.width,
    },
    overlayBottom: {
      overlayTop: incrementValue - overlayRect?.height,
      overlayLeft: placementData.overlayLeft,
    },
  };
  const topLeftData = overlaySidesDictionary[incrementSide];
  const overlayRight = topLeftData?.overlayLeft + overlayRect?.width;
  const overlayBottom = topLeftData?.overlayTop + overlayRect?.height;
  return { ...topLeftData, overlayRight, overlayBottom };
}

function getAlgorithmSteps(
  placement: string,
  flipped: boolean,
  flippedAlgorithm: unknown,
  unflippedAlgorithm: unknown
) {
  let algorithm;
  if (flipped) {
    algorithm = flippedAlgorithm || getFlippedAlgorithm(placement);
  } else {
    algorithm = unflippedAlgorithm || getUnFlippedAlgorithm(placement);
  }
  return algorithm;
}

function correctToViewportForSide(
  side: string,
  placementInfo: keyNumber,
  overlayRect: DOMRect,
  actualViewport: keyNumber
) {
  if (side === 'overlayTop') {
    if (placementInfo?.overlayTop < actualViewport?.top) {
      placementInfo.overlayTop = actualViewport?.top;
    }
  }
  if (side === 'overlayRight') {
    if (placementInfo?.overlayRight > actualViewport?.right) {
      placementInfo.overlayRight = actualViewport?.right;
    }
  }
  if (side === 'overlayLeft') {
    if (placementInfo?.overlayLeft < actualViewport?.left) {
      placementInfo.overlayLeft = actualViewport?.left;
    }
  }
  if (side === 'overlayBottom') {
    if (placementInfo?.overlayBottom > actualViewport?.bottom) {
      placementInfo.overlayBottom = actualViewport?.bottom;
    }
  }
  placementInfo = getOtherOverlaySides(side, placementInfo[side], placementInfo, overlayRect);
  return placementInfo;
}

function getViewportCorrectionOrder(placement: string) {
  let order: string[] = [];
  switch (placement) {
    case 'top':
    case 'top-left':
    case 'top-right':
      order = ['overlayTop', 'overlayRight', 'overlayBottom', 'overlayLeft'];
      break;
    case 'bottom':
    case 'bottom-left':
    case 'bottom-right':
      order = ['overlayBottom', 'overlayLeft', 'overlayTop', 'overlayRight'];
      break;
    case 'left':
    case 'left-up':
    case 'left-down':
      order = ['overlayLeft', 'overlayTop', 'overlayRight', 'overlayBottom'];
      break;
    case 'right':
    case 'right-up':
    case 'right-down':
      order = ['overlayRight', 'overlayBottom', 'overlayLeft', 'overlayTop'];
      break;
  }
  return order;
}
