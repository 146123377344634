// === GENERATED FILE ===
// DO NOT EDIT DIRECTLY, EDIT sh-image-segment.lit.scss INSTEAD!
import { css } from 'lit';
export default css`:root,
[sh-scale=s] {
  /* 
   * @cssprop [--sh-image-segment-slot-left=0.75rem] - slot left
   */
  --sh-image-segment-slot-left: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-slot-right=0.75rem] - slot right
   */
  --sh-image-segment-slot-right: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-slot-top=0.75rem] - slot top
   */
  --sh-image-segment-slot-top: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-slot-bottom=0.75rem] - slot bottom
   */
  --sh-image-segment-slot-bottom: 0.75rem;
  /* 
   * @cssprop [--sh-image-segment-left-right-margin=0.25rem 0] - margin for left and right side partions of slot
   */
  --sh-image-segment-left-right-margin: 0.25rem 0;
  /* 
   * @cssprop [--sh-image-segment-top-bottom-margin=0 0.25rem] - margin for top and bottom side partions of slot
   */
  --sh-image-segment-top-bottom-margin: 0 0.25rem;
  /* 
   * @cssprop [--sh-image-segment-text-line-height=1.25rem] - line-height of text
   */
  --sh-image-segment-text-line-height: 1.25rem;
  /* 
   * @cssprop [--sh-image-segment-corner-right-ml=0.5rem] - margin-left of corner-right
   */
  --sh-image-segment-corner-right-ml: 0.5rem;
  /* 
   * @cssprop [--sh-image-segment-outline-z-index=1] - z-index value
   */
  --sh-image-segment-outline-z-index: 1;
}

:host {
  position: relative;
  border-radius: 0.125rem;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* slotted image */
:host > ::slotted(*:not([slot])) {
  height: 100%;
  width: 100%;
  flex: 1;
}

/* outlines */
.segment-outline {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  height: calc(100% - 0.25rem);
  width: calc(100% - 0.25rem);
  box-sizing: border-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  transition: var(--transition-time) all ease-in-out;
  opacity: var(--opacity-3);
  z-index: var(--sh-image-segment-outline-z-index);
}

:host(:not([outline])[visual-focus]) .segment-outline {
  border-color: rgb(var(--ui-0));
}

:host([visual-focus]) .segment-outline {
  border-width: 0.125rem;
  opacity: var(--opacity-2);
}

/* slots */
:host ::slotted(*[slot]) {
  pointer-events: all;
}

.corner,
.side {
  pointer-events: none;
  position: absolute;
  height: auto;
  width: auto;
  transition: var(--transition-time) all ease-in-out;
}

:host([hide-overlay]) .corner,
:host([hide-overlay]) .side {
  opacity: 0;
  pointer-events: none;
}

/* corner slots */
.corner {
  max-width: calc(50% - 0.75rem);
  display: flex;
  flex-direction: column;
}

.corner > ::slotted(*) {
  display: flex;
  flex-direction: column;
}

.corner.right ::slotted(*) {
  text-align: right;
  margin-left: var(--sh-image-segment-corner-right-ml);
}

/* side slots */
.side {
  display: flex;
  align-items: center;
  justify-content: center;
}

.side.left > ::slotted(*),
.side.right > ::slotted(*) {
  margin: var(--sh-image-segment-left-right-margin);
}

.side.left,
.side.right {
  height: calc(100% - 1.5rem);
  flex-direction: column;
}

.side.top > ::slotted(*),
.side.bottom > ::slotted(*) {
  margin: var(--sh-image-segment-top-bottom-margin);
}

.side.top,
.side.bottom {
  width: calc(100% - 1.5rem);
  flex-direction: row;
}

.left {
  left: var(--sh-image-segment-slot-left);
}

.right {
  right: var(--sh-image-segment-slot-right);
}

.top {
  top: var(--sh-image-segment-slot-top);
}

.bottom {
  bottom: var(--sh-image-segment-slot-bottom);
}`;