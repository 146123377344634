// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
import { __decorate } from 'tslib';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { CheckboxToggleMixin } from '../../utils/checkbox-toggle-mixin';
import { deviceIdentifier } from '../../utils/device-identifier.js';
import {
  dispatchAttributeChangedEventTypesafe,
  event,
  ShuiLitElement,
} from '../../utils/event-decorator';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import { extendInteractiveArea } from '../../utils/interactive-area.js';
import { RefrainSpaceScrollMixin } from '../../utils/refrain-space-scroll-mixin.js';
import checkboxStyles from './sh-checkbox.lit.scss.js';
export const checkboxListOfProperties = ['label', 'indeterminate', 'disabled'];
/**
 * @fires checked {CustomEvent<string>} - Dispatched when clicking on checkbox.
 * @fires label-changed {LabelPropertyChangedEvent} - *hide Dispatched when label changes.
 * @fires disabled-changed {DisabledPropertyChangedEvent} - *hide Event emitted when disabled property changes
 * @fires indeterminate-changed {IndeterminatePropertyChangedEvent} - *hide Dispatched when indeterminate changes.
 */
let SHCheckbox = class SHCheckbox extends RefrainSpaceScrollMixin(
  CheckboxToggleMixin(FocusBlurMixin(ShuiLitElement))
) {
  static get styles() {
    return [checkboxStyles, extendInteractiveArea()];
  }
  render() {
    var _a;
    const isDisabled = this.disabled || this.readonly;
    return html`
      <input
        id="checkbox"
        type="checkbox"
        aria-labelledby="checkbox-label"
        aria-label="${ifDefined(this.label)}"
        ?checked="${this.active}"
        ?readonly="${this.readonly}"
        ?disabled="${isDisabled}"
        tabindex="${isDisabled ? '-1' : '0'}"
        value="${this.active}"
        .indeterminate="${(_a = this.indeterminate) !== null && _a !== void 0 ? _a : false}"
      />
      <span class="pseudoCheckbox focus-item" id="pseudoCheckbox" aria-hidden="true"></span>
      <label
        class="checkboxLabel"
        id="checkbox-label"
        for="checkbox"
        @click="${(e) => e.stopPropagation()}"
        >${this.label}</label
      >
    `;
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    dispatchAttributeChangedEventTypesafe(this, changedProperties, checkboxListOfProperties);
  }
  connectedCallback() {
    super.connectedCallback();
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('touch-device');
    }
    this.addEventListener('keydown', this.handleSpaceKeydown);
    this.addEventListener('click', () => {
      this.checkedEvent.emit(new CustomEvent('checked'));
    });
  }
  handleSpaceKeydown(event) {
    if (event.code === 'Space' && !this.readonly) {
      event.preventDefault(); // Prevent the default action (checkbox toggle)
      event.stopPropagation(); // Stop event propagation to avoid interference with other handlers
    }
  }
  _spaceKeyAction() {
    if (!this.readonly) {
      this.handleClick();
      this.checkedEvent.emit(new CustomEvent('checked'));
    }
  }
};
__decorate([event()], SHCheckbox.prototype, 'labelChangedEvent', void 0);
__decorate([event()], SHCheckbox.prototype, 'disabledChangedEvent', void 0);
__decorate([event()], SHCheckbox.prototype, 'checkedEvent', void 0);
__decorate([event()], SHCheckbox.prototype, 'indeterminateChangedEvent', void 0);
__decorate(
  [property({ type: Boolean, reflect: true })],
  SHCheckbox.prototype,
  'indeterminate',
  void 0
);
__decorate([property({ type: String, reflect: true })], SHCheckbox.prototype, 'label', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHCheckbox.prototype, 'readonly', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHCheckbox.prototype, 'disabled', void 0);
SHCheckbox = __decorate([customElement('sh-checkbox')], SHCheckbox);
export { SHCheckbox };
