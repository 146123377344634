// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------

/**
 * Base event class for our legacy propXYZ-changed events
 */
export class LegacyPropertyChangedEvent<
  Prop extends string,
  TDetail = void,
> extends CustomEvent<TDetail> {
  override readonly type!: `${Prop}-changed`;

  constructor(prop: Prop, init?: CustomEventInit<TDetail>) {
    super(`${prop}-changed`, init);
  }
}

export class DisabledPropertyChangedEvent extends LegacyPropertyChangedEvent<'disabled', boolean> {
  constructor(init?: CustomEventInit<boolean>) {
    super('disabled', init);
  }
}

export class IconPropertyChangedEvent extends LegacyPropertyChangedEvent<'icon'> {
  constructor() {
    super('icon');
  }
}

export class LabelPropertyChangedEvent extends LegacyPropertyChangedEvent<'label'> {
  constructor() {
    super('label');
  }
}

export class SizePropertyChangedEvent extends LegacyPropertyChangedEvent<'size'> {
  constructor() {
    super('size');
  }
}

export class ColorPropertyChangedEvent extends LegacyPropertyChangedEvent<'color'> {
  constructor() {
    super('color');
  }
}

export class TooltipPropertyChangedEvent extends LegacyPropertyChangedEvent<'tooltip'> {
  constructor() {
    super('tooltip');
  }
}

export class HrefPropertyChangedEvent extends LegacyPropertyChangedEvent<'href'> {
  constructor() {
    super('href');
  }
}
export class DragAndDropPropertyChangedEvent extends LegacyPropertyChangedEvent<'dragAndDrop'> {
  constructor() {
    super('dragAndDrop');
  }
}
export class IconColorPropertyChangedEvent extends LegacyPropertyChangedEvent<'iconColor'> {
  constructor() {
    super('iconColor');
  }
}
export class HeaderPropertyChangedEvent extends LegacyPropertyChangedEvent<'header'> {
  constructor() {
    super('header');
  }
}
export class ButtonPropertyChangedEvent extends LegacyPropertyChangedEvent<'button'> {
  constructor() {
    super('button');
  }
}
export class BadgePropertyChangedEvent extends LegacyPropertyChangedEvent<'badge'> {
  constructor() {
    super('badge');
  }
}
export class TogglePropertyChangedEvent extends LegacyPropertyChangedEvent<'toggle'> {
  constructor() {
    super('toggle');
  }
}
export class StickyPropertyChangedEvent extends LegacyPropertyChangedEvent<'sticky'> {
  constructor() {
    super('sticky');
  }
}

export class TitlePropertyChangedEvent extends LegacyPropertyChangedEvent<'title'> {
  constructor() {
    super('title');
  }
}

export class VariationPropertyChangedEvent extends LegacyPropertyChangedEvent<'variation'> {
  constructor() {
    super('variation');
  }
}

export class TargetPropertyChangedEvent extends LegacyPropertyChangedEvent<'target'> {
  constructor() {
    super('target');
  }
}
export class NumberPropertyChangedEvent extends LegacyPropertyChangedEvent<'number'> {
  constructor() {
    super('number');
  }
}

export class VisiblePropertyChangedEvent extends LegacyPropertyChangedEvent<'visible'> {
  constructor() {
    super('visible');
  }
}
export class TypePropertyChangedEvent extends LegacyPropertyChangedEvent<'type'> {
  constructor() {
    super('type');
  }
}
export class ModalHeightPropertyChangedEvent extends LegacyPropertyChangedEvent<'modalHeight'> {
  constructor() {
    super('modalHeight');
  }
}

export class ModalWidthPropertyChangedEvent extends LegacyPropertyChangedEvent<'modalWidth'> {
  constructor() {
    super('modalWidth');
  }
}
export class PagesPropertyChangedEvent extends LegacyPropertyChangedEvent<'pages'> {
  constructor() {
    super('pages');
  }
}
export class CurrentPagePropertyChangedEvent extends LegacyPropertyChangedEvent<'current-page'> {
  constructor() {
    super('current-page');
  }
}
export class SpacingPropertyChangedEvent extends LegacyPropertyChangedEvent<'spacing'> {
  constructor() {
    super('spacing');
  }
}
export class VariantPropertyChangedEvent extends LegacyPropertyChangedEvent<'variant'> {
  constructor() {
    super('variant');
  }
}
export class VerticalPropertyChangedEvent extends LegacyPropertyChangedEvent<'vertical'> {
  constructor() {
    super('vertical');
  }
}
export class IndeterminatePropertyChangedEvent extends LegacyPropertyChangedEvent<'indeterminate'> {
  constructor() {
    super('indeterminate');
  }
}
export class NeutralPropertyChangedEvent extends LegacyPropertyChangedEvent<'neutral'> {
  constructor() {
    super('neutral');
  }
}
export class HintPropertyChangedEvent extends LegacyPropertyChangedEvent<'hint'> {
  constructor() {
    super('hint');
  }
}
export class ErrorMessagePropertyChangedEvent extends LegacyPropertyChangedEvent<'errorMessage'> {
  constructor() {
    super('errorMessage');
  }
}
export class MultiSelectPropertyChangedEvent extends LegacyPropertyChangedEvent<'multiSelect'> {
  constructor() {
    super('multiSelect');
  }
}
export class NoBorderPropertyChangedEvent extends LegacyPropertyChangedEvent<'noBorder'> {
  constructor() {
    super('noBorder');
  }
}

export class MandatoryPropertyChangedEvent extends LegacyPropertyChangedEvent<'mandatory'> {
  constructor() {
    super('mandatory');
  }
}
export class SuccessPropertyChangedEvent extends LegacyPropertyChangedEvent<'success'> {
  constructor() {
    super('success');
  }
}
export class SafetyPropertyChangedEvent extends LegacyPropertyChangedEvent<'safety'> {
  constructor() {
    super('safety');
  }
}
export class EditablePropertyChangedEvent extends LegacyPropertyChangedEvent<'editable'> {
  constructor() {
    super('editable');
  }
}

export class ValuePropertyChangedEvent extends LegacyPropertyChangedEvent<'value', number> {
  constructor(init?: CustomEventInit<number>) {
    super('value', init);
  }
}

export class MultilinePropertyChangedEvent extends LegacyPropertyChangedEvent<'multiline'> {
  constructor() {
    super('multiline');
  }
}

export class FieldsPropertyChangedEvent extends LegacyPropertyChangedEvent<'fields'> {
  constructor() {
    super('fields');
  }
}

export class SrcPropertyChangedEvent extends LegacyPropertyChangedEvent<'src'> {
  constructor() {
    super('src');
  }
}

export class PlacementPropertyChangedEvent extends LegacyPropertyChangedEvent<'placement'> {
  constructor() {
    super('placement');
  }
}
export class ErrorPropertyChangedEvent extends LegacyPropertyChangedEvent<'error'> {
  constructor() {
    super('error');
  }
}
export class ImagePropertyChangedEvent extends LegacyPropertyChangedEvent<'image'> {
  constructor() {
    super('image');
  }
}

export class LabelAlignmentPropertyChangedEvent extends LegacyPropertyChangedEvent<'labelAlignment'> {
  constructor() {
    super('labelAlignment');
  }
}

export class OverlayPropertyChangedEvent extends LegacyPropertyChangedEvent<'overlay'> {
  constructor() {
    super('overlay');
  }
}

export class PausedPropertyChangedEvent extends LegacyPropertyChangedEvent<'paused'> {
  constructor() {
    super('paused');
  }
}

export class RadialPropertyChangedEvent extends LegacyPropertyChangedEvent<'radial'> {
  constructor() {
    super('radial');
  }
}

export class StatusPropertyChangedEvent extends LegacyPropertyChangedEvent<'status'> {
  constructor() {
    super('status');
  }
}

export class InfoPropertyChangedEvent extends LegacyPropertyChangedEvent<'info'> {
  constructor() {
    super('info');
  }
}

export class ModalPropertyChangedEvent extends LegacyPropertyChangedEvent<'modal'> {
  constructor() {
    super('modal');
  }
}

export class ProgressPropertyChangedEvent extends LegacyPropertyChangedEvent<'progress'> {
  constructor() {
    super('progress');
  }
}

export class SubHeadlinePropertyChangedEvent extends LegacyPropertyChangedEvent<'subHeadline'> {
  constructor() {
    super('subHeadline');
  }
}

export class VersionPropertyChangedEvent extends LegacyPropertyChangedEvent<'version'> {
  constructor() {
    super('version');
  }
}
export class NoClearPropertyChangedEvent extends LegacyPropertyChangedEvent<'noClear'> {
  constructor() {
    super('noClear');
  }
}
export class ActivePropertyChangedEvent extends LegacyPropertyChangedEvent<'active'> {
  constructor() {
    super('active');
  }
}

export class CurrentPropertyChangedEvent extends LegacyPropertyChangedEvent<'current'> {
  constructor() {
    super('current');
  }
}

export class DecimalsPropertyChangedEvent extends LegacyPropertyChangedEvent<'decimals'> {
  constructor() {
    super('decimals');
  }
}

export class EuropeanNumberConventionPropertyChangedEvent extends LegacyPropertyChangedEvent<'europeanNumberConvention'> {
  constructor() {
    super('europeanNumberConvention');
  }
}
export class ReadonlyPropertyChangedEvent extends LegacyPropertyChangedEvent<'readonly'> {
  constructor() {
    super('readonly');
  }
}

export class MessageVisiblePropertyChangedEvent extends LegacyPropertyChangedEvent<'messageVisible'> {
  constructor() {
    super('messageVisible');
  }
}

export class ThumbnailPropertyChangedEvent extends LegacyPropertyChangedEvent<'thumbnail'> {
  constructor() {
    super('thumbnail');
  }
}
export class FlatPropertyChangedEvent extends LegacyPropertyChangedEvent<'flat'> {
  constructor() {
    super('flat');
  }
}

export class ExpandedPropertyChangedEvent extends LegacyPropertyChangedEvent<'expanded'> {
  constructor() {
    super('expanded');
  }
}

export class MessagePropertyChangedEvent extends LegacyPropertyChangedEvent<'message'> {
  constructor() {
    super('message');
  }
}

export class VisualFocusPropertyChangedEvent extends LegacyPropertyChangedEvent<'visualFocus'> {
  constructor() {
    super('visualFocus');
  }
}

export class SelectablePropertyChangedEvent extends LegacyPropertyChangedEvent<'selectable'> {
  constructor() {
    super('selectable');
  }
}

export class NewPropertyChangedEvent extends LegacyPropertyChangedEvent<'new'> {
  constructor() {
    super('new');
  }
}
export class SubMenuPropertyChangedEvent extends LegacyPropertyChangedEvent<'subMenu'> {
  constructor() {
    super('subMenu');
  }
}

export class PositionPropertyChangedEvent extends LegacyPropertyChangedEvent<'position'> {
  constructor() {
    super('position');
  }
}

export class PassivePropertyChangedEvent extends LegacyPropertyChangedEvent<'passive'> {
  constructor() {
    super('passive');
  }
}

export class MobilePropertyChangedEvent extends LegacyPropertyChangedEvent<'mobile'> {
  constructor() {
    super('mobile');
  }
}
export class MiniPropertyChangedEvent extends LegacyPropertyChangedEvent<'mini'> {
  constructor() {
    super('mini');
  }
}

export class CheckedPropertyChangedEvent extends LegacyPropertyChangedEvent<'checked'> {
  constructor() {
    super('checked');
  }
}

export class OptionsPropertyChangedEvent extends LegacyPropertyChangedEvent<'options'> {
  constructor() {
    super('options');
  }
}
export class CollapsedPropertyChangedEvent extends LegacyPropertyChangedEvent<'collapsed'> {
  constructor() {
    super('collapsed');
  }
}

export class CondensedPropertyChangedEvent extends LegacyPropertyChangedEvent<'condensed'> {
  constructor() {
    super('condensed');
  }
}

export class NativePropertyChangedEvent extends LegacyPropertyChangedEvent<'native'> {
  constructor() {
    super('native');
  }
}

export class EmbeddedPropertyChangedEvent extends LegacyPropertyChangedEvent<'embedded'> {
  constructor() {
    super('embedded');
  }
}
export class TextareaPropertyChangedEvent extends LegacyPropertyChangedEvent<'textarea'> {
  constructor() {
    super('textarea');
  }
}
export class ResizablePropertyChangedEvent extends LegacyPropertyChangedEvent<'resizable'> {
  constructor() {
    super('resizable');
  }
}
export class CardHeightPropertyChangedEvent extends LegacyPropertyChangedEvent<'cardHeight'> {
  constructor() {
    super('cardHeight');
  }
}
export class ArrowPositionPropertyChangedEvent extends LegacyPropertyChangedEvent<'arrowPosition'> {
  constructor() {
    super('arrowPosition');
  }
}

export class BehaviorPropertyChangedEvent extends LegacyPropertyChangedEvent<'behavior'> {
  constructor() {
    super('behavior');
  }
}
export class StretchPropertyChangedEvent extends LegacyPropertyChangedEvent<'stretch'> {
  constructor() {
    super('stretch');
  }
}
export class HPropertyChangedEvent extends LegacyPropertyChangedEvent<'h'> {
  constructor() {
    super('h');
  }
}

export class MPropertyChangedEvent extends LegacyPropertyChangedEvent<'m'> {
  constructor() {
    super('m');
  }
}

export class SPropertyChangedEvent extends LegacyPropertyChangedEvent<'s'> {
  constructor() {
    super('s');
  }
}

export class AmPmPropertyChangedEvent extends LegacyPropertyChangedEvent<'amPm'> {
  constructor() {
    super('amPm');
  }
}

export class StepPropertyChangedEvent extends LegacyPropertyChangedEvent<'step'> {
  constructor() {
    super('step');
  }
}

export class ClockFormatPropertyChangedEvent extends LegacyPropertyChangedEvent<'clockFormat'> {
  constructor() {
    super('clockFormat');
  }
}

export class ValPropertyChangedEvent extends LegacyPropertyChangedEvent<'val'> {
  constructor() {
    super('val');
  }
}

export class TimeValueChangedEvent extends CustomEvent<{ Name: string }> {
  constructor(name: string, init?: CustomEventInit) {
    super(name, init);
  }
}

export class currentActivePropertyChangedEvent extends LegacyPropertyChangedEvent<'currentActive'> {
  constructor() {
    super('currentActive');
  }
}

export class currentSelectedPropertyChangedEvent extends LegacyPropertyChangedEvent<'currentSelected'> {
  constructor() {
    super('currentSelected');
  }
}

export class DrawerWidthPropertyChangedEvent extends LegacyPropertyChangedEvent<'drawerWidth'> {
  constructor() {
    super('drawerWidth');
  }
}
export class AspectRatioPropertyChangedEvent extends LegacyPropertyChangedEvent<'aspectRatio'> {
  constructor() {
    super('aspectRatio');
  }
}
export class MinPropertyChangedEvent extends LegacyPropertyChangedEvent<'min'> {
  constructor() {
    super('min');
  }
}

export class MaxPropertyChangedEvent extends LegacyPropertyChangedEvent<'max'> {
  constructor() {
    super('max');
  }
}

export class KeypadPropertyChangedEvent extends LegacyPropertyChangedEvent<'keypad'> {
  constructor() {
    super('keypad');
  }
}

export class NoArrowsPropertyChangedEvent extends LegacyPropertyChangedEvent<'noArrows'> {
  constructor() {
    super('noArrows');
  }
}

export class InputDisabledPropertyChangedEvent extends LegacyPropertyChangedEvent<'inputDisabled'> {
  constructor() {
    super('inputDisabled');
  }
}

export class KeypadActivePropertyChangedEvent extends LegacyPropertyChangedEvent<'keypadActive'> {
  constructor() {
    super('keypadActive');
  }
}

export class KeypadDisabledPropertyChangedEvent extends LegacyPropertyChangedEvent<'keypadDisabled'> {
  constructor() {
    super('keypadDisabled');
  }
}

export class NoValidatePropertyChangedEvent extends LegacyPropertyChangedEvent<'noValidate'> {
  constructor() {
    super('noValidate');
  }
}
export class FixedPropertyChangedEvent extends LegacyPropertyChangedEvent<'fixed'> {
  constructor() {
    super('fixed');
  }
}
export class InitialsPropertyChangedEvent extends LegacyPropertyChangedEvent<'initials'> {
  constructor() {
    super('initials');
  }
}

export class NamePropertyChangedEvent extends LegacyPropertyChangedEvent<'name'> {
  constructor() {
    super('name');
  }
}
export class PatientPropertyChangedEvent extends LegacyPropertyChangedEvent<'patient'> {
  constructor() {
    super('patient');
  }
}

export class ShowControlsPropertyChangedEvent extends LegacyPropertyChangedEvent<'showControls'> {
  constructor() {
    super('showControls');
  }
}

export class ShowRangePropertyChangedEvent extends LegacyPropertyChangedEvent<'showRange'> {
  constructor() {
    super('showRange');
  }
}

export class LowerValuePropertyChangedEvent extends LegacyPropertyChangedEvent<'lower-value'> {
  constructor(init?: CustomEventInit) {
    super('lower-value', init);
  }
}

export class UpperValuePropertyChangedEvent extends LegacyPropertyChangedEvent<'upper-value'> {
  constructor(init?: CustomEventInit) {
    super('upper-value', init);
  }
}

export class ChangeEndEvent extends CustomEvent<{
  value?: number;
  lowerValue?: number;
  upperValue?: number;
}> {
  constructor(
    type: string,
    init?: CustomEventInit<{ value?: number; lowerValue?: number; upperValue?: number }>
  ) {
    super(type, init); //emit different strings based on differnt event types (value-change-end, range-value-change-end)
  }
}

export class UnauthenticatedPropertyChangedEvent extends LegacyPropertyChangedEvent<'unauthenticated'> {
  constructor() {
    super('unauthenticated');
  }
}
export class BackgroundPropertyChangedEvent extends LegacyPropertyChangedEvent<'background'> {
  constructor() {
    super('background');
  }
}

export class PaddingPropertyChangedEvent extends LegacyPropertyChangedEvent<'padding'> {
  constructor() {
    super('padding');
  }
}

export class ElevationPropertyChangedEvent extends LegacyPropertyChangedEvent<'elevation'> {
  constructor() {
    super('elevation');
  }
}

export class ContentGapPropertyChangedEvent extends LegacyPropertyChangedEvent<'contentGap'> {
  constructor() {
    super('contentGap');
  }
}

export class LayoutPropertyChangedEvent extends LegacyPropertyChangedEvent<'layout'> {
  constructor() {
    super('layout');
  }
}
