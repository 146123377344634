// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthcare GmbH/Siemens Medical Solutions USA, Inc., 2023. All rights reserved
// -----------------------------------------------------------------------------------------------------------------
import { __awaiter, __decorate } from 'tslib';
import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { sharedStyles } from '../../styles';
import { dispatchAttributeChangedEvent } from '../../utils/attribute-changed-event-dispatcher.js';
import { baseFontSize } from '../../utils/base-font-size.js';
import '../../utils/device-identifier.js'; // just to pull in modrnzr
import { deviceIdentifier } from '../../utils/device-identifier.js';
import { FocusBlurMixin } from '../../utils/focus-blur-mixin.js';
import tabStyles from './sh-tabs.lit.scss.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore*/
let SHTabs = class SHTabs extends FocusBlurMixin(LitElement) {
  constructor() {
    super(...arguments);
    /**adds styles asper nav bar footer specs*/
    /** @ignore */
    this.footerContext = false;
    /**Default false. If set to `true`, the tabs will not be collapsed when placed inside the Access Bar for small screen sizes.*/
    this.fixed = false;
    /**If set to `true`, the tabs will scroll horizontally when there are too many tabs across the viewport.*/
    this.scrollable = false;
    /**If `scrollable` is set to true, this property can be used in a touch environment, where scrolling horizontally is intuitive and the arrows can be dismissed due to lack of space.*/
    this.noArrows = false;
    /**If `no-scroll` is set to true, the tabs will not scroll horizontally when there are too many tabs across the viewport.*/
    this.noScroll = false;
  }
  static get styles() {
    return [sharedStyles, tabStyles];
  }
  render() {
    return html`
      ${this.collapsed
        ? html` <div class="collapsed-input" @click="${() => this.show()}">
              ${this.icon ? html`<sh-icon icon="${this.icon}" id="tab-icon"></sh-icon>` : ``}
              <div class="text">
                ${this.label} ${this.info ? html` <div class="info">${this.info}</div> ` : ``}
              </div>
              ${this.info ? html` <div class="info"></div> ` : ``}
              <sh-icon icon="down-s" id="expand-icon"></sh-icon>
            </div>
            <div class="collapsed-popover">
              <slot id="collapsed-slot" @slotchange="${this.handleTabsSlotChange}"></slot>
            </div>`
        : html` ${this.scrollable && !this.noScroll
            ? html`<sh-icon
                icon="arrow-line-left-s"
                button
                @click="${() => this.scrollTabs('left')}"
                class="arrow-icons left hidden"
              ></sh-icon>`
            : ''}
          ${this.noScroll
            ? html` <div class="no-scroll">
                <slot id="main-slot" @slotchange="${this.handleTabsSlotChange}"></slot>
              </div>`
            : html` <div class="items-wrapper" @scroll="${this.handleArrows.bind(this)}">
                <div class="items-scroll-wrapper">
                  <slot id="main-slot" @slotchange="${this.handleTabsSlotChange}"></slot>
                </div>
              </div>`}
          ${this.scrollable && !this.noScroll
            ? html`<sh-icon
                icon="arrow-line-right-s"
                button
                @click="${() => this.scrollTabs('right')}"
                class="arrow-icons right"
              ></sh-icon>`
            : ''}`}
    `;
  }
  connectedCallback() {
    super.connectedCallback();
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'navigation');
    }
    if (deviceIdentifier.isTouchDevice()) {
      this.classList.add('no-hovermq');
    }
    this.resizeObserver = new ResizeObserver(() => {
      var _a;
      if (this.collapsed) return;
      const itemsScrollWrapper =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.items-scroll-wrapper');
      if (!itemsScrollWrapper) return;
      if (itemsScrollWrapper.getBoundingClientRect().width > this.getBoundingClientRect().width) {
        this.handleOverflow(true);
      } else {
        this.handleOverflow(false);
      }
    });
    this.resizeObserver.observe(this);
    // update the tab elements active state
    this.addEventListener('clicked', (e) => {
      const tabItem = e.target;
      this.label = tabItem.label;
      this.icon = tabItem.icon;
      if (tabItem.patient === true) {
        this.icon = 'patient';
        this.info = tabItem.info;
      } else {
        this.info = undefined;
      }
      // get name for tabs without labels
      if (tabItem.label === undefined) {
        this.label = tabItem.name;
      }
      this.makeTabItemsInactive();
      this.hide();
    });
    this.addEventListener('focus', this.handleFocus);
    this.addEventListener('blur', this.handleBlur);
    this.addEventListener('item-active', (e) => {
      this.scrollTabsIntoView(e.target);
    });
  }
  disconnectedCallback() {
    var _a;
    (_a = this.resizeObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
    this.removeEventListener('item-active', (e) => {
      this.scrollTabsIntoView(e.target);
    });
    clearTimeout(this.timeout);
    super.disconnectedCallback();
  }
  firstUpdated() {
    if (this.condensed) {
      this.setAttribute('tabindex', '0');
    } else {
      this.removeAttribute('tabindex');
    }
    // close popover on focusout
    this.addEventListener('focusout', function (event) {
      // If focus is still in the popover, do nothing
      if (this.contains(event.relatedTarget)) return;
      this.active = false;
    });
  }
  update(changedProperties) {
    if (changedProperties.has('collapsed')) {
      this.collapsedChanged();
    }
    if (changedProperties.has('condensed')) {
      this.condensedChanged();
    }
    super.update(changedProperties);
  }
  updated(changedProperties) {
    if (changedProperties.has('scrollable')) {
      const activeItem = this.querySelector('sh-tab-item[active]');
      if (activeItem) {
        this.scrollTabsIntoView(activeItem);
      }
    }
    const listOfProperties = ['collapsed', 'active', 'label', 'icon', 'info', 'condensed', 'fixed'];
    dispatchAttributeChangedEvent(this, changedProperties, listOfProperties);
  }
  scrollTabs(direction) {
    var _a;
    const itemsWrapper =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.items-wrapper');
    if (direction === 'left') {
      itemsWrapper.scrollLeft -= 100;
    } else {
      itemsWrapper.scrollLeft += 100;
    }
  }
  handleOverflow(isOverflowing) {
    this.scrollable = isOverflowing;
    if (this.scrollable) {
      const activeItem = this.querySelector('sh-tab-item[active]');
      if (activeItem) {
        this.scrollTabsIntoView(activeItem);
      }
    }
  }
  scrollTabsIntoView(tabItem) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a, _b, _c, _d;
      const itemsWrapper =
        (_a = this.shadowRoot) === null || _a === void 0
          ? void 0
          : _a.querySelector('.items-wrapper');
      const leftIcon =
        (_b = this.shadowRoot) === null || _b === void 0
          ? void 0
          : _b.querySelector('sh-icon.left');
      const rightIcon =
        (_c = this.shadowRoot) === null || _c === void 0
          ? void 0
          : _c.querySelector('sh-icon.right');
      const leftX = leftIcon ? leftIcon.getBoundingClientRect().x : 0;
      const rightX = rightIcon ? rightIcon.getBoundingClientRect().x : 0;
      const tabItemX = tabItem.getBoundingClientRect().x;
      const tabItemRight = tabItem.getBoundingClientRect().right;
      if (leftIcon && rightIcon && !this.noArrows) {
        if (tabItemX < leftX + leftIcon.getBoundingClientRect().width) {
          tabItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else if (tabItemRight > rightX && rightX !== 0) {
          const arrowPaddingLeftRight =
            getComputedStyle(rightIcon).getPropertyValue('padding-left');
          const paddingForRightLeftIcon = parseFloat(arrowPaddingLeftRight) * 4;
          const itemsWrapperRight = itemsWrapper.getBoundingClientRect().right;
          const tabsRight = this.getBoundingClientRect().right;
          let iconNumber = 1;
          if (leftIcon.getBoundingClientRect().width === 0) {
            iconNumber = 2;
          }
          if (
            ((_d = tabItem.nextElementSibling) === null || _d === void 0 ? void 0 : _d.tagName) !==
              'SH-TAB-ITEM' &&
            itemsWrapperRight < tabsRight
          ) {
            itemsWrapper.scrollLeft += tabItemRight - itemsWrapperRight;
            /*this code is added specific to iPad , scroll further after the right icon is hidden and timeout is added as it takes time
                        for the icon to be hidden*/
            this.timeout = setTimeout(() => {
              itemsWrapper.scrollLeft +=
                parseFloat(
                  getComputedStyle(this).getPropertyValue('--sh-tab-item-arrow-icons-line-height')
                ) * baseFontSize;
            }, 400);
          } else {
            /*scroll left += [tabitem's width - (right icon's x - tabitem's x + width of the right arrow icon)]
                      (part of the item width hidden = total item width - part of the item's width visible + width of the right arrow icon))*/
            itemsWrapper.scrollLeft +=
              tabItemRight -
              this.getBoundingClientRect().right +
              +(
                parseFloat(
                  getComputedStyle(this).getPropertyValue('--sh-tab-item-arrow-icons-line-height')
                ) * baseFontSize
              ) *
                iconNumber +
              paddingForRightLeftIcon;
          }
        }
      } else if (this.noArrows) {
        const itemsWrapperRight =
          itemsWrapper === null || itemsWrapper === void 0
            ? void 0
            : itemsWrapper.getBoundingClientRect().right;
        const itemsWrapperX =
          itemsWrapper === null || itemsWrapper === void 0
            ? void 0
            : itemsWrapper.getBoundingClientRect().x;
        if (tabItemRight > itemsWrapperRight) {
          itemsWrapper.scrollLeft += tabItemRight - itemsWrapperRight;
        }
        if (tabItemX < itemsWrapperX) {
          itemsWrapper.scrollLeft -= Math.abs(tabItemX - itemsWrapperX);
        }
      }
    });
  }
  handleArrows() {
    var _a, _b, _c;
    const itemsWrapper =
      (_a = this.shadowRoot) === null || _a === void 0
        ? void 0
        : _a.querySelector('.items-wrapper');
    if (!itemsWrapper) return;
    const leftIcon =
      (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('sh-icon.left');
    const rightIcon =
      (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('sh-icon.right');
    if (leftIcon) {
      if (itemsWrapper.scrollLeft == 0) {
        leftIcon.classList.add('hidden');
      } else {
        leftIcon.classList.remove('hidden');
      }
    }
    if (rightIcon) {
      if (
        Math.ceil(itemsWrapper.scrollLeft + itemsWrapper.offsetWidth) >= itemsWrapper.scrollWidth
      ) {
        rightIcon.classList.add('hidden');
      } else {
        rightIcon.classList.remove('hidden');
      }
    }
  }
  makeTabItemsInactive() {
    const children = this.querySelectorAll('sh-tab-item[active]');
    for (let i = 0; i < children.length; i++) {
      children[i].active = false;
    }
  }
  setTabsProperties() {
    const activeTab = this.querySelector('[active]');
    if (activeTab === null) return;
    // populate placeholder properties with data from active tab-item
    if (activeTab.name) {
      this.label = activeTab.name;
    } else if (activeTab.label) {
      this.label = activeTab.label;
    }
    if (activeTab.patient) {
      this.icon = 'patient';
      if (activeTab.info) {
        this.info = activeTab.info;
      }
    } else if (activeTab.icon) {
      this.icon = activeTab.icon;
    }
  }
  condensedChanged() {
    const tabItems = this.querySelectorAll('sh-tab-item');
    for (let i = 0; i < tabItems.length; i++) {
      tabItems[i].condensed = this.condensed;
    }
  }
  collapsedChanged() {
    const tabItems = this.querySelectorAll('sh-tab-item');
    for (let i = 0; i < tabItems.length; i++) {
      tabItems[i].collapsed = this.collapsed;
    }
    if (this.collapsed) {
      this.setAttribute('tabindex', '1');
    } else {
      this.removeAttribute('tabindex');
    }
  }
  show() {
    if (this.active === true) {
      this.hide();
      return;
    }
    this.active = true;
  }
  hide() {
    this.active = false;
  }
  handleTabsSlotChange() {
    const tabItem = this.querySelectorAll('sh-tab-item');
    if (this.accessContext) {
      tabItem.forEach((el) => {
        el.accessContext = true;
      });
    }
    this.setTabsProperties();
    this.collapsedChanged();
    this.condensedChanged();
  }
  handleKeyup(e) {
    var _a;
    if (e.code === 'Tab' && !this.disabled) {
      if (
        document.activeElement === this &&
        !((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.activeElement)
      ) {
        this.classList.add('item-focused');
      } else {
        this.classList.remove('item-focused');
      }
    }
    if (e.code === 'Space' || e.code === 'Enter' || e.code === 'NumpadEnter') {
      this.toggleAttribute('active');
    }
    // arrow_down on collapsed active sh-tabs moves focus to first possible child
    if (e.code === 'ArrowDown' && this.collapsed && this.active) {
      const fChild = this.querySelector('SH-TAB-ITEM:not([disabled])');
      fChild.focus();
      fChild.classList.add('item-focused');
    }
    // arrow_up on collapsed active sh-tabs moves focus to last possible child
    if (e.code === 'ArrowUp' && this.collapsed && this.active) {
      const child = this.querySelectorAll('SH-TAB-ITEM:not([disabled])');
      const lChild = child[child.length - 1];
      lChild.focus();
      lChild.classList.add('item-focused');
    }
  }
  handleFocus() {
    super.handleFocus();
    this.addEventListener('keyup', this.handleKeyup);
  }
  handleBlur() {
    super.handleBlur();
    this.removeEventListener('keyup', this.handleKeyup);
  }
};
__decorate([property({ type: Boolean, reflect: true })], SHTabs.prototype, 'collapsed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabs.prototype, 'active', void 0);
__decorate([property({ type: String, reflect: true })], SHTabs.prototype, 'label', void 0);
__decorate([property({ type: String, reflect: true })], SHTabs.prototype, 'icon', void 0);
__decorate([property({ type: String, reflect: true })], SHTabs.prototype, 'info', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'access-context' })],
  SHTabs.prototype,
  'accessContext',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'nav-context' })],
  SHTabs.prototype,
  'navContext',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'footer-context' })],
  SHTabs.prototype,
  'footerContext',
  void 0
);
__decorate([property({ type: Boolean, reflect: true })], SHTabs.prototype, 'condensed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabs.prototype, 'fixed', void 0);
__decorate([property({ type: Boolean, reflect: true })], SHTabs.prototype, 'scrollable', void 0);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-arrows' })],
  SHTabs.prototype,
  'noArrows',
  void 0
);
__decorate(
  [property({ type: Boolean, reflect: true, attribute: 'no-scroll' })],
  SHTabs.prototype,
  'noScroll',
  void 0
);
__decorate([state()], SHTabs.prototype, 'timeout', void 0);
SHTabs = __decorate([customElement('sh-tabs')], SHTabs);
export { SHTabs };
