// -----------------------------------------------------------------------------------------------------------------
// Restricted - Copyright (C) Siemens Healthineers AG 2023.
// -----------------------------------------------------------------------------------------------------------------
export const resizeMixin = (superClass) => {
  class ResizeMixinElement extends superClass {
    connectedCallback() {
      super.connectedCallback();
      /** @ignore */
      this.windowDimensionObserver = this.handleResize.bind(this);
      window.addEventListener('resize', this.windowDimensionObserver);
    }
    disconnectedCallback() {
      window.removeEventListener('resize', this.windowDimensionObserver);
      super.disconnectedCallback();
    }
  }
  /**
   * if you want to understand the type coersion here read this:
   * read this: https://lit.dev/docs/composition/mixins/#mixins-in-typescript
   */
  return ResizeMixinElement;
};
